import React from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store';

import {
  updatePageContent,
  updateComponentDataDefaults,
  updateComponentDefaults,
} from '../../store/slices/templatesConfig';

const AdminCustomization = () => {
  const setPages = useAppSelector((state) => state.templatesConfig.setPages);
  const location = useLocation();

  const dispatch = useAppDispatch();

  const paths: any = Object.keys(setPages).map((path) => ({ ...setPages[path], path }));
  const matches = matchRoutes(paths, location);

  const handleIframeMessage = (event: MessageEvent) => {
    if (
      event.origin &&
      (event.origin.endsWith('.play-online.com') ||
        event.origin.startsWith('http://localhost') ||
        event.origin.startsWith('http://127.0.0.1'))
    ) {
      if (event.data.type === 'upc') {
        dispatch(updatePageContent({ id: event.data.payload.id, content: event.data.payload.content }));
      }
      if (event.data.type === 'ucdd') {
        dispatch(updateComponentDataDefaults({ content: event.data.payload.content }));
      }
      if (event.data.type === 'ucd') {
        dispatch(updateComponentDefaults({ content: event.data.payload.content }));
      }
      if (event.data.type === 'sl') {
        // selected component / component data
        if (event.data.payload.id) {
          const element = document.querySelector(`[data-cjs-n-id="${event.data.payload.id}"]`);
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  };

  React.useEffect(() => {
    window.onmessage = handleIframeMessage;
    return () => {
      window.onmessage = null;
    };
  }, []);

  React.useEffect(() => {
    if (matches?.length) {
      const match = matches[0];
      if (window !== window.parent) {
        window.top?.postMessage({ type: 'pageChange', payload: match }, '*');
      }
    }
  }, [matches]);

  return null;
};

export default AdminCustomization;
