const noop = () => {
  /**/
};

let defaultLevel = 'error';

if (import.meta.env.DEV) {
  defaultLevel = 'debug';
}

function Logger(name: string, level = defaultLevel) {
  let levelNo = 0;
  switch (level) {
    case 'debug':
      levelNo = 5;
      break;
    case 'info':
      levelNo = 4;
      break;
    case 'warn':
      levelNo = 3;
      break;
    case 'error':
      levelNo = 2;
      break;
    case 'fatal':
      levelNo = 1;
      break;
    default:
      levelNo = 0;
  }

  const tag = name ? `${name}|` : '';

  const obj = {
    debug: levelNo >= 5 ? console.log.bind(console.log, `[${tag}debug]`) : noop,
    info: levelNo >= 4 ? console.log.bind(console.log, `[${tag}info]`) : noop,
    warn: levelNo >= 3 ? console.log.bind(console.log, `[${tag}warn]`) : noop,
    error: levelNo >= 2 ? console.error.bind(console.error, `[${tag}error]`) : noop,
    fatal: levelNo >= 1 ? console.error.bind(console.error, `[${tag}fatal]`) : noop,
  };
  return obj;
}

Logger.DEBUG = 'debug';
Logger.INFO = 'info';
Logger.WARN = 'warn';
Logger.ERROR = 'error';
Logger.FATAL = 'fatal';

window.Logger = Logger;
window.log = Logger('global');

export default Logger;
