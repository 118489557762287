import * as React from 'react';
import { Modal, ModalBody } from 'reactstrap';

type EditPhoneNumberProps = {
  data: any;
  onClose: () => void;
};

const EditPhoneNumber = (props: EditPhoneNumberProps) => {
  return (
    <Modal isOpen={true} toggle={props.onClose}>
      <ModalBody>test</ModalBody>
    </Modal>
  );
};

export default EditPhoneNumber;
