import { combineReducers } from 'redux';

import depositTicket from './deposit-ticket';

export const depositTicketRootReducer = combineReducers({
  depositTicket: depositTicket,
});

export const rootReducer = combineReducers({
  wheel: depositTicketRootReducer
});

export default rootReducer;
