import React from 'react';
import styled from 'styled-components';
//import { useNavigate, useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { fetchRankSystems } from '../../../store/slices/rankSystems';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

import './index.scss';
import { shallowEqual } from 'react-redux';

type RankSystemsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const RankSystems = (componentProps: RankSystemsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();

  const { rankGroups, wallets, loading, loaded, authentication } = useAppSelector(
    (state) => ({
      authentication: state.authentication,
      rankGroups: state.rankSystems.rankGroups,
      wallets: state.rankSystems.wallets,
      loading: state.rankSystems.loading,
      loaded: state.rankSystems.loaded,
    }),
    shallowEqual,
  );

  React.useEffect(() => {
    if (!rankGroups || !rankGroups.length) dispatch(fetchRankSystems());
  }, []);

  if (!rankGroups || (Array.isArray(rankGroups) && rankGroups.length === 0)) return null;

  const contextValue = {
    rank_groups: rankGroups,
    wallets: wallets,
    loading: loading,
    loaded: loaded,
  };

  if (
    authentication &&
    (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null)
  ) {
    return null;
  }

  //console.log(contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default RankSystems;
