import { winCoinsConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  data: [],
  loaded: false,
  loading: true,
  collecting: false,
};

const templateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case winCoinsConstants.CLEAR:
        draft.loaded = false;
        draft.loading = false;
        draft.data = [];
        break;
      case winCoinsConstants.LOAD:
        draft.loaded = false;
        draft.loading = true;
        break;
      case winCoinsConstants.LOADED:
        draft.loading = false;
        draft.loaded = true;
        if (action.data) {
          draft.data = action.data;
        }
        break;
      case winCoinsConstants.UPDATE_SYSTEM:
        if (action.data && action.id) {
          const systems = [];
          draft.data.forEach((system) => {
            if (system && system.coin_system && system.coin_system.id && system.coin_system.id === action.id) {
              const newSystem = Object.assign(system, action.data);
              systems.push(newSystem);
            } else {
              systems.push(system);
            }
          });
          draft.data = systems;
        }
        break;
      case winCoinsConstants.STATUS:
        if (action.data && typeof action.data.collecting !== 'undefined') {
          draft.collecting = action.data.collecting;
        }
        break;
      default:
      /* noop */
    }
  });

export default templateReducer;
