import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { useMediaQuery } from '../../../utils/useQueryMedia';
import { expiredLottoEvents } from '../../../../modules/lotto/store/actions/lotto-events';
import images from '../../../../modules/lotto/assets/countries';

import './index.scss';

type LottoEventsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LottoEvents = (componentProps: LottoEventsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const lottoEvents = useAppSelector((state) => state.lotto.lottoEvents.items);
  const loading = useAppSelector((state) => state.lotto.lottoEvents.loading);
  const filter = useAppSelector((state) => state.lotto.lottoEvents.defaultSystem);
  const [showE, setShowE] = React.useState(20);
  const isDesktop = useMediaQuery('(min-width:900px)');
  const [count, setCount] = React.useState(0);

  const showMoreEvents = React.useCallback(() => {
    let ns = showE + (isDesktop ? 100 : 10);

    if (ns > lottoEvents.length) {
      ns = lottoEvents.length;
    }

    setShowE(ns);
  }, [showE, lottoEvents, isDesktop]);

  React.useEffect(() => {
    const tid = window.setInterval(() => {
      setCount((c) => c + 1);
    }, 1000);

    return () => {
      clearTimeout(tid);
    };
  }, [lottoEvents]);

  const contextValue = React.useMemo(() => {
    let filteredEvents = lottoEvents;
    if (filter && filter !== 'all') {
      if (filter?.startsWith('balls_')) {
        const newFilter = filter.replace('balls_', '');
        filteredEvents = lottoEvents.filter((e) => e.n.toString() === newFilter);
      } else if (filter?.startsWith('plus_')) {
        const newFilter = filter.replace('plus_', '');
        filteredEvents = lottoEvents.filter((e) => e.n.toString() === newFilter);

        const nextNMinutes = moment().add(parseInt(newFilter), 'minutes');
        const strDate = nextNMinutes.format('YYYY-MM-DD HH:mm:ss');

        filteredEvents = filteredEvents.filter((e) => e?.event_date <= strDate);
      } else {
        filteredEvents = lottoEvents.filter((e) => e.event_name === filter);
      }
    }

    filteredEvents = JSON.parse(JSON.stringify(filteredEvents));

    filteredEvents.find((event, i) => {
      if (i + 1 > showE) {
        return true;
      }
      const dt = moment(event.event_date, 'YYYY-MM-DD HH:mm:ss');

      const dtNow = moment();

      const hours = dt.diff(dtNow, 'hours');
      const mins = dt.diff(dtNow, 'minutes') - hours * 60;
      const secs = dt.diff(dtNow, 'seconds') - mins * 60;

      if (hours < 0 || mins < 0 || secs < 0) {
        event.counter = '00 : 00 : 00';
      } else {
        if (secs < 60) {
          event.counter = `${hours < 10 ? `0${hours}` : hours} : ${mins < 10 ? `0${mins}` : mins} : ${
            secs < 10 ? `0${secs}` : secs
          }`;
        } else {
          event.counter = dt.format('D MMM HH:mm');
        }
      }

      // @ts-ignore
      if (images[event.iso2] || images[event.iso2.toLowerCase()]) {
        // @ts-ignore
        event.country_flag = images[event.iso2] || images[event.iso2.toLowerCase()];
      }

      return false;
    });

    return {
      list: filteredEvents.slice(0, showE),
      listTotal: filteredEvents.length,
      shownEvents: showE,
      showMoreEvents,
      loading,
    };
  }, [lottoEvents, loading, filter, showE, showMoreEvents, count]);

  //console.log('LottoEvents[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LottoEvents;
