import * as actionTypes from './actionTypes';

export const requestTransactionsHistory = (requestType, showMore = false) => {
  return {
    type: actionTypes.transactions.REQUEST_TRANSACTIONS_HISTORY,
    requestType: requestType,
    showMore: showMore,
  };
};

export const receivedTransactionsHistory = (data) => {
  return {
    type: actionTypes.transactions.RECEIVED_TRANSACTIONS_HISTORY,
    transactions: data,
  };
};
