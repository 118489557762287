import { useAppSelector } from '../../store';
import craftJsParser from '../utils/craftJsParser';

type LinkedComponentProps = {
  componentId: string;
  className: string;
};

const LinkedComponent = (props: LinkedComponentProps) => {
  const config = useAppSelector((state) => state.templatesConfig);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user') as boolean,
  );

  const { componentId, className } = props;

  let content = null;

  try {
    const projectData = config.components[componentId].projectData;
    const data = JSON.parse(projectData);
    const rootNodeId = 'ROOT';

    if (rootNodeId) {
      data[rootNodeId].type = 'Container';
      //data[rootNodeId].props.styleText = '';

      content = craftJsParser({
        craftState: data,
        rootNodeId: rootNodeId,
        pageId: componentId,
        pageType: 'components',
        extendProps: {
          className: className ?? null,
        },
        options: {
          authenticated,
        },
        withProvider: true,
      });
    }
  } catch (err) {
    /**/
  }
  return content;
};

export default LinkedComponent;
