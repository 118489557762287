//import images from '@/modules/lotto/assets/countries';

export const flags: any = {
  /*
  '-74': images.de,
  '-86': images.hu,
  '-159': images.gbSCT,
  '-178': images.ch,
  '-169': images.es,
  '-94': images.it,
  '-49': images.hr,
  '-2': images.al,
  '-165': images.si,
  '-204': images.rs,
  '-53': images.dk,
  '-60': images.gbENG,
  '-147': images.pl,
  '-13': images.at,
  '-132': images.nl,
  '-69': images.fr,
  '-151': images.ro,
  '-20': images.be,
  '-164': images.sk,
  '-192': images.ua,
  '-188': images.tr,
  '-148': images.pt,
  '-73': images.ge,
  '-52': images.cz,
  */
};
