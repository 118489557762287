import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import ModalHeader from '../ModalHeader';
import SystemSelectEntry from '../SystemSelectEntry';
import './index.scss';
import { getLottoState } from '../../store/selectors/lottoData';
import { fetchLottoResults } from '../../store/actions/lotto-results';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: '#EDF0F0',
    },
    '&.MuiDialog-root': {
      zIndex: '1303!important',
    }
  },
  minHeightSmall: {
    '& .MuiPaper-root.MuiDialog-paper': {
      minHeight: 'calc(100% - 64px)',
      width: '612px',
    }
  },
  content: {
    padding: '20px 13px',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: 'calc(100vh - 180px)',
  },
  contentDesktop: {
    height: 'calc(100vh - 244px)',
  },
  empty: {
    textAlign: 'center',
    padding: '30px 0',
    fontSize: '14px',
    fontWeight: '600',
    color: '#17214d'
  },
  latestDraw: {
    margin: '0 0 28px',
  },
  drawTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    padding: '0 5px',
    color: '#111524',
    marginBottom: '5px',
  },
  drawMuted: {
    fontSize: '11px',
    color: '#828EA8'
  },
  balls: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'start',
    '&>div': {
      flexBasis: '10%'
    }
  },
  ball: {
    width: '29px',
    height: '29px',
    borderRadius: '50%',
    border: '1.5px solid #00C7B2',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: '600',
    color: '#fff',
    lineHeight: '24px',
    margin: '3px auto',
    background: 'linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)',
  },
  header: {
    background: '#FFFFFF',
    padding: '15px'
  },
  probe: {
    width: '1px',
    height: '1px',
  },
  dropShadow: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  muted: {
    opacity: '0.5'
  },
  mt: {
    marginTop: '7px',
  },
  dateButton: {
    backgroundColor: '#DBFFFB',
    borderRadius: '10px',
    fontSize: '14px',
    color: '#17214D',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: '1px solid #06D2BD',
    height: '34px',
    justifyContent: 'center',
    width: '44%',
    textAlign: 'center',
    paddingLeft: '16px',
  },
  active: {
    backgroundColor: '#6eecde',
  },
  iconSvg: {
    width: '16px'
  },
  canceled: {
    flexBasis: 'auto!important',
    textAlign: 'center',
    width: '100%',
    fontSize: '14px',
    fontWeight: '600'
  },
  more: {
    fontSize: '10px',
    fontWeight: '600',
    color: '#586682',
    margin: '16px 0',
    textAlign: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const Empty = props => {
  return <React.Fragment></React.Fragment>;
};

const LotteryResults = (props) => {
  const classes = useStyles();
  const { eventName, events, total, open, onClose, loading, getResults, t, i18n } = props;
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const fullScreen = isDesktop ? false : true;

  const [openStartDate, setOpenStartDate] = React.useState(false);
  const [selectedStartDate, setSelectedStartDate] = React.useState(moment().subtract(1, 'days'));
  const [openEndDate, setOpenEndDate] = React.useState(false);
  const [selectedEndDate, setSelectedEndDate] = React.useState(moment());

  const sdRef = React.useRef();
  const edRef = React.useRef();

  const handleStartDateChange = date => {
    setOffset(0);
    setSelectedStartDate(date);
    setOpenStartDate(false);
  };
  const handleEndDateChange = date => {
    setOffset(0);
    setSelectedEndDate(date);
    setOpenEndDate(false);
  };
  const handleStartDateClick = () => {
    setOpenStartDate(v => !v);
  };
  const onStartDateClose = () => {
    setOpenStartDate(false);
  };
  const handleEndDateClick = () => {
    setOpenEndDate(v => !v);
  };
  const onEndDateClose = () => {
    setOpenEndDate(false);
  };

  const [filter, setFilter] = React.useState(eventName);
  const handleFilterChange = (v) => {
    setOffset(0);
    setFilter(v ? v : eventName);
  };

  const [dropShadow, setDropShadow] = React.useState(false);

  const probe = React.createRef();

  React.useEffect(() => {
    const fireEvent = (stuck, targetInfo, rootBoundsInfo) => {
      setDropShadow(oldVal => {
        if (stuck !== oldVal) {
          return stuck;
        }
        return oldVal;
      });
    };

    let observer = null;
    let probeRef = probe && probe.current ? probe.current : null;

    if (probeRef) {
      observer = new IntersectionObserver(
        (records, observer) => {
          for (const record of records) {
            const targetInfo = record.boundingClientRect;
            const rootBoundsInfo = record.rootBounds;

            if (targetInfo && rootBoundsInfo) {

              // Started sticking.
              if (targetInfo.bottom < rootBoundsInfo.top) {
                fireEvent(true, targetInfo, rootBoundsInfo);
              }

              // Stopped sticking.
              if (
                targetInfo.bottom >= rootBoundsInfo.top &&
                targetInfo.bottom < rootBoundsInfo.bottom
              ) {
                fireEvent(false, targetInfo, rootBoundsInfo);
              }
            }
          }
        },
        { threshold: [0], root: probeRef.parentElement }
      );

      observer.observe(probeRef); // attach observer to our probe
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [probe]);

  const [offset, setOffset] = React.useState(0);

  React.useEffect(() => {
    if (filter && selectedStartDate && selectedEndDate) {
      getResults({
        limit: 10,
        offset: offset,
        event_name: filter,
        date_to: selectedEndDate.endOf('day').format(),
        date_from: selectedStartDate.startOf('day').format()
      });
    }
  }, [filter, selectedStartDate, selectedEndDate, offset]); // eslint-disable-line

  const showMore = () => {
    let ns = offset + 10;

    if (ns > total) {
      ns = total;
    }

    setOffset(ns);
  };

  return (
    <div>
      {open &&
        <Dialog
          fullScreen={fullScreen}
          disableEnforceFocus
          open={open}
          TransitionComponent={Transition}
          maxWidth={!fullScreen ? 'sm' : false}
          className={`${classes.root} ${isDesktop ? classes.minHeightSmall : ''} lottoModal`}
          onClose={onClose}
        >
          <div>
            <ModalHeader
              title={filter}
              subtitle={t('Lottery Draws')}
              active={false}
              type={'none'}
              goBack={onClose}
            />
            <div className={`${classes.header} ${dropShadow ? classes.dropShadow : ''}`}>
              <SystemSelectEntry systemName={filter} onChange={handleFilterChange} direct={true} />
              <div className={`d-flex flex-row align-items-center justify-content-between ${classes.mt}`}>
                {/* eslint-disable-next-line */}
                <div
                  className={`${classes.dateButton} ${openStartDate ? classes.active : ''} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
                  onClick={handleStartDateClick}
                  ref={sdRef}>
                  <div className="flex-grow-1">{selectedStartDate.format('DD.MM.YYYY')}</div>
                  <ArrowDropDownIcon className={`${classes.iconSvg}`} />
                </div>
                <div>-</div>
                {/* eslint-disable-next-line */}
                <div
                  className={`${classes.dateButton} ${openEndDate ? classes.active : ''} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
                  onClick={handleEndDateClick}
                  ref={edRef}>
                  <div className="flex-grow-1">{selectedEndDate.format('DD.MM.YYYY')}</div>
                  <ArrowDropDownIcon className={`${classes.iconSvg}`} />
                </div>
              </div>
            </div>
            <div className={`${classes.content} ${isDesktop ? classes.contentDesktop : ''}`}>
              <div className={classes.probe} ref={probe}></div>
              {events.length > 0 && events.map((event, i) => {
                const dt = moment(event.event_date, 'YYYY-MM-DD HH:mm:ss');
                return (<div key={i} className={classes.latestDraw}>
                  <div className={classes.drawTitle}>
                    <div>
                      <span className={classes.muted}>{dt.format('DD.MM.YYYY |')}</span> {dt.format('HH:mm')}
                    </div>
                    <div className={classes.drawMuted}>#{event.event_code}</div>
                  </div>
                  <div className={classes.balls}>
                    {event.results && event.results.length > 0 && event.results.map((res, j) => (
                      <div key={j}>
                        <div className={classes.ball}>{res}</div>
                      </div>
                    ))}
                    {event.canceled && <div className={classes.canceled}>{t('Event was canceled')}</div>}
                  </div>
                </div>);
              })}
              {!loading && events.length === 0 && <div className={classes.empty}>{t('There are no results')}</div>}
              {loading && <div className={classes.empty}><CircularProgress /></div>}
              {/* eslint-disable-next-line */}
              {total > 0 && events.length < total && <div className={classes.more} onClick={showMore}>{t('Show More')} ({events.length}/{total}) </div>}
            </div>
          </div>
          <MuiPickersUtilsProvider locale={i18n.language} utils={MomentUtils}>
            {sdRef.current !== null && (
              <DatePicker
                autoOk
                open={openStartDate}
                format="DD.MM.YYYY"
                variant="inline"
                value={selectedStartDate}
                onChange={handleStartDateChange}
                onClose={onStartDateClose}
                animateYearScrolling
                TextFieldComponent={Empty}
                PopoverProps={{
                  anchorEl: sdRef.current
                }}
              />
            )}
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider locale={i18n.language} utils={MomentUtils}>
            {edRef.current !== null && (
              <DatePicker
                autoOk
                open={openEndDate}
                format="DD.MM.YYYY"
                variant="inline"
                value={selectedEndDate}
                onChange={handleEndDateChange}
                onClose={onEndDateClose}
                animateYearScrolling
                TextFieldComponent={Empty}
                PopoverProps={{
                  anchorEl: edRef.current
                }}
              />
            )}
          </MuiPickersUtilsProvider>
        </Dialog>}
    </div>
  );
};

LotteryResults.propTypes = {
  loading: PropTypes.bool,
  events: PropTypes.array
};

LotteryResults.defaultProps = {
  loading: false,
  events: []
};

const mapStateToProps = (state, props) => {
  const bst = getLottoState(state);
  return {
    events: bst.lottoResults.results,
    total: bst.lottoResults.total,
    loading: bst.lottoResults.loading,
  };
};

const actionCreators = {
  getResults: fetchLottoResults
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(LotteryResults));