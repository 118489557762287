import * as actionTypes from './../actions/actionTypes';
import { produce } from 'immer';
import { fastEqual } from '../../../../utils/fastDeepEqual';

const initialState = {
  freeSpins: [],
  freeSpinsHistory: [],
  freeSpinsHistoryHasMore: true,
  loading: false,
  loadingHistory: false,
};

const freeBetsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.freeSpins.FREE_SPINS_LOADED: {
        if (!fastEqual(draft.freeSpins, action.freeSpins)) {
          // don't update if they're the same
          draft.freeSpins = action.freeSpins;
        }
        draft.loading = false;
        break;
      }
      case actionTypes.freeSpins.FREE_SPINS_LOADING: {
        if (action.loadingType === 1) {
          draft.loading = true;
        } else {
          draft.loadingHistory = true;
        }
        break;
      }
      case actionTypes.freeSpins.FREE_SPINS_SET_HISTORY: {
        if (action.page === 0) {
          draft.freeSpinsHistory = action.items;
          draft.freeSpinsHistoryHasMore = action.items.length > 0 ? true : [];
        } else {
          if (action.items.length) {
            draft.freeSpinsHistory = [...action.items];
            draft.freeSpinsHistoryHasMore = true;
          } else {
            draft.freeSpinsHistoryHasMore = false;
          }
        }
        draft.loadingHistory = false;
        break;
      }
      default:
        break;
    }
  });

export default freeBetsReducer;
