import { betsSlipConstants } from './constants';

export const betsSlipStakeInc = (stake) => (dispatch, getState) => {
  dispatch({
    type: betsSlipConstants.STAKE_INC,
    stake,
  });
};

export const betsSlipStakeDec = (stake) => (dispatch, getState) => {
  dispatch({
    type: betsSlipConstants.STAKE_DEC,
    stake,
  });
};

export const betsSlipStakeSet = (stake) => (dispatch, getState) => {
  dispatch({
    type: betsSlipConstants.STAKE_SET,
    stake,
  });
};

export const betsSlipSetTicketOnline = (online) => (dispatch, getState) => {
  dispatch({
    type: betsSlipConstants.SET_TICKET_ONLINE,
    online,
  });
};

export const betsSlipAddLottoTicket = (ticket) => ({
  type: betsSlipConstants.LOTTO_ADD_TICKET,
  ticket,
});

export const betsSlipClearLottoTicket = () => ({
  type: betsSlipConstants.LOTTO_CLEAR_TICKET,
});

export const betSlipLottoTicketCreate = () => ({
  type: betsSlipConstants.LOTTO_TICKET_CREATE,
});

export const betSlipLottoTicketCreateSuccess = () => ({
  type: betsSlipConstants.LOTTO_TICKET_CREATE_SUCCESS,
});

export const betsSlipLottoTicketCreateManualAuth = () => ({
  type: betsSlipConstants.LOTTO_TICKET_CREATE_MANUAL_AUTH,
});

export const betSlipLottoTicketCreateError = (error) => ({
  type: betsSlipConstants.LOTTO_TICKET_CREATE_ERROR,
  error,
});

export const betSlipLottoInitializeTickets = ({ event, tickets }) => ({
  type: betsSlipConstants.LOTTO_TICKETS_INITIALIZE,
  event,
  tickets,
});

export const betSlipLottoProcessNextTicket = () => ({
  type: betsSlipConstants.LOTTO_TICKETS_PROCESS_NEXT_TICKET,
});

export const betSlipLottoTicketProcessingResult = (ticket) => ({
  type: betsSlipConstants.LOTTO_TICKETS_PROCESSING_RESULT,
  ticket,
});

export const betSlipLottoTicketProcessingError = (err) => ({
  type: betsSlipConstants.LOTTO_TICKETS_PROCESSING_ERROR,
  err,
});

export const betSlipLottoTicketCheckAuthorization = (ticket_id) => ({
  type: betsSlipConstants.LOTTO_TICKETS_CHECK_AUTHORIZATION,
  ticket_id,
});

export const betSlipLottoIncrementCurrentIndex = () => ({
  type: betsSlipConstants.LOTTO_TICKETS_INCREMENT_CURRENT_INDEX,
});

export const betSlipLottoSetFreeBetIndex = (index, freeBet) => ({
  type: betsSlipConstants.FREE_BET_SELECTED_INDEX,
  index: index,
  freeBet: freeBet,
});
export const betSlipLottoFreeBetClear = () => ({
  type: betsSlipConstants.FREE_BET_SELECTED_INDEX,
  index: -1,
  freeBet: null,
});

export const betSlipLottoBonusEvaluateResponse = (bonusEvaluate) => ({
  type: betsSlipConstants.BONUS_EVALUATE_RESPONSE,
  bonusEvaluate,
});

export const betSlipLottoBonusRequestEvaluation = () => (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: betsSlipConstants.REQUEST_BONUS_EVALUATION,
    wallet: state.wallet,
    profile: state.profile,
    authentication: state.authentication,
  });
};
