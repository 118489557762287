import moment from 'moment';

const months = {
  '1': 'Ian',
  '2': 'Feb',
  '3': 'Mart',
  '4': 'Apr',
  '5': 'Mai',
  '6': 'Iun',
  '7': 'Iul',
  '8': 'Aug',
  '9': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec'
};

export const formatDate = (date, lang) => {
  let refDate = moment();
  let tomorrow = moment().add(1, 'days');
  let dt = moment(date);

  if (refDate.isSame(dt, 'day')) {
    return `Azi ${dt.format('HH:mm')}`;
  }
  if (tomorrow.isSame(dt, 'day')) {
    return `Maine ${dt.format('HH:mm')}`;
  }

  if (lang === 'ro') {
    return `${dt.format('ddd, D ')}${months[dt.format('M')].toUpperCase().replace('.', '')}`;
  }
  return `${dt.format('ddd, ')}${dt
    .format('MMM')
    .toUpperCase()
    .replace('.', '')}${dt.format(' D')}`;
};

export const formatCurrency = currency => {
  if (currency && currency.toLowerCase() === 'ron') return 'Lei';

  if (currency === 'LEI') return 'Lei';

  return currency;
};

export const formatAmount = (amount, decimals = false, currency = null, forceDecimals = false) => {
  let value = Number(amount).toFixed(2);
  let locales = 'ro-RO';
  let type = {};
  let minimumFractionDigits = 0;
  let maximumFractionDigits = 2;
  let formattedCurrency = null;

  if (!decimals) {
    value = Math.floor(value);
    maximumFractionDigits = 0;
  }

  if (forceDecimals && value.length < 6) {
    minimumFractionDigits = 2;
  }

  if (currency) {
    formattedCurrency = formatCurrency(currency);
    type.style = 'currency';
    type.currency = formattedCurrency;
  }

  type.minimumFractionDigits = minimumFractionDigits;
  type.maximumFractionDigits = maximumFractionDigits;

  let formattedAmount = new Intl.NumberFormat(locales, type).format(value);
  if (formattedCurrency) {
    formattedAmount = formattedAmount.replace(formattedCurrency.toUpperCase(), formattedCurrency);
  }

  return formattedAmount;
};

export const formatTotalOddsValue = value => {
  let v = parseFloat(value);
  let suffix = '';

  if (value < 1000) {
    v = v.toFixed(2);
  } else if (value < 10000) {
    v = v.toFixed(1);
  } else if (value < 100000) {
    v = v.toFixed(0);
  } else if (value < 1000000) {
    v = (v / 1000).toFixed(2);
    suffix = 'k';
  } else {
    v = (v / 1000000).toFixed(2);
    suffix = 'm';
  }

  v = v.toString();

  return v + suffix;
};

export const formatAmount2 = value => {
  let v = parseFloat(value);

  if (value < 1000) {
    v = v.toFixed(2);
  } else if (value < 10000) {
    v = v.toFixed(1);
  } else {
    v = v.toFixed(0);
  }

  return v.toString();
};

export const formatAmountWithDecimals = (amount, decimals = false, forceDecimals = false) => {
  let value = Number(amount).toFixed(2);
  let locales = 'ro-RO';
  let type = {};
  let minimumFractionDigits = 0;
  let maximumFractionDigits = 2;

  if (!decimals) {
    value = Math.floor(value);
    maximumFractionDigits = 0;
  }

  if (forceDecimals) {
    minimumFractionDigits = 2;
  }

  type.minimumFractionDigits = minimumFractionDigits;
  type.maximumFractionDigits = maximumFractionDigits;

  let formattedAmount = new Intl.NumberFormat(locales, type).format(value);
  return formattedAmount;
};
