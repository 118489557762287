import { liveConstants } from './constants';

export const liveSetMiniState = data => ({
	type: liveConstants.SET_MINI_STATE,
	data
});

export const liveInitialize = () => ({
	type: liveConstants.INITIALIZE
});

export const liveSetState = data => ({
	type: liveConstants.SET_STATE,
	data
});

export const liveSetOdds = data => ({
	type: liveConstants.SET_ODDS,
	data
});

export const liveSetCurrentMatches = data => ({
	type: liveConstants.SET_CURRENT_MATCHES,
	data
});

export const liveSetCards = data => ({
	type: liveConstants.SET_CARDS,
	data
});

export const liveSetMatchBettingStatus = data => ({
	type: liveConstants.SET_MATCH_BETTING_STATUS,
	data
});

export const liveAddMatches = data => ({
	type: liveConstants.ADD_MATCHES,
	data
});

export const liveRemoveMatches = data => ({
	type: liveConstants.REMOVE_MATCHES,
	data
});

export const liveMergeState = () => ({
	type: liveConstants.MERGE_STATE
});

export const liveClearMergeState = () => ({
	type: liveConstants.CLEAR_MERGE_STATE
});

export const livePlayerLogin = uuid => ({
	type: liveConstants.PLAYER_LOGIN,
	uuid
});

export const livePlayerLogout = () => ({
	type: liveConstants.PLAYER_LOGOUT
});

export const liveCreateTicketRequest = ({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable }) => ({
	type: liveConstants.CREATE_TICKET_REQUEST,
	free_bet_code,
	free_bet_name,
	free_bet_pid,
	free_bet_redeemable
});

export const liveUpdateSport = data => ({
	type: liveConstants.UPDATE_SPORT,
	data
});

export const liveUpdateBet = data => ({
	type: liveConstants.UPDATE_BET,
	data
});

export const liveUpdateCategory = data => ({
	type: liveConstants.UPDATE_CATEGORY,
	data
});

export const liveUpdateTournament = data => ({
	type: liveConstants.UPDATE_TOURNAMENT,
	data
});

export const liveUpdateTeam = data => ({
	type: liveConstants.UPDATE_TEAM,
	data
});

export const liveUpdateMatch = data => ({
	type: liveConstants.UPDATE_TEAM,
	data
});

export const liveRemoveTournament = data => ({
	type: liveConstants.REMOVE_TOURNAMENT,
	data
});

export const liveRemoveCategory = data => ({
	type: liveConstants.REMOVE_CATEGORY,
	data
});

export const liveBetMeta = data => ({
	type: liveConstants.BET_META,
	data
});

export const liveFetchMatch = idMatch => ({
	type: liveConstants.FETCH_MATCH,
	idMatch
});

export const liveFetchMatches = (idMatch, idRequest, marketCount) => ({
	type: liveConstants.FETCH_MATCHES,
	idMatch,
	idRequest,
	marketCount,
});

export const liveSetMatch = data => ({
	type: liveConstants.SET_MATCH,
	data
});

export const liveSetMatches = (data, idRequest) => ({
	type: liveConstants.SET_MATCHES,
	data,
	idRequest
});

