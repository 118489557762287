import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { useAppSelector } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { useMediaQuery } from '../../../../utils/useQueryMedia';

type PopupBetslip = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const PopupBetslip = (componentProps: PopupBetslip) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const noBalls = useAppSelector((state) => {
    return state.lotto.betsSlip.lottoTicket?.numbers?.length ?? 0;
  });
  const [fullBetslip, setFullBetslip] = React.useState(false);
  const isDesktop = useMediaQuery('(min-width:900px)');
  const matches = useMatches();

  const onToggleFullBetslip = () => {
    setFullBetslip((v) => {
      if (!v) {
        document.body.style.overflow = 'hidden';
      } else if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = '';
      }
      return !v;
    });
  };

  const onNotifyOpen = (willOpen: boolean) => {
    if (willOpen) {
      setFullBetslip(true);
      document.body.style.overflow = 'hidden';
    }
  };

  const onNotifyClose = (willClose: boolean) => {
    if (willClose) {
      setFullBetslip(false);
      document.body.style.overflow = '';
    }
  };

  React.useEffect(() => {
    if (fullBetslip && noBalls === 0) {
      setFullBetslip(false);
      document.body.style.overflow = '';
    }
  }, [noBalls, fullBetslip]);

  const contextValue = {
    noBalls: noBalls,
    fullBetslip: fullBetslip,
    onToggleFullBetslip: onToggleFullBetslip,
    onNotifyOpen,
    onNotifyClose,
  };

  //console.log('PopupBetslip[contextValue]', contextValue);

  if (isDesktop) return null;

  if (matches?.length > 0) {
    const path = matches[matches.length - 1].pathname;

    let hide = true;
    if (path.indexOf('/lotto') === 0) {
      hide = false;
    }

    if (hide) {
      return null;
    }
  }

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default PopupBetslip;
