import i18n from '@/utils/i18n';
import evBusMain from '@/utils/evbus';

const cordovaRedirect = (iframeLink, loadedUrl) => {
  let loadedUrlTmp = loadedUrl;

  if (window.config.cordova === true && loadedUrlTmp !== iframeLink) {
    const closeButtonCaption = i18n.t('Close');

    let depositWindow = window.cordova.InAppBrowser.open(iframeLink, '_blank', `location=no,closebuttoncaption=${closeButtonCaption}`);

    loadedUrlTmp = iframeLink;

    const onLoadStart = (params) => {
      console.log('InAppBrowser[onLoadStart] Url: ', params.url);
      const url = new URL(params.url);
      const pathAndQuery = url.pathname + url.search;

      let isApprovedDomain = false;
      let domain = window.config.front_url.replace('^https', '').replace('^http', '');

      if (params.url.indexOf(domain) !== -1) {
        isApprovedDomain = true;
      }

      if (isApprovedDomain) {
        if (
          params.url.indexOf('/deposit/success') > -1 ||
          params.url.indexOf('/deposit/pending') > -1 ||
          params.url.indexOf('/deposit/error') > -1 ||
          params.url.indexOf('/deposit/final-step') > -1
        ) {
          onExit();
          evBusMain.emit('route-change', { url: pathAndQuery });
        } else {
          onExit();
        }
      }
    };

    const onExit = () => {
      console.log('InAppBrowser[exit] remove events listener');
      depositWindow.removeEventListener('loadstart', onLoadStart);
      depositWindow.removeEventListener('exit', onExit);
      depositWindow.close();
      depositWindow = null;
    };

    depositWindow.addEventListener('exit', onExit);
    depositWindow.addEventListener('loadstart', onLoadStart);
  }

  return loadedUrlTmp;
};
export default cordovaRedirect;