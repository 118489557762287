import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

import { changeCurrency } from '../../../store/slices/currencies';
import { requestWallet } from '@/modules/casino/store/actions/wallet';

import './index.scss';

type CurrencySelectorProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  isOpen: boolean;
  currency: string;
  currencies: string[];
};

type CurrenciesProps = {
  id: string;
  isSelected: boolean;
  onChangeCurrency: any;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const CurrencySelector = (componentProps: CurrencySelectorProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const currentCurrency = useAppSelector((state) => state.currencies.currentCurrency);
  const currencies = useAppSelector((state) => state.currencies.currenciesList);

  const initialState = {
    currency: currentCurrency,
    currencies: currencies,
    isOpen: false,
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const onChangeCurrency = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.currency) {
      updateCurrency(e.currentTarget.dataset.currency);
    }
  };

  const onToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setState((v) => ({
      ...v,
      isOpen: !v.isOpen,
    }));
  };

  const getSelected = (id: string) => {
    const newCurrencies: CurrenciesProps[] = [];
    for (const c of state.currencies) {
      newCurrencies.push({
        id: c,
        isSelected: c === id,
        onChangeCurrency,
      });
    }
    return newCurrencies;
  };

  const updateCurrency = (newCurrency: string) => {
    dispatch(changeCurrency({ currency: newCurrency }));
    dispatch(requestWallet());
    setState((pS) => ({
      ...pS,
      currency: newCurrency,
      isOpen: false,
    }));
  };

  const storeCurrency = currentCurrency ? currentCurrency : state.currency;
  /*
  React.useEffect(() => {
    storeCurrency = currentCurrency ? currentCurrency : state.currency;
  }, [updateCurrency]);
  */

  const contextValue = React.useMemo(() => {
    return {
      currentCurrency: storeCurrency,
      currencies: getSelected(storeCurrency),
      isOpen: state.isOpen,
      onToggle: onToggle,
      onChangeCurrency: onChangeCurrency,
    };
  }, [storeCurrency, state.isOpen]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default CurrencySelector;
