import { takeEvery, put, delay } from 'redux-saga/effects';
import * as actionTypes from '../../../casino/store/actions/actionTypes';
import { loadedTournamentsGroups, resetLoadedTournamentsGroups } from '../actions/tournaments_groups';
import { tournamentGroupsConstants } from '../actions/constants';
import getStore from '../../store';
import axios, { CancelToken, isCancel } from 'axios';
import { uuidv4 } from '../../../bets/utils';

//let apiUrl = window.config.betsApiUrl + "/casinoapi/echo-service";
let apiUrl = window.config.betsApiUrl + '/missions';

let last_sent = {
  timestamp: 0,
  authentication: false,
  reqId: '',
};
let cancelSource = null;

function* loadTournamentsGroupsSaga() {
  const { authentication } = getStore().getState();

  const headers = {};
  if (authentication.access_token) {
    headers['Authorization'] = 'Bearer ' + authentication.access_token;
  } else {
    return;
  }
  let withAuthentication = false;

  if (authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1) {

    withAuthentication = true;

    if (!last_sent.authentication) {
      last_sent = {
        timestamp: 0,
        authentication: true,
      };
      try {
        if (cancelSource) cancelSource.cancel();
      } catch (err) {
        /*noop*/
      }
    }
  } else {
    if (last_sent.authentication) {
      last_sent = {
        timestamp: 0,
        authentication: false,
      };
      try {
        if (cancelSource) cancelSource.cancel();
      } catch (err) {
        /*noop*/
      }
    }
  }

  const now = +new Date();
  if (last_sent.timestamp && now - last_sent.timestamp < 60 * 1000) return;

  last_sent.timestamp = now;
  const reqId = uuidv4();
  last_sent.reqId = reqId;

  try {
    cancelSource = CancelToken.source();
    const resp = yield axios.get(apiUrl + '/tournaments/list?tenant_id=' + window.config.clientId, {
      headers,
      cancelToken: cancelSource.token,
    });

    cancelSource = null;

    yield put(loadedTournamentsGroups(resp.data, withAuthentication));
  } catch (err) {
    if (isCancel(err)) {
      console.log('loadTournamentsGroupsSaga[ERR]: request canceled', err);
    } else {
      console.log('loadTournamentsGroupsSaga[ERR]:', err);
    }
  }
}

function* resetTournamentDataSaga() {
  last_sent.timestamp = 0;
  yield put(resetLoadedTournamentsGroups());
  yield loadTournamentsGroupsSaga();
}

export default function* watchWheelSaga() {
  yield takeEvery(actionTypes.application.INITIALIZE, loadTournamentsGroupsSaga);
  yield takeEvery(actionTypes.application.REINITIALIZE, loadTournamentsGroupsSaga);
  yield takeEvery(tournamentGroupsConstants.LOAD, loadTournamentsGroupsSaga);
  yield takeEvery(actionTypes.profile.RECEIVED_ACCOUNT, resetTournamentDataSaga);
  yield takeEvery(actionTypes.authentication.CLEAR, resetTournamentDataSaga);
}
