import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { withTranslation } from "react-i18next";
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import "./ReferralPopup.scss";
import YouCollectedLogo from "./assets/you-collected-logo.png";
import { copyInputText } from "../../utils/functions";
import Facebook from "./assets/icon-facebook@3x.png";
import FBMessenger from "./assets/icon-fb-messenger@3x.png";
import WhatsApp from "./assets/icon-whatsapp@3x.png";
import Twitter from "./assets/icon-twitter@3x.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReferralPopup = props => {
  const { open, handleClose, link, t } = props;

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  });

  const handleCopy = () => {
    const el = document.getElementById("referral-link-input-2");
    if (el) {
      copyInputText(el);
    }
  };

  const openLink = (url) => (e) => {
    if (window.config.cordova) {
      try {
        e.stopPropagation();
        e.preventDefault();
      } catch (err) { }
      window.cordova.InAppBrowser.open(url, '_system');
    }
  };

  if (!open) return null;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winter-fun-modal-referral-popup betsModal betsModalDialog`}
    >
      <DialogContent className="wf-dialog-content">
        <img src={YouCollectedLogo} className="wf-header-icon" alt="winner fun logo" />

        <div className="wf-title">{t("Invite a friend with the link below")}</div>

        <div className="wf-input-body">
          <div className="wf-text">{t("Copy and send this link on Whatsapp, Facebook, Instagram, Messenger or by mail")}:</div>
          <div className="wf-input">
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">{t("Link")}</InputLabel>
              <OutlinedInput
                id="referral-link-input-2"
                type='text'
                value={link}
                multiline={true}
                rows={4}
                rowsMax={4}
                readOnly
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="copy button"
                      onClick={handleCopy}
                      edge="end"
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </div>
          <div className="wf-icons">
            <a href={`whatsapp://send?text=${encodeURIComponent(link)}`}
              onClick={openLink(`whatsapp://send?text=${encodeURIComponent(link)}`)}
              className="wf-icon" target="_blank" rel="noopener noreferrer">
              <img src={WhatsApp} alt="" />
            </a>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`}
              onClick={openLink(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`)}
              className="wf-icon" target="_blank" rel="noopener noreferrer">
              <img src={Facebook} alt="" />
            </a>
            <a href={`fb-messenger://share/?link=${encodeURIComponent(link)}&app_id=388367355791495`}
              onClick={openLink(`fb-messenger://share/?link=${encodeURIComponent(link)}&app_id=388367355791495`)}
              className="wf-icon" target="_blank" rel="noopener noreferrer">
              <img src={FBMessenger} alt="" />
            </a>
            <a href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}&text=`}
              onClick={openLink(`https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}&text=`)}
              className="wf-icon" target="_blank" rel="noopener noreferrer">
              <img src={Twitter} alt="" />
            </a>
          </div>
        </div>

        <div className="wf-button-wrapper">
          <ButtonBase className="wf-button" onClick={handleClose}>
            {t("Close")}
          </ButtonBase>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(ReferralPopup);
