import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import TextTransition from './lib/text-transition';
import { config as presets } from 'react-spring';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    texts: [],
    delay: 6000,
    config: 'default',
    award: false,
    awardSrc: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    'data-extradelayatindex': -1,
    'data-extradelaytime': 0,
    'data-extratimes': [
      {
        index: -1,
        extraDelay: 0,
      },
    ],
  },
};

const TextsAnimationDiv = styled.div((props) => props.$styleText);

export const TextsAnimation = (componentProps) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  let onClick = null;
  if (typeof props?.properties?.onClick === 'function') {
    onClick = props.properties.onClick;
  }

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  const [index, setIndex] = React.useState(0);

  let texts = [];
  if (props?.properties?.texts) texts = props.properties.texts;

  React.useEffect(() => {
    let intervalId = 0;
    /*
    if (isVisible) {
      if (extraDataProps && extraDataProps['data-extradelaytime'] && extraDataProps['data-extradelayatindex'] != null) {
        if (extraDataProps['data-extradelayatindex'] === index % texts.length) {
          intervalId = setInterval(
            () => {
              setIndex((index) => index + 1);
            },

            parseInt(props.properties.delay) + parseInt(extraDataProps['data-extradelaytime']),
          );
        } else {
          intervalId = setInterval(
            () => {
              setIndex((index) => index + 1);
            },
            props.properties.delay ? props.properties.delay : defaultProps.properties.delay,
          );
        }
      } else {
        intervalId = setInterval(
          () => {
            setIndex((index) => index + 1);
          },
          props.properties.delay ? props.properties.delay : defaultProps.properties.delay,
        );
      }
    }
    */
    if (isVisible) {
      if (extraDataProps && extraDataProps['data-extratimes'] && Array.isArray(extraDataProps['data-extratimes'])) {
        let found = false;
        // let foundIndex = -1;
        let foundExtraDelay = 0;
        extraDataProps['data-extratimes'].forEach((extraTime) => {
          if (extraTime.index === index % texts.length) {
            found = true;
            // foundIndex = extraTime.index;
            foundExtraDelay = extraTime.extraDelay;
          }
        });

        if (found) {
          intervalId = setInterval(
            () => {
              setIndex((index) => index + 1);
            },
            parseInt(props.properties.delay) + parseInt(foundExtraDelay),
          );
        } else {
          intervalId = setInterval(
            () => {
              setIndex((index) => index + 1);
            },
            parseInt(props.properties.delay) ? parseInt(props.properties.delay) : defaultProps.properties.delay,
          );
        }
      } else {
        intervalId = setInterval(
          () => {
            setIndex((index) => index + 1);
          },
          parseInt(props.properties.delay) ? parseInt(props.properties.delay) : defaultProps.properties.delay,
        );
      }
    }

    return () => {
      if (isVisible) clearTimeout(intervalId);
    };
  }, [isVisible, props.properties.delay, texts, extraDataProps]);

  if (!isVisible) return null;

  // console.log('TextsAnimation', { extraDataProps, props, index, isVisible });

  return (
    <TextsAnimationDiv
      className={props.className ?? ''}
      $styleText={props.styleText}
      onClick={onClick}
      style={props.style}
      {...extraDataProps}
    >
      {texts.length !== 0 && (
        <TextTransition
          text={texts[index % texts.length]}
          springConfig={presets[props.properties.config]}
          award={props.properties.award}
          awardSrc={props.properties.awardSrc}
          isHtml={true}
        />
      )}
    </TextsAnimationDiv>
  );
};
