// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';

type FetchConfigProps = {
  tenantId: string;
  siteId: string;
};

type FetchConfigResult = {
  data: any[];
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

export const fetchAllGamesConfig = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'allGames/list',
  async ({ tenantId, siteId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(endpoints.config.games + tenantId, {
        params: { platformType: window.config.platformType, tenantId, siteId },
      });

      if (response.data) {
        return { data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch games config",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface ConfigReducer {
  items: any[];
  loading: boolean;
  error: any;
}

export const allGamesSlice = createSlice({
  name: 'allGames',
  initialState: <ConfigReducer>{
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    reset: (_state) => {
      /* example */
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGamesConfig.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          state.items = action.payload.data;
        }
        console.log('fetchAllGamesConfig.fulfilled', action.payload);
      })
      .addCase(fetchAllGamesConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllGamesConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        console.log('fetchAllGamesConfig.rejected', action.payload);
      });
  },
});

export const { reset } = allGamesSlice.actions;

export default allGamesSlice.reducer;
