import { authConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
	details: null,
	live: null,
	prematch: null,
	winnerFunDetails: null,
};

const authReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case authConstants.SUCCESS: {
				draft.details = action.details;
				break;
			}
			case authConstants.WINNER_FUN_SUCCESS: {
				draft.winnerFunDetails = action.details;
				break;
			}
			case authConstants.LIVE_SUCCESS: {
				draft.live = action.details;
				break;
			}
			case authConstants.PREMATCH_SUCCESS: {
				draft.prematch = action.details;
				break;
			}
			case authConstants.CLEAR: {
				draft.details = null;
				draft.winnerFunDetails = null;
				break;
			}
		}
	});

export default authReducer;
