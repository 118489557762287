import React from 'react';

const useCountUp = (props) => {
  const { begin, start, end, duration, delay } = props;

  const [state, setState] = React.useState({
    start: start,
    end: end,
    duration: duration,
    delay: delay,
    current: start,
  });

  React.useEffect(() => {
    let startTime;
    let rafId;
    let elapsed;
    let progress;
    let current;

    const step = (timestamp) => {
      if (!startTime) {
        startTime = timestamp;
      }

      elapsed = timestamp - startTime;
      progress = Math.min(elapsed / duration, 1);
      current = Math.floor(progress * (end - start) + start);

      setState((prevState) => ({
        ...prevState,
        current: current,
      }));

      if (progress < 1) {
        rafId = requestAnimationFrame(step);
      }
    };

    if (begin) {
      if (state.current < end) {
        if (delay > 0) {
          setTimeout(() => {
            rafId = requestAnimationFrame(step);
          }, delay);
        } else {
          rafId = requestAnimationFrame(step);
        }
      }
    }

    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [begin]); // eslint-disable-line

  React.useEffect(() => {
    if (!begin) {
      setState({
        start: start,
        end: end,
        duration: duration,
        delay: delay,
        current: start,
      });
    }
  }, [begin, start, end, duration, delay]);

  return state.current;
};

export default useCountUp;
