import React from 'react';
import { PageDataContext, getProjectDataFromContext } from '../utils/PageDataProvider';
import { useAppSelector } from '../../store';
import craftJsParser from '../utils/craftJsParser';

type LinkedElementProps = {
  refType: string;
  refId: string;
  linkedId: string;
  nodeId: string;
};

const LinkedElement = (props: LinkedElementProps) => {
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user') as boolean,
  );

  const { refType, refId, linkedId, nodeId } = props;
  const pageDataContext = React.useContext(PageDataContext);

  let content = null;

  try {
    const data = getProjectDataFromContext(pageDataContext);
    const rootNodeId = data[nodeId].linkedNodes[linkedId];

    if (rootNodeId) {
      content = craftJsParser({
        craftState: data,
        rootNodeId: rootNodeId,
        pageId: refId,
        pageType: refType,
        options: {
          authenticated,
        },
      });
    }
  } catch (err) {
    /**/
  }
  return content;
};

export default LinkedElement;
