import { createSelector } from 'reselect';

export const getLottoState = (state) => state.lotto;
export const getBonuses = (state) => getLottoState(state).bonuses.items;
export const getSystems = (state) => getLottoState(state).lottoEvents.systems;
export const getEvents = (state) => getLottoState(state).lottoEvents.items;
export const getExpiredEvents = (state) => getLottoState(state).lottoEvents.expiredItems;

const emptySystems = {};

export const getSystemsBonuses = createSelector([getBonuses, getSystems], (bonuses, systems) => {
  if (!bonuses || bonuses.length === 0) {
    return emptySystems;
  }

  const existsSystems = {};

  systems.forEach((s) => {
    existsSystems[s.system_id] = true;
  });

  const bonusesHash = {};

  const bonusesTypes = {
    6: 'lotto_lucky_number',
    7: 'lotto_chance',
  };

  bonuses.forEach((b) => {
    b.lottery_systems.forEach((s) => {
      if (typeof existsSystems[s] !== 'undefined') {
        if (typeof bonusesHash[s] === 'undefined') bonusesHash[s] = {};
        bonusesHash[s][bonusesTypes[b.bonus_type]] = JSON.parse(JSON.stringify(b));
      }
    });
  });

  return bonusesHash;
});

const getEventId = (state, props) => {
  if (props && props.match && props.match.params && props.match.params.id) {
    return props.match.params.id;
  }

  if (props && props.event_id) {
    return props.event_id;
  }

  return '';
};

const getSystemId = (state, props) => {
  if (props && props.match && props.match.params && props.match.params.systemId) {
    return props.match.params.systemId;
  }

  return '';
};

export const makeGetBonuses = () =>
  createSelector(
    [getSystemsBonuses, getEvents, getExpiredEvents, getSystemId, getEventId],
    (bonuses, events, expiredEvents, systemId, eventId) => {
      if (!eventId) {
        return emptySystems;
      }

      if (typeof bonuses[systemId] !== 'undefined') return bonuses[systemId];

      for (let i = 0; i < events.length; i++) {
        if (events[i].event_id === eventId) {
          if (typeof bonuses[events[i].system_id] !== 'undefined') return bonuses[events[i].system_id];
        }
      }

      for (let i = 0; i < expiredEvents.length; i++) {
        if (expiredEvents[i].event_id === eventId) {
          if (typeof bonuses[expiredEvents[i].system_id] !== 'undefined') return bonuses[expiredEvents[i].system_id];
        }
      }

      return emptySystems;
    },
  );
