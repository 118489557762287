// key === idSport:
// internal sport identification code (used currently duplicated as map key)

// sportName: 
// (long) sport name

// intervalFormat: 
// format interval display as - letter, minute, score(points)
const FormatLetter = 'letter';    // default formatting (round letter+nr or special status letters)
const FormatMinute = 'minute';    // minutes in addition to default formatting
const FormatScore = 'score';      // additional formatting based on score criteria (currently very minor)

// intervalCountType:
// type of number of intervals - fixed, variable, or by win points
const CountFixed = 'fixed';       // fixed count and round time (overtime possible)
// const CountVariable = "variable"; // variable count, fixed round time (overtime possible)
const CountPoints = 'points';     // variable count and/or round time

// intervalLetter:
// display prefix letter to use for intervals (NOT ignored even if intervalFormat=="minutes")
// example values: "R" - repriza, runda; "S" - sfert, set; "J" - joc; "M" - meci/harta(map)
// AVOID VALUES: "P" (Pauza), "F" (Final); also avoid "NS" (Not Started), "ST" (Stopped)

// intervalCountDefault:
// (default) number of intervals per match (normally irrelevant if intervalCountType=="lookup" but stll used)

// intervalCountLookup:
// location of number of intervals per match in match.extraInfo._?_ (irrelevant if intervalCountType=="fixed")

// intervalLengthDefault:
// (default) interval length in minutes/points/etc

// intervalLengthLookup:
// location of interval length in minutes/points/etc in match.extraInfo._?_

// overtimeLengthDefault:
// (default) overtime length in minutes

// overtimeLengthLookup:
// location of overtime length in match.extraInfo._?_

// REDUNDANT by multi-use of intervalLengthDefault, in process of removing entirely
// intervalPointsWinAmount:
// number of points needed to win an interval
// (only relevant for score/points-based win criteria sports)

// intervalPointsWinDifference:
// minimum difference in number of points needed to win an interval
// (only relevant for some score/points-based win criteria sports)

// matchIntervalWinsMinAmount:
// number of won intervals needed to win a match
// (only relevant for some score/points-based win criteria sports)

// NOT (YET) used anywhere... candidate for removal?
// matchIntervalWinsMinDifference:
// minimum difference in number of won intervals needed to win a match
// (only relevant for some score/points-based win criteria sports)


// main sport display setup map
export const scoreDisplayDef = new Map([
  ['1', {
    'idSport': '1',
    'sportName': 'Soccer',
    'intervalFormat': FormatMinute,
    'intervalCountType': CountFixed,
    'intervalLetter': 'R', // Repriza
    'intervalCountDefault': 2,
    'intervalCountLookup': '',
    'intervalLengthDefault': 45,
    'intervalLengthLookup': 'PeriodLength',
    'overtimeLengthDefault': 15,
    'overtimeLengthLookup': 'OvertimeLength',
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['4', {
    'idSport': '4',
    'sportName': 'Basketball',
    'intervalFormat': FormatMinute,
    'intervalCountType': CountFixed,
    'intervalLetter': 'S', // Sfert
    'intervalCountDefault': 4,
    'intervalCountLookup': '',
    'intervalLengthDefault': 12,
    'intervalLengthLookup': 'PeriodLength',
    'overtimeLengthDefault': 5,
    'overtimeLengthLookup': 'OvertimeLength',
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['5', {
    'idSport': '5',
    'sportName': 'Baseball',
    'intervalFormat': FormatLetter,
    'intervalCountType': CountPoints,
    'intervalLetter': 'R', // check (posibilitati Inning -> Runda / Repriza / Mansa)
    'intervalCountDefault': 9,
    'intervalCountLookup': '',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': 5,
    'matchIntervalWinsMinDifference': 1,
  }],
  ['10', {
    'idSport': '10',
    'sportName': 'Ice Hockey',
    'intervalFormat': FormatMinute,
    'intervalCountType': CountFixed,
    'intervalLetter': 'R', // check
    'intervalCountDefault': 3,
    'intervalCountLookup': null,
    'intervalLengthDefault': 20,
    'intervalLengthLookup': 'PeriodLength',
    'overtimeLengthDefault': 5,
    'overtimeLengthLookup': 'OvertimeLength',
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['3', {
    'idSport': '3',
    'sportName': 'Tennis',
    'intervalFormat': FormatLetter,
    'intervalCountType': CountPoints,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 3,
    'intervalCountLookup': 'NumberOfSets',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": 6,
    'intervalPointsWinDifference': 2,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['13', {
    'idSport': '13', // no match live data, using googled info and best guesses
    'sportName': 'Handball',
    'intervalFormat': FormatMinute,
    'intervalCountType': CountFixed,
    'intervalLetter': 'R',
    'intervalCountDefault': 2,
    'intervalCountLookup': null,
    'intervalLengthDefault': 30,
    'intervalLengthLookup': 'PeriodLength', // best guess
    'overtimeLengthDefault': 5,
    'overtimeLengthLookup': 'OvertimeLength', // best guess
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['14', {
    'idSport': '14', // no match live data, using googled info and best guesses - UNCHECKED
    'sportName': 'Rugby',
    'intervalFormat': FormatLetter,
    'intervalCountType': CountFixed,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 2,
    'intervalCountLookup': '',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['39', {
    'idSport': '39', // no match live data, using googled info and best guesses - UNCHECKED
    'sportName': 'Aussie Rules',
    'intervalFormat': FormatLetter,
    'intervalCountType': CountFixed,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 2,
    'intervalCountLookup': '',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['6', {
    'idSport': '6', // no match live data, using googled info and best guesses - UNCHECKED
    'sportName': 'American Football',
    'intervalFormat': FormatLetter,
    'intervalCountType': CountFixed,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 4,
    'intervalCountLookup': '',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['19', {
    'idSport': '19',
    'sportName': 'Snooker',
    'intervalFormat': FormatScore,
    'intervalCountType': CountPoints,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 5,
    'intervalCountLookup': 'BestOfFrames',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": 147, // maximum normal break, NOT amount needed to win frame
    'intervalPointsWinDifference': 1,
    'matchIntervalWinsMinAmount': 2,
    'matchIntervalWinsMinDifference': 1,
  }],
  ['25', {
    'idSport': '25',
    'sportName': 'Table Tennis',
    'intervalFormat': FormatScore,
    'intervalCountType': CountPoints,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 5,
    'intervalCountLookup': 'NumberOfSets',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": 11,
    'intervalPointsWinDifference': 2,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['36', {
    'idSport': '36', // no match live data, using googled info and best guesses - UNCHECKED
    'sportName': 'Cricket',
    'intervalFormat': FormatLetter,
    'intervalCountType': CountFixed,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 2,
    'intervalCountLookup': '',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['20', {
    'idSport': '20', // no match live data, using googled info and best guesses - UNCHECKED
    'sportName': 'Darts',
    'intervalFormat': FormatScore,
    'intervalCountType': CountFixed,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 2,
    'intervalCountLookup': '',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['12', {
    'idSport': '12',
    'sportName': 'Volleyball',
    'intervalFormat': FormatScore,
    'intervalCountType': CountPoints,
    'intervalLetter': 'S', // unsure
    'intervalCountDefault': 5,
    'intervalCountLookup': 'NumberOfSets',
    'intervalLengthDefault': 25,
    'intervalLengthLookup': 'SetLimit',
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": 25,
    'intervalPointsWinDifference': 2,
    'matchIntervalWinsMinAmount': 3,
    'matchIntervalWinsMinDifference': 1,
  }],
  ['17', {
    'idSport': '17', // no match live data, using googled info and best guesses - UNCHECKED
    'sportName': 'Futsal',
    'intervalFormat': FormatLetter,
    'intervalCountType': CountFixed,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 2,
    'intervalCountLookup': '',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['29', {
    'idSport': '29',
    'sportName': 'Badminton',
    'intervalFormat': FormatScore,
    'intervalCountType': CountPoints,
    'intervalLetter': 'S', // unsure
    'intervalCountDefault': 3,
    'intervalCountLookup': 'NumberOfSets',
    'intervalLengthDefault': 21,
    'intervalLengthLookup': 'SetLimit',
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": 21,
    'intervalPointsWinDifference': 2, // EXCEPTION: first to 30 wins even with 1 point difference
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['31', {
    'idSport': '31', // no match live data, using googled info and best guesses - UNCHECKED
    'sportName': 'Bowls',
    'intervalFormat': FormatLetter,
    'intervalCountType': CountFixed,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 2,
    'intervalCountLookup': '',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['26', {
    'idSport': '26', // no match live data, using googled + regular volley based guesses
    'sportName': 'Beach Volley',
    'intervalFormat': FormatScore,
    'intervalCountType': CountPoints,
    'intervalLetter': 'S', // unsure
    'intervalCountDefault': 3,
    'intervalCountLookup': 'NumberOfSets',
    'intervalLengthDefault': 21,
    'intervalLengthLookup': 'SetLimit',
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": 21,
    'intervalPointsWinDifference': 2,
    'matchIntervalWinsMinAmount': 2,
    'matchIntervalWinsMinDifference': 1,
  }],
  ['32', {
    'idSport': '32', // no match live data, using googled info and best guesses - UNCHECKED
    'sportName': 'Squash',
    'intervalFormat': FormatLetter,
    'intervalCountType': CountFixed,
    'intervalLetter': 'S', // TODO
    'intervalCountDefault': 2,
    'intervalCountLookup': '',
    'intervalLengthDefault': null,
    'intervalLengthLookup': null,
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['53', {
    'idSport': '53',
    'sportName': 'Counter-Strike',
    'intervalFormat': FormatScore,
    'intervalCountType': CountPoints,
    'intervalLetter': 'M', // Map in loc de Harta? check if ok...
    'intervalCountDefault': 3,
    'intervalCountLookup': 'Best of maps',
    'intervalLengthDefault': null,
    'intervalLengthLookup': 'Rounds per map',
    'overtimeLengthDefault': null,
    'overtimeLengthLookup': null,
    // "intervalPointsWinAmount": 2,
    'intervalPointsWinDifference': 1,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
  ['9999', {
    'idSport': '9999', // end placeholder
    'sportName': 'Default(undefined)', // arbitrary name
    'intervalFormat': FormatMinute, // arbitrary default
    'intervalCountType': CountFixed, // arbitrary default
    'intervalLetter': 'S', // best guess, assume it means "Set"
    'intervalCountDefault': 2, // arbitrary default
    'intervalCountLookup': '', // best guess
    'intervalLengthDefault': 30, // arbitrary default
    'intervalLengthLookup': 'PeriodLength', // best guess
    'overtimeLengthDefault': 5, // arbitrary default
    'overtimeLengthLookup': 'OvertimeLength', // best guess
    // "intervalPointsWinAmount": null,
    'intervalPointsWinDifference': null,
    'matchIntervalWinsMinAmount': null,
    'matchIntervalWinsMinDifference': null,
  }],
]);

// export default scoreDisplayDef; // no, import manually
