import { fork, put, take } from 'redux-saga/effects';
import ClientAPI from '../../../ClientAPI/ClientAPI';
import { beginRequest } from '../../actions/requestManager';
import Utils from '../../../utils/Utils';

export const takeEveryWithCooldown = (pattern, saga, ...args) =>
  fork(function* () {
    const defaultCooldown = yield ClientAPI.getStore().getState().requestManager.requests.cooldown;
    let token = false;
    while (true) {
      if (!token) {
        yield Utils.getAxiosWithToken(); // wait for token
        token = true;
      }

      const action = yield take(pattern);
      if (action.cooldown) {
        // use reducer to have access in app
        // this help us to manage it from the app too.
        // we can reset requestManager reducer (full or partially)
        let requestList = yield ClientAPI.getStore().getState().requestManager.requests;

        if (typeof requestList[pattern] !== 'undefined') {
          let timestamp = parseInt(new Date().getTime() / 1000, 10);
          let ttl = parseInt(action.cooldown, 10);
          if (action.cooldown === true || ttl < 5) {
            // if cooldown is boolean, set it to default value
            ttl = defaultCooldown;
          }
          if (requestList[pattern].last_sent + ttl <= timestamp) {
            // mark request as started;
            yield put(beginRequest(pattern));
            yield fork(saga, ...args.concat(action));
          }
        } else {
          yield put(beginRequest(pattern));
          yield fork(saga, ...args.concat(action));
        }
      } else {
        yield fork(saga, ...args.concat(action));
      }
    }
  });
