const mediaQueries = [
  {
    widthMedia: '360px',
    indicator: '',
  },
  {
    widthMedia: '576px',
    indicator: 'SM',
  },
  {
    widthMedia: '768px',
    indicator: 'MD',
  },
  {
    widthMedia: '900px',
    indicator: '<900px',
  },
  {
    widthMedia: '992px',
    indicator: 'LG',
  },
  {
    widthMedia: '1200px',
    indicator: 'XL',
  },
  {
    widthMedia: '1400px',
    indicator: 'XXL',
  },
];

export default mediaQueries;
