import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';

//import './index.scss';

type TimeSpentPopupProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const TSP_KEY = 'timeSpentPopup';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const TimeSpentPopup = (componentProps: TimeSpentPopupProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const [popupActive, setPopupActive] = React.useState(false);

  React.useEffect(() => {
    ExpiringLocalStorage.set(TSP_KEY, 0);

    //Increment the time counter every minute.
    setInterval(TimeSpendHandler, 60000); // 1 minute
  }, []);

  const TimeSpendHandler = () => {
    let time = ExpiringLocalStorage.get(TSP_KEY);
    time = parseInt(time) + 1;
    if (time >= 60) {
      setPopupActive(true);
    } else {
      ExpiringLocalStorage.set(TSP_KEY, time);
    }
  };

  const closePopupHandler = () => {
    ExpiringLocalStorage.set(TSP_KEY, 0);
    setPopupActive(false);
  };

  const contextValue = React.useMemo(() => {
    return {
      popupActive: popupActive,
      closePopupHandler: closePopupHandler,
    };
  }, [popupActive, closePopupHandler]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default TimeSpentPopup;
