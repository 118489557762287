import axios from 'axios';
import * as actionTypes from './actionTypes';

export const startAppAuth = (step) => {
  if (!step) {
    step = 1;
  }
  return {
    type: actionTypes.authentication.START_APP_AUTH,
    step: step,
  };
};

export const authenticate = (accessToken, expiresIn, refreshToken, authType, tokenType, authDate) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.authentication.AUTHENTICATE,
      accessToken: accessToken,
      expiresIn: expiresIn,
      refreshToken: refreshToken,
      authType: authType,
      tokenType: tokenType,
      authDate: authDate,
    });
  };
};

// export const logout = () => {
//     return dispatch => {
//         console.log(`[AUTH] Logout action called`);
//     }
// };

export const checkAuthTimeout = (expirationTime) => {
  return {
    type: actionTypes.authentication.CHECK_TOKEN_EXPIRATION,
    expirationTime: expirationTime,
  };
};

export const refreshAuthToken = () => {
  return {
    type: actionTypes.authentication.REFRESH_AUTH_TOKEN,
  };
};

export const clearAuthentication = () => {
  return {
    type: actionTypes.authentication.CLEAR,
  };
};

export const openAccountProblemModal = (code, message = null) => {
  return {
    type: actionTypes.authentication.ACCOUNT_PROBLEM,
    show: true,
    code: code,
    message: message,
  };
};

export const closeAccountProblemModal = () => {
  return {
    type: actionTypes.authentication.ACCOUNT_PROBLEM,
    show: false,
    code: 0,
    message: null,
  };
};

export const getSportsbookToken = (authData, cb) => {
  const headers = { ...axios.defaults.headers.common };
  axios.defaults.headers.common = {};

  axios
    .get(window.config.appApiUrl + '/sportsbook-token', {
      headers: {
        Authorization: 'Bearer ' + authData.access_token,
      },
    })
    .then((res) => {
      cb(res.data);
    })
    .catch((e) => {
      // console.log(`[CATCHED ERROT] => ${e}`);
    })
    .finally(() => {
      axios.defaults.headers.common = headers;
    });
};

export const doLogin = (props) => () => {
  props.history.push('/login');
};
