import * as actionTypes from '../actions/actionTypes';
import { produce } from 'immer';

const initialState = {
  requestType: null,
  depositsWithdrawals: null,
  bonuses: null,
  spins: null,
  show: false,
  loading: false,
  showMore: false,
  taxes: [],
};

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.transactions.RECEIVED_TRANSACTIONS_HISTORY:
        draft.show = true;
        draft.loading = false;
        draft.requestType = action.transactions.requestType;

        switch (action.transactions.requestType) {
          case 1:
            draft.spins = action.transactions.result;
            //draft.depositsWithdrawals = null;
            //draft.bonuses = null;
            break;
          case 2:
            draft.depositsWithdrawals = action.transactions.result;
            //draft.bonuses = null;
            //draft.spins = null;
            break;
          case 3:
            ///draft.depositsWithdrawals = null;
            //draft.spins = null;

            const bonuses = [];
            try {
              Object.keys(action.transactions.result).forEach((currency) => {
                action.transactions.result[currency].bonus.forEach((bonus) => {
                  bonuses.push({
                    ...bonus,
                    currency,
                  });
                });
              });
            } catch (err) {
              console.error(err);
            }

            draft.bonuses = bonuses;
            break;
          default:
            draft.depositsWithdrawals = null;
            //draft.bonuses = null;
            //draft.spins = null;
            break;
        }
        break;
      case actionTypes.transactions.REQUEST_TRANSACTIONS_HISTORY:
        draft.show = false;
        draft.loading = true;
        draft.requestType = action.requestType;
        draft.showMore = action.showMore;
        break;
      case actionTypes.withdrawals.REQUEST_TAX_SERVICE:
        if (action.id) {
          draft.taxes[action.id] = false;
        }
        break;
      case actionTypes.withdrawals.RECEIVED_TAX_SERVICE:
        if (action.id) {
          draft.taxes[action.id] = action.taxAmount;
        }
        return state;
      default:
        break;
    }
  });

export default reducer;
