import { takeEvery, put } from 'redux-saga/effects';

import { searchDigitainApiByQuery } from '@/components/modules/general-search/lib/search-api';
import { prematchSetMatches } from '../../modules/bets/store/actions/prematch';
import store from '..';

function* fetchDigitainTournaments(action: any) {
  const language = store.getState().application.language;

  try {
    // @ts-ignore
    const res = yield searchDigitainApiByQuery({
      request: { language, limit: 100, tournament_id: action.tournaments },
    });

    const liveMatches: any[] = [];
    const prematchMatches: any[] = [];

    res?.data?.sports?.forEach((sport: any) => {
      sport?.events?.forEach((event: any) => {
        if (event.mType === 'live') {
          liveMatches.push(event);
        } else if (event.mType === 'prematch') {
          prematchMatches.push(event);
        }
      });
    });

    //console.log('fetchDigitainTournaments', liveMatches, prematchMatches);

    if (prematchMatches.length > 0) {
      yield put(prematchSetMatches(prematchMatches));
    }
  } catch (e) {}
}

export default function* watchDigitainSaga() {
  yield takeEvery('FETCH-DIGITAIN-TOURNAMENTS', fetchDigitainTournaments);
}
