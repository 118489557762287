import { templateConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  tmp: [],
};

const templateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case templateConstants.DEMO:
        draft.tmp = action.tmp;
        break;
      default:
      /* noop */
    }
  });

export default templateReducer;
