import { fastEqual } from '../../../../utils/fastDeepEqual';
import * as actionTypes from './../actions/actionTypes';
import { produce } from 'immer';

const initialState = {
  bonuses: [],
  main: 0,
  bonus: 0,
  total: 0,
  ringFence: 0,
  ringFences: [],
  currency: 'RON',
  cardBonus: null,
  loadingWallet: false,
  loadingRemoveBonus: false,
  bonusRemoved: null,
  walletManagement: [],
};

const reducer = (state = { ...initialState }, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.wallet.RESET_WALLET:
        // in order ti change the draft we need to return it instead of modifying "draft = " local variable which doesn't work
        return { ...initialState };
      case actionTypes.wallet.REQUEST_WALLET: {
        draft.loading = true;
        break;
      }
      case actionTypes.wallet.RECEIVED_WALLET:
        let cardBonus = null;
        let rawWallet = { ...action.wallet };
        if (typeof rawWallet[action.currentCurrency] === 'undefined') {
          rawWallet[action.currentCurrency] = { main: 0 };
        }

        let wallet = rawWallet[action.currentCurrency];

        let main = 0.0;
        let total = main;
        let bonus = main;
        let ringFence = main;

        let walletManagement = [];

        if (typeof wallet['main'] !== 'undefined') {
          main += parseFloat(wallet.main);
          total += main;
        }

        let bonuses = [];
        let totalBonusPerProducts = {};
        if (typeof wallet['bonus'] !== 'undefined' && wallet.bonus) {
          bonuses = wallet.bonus;

          wallet.bonus.forEach((bonusRow) => {
            let bAmount = bonusRow.amount ? bonusRow.amount : '0';
            if (!(1 === Number.parseInt(bonusRow.status, 10))) {
              return;
            }

            if (1 === Number.parseInt(bonusRow.type, 10)) {
              cardBonus = bonusRow;
            }
            if (typeof bonusRow['eligibleProducts'] !== 'undefined' && bonusRow.eligibleProducts) {
              bonusRow.eligibleProducts.forEach((eligibleProduct) => {
                let bonusAmount = bAmount;
                if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].bonusAmount) {
                  bonusAmount = parseFloat(walletManagement[eligibleProduct].bonusAmount) + parseFloat(bAmount);
                }

                let bProductAmount = 0;

                if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                  bProductAmount = parseFloat(walletManagement[eligibleProduct].productAmount) + parseFloat(bAmount);
                } else {
                  bProductAmount = bonusAmount;
                }
                walletManagement[eligibleProduct] = {
                  ...walletManagement[eligibleProduct],
                  bonusAmount: Number(parseFloat(bonusAmount).toFixed(2)),
                  productAmount: Number(parseFloat(bProductAmount).toFixed(2)),
                };
              });
            }
            bonus += Number.parseFloat(bAmount);
          });

          total += bonus;
        }

        let ringFences = [];
        if (typeof wallet['ringFence'] !== 'undefined' && wallet['ringFence']) {
          ringFences = wallet.ringFence;

          wallet['ringFence'].forEach((ringFenceRow) => {
            let rfAmount = ringFenceRow.amount ? ringFenceRow.amount : '0';
            if (!(1 === Number.parseInt(ringFenceRow.status, 10))) {
              return;
            }

            if (typeof ringFenceRow['eligibleProducts'] !== 'undefined' && ringFenceRow.eligibleProducts) {
              ringFenceRow.eligibleProducts.forEach((eligibleProduct) => {
                let ringFenceAmount = rfAmount;
                if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].ringFenceAmount) {
                  ringFenceAmount =
                    parseFloat(walletManagement[eligibleProduct].ringFenceAmount) + parseFloat(rfAmount);
                }

                let rfProductAmount = ringFenceAmount;
                if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                  rfProductAmount = parseFloat(walletManagement[eligibleProduct].productAmount) + parseFloat(rfAmount);
                }

                walletManagement[eligibleProduct] = {
                  ...walletManagement[eligibleProduct],
                  ringFenceAmount: Number(parseFloat(ringFenceAmount).toFixed(2)),
                  productAmount: Number(parseFloat(rfProductAmount).toFixed(2)),
                };
              });
            }

            ringFence += Number.parseFloat(rfAmount);
          });

          total += ringFence;
        }

        Object.keys(walletManagement).forEach((eligibleProduct) => {
          if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
            walletManagement[eligibleProduct].productAmount = parseFloat(
              (walletManagement[eligibleProduct].productAmount + main).toFixed(2),
            );
          }
        });

        if (bonuses.length > 0 && ringFences.length > 0) {
          bonuses.forEach((bonus) => {
            ringFences.forEach((ringFence) => {
              if (ringFence.id === bonus.id) {
                bonus.ringFenceAmount = ringFence.amount;
              }
            });
          });
        }

        draft.main = parseFloat(main.toFixed(2));
        draft.bonus = bonus;
        draft.total = parseFloat(total.toFixed(2));
        draft.ringFence = parseFloat(ringFence.toFixed(2));
        draft.ringFences = ringFences;
        draft.walletManagement = walletManagement;
        draft.cardBonus = cardBonus;
        draft.loadingWallet = false;
        draft.totalBonusPerProducts = totalBonusPerProducts;
        draft.currency = action.currentCurrency;
        draft.loading = false;

        if (!fastEqual(state.bonuses, bonuses)) {
          // check if they're not the same
          draft.bonuses = bonuses;
        }

        break;
      case actionTypes.wallet.REQUEST_REMOVE_BONUS:
        draft.loadingRemoveBonus = true;
        draft.bonusRemoved = null;
        break;
      case actionTypes.wallet.RECEIVED_REMOVE_BONUS:
        let bonusRemoved = false;
        if (Array.isArray(action.result) && action.result.length === 0) {
          bonusRemoved = true;
        }
        draft.loadingRemoveBonus = false;
        draft.bonusRemoved = bonusRemoved;
        break;
      case actionTypes.wallet.CORE_SET_BALANCE: {
        let main = action.data.balance_amount / 100;
        let bonus = state.bonus;
        if (action.data.bonus_amount !== null) {
          bonus = action.data.bonus_amount / 100;
        }
        draft.main = main;
        draft.bonus = bonus;
        draft.total = main + bonus;
        break;
      }
      default:
        break;
    }
  });
};

export default reducer;
