import { wheelConstants } from '../actions/constants';
import { produce } from 'immer';

const getParsedValue = (val, def) => {
  const parsedValue = parseFloat(val);
  if (!isNaN(parsedValue)) return parsedValue;
  return def;
};

const getRandom = (max) => {
  const rng = Math.floor(Math.random() * (max + 1));
  return rng;
};

const initialState = {
  status: null,
  loading: false,
};
let rt = null;

const wheelReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case wheelConstants.LOAD:
        draft.loading = true;
        break;
      case wheelConstants.LOADED:
        if (action.status) {
          const status = action.status;
          if (rt === null) {
            rt = getRandom(getParsedValue(action.randomTime, 600)) * 1000;
          }

          if (status && status.wheel_setup && status.free_spin && status.free_spin.next_spin_after) {
            // add a random value to the nex spin time (no more than wheelSetup.parameters.randomTime
            // value) so that players don't try to spin all at the same time
            status.free_spin.next_spin_after = status.free_spin.next_spin_after + (rt ? rt : 0);
          }

          draft.status = status;
        }
        draft.loading = false;
        break;
      default:
      /* noop */
    }
  });

export default wheelReducer;
