let endpoints = {
  wsUrl: window.config.playerWsUrl,
  launchGame: `${window.config.appApiUrl}/launch-evolution-game`,
  evoHost: 'https://playonlinesolutions-ro.evo-games.com',
};

if (
  (import.meta.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.config &&
    window.config.environment !== 'production') ||
  (typeof window !== 'undefined' && window.config && window.config.environment !== 'production')
) {
  endpoints = {
    wsUrl: window.config.playerWsUrl,
    launchGame: `${window.config.appApiUrl}/launch-evolution-game`,
    evoHost: 'https://playonlinesolutions-ro.uat1.evo-test.com',
  };
}

export default endpoints;
