export const appConstants = {
  INITIALIZE: 'LOTTO_INITIALIZE',
  EVALUATE_BONUS_REQUEST: 'LOTTO_APP_EVALUATE_BONUS_REQUEST',
  EVALUATE_BONUS_RESPONSE: 'LOTTO_APP_EVALUATE_BONUS_REQUEST',
};

export const betsSlipConstants = {
  STAKE_INC: 'LOTTO_BETSLIP_STAKE_INC',
  STAKE_DEC: 'LOTTO_BETSLIP_STAKE_DEC',
  STAKE_SET: 'LOTTO_BETSLIP_STAKE_SET',
  SET_TICKET_ONLINE: 'LOTTO_BETSLIP_SET_TICKET_ONLINE',
  LOTTO_ADD_TICKET: 'LOTTO_BETSLIP_ADD_TICKET',
  LOTTO_CLEAR_TICKET: 'LOTTO_BETSLIP_CLEAR_TICKET',
  LOTTO_TICKET_CREATE: 'LOTTO_BETSLIP_CREATED_TICKET',
  LOTTO_TICKET_CREATE_SUCCESS: 'LOTTO_CREATE_TICKET_SUCCESS',
  LOTTO_TICKET_CREATE_MANUAL_AUTH: 'LOTTO_CREATE_TICKET_MANUAL_AUTH',
  LOTTO_TICKET_CREATE_ERROR: 'LOTTO_CREATE_TICKET_ERROR',
  LOTTO_TICKETS_INITIALIZE: 'LOTTO_TICKETS_INITIALIZE',
  LOTTO_TICKETS_PROCESS_NEXT_TICKET: 'LOTTO_TICKETS_PROCESS_NEXT_TICKET',
  LOTTO_TICKETS_PROCESSING_RESULT: 'LOTTO_TICKETS_PROCESSING_RESULT',
  LOTTO_TICKETS_CHECK_AUTHORIZATION: 'LOTTO_TICKETS_CHECK_AUTHORIZATION',
  LOTTO_TICKETS_PROCESSING_ERROR: 'LOTTO_TICKETS_PROCESSING_ERROR',
  LOTTO_TICKETS_INCREMENT_CURRENT_INDEX: 'LOTTO_TICKETS_INCREMENT_CURRENT_INDEX',

  BONUS_EVALUATE_RESPONSE: 'LOTTO_TICKETS_BONUS_EVALUATE_RESPONSE',
  REQUEST_BONUS_EVALUATION: 'LOTTO_TICKETS_REQUEST_BONUS_EVALUATION',

  FREE_BET_SELECTED_INDEX: 'LOTTO_FREE_BET_SELECTED_INDEX',
};

export const lottoEventsConstants = {
  LOADED: 'LOTTO_EVENTS_LOADED',
  LOADED_SYSTEMS: 'LOTTO_EVENTS_LOADED_SYSTEMS',
  SELECT_SYSTEM: 'LOTTO_EVENTS_SELECT_SYSTEM',
  CLEAR_SYSTEM: 'LOTTO_EVENTS_CLEAR_SYSTEM',
  LOADING: 'LOTTO_EVENTS_LOADING',
  EXPIRED: 'LOTTO_EVENTS_EXPIRED',
  FETCH: 'LOTTO_EVENTS_FETCH',
  FETCH_SYSTEMS: 'LOTTO_EVENTS_FETCH_SYSTEMS',
};

export const favNumbersConstants = {
  SET_FAV_NUMBERS: 'LOTTO_SET_FAV_NUMBERS',
  DEL_FAV_NUMBERS: 'LOTTO_DEL_FAV_NUMBERS',
  FETCH_FAV_NUMBERS: 'LOTTO_FETCH_FAV_NUMBERS',
  LOAD_FAV_NUMBERS: 'LOTTO_LOAD_FAV_NUMBERS',
};

export const lottoResultsConstants = {
  FETCH_LATEST: 'LOTTO_LATEST_RESULTS_FETCH',
  LOADED_LATEST: 'LOTTO_LATEST_RESULTS_LOADED',
  LOADING_LATEST: 'LOTTO_LATEST_RESULTS_LOADING',
  FETCH: 'LOTTO_RESULTS_FETCH',
  LOADED: 'LOTTO_RESULTS_LOADED',
  LOADING: 'LOTTO_RESULTS_LOADING',
};

export const authConstants = {
  INITIALIZE: 'LOTTO_AUTH_INITIALIZE',
  SUCCESS: 'LOTTO_AUTH_SUCCESS',
  CLEAR: 'LOTTO_AUTH_CLEAR',
  END_CYCLE: 'LOTTO_AUTH_END_CYCLE',
  PLAYER_LOGIN: 'LOTTO_PLAYER_LOGIN',
  PLAYER_LOGOUT: 'LOTTO_PLAYER_LOGOUT',
};

export const ticketConstants = {
  SETTLED_LIST_REQUEST: 'LOTTO_TICKET_SETTLED_LIST_REQUEST',
  OPENED_LIST_REQUEST: 'LOTTO_TICKET_OPENED_LIST_REQUEST',
  RESERVED_LIST_REQUEST: 'LOTTO_TICKET_RESERVED_LIST_REQUEST',
  LIST_REQUEST: 'LOTTO_TICKET_LIST_REQUEST',
  SETTLED_LIST_RECEIVED: 'LOTTO_TICKET_SETTLED_LIST_RECEIVED',
  OPENED_LIST_RECEIVED: 'LOTTO_TICKET_OPENED_LIST_RECEIVED',
  RESERVED_LIST_RECEIVED: 'LOTTO_TICKET_RESERVED_LIST_RECEIVED',
  LIST_RECEIVED: 'LOTTO_TICKET_LIST_RECEIVED',
  LIST_ERROR: 'LOTTO_TICKET_LIST_ERROR',
  REQUESTING_DATA: 'LOTTO_REQUESTING_TICKET_DATA',
  CANCEL_REQUEST: 'LOTTO_TICKET_CANCEL_REQUEST',
  CANCEL_SUCCESS: 'LOTTO_TICKET_CANCEL_SUCCESS',
  CANCEL_ERROR: 'LOTTO_TICKET_CANCEL_ERROR',
  STORE_OPEN: 'LOTTO_TICKET_STORE_OPEN',
  STORE_RESERVED: 'LOTTO_TICKET_STORE_RESERVED',
  STORE_RECENTLY_WON: 'LOTTO_STORE_RECENTLY_WON',
  DRAW_FINISHED: 'LOTTO_DRAW_FINISHED',
  DRAWS_FINISHED: 'LOTTO_DRAWS_FINISHED',
  CHECK_CODE: 'LOTTO_TICKET_CHECK_CODE',
  CHECK_CODE_PENDING: 'LOTTO_TICKET_CHECK_PENDING',
  CHECK_CODE_DATA: 'LOTTO_TICKET_CHECK_CODE_DATA',
  CHECK_CODE_ERROR: 'LOTTO_TICKET_CHECK_CODE_ERROR',
  CHECK_CODE_CLOSE: 'LOTTO_TICKET_CHECK_CODE_CLOSE',
};

export const configConstants = {
  LOADED: 'LOTTO_CONFIG_LOADED',
  RELOAD: 'LOTTO_CONFIG_RELOADED',
};

export const playerConstants = {
  INITIALIZE: 'LOTTO_PLAYER_INITIALIZE',
};

export const bonusesConstants = {
  SET: 'LOTTO_BONUSES_SET',
};

export const drawingEvents = {
  SET: 'LOTTO_DRAWING_EVENTS_SET',
};
