import { bonusesConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  items: [],
};

const bonusesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case bonusesConstants.SET:
        draft.items = action.bonuses;
        break;
      default:
      /* noop */
    }
  });

export default bonusesReducer;
