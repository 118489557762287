import React from 'react';
import styled from 'styled-components';
import { useMatches, useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { requestWallet } from '../../../modules/casino/store/actions/wallet';

import './index.scss';

type WalletPreviewProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type WalletProps = {
  walletAmount: any;
  bonusAmount: any;
  ringFenceAmount: any;
  currency: string;
  id: number;
  name: string;
  type: string;
  enabled: boolean;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const WalletPreview = (componentProps: WalletPreviewProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const uriMatches = useMatches();

  const wallet = useAppSelector((state) => state.wallet);
  const winnerFunWallet = useAppSelector((state) =>
    state.winnerFun && state.winnerFun.wallet ? state.winnerFun.wallet : null,
  );

  const dispatch = useAppDispatch();

  const [currentProduct, setCurrentProduct] = React.useState('walletMain');

  React.useEffect(() => {
    if (uriMatches && uriMatches.length) {
      const uriMatch = uriMatches[uriMatches.length - 1];

      if (uriMatch && uriMatch.pathname) {
        getCurrentPathWallet(uriMatch.pathname);
      }
    }
  }, [uriMatches]);

  const getCurrentPathWallet = (path: any) => {
    // console.log(`running getCurrentPathWallet with path: ${path}`);
    if (path.startsWith('/casino')) {
      setCurrentProduct('walletCasino');
    } else if (path.startsWith('/casino-live')) {
      setCurrentProduct('walletCasinoLive');
    } else if (path.startsWith('/bets')) {
      setCurrentProduct('walletSportsbook');
    } else if (path.startsWith('/lotto')) {
      setCurrentProduct('walletLotto');
    } else if (path.startsWith('/winnerfun')) {
      dispatch(requestWallet());
      setCurrentProduct('walletWinnerFun2');
    } else {
      setCurrentProduct('walletMain');
    }
  };

  const walletTypes = {
    CASINO: { id: 1, name: 'Casino', type: 'walletCasino', enabled: true },
    CASINO_LIVE: {
      id: 4,
      name: 'Live Casino',
      type: 'walletCasinoLive',
      enabled: window.config && window.config.liveCasinoEnabled === '1',
    },
    SPORTSBOOK: {
      id: 2,
      name: 'Sports',
      type: 'walletSportsbook',
      enabled:
        (window.config && window.config.betsEnabled === '1') || (window.config && window.config.nsoftEnabled === '1'),
    },
    // VIRTUALS: {
    //   id: 999,
    //   name: 'Virtual',
    //   type: 'walletVirtuals',
    //   enabled: window.config && window.config.virtualsEnabled === '1',
    // }, // ID to be defined
    LOTTO: {
      id: 3,
      name: 'Lotto',
      type: 'walletLotto',
      enabled: window.config && window.config.lottoEnabled === '1',
    },
    WINNER_FUN: {
      id: 6,
      name: 'Winner Fun',
      type: 'walletWinnerFun2',
      enabled: window.config && window.config.winnerFunEnabled === '1',
    },
    // add more here as they are implemented
  };

  const getCurrentWallet = (id: any) => {
    if (!wallet.loadingWallet) {
      if (id === walletTypes['WINNER_FUN'].id) {
        return winnerFunWallet ? winnerFunWallet.total : 0;
      } else if (wallet.walletManagement[id] && wallet.walletManagement[id].productAmount) {
        return parseFloat(wallet.walletManagement[id].productAmount.toFixed(2));
      } else {
        return parseFloat(wallet.main.toFixed(2));
      }
    }
    return 0;
  };

  const getCurrentWalletBonus = (id: any) => {
    if (!wallet.loadingWallet) {
      if (id === walletTypes['WINNER_FUN'].id) {
        return winnerFunWallet ? winnerFunWallet.secondary : 0;
      } else if (wallet.walletManagement[id] && wallet.walletManagement[id].bonusAmount) {
        return parseFloat(wallet.walletManagement[id].bonusAmount.toFixed(2));
      } else {
        return 0;
      }
    }
    return 0;
  };

  const getCurrentRingFenceAmount = (id: any) => {
    if (!wallet.loadingWallet) {
      if (id === walletTypes['WINNER_FUN'].id) {
        return winnerFunWallet ? winnerFunWallet.ringFence : 0;
      } else if (wallet.walletManagement[id] && wallet.walletManagement[id].ringFenceAmount) {
        return parseFloat(wallet.walletManagement[id].ringFenceAmount.toFixed(2));
      } else {
        return 0;
      }
    }
    return 0;
  };

  const wallets: WalletProps[] = [];

  for (const [key, value] of Object.entries(walletTypes)) {
    if (value.enabled) {
      // separate WF from other wallets
      if (currentProduct === 'walletWinnerFun2') {
        if (value.id === 6) {
          wallets.push({
            ...value,
            walletAmount: getCurrentWallet(value.id),
            bonusAmount: getCurrentWalletBonus(value.id),
            ringFenceAmount: getCurrentRingFenceAmount(value.id),
            currency: wallet.currency,
          });
        }
      } else {
        if (value.id !== 6) {
          wallets.push({
            ...value,
            walletAmount: getCurrentWallet(value.id),
            bonusAmount: getCurrentWalletBonus(value.id),
            ringFenceAmount: getCurrentRingFenceAmount(value.id),
            currency: wallet.currency,
          });
        }
      }
    }
  }

  let showProducts = false;

  if (wallets.length > 0) {
    for (const wallet of wallets) {
      if (wallet.bonusAmount && wallet.bonusAmount > 0) {
        showProducts = true;
        break;
      }
    }
  }

  const contextValue = {
    currency: wallet.currency,
    wallets: wallets,
    // showProducts: showProducts,
    showProducts: true,

    displayWallet: wallets.find((w) => w.type === currentProduct),
    wallet: wallet,
  };

  // console.log('DEBUG WALLET PREV: ', {
  //   contextValue,
  //   wallets,
  //   wallet,
  //   currentProduct,
  //   displayWallet: contextValue.displayWallet,
  // });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default WalletPreview;
