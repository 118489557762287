import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import SwipeUp from './assets/swipe-up.svg?react';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import SwipeUpPointer from './assets/swipe-up-pointer.svg?react';

import './index.scss';

type SlideToHideToolbarProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

let handleViewportInterval: any = -1;
let handleViewportCountdown = 5;

const SlideToHideToolbar = (componentProps: SlideToHideToolbarProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const isGameRunning = useAppSelector((state) => state.games.isRunning);

  const {
    isCriOS,
    isiOS,
    iOSVersion,
    major,
    minor,
    isIOSSafari,
    doNotShowHandInPortrait,
    hasProblematicTabbar,
    hasUnreliableScroll,
    isIpad,
    isLandscape,
  } = React.useMemo(() => {
    const isCriOS = null != navigator.userAgent.match(/CriOS/i);
    const isiOS =
      /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
      ('MacIntel' === navigator.platform && navigator.maxTouchPoints > 1);
    const iOSVersion = (function () {
      if (!/iPhone|iPad|iPod/i.test(navigator.userAgent)) return null;
      const t: any = navigator.userAgent.match(/OS (\d+)_(\d+)(?:_(\d+))?/),
        e = t[0];
      return {
        major: +t[1],
        minor: +t[2],
        bugfix: +t[3],
        full: e.replace(/_/, '.').replace('OS ', ''),
      };
    })();

    const { major, minor } = iOSVersion || { minor: 0, major: 0 };
    const isIOSSafari = isiOS && !isCriOS;
    const doNotShowHandInPortrait = isIOSSafari && major === 15 && (minor === 0 || minor >= 4);
    const hasProblematicTabbar = (isIOSSafari && major === 16 && minor >= 3) || (isIOSSafari && major === 17);
    const hasUnreliableScroll = hasProblematicTabbar && `${major}.${minor}` !== '17.0';

    const isIpad =
      navigator.userAgent.match(/iPad/i) != null ||
      // since iPad is not present in the userAgent for iPad OS 13 an alternative was needed
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    const isLandscape = window.matchMedia('(orientation: landscape)').matches;

    return {
      isCriOS,
      isiOS,
      iOSVersion,
      major,
      minor,
      isIOSSafari,
      doNotShowHandInPortrait,
      hasProblematicTabbar,
      hasUnreliableScroll,
      isIpad,
      isLandscape,
    };
  }, []);

  const checkIsMaximized = React.useCallback(() => {
    if (!isiOS) return true;

    const { width, height } = window.screen;
    const isLandscape = window.matchMedia('(orientation: landscape)').matches && isiOS;

    if (isLandscape) {
      // console.log('isLandscape');
      return window.innerHeight / Math.min(width, height) >= 0.9;
    }
    if (isCriOS) {
      // console.log('isCriOS');
      return window.innerHeight / Math.max(width, height) >= 0.9;
    }
    return window.innerHeight / document.body.offsetHeight >= 0.99;
  }, [isiOS]);

  const hideElement = React.useCallback(
    (element: any, _isHandShown: boolean) => {
      if (element) {
        if (major !== 15) {
          element.style.visibility = _isHandShown ? 'visible' : 'hidden';
        } else {
          element.style.display = _isHandShown ? 'block' : 'none';
        }
      }
    },
    [major],
  );

  const onResize = React.useCallback(() => {
    const iOSFullscreen = document.getElementById('iOSFullscreen');
    const iOSFullscreenAnimation = document.getElementById('iOSFullscreenAnimation');

    const isMaximized = checkIsMaximized();

    const _isHandShown =
      !isMaximized && !(window.matchMedia('(orientation: portrait)').matches && doNotShowHandInPortrait);

    hideElement(iOSFullscreen, _isHandShown);
    hideElement(iOSFullscreenAnimation, _isHandShown);

    if (iOSFullscreen) {
      if (isCriOS) {
        iOSFullscreen.style.height = '1200vh';
      } else if (_isHandShown) {
        iOSFullscreen.style.height = '155vh';
      } else {
        iOSFullscreen.style.height = '120vh';
      }
    }

    if (!isMaximized) {
      window.scrollTo(0, 0);
    }
  }, [isiOS, isCriOS, major, isGameRunning]);

  const onOrientationChange = React.useCallback(() => {
    onResize();

    if (hasUnreliableScroll || doNotShowHandInPortrait) {
      clearInterval(handleViewportInterval);
      handleViewportCountdown = 5;

      handleViewportInterval = setInterval(function () {
        onResize();
        if (--handleViewportCountdown === 0) {
          clearInterval(handleViewportInterval);
        }
      }, 100);
    }
  }, [hasUnreliableScroll, doNotShowHandInPortrait, isiOS]);

  const onOrientationChangeEvent = React.useCallback((fn: any) => {
    const orientation = window.screen.orientation;
    const events = [
      (orientation === null || orientation === void 0 ? void 0 : orientation.type)
        ? [orientation, 'change']
        : [window, 'orientationchange'],
      [document, 'visibilitychange'],
    ];
    events.forEach(function (_a) {
      const target: any = _a[0],
        eventName = _a[1];
      return target.addEventListener(eventName, fn);
    });
    return function () {
      events.forEach(function (_a) {
        const target: any = _a[0],
          eventName = _a[1];
        return target.removeEventListener(eventName, fn);
      });
    };
  }, []);

  const preventDefault = React.useCallback((event: any) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onTouchStart = React.useCallback((event: any) => {
    const isMaximized = checkIsMaximized();

    const _isHandShown =
      !isMaximized && !(window.matchMedia('(orientation: portrait)').matches && doNotShowHandInPortrait);

    if (!_isHandShown) {
      preventDefault(event);
      clearInterval(handleViewportInterval);
    }
  }, []);

  React.useEffect(() => {
    let removeOrientationChangeHandler = () => {};
    if (isGameRunning) {
      document.body.classList.add('ios-height-check');
      onResize();
      removeOrientationChangeHandler = onOrientationChangeEvent(onOrientationChange);
      window.addEventListener('resize', onResize, true);
      window.addEventListener('touchstart', onTouchStart, true);
    }

    return () => {
      try {
        document.body.classList.remove('ios-height-check');
        removeOrientationChangeHandler();
        window.removeEventListener('resize', onResize, true);
        window.removeEventListener('touchstart', onTouchStart, true);
      } catch (e) {}
    };
  }, [isGameRunning]);

  /*
  const contextValue = React.useMemo(() => {
    return {};
  }, [dataElementContext, componentProps]);

  console.log('SlideToHideToolbar[contextValue]', contextValue, props);
  */

  if (!props.properties.enable) return null;

  if (
    !isiOS ||
    isIpad ||
    // @ts-ignore
    window.navigator?.standalone ||
    // @ts-ignore
    (!isCriOS && window.webkit && window.webkit.messageHandlers)
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="ios-fullscreen" id="iOSFullscreen"></div>
      <div id="iOSFullscreenAnimation">
        <div className="swipe-up__wrapper">
          <SwipeUp />
          <SwipeUpPointer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SlideToHideToolbar;
