import React from 'react';
import styled from 'styled-components';

const DataListItemsDiv = styled.div((props) => props.$styleText);

export const DataListItems = (componentProps) => {
  return (
    <DataListItemsDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText} style={componentProps.style}>
      {componentProps.children}
    </DataListItemsDiv>
  );
};
