export const activeWinnerFunCampaigns = (campaigns: any[], simplified?: boolean) => {
  const res: any = { winner_fun: true };
  const now = (+new Date()).toString();

  campaigns.forEach((c: any) => {
    if (c.start_date <= now && now < c.end_date) {
      if (c.type === 'season') {
        res['season_id'] = c.id;
        if (!simplified) res['season'] = c;
      } else if (c.type === 'event') {
        res['event_id'] = c.id;
        if (!simplified) res['event'] = c;
      }
    }
  });

  return res;
};

export const activeWinnerFunCampaign = (campaigns: any[]) => {
  let activeCampaign = null;

  const now = (+new Date()).toString();

  try {
    for (let i = 0; i < campaigns.length; i++) {
      const campaign = campaigns[i];

      if (campaign.startDate <= now && now < campaign.endDate) {
        activeCampaign = campaign;
        let activeEvent = null;

        if (campaign.events) {
          const events = campaign.events;

          for (let j = 0; j < events.length; j++) {
            const event = events[j];
            if (event.startDate <= now && now < event.endDate) {
              activeEvent = event;
              break;
            }
          }
        }

        if (activeEvent) {
          activeCampaign = activeEvent;
        }
        break;
      }
    }
  } catch (err) {
    /* noop */
  }

  return activeCampaign;
};
export const lastActiveWinnerFunCampaign = (campaigns: any[]) => {
  let inactiveCampaign = null;

  const now = (+new Date()).toString();

  try {
    for (let i = 0; i < campaigns.length; i++) {
      const campaign = campaigns[i];

      if (campaign.startDate <= now && now < campaign.endDate) {
        let inactiveEvent = null;

        if (campaign.events) {
          const events = campaign.events.reverse();

          for (let j = 0; j < events.length; j++) {
            const event = events[j];
            if (!(event.startDate <= now && now < event.endDate)) {
              inactiveEvent = event;
              break;
            }
          }
        }

        if (inactiveEvent) {
          inactiveCampaign = inactiveEvent;
          break;
        }
      } else {
        inactiveCampaign = campaign;
        break;
      }
    }
  } catch (err) {
    /* noop */
  }

  return inactiveCampaign;
};
