import React from 'react';
import { Toast, ToastHeader, ToastBody, Progress } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

export const TOAST_TIMER = 8000;
const TOAST_ANIMATION_TIMER = 6500;

const ToastElementDiv = styled.div({
  paddingBottom: '6px',
  '& .toast': {
    position: 'relative',
    overflow: 'hidden',
    // @ts-ignore
    '&.toast-content': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '16px',
    },
    '&.type-success': {
      backgroundColor: '#F3FAF1',
      color: '#66638B',
      '& .toast-icon': {
        '& i': {
          color: '#51C981',
        },
      },
      '&.last-secs': {
        backgroundColor: '#E2EEE0',
      },
    },
    '& .toast-icon': {
      width: '30px',
      '& i': {
        fontSize: '20px',
      },
    },
    '& .progress': {
      height: '4px',
      position: 'absolute',
      bottom: '0',
      left: '0',
      width: '100%',
      '& .progress-bar': {
        transition: 'none',
        transitionProperty: 'none',
        transitionDuration: 'none',
        transitionTimingFunction: 'none',
        transitionDelay: 'none',
        animation: 'none',
        animationName: 'none',
        animationDuration: '.15s',
        animationTimingFunction: 'none',
        animationDelay: 'none',
        animationIterationCount: 'none',
        animationDirection: 'none',
        animationFillMode: 'none',
        animationPlayState: 'none',
      },
    },
  },
});

type CustomToastProps = {
  notification: Notification;
};

export type Notification = {
  id?: string; // random string
  icon?: any;
  title?: string;
  message: string;
  type: string;
  duration?: number;
  progressBar?: boolean;
  placeholders: any;
};
const CustomToast = (props: CustomToastProps) => {
  const [loadingBarValue, setLoadingBarValue] = React.useState(100);
  const [lastSecs, setLastSecs] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    const endInterval = (props.notification.duration ?? TOAST_TIMER) - (TOAST_TIMER - TOAST_ANIMATION_TIMER);

    const startAnimationTime = new Date().getTime();
    const interval = setInterval(() => {
      setLoadingBarValue((v) => {
        const now = new Date().getTime();
        // calculate value for progress bar based on time
        const value = ((now - startAnimationTime) / endInterval) * 100;

        if (value >= 99.5) {
          setLastSecs(true);
          clearInterval(interval);
          return 100;
        }
        return value;
      });
    }, 25);
  }, []);

  const getIcon = () => {
    if (!props.notification.icon) return null;

    let icon = null;
    switch (props.notification.type) {
      case 'success':
        icon = <i className="bi bi-check-circle-fill"></i>;
        break;
      case 'error':
        icon = <i className="bi bi-exclamation-circle-fill"></i>;
        break;
      case 'warning':
        icon = <i className="bi bi-exclamation-triangle-fill"></i>;
        break;
      case 'info':
        icon = <i className="bi bi-info-circle-fill"></i>;
        break;
      default:
        return null;
    }

    return <div className="toast-icon">{icon}</div>;
  };

  let text: any = props.notification.message;
  text = text != null ? t(text, props.notification.placeholders ? props.notification.placeholders : {}) : '';

  return (
    <ToastElementDiv>
      <Toast className={`type-${props.notification.type}` + (lastSecs ? ' last-secs' : '')}>
        {props.notification.title && <ToastHeader>{props.notification.title}</ToastHeader>}
        {props.notification.message && (
          <ToastBody>
            <div className="toast-content">
              {getIcon()}
              <div dangerouslySetInnerHTML={{ __html: text }} className={'toast-message'}></div>
            </div>
            {props.notification.progressBar && (
              <Progress color={props.notification.type} max={100} min={0} value={loadingBarValue} />
            )}
          </ToastBody>
        )}
      </Toast>
    </ToastElementDiv>
  );
};

export default CustomToast;
