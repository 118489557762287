import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { fetchLeagueStats, clearLeagueStats } from '../../../store/slices/leagueStats';
import { digitainConfig } from '@/modules/bets/api/config/digitain';
import DefaultCrest from '@/modules/bets/assets/default-crest.svg';
import { statsLabels, statsAbbrev } from '../bets-match-stats/utils/processStatsData';
import { useMediaQuery } from '../../utils/useQueryMedia';
import { flags } from '../bets-match-stats/utils/flags';

const crest = (teamLogoNo: number) => {
  if (teamLogoNo) {
    if (flags[teamLogoNo]) {
      return flags[teamLogoNo];
    }
    return `${digitainConfig().apiURL}/team-logo2/${teamLogoNo}`;
  }

  return DefaultCrest;
};

const toName = (name: any) => {
  if (!name) return '';

  if (typeof name === 'string') {
    try {
      const obj = JSON.parse(name);
      if (typeof obj === 'object' && (obj['2'] || obj['0'])) {
        return obj;
      }
    } catch (e) {
      /**/
    }
  }
  return name;
};

const padString = (str: any) => {
  return String(str).padStart(2, '0');
};

type BetsLeagueStatsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetsLeagueStats = (componentProps: BetsLeagueStatsProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const context = props.properties?.context ?? 'full';

  const dispatch = useAppDispatch();
  const leagueStats = useAppSelector((state) => state.leagueStats);
  const isDesktop = useMediaQuery('(min-width:900px)');

  let selectorIndex = 2;
  let mainIndex = 0;
  const hash = location.hash;
  let mainTab = '';
  if (hash.includes('-')) {
    const hashParts = hash.split('-');
    if (hashParts.length > 1) {
      selectorIndex = parseInt(hashParts[0].replace('#', ''), 10);
      mainTab = hashParts[1];
    }
  }
  switch (mainTab) {
    case 'ranking':
      mainIndex = 0;
      break;
    case 'fixtures':
      mainIndex = 1;
      break;
    case 'results':
      mainIndex = 2;
      break;
    default:
      mainIndex = 0;
  }

  if (props.properties?.selectorIndex) {
    selectorIndex = parseInt(props.properties?.selectorIndex, 10);
  }
  if (props.properties?.mainIndex) {
    mainIndex = parseInt(props.properties?.mainIndex, 10);
  }

  const [state, setState] = React.useState({
    selectorIndex: selectorIndex,
    mainIndex: mainIndex,
    subIndex: props.properties?.subIndex ? parseInt(props.properties?.subIndex, 10) : 0,
    lobbyStatsIndex: 0,
    drawIndex: 0,
    performanceIndex: 0,
    performanceTypeIndex: 0,
    sortByIndex: 0,
    sortDirection: 'desc',
    loading: false,
  });

  const onIndexChange = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.selectorIndex != null) {
        const tabIndex = parseInt(e.currentTarget.dataset.selectorIndex, 10) ?? 0;

        const mainIndex = e.currentTarget.dataset.mainIndex ? parseInt(e.currentTarget.dataset.mainIndex, 10) : 0;
        const subIndex = e.currentTarget.dataset.subIndex ? parseInt(e.currentTarget.dataset.subIndex, 10) : 0;

        switch (mainIndex) {
          case 0:
            location.hash = `#${tabIndex}-ranking`;
            break;
          case 1:
            location.hash = `#${tabIndex}-fixtures`;
            break;
          case 2:
            location.hash = `#${tabIndex}-results`;
            break;
        }

        setState((v) => ({
          ...v,
          selectorIndex: tabIndex,
          mainIndex: mainIndex,
          subIndex: subIndex,
          drawIndex: 0,
          performanceIndex: 0,
          performanceTypeIndex: 0,
          sortByIndex: 0,
          sortDirection: 'desc',
        }));
        return;
      }

      if (e.currentTarget.dataset.drawIndex != null) {
        const drawIndex = parseInt(e.currentTarget.dataset.drawIndex, 10) ?? 0;
        setState((v) => ({ ...v, drawIndex: drawIndex }));
        return;
      }

      if (e.currentTarget.dataset.performanceIndex != null) {
        const performanceIndex = parseInt(e.currentTarget.dataset.performanceIndex, 10) ?? 0;
        setState((v) => ({
          ...v,
          performanceIndex: performanceIndex,
          sortByIndex: 0,
          sortDirection: 'desc',
          performanceTypeIndex: 0,
        }));
        return;
      }

      e.stopPropagation();

      if (e.currentTarget.dataset.mainIndex != null) {
        const tabIndex = parseInt(e.currentTarget.dataset.mainIndex, 10) ?? 0;
        setState((v) => ({ ...v, mainIndex: tabIndex }));

        switch (tabIndex) {
          case 0:
            location.hash = `#${state.selectorIndex}-ranking`;
            break;
          case 1:
            location.hash = `#${state.selectorIndex}-fixtures`;
            break;
          case 2:
            location.hash = `#${state.selectorIndex}-results`;
            break;
        }
        return;
      }
      if (e.currentTarget.dataset.subIndex != null) {
        const tabIndex = parseInt(e.currentTarget.dataset.subIndex, 10) ?? 0;
        setState((v) => ({ ...v, subIndex: tabIndex, mainIndex: 0 }));
        return;
      }
      if (e.currentTarget.dataset.lobbyStatsIndex != null) {
        const tabIndex = parseInt(e.currentTarget.dataset.lobbyStatsIndex, 10) ?? 0;
        setState((v) => ({ ...v, lobbyStatsIndex: tabIndex }));
        return;
      }

      if (e.currentTarget.dataset.performanceTypeIndex != null) {
        const tabIndex = parseInt(e.currentTarget.dataset.performanceTypeIndex, 10) ?? 0;
        setState((v) => ({ ...v, performanceTypeIndex: tabIndex }));
        return;
      }
    },
    [state],
  );

  const onSortChange = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.sortByIndex != null && e.currentTarget.dataset.sortDirection != null) {
      const sortByIndex = parseInt(e.currentTarget.dataset.sortByIndex ?? 0, 10);
      const sortDirection = e.currentTarget.dataset.sortDirection;
      setState((v) => ({ ...v, sortByIndex, sortDirection }));
    }
  }, []);

  React.useEffect(() => {
    let id = `${state.selectorIndex}-${state.mainIndex}-${state.mainIndex === 0 ? state.subIndex : 0}`;

    if (context === 'lobby') {
      if (state.lobbyStatsIndex === 0) id = '2-0-0';
      if (state.lobbyStatsIndex === 1) id = '2-0-1';

      if (!leagueStats.data[id] && !leagueStats.requested[id]) {
        dispatch(fetchLeagueStats({ id }));
      }
    } else {
      if (id === '1-0-0') id = '1-2-0';

      if (!leagueStats.data[id] && !leagueStats.requested[id]) {
        dispatch(fetchLeagueStats({ id }));
      }
    }
  }, [state, leagueStats, context]);

  React.useEffect(() => {
    return () => {
      dispatch(clearLeagueStats());
    };
  }, []);

  React.useEffect(() => {
    setState((v) => ({ ...v, performanceIndex: 0, sortByIndex: 0, sortDirection: 'desc', performanceTypeIndex: 0 }));
  }, [isDesktop]);

  const processStandings = React.useCallback((groups: any, reduced: boolean) => {
    const result: any = [];

    groups.forEach?.((group: any) => {
      const data: any = {};
      data.label = group.name;

      data.matches = [];
      group.standings?.slice?.(0, reduced ? 4 : groups.standings?.length).forEach?.((team: any, index: number) => {
        data.matches.push({
          team_id: team.logo,
          team: team.name,
          image: crest(team.logo),
          position: index + 1,
          note: team.note,
          matches: team.p,
          goalsFor: team.gf,
          goalsAgainst: team.ga,
          points: team.pnt,
        });
      });

      result.push(data);
    });

    return result;
  }, []);

  const processResult = React.useCallback((teams: any) => {
    const date = new Date(teams.event_time);
    const dateShort = teams.event_time ? `${padString(date.getDate())}.${padString(date.getMonth() + 1)}` : '-';

    const dateFullYear = teams.event_time ? date.getFullYear() : '';
    const nowFullYear = new Date().getFullYear();
    const time = teams.event_time ? date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) : '-';
    const url =
      teams.digitain_sport_id && teams.digitain_category_id && teams.digitain_tournament_id && teams.digitain_event_id
        ? `/bets/match/pre-match/${teams.digitain_sport_id}/${teams.digitain_category_id}/${teams.digitain_tournament_id}/${teams.digitain_event_id}`
        : '';

    return {
      date: date.getTime(),
      dateShort: dateShort,
      year: dateFullYear === nowFullYear ? '' : dateFullYear,
      time: time,
      matchId: teams.event_id,
      matchDetailsURL: url,
      team1: {
        id: teams.team1_logo,
        name: toName(teams.team1_name),
        image: crest(teams.team1_logo),
        score: teams.status_result?.result1 ?? '',
        win: teams.status_result?.result1 > teams.status_result?.result2,
      },
      team2: {
        id: teams.team2_logo,
        name: toName(teams.team2_name),
        image: crest(teams.team2_logo),
        score: teams.status_result?.result2 ?? '',
        win: teams.status_result?.result2 > teams.status_result?.result1,
      },
    };
  }, []);

  const processResults = React.useCallback((results: any, isFixtures: boolean, isDraws: boolean = false) => {
    const groups: any = {};

    results.forEach?.((match: any) => {
      const tournamentId = match.tournament_id ?? '1';
      const group_index = match.group_index ?? '0';
      if (match.group_id) {
        const key = `${group_index}_${tournamentId}-${match.group_id}`;
        if (!groups[key]) groups[key] = { matches: [] };

        groups[key].tournamentName = toName(match.tournament_name);
        groups[key].groupName = toName(match.group_name);
        groups[key].key = key;

        const now = new Date().getTime();
        const date = match.event_time ? new Date(match.event_time).getTime() : now + 16 * 60 * 1000;

        if (isFixtures && date - now > 15 * 60 * 1000) {
          groups[key].matches.push(processResult(match));
        } else if (!isFixtures) {
          groups[key].matches.push(processResult(match));
        }
      } else {
        console.error('Match, without group_id', match);
      }
    });

    const fullResults: any = [];

    Object.keys(groups).forEach((key) => {
      groups[key].matches.sort((a: any, b: any) => {
        if (isFixtures) return a.date - b.date;
        return b.date - a.date;
      });

      fullResults.push(groups[key]);
    });

    fullResults.sort((a: any, b: any) => {
      if (isDraws) return b.key.localeCompare(a.key);
      return a.key.localeCompare(b.key);
    });

    fullResults.reverse();

    /*
    if (fullResults.length) {
      fullResults.unshift(JSON.parse(JSON.stringify(fullResults[0])));

      fullResults[0].groupName = 'test';
      fullResults[0].key = fullResults[0].key + '_test';
    }
    

    if (fullResults.length) {
      fullResults[0].matches[0].team2.win = false;
      fullResults[0].matches[1].team1.win = false;
      fullResults[0].matches[2].team1.win = false;
    }
    */

    return fullResults;
  }, []);

  const processDraws = React.useCallback((results: any, isFixtures: boolean) => {
    const grouped = processResults(results, false, true);

    const copyGrouped = JSON.parse(JSON.stringify(grouped.reverse()));

    //console.log('copyGroup');

    copyGrouped.forEach((group: any, index: number) => {
      let orderIndex = 0;

      if (index + 1 < copyGrouped.length) {
        copyGrouped[index + 1].nextKey = group.key;
        copyGrouped[index + 1].nextIndex = index + 1;
      }

      group.matches.forEach((match: any) => {
        const team1Id = match.team1.id.toString();
        const team2Id = match.team2.id.toString();

        if (copyGrouped[index + 1]) {
          copyGrouped[index + 1].matches.forEach((match: any) => {
            if (team1Id === match.team1.id.toString() || team1Id === match.team2.id.toString()) {
              match.order_index = orderIndex + 1;
            } else if (team2Id === match.team1.id.toString() || team2Id === match.team2.id.toString()) {
              match.order_index = orderIndex + 2;
            }
          });
          orderIndex += 2;
        }
      });
    });

    copyGrouped.forEach((group: any, index: number) => {
      group.matches.sort((a: any, b: any) => {
        return a.order_index - b.order_index;
      });
    });

    if (copyGrouped.length === 1) {
      copyGrouped[0].matches.sort((a: any, b: any) => {
        return a.date - b.date;
      });
    }

    return copyGrouped.reverse();
  }, []);

  const processPlayers = React.useCallback((players: any, reduced: boolean) => {
    const result: any = [];

    players.forEach?.((player: any, index: number) => {
      const data: any = {
        name: player.player_name,
        team: player.team_name,
        team_id: player.team_logo,
        image: crest(player.team_logo),
        position: index + 1,
        //matches: 5,
        goals: player.goals,
        points: player.penalties,
      };

      result.push(data);
    });

    if (reduced) {
      return result.slice(0, 4);
    }

    return result;
  }, []);

  const processPerformance = React.useCallback(
    (performance: any, sortBy: number, sortByIndex: number = 0, sortDirection: string = ''): any => {
      const teams: any = [];
      const keys: any = [];

      performance?.keys?.forEach?.((key: any) => {
        keys.push({
          id: key,
          label: statsLabels[key] ?? key,
          abbrev: statsAbbrev[key] ?? key,
        });
      });

      performance?.teams?.forEach?.((team: any, index: number) => {
        teams.push({
          team: team.team_name,
          team_id: team.team_logo,
          image: crest(team.team_logo),
          position: index + 1,
          stats: team.stats,
        });
      });

      if (sortDirection) {
        if (keys?.[sortBy]?.id) {
          teams.sort((a: any, b: any) => {
            let aValue = a.stats[keys[sortBy].id];
            let bValue = b.stats[keys[sortBy].id];

            if (sortByIndex === 0) {
              if (aValue?.total) aValue = aValue.total;
              if (bValue?.total) bValue = bValue.total;
            } else {
              if (aValue?.average) aValue = aValue.average;
              if (bValue?.average) bValue = bValue.average;
            }

            aValue = aValue ? parseFloat(aValue) : 0;
            bValue = bValue ? parseFloat(bValue) : 0;

            if (sortDirection === 'asc') return aValue - bValue;
            if (sortDirection === 'desc') return bValue - aValue;
            return 0;
          });
        }
      }

      //console.log('BetsLeagueStats[processPerformance]', performance);

      return [keys, teams];
    },
    [],
  );

  const contextValue = React.useMemo(() => {
    let lobbyGroups: any = [];
    let lobbyPlayers: any = [];
    let overrideKey = 'not-generated';
    let disableInViewport = false;
    let fullGroups: any = [];
    let fullResults: any = [];
    let fullFixtures: any = [];
    let fullDraws: any = [];
    let fullPlayers: any = [];

    let fullPerformance: any = [];
    let performanceKeys: any = [];
    let performanceKeyInfo: any = {};

    const sortByIndex: number = state.sortByIndex;
    const sortDirection: string = state.sortDirection;

    if (context === 'lobby') {
      if (state.lobbyStatsIndex === 0) {
        lobbyGroups = processStandings(leagueStats.data['2-0-0'] ?? [], true);
        if (lobbyGroups.length) {
          overrideKey = 'generated' + new Date().getTime();
          disableInViewport = true;
        }
      }

      if (state.lobbyStatsIndex === 1) {
        lobbyPlayers = processPlayers(leagueStats.data['2-0-1'] ?? [], true);
      }
    }
    if (context === 'full') {
      if (state.selectorIndex === 0) {
        if (state.mainIndex === 0 && state.subIndex === 0) {
          fullGroups = processStandings(leagueStats.data['0-0-0'] ?? [], false);
        }

        if (state.mainIndex === 0 && state.subIndex === 1) {
          fullPlayers = processPlayers(leagueStats.data['0-0-1'] ?? [], false);
        }

        if (state.mainIndex === 2) {
          fullResults = processResults(leagueStats.data['0-2-0'] ?? [], false, true);
        }
      } else if (state.selectorIndex === 1) {
        if (state.mainIndex === 0 && state.subIndex === 0) {
          fullDraws = processDraws(leagueStats.data['1-2-0'] ?? [], false);
        }

        if (state.mainIndex === 0 && state.subIndex === 1) {
          fullPlayers = processPlayers(leagueStats.data['1-0-1'] ?? [], false);
        }

        if (state.mainIndex === 2) {
          fullResults = processResults(leagueStats.data['1-2-0'] ?? [], false);
        }
      } else if (state.selectorIndex === 2) {
        if (state.mainIndex === 0 && state.subIndex === 0) {
          fullGroups = processStandings(leagueStats.data['2-0-0'] ?? [], false);
        }

        if (state.mainIndex === 0 && state.subIndex === 1) {
          fullPlayers = processPlayers(leagueStats.data['2-0-1'] ?? [], false);
        }

        if (state.mainIndex === 0 && state.subIndex === 2) {
          [performanceKeys, fullPerformance] = processPerformance(
            leagueStats.data['2-0-2'] ?? [],
            isDesktop ? state.sortByIndex : state.performanceIndex,
            isDesktop ? state.performanceTypeIndex : state.sortByIndex,
            sortDirection,
          );
          if (performanceKeys.length && performanceKeys[state.performanceIndex]) {
            const sel: any = performanceKeys[state.performanceIndex];
            performanceKeyInfo = {
              label: sel.label,
              id: sel.id,
              abbrev: sel.abbrev,
              comp: false,
              sortByIndex,
            };

            if (fullPerformance?.[0]?.stats?.[sel.id]?.total) {
              performanceKeyInfo.comp = true;
            }
          } else {
            performanceKeyInfo = { label: '', id: '', abbrev: '' };
          }
        }

        if (state.mainIndex === 1) {
          fullFixtures = processResults(leagueStats.data['2-1-0'] ?? [], true, true);
        }

        if (state.mainIndex === 2) {
          fullResults = processResults(leagueStats.data['2-2-0'] ?? [], false, true);
        }
      } else if (state.selectorIndex === 3) {
        if (state.mainIndex === 0 && state.subIndex === 0) {
          fullDraws = processDraws(leagueStats.data['3-0-0'] ?? [], false);
        }

        if (state.mainIndex === 0 && state.subIndex === 1) {
          fullPlayers = processPlayers(leagueStats.data['3-0-1'] ?? [], false);
        }

        if (state.mainIndex === 1) {
          fullFixtures = processResults(leagueStats.data['3-1-0'] ?? [], true);
        }

        if (state.mainIndex === 2) {
          fullResults = processResults(leagueStats.data['3-2-0'] ?? [], false);
        }
      }
    }

    const res = {
      ...state,
      onIndexChange,

      isDesktop,

      sortByIndex,
      sortDirection,
      onSortChange,

      lobbyGroups: {
        overrideKey,
        disableInViewport,
        data: lobbyGroups,
      },

      lobbyPlayers: {
        data: lobbyPlayers,
      },

      fullGroups: {
        data: fullGroups,
      },
      fullDraws: {
        data: fullDraws,
      },
      fullPlayers: {
        data: fullPlayers,
      },
      fullPerformance: {
        selected: performanceKeyInfo,
        keys: performanceKeys,
        teams: fullPerformance,
      },

      fullFixtures: {
        data: fullFixtures,
      },
      fullResults: {
        data: fullResults,
      },
    };

    console.log('BetsLeagueStats[contextValue]', res);

    return res;
  }, [state, onIndexChange, onSortChange, leagueStats, context, isDesktop]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetsLeagueStats;
