import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const testRegex = /^[0-9]$/;
const debugMissingKeys = window.location.hostname === 'winner.test.wlscasino.com';
const exists: any = {};

// eslint-disable-next-line
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: window.config.languages,
    fallbackLng: 'en',
    lng: window.config.defaultLanguage,

    debug: false, //import.meta.env.DEV, // debug only on development mode
    nonExplicitSupportedLngs: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    nsSeparator: false,
    keySeparator: false,

    initImmediate: false, // setting initImediate to false, will load the resources synchronously

    backend: {
      loadPath: function (lng: string, _ns: string) {
        return `${window.config.translationApiUrl}/api/multilang/v1/translate/table/${window.config.translationProjectCode}/${lng}?tenant_id=${window.config.clientId}`;
      },
    },

    react: {
      useSuspense: false,
    },

    saveMissing: false, // debugMissingKeys,
    missingKeyHandler: (_lngs, _ns, key, fallbackValue) => {
      if (debugMissingKeys && key && !testRegex.exec(key[0])) {
        if (!exists[key]) {
          exists[key] = true;
          console.log('missingKeyHandler', { key, fallbackValue });
        }
      }
    },
  });

export const getLanguage = () => {
  return i18n.language ? i18n.language : 'en';
};

export default i18n;
