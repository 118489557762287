import { tournamentConstants } from './constants';

export const tournamentEnroll = ({ tournamentId, activate, cb }) => ({
  type: tournamentConstants.ENROLL,
  tournamentId,
  activate,
  cb,
});

export const tournamentEnrollResponse = ({ tournamentId, data }) => ({
  type: tournamentConstants.ENROLL_RESPONSE,
  tournamentId: tournamentId,
  data,
});

export const tournamentActivate = ({ tournamentId, cb }) => ({
  type: tournamentConstants.ACTIVATE,
  tournamentId,
  cb,
});

export const tournamentActivateResponse = ({ tournamentId, data }) => ({
  type: tournamentConstants.ACTIVATE_RESPONSE,
  tournamentId: tournamentId,
  data,
});
