import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    margin: '0',
    padding: '0',
    '&:active': {
      position: 'relative',
      top: '2px',
    }
  }
});

const BounceButton = (props) => {
  const classes = useStyles();

  const handleClick = (e) => {
    if (typeof props.onClick === 'function') {
      props.onClick(e);
    }
  };
  let rootStyle = {};
  if (props.style) {
    rootStyle = props.style;
  }

  // eslint-disable-next-line
  return (<div className={`${classes.root} ${props.className ? props.className : ''}`} style={rootStyle} onClick={handleClick}>
    {props.children}
  </div>);
};

export default BounceButton;