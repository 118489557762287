import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { liveScore, formatDate } from '../bets/utils/functions';
import { buildOutcomes, canCashout } from '../bets/prematch/sport';
import crests from '../../../modules/bets/utils/crests';
import { getData } from '../bets/utils/hooks';

//import './index.scss';

type UltraOddsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const UltraOdds = (componentProps: UltraOddsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const waMatches = useAppSelector((state) => state.bets.prematch.waMatches);
  const isWinnerFun = useAppSelector((state) => state.bets.app.isWinnerFun);
  const { bets, selectedBets } = getData({ type: 'prematch' });
  const { i18n, t } = useTranslation();

  const contextValue = React.useMemo(() => {
    const matches = JSON.parse(JSON.stringify(Object.values(waMatches)));

    matches.forEach((match: any) => {
      match.inPageSelected = false;
      match.canCashout = canCashout(match);

      match.matchDateTimeString = formatDate(match.matchDateTime, i18n.language, false);
      if (match.mType === 'live') match.scoreInfo = liveScore(match, match.currentStatus);

      match.outcomes = buildOutcomes(match, bets, selectedBets, i18n.language, 'Ultra Odds', isWinnerFun);

      match.team1LogoUrl = crests(match.team1Name, match, 1);
      match.team2LogoUrl = crests(match.team2Name, match, 2);
    });

    return {
      list: matches,
    };
  }, [waMatches, i18n, bets, selectedBets, isWinnerFun]);

  if (!contextValue.list?.length) {
    return null;
  }

  //console.log('UltraOdds[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default UltraOdds;
