import countriesMap from '../../common/countriesMap.json';

const testValues = (v1, v2) => {
  if (v1 === null) {
    return 1;
  }

  if (v2 === null) {
    return -1;
  }

  const iv1 = parseInt(v1 + '', 10);
  const iv2 = parseInt(v2 + '', 10);

  return iv1 - iv2;
};

export const sortArrayByKey = (a, p) => {
  a.sort((e1, e2) => {
    return testValues(e1[p], e2[p]);
  });
};

export const sortArrayByKey2 = (a, p1, p2) => {
  a.sort((e1, e2) => {
    const tv = testValues(e1[p1], e2[p1]);
    if (tv !== 0) {
      return tv;
    }

    return testValues(e1[p2], e2[p2]);
  });
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const hasIso2 = (iso3) => {
  if (iso3 in countriesMap) {
    return true;
  }
  return false;
};

export const getIso2 = (iso3) => {
  if (typeof iso3 === 'undefined' || iso3 === null || iso3 === '') {
    return 'undefined';
  }
  const ucISO3 = iso3.toUpperCase();
  if (ucISO3 in countriesMap) {
    return countriesMap[ucISO3].iso2Name.toLowerCase();
  }
  return iso3.toLowerCase();
};

export const arrayProxy = (arr) =>
  new Proxy(arr, {
    get(target, prop) {
      if (!isNaN(prop)) {
        const tl = target.length;
        prop = parseInt(prop, 10);
        /*
				if (prop < 0) {
					prop = (prop % tl) + tl;
				}
				if (prop >= tl) {
					prop = prop % tl;
				}
				*/
        prop = Math.abs(prop % tl);
      }
      return target[prop];
    },
  });

export const pushUnique = (a, v) => {
  if (a.find((x) => x === v)) {
    return;
  }

  a.push(v);
};

// from facebook

/*eslint-disable no-self-compare */

const hasOwnProperty = Object.prototype.hasOwnProperty;

/**
 * inlined Object.is polyfill to avoid requiring consumers ship their own
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
 */
const is = (x, y) => {
  // SameValue algorithm
  if (x === y) {
    // Steps 1-5, 7-10
    // Steps 6.b-6.e: +0 != -0
    // Added the nonzero y check to make Flow happy, but it is redundant
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    // Step 6.a: NaN == NaN
    return x !== x && y !== y;
  }
};

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
export const shallowEqual = (objA, objB) => {
  if (is(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }

  return true;
};

// compare to object by making sure each one has the same value in provided keys
export const compareObjsByKeys = (objA, objB, ks) => {
  if (is(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwnProperty.call(objB, keysA[i])) {
      return false;
    }

    const oa = objA[keysA[i]];
    const ob = objB[keysA[i]];

    for (let j = 0; j < ks.length; j++) {
      const k = ks[j];

      if (typeof oa[k] === 'undefined' || typeof ob[k] === 'undefined') {
        return false;
      }

      if (oa[k] !== ob[k]) {
        return false;
      }
    }
  }

  return true;
};

let debug = () => {
  /**/
};

if (typeof window !== 'undefined') {
  debug = window._cdebug;
  window._enableLottoDebug = () => {
    debug = window._cdebug;
  };
  window._disableLottoDebug = () => {
    debug = () => {
      /**/
    };
  };
}

export { debug };
