import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { appToggleWinnerFun } from '../../../../modules/bets/store/actions/app';
import { betsSlipClear } from '../../../../modules/bets/store/actions/betsSlip';

import './index.scss';

type EnableWinnerFunProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const EnableWinnerFun = (componentProps: EnableWinnerFunProps) => {
  const dispatch = useAppDispatch();
  const isWinnerFun = useAppSelector((state) => state.bets.app.isWinnerFun);

  if (isWinnerFun) {
    document.body.classList.add('winner-fun');
  } else {
    document.body.classList.remove('winner-fun');
  }

  const onToggleWinnerFun = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.enable) {
      const enable = e.currentTarget.dataset.enable === 'true';
      dispatch(appToggleWinnerFun(enable));

      if (isWinnerFun !== enable) {
        dispatch(betsSlipClear());
      }
    }
  };

  const contextValue = {
    isWinnerFun,
    onToggleWinnerFun,
  };

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default EnableWinnerFun;
