import moment from 'moment';
import 'moment/dist/locale/ro';

import { store } from '../../store';
import { getText } from '../../components/modules/wheel/utils/index';

export const specialDateToString = (date: string | number, format?: string) => {
  if (!date) return;
  const lang = store.getState().application.language;
  const useLongDateToStrings = window.config && window.config.useLongDateToStrings === '1';

  let mDate = null;

  if (lang === 'ro') {
    mDate = moment(date).locale('ro');
  } else {
    mDate = moment(date).locale('en');
  }

  const refDate = mDate;
  const now = moment();

  if (!useLongDateToStrings) {
    if (format) {
      return `${refDate.format(format)}`;
    } else {
      return `${refDate.format('DD MM YYYY')}`;
    }
  }

  let formattedString = `${refDate.format('DD MM YYYY')}`;

  // if in 7 days or more
  if (refDate.isSameOrAfter(now.add(7, 'days'), 'day')) {
    formattedString = `${mDate.format('DD MMMM')}`;
  }

  // if is less than 7 days
  if (refDate.isBetween(now, now.add(6, 'days'), 'day') || refDate.isSame(now.add(6, 'days'), 'day')) {
    formattedString = `${mDate.format('dddd')}`;
  }

  // if tomorrow
  if (refDate.isSame(now.add(1, 'days'), 'day')) {
    formattedString = `Tomorrow ${mDate.format('HH:mm')}`;
  }

  // if today
  if (refDate.isSame(now, 'day')) {
    formattedString = `Today ${mDate.format('HH:mm')}`;
  }

  return formattedString;
};

export const getTranslatedText = (original: any) => {
  const regExp = /<[^>]*>?/gm;

  const lng: string = store.getState().application.language ?? 'en';

  let text = original;

  if (typeof text === 'object') {
    if (text['0'] != null || text['2'] != null || text['42'] != null) {
      const lang: { [id: string]: string } = {
        en: '2',
        ro: '42',
      };

      if (text[lang[lng]]) {
        text = text[lang[lng]];
      } else {
        text = text['2'] ?? text['0'] ?? '';
      }
    } else if (text['en'] != null || text['ro'] != null) {
      text = getText(text, lng, '');
      if (text) text = text.replace(regExp, '');
    }
  } else if (text?.[0] === '{' && text?.[text.length - 1] === '}') {
    try {
      const tmp = JSON.parse(text);
      if (tmp.en != null || tmp.ro !== null) {
        text = getText(tmp, lng, '');
        if (text) text = text.replace(regExp, '');
      }
    } catch (e) {}
  }

  return text ?? '';
};

export const getIsOver30Days = (date: string | number) => {
  // returns true/false if date is over 30 days or not
  // returns null if date is invalid
  if (!date) {
    console.log('[getIsOver30Days]: no date provided ', date);
    return null;
  }

  const in30Days = moment().add(31, 'days');
  const mDate = moment(date);

  if (mDate.isValid() === false) {
    console.log('[getIsOver30Days]: date is invalid ', {
      momentDate: moment(date).format('DD MM YYYY'),
      in30Days,
      mDate,
      date,
    });

    return null;
  }

  const test = mDate.isSameOrAfter(in30Days, 'day');

  // console.log('[getIsOver30Days]: ', { date: moment(date).format('DD MM YYYY'), in30Days, test, mDate });
  return test;
};

/*
CHANGE REQ:

“Pays before” tiers must be also implemented following the new design. 

Formatting rules “Value”:
Pays before <value currency>; must use “Lei” instead of “RON”

Formatting rules “Date”:
“Pays before Dec, 24” / “Plateste inainte de 24 dec” 
when the target is 7 days or more in the future. 
A “24 december 20:00” is considered “24 december”, a “23 december 9:00” is considered “23 december”.

“Pays before Today 20:00” 
when the date is today.

“Pays before Tomorrow 20:00”
when the date is tomorrow.

“Pays before Sunday”
when the date is less than 6 or fewer days.    

*/

export const lottoDateToString = (date: string | number) => {
  if (!date) return;
  const lang = store.getState().application.language;

  let mDate = null;

  if (lang === 'ro') {
    mDate = moment(date).locale('ro');
  } else {
    mDate = moment(date).locale('en');
  }

  const refDate = mDate;
  const now = moment();
  // const now = moment('2023-09-08 00:00').valueOf();

  let formattedString = `${refDate.format('DD MMM, HH:mm')}`;

  // if in 7 days or more
  if (refDate.isSameOrAfter(now.add(7, 'days'), 'day')) {
    formattedString = `${mDate.format('DD MMMM')}`;
  }

  // if is less than 7 days
  if (refDate.isBetween(now, now.add(6, 'days'), 'day') || refDate.isSame(now.add(6, 'days'), 'day')) {
    formattedString = `${mDate.format('dddd')}`;
  }

  // if tomorrow
  if (refDate.isSame(now.add(1, 'days'), 'day')) {
    formattedString = `Tomorrow ${mDate.format('HH:mm')}`;
  }

  // if today
  if (refDate.isSame(now, 'day')) {
    formattedString = `Today ${mDate.format('HH:mm')}`;
  }

  return formattedString;
};

export const MONTHS: any = {
  '1': 'Ian',
  '2': 'Feb',
  '3': 'Mart',
  '4': 'Apr',
  '5': 'Mai',
  '6': 'Iun',
  '7': 'Iul',
  '8': 'Aug',
  '9': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};
