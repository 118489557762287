import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import ButtonBase from '@material-ui/core/ButtonBase';

import BounceButton from '../../elements/BounceButton';
import DeleteIcon from './assets/delete.svg';
import FavIcon from './assets/favorite.svg';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    borderRadius: '7px',
    padding: '13px 6px 2px',
    marginBottom: '10px'
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  number: {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    border: '1.5px solid #00C7B2',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    paddingTop: '2px',
    margin: '3px 0',
    background: 'linear-gradient(180deg, rgba(6,210,189,1) 0%, rgba(0,51,17,1) 100%)',
  },
  emptyNumber: {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    border: '1.5px solid #E9E9E9',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
    color: '#C7CCE1',
    paddingTop: '2px',
    margin: '3px 0',
    background: '#fff',
  },
  hr: {
    borderTop: '1px solid #C3CFD9',
    width: '95%',
    margin: '10px auto 5px'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  button: {
    margin: '4px'
  }
});

const Event = (props) => {
  const classes = useStyles();
  const { numbers, index, onDelete, onSelect, t } = props;

  const handleDelete = (ev) => {
    onDelete(index, ev);
  };

  const handleSelect = (ev) => {
    onSelect(index, ev);
  };

  return (
    <div className={classes.root}>
      <div className={classes.numbers}>
        <div className={classes.line}>
          {[...Array(10).keys()].map(i => {
            if (typeof numbers[i] != 'undefined') {
              return <div key={i} className={classes.number}>{numbers[i]}</div>;
            }
            return <div key={i} className={classes.emptyNumber}>-</div>;
          })}
        </div>
        <div className={classes.line}>
          {[...Array(10).keys()].map(i => {
            if (typeof numbers[i + 10] != 'undefined') {
              return <div key={i + 10} className={classes.number}>{numbers[i + 10]}</div>;
            }
            return <div key={i + 10} className={classes.emptyNumber}>-</div>;
          })}
        </div>
      </div>
      <div className={classes.hr}></div>
      <div className={classes.actions}>
        <BounceButton>
          <ButtonBase onClick={handleDelete} variant="contained" className={classes.button}>
            <img src={DeleteIcon} alt='' />
          </ButtonBase>
        </BounceButton>
        <BounceButton>
          <ButtonBase onClick={handleSelect} variant="contained" className={classes.button}>
            <img src={FavIcon} alt='' />
          </ButtonBase>
        </BounceButton>
      </div>
    </div>
  );
};

export default withTranslation()(Event);