import { createSelector } from 'reselect';

const emptyArray = [];

const getWallet = state => state.wallet;

export const getBetsBonuses = createSelector([getWallet], wallet => {
    return window.config.useBonusEvaluation === '1' && wallet && wallet.bonuses ? wallet.bonuses.filter(w => w.eligibleProducts.indexOf(2) !== -1) : emptyArray;
});

export const getBetsRingFences = createSelector([getWallet], wallet => {
    return window.config.useBonusEvaluation === '1' && wallet && wallet.ringFences ? wallet.ringFences.filter(w => w.eligibleProducts.indexOf(2) !== -1) : emptyArray;
});
