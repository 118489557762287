import React from 'react';
import { useAppSelector } from '../../store';

import craftJsParser from '../../components/utils/craftJsParser';
import transferPropertiesToComponent from './transferPropertiesToComponent';
import { DataElementContext } from '../common/DataElementContext';
import { uniqueId } from 'lodash-es';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

export const defaultProps = {
  properties: {
    componentDataId: null,
    componentDataVersion: null,
    componentId: null,
    componentVersion: null,
  },
};

export const ComponentData = (componentProps) => {
  let props = componentProps;

  const components = useAppSelector((state) => state.templatesConfig.components);
  const componentsData = useAppSelector((state) => state.templatesConfig.componentsData);
  const componentsDataRef = useAppSelector((state) => state.templatesConfig.componentsDataRef);
  const setComponentsDefaults = useAppSelector((state) => state.templatesConfig.setComponentsDefaults);
  const setComponentsDataDefaults = useAppSelector((state) => state.templatesConfig.setComponentsDataDefaults);
  const authenticated = useAppSelector((state) => state.authentication && state.authentication.auth_type === 'user');

  const [__id] = React.useState(uniqueId('unique-'));
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const { componentId: cId, componentDataId } = props.properties;
  const componentId = componentsDataRef[componentDataId] ?? cId;
  const rootProps = React.useRef({});

  let content = React.useMemo(() => {
    try {
      const projectData = components[componentId].projectData;
      const cdProperties = componentsData[componentDataId] != null ? componentsData[componentDataId] : {};

      const setCompontsDefaultsValue =
        setComponentsDefaults[componentId] != null ? setComponentsDefaults[componentId] : {};

      const visualIdentity =
        setComponentsDataDefaults[`${componentId}|${componentDataId}`] != null
          ? setComponentsDataDefaults[`${componentId}|${componentDataId}`]
          : {};

      const finalCDProperties = { ...cdProperties, ...visualIdentity };

      const data = JSON.parse(projectData);

      //console.log(`setCompontsDefaults[${componentId}]`, setCompontsDefaults);

      if (setCompontsDefaultsValue && Object.keys(setCompontsDefaultsValue).length)
        transferPropertiesToComponent(data, setCompontsDefaultsValue);
      if (finalCDProperties && Object.keys(finalCDProperties).length)
        transferPropertiesToComponent(data, finalCDProperties);

      let rootNodeId = 'ROOT';

      if (rootNodeId) {
        data[rootNodeId].type = 'Container';
        data[rootNodeId].props.styleText = 'width:100%;';

        Object.keys(data[rootNodeId].props.properties).forEach((key) => {
          /* dsId, deId, dsType, expose */
          const omittedKeys = { dsId: true, deId: true, dsType: true, expose: true };
          if (!omittedKeys[key]) {
            rootProps.current[key] = data[rootNodeId].props.properties[key];
          }
        });

        if (data[rootNodeId].nodes && data[rootNodeId].nodes.length === 1) {
          rootNodeId = data[rootNodeId].nodes[0];
        }

        if (window !== window.parent && data?.[rootNodeId] && componentProps?.id) {
          data[rootNodeId].id = componentProps.id;
          data[componentProps.id] = data[rootNodeId];
          rootNodeId = componentProps.id;
        }

        return craftJsParser({
          craftState: data,
          rootNodeId: rootNodeId,
          pageId: componentDataId,
          pageLayoutId: componentId,
          pageType: 'componentsDataRef',
          options: {
            authenticated,
          },
          withProvider: true,
        });
      }
    } catch (err) {
      console.error('ComponentData', err);
    }
    return null;
  }, [
    components, componentsData, setComponentsDefaults, setComponentsDataDefaults,
    authenticated, componentId, componentDataId
  ]);

  const contextValue = React.useMemo(() => {
    let contextValue = {
      ...rootProps.current,
      parentContext: dataElementContext,
      __id,
    };
    return contextValue;
  }, [dataElementContext]);

  //console.log('ComponentData[contextValue]', contextValue);

  return <DataElementContext.Provider value={contextValue}>{content}</DataElementContext.Provider>;
};
