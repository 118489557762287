import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import {
  momentumProgressDetails,
  starThresholds,
  triggerGTMEvent,
} from '../../../../modules/momentum/utils/functions.js';
import { getMomentumBonuses } from '../../../../modules/momentum/store/actions/momentum';
import { formatCurrency } from '@/modules/bets/utils/formatters';

type MomentumGameHeaderNotificationProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);
const emptyArray: any = [];

const MomentumGameHeaderNotification = (componentProps: MomentumGameHeaderNotificationProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const momentumPrize = useAppSelector<any>((state) => state.momentum.prize?.value);
  const momentumEligibleGames = useAppSelector<any>((state) =>
    state.momentum?.prize?.eligibleGames ? state.momentum.prize.eligibleGames : emptyArray,
  );
  const momentumPrizeLoaded = useAppSelector<any>((state) => state.momentum.prize?.loaded);

  const [state, setState] = React.useState({
    show: false,
    eligible: false,
    shown: false,
  });
  const tid = React.useRef<any>(0);

  React.useEffect(() => {
    if (momentumPrizeLoaded && !state.shown && momentumEligibleGames.length > 0) {
      const parts = location.pathname.split('/');
      if (parts[1] === 'slot-game') {
        const gameId = parseInt(parts[2], 10);
        if (!isNaN(gameId)) {
          const includesGame = momentumEligibleGames.includes(gameId);
          if (includesGame && !momentumPrize?.active) {
            setState({ show: true, eligible: true, shown: true });
          } else if (!includesGame) {
            setState({ show: true, eligible: false, shown: true });
          }
        }
      }
    }
  }, [momentumPrizeLoaded, state]);

  React.useEffect(() => {
    if (state.show) {
      clearTimeout(tid.current);
      tid.current = setTimeout(() => {
        setState((v) => ({ ...v, show: false }));
      }, 5000);
    }
  }, [state]);

  const onDismiss = React.useCallback(() => {
    setState((v) => ({ ...v, show: false }));
  }, []);

  const contextValue = React.useMemo(
    () => ({
      ...state,
      onDismiss,
    }),
    [state, onDismiss],
  );

  if (
    window.config.momentumEnabled !== '1' ||
    !momentumPrizeLoaded ||
    momentumEligibleGames.length === 0 ||
    momentumPrize === null
  ) {
    return null;
  }
  //console.log('MomentumGameHeaderNotification[contextValue]: ', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default MomentumGameHeaderNotification;
