import * as actionTypes from './../actions/actionTypes';

const initialState = {
  leftMenu: false,
  items: null,
  allowedPageTypes: ['root-level', 'reset-password', 'connect', 'withdraw', 'test', 'profile'],
  topMenu: false,
  rightMenu: false,
  showProfileSection: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.menu.LEFT_OPEN:
      return {
        ...state,
        leftMenu: true,
      };
    case actionTypes.menu.LEFT_CLOSE:
      return {
        ...state,
        leftMenu: false,
      };
    case actionTypes.menu.REQUEST_MENU:
      return {
        ...state,
      };
    case actionTypes.menu.RECEIVED_MENU:
      const items = {};
      action.items &&
        Object.keys(action.items).forEach((key) => {
          if (key !== 'cazino') {
            items[key] = action.items[key];
          } else {
            const value = action.items[key];
            let newKey = 'cazino';

            if (window.config.lobbyEnabled === '1') {
              if (window.config.rootPaths && window.config.rootPaths.casino) {
                newKey = window.config.rootPaths.casino;
                value.code = newKey;
              }
            }

            items[newKey] = value;
          }
        });

      return {
        ...state,
        items: items,
      };
    case actionTypes.menu.CREATE_PAGE_TYPES:
      const allowed = [...state.allowedPageTypes];
      action.items.forEach((item) => {
        if (allowed.includes(item)) {
          return;
        }

        allowed.push(item);
      });

      if (window.config.lobbyEnabled === '1') {
        if (window.config.rootPaths && window.config.rootPaths.casino) {
          allowed.push(window.config.rootPaths.casino);
        }
      }

      return {
        ...state,
        allowedPageTypes: allowed,
      };
    case actionTypes.menu.TOP_MENU_SHOW:
      return {
        ...state,
        topMenu: action.value,
      };
    case actionTypes.menu.RIGHT_OPEN:
      return {
        ...state,
        rightMenu: true,
      };
    case actionTypes.menu.RIGHT_CLOSE:
      return {
        ...state,
        rightMenu: false,
      };
    case actionTypes.menu.SHOW_PROFILE_SECTION:
      return {
        ...state,
        showProfileSection: action.show,
      };
    default:
      return state;
  }
};

export default reducer;
