import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  loaded: false,
  loadedViva: false,
  loadedHistory: false,
  loadingLocations: false,
  loadingTax: false,
  loadingCancel: false,
  loadingVerification: false,
  verificationData: null,
  locations: null,
  cancelWithdrawal: null,
  requests: [],
  pending: [],
  approved: [],
  history: [],
  amount: 0,
  depositTax: 0,
  taxes: {},
  paymentMethods: [],
};

const withdrawalStates = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  PROCESSED: 4,
  CANCELLED: 5,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.withdrawals.RECEIVED_PENDING_WITHDRAWALS:
      let data = action.withdrawals.result;
      let src = action.withdrawals.type;
      let pending = state.pending.filter((elem) => elem['src'] !== src);
      let approved = state.approved.filter((elem) => elem['src'] !== src);
      let history = state.history.filter((elem) => elem['src'] !== src);
      let amount = 0.0;
      if (data.length > 0) {
        data.forEach((elem) => {
          if (src === 'withdraw-history') {
            let newElem = {
              amount: parseInt(elem.amount / 100),
              // card_expiration_date: false,
              // card_number: false,
              // card_type_id: false,
              cancelled: [],
              currency_id: elem.currency,
              date: elem.insertDate,
              id: elem.id,
              src: src,
              status_id: elem.status,
              transaction_id: elem.id,
              withdrawType: elem.withdrawType,
            };
            elem = newElem;
            if (elem.amount <= 0) {
              return;
            }
          }
          if (typeof elem['status_id'] === 'undefined') {
            return;
          }
          //set source
          elem['src'] = src;
          createCancelledItems(elem, history);

          let statusId = Number.parseInt(elem.status_id, 10);
          switch (statusId) {
            case withdrawalStates.PENDING:
              pending.push(elem);
              break;
            case withdrawalStates.APPROVED:
              approved.push(elem);
              break;
            case withdrawalStates.CANCELLED:
            case withdrawalStates.REJECTED:
            case withdrawalStates.PROCESSED:
              history.push(elem);
              break;
            default:
              break;
          }
        });

        history.sort(dateSort);
        pending.sort(dateSort);
        approved.sort(dateSort);
      }
      pending.forEach((transaction) => {
        amount += parseFloat(transaction.amount);
      });
      return {
        ...state,
        loading: false,
        loaded: state.loaded || action.opType === 'approved',
        loadedViva: state.loadedViva || action.opType === 'pending',
        loadedHistory: state.loadedHistory || action.opType === 'history',
        requests: data,
        pending: pending,
        approved: approved,
        history: history,
        amount: amount,
      };
    case actionTypes.withdrawals.CLEAR_LOADED_STATES:
      return {
        ...state,
        loaded: false,
        loadedViva: false,
      };
    case actionTypes.withdrawals.REQUEST_PENDING_WITHDRAWALS:
    case actionTypes.withdrawals.REQUEST_PENDING_VIVA_WITHDRAWALS:
      return {
        ...state,
        loading: true,
        cancelWithdrawal: null,
      };
    case actionTypes.withdrawals.RECEIVED_LOCATIONS:
      return {
        ...state,
        loadingLocations: false,
        locations: action.withdrawals.result.locations,
      };
    case actionTypes.withdrawals.REQUEST_LOCATIONS:
      return {
        ...state,
        loadingLocations: true,
      };
    case actionTypes.withdrawals.RECEIVED_TAX_SERVICE:
      if (action.withdrawals.result.type && action.withdrawals.result.type === 2) {
        return {
          ...state,
          depositTax: action.withdrawals.result.tax,
          taxes: {
            ...state.taxes,
            [action.withdrawals.result.id]: {
              amount: action.withdrawals.result.amount,
              tax: action.withdrawals.result.tax,
            },
          },
          loadingTax: false,
        };
      } else {
        return {
          ...state,
          taxes: {
            ...state.taxes,
            [action.withdrawals.result.id]: {
              amount: action.withdrawals.result.amount,
              tax: action.withdrawals.result.tax,
            },
          },
          loadingTax: false,
        };
      }
    case actionTypes.withdrawals.REQUEST_TAX_SERVICE:
      return {
        ...state,
        loadingTax: true,
      };
    case actionTypes.withdrawals.CLEAR_TAX_SERVICE:
      return {
        ...state,
        loadingTax: false,
        depositTax: 0,
        taxes: {},
      };
    case actionTypes.withdrawals.RECEIVED_CANCEL_WITHDRAWAL:
      return {
        ...state,
        cancelWithdrawal: {
          result: action.withdrawals.result,
          src: action.withdrawals.type,
        },
        loadingCancel: false,
      };
    case actionTypes.withdrawals.REQUEST_CANCEL_WITHDRAWAL:
    case actionTypes.withdrawals.REQUEST_CANCEL_VIVA_WITHDRAWAL:
    case actionTypes.withdrawals.REQUEST_CANCEL_WITHDRAWAL_NEW:
      return {
        ...state,
        loadingCancel: true,
      };
    case actionTypes.withdrawals.CLEAN_VERIFICATION_CODE:
      return {
        ...state,
        verificationData: null,
        loadingVerification: false,
      };
    case actionTypes.withdrawals.RECEIVED_VERIFICATION_CODE:
      let verificationData = null;
      if (action.data) {
        let now = parseInt(new Date().getTime() / 1000, 10);
        let timestamp = action.data.validation_code_expire;
        let remaining = (timestamp - now) / 3600;

        let hours = parseInt(parseInt(remaining * 3600, 10) / 3600, 10);
        let minutes = parseInt((parseInt(remaining * 3600, 10) - hours * 3600) / 60, 10);
        let seconds = parseInt(parseInt(remaining * 3600, 10) - minutes * 60 - hours * 3600, 10);

        verificationData = {
          ...state.verificationData,
          [action.withdrawID]: {
            remainingMinutes: minutes,
            remainingSeconds: seconds,
            ...action.data,
          },
        };
      }
      return {
        ...state,
        verificationData: verificationData,
        loadingVerification: false,
      };
    case actionTypes.withdrawals.REQUEST_VERIFICATION_CODE:
      return {
        ...state,
        loadingVerification: true,
      };
    case actionTypes.withdrawals.RECEIVED_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.data,
      };
    default:
      return state;
  }
};

function createCancelledItems(elem, history) {
  if (Object.keys(elem.cancelled).length > 0) {
    Object.keys(elem.cancelled).forEach((cancelId) => {
      if (elem.cancelled[cancelId].canceled_amount > 0) {
        let hItem = {};
        hItem.id = cancelId.toString();
        hItem.status_id = withdrawalStates.CANCELLED;
        hItem.amount = elem.cancelled[cancelId].canceled_amount;
        hItem.date = elem.cancelled[cancelId].date;
        hItem.location_id = elem.location_id;
        hItem.src = elem.src;
        history.push(hItem);
      }
    });
  }
}

function dateSort(a, b) {
  let comparison = 0;
  if (a.date > b.date) {
    comparison = -1;
  } else if (a.date < b.date) {
    comparison = 1;
  }

  return comparison;
}

export default reducer;
