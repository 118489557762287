import { put } from 'redux-saga/effects';
import * as actions from '../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import * as paths from '../../ClientAPI/paths';
import moment from 'moment';

export function* requestTransactionsHistorySaga(action) {
  const axios = ClientAPI.getInstance();
  const storeState = ClientAPI.getStore().getState();
  let requestType = storeState.transactions.requestType;

  let data = {
    type: requestType,
    responseType: 'transactions-history',
    startDate: moment().subtract(7, 'day').unix(),
    endDate: moment().unix(),
  };

  if (action.showMore) {
    data = {
      ...data,
      rawData: true,
      export: 1,
    };
  }

  try {
    const response = yield axios({
      url: paths.transactions.TRANSACTIONS_HISTORY,
      method: 'post',
      data: data,
    });

    if (!response) {
      throw new Error('[ERROR] Transactions History response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Transactions History response has no 'result' property");
    }

    yield put(actions.receivedTransactionsHistory(response));
  } catch (error) {
    console.log(error);
    yield put(actions.receivedTransactionsHistory([]));
    return;
  }
}

export const fetchTransactionsDumpHistory = (type) => {
  const axios = ClientAPI.getInstance();
  const store = ClientAPI.getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return new Promise((resolve, reject) => {
      reject(new Error('Not Authenticated'));
    });
  }

  return new Promise((resolve) => {
    axios({
      url: paths.transactions.TRANSACTIONS_HISTORY,
      method: 'post',
      data: {
        type: type,
        responseType: 'transactions-history',
        rawData: true,
        export: 1,
      },
    })
      .then((response) => {
        resolve(response && response.result ? response.result : []);
      })
      .catch((err) => {
        console.log('fetchInOutDumpHistory: err', err);
        resolve([]);
      });
  });
};
