import {
  validateFieldResponse,
  sendRegisterSMSResponse,
  registerResponse,
  partialAccountResponse,
} from '../actions/register';
import ClientAPI from './../../ClientAPI/ClientAPI';
import { put } from 'redux-saga/effects';
import { validation, register } from '../../ClientAPI/paths';
import PlayOnline from '@/utils/marketing/Providers/PlayOnline';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker.js';

// import ExpiringLocalStorage from '../../utils/ExpiringLocalStorage';

const parameters = {
  email: 'mail',
  phone: 'phone',
  cnp: 'cnp',
  nin: 'cnp',
  nickname: 'nickname',
};
export function* validateFieldSaga(action) {
  const axios = ClientAPI.getInstance();

  try {
    let data = {};
    data[parameters[action.field]] = action.value;

    const response = yield axios({
      url: validation[action.field],
      method: 'post',
      data: data,
    });

    if (!response.status) {
      throw new Error(`[ERROR] ${action.field} Validation missing status!`);
    }

    if (!('OK' === response.status)) {
      throw new Error(`[ERROR] ${action.field} Validation not triggered!`);
    }

    yield put(validateFieldResponse(response.result, action.field));
  } catch (error) {
    console.log(error);
  }
}

export function* sendRegisterSMSSaga(action) {
  const axios = ClientAPI.getInstance();
  try {
    const response = yield axios({
      url: register.SEND_REGISTER_SMS,
      method: 'post',
      data: {
        phone: action.phone,
      },
    });

    if (!response.status) {
      throw new Error('[ERROR] SMS not sent!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] SMS not sent!');
    }

    yield put(sendRegisterSMSResponse(response.result));
  } catch (error) {
    console.log(error);
  }
}

export function* registerSaga(action) {
  const axios = ClientAPI.getInstance();
  const { data } = action;
  let pac = PlayerAbuseChecker.getInfo();

  try {
    const response = yield axios({
      url: register.REGISTER,
      method: 'post',
      data: {
        ...pac,
        ...data,
      },
    });

    if (!response.status) {
      throw new Error('[ERROR] Register not sent!');
    }

    yield put(registerResponse(response.result));
  } catch (error) {
    yield put(registerResponse(false));
  }
}

export function* setPartialAccountSaga(action) {
  const axios = ClientAPI.getInstance();
  try {
    const response = yield axios({
      url: '/api/register/setPartial',
      method: 'post',
      data: action.data,
    });

    if (!response.status) {
      throw new Error('[ERROR] REGISTER missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] REGISTER Validation not triggered!');
    }
    PlayOnline.setPaid(response.result.paid);
    yield put(partialAccountResponse({ success: true }));
  } catch (error) {
    yield put(partialAccountResponse({ success: false }));
  }
}
