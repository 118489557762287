import { ticketConstants } from './constants';

export const ticketOpenedListRequest = (data) => ({
	type: ticketConstants.OPENED_LIST_REQUEST,
	data
});

export const ticketWinnerFunOpenedListRequest = (data) => ({
	type: ticketConstants.WINNER_FUN_OPENED_LIST_REQUEST,
	data
});

export const ticketSettledListRequest = data => ({
	type: ticketConstants.SETTLED_LIST_REQUEST,
	data
});

export const ticketWinnerFunSettledListRequest = data => ({
	type: ticketConstants.WINNER_FUN_SETTLED_LIST_REQUEST,
	data
});

export const ticketReservedListRequest = (data) => ({
	type: ticketConstants.RESERVED_LIST_REQUEST,
	data
});

export const ticketOpenedListReceived = data => ({
	type: ticketConstants.OPENED_LIST_RECEIVED,
	data
});

export const ticketWinnerFunOpenedListReceived = data => ({
	type: ticketConstants.WINNER_FUN_OPENED_LIST_RECEIVED,
	data
});

export const ticketSettledListReceived = data => ({
	type: ticketConstants.SETTLED_LIST_RECEIVED,
	data
});

export const ticketWinnerFunSettledListReceived = data => ({
	type: ticketConstants.WINNER_FUN_SETTLED_LIST_RECEIVED,
	data
});

export const ticketReservedListReceived = data => ({
	type: ticketConstants.RESERVED_LIST_RECEIVED,
	data
});

export const ticketListError = error => ({
	type: ticketConstants.LIST_ERROR,
	error
});

export const ticketListRequesting = data => ({
	type: ticketConstants.REQUESTING_DATA,
	data
});

export const ticketWinnerFunListError = error => ({
	type: ticketConstants.WINNER_FUN_LIST_ERROR,
	error
});

export const ticketWinnerFunListRequesting = data => ({
	type: ticketConstants.WINNER_FUN_REQUESTING_DATA,
	data
});

export const ticketCancelRequest = ticketId => ({
	type: ticketConstants.CANCEL_REQUEST,
	ticketId
});

export const ticketCancelSuccess = () => ({
	type: ticketConstants.CANCEL_SUCCESS
});

export const ticketCancelError = error => ({
	type: ticketConstants.CANCEL_ERROR,
	error
});

export const ticketStoreOpen = data => ({
	type: ticketConstants.STORE_OPEN,
	data
});

export const ticketWinnerFunStoreOpenPrepare = data => ({
	type: ticketConstants.WINNER_FUN_STORE_OPEN_PREPARE,
	data
});

export const ticketWinnerFunStoreOpen = data => ({
	type: ticketConstants.WINNER_FUN_STORE_OPEN,
	data
});

export const ticketCheckCode = code => ({
	type: ticketConstants.CHECK_CODE,
	code
});

export const ticketCheckCodePending = () => ({
	type: ticketConstants.CHECK_CODE_PENDING
});

export const ticketCheckCodeData = (data, code) => ({
	type: ticketConstants.CHECK_CODE_DATA,
	data,
	code
});

export const ticketCheckCodeError = error => ({
	type: ticketConstants.CHECK_CODE_ERROR,
	error
});

export const ticketCheckCodeClose = () => ({
	type: ticketConstants.CHECK_CODE_CLOSE
});

export const ticketReloadPrematch = t => ({
	type: ticketConstants.RELOAD_PREMATCH,
	t
});

export const ticketReloadLive = t => ({
	type: ticketConstants.RELOAD_LIVE,
	t
});

export const ticketHandleUpdated = ts => ({
	type: ticketConstants.HANDLE_UPDATED,
	ts
});

export const ticketWinnerFunReloadPrematch = t => ({
	type: ticketConstants.WINNER_FUN_RELOAD_PREMATCH,
	t
});

export const ticketWinnerFunReloadLive = t => ({
	type: ticketConstants.WINNER_FUN_RELOAD_LIVE,
	t
});

export const ticketWinnerFunHandleUpdated = ts => ({
	type: ticketConstants.WINNER_FUN_HANDLE_UPDATED,
	ts
});

export const ticketStoreMeta = (meta, matches) => ({
	type: ticketConstants.STORE_META,
	meta,
	matches
});

export const ticketHandleCashouted = (ticketHash) => ({
	type: ticketConstants.HANDLE_CASHOUTED,
	ticketHash,
});

export const ticketWinnerFunHandleCashouted = (ticketHash) => ({
	type: ticketConstants.WINNER_FUN_HANDLE_CASHOUTED,
	ticketHash,
});

export const ticketSetBetradarId = () => ({
	type: ticketConstants.SET_BRID
});
