import * as actionTypes from './actionTypes';

export const configSet = (data) => ({
  type: actionTypes.config.SET,
  data,
});

export const configLoad = (step) => ({
  type: actionTypes.config.LOAD,
  step,
});
