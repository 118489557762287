import React from 'react';
import styled from 'styled-components';

const defaultProps = {
  className: '',
  styleText: `
  width: 100%;
  height: auto;
  `,
};

const StateScrollerItemsDiv = styled.div`
  width: 100%;
  white-space: nowrap;
  pointer-events: all;
  padding-bottom: 5px;
  padding-top: 5px;
  overflow: visible;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  &:before {
    content: ' ';
    min-width: 9px;
    max-width: 9px;
  }

  &:after {
    content: ' ';
    min-width: 9px;
    max-width: 9px;
  }

  ${(props) => props.$styleText}
`;

export const StateScrollerItems = (componentProps) => {
  return (
    <StateScrollerItemsDiv
      className={componentProps.className ?? ''}
      $styleText={componentProps.styleText != null ? componentProps.styleText : defaultProps.styleText}
      style={componentProps.style}
    >
      {componentProps.children}
    </StateScrollerItemsDiv>
  );
};
