import { tournamentGroupsConstants } from './constants';

export const loadTournamentsGroups = () => ({
  type: tournamentGroupsConstants.LOAD,
});

export const loadingTournamentsGroups = () => ({
  type: tournamentGroupsConstants.LOADING,
});

export const loadedTournamentsGroups = (groups, withAuthentication) => ({
  type: tournamentGroupsConstants.LOADED,
  groups,
  withAuthentication,
});

export const resetLoadedTournamentsGroups = (groups) => ({
  type: tournamentGroupsConstants.RESET_LOADED,
  groups,
});

export const rerenderTournamentsGroups = () => ({
  type: tournamentGroupsConstants.RERENDER,
});

export const tournamentEnded = ({ tournamentId, missionGroupId }) => ({
  type: tournamentGroupsConstants.TOURNAMENT_ENDED,
  tournamentId,
  missionGroupId,
});

export const tournamentCreated = ({ tournamentId, missionGroupId, data }) => ({
  type: tournamentGroupsConstants.TOURNAMENT_CREATED,
  tournamentId,
  missionGroupId,
  data,
});

export const toggleHappyHoursSelected = (selected) => ({
  type: tournamentGroupsConstants.HAPPY_HOURS_TOGGLE,
  selected: selected,
});
