import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';
import moment from 'moment';

import { betsSlipConstants, ticketConstants } from '../actions/constants';

import {
  betSlipLottoTicketCreateSuccess,
  //	betsSlipLottoTicketCreateManualAuth,
  betSlipLottoTicketProcessingError,
  betSlipLottoTicketCreateError,
  betSlipLottoTicketProcessingResult,
} from '../actions/betsSlip';
import {
  ticketOpenedListReceived,
  ticketSettledListReceived,
  ticketReservedListReceived,
  ticketListError,
  ticketListRequesting,
  ticketStoreOpen,
  ticketCheckCodePending,
  ticketCheckCodeData,
  ticketCheckCodeError,
} from '../actions/tickets';

import getStore from '../../store';
import { getLottoState } from '../selectors/lottoData';

import { lottoConfig } from '../../api/config/lotto';

import { uuidv4 } from '../../utils';

import { playerBetsApi } from './app';

export const ticketHistoryRequest = async (dateStart) => {
  const state = getStore().getState();
  const lst = getLottoState(state);

  const { auth } = lst;

  if (!auth || !auth.token) {
    return;
  }

  let done = false;
  let page = 1;
  let tickets = [];

  while (!done) {
    const { data } = await axios.post(
      lottoConfig.listTickets,
      {
        rowsPerPage: 0,
        page,
        exactFields: ['is_valid', 'status', '>save_time'],
        exactValues: [[1, 5], [0, 1, 2, 3], [dateStart]],
      },
      {
        headers: {
          Authorization: auth.token,
        },
      },
    );

    if (data.data && data.data.rows) {
      tickets.push(...data.data.rows);
      if (data.data.rows.length < 10000) {
        done = true;
      }
    } else {
      done = true;
    }

    page = page + 1;
  }

  return tickets;
};

function* lottoCreateTicketSaga() {
  const state = getStore().getState();

  const { auth, betsSlip } = getLottoState(state);
  const lt = betsSlip.lottoTicket;

  const ticketReq = {
    ticket_id: uuidv4(),
    time: '' + new Date().getTime(),
    numbers: lt.numbers,
    systems: lt.systems,
    eevent_ids: [lt.selectedEvents[0]],
    amount: betsSlip.amount,
    version: 2,
  };

  try {
    const { data } = yield axios.post(lottoConfig.createTicket, ticketReq, {
      headers: {
        Authorization: auth.token,
      },
    });

    yield put(ticketStoreOpen([data.data.ticket]));
    yield put(betSlipLottoTicketCreateSuccess());
  } catch (e) {
    console.log('error creating ticket', e);
    yield put(betSlipLottoTicketCreateError(e.toString()));
  }
}

function* lottoProcessNextTicket() {
  const state = getStore().getState();

  const { auth, betsSlip } = getLottoState(state);
  const tickets = betsSlip.ticketRegistration.tickets;
  const currentIndex = betsSlip.ticketRegistrationIndex;
  const allSelectedEvents = betsSlip.lottoTicket.allSelectedEventsFull;
  const ticketOnline = betsSlip.ticketOnline;
  const ticket = tickets[currentIndex];

  if (ticketOnline) {
    const ticketReq = {
      ticket_id: uuidv4(),
      time: '' + new Date().getTime(),
      numbers: ticket.numbers,
      systems: ticket.systems,
      eevent_ids: [ticket.event_id],
      amount: ticket.amount,
      version: 2,
      bonus_user_data: ticket.bonus_user_data ? ticket.bonus_user_data : '[]',
    };

    try {
      const { data } = yield axios.post(lottoConfig.createTicket, ticketReq, {
        headers: {
          Authorization: auth.token,
        },
      });

      data.data.ticket.ticketOnline = true;

      yield put(betSlipLottoTicketProcessingResult(data.data.ticket));
    } catch (e) {
      console.log('error processing ticket', e);
      if (e && e.response && e.response.data && e.response.data.details && Array.isArray(e.response.data.details)) {
        yield put(betSlipLottoTicketProcessingError(e.response.data.details.join(';')));
      } else {
        yield put(betSlipLottoTicketProcessingError(e.toString()));
      }
    }
  } else {
    const ticketReqOffiline = {
      numbers: ticket.numbers,
      systems: ticket.systems,
      eevent_id: ticket.event_id,
      amount: ticket.amount,
    };

    try {
      const { data } = yield axios.post(lottoConfig.reserveTicket, ticketReqOffiline);

      let ticket = {};
      if (data.data && data.data.length) {
        ticket = data.data[0];
      }

      const event = allSelectedEvents.find((e) => e.event_id === ticket.eevent_id);
      if (event) {
        const finalTicket = {
          amount: ticket.amount,
          custom_odds: '{}',
          event_K: event.k,
          event_M: event.m,
          event_N: event.n,
          event_R: event.r,
          event_code: event.event_code,
          event_id: ticket.eevent_id,
          event_name: event.event_name,
          event_results: null,
          event_time: moment(event.event_date, 'YYYY-MM-DD HH:mm:ss').valueOf() * 1e6,
          id: ticket.eid,
          number_of_lines: 1,
          numbers: ticket.numbers,
          odds: event.odds,
          reserve_id: ticket.reserve_id,
          serial: ticket.reserve_id,
          status: 'OPEN',
          systems: ticket.systems,
          time: ticket.time,
          winning_tax: 0,
          gross_winning_amount: 0,
          max_winning: 0,
          min_winning: 0,
          net_winning_amount: 0,
        };

        finalTicket.ticketOnline = false;

        yield put(betSlipLottoTicketProcessingResult(finalTicket));
      }
    } catch (e) {
      console.log('error processing ticket', e);
      yield put(betSlipLottoTicketProcessingError(e.toString()));
    }
  }
}

function* lottoCheckAuthorization(action) {
  const state = getStore().getState();
  const lst = getLottoState(state);

  const { auth } = lst;

  if (!auth || !auth.token) {
    return;
  }

  try {
    const { data } = yield axios.get(lottoConfig.getTicket + action.ticket_id, {
      headers: {
        Authorization: auth.token,
      },
    });

    if (data.data === null) {
      yield put(betSlipLottoTicketProcessingError('Missing ticket with ID: ', action.ticket_id));
    } else {
      data.data.ticketOnline = true;
      yield put(betSlipLottoTicketProcessingResult(data.data));
    }
  } catch (e) {
    console.log('failed to check authorization', e);
    yield put(betSlipLottoTicketProcessingError(e.toString()));
  }
}

function* ticketOpenedListSaga() {
  const state = getStore().getState();
  const lst = getLottoState(state);
  yield put(ticketListRequesting({ val: true, key: 'opened' }));

  const { auth } = lst;

  if (!auth || !auth.token) {
    return;
  }

  try {
    let ticketsNumber = 1000;
    if (
      window.config &&
      window.config.lottoTicketsMaxNumber &&
      !isNaN(parseInt(window.config.lottoTicketsMaxNumber, 10))
    ) {
      ticketsNumber = parseInt(window.config.lottoTicketsMaxNumber, 10);
    }

    const { data } = yield axios.post(
      lottoConfig.listTickets,
      {
        rowsPerPage: ticketsNumber,
        exactFields: ['is_valid', 'status'],
        exactValues: [[1], [0]],
      },
      {
        headers: {
          Authorization: auth.token,
        },
      },
    );

    if (data.data.rows === null) {
      data.data.rows = [];
    }

    yield put(ticketOpenedListReceived(data.data.rows));
  } catch (e) {
    console.log('failed to get open ticket list', e);
    /*
		yield put(
			appSaveErrorLog(
				JSON.stringify({ requestUuid }),
				"Failed to get open ticket list",
				JSON.stringify(e)
			)
		);
		*/
    yield put(ticketListRequesting({ val: false, key: 'opened' }));
    yield put(ticketListError(e.toString()));
  }
}

function* ticketSettledListSaga(action) {
  const state = getStore().getState();
  const lst = getLottoState(state);
  yield put(ticketListRequesting({ val: true, key: 'settled' }));

  const { auth } = lst;

  if (!auth || !auth.token) {
    return;
  }

  const limit = 5;

  try {
    const { data } = yield axios.post(
      lottoConfig.listTickets,
      {
        rowsPerPage: limit,
        page: action.data.page,
        exactFields: ['is_valid', 'status'],
        exactValues: [
          [1, 5],
          [1, 2, 3],
        ],
      },
      {
        headers: {
          Authorization: auth.token,
        },
      },
    );

    if (data.data.rows === null) {
      data.data.rows = [];
    }

    yield put(ticketSettledListReceived({ rows: data.data.rows, page: action.data.page, total: data.data.total }));
  } catch (e) {
    console.log('failed to get ticket list', e);
    /*
		yield put(
			appSaveErrorLog(
				JSON.stringify({ requestUuid }),
				"Failed to get settled ticket list",
				JSON.stringify(e)
			)
		);
		*/
    yield put(ticketListRequesting({ val: false, key: 'settled' }));
    yield put(ticketListError(e.toString()));
  }
}

function* saveReservedTicketSaga(action) {
  const { authentication } = getStore().getState();

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  if (action && action.data && action.data[0]) {
    try {
      const data = Object.assign({}, action.data[0]);
      data.ticketHashes = [{ hash: data.reserve_id }];
      data.createdAtMs = Math.floor(data.event_time / 1e6);

      yield playerBetsApi(true, 'POST', '/player/tickets/reserved', {
        tType: 'lotto',
        data: data,
      });
    } catch (e) {
      console.log('failed to save reserved ticket', e);
    }
  }
}

function* ticketReservedListSaga() {
  const { authentication } = getStore().getState();

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  yield put(ticketListRequesting({ val: true, key: 'reserved' }));

  try {
    const { data } = yield playerBetsApi(true, 'GET', '/player/tickets/reserved');

    let result = [];
    data.forEach((t) => {
      if (t.type === 'lotto') {
        result.push(JSON.parse(t.data));
      }
    });

    result.sort((a, b) => {
      if (a.event_time > b.event_time) return -1;
      if (b.event_time > a.event_time) return 1;

      return 0;
    });

    yield put(ticketReservedListReceived(result));
  } catch (e) {
    yield put(ticketListRequesting({ val: false, key: 'reserved' }));
    //console.log("failed to get ticket list", e);
    yield put(ticketListError(e.toString()));
  }
}

function* ticketCheckCodeSaga(action) {
  yield put(ticketCheckCodePending());

  try {
    if (!action.code) return;

    let code = action.code.trim();
    if (code && code.indexOf('-') > -1) {
      const parts = code.split('-');
      if (parts && parts[0]) code = parts[0];
    }

    if (code) {
      code = code.toUpperCase();
    }

    const { data } = yield axios.get(lottoConfig.checkTicket + '/' + code);

    yield put(ticketCheckCodeData(data, action.code.trim()));
  } catch (e) {
    let err = e.toString();

    if (e.response && e.response.data && e.response.data.details) {
      // t("Ticket not found")
      err = e.response.data.details.join('');
    }

    yield put(ticketCheckCodeError(err));
  }
}

export default function* watchTicketsSaga() {
  yield takeEvery(ticketConstants.OPENED_LIST_REQUEST, ticketOpenedListSaga);
  yield takeEvery(ticketConstants.SETTLED_LIST_REQUEST, ticketSettledListSaga);
  yield takeEvery(ticketConstants.RESERVED_LIST_REQUEST, ticketReservedListSaga);
  yield takeEvery(betsSlipConstants.LOTTO_TICKET_CREATE, lottoCreateTicketSaga);
  yield takeEvery(ticketConstants.STORE_RESERVED, saveReservedTicketSaga);
  yield takeEvery(betsSlipConstants.LOTTO_TICKETS_PROCESS_NEXT_TICKET, lottoProcessNextTicket);
  yield takeEvery(betsSlipConstants.LOTTO_TICKETS_CHECK_AUTHORIZATION, lottoCheckAuthorization);
  yield takeEvery(ticketConstants.CHECK_CODE, ticketCheckCodeSaga);
}
