import { configConstants } from '../actions/constants';
import { produce } from 'immer';

const parseRules = (rs) => {
  const rules = {};

  Object.entries(rs).forEach(([c, bo]) => {
    rules[c] = {};

    Object.entries(bo).forEach(([bId, rs]) => {
      rules[c][bId] = rs.map((r) => {
        let code = '"use strict"; return ' + r.definition;

        r['script'] = window.Function(code)();
        return r;
      });
    });
  });

  return rules;
};

const initialState = {
  loaded: false,
  loadState: true,
  homepageLottoPromoted: [],
  bonusRules: {},
};

const configReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case configConstants.LOADED: {
        draft.loaded = true;
        draft.loadState = false;

        if (typeof action.data.homepageLottoPromoted !== 'undefined') {
          const promoted = [];
          action.data.homepageLottoPromoted.forEach((s) => {
            promoted.push(s.system_name);
          });

          draft.homepageLottoPromoted = promoted;
        }

        if (action.data.bonusRules) {
          draft.bonusRules = parseRules(action.data.bonusRules);
        }
        break;
      }
      case configConstants.RELOAD: {
        draft.loaded = false;
        break;
      }
      default:
        break;
    }
  });

export default configReducer;
