import { statScoreConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  exists: {},
};

const statScoreReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case statScoreConstants.SAVE: {
        //console.log("navsport save", action);
        const exists = { ...draft.exists };
        exists[action.idMatch] = {
          status: action.status,
          ssId: action.ssId
        };
        draft.exists = exists;
        break;
      }
      default:
        break;
    }
  });

export default statScoreReducer;