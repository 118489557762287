import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

import './index.scss';

type HappyHourProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    hhType: string;
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    hhType: '',
    dsType: '',
  },
};

const HappyHourDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const HappyHour = (componentProps: HappyHourProps) => {
  let props = componentProps;
  const happyHourRunning = useAppSelector((state) => state.happyHour.running);
  const happyHourStatus = useAppSelector((state) => state.happyHour.status);
  const [showTermsAndConditions, setShowTermsAndConditions] = React.useState(false);

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  // the type of the HappyHour to be shown. eg. online-slots, bets-live, bets-prematch
  const type = props.properties?.hhType ?? 'online-slots';

  const contextValue = React.useMemo(() => {
    return {
      onlineSlotsActive: !!happyHourRunning['online-slots'],
      onlineSlots: happyHourStatus['online-slots'] ?? null,
      betsPrematchActive: !!happyHourRunning['bets-prematch'],
      betsPrematch: happyHourStatus['bets-prematch'] ?? null,
      betsLiveActive: !!happyHourRunning['bets-live'],
      betsLive: happyHourStatus['bets-live'] ?? null,
      showTermsAndConditions: showTermsAndConditions,
      toggleTermsAndCondition: () => {
        setShowTermsAndConditions((v) => !v);
      },
      selectedHHType: type,
    };
  }, [happyHourRunning, happyHourStatus, type]);

  if (!(contextValue.onlineSlotsActive || contextValue.betsPrematchActive || contextValue.betsLiveActive)) return null;

  //console.log('HappyHour[contextValue]', contextValue, componentProps);

  return (
    <HappyHourDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </HappyHourDiv>
  );
};

export default HappyHour;
