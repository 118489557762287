import React from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';

import { useAppSelector } from '@/store';

const cache: any = {
  loading: {},
  ref: {},
};
window._de_cache = cache;
const cacheFor = 1000 * 30;

type GenericFetchProps = {
  elementId: string;
  apiUrl: string;
};

const genericFetch = ({ elementId, apiUrl }: GenericFetchProps) => {
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);

  const [state, setState] = React.useState<{ data: any; loading: boolean; loaded: boolean }>({
    data: {
      id: null,
      data: null,
    },
    loading: true,
    loaded: false,
  });

  React.useEffect(() => {
    return () => {
      Object.keys(cache).forEach((key) => {
        if (key === 'ref' || key === 'loading') return;

        if (cache[key] && cache[key].time) {
          const now = new Date().getTime();
          const diff = now - cache[key].time;
          if (diff > cacheFor) {
            // 1 minutes
            delete cache[key];
            delete cache.ref[key];
            delete cache.loading[key];
          }
        }
      });
    };
  }, []);

  React.useEffect(() => {
    let stillMounted = true;

    if (elementId && authenticationToken) {
      if (cache.ref[elementId] == null) cache.ref[elementId] = 0;
      cache.ref[elementId] += 1;

      if (cache[elementId]) {
        const data = cloneDeep(cache[elementId]);
        setState({
          data: data,
          loading: false,
          loaded: true,
        });
        return;
      } else if (cache.loading[elementId]) {
        cache.loading[elementId].promise
          .then((data: any) => {
            if (!stillMounted) return;

            setState({
              data: data,
              loading: false,
              loaded: true,
            });
          })
          .catch((e: Error) => {
            console.log(e);
            setState((v) => ({ ...v, loading: false }));
          });
        return;
      } else {
        cache.loading[elementId] = {};
        cache.loading[elementId].promise = new Promise((resolve, reject) => {
          axios
            .get(apiUrl, {
              headers: {
                Authorization: 'Bearer ' + authenticationToken,
              },
            })
            .then((resp) => {
              let data = null;
              if (resp && resp.data) {
                data = resp.data;
              }

              const final = {
                id: elementId,
                data: data ?? {},
                time: new Date().getTime(),
              };

              if (cache.ref[elementId]) cache[elementId] = final;

              if (stillMounted) {
                setState({
                  data: final,
                  loading: false,
                  loaded: true,
                });
              }

              resolve(final);
            })
            .catch((e: Error) => {
              console.log(e);
              setState((v) => ({ ...v, loading: false }));
              reject(e);
            });
        });
      }
    }

    return () => {
      stillMounted = false;
    };
  }, [elementId, authenticationToken]);

  return state;
};

export default genericFetch;
