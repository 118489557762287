import React from 'react';
//import { withTranslation } from "react-i18next";
import moment from 'moment';

import './simple_timer.scss';

const SimpleTimer = (props) => {
  const { date, onLive, className, time,
    //  i18n 
  } = props;

  const [live, setLive] = React.useState(false);
  const [counter, setCounter] = React.useState(false);
  const [state, setState] = React.useState({
    mins: '--',
    secs: '--'
  });

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;

    if (mounted) {
      if (date) {
        const calcTime = () => {
          const dt = moment(date, 'YYYY-MM-DD HH:mm:ss');
          const dtNow = moment();

          const hours = dt.diff(dtNow, 'hours');
          const mins = dt.diff(dtNow, 'minutes') - hours * 60;
          const secs = dt.diff(dtNow, 'seconds') - mins * 60;

          if (hours < 0 || mins < 0 || secs < 0) {
            setCounter(false);
            setLive(true);
            clearInterval(intervalID);
            if (typeof onLive === 'function') onLive();
            return;
          }

          if (hours > 24) {
            setCounter(false);
            setLive(false);
            return;
          }

          setState({
            hours: ('0' + hours).slice(-2),
            mins: ('0' + mins).slice(-2),
            secs: ('0' + secs).slice(-2),
          });
          setLive(false);
          setCounter(true);
        };

        clearInterval(intervalID);
        intervalID = setInterval(() => {
          calcTime();
        }, 1000);
        calcTime();
      }
    }

    return () => {
      mounted = false;
      clearInterval(intervalID);
    };
  }, [date]); // eslint-disable-line

  if (!date) return null;

  if (time) {
    return <div className={className ? className : ''}>
      <span>
        {moment(date).format('HH:mm')}
      </span>
    </div>;
  }

  return (
    <div className={className ? className : ''}>
      {counter &&
        <span>
          {state.hours === '00' ? <span className="muted">{state.hours} :</span> : `${state.hours} :`} {state.hours === '00' && state.mins === '00' ? <span className="muted">{state.mins} :</span> : `${state.mins} :`} {state.secs}
        </span>
      }
      {!counter && <span><span className="muted">--</span> : <span className="muted">--</span> : <span className="muted">--</span></span>}
    </div>
  );
};

//export default withTranslation()(TimerExtended);
export default SimpleTimer;