const transferPropertiesToComponent = (craftState: any, componentDataProperties: any) => {
  if (!componentDataProperties) return;
  if (!craftState) return;

  const rx = RegExp(/^(.*?)\[(.*?)\]$/);

  Object.keys(componentDataProperties).forEach((key: any) => {
    const matches = rx.exec(key);
    if (matches && matches.length === 3) {
      const nodeId = matches[2];
      const prop = matches[1];
      if (craftState[nodeId] && craftState[nodeId].props) {
        if (prop === 'className') {
          craftState[nodeId].props.className = componentDataProperties[key];
        } else if (craftState[nodeId].props.properties) {
          craftState[nodeId].props.properties[prop] = componentDataProperties[key];
        }
      }
    }
  });
};

export default transferPropertiesToComponent;
