/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

import { lottoResultsConstants } from '../actions/constants';
import {
  loadLottoResults,
  loadingLottoResults,
  loadLottoLatestResults,
  loadingLottoLatestResults,
} from '../actions/lotto-results';

import { lottoConfig } from '../../api/config/lotto';

const apiCall = (url, params) => {
  return axios
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
};

function* fetchLottoResultsSaga(action) {
  yield put(loadingLottoResults(true));

  try {
    let { data } = yield call(apiCall, lottoConfig.publicResults, {
      limit: 0,
      sort_by: 'event_date',
      sort_direction: 'DESC',
      offset: 0,
      ...action.extra,
    });
    if (data) {
      const rows = data.results ? data.results : [];
      const total = data.total ? data.total : 0;
      const add = action.extra.offset ? true : false;
      yield put(loadLottoResults(rows, total, add));
    }
  } catch (e) {
    //console.log("[fetchLottoResultsSaga] err", e);
  }

  yield put(loadingLottoResults(false));
}

function* fetchLottoLatestResultsSaga(action) {
  yield put(loadingLottoLatestResults(true));

  try {
    let { data } = yield call(apiCall, lottoConfig.publicResults, {
      limit: 0,
      sort_by: 'event_date',
      sort_direction: 'DESC',
      offset: 0,
      ...action.extra,
    });
    if (data) {
      const rows = data.results;
      yield put(loadLottoLatestResults(rows ? rows : []));
    }
  } catch (e) {
    //console.log("[fetchLottoResultsSaga] err", e);
  }

  yield put(loadingLottoLatestResults(false));
}

export default function* watchLottoEvents() {
  //console.log("[saga] watching lotto results requests");
  yield takeEvery(lottoResultsConstants.FETCH, fetchLottoResultsSaga);
  yield takeEvery(lottoResultsConstants.FETCH_LATEST, fetchLottoLatestResultsSaga);
}
