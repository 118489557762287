import getStore from '../store';

import { digitainConfig } from '../api/config/digitain';
import DefaultCrest from '../assets/default-crest.svg';
import { flags } from '@/components/modules/bets-match-stats/utils/flags';

const removeAccents = str =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const crests = {};

let host = 'https://micros-p.b-cdn.net';
let baseUrl = host;

if (baseUrl.substr(-1) !== '/') baseUrl += '/';

const matchCrest = (name, match, teamNo) => {

  if (match && match.provider === 'digitain') {
    if (match[`team${teamNo}Logo`]) {
      if (flags[match[`team${teamNo}Logo`]]) {
        return flags[match[`team${teamNo}Logo`]];
      }
      return `${digitainConfig().apiURL}/team-logo2/${match[`team${teamNo}Logo`]}`;
    }
  }
  if (!name) return '';

  const { config } = getStore().getState().bets;
  let t = name;

  if (typeof t === 'object') {
    if (t['2'] || t['0']) {
      t = t['2'] ?? t['0'];
    } else {
      return DefaultCrest;
    }
  }

  t = t.toLowerCase();

  if (typeof config.teamsLogo !== 'undefined' && typeof config.teamsLogo[t] !== 'undefined') {
    if (config.teamsLogo[t].indexOf('/') !== -1) return host + config.teamsLogo[t];
    return `${baseUrl}${config.teamsLogo[t]}`;
  }
  if (typeof crests[t] !== 'undefined') {
    return `${baseUrl}${crests[t]}`;
  }
  if (typeof crests[t + ' fc'] !== 'undefined') {
    return `${baseUrl}${crests[t + ' fc']}`;
  }
  if (typeof crests['fc ' + t] !== 'undefined') {
    return `${baseUrl}${crests['fc ' + t]}`;
  }
  if (typeof crests[removeAccents(t)] !== 'undefined') {
    return `${baseUrl}${crests[removeAccents(t)]}`;
  }
  if (typeof crests[removeAccents(t) + ' fc'] !== 'undefined') {
    return `${baseUrl}${crests[removeAccents(t) + ' fc']}`;
  }
  if (typeof crests['fc ' + removeAccents(t)] !== 'undefined') {
    return `${baseUrl}${crests['fc ' + removeAccents(t)]}`;
  }
  return DefaultCrest;
};

export default matchCrest;
