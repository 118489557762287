import * as React from 'react';
import { useMatches } from 'react-router-dom';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';

const cache: any = {};

const getExtraMeta = (props: any) => {
  const uriMatches = useMatches();
  const uriMatch = uriMatches[uriMatches.length - 1];

  const [extraData, setExtraData] = React.useState<any>({});

  React.useEffect(() => {
    if (uriMatch.pathname && extraData.pathname !== uriMatch.pathname) {
      if (cache[uriMatch.pathname]) {
        setExtraData(cache[uriMatch.pathname]);
        return;
      }

      axios
        .get(
          `${endpoints.templatization.meta}?tenantId=${window.config.clientId}&siteId=${window.config.siteId}&path=${encodeURIComponent(uriMatch.pathname)}`,
        )
        .then((response) => {
          cache[uriMatch.pathname] = { pathname: uriMatch.pathname, ...response.data };
          setExtraData(cache[uriMatch.pathname]);
        });
    }
  }, [uriMatch, extraData]);

  //console.log('getExtraMeta[props]', extraData);

  return extraData;
};

export default getExtraMeta;
