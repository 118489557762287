import React from 'react';
import styled from 'styled-components';

const defaultProps = {
  className: '',
  styleText: `
  width: 100%;
  height: auto;
  `,
};

const DataGridItemsDiv = styled.div`
  display: grid;

  ${(props) => props.$styleText}
`;

export const DataGridItems = (componentProps) => {
  return (
    <DataGridItemsDiv
      className={componentProps.className ?? ''}
      $styleText={componentProps.styleText != null ? componentProps.styleText : defaultProps.styleText}
      style={componentProps.style}
    >
      {componentProps.children}
    </DataGridItemsDiv>
  );
};
