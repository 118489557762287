import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { fetchAllJackpotsMeta } from '../../../store/slices/jackpotsMeta';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

import './index.scss';

type JackpotsMetaProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const JackpotsMeta = (componentProps: JackpotsMetaProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchAllJackpotsMeta());
  }, []);

  const { items, loading } = useAppSelector((state) => ({
    items: state.jackpotsMeta.items,
    loading: state.jackpotsMeta.loading,
  }));

  if (!items) return null;

  const contextValue = {
    items: items,
    loading: loading,
  };

  //console.log(contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default JackpotsMeta;
