interface StyleIDS {
  [key: string]: string;
}

const StyleIds: StyleIDS = {
  styleRootId: 'root-css',
  styleOverwriteId: 'root-overwrite-css',
};

export default StyleIds;
