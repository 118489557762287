import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

import './index.scss';

type ChangeProfileDetailsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  isOpen: boolean;
  nickname: string;
  avatar: string;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ChangeProfileDetails = (componentProps: ChangeProfileDetailsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const { profile, authenticated } = useAppSelector((state) => ({
    authenticated: (state.authentication && state.authentication.auth_type === 'user') as boolean,
    profile: state.profile,
  }));
  const navigate = useNavigate();

  const initialState = {
    isOpen: false,
    nickname: '',
    avatar: '',
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const contextValue = {
    ...state,
    // nickname: state.nickname,
    // avatar: state.avatar,

    onChangeNickname: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';

      setState((v) => ({
        ...v,
        nickname: value,
      }));
    },

    onToggle: (e: React.MouseEvent<HTMLElement>) => {
      setState((v) => ({
        ...initialState,
        isOpen: !v.isOpen,
      }));
    },

    onConfirm: (e: React.MouseEvent<HTMLElement>) => {
      const { nickname, avatar } = state;

      if (nickname && nickname.length > 0) {
        if (avatar) {
          // dispatch(changeProfileDetails({ nickname, avatar })); // TODO: saga for changeProfileDetails
        } else {
          // TODO: default_avatar
          // dispatch(changeProfileDetails({ nickname, def_avatar })); // TODO: saga for changeProfileDetails
        }
      }

      setState((v) => ({
        ...initialState,
        isOpen: !v.isOpen,
      }));
    },
  };

  // console.log('Debug Change Profile Details: ', { contextValue, state, profile });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default ChangeProfileDetails;
