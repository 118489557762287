import * as actionTypes from './actionTypes';

export const sendComplaintForm = (name, email, subject, body) => {
  return {
    type: actionTypes.customer_support.SEND_COMPLAINT_FORM,
    name: name,
    email: email,
    subject: subject,
    body: body,
  };
};

export const complaintFormResponse = (data) => {
  return {
    type: actionTypes.customer_support.COMPLAINT_FORM_RESPONSE,
    customerSupport: data,
  };
};

export const cleanComplaintForm = () => {
  return {
    type: actionTypes.customer_support.CLEAN_COMPLAINT_FORM,
  };
};

export const requestGameRules = () => {
  return {
    type: actionTypes.customer_support.REQUEST_GAME_RULES,
  };
};

export const receivedGameRules = (gameRules, groupedRules) => {
  return {
    type: actionTypes.customer_support.RECEIVED_GAME_RULES,
    items: gameRules,
    groups: groupedRules,
  };
};
