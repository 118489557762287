import getStore from '../store';
import { getBetsState } from '../store/selectors/betData';
import { debug } from './';
import { formatBetTitle, formatOddName } from './formatters';
import { cloneDeep } from 'lodash-es';

const formatMoneyValue = (ttype, v) => {
    let sv = null;

    if (ttype === 'core') {
        sv = Math.round(v * 100);
    } else {
        sv = parseFloat(v).toFixed(2);
        sv.replace(/(\.0+)$/, '');
    }

    return sv;
};

const round2 = v => Math.round(v * 100) / 100;

const normalizeBets = (ttype, bets) => {
    const bst = getBetsState(getStore().getState());
    if (!bst) {
        debug('no bets state');
        return [];
    }

    let ro = bets.map(b => {
        if (!bst[b.mType]) {
            debug(`no bets state for ${b.mType}`);
            return null;
        }

        const st = bst[b.mType];

        if (!(st.matches && st.matches[b.idMatch])) {
            debug('no match');
            return null;
        }
        const m = st.matches[b.idMatch];

        let s = null;
        if (st.sports && st.sports[b.idSport]) {
            s = st.sports[b.idSport];
        } else {
            debug('no sport', b, st.sports);
        }

        let c = null;
        if (st.categories && st.categories[m.idCategory]) {
            c = st.categories[m.idCategory];
        } else {
            debug('no category', m, st.categories);
        }

        let t = null;
        if (st.tournaments && st.tournaments[m.idTournament]) {
            t = st.tournaments[m.idTournament];
        } else {
            debug('no tournament', m, st.tournaments);
        }

        debug(m, s, c, t);

        const mb = m.matchBets.find(mb => mb.idMb === b.idMb);
        let mo = null;
        if (mb) {
            mo = mb.mbOutcomes.find(mo => mo.idMbo === b.idMbo);
        }

        return {
            idSport: b.idSport,
            sportName: s ? s.sportName : 'unknown sport',
            idCategory: m.idCategory,
            categoryName: c ? c.categoryName : 'unknown category',
            idTournament: m.idTournament,
            tournamentName: t ? t.tournamentName : 'unknown tournament',
            idMatch: b.idMatch,
            team1Name: m.team1Name,
            team2Name: m.team2Name,
            idBet: b.idBet,
            betName: mb ? formatBetTitle(mb, m, st.bets) : 'unknown bet',
            idMb: b.idMb,
            idBo: b.idBo,
            idMbo: b.idMbo,
            outcomeName: mo ? formatOddName(b.idBet, mo, m, st.bets) : 'unknown odd',
            value: b.odd,
            brId: m.brId,
            betWinnerPlus: mb ? mb.winnerPlus : false,
            betWinnerAdv: mb ? mb.winnerAdv : false,
            betWinnerSpecial: mb ? mb.winnerSpecial : false,
            matchWinnerPlus: m.winnerPlus,
            matchWinnerAdv: m.winnerAdv,
            matchWinnerSpecial: m.winnerSpecial,
            betType: b.betType,
        };
    });

    return ro.filter(o => o !== null);
};

const evaluateBonuses = rt => {
    const ttype = 'front';
    const ticket = { ...rt.ticket };

    const state = getStore().getState();
    const bst = getBetsState(state);

    if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
        return;
    }

    debug('evaluateBonuses', rt);

    const tbets = normalizeBets(ticket.betType, ticket.bets);

    debug('evaluateBonuses tbets', tbets);

    const betsProductId = 2;

    const free_money_balance = state.wallet.main;
    const bonus = state.wallet.bonuses ? state.wallet.bonuses.filter(w => w.eligibleProducts.find(ep => ep === betsProductId)) : [];
    const ring_fence = state.wallet.ringFences ? state.wallet.ringFences.filter(w => w.eligibleProducts.find(ep => ep === betsProductId)) : [];
    const rules = bst.config.bonusRules;

    debug('rules', rules);

    /*
    // process ticket
    const ticket = {
        amount: t.amount,
        tax: t.tax,
        stake: t.stake,
        totalOdds: rt.totalOdds,
        totalOddsMin: rt.totalOddsMin,
        totalOddsMax: rt.totalOddsMax,
        totalCombinations: rt.totalCombinations,
        minWinAmount: rt.minWinAmount,
        maxWinAmount: rt.maxWinAmount,
        ticketType: rt.ticketType,
        product: rt.betType === "prematch" ? "SportsbookSM" : "LiveBetting",
        bets: tbets,
        systems: rt.systems,
    };
    */

    ticket.product = ticket.betType === 'prematch' ? 'SportsbookSM' : 'LiveBetting';
    ticket.bets = tbets;

    // default response
    const response = {
        valid: false,
        eligible: false,
        free_money_used: 0,
        bonus: [],
        ring_fence: [],
        details: [],
    };

    // total amount to pay
    let toPay = ticket.amount;
    let wagerToDistribute = ticket.amount;
    let free_money_used = 0;

    // check if free money balance covers it
    if (rt.free_money_balance >= toPay) {
        response.valid = true;
        free_money_used = toPay;
        toPay = 0;
    } else {
        // update what's left to pay
        toPay = round2(toPay - rt.free_money_balance);
        free_money_used = rt.free_money_balance;
    }

    response.free_money_used = formatMoneyValue(ttype, free_money_used);

    // if we have a BB bet then no bonus applies
    // if (ticket.bets.find(b => b.betType === 'betBuilder')) {
    //     return {
    //         success: true,
    //         data: response,
    //     };
    // }

    debug('evaluating ticket', ticket);

    const rtBonus = cloneDeep(rt.bonus);
    const rtRingFence = cloneDeep(rt.ring_fence);

    // go through every provided bonus and evaluate rules
    rtBonus.forEach(tb => {
        debug('evaluating bonus', tb);

        // do we have rules for this bonus ID?
        if (typeof rules['bets'] === 'undefined' || !(tb.rulesetId in rules['bets'])) {
            debug('no rule for bets bonus', tb.rulesetId);
            return;
        }

        // evaluate rules (stop at the first successful validation)
        rules['bets'][tb.rulesetId].find(r => {
            debug('evaluating rule', r.id);
            debug('rule lists', r.lists);

            /*
            const sandbox = {
                ticket,
                bonus: tb,
                lists: rt.product === "SportsbookSM" ? r.lists.prematch : r.lists.live,
                __console_log: (...theArgs) => {
                    try {
                        debug(`VM log: ${theArgs}`);
                    } catch (err) {}
                },
            };
            debug("input for evaluation", sandbox);
            */

            /*
            const vm = new NodeVM({
                console: "redirect",
                timeout: 1000,
                sandbox: sandbox,
                require: {
                    external: true,
                    root: "./"
                }
                //wrapper: "none"
            });

            vm.on("console.log", data => {
                logger.debug(`VM stdout: ${data}`);
            });
            */

            let evalRes = null;
            let err = null;

            const lists = rt.product === 'SportsbookSM' ? r.lists.prematch : r.lists.live;

            try {
                //evalRes = r.script.runInNewContext(sandbox);
                evalRes = r.script(ticket, tb, lists);
            } catch (e) {
                console.error('error eval rule', e);
                err = {
                    code: e.code,
                    message: e.message,
                    stack: e.stack,
                };
            }

            debug(`eval result for rule ${r.name}`, err, evalRes);
            /*
            if (sandbox.__log) {
                debug(`eval log`, sandbox.log);
            }
            */

            // handle evaluation errors
            if (err !== null) {
                console.error(`error evaluating rule ${r.name} - ${r.category} - ${tb.rulesetId}`, err, ticket);
                return false;
            }

            // handle unsuccessful evaluation
            if (!evalRes.eligible) {
                debug(evalRes);

                response.details.push({
                    id: tb.id,
                    error: evalRes.error,
                });
                return false;
            }

            // return bonus
            response.bonus.push({
                id: tb.id,
                balance_used: evalRes.balance_used,
            });

            return true;
        });
    });

    // if there is at least one bonus mark ticket as eligible
    response.eligible = response.bonus.length > 0;

    // sort ring-fenced wallets by amount
    //rtRingFence.sort((a, b) => a.amount - b.amount);

    // money used from ring_fences
    const rrfences = [];
    const rrfused = {};

    if (response.eligible) {
        // resulted bonuses and ring fences
        const rbonus = [];

        // copy bonuses
        const bonus = [...response.bonus];

        // sort bonuses by balance used - we try to exhaust the small ones first
        //bonus.sort((a, b) => a.balance_used - b.balance_used);

        if (toPay > 0) {
            // consume first from the ring-fenced wallets
            rtRingFence.find(rf => {
                // check the wallet has monet in it
                if (rf.amount === 0) {
                    return false;
                }

                // if a bonus with the same ID eligible?
                const rfb = bonus.find(b => b.id === rf.id);
                if (!rfb) {
                    return false;
                }

                // wallet eligible - get how much we can use
                let bu = rf.amount;

                // if the balance to be used is greater than the amount left to pay don't use it all
                if (toPay <= bu) {
                    bu = toPay;
                }

                // remember what we used
                rrfused[rf.id] = bu;

                // remove what we used
                rf.amount = rf.amount - bu;

                // store wallet usage
                rrfences.push({
                    id: rf.id,
                    balance_used: formatMoneyValue(ttype, bu),
                });

                // update what we have to pay
                toPay = round2(toPay - bu);

                // stop if ticket paid
                return toPay === 0;
            });
        }

        // try to pay the ticket starting with the smallest bonus
        bonus.find(b => {
            // handle evaluation results
            let bu = b.balance_used;

            // if the balance to be used is greater than the amount left to pay don't use it all
            if (toPay <= bu) {
                bu = toPay;
            }

            // wager generated (equal with balance used if not sepcified)
            let wg = 'wager_generated' in b ? b.wager_generated : bu;

            // add whatever we used from the ring fenced wallet associated with the bonus
            if (b.id in rrfused) {
                wg += rrfused[b.id];
            }

            // add whatever free  money was used
            //wg = wg + free_money_used;

            // reset free money used so we'll add it only to the first bonus
            //free_money_used = 0;

            // get the original bonus
            const ob = rtBonus.find(rb => rb.id === b.id);

            // calculate how much wager is left to fill
            let wl = 'wagerTarget' in ob && 'wager' in ob ? ob.wagerTarget - ob.wager : 0;

            // check if there is enough wager to distribute to cover what we need
            if (wagerToDistribute <= wl) {
                wl = wagerToDistribute;
            }

            // if the amount of wager we have to fill is greater than what we generated
            // try to fill the remainder from free moey used
            if (wl > wg) {
                if (free_money_used > 0) {
                    let wdiff = wl - wg;

                    if (free_money_used <= wdiff) {
                        wdiff = free_money_used;
                    }

                    wl = wg + wdiff;
                    free_money_used -= wdiff;
                } else {
                    wl = wg;
                }
            }

            // store bonus
            rbonus.push({
                id: b.id,
                balance_used: formatMoneyValue(ttype, bu),
                wager_generated: formatMoneyValue(ttype, wl),
            });

            // update what we have to pay
            toPay = round2(toPay - bu);

            // update the wager we have to distribute
            wagerToDistribute = round2(wagerToDistribute - wl);

            // stop if ticket paid
            return toPay === 0 && wagerToDistribute === 0;
        });

        // store processed bonus
        response.bonus = rbonus;
    }

    // at this point if we still need to pay part of the ticket we try to
    // use ring fence money, but the consumed amounts will not generate wager
    if (toPay > 0) {
        // consume first from the ring-fenced wallets
        rtRingFence.find(rf => {
            // check the wallet has monet in it
            if (rf.amount === 0) {
                return false;
            }

            // wallet eligible - get how much we can use
            let bu = rf.amount;

            // if the balance to be used is greater than the amount left to pay don't use it all
            if (toPay <= bu) {
                bu = toPay;
            }

            // remove what we used
            rf.amount = rf.amount - bu;

            // store wallet usage
            rrfences.push({
                id: rf.id,
                balance_used: formatMoneyValue(ttype, bu),
                no_wager_generated: true
            });

            // update what we have to pay
            toPay = round2(toPay - bu);

            // stop if ticket paid
            return toPay === 0;
        });
    }

    // store processed ring fences
    response.ring_fence = rrfences;

    // if the ticket can be fully paid, mark it as valid
    if (toPay === 0) {
        response.valid = true;
    }

    // store wager remained undistributed
    response.undistributed_wager = formatMoneyValue(ttype, wagerToDistribute);

    debug('eval result', response);

    return {
        success: true,
        data: response,
    };
};

export { normalizeBets, evaluateBonuses, formatMoneyValue, round2 };
