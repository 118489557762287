import { produce } from 'immer';

import { evaluatePropFunction } from './evaluatePropFunction';
import { evaluateVisibilityFunction } from './evaluateVisibilityFunction';
import { propsApplyLinksValues } from './applyLinksValues';
import React from 'react';

/**
 * Process the component props by applying the links and propFunction
 * @param originalProps
 * @param dataElementContext
 * @param isDesktop
 */
export const processComponentProps = (originalProps: any, dataElementContext: any, breakpoints: any | null = null) => {
  const res = React.useMemo(() => {
    let props = originalProps;

    if (props?.properties?.responsive && breakpoints != null) {
      props = produce(props, (draft: any) => {
        if (breakpoints.isDesktop) {
          draft.properties.isDesktop = true;
        }
        if (breakpoints.isTablet) {
          draft.properties.isTablet = true;
        }
      });
    }

    if (
      Object.values(props?.properties?.['links'] ?? []).length &&
      props?.properties?.['propFunction'] != null &&
      props?.properties?.['propFunction']
    ) {
      // use the same produce draft when we have both links to apply and a function to execute
      props = produce(props, (draft: any) => {
        if (dataElementContext) propsApplyLinksValues(draft, dataElementContext);
        evaluatePropFunction(draft, dataElementContext);
      });
    } else {
      // move data from context to properties if links are defined
      if (dataElementContext && props?.properties['links'] && Object.values(props?.properties['links']).length) {
        props = produce(props, (draft: any) => {
          propsApplyLinksValues(draft, dataElementContext);
        });
      }

      // evaluate a function which might modify the props
      if (props?.properties?.['propFunction'] != null && props?.properties?.['propFunction']) {
        props = produce(props, (draft: any) => {
          evaluatePropFunction(draft, dataElementContext);
        });
      }
    }

    let isVisible = true;
    if (props?.hide != null && props?.hide === true) {
      isVisible = false;
    } else if (props?.properties?.__display != null && props?.properties?.__display === false) {
      isVisible = false;
    } else if (props?.visibility && props?.visibility['visibilityFunction'] != null) {
      const visible = evaluateVisibilityFunction(props, dataElementContext);
      if (visible != null && !visible) isVisible = false;
    }

    return [props, isVisible];
  }, [originalProps, dataElementContext, breakpoints]);

  return res;
};
