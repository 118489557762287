import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import {
  lastPlayedGames as lastPlayedGamesFn,
  mostPlayedGames as mostPlayedGamesFn,
} from '../slot-game-launcher/utils/favorite_store';
import fetchGamesInfo from '../../../utils/games-info-api-cache';

//import './index.scss';

type FavoriteGamesProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const FavoriteGames = (componentProps: FavoriteGamesProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const accessToken = useAppSelector<any>((state) => state.authentication.access_token);

  const [lastPlayedGamesList, mostPLayedGamesList, common] = React.useMemo(() => {
    const lastPlayedGamesList = lastPlayedGamesFn(20);
    const mostPLayedGamesList = mostPlayedGamesFn(20);

    const common: any[] = [];
    const exists: any = {};
    for (let i = 0; i < lastPlayedGamesList.length; i++) {
      if (!exists[lastPlayedGamesList[i]]) {
        common.push(lastPlayedGamesList[i]);
        exists[lastPlayedGamesList[i]] = true;
      }
    }
    for (let i = 0; i < mostPLayedGamesList.length; i++) {
      if (!exists[mostPLayedGamesList[i]]) {
        common.push(mostPLayedGamesList[i]);
        exists[mostPLayedGamesList[i]] = true;
      }
    }

    return [lastPlayedGamesList, mostPLayedGamesList, common];
  }, []);

  const {
    data: { data: allGames },
  } = fetchGamesInfo({
    dsId: window.config.dataSourceAllGames,
    gameIds: common,
    authenticationToken: accessToken,
  });

  const contextValue = React.useMemo(() => {
    const allGamesById: any = {};
    let lastPlayedGames = [];
    let mostPlayedGames = [];

    if (allGames) {
      for (let i = 0; i < allGames.length; i++) {
        allGamesById[allGames[i].id] = allGames[i];
      }
      lastPlayedGames = lastPlayedGamesList
        .map((gameId: string) => allGamesById[gameId] ?? null)
        .filter((game: any) => game !== null);
      mostPlayedGames = mostPLayedGamesList
        .map((gameId: string) => allGamesById[gameId] ?? null)
        .filter((game: any) => game !== null);
    }

    return { lastPlayedGames, mostPlayedGames, parentContext: dataElementContext };
  }, [lastPlayedGamesList, mostPLayedGamesList, allGames, dataElementContext]);

  //console.log('FavoriteGames[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default FavoriteGames;
