import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { withTranslation } from "react-i18next";
import moment from "moment";

import "./SeasonStart.scss";
import SeasonStartHeaderIcon from "./assets/season-start-header-icon.svg";
import TimeRangeArrows from "./assets/arrows.svg";
import { chooseTextLanguage } from "../../utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const months = {
  "jan": "ian",
  "feb": "feb",
  "mar": "mart",
  "apr": "apr",
  "may": "mai",
  "jun": "iun",
  "jul": "iul",
  "aug": "aug",
  "sep": "sept",
  "oct": "oct",
  "nov": "nov",
  "dec": "dec"
};

const SeasonStart = props => {
  const { open, handleClose, campaign, t, i18n } = props;

  if (!open) return null;
  if (!campaign) return null;

  const sd = moment(parseInt(campaign.startDate), "x");
  const ed = moment(parseInt(campaign.endDate), "x");

  const formatDate = (month, lang) => {
    if (lang === "eng") return month;
    return months[month.toLowerCase()];
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winter-fun-modal-season-start betsModal betsModalDialog`}
    >
      <DialogContent className="wf-dialog-content">
        <img src={SeasonStartHeaderIcon} className="wf-header-icon" alt="season start icon" />

        <div className="wf-title">{campaign.type === "season" ? t("A new season has started!") : t("A new tournament has started!")}</div>
        <div className="wf-subtitle">{t("Play for free and win real money")}</div>

        <div className="wf-timeline">
          <div className="wf-calendar">
            <div className="wf-day">{sd.format("DD")}</div>
            <div className="wf-month">{formatDate(sd.format("MMM"), i18n.language)}</div>
          </div>
          <div className="wf-time-range">
            <div className="wf-text">{ed.diff(sd, "days")} {t("wf.days")}</div>
            <div className="wf-icon"><img src={TimeRangeArrows} alt="time range icon" /></div>
          </div>
          <div className="wf-calendar">
            <div className="wf-day">{ed.format("DD")}</div>
            <div className="wf-month">{formatDate(ed.format("MMM"), i18n.language)}</div>
          </div>
        </div>

        <div className="wf-rules">
          <div className="wf-rule">
            <div className="wf-icon"></div>
            <div className="wf-content">
              <div className="wf-header">{chooseTextLanguage(campaign.ranking.text, i18n.language)}</div>
              <div className="wf-text">{t("The top ranked players win real money.")}</div>
            </div>
          </div>
          {/*<div className="wf-rule">
            <div className="wf-icon"></div>
            <div className="wf-content">
              <div className="wf-header">{t("275 Sanse la Tombola")}</div>
              <div className="wf-text">{t("Continua sa strangi bilete la tombola pentru marele premiu")}</div>
            </div>
          </div>*/}
        </div>

        <div className="wf-button-wrapper">
          <ButtonBase className="wf-button" onClick={handleClose}>
            {t("LET'S PLAY")}
          </ButtonBase>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(SeasonStart);
