import { betsSlipConstants } from '../actions/constants';
import { produce } from 'immer';
import moment from 'moment';
import { isEqual } from 'lodash-es';

import { appEvaluateBonusRequest } from '../actions/app';
import { uuidv4, debug } from '../../utils';

const stakeThresholds = [
  {
    min: 0,
    max: 30,
    increment: 5,
  },
  {
    min: 30,
    max: 100,
    increment: 10,
  },
  {
    min: 100,
    max: 10000,
    increment: 50,
  },
];

const limits = {
  eventsCount: 10,
  minAmount: 1,
  maxAmount: 99999,
};

const defTicketRegistration = {
  event: {},
  tickets: [],
  state: [],
  currentIndex: 0,
  authorization: {
    id: '',
    maxWaiting: 150, //wait for 120 seconds until either ticket is auth/denied or auto-denied
    waiting: false,
    endTime: 0,
  },
};

const initialState = {
  totalStake: 1,
  amount: 1,
  stake: 1,
  tax: 0,
  taxPercent: 0,

  placeTicketEnabled: false,
  lottoTicket: null,
  eventsCount: 0,

  ticketCreateSuccess: false,
  ticketCreateStatus: 'pending',
  ticketOnline: true,
  ticketCode: '',

  ticketRegistration: { ...defTicketRegistration },
  ticketRegistrationIndex: 0,

  selectError: 0,

  freeBets: [],
  selectedFreeBet: -1,
  selectedFreeBetData: null,
};

const DEBUG = false;

const requestBonusEvaluation = (action, t, amount) => {
  if (!t) {
    return;
  }

  if (window.config.useBonusEvaluation !== '1') {
    return;
  }

  DEBUG && debug('requestBonusEvaluation', action, t);

  if (
    action.authentication &&
    action.authentication.auth_type !== 'user' &&
    action.authentication.auth_type !== 'token'
  ) {
    DEBUG && debug('not authenticated');
    return;
  }

  if (action.profile && action.profile.client_player_id === null) {
    DEBUG && debug('account data not present');
    return;
  }

  const tc = JSON.parse(JSON.stringify(t));

  const bData = {
    product: 'Lotto',
    ticket: {
      amount: amount,
      custom_odds: '{}',
      event_K: tc?.event?.k,
      event_M: tc?.event?.m,
      event_N: tc?.event?.n,
      event_R: tc?.event?.r,
      event_code: tc?.event?._code,
      event_id: tc?.event?._id,
      event_name: tc?.event?._name,
      event_results: null,
      event_time: moment(tc?.event?.event_date, 'YYYY-MM-DD HH:mm:ss').valueOf() * 1000000,
      gross_winning_amount: 0,
      max_winning: 0,
      min_winning: 0,
      net_winning_amount: 0,
      number_of_lines: 0,
      numbers: tc?.numbers,
      odds: tc?.event?.odds,
      system_id: tc?.event?.system_id,
      systems: tc?.systems,
      winning_tax: 0,
    },
  };

  let bDataStr = JSON.stringify(bData);
  if (action.wallet) {
    bDataStr += JSON.stringify(action.wallet);
  }
  if (amount) {
    bDataStr += `${amount}`;
  }

  if (bDataStr !== t.bDataStr) {
    bData.requestId = t.bRequestId = uuidv4();
    t.bDataStr = bDataStr;

    DEBUG && debug('bonus evaluate request', bData);

    action.asyncDispatch(appEvaluateBonusRequest(bData));
  } else {
    DEBUG && debug('nothing changed');
  }
};

const betsSlipReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case betsSlipConstants.FREE_BET_SELECTED_INDEX: {
        draft.selectedFreeBet = action.index;
        draft.selectedFreeBetData = action.freeBet;

        if (draft.selectedFreeBet > -1 && draft.selectedFreeBetData) {
          const eventCount = draft.selectedFreeBetData.count;

          if (draft.lottoTicket.allSelectedEvents.length > eventCount) {
            let lottoTicket = JSON.parse(JSON.stringify(draft.lottoTicket));

            if (draft.lottoTicket.allSelectedEvents.length - eventCount > 0) {
              let selectedEvents = [];
              let allSelectedEvents = [];
              let allSelectedEventsFull = [];

              if (eventCount > draft.lottoTicket.selectedEvents.length) {
                selectedEvents = draft.lottoTicket.selectedEvents;
                for (let i = 0; i < eventCount; i++) {
                  allSelectedEvents.push(draft.lottoTicket.allSelectedEventsFull[i].event_id);
                  allSelectedEventsFull.push(draft.lottoTicket.allSelectedEventsFull[i]);
                }
              } else {
                for (let i = 0; i < eventCount; i++) {
                  selectedEvents.push(draft.lottoTicket.allSelectedEventsFull[i].event_id);
                  allSelectedEvents.push(draft.lottoTicket.allSelectedEventsFull[i].event_id);
                  allSelectedEventsFull.push(draft.lottoTicket.allSelectedEventsFull[i]);
                }
              }

              lottoTicket.selectedEvents = selectedEvents;
              lottoTicket.allSelectedEvents = allSelectedEvents;
              lottoTicket.allSelectedEventsFull = allSelectedEventsFull;
            } else {
              lottoTicket.selectedEvents = [];
              lottoTicket.allSelectedEvents = [];
              lottoTicket.allSelectedEventsFull = [];
            }
            lottoTicket.additionalEvents = lottoTicket.allSelectedEvents.length - lottoTicket.selectedEvents.length;
            draft.lottoTicket = lottoTicket;
          }
        }

        break;
      }
      case betsSlipConstants.LOTTO_TICKETS_INCREMENT_CURRENT_INDEX: {
        const currentIndex = draft.ticketRegistrationIndex;
        if (currentIndex + 1 < draft.ticketRegistration.tickets.length) {
          draft.ticketRegistrationIndex = currentIndex + 1;
        }
        break;
      }
      case betsSlipConstants.LOTTO_TICKETS_PROCESS_NEXT_TICKET: {
        const currentIndex = draft.ticketRegistrationIndex;
        const ticketsState = [...draft.ticketRegistration.state];
        ticketsState[currentIndex].status = 'Registering';
        ticketsState[currentIndex].statusCode = 100;
        draft.ticketRegistration.state = ticketsState;
        break;
      }
      case betsSlipConstants.LOTTO_TICKETS_INITIALIZE: {
        const st = [];
        action.tickets.forEach((t) => {
          st.push({ status: 'Pending', statusCode: 0, date: t.date, ticketResult: null });
        });

        draft.ticketRegistration = {
          ...defTicketRegistration,
          tickets: action.tickets,
          event: action.event,
          state: st,
        };
        draft.ticketRegistrationIndex = 0;
        draft.selectError = 0;
        break;
      }
      case betsSlipConstants.LOTTO_TICKETS_PROCESSING_RESULT: {
        const currentIndex = draft.ticketRegistrationIndex;
        const ticketsState = [...draft.ticketRegistration.state];
        if (action.ticket.status === 'OPEN') {
          ticketsState[currentIndex].status = action.ticket.ticketOnline ? 'Registered' : action.ticket.reserve_id;
          ticketsState[currentIndex].statusCode = 200;
          ticketsState[currentIndex].ticketResult = action.ticket;
          draft.ticketRegistration.authorization.id = '';
          draft.ticketRegistration.authorization.waiting = false;
          draft.ticketRegistration.authorization.endTime = 0;
        } else if (action.ticket.status === 'MANUAL_AUTHORIZATION') {
          ticketsState[currentIndex].status = 'Manual Authorization';
          ticketsState[currentIndex].statusCode = 300;
          ticketsState[currentIndex].ticketResult = action.ticket;
          draft.ticketRegistration.authorization.id = action.ticket.eauthorization_id;
          draft.ticketRegistration.authorization.waiting = true;
          draft.ticketRegistration.authorization.endTime = (+new Date()) + draft.ticketRegistration.authorization.maxWaiting * 1000;
        } else {
          ticketsState[currentIndex].status = action.ticket.status;
          ticketsState[currentIndex].statusCode = 400;
          ticketsState[currentIndex].ticketResult = action.ticket;
          draft.ticketRegistration.authorization.id = '';
          draft.ticketRegistration.authorization.waiting = false;
          draft.ticketRegistration.authorization.endTime = 0;
        }

        draft.ticketRegistration.state = ticketsState;
        break;
      }
      case betsSlipConstants.LOTTO_TICKETS_PROCESSING_ERROR: {
        const currentIndex = draft.ticketRegistrationIndex;
        const ticketsState = [...draft.ticketRegistration.state];
        ticketsState[currentIndex].status = action.err;
        ticketsState[currentIndex].statusCode = 400;
        draft.ticketRegistration.state = ticketsState;
        break;
      }
      case betsSlipConstants.STAKE_INC: {
        let amount = draft.amount;

        if (amount === '') amount = 0;

        if (action.stake !== -1) {
          amount += action.stake;
        } else {
          // get stake threshold
          const st = stakeThresholds.find((st) => {
            if (amount >= st.min && amount < st.max) {
              return true;
            }
            return false;
          });

          // round up to the nearest increment
          if (amount === 0) {
            amount = st.increment;
          } else if (amount % st.increment === 0) {
            amount = (amount / st.increment + 1) * st.increment;
          } else {
            amount = Math.ceil(amount / st.increment) * st.increment;
          }

          //ct.amount += action.stake;
        }

        if (amount > limits.maxAmount) {
          amount = limits.maxAmount;
        }

        draft.amount = amount;
        break;
      }
      case betsSlipConstants.STAKE_DEC: {
        let amount = draft.amount;

        if (amount === '') amount = 0;
        if (amount <= limits.minAmount) return;

        if (action.stake !== -1) {
          if (amount - action.stake > 0) {
            amount -= action.stake;
          } else {
            return;
          }
        } else {
          // get stake threshold
          let stIdx = stakeThresholds.findIndex((st) => {
            if (amount >= st.min && amount < st.max) {
              return true;
            }
            return false;
          });

          if (stIdx === -1) stIdx = 0;
          //if (stIdx > 0) stIdx--;

          const st = stakeThresholds[stIdx];

          // round down to the nearest increment
          if (amount === 0) {
            amount = st.increment;
          } else if (amount % st.increment === 0) {
            amount = (amount / st.increment - 1) * st.increment;
          } else {
            amount = Math.floor(amount / st.increment) * st.increment;
          }

          if (amount <= 0) {
            amount = limits.minAmount;
          }
        }

        if (amount < limits.minAmount) {
          amount = limits.minAmount;
        }

        draft.amount = amount;
        break;
      }
      case betsSlipConstants.STAKE_SET: {
        let amount = draft.amount;

        if (action.stake === '') {
          draft.amount = '';
        } else {
          let stake = parseFloat(action.stake);
          if (isNaN(stake)) {
            stake = 0;
          }

          if (stake >= 0) {
            amount = stake;

            if (amount < limits.minAmount) {
              amount = limits.minAmount;
            } else if (amount > limits.maxAmount) {
              amount = limits.maxAmount;
            }

            draft.amount = amount;
          }
        }

        break;
      }
      case betsSlipConstants.LOTTO_CREATE_TICKET_SUCCESS: {
        break;
      }
      case betsSlipConstants.SET_TICKET_ONLINE: {
        draft.ticketOnline = action.online;
        break;
      }
      case betsSlipConstants.LOTTO_ADD_TICKET: {
        const origTicket = {};

        if (action?.ticket?.numbers) {
          action.ticket.numbers = action.ticket.numbers.filter((n) => n !== 0);
        }

        if (action.ticket && draft.lottoTicket) {
          Object.keys(action.ticket).forEach((k) => {
            if (typeof draft.lottoTicket[k] !== 'undefined') {
              origTicket[k] = draft.lottoTicket[k];
            }
          });
        }

        const origJson = JSON.parse(JSON.stringify(origTicket));

        if (isEqual(origJson, action.ticket)) {
          return;
        }

        if (action.ticket?.src === 'details' && !action.ticket?.systems) {
          const bsNumbers = draft.lottoTicket?.numbers ?? [];
          if (action.ticket?.numbers && action.ticket?.numbers.length !== bsNumbers.length) {
            if (action.ticket?.event && action.ticket?.numbers) {
              let maxSystems = action.ticket?.event.m;
              if (action.ticket?.numbers.length < action.ticket?.event.m) {
                maxSystems = action.ticket?.numbers.length;
              }
              const systems = [maxSystems];
              action.ticket.systems = systems;
            }
          }
        }

        if (draft.lottoTicket) {
          Object.keys(action.ticket).forEach((k) => {
            draft.lottoTicket[k] = action.ticket[k];
          });
        } else {
          draft.lottoTicket = action.ticket;
        }

        if (draft.selectedFreeBet !== -1 && draft.selectedFreeBetData) {
          const eventCount = draft.selectedFreeBetData.count;

          if (draft.lottoTicket.allSelectedEvents.length > eventCount) {
            if (draft.lottoTicket.allSelectedEvents.length - eventCount > 0) {
              let selectedEvents = [];
              let allSelectedEvents = [];
              let allSelectedEventsFull = [];
              if (eventCount > draft.lottoTicket.selectedEvents.length) {
                selectedEvents = draft.lottoTicket.selectedEvents;
                for (let i = 0; i < eventCount; i++) {
                  allSelectedEvents.push(draft.lottoTicket.allSelectedEventsFull[i].event_id);
                  allSelectedEventsFull.push(draft.lottoTicket.allSelectedEventsFull[i]);
                }
              } else {
                for (let i = 0; i < eventCount; i++) {
                  selectedEvents.push(draft.lottoTicket.allSelectedEventsFull[i].event_id);
                  allSelectedEvents.push(draft.lottoTicket.allSelectedEventsFull[i].event_id);
                  allSelectedEventsFull.push(draft.lottoTicket.allSelectedEventsFull[i]);
                }
              }

              draft.lottoTicket.selectedEvents = selectedEvents;
              draft.lottoTicket.allSelectedEvents = allSelectedEvents;
              draft.lottoTicket.allSelectedEventsFull = allSelectedEventsFull;
            } else {
              draft.lottoTicket.selectedEvents = [];
              draft.lottoTicket.allSelectedEvents = [];
              draft.lottoTicket.allSelectedEventsFull = [];
            }

            draft.lottoTicket.additionalEvents =
              draft.lottoTicket.allSelectedEvents.length - draft.lottoTicket.selectedEvents.length;
          }
        }

        draft.lottoTicket.bonus = {
          ticketAppliedBonus: null,
          ticketBonusEligibles: [],
        };
        draft.lottoTicket.bonusEvaluate = null;
        draft.lottoTicket.bDataStr = '';

        draft.placeTicketEnabled = true;

        if (draft.lottoTicket?.allSelectedEvents?.length === 0) {
          draft.placeTicketEnabled = false;
        }

        if (draft.lottoTicket?.numbers?.length === 0) {
          draft.placeTicketEnabled = false;
        }

        if (draft.lottoTicket?.systems?.length === 0) {
          draft.placeTicketEnabled = false;
        }

        break;
      }
      case betsSlipConstants.LOTTO_CLEAR_TICKET: {
        draft.placeTicketEnabled = false;
        draft.lottoTicket = null;
        draft.eventsCount = 0;

        draft.ticketCreateSuccess = false;
        draft.ticketCreateStatus = 'pending';
        draft.ticketOnline = true;
        draft.ticketCode = '';

        draft.ticketRegistration = { ...defTicketRegistration };
        draft.ticketRegistrationIndex = 0;
        draft.selectError = 0;

        draft.selectedFreeBet = -1;
        draft.selectedFreeBetData = null;

        draft.totalStake = 1;
        draft.amount = 1;
        draft.stake = 1;
        draft.tax = 0;
        draft.taxPercent = 0;

        break;
      }
      case betsSlipConstants.REQUEST_BONUS_EVALUATION: {
        const t = draft.lottoTicket;
        const amount = draft.amount;
        requestBonusEvaluation(action, t, amount);

        return;
      }
      case betsSlipConstants.BONUS_EVALUATE_RESPONSE: {
        //DEBUG && debug("bonus evaluate response", action.bonusEvaluate && action.bonusEvaluate.data ? action.bonusEvaluate.data : {});
        if (draft.lottoTicket) {
          draft.lottoTicket.bonusEvaluate = action.bonusEvaluate;
        }
        return;
      }
      default:
        break;
    }
  });

export default betsSlipReducer;
