import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';

//import './index.scss';

type CookiesProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const COOKIES_KEY = 'cookieAgreement';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Cookies = (componentProps: CookiesProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const [showCookies, setShowCookies] = React.useState<boolean | null>(null);

  React.useEffect(() => {
    checkCookies();
    setCookieIfCampaign();
  }, []);

  const acceptHandler = () => {
    setShowCookies(false);
    const expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime();
    // @ts-ignore
    ExpiringLocalStorage.set(COOKIES_KEY, 'true', expireDate);
  };

  const checkCookies = () => {
    const cookies = ExpiringLocalStorage.get(COOKIES_KEY);
    if (cookies) {
      setShowCookies(false);
    } else {
      setShowCookies(true);
    }
  };

  const setCookieIfCampaign = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const affId = urlParams.get('aff_id');

    if (affId) {
      // @ts-ignore
      ExpiringLocalStorage.set('aff_id', affId, 2);
    }

    const gclid = urlParams.get('gclid');
    if (gclid) {
      // @ts-ignore
      ExpiringLocalStorage.set('gclid', gclid, 2);
    }

    const sub_id = urlParams.get('sub_id');
    if (sub_id) {
      // @ts-ignore
      ExpiringLocalStorage.set('sub_id', sub_id, 2);
    }
  };

  const contextValue = React.useMemo(() => {
    return {
      showCookies: showCookies,
      acceptHandler: acceptHandler,
    };
  }, [showCookies, acceptHandler]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Cookies;
