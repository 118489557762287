import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';

class MetaPixel {
  static isActive() {
    return window.config?.pixel_id !== '';
  }
  static init(debug: boolean) {
    if (!this.isActive()) {
      if (debug) {
        window.config.MarketingEvents.push('MetaPixel not enabled');
      }
      return false;
    }
    const pixelId = window.config.pixel_id;

    if (pixelId) {
      const advancedMatching: any = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
      const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: debug || false, // enable logs
      };
      ReactPixel.init(pixelId, advancedMatching, options);

      this.pageView();
    }
  }

  static pageView() {
    if (!this.isActive()) {
      return false;
    }
    ReactPixel.pageView();
  }

  static deposit(data: { currency: string; amount: number }) {
    if (!this.isActive()) {
      return false;
    }
    ReactPixel.track('Purchase', { currency: data.currency, value: data.amount });
    ReactPixel.track('Lead', { currency: data.currency, value: data.amount });
  }

  static signUp() {
    if (!this.isActive()) {
      return false;
    }

    ReactPixel.track('CompleteRegistration');
  }

  static addPaymentMethod(data: { user_data: { email: string } }): boolean {
    if (!this.isActive()) {
      return false;
    }

    ReactPixel.track('AddPaymentMethod', data);
    return true;
  }
}
export default MetaPixel;
