/* eslint prefer-rest-params: 0 */
/* eslint prefer-spread: 0 */
import { createSelectorCreator } from 'reselect';

// START STUFF TAKEN DIRECTLY FROM RESELECT SOURCE
function defaultEqualityCheck(a: any, b: any) {
  return a === b;
}

function areArgumentsShallowlyEqual(equalityCheck: any, prev: any, next: any) {
  if (prev == null || next == null || prev.length !== next.length) {
    return false;
  }

  // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
  const length = prev.length;
  for (let i = 0; i < length; i++) {
    if (prev[i] !== next[i]) {
      return false;
    }
  }

  return true;
}

function shallowEqualArrays(arrA: any[], arrB: any[]): boolean {
  if (arrA === arrB) {
    return true;
  }

  if (!arrA || !arrB) {
    return false;
  }

  const len = arrA.length;

  if (arrB.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    if (arrA[i] !== arrB[i]) {
      return false;
    }
  }

  return true;
}
// END STUFF TAKEN DIRECTLY FROM RESELECT SOURCE

function componentMatchesMemoized(
  func: any,
  resultEqualityCheck = defaultEqualityCheck,
  equalityCheck = defaultEqualityCheck,
) {
  const lastArgs: any = {};
  const lastResult: any = {};

  // we reference arguments instead of spreading them for performance reasons
  return function () {
    const from = arguments?.[0];
    const dsId = arguments?.[1];

    if (!from) return null;
    if (!dsId) return null;

    if (!areArgumentsShallowlyEqual(equalityCheck, lastArgs?.[`${from}-${dsId}`], arguments)) {
      // apply arguments instead of spreading for performance.
      const result = func.apply(null, arguments);
      if (!resultEqualityCheck(lastResult?.[`${from}-${dsId}`], result)) {
        lastResult[`${from}-${dsId}`] = result;
      }
    }

    lastArgs[`${from}-${dsId}`] = arguments;
    return lastResult[`${from}-${dsId}`];
  };
}
export const createMemoizeSelector = createSelectorCreator(componentMatchesMemoized, shallowEqualArrays);
