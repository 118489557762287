import { searchConstants } from '../actions/constants';

const initialState = {
  query: '',
  queryLeague: ''
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchConstants.SAVE:
      if (state.query !== action.query) {
        return {
          ...state,
          query: action.query,
        };
      }
      return state;
    case searchConstants.SAVE_LEAGUE:
      if (state.queryLeague !== action.query) {
        return {
          ...state,
          queryLeague: action.query,
        };
      }
      return state;
    default:
      return state;
  }
};

export default searchReducer;