import { takeEvery, put } from 'redux-saga/effects';

import * as actionTypes from '../../modules/casino/store/actions/actionTypes';
import { fetchElements } from '../slices/dataElements';

function* fetchSiteElement() {
  // @ts-ignore
  yield put(fetchElements({ ids: ['_site'] }));
}

export default function* watchDataElementsSaga() {
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, fetchSiteElement);
}
