import React from 'react';

import evBusMain from '../../utils/evbus';
import EditPhoneNumber from './actions/edit-phone-number';

type Action = {
  type: string;
  data: any;
};

const ActionUIHandler = () => {
  const [action, setAction] = React.useState<Action | null>(null);

  const handleUIAction = (data: any, event: string) => {
    setAction(data);
  };

  const handleClose = () => {
    setAction(null);
  };

  React.useEffect(() => {
    evBusMain.on('HANDLE_UI_ACTION', handleUIAction);
    return () => {
      evBusMain.remove('HANDLE_UI_ACTION', handleUIAction);
    };
  }, []);

  if (!action) return null;

  if (action.type === 'EDIT_PHONE_NUMBER') {
    return <EditPhoneNumber data={action.data} onClose={handleClose} />;
  }

  return null;
};

export default ActionUIHandler;
