import Utils from '@/modules/casino/utils/Utils';

class OneTagPixel {
  static init(debug: boolean) {
    // this function is called from MarketingEvents.init()
    if (debug) {
      if (!this.isActive()) {
        window.config.MarketingEvents.push('OneTagPixel not enabled');
      }
    }
  }

  static getInfo(): { src: string; params: { [key: string]: string } } {
    return {
      src: 'https://onetag-sys.com/audience-router/?tag=script',
      params: {
        allPages: '&segment_group=683',
        signUpStarted: '&segment_id=116806',
        signUpCompleted: '&segment_id=116805&account_id={ACCOUNT_ID}',
        depositStarted: '&segment_id=116804&account_id={ACCOUNT_ID}',
        ftd: '&segment_id=116802&account_id={ACCOUNT_ID}',
        depositCompleted: '&segment_id=116803&account_id={ACCOUNT_ID}',
      },
    };
  }

  static removeAll(exception?: string) {
    const info = this.getInfo();
    const events = Object.keys(info.params);
    events.forEach((event) => {
      // skip allPages
      if (event === 'allPages') {
        return;
      }

      // skip exception - current event most of the time
      if (exception && exception === event) {
        return;
      }

      // @ts-ignore
      this.remove(event);
    });
  }

  static remove(event: string) {
    Utils.removeScript(`onetag-script-${event}`);
  }

  static isActive() {
    return window.config?.one_tag_pixel_enabled;
  }

  static pushEvent(event: string, playerId: string) {
    if (!this.isActive()) {
      return false;
    }
    this.removeAll(event);
    const info = this.getInfo();

    let eventUrl = `${info.src}${info.params[event]}`;
    if (playerId) {
      eventUrl = eventUrl.replace('{ACCOUNT_ID}', playerId);
    }
    Utils.injectScript(eventUrl, `onetag-script-${event}`, false, {
      async: true,
      referrerPolicy: 'no-referrer-when-downgrade',
    });
  }

  static pageView() {
    return;
    // this.pushEvent('allPages');
  }

  static signUpStarted() {
    return;
    // this.pushEvent('signUpStarted');
  }

  static signUpCompleted(playerId: string | undefined) {
    if (playerId) {
      this.pushEvent('signUpCompleted', playerId);
    } else {
      console.log('OneTagPixel: playerId is missing');
    }
  }

  static depositStarted(playerId: string | undefined) {
    if (playerId) {
      this.pushEvent('depositStarted', playerId);
    } else {
      console.log('OneTagPixel: playerId is missing');
    }
  }

  static ftd(playerId: string | undefined) {
    if (playerId) {
      this.pushEvent('ftd', playerId);
    } else {
      console.log('OneTagPixel: playerId is missing');
    }
  }

  static depositCompleted(playerId: string | undefined) {
    if (playerId) {
      this.pushEvent('depositCompleted', playerId);
    } else {
      console.log('OneTagPixel: playerId is missing');
    }
  }
}
export default OneTagPixel;
