class GoogleAds {
  static init(debug: boolean) {
    if (debug) {
      if (!this.isActive()) {
        window.config.MarketingEvents.push('GoogleAds not enabled');
      }
    }
  }

  static isActive() {
    return !!window.config.google_ads_id;
  }

  static pushConversion(data: {
    send_to: string;
    value?: number;
    currency?: string;
    transaction_id?: string;
    event_callback?: () => void;
  }) {
    if (!this.isActive()) {
      return false;
    }
    // if (typeof data.event_callback === 'undefined') {
    //     data.event_callback = this.conversion_callback;
    // }
    // @ts-ignore
    window.gtag('event', 'conversion', data);
  }

  static deposit(data: { currency: string; amount: number }) {
    if (!this.isActive()) {
      return false;
    }
    if (window.config?.google_ads_deposit_label) {
      GoogleAds.pushConversion({
        send_to: `${window.config.google_ads_id}/${window.config.google_ads_deposit_label}`,
        value: data.amount,
        currency: data.currency,
        transaction_id: '',
      });
    }
  }

  static signUp() {
    if (!this.isActive()) {
      return false;
    }
    if (window.config?.google_ads_register_label) {
      GoogleAds.pushConversion({
        send_to: `${window.config.google_ads_id}/${window.config.google_ads_register_label}`,
      });
    }
  }
}

export default GoogleAds;
