import React, { useContext } from 'react';
import styled from 'styled-components';
import { shallowEqual } from 'react-redux';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

type BetProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ChangedData = (props: any) => {
  const [data, setData] = React.useState<any>(props.data);

  React.useEffect(() => {
    if (JSON.stringify(props.data) !== JSON.stringify(data)) setData(props.data);
  }, [data, props.data]);

  return data;
};

const Bet = (componentProps: BetProps) => {
  let props: any = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const { prematchSelectedBets, liveSelectedBets } = useAppSelector(
    (state) => ({
      prematchSelectedBets: state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket].prematch.selected,
      liveSelectedBets: state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket].live.selected,
    }),
    shallowEqual,
  );

  const [state, setState] = React.useState();

  let selectedBets: any = [];
  if (props.properties.mType === 'prematch') {
    selectedBets = prematchSelectedBets;
  } else if (props.properties.mType === 'live') {
    selectedBets = liveSelectedBets;
  }

  const selectedHash: any = {};
  selectedBets.forEach((bet: any) => {
    selectedHash[bet.idMbo] = true;
  });

  const data = {
    mType: props.properties.mType,
    idSport: props.properties.idSport,
    idMatch: props.properties.idMatch,
    idBet: props.properties.idBet,
    idMb: props.properties.idMb,
    idBo: props.properties.idBo,
    idMbo: props.properties.idMbo,
    oddValue: props.properties.oddValue,
    changeDir: props.properties.changeDir,
    oddName: props.properties.oddName,
    marketName: props.properties.marketName,
    selected: selectedHash[props.properties.idMbo] ? true : false,
  };

  const memoizedFinal = ChangedData({ data: data });

  const contextValue = React.useMemo(() => {
    //console.log('Bet[contextValue]', memoizedFinal);

    return memoizedFinal;
  }, [memoizedFinal]);

  return <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>;
};

export default Bet;
