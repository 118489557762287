import { debug } from './';
import { normalizeBets } from './bonusEvaluation';
import getStore from '../store';
import { getBetsState } from '../store/selectors/betData';
import { activeWinnerFunCampaigns } from './winnerFunUtils';

const evaluateMatch = (fs, match) => {
    // if no filters, then all are valid
    if (!fs || !fs.filters) return false;

    let idx = fs.filters.findIndex(f => {
        if ('idMatch' in f) return match.idMatch === f.idMatch;
        if ('idTournament' in f) return match.idTournament === f.idTournament;
        if ('idCategory' in f) return match.idCategory === f.idCategory;
        if ('idSport' in f) return match.idSport === f.idSport;
        return false;
    });

    return idx !== -1;
};

const evaluateMatch2 = (fs, idMatch, idTournament, idCategory, idSport) => {
    // if no filters, then all are valid
    if (!fs || !fs.filters) return false;

    let idx = fs.filters.findIndex(f => {
        if ('idMatch' in f) return idMatch === f.idMatch;
        if ('idTournament' in f) return idTournament === f.idTournament;
        if ('idCategory' in f) return idCategory === f.idCategory;
        if ('idSport' in f) return idSport === f.idSport;
        return false;
    });

    return idx !== -1;
};

const evaluateEvent = (event, ticket) => {
    // check there are some filters
    if (!('filters' in event)) return [false, 1];

    // check ticket type in filters
    if (!(ticket.betType in event.filters)) return [false, 1];

    const fs = event.filters[ticket.betType];

    debug('filters', fs.filters);

    let eligible = false;
    let pointsMultiplier = event.pointsMultiplier;

    for (let bet of ticket.bets) {
        if (fs && fs.filters) {
            let idx = fs.filters.findIndex(f => {
                if ('idMatch' in f) return bet.idMatch === f.idMatch;
                if ('idTournament' in f) return bet.idTournament === f.idTournament;
                if ('idCategory' in f) return bet.idCategory === f.idCategory;
                if ('idSport' in f) return bet.idSport === f.idSport;
                return false;
            });

            if (idx === -1) {
                // bet is not in event filters - ticket may be eligible but we reset the multiplier
                pointsMultiplier = 1;
            } else {
                // at leat one bet matches filters - set eligible
                eligible = true;
            }
        } else {
            // if no filters
            pointsMultiplier = 1;
        }
    }

    // all bets found in ticket filters - ticket eligible
    return [eligible, pointsMultiplier];
};

const round2 = v => Math.round(v * 100) / 100;

const ERR_TICKET_NOT_ELIGIBLE = -1;
const ERR_TOTAL_ODD_LOWER_THAN_MIN_CAP_ODD = -2;
const ERR_ODD_CAP_NOT_DEFINED = -3;
const ERR_ODD_CAP_INVALID = -4;
const ERR_NO_SECONDARY_AMOUNT = -5;
const ERR_DUPLICATE_EVENT_BOOST = -6;

const computeBoost = (event, ticket) => {
    const odds = ticket.totalOdds;

    const minOdd = event.min_odd_cap;
    const maxOdd = event.max_odd_cap;

    debug('computeBoost', 'odds', odds, 'minOdd', minOdd, 'maxOdd', maxOdd);

    if (!minOdd || !maxOdd) {
        return ERR_ODD_CAP_NOT_DEFINED;
    }

    if (maxOdd < minOdd) {
        return ERR_ODD_CAP_INVALID;
    }

    if (odds <= minOdd) {
        return ERR_TOTAL_ODD_LOWER_THAN_MIN_CAP_ODD;
    }

    if (odds >= maxOdd) {
        return event.pointsMultiplier;
    }

    const oddsp = (ticket.totalOdds - minOdd) / (maxOdd - minOdd);

    const r = round2(1 + oddsp * (event.pointsMultiplier - 1));

    debug('oddsp', oddsp, 'r', r);

    return r;
};

const evaluateWinnerFun = rt => {
    debug('evaluateWinnerFun', rt);

    const ticket = rt.ticket;

    const state = getStore().getState();
    const bst = getBetsState(state);

    if (state.authentication.auth_type !== 'user' && state.authentication.auth_type !== 'token') {
        return;
    }

    const { auth, betsSlip, app, config, ticketsWinnerFun } = bst;

    if (!app.isWinnerFun) {
        debug('not in winner fun');
        return;
    }

    debug('config.winnerFunCampaigns', config.winnerFunCampaigns);

    const winnerFunData = activeWinnerFunCampaigns(config.winnerFunCampaigns);

    debug('winnerFunData', winnerFunData);

    if (!winnerFunData.season && !winnerFunData.event) {
        return;
    }

    const tbets = normalizeBets(ticket.betType, ticket.bets);

    ticket.product = ticket.betType === 'prematch' ? 'SportsbookSM' : 'LiveBetting';
    ticket.bets = tbets;

    // total amount to pay
    let toPay = ticket.amount;
    let ticket_amount = toPay;
    let main_amount = 0;
    let secondary_amount = 0;

    // check if ticket is eligible for event
    const event = winnerFunData.event;
    let eventMatch = false;
    let eventPointsMultiplier = 1;
    let evalError = 0;

    if (event) {
        [eventMatch, eventPointsMultiplier] = evaluateEvent(event, ticket);
    }

    // default response
    let response = {
        valid: false,
        eligible: false,
        evalError,
        main_amount: 0,
        secondary_amount: 0,
        details: [],
        points: 0,
        event_name: winnerFunData.event ? winnerFunData.event.name : '',
        event_icon: winnerFunData.event ? winnerFunData.event.icon : { desktop: '', mobile: '' },
        season_icon: winnerFunData.season ? winnerFunData.season.icon : { desktop: '', mobile: '' },
        maxWinAmount: ticket.maxWinAmount,
        minWinAmount: ticket.minWinAmount,
    };

    let minWinAmount = ticket.minWinAmount;
    let originalMinWinAmount = minWinAmount;
    let maxWinAmount = ticket.maxWinAmount;
    let originalMaxWinAmount = maxWinAmount;
    let boostAvailable = false;
    let wfTotalOdds = ticket.totalOdds;

    if (eventMatch && rt.secondary_amount) { // don't process if there is zero wincoins in secondary
        // consume from secondary amount
        if (rt.secondary_amount >= toPay) {
            secondary_amount = toPay;
        } else {
            secondary_amount = rt.secondary_amount;
        }

        // update to pay
        toPay = toPay - secondary_amount;

        // pay what's left from main amount
        if (toPay > 0) {
            if (rt.main_amount >= toPay) {
                main_amount = toPay;
            } else {
                main_amount = rt.main_amount;
            }

            // update to pay
            toPay = toPay - main_amount;
        }

        // store winnerFun winCoins data => type boost

        // if the multiplier is > 1 then the ticket is eligible
        if (eventPointsMultiplier > 1) {
            // check if the user wants to boost
            if (ticket.use_boost) {
                // check if the user has boosters
                if (rt.boost_num > 0) {
                    // boost available
                    boostAvailable = true;

                    // compute the actual multiplier
                    eventPointsMultiplier = computeBoost(event, ticket);

                    if (eventPointsMultiplier < 0) {
                        evalError = eventPointsMultiplier;
                        eventPointsMultiplier = 1;
                    }
                } else {
                    // no boosts available. reset multiplier
                    eventPointsMultiplier = 1;
                }
            } else {
                // user doesn't want to boost. check if boost available
                boostAvailable = rt.boost_num > 0;

                // and reset multiplier
                eventPointsMultiplier = 1;
            }
        } else {
            evalError = ERR_TICKET_NOT_ELIGIBLE;
        }

        /* min win */

        // compute min profit
        const minProfit = ticket.minWinAmount;

        debug('minProfit = ', minProfit);

        // main min win for main amount participation
        const mainMinWin = (main_amount * minProfit) / ticket_amount;

        // secondary min win for secondary amount participation and evaluated event points multiplier
        const secondaryMinWin = (secondary_amount * minProfit * eventPointsMultiplier) / ticket_amount;

        minWinAmount = mainMinWin + secondaryMinWin;

        /* max win */
        const maxProfit = ticket.maxWinAmount;

        debug('maxProfit = ', maxProfit);

        // main min win for main amount participation
        const mainMaxWin = (main_amount * maxProfit) / ticket_amount;

        // secondary min win for secondary amount participation and evaluated event points multiplier
        const secondaryMaxWin = (secondary_amount * maxProfit * eventPointsMultiplier) / ticket_amount;

        maxWinAmount = mainMaxWin + secondaryMaxWin;

        wfTotalOdds = round2(maxWinAmount / ticket_amount);

        if (toPay > 0) {
            // not enough money in main + secondary to pay ticket
            return {
                success: true,
                data: {
                    ...response,
                    eligible: true,
                    evalError,
                    main_amount,
                    secondary_amount,
                    minWinAmount,
                    maxWinAmount,
                    eventPointsMultiplier,
                    details: ['Insufficient balance'],
                },
            };
        }
    } else {
        eventPointsMultiplier = 1;

        // consume from main amount
        if (rt.main_amount >= toPay) {
            main_amount = toPay;
        } else {
            main_amount = rt.main_amount;
        }

        // update to pay
        toPay = toPay - main_amount;

        // pay what's left from secondary amount, only if there is an event active
        if (toPay > 0 && event) {
            if (rt.secondary_amount >= toPay) {
                secondary_amount = toPay;
            } else {
                secondary_amount = rt.secondary_amount;
            }

            // update to pay
            toPay = toPay - secondary_amount;
        }

        if (toPay > 0) {
            // not enough money in secondary + main to pay ticket
            return {
                success: true,
                data: {
                    ...response,
                    eligible: false,
                    evalError,
                    main_amount,
                    secondary_amount,
                    eventPointsMultiplier,
                    details: ['Insufficient balance'],
                },
            };
        }
    }

    let duplicateEvent = null;

    if (evalError === 0 && eventMatch) {
        debug('checking for duplicate events');

        // ticket uses boost. check open tickets for identical events
        for (let bet of ticket.bets) {
            debug('bet', bet);

            for (let ot of ticketsWinnerFun.ticketsOpened) {
                debug('ot', ot);

                let usedBoost = false;
                if (ot.eval_res && ot.eval_res.event_balance_multiplier > 1) {
                    usedBoost = true;
                }

                if (!usedBoost) {
                    continue;
                }

                for (let obet of ot.bets) {
                    debug('obet', obet);

                    if (bet.idMatch === '' + obet.idMatch) {
                        evalError = ERR_DUPLICATE_EVENT_BOOST;
                        duplicateEvent = obet.teams.map(t => t.teamDisplayName).join(' - ');
                        break;
                    }
                }

                if (evalError !== 0) {
                    break;
                }
            }

            if (evalError !== 0) {
                break;
            }
        }
    }

    response = {
        valid: true,
        eligible: eventMatch,
        evalError,
        event_min_odd_cap: event && event.min_odd_cap ? event.min_odd_cap : 1,
        event_max_odd_cap: event && event.max_odd_cap ? event.max_odd_cap : 1,
        main_amount,
        secondary_amount,
        ticket_amount,
        event_name: winnerFunData.event ? winnerFunData.event.name : '',
        event_icon: winnerFunData.event ? winnerFunData.event.icon : { desktop: '', mobile: '' },
        season_icon: winnerFunData.season ? winnerFunData.season.icon : { desktop: '', mobile: '' },
        minWinAmount,
        maxWinAmount,
        originalMinWinAmount,
        originalMaxWinAmount,
        eventPointsMultiplier,
        maxMultiplier: boostAvailable ? event.pointsMultiplier : 1,
        boostAvailable,
        wfTotalOdds,
        duplicateEvent,
    };

    return {
        success: true,
        data: response,
    };
};

export { evaluateWinnerFun, evaluateMatch, evaluateMatch2 };
