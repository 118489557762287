import { wheelConstants } from './constants';
import getStore from '../../store';

export const loadWheelStatus = () => ({
  type: wheelConstants.LOAD,
});

export const loadingWheelStatus = () => ({
  type: wheelConstants.LOADING,
});

export const loadedWheelStatus = (status, skip) => {
  const { wheelsDataSource } = getStore().getState();
  const wheelSetups = wheelsDataSource.wheels;

  let wheelSetupId = '';
  if (wheelSetups && Array.isArray(wheelSetups)) {
    wheelSetupId = wheelSetups[0].id;
  } else {
    const wls = Object.values(wheelSetups);
    if (wls && wls.length && wls[0].id !== 'undefinded') {
      wheelSetupId = wls[0].id;
    }
  }

  const cfg =
    wheelSetups && Array.isArray(wheelSetups)
      ? JSON.parse(JSON.stringify(wheelSetups[0]))
      : typeof wheelSetups === 'object' && wheelSetupId && typeof wheelSetups[wheelSetupId] !== 'undefined'
        ? JSON.parse(JSON.stringify(wheelSetups[wheelSetupId]))
        : null;

  let randomTime = 600;

  if (status && status.wheel_setup && status.free_spin && status.free_spin.next_spin_after) {
    if (cfg) {
      if (typeof cfg !== 'undefined' && cfg.parameters) {
        randomTime = cfg.parameters.randomTime;
      }
    }
  }

  return {
    type: wheelConstants.LOADED,
    randomTime: skip ? 0 : randomTime,
    status,
  };
};
