import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import LockIcon from '@material-ui/icons/Lock';
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReplyIcon from '@material-ui/icons/Reply';

import './ReferralCode.scss';
import { getWinnerFunState } from '../../store/selectors/winnerFunData';
import { copyInputText } from '../../utils/functions';
import LockOpenSVG from '../../assets/lock-open@3x.png';
import WinnerCoinLogo from './assets/coins.png';
import WinnerCoinImg from '../../assets/winner-fun-logo.svg';
import { playerLoad } from '../../store/actions/player';
import { winCoinsCollectReferrer } from '../../store/actions/wincoins';
import ReferralPopup from '../../views/modals/ReferralPopup';

import MaleProfileIcon from '../../assets/avatars/icon_profile_male.svg';

const ReferralCode = (props) => {
  const { player, t, settings, walletTotal, onCollected, playerLoad, winCoinsCollectReferrer } = props;

  const [popup, setPopup] = React.useState({
    open: false,
    link: '',
  });

  const [loading, setLoading] = React.useState(false);
  const [loadingIndex, setLoadingIndex] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = () => {
    setExpanded((v) => !v);
  };

  let code = player.player_id;

  const list = [];

  let toCollect = 0;
  player &&
    player.friends &&
    player.friends.forEach((f) => {
      if (!f.collected) {
        toCollect += 1;
      }

      list.push({
        friend_id: f.friend_id,
        nickname: f.nickname,
        wincoins: f.amount,
        collected: f.collected,
        locked: false,
      });
    });

  if (list.length < settings.no_friends) {
    for (let i = list.length; i < 5; i++) {
      list.push({ wincoins: settings.coins, locked: true });
    }
  }

  React.useEffect(() => {
    playerLoad();
  }, []); // eslint-disable-line

  const handleCopy = () => {
    const el = document.getElementById('referral-link-input');
    if (el) {
      copyInputText(el);
    }
  };

  const handleCollect = (player_id, amount) => () => {
    setLoadingIndex(player_id);
    setTimeout(() => {
      (function (amount, walletTotal) {
        winCoinsCollectReferrer({
          referrer_id: player_id,
          cb: (success) => {
            if (success) {
              onCollected(amount, walletTotal);
            }
            setLoadingIndex('');
          },
        });
      })(amount, walletTotal);
    }, 0);
  };
  const handleCollectMe = () => {
    setLoading(true);
    setTimeout(() => {
      (function (amount, walletTotal) {
        winCoinsCollectReferrer({
          cb: (success) => {
            if (success) {
              onCollected(amount, walletTotal);
            }
            setLoading(false);
          },
        });
      })(player.register_coins, walletTotal);
    }, 0);
  };

  const noop = () => {};
  const url = `${window.config.front_url}/register?winner_fun=true&winner_referral_id=${code}`;

  const handleOpen = () => {
    setPopup({
      open: true,
      link: url,
    });
  };
  const handleClose = () => {
    setPopup({
      open: false,
      link: '',
    });
  };

  if (!code) return null;

  // let profileMale = `${window.config.front_url}/img/mobile/profile/icon_profile_male.svg`;

  let showCollect = false;
  if (!player.register_collected && player.register_coins !== 0) {
    showCollect = true;
  }

  let collected = 0;
  list.forEach((el) => {
    if (el.collected) collected += 1;
  });
  let isFull = collected === list.length;

  return (
    <div className="wf-referral-code">
      <div className="wf-header-top">
        <div className="wf-icon">
          <GroupAddOutlinedIcon />
        </div>
        <div className="wf-title">{t('Friends')}</div>
      </div>
      <div className={`wf-body ${expanded ? 'wf-expanded' : 'wf-collapsed'}`}>
        {showCollect && (
          <div className="wf-header wf-mb-10 wf-special">
            <div className="wf-coins-holder">
              <img src={WinnerCoinLogo} alt="collect" />
              <div className={'wf-wrapper'}>
                <div className="wf-inner-wrapper">
                  <img src={WinnerCoinImg} alt="winner coin" />
                  <div className={'wf-text'}>+{player.register_coins}</div>
                </div>
              </div>
            </div>

            <div className={`wf-button ${loading ? 'wf-loading' : ''}`} onClick={handleCollectMe}>
              {t('COLLECT')}
              <div className={'wf-loader'}>
                <CircularProgress />
              </div>
            </div>
          </div>
        )}

        {!showCollect && (
          <div className="wf-header">
            <div className="wf-text">{t('Invite a friend to join the game and receive WinCoins')}</div>
            <Badge badgeContent={toCollect !== 0 && !expanded ? toCollect : 0} color="error">
              <div className={'wf-button'} onClick={handleExpand}>
                {expanded && t('CLOSE')}
                {!expanded && toCollect !== 0 && t('OPEN ')}
                {!expanded && toCollect === 0 && !isFull && t('BEGIN')}
                {!expanded && toCollect === 0 && isFull && t('SEE YOUR TEAM')}
              </div>
            </Badge>
          </div>
        )}

        {expanded && (
          <React.Fragment>
            <div className="wf-input-body">
              <div className="wf-text">{t('Send this link to your friends')}:</div>
              <div className="wf-input">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{t('Link')}</InputLabel>
                  <OutlinedInput
                    id="referral-link-input"
                    type="text"
                    value={url}
                    readOnly
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleCopy} edge="end">
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
              </div>
            </div>
            <div className="wf-list-body">
              <div className="wf-header">{t('See below when they made an account')}</div>
              <div className="wf-list">
                {list.map((el, i) => {
                  let cls = 'wf-regular';
                  if (el.collected) {
                    cls = 'wf-collected';
                  } else if (!el.collected && !el.locked) {
                    cls = 'wf-collect';
                  }

                  let handler =
                    cls === 'wf-collect'
                      ? handleCollect(el.friend_id, parseInt(el.wincoins))
                      : cls === 'wf-regular'
                        ? handleOpen
                        : noop;

                  return (
                    <div className={`wf-element ${cls}`} key={i} onClick={handler}>
                      <div className={`wf-collect-button ${cls} ${loadingIndex === el.friend_id ? 'wf-loading' : ''}`}>
                        {el.collected && (
                          <div className="wf-icon">
                            <CheckIcon />
                          </div>
                        )}
                        {!el.collected && !el.locked && <img src={LockOpenSVG} alt="" />}
                        {!el.collected && el.locked && (
                          <div className="wf-lock-icon">
                            <LockIcon />
                          </div>
                        )}
                        {el.wincoins !== 0 && <div className="wf-coins">{el.wincoins}</div>}
                        {loadingIndex === el.friend_id && (
                          <div className={'wf-loader'}>
                            <CircularProgress />
                          </div>
                        )}
                      </div>
                      <div className="wf-avatar">
                        {/* <img src={profileMale} alt="" /> */}
                        <img src={MaleProfileIcon} alt="" />
                      </div>
                      <div className="wf-nickname">{el.nickname ? el.nickname : t('Empty')}</div>
                      {el.locked && (
                        <div className="wf-invite">
                          {t('Invite')} <ReplyIcon />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      {popup.open && <ReferralPopup open={popup.open} handleClose={handleClose} link={popup.link} />}
    </div>
  );
};

const mapStateToProps = (state) => {
  const wfs = getWinnerFunState(state);
  return {
    player: wfs.player,
    walletTotal: wfs && wfs.wallet && wfs.wallet.total ? wfs.wallet.total : 0,
    settings: wfs.config.settings,
  };
};

const mapActions = {
  playerLoad,
  winCoinsCollectReferrer,
};

export default withTranslation()(connect(mapStateToProps, mapActions)(ReferralCode));
