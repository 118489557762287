import getStore from '../store';

const DEBUG = false;

export const starThresholds = [7, 11, 18, 27, 37];

export const momentumProgressDetails = (bonusProgress, starThresholds, strict = false) => {
  DEBUG && console.log(`momentumProgressDetails in: bonusProgress = ${bonusProgress}, starThresholds = `, starThresholds);

  let res = {
    stars: 0,
    progress: 0,
  };

  if (strict) {
    let ps = 0;
    for (let i = 0; i < starThresholds.length; i++) {
      ps += starThresholds[i];
    }

    if (ps !== 100) {
      console.error(`momentumProgressDetails: starThresholds sum is not 100, ps = ${ps}`);
      return res;
    }

    if (isNaN(bonusProgress) || bonusProgress <= 0) {
      console.error(
        `momentumProgressDetails: bonusProgress is not a positive number, bonusProgress = ${bonusProgress}`
      );
      return res;
    }
  }

  if (isNaN(bonusProgress) || bonusProgress <= 0) {
    res = {
      stars: 0,
      progress: 0,
    };
  } else if (bonusProgress >= 100) {
    res = {
      stars: starThresholds.length,
      progress: 0,
    };
  } else {
    let currentStar = 0;

    while (currentStar < starThresholds.length && bonusProgress >= starThresholds[currentStar]) {
      bonusProgress -= starThresholds[currentStar];
      currentStar++;
    }

    DEBUG && console.log(`momentumProgressDetails: currentStar = ${currentStar}, remaining bonusProgress = ${bonusProgress}`);

    res = {
      stars: currentStar,
      progress: Math.round((bonusProgress / starThresholds[currentStar]) * 100),
    };
  }

  DEBUG && console.log('momentumProgressDetails out: res = ', res);

  return res;
};

export const detectBrowser = () => {
  const ua = navigator.userAgent;

  let isIos = /iPad|iPhone|iPod/.test(ua) && !window.MSStream;
  if (window.config?.native_platform === 'ios') {
    isIos = true;
  }
  const isSafari = ua.indexOf('Safari') !== -1 && ua.indexOf('Chrome') === -1;

  if (isIos || isSafari) {
    return true;
  }
};

export const triggerGTMEvent = (data) => {
  try {
    const state = getStore().getState();

    if (!state?.momentum?.prize?.value?.uuid || !state?.profile?.global_player_id) {
      return;
    }

    const event = {
      ...data,
      momentum_session_id: state.momentum.prize.value.uuid ?? '',
      user_id: state.profile.global_player_id ?? '',
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  } catch (e) {/* */ }
};


