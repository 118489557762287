import { playerConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  default_nickname: '',
  nickname: '',
  history: '{}',
  approved: false,
  player_id: '',
  friends: [],
  register_coins: 0,
  register_collected: false,
  team_id: '',
};

const templateReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case playerConstants.LOADED:
        if (action.data && typeof action.data.default_nickname !== 'undefined') {
          draft.default_nickname = action.data.default_nickname;
        }

        if (action.data && typeof action.data.nickname !== 'undefined') {
          draft.nickname = action.data.nickname;
        }

        if (action.data && typeof action.data.history !== 'undefined') {
          draft.history = action.data.history;
        }

        if (action.data && typeof action.data.approved !== 'undefined') {
          draft.approved = action.data.approved;
        }

        if (action.data && typeof action.data.player_id !== 'undefined') {
          draft.player_id = action.data.player_id;
        }

        if (action.data && typeof action.data.friends !== 'undefined') {
          draft.friends = action.data.friends;
        }

        if (action.data && typeof action.data.register_coins !== 'undefined') {
          draft.register_coins = action.data.register_coins;
        }

        if (action.data && typeof action.data.register_collected !== 'undefined') {
          draft.register_collected = action.data.register_collected;
        }

        if (action.data && typeof action.data.team_id !== 'undefined') {
          draft.team_id = action.data.team_id;
        }

        break;
      default:
      /* noop */
    }
  });

export default templateReducer;
