import * as actionTypes from './actionTypes';

export const requestWallet = () => {
  return (dispatch) => {
    /*
        dispatch({
            type:"WINNER_FUN_WALLET_REQUEST_WALLET"
        });
        */

    dispatch({
      type: actionTypes.wallet.REQUEST_WALLET,
    });
  };
};

export const walletReceived = (data, currentCurrency) => {
  return {
    type: actionTypes.wallet.RECEIVED_WALLET,
    wallet: data,
    currentCurrency
  };
};

export const resetWallet = () => {
  return {
    type: actionTypes.wallet.RESET_WALLET,
  };
};

export const requestRemoveBonus = (bonusID) => {
  return {
    type: actionTypes.wallet.REQUEST_REMOVE_BONUS,
    bonusID: bonusID,
  };
};

export const receivedRemoveBonus = (result) => {
  return {
    type: actionTypes.wallet.RECEIVED_REMOVE_BONUS,
    result: result,
  };
};

export const coreSetBalance = (data) => ({
  type: actionTypes.wallet.CORE_SET_BALANCE,
  data,
});
