export const productID = {
  HOME: 1,
  CASINO: 2,
  LOTTO: 3,
  SPORT: 4,
  LIVE_CASINO: 5,
  LIVE_SPORT: 6,
  VIRTUALS: 7,
  WINNER_FUN: 8,
  CUSTOMER_SUPPORT: 9,
  ALL: 10000,
};

export const productMainURL = {
  HOME: '/',
  CASINO: '/casino',
  LOTTO: '/lotto',
  SPORT: '/bets/pre-match',
  LIVE_CASINO: '/live-casino',
  LIVE_SPORT: '/bets/live-match',
  VIRTUALS: '/virtuals',
  WINNER_FUN: '/winnerfun',
  CUSTOMER_SUPPORT: '/profile/customer-support',
  ALL: '*',
};

const serverIDs: any = {};
serverIDs[productID.CASINO] = 1;
serverIDs[productID.HOME] = 1;
serverIDs[productID.SPORT] = 2;
serverIDs[productID.LIVE_SPORT] = 2;
serverIDs[productID.WINNER_FUN] = 2;
serverIDs[productID.LOTTO] = 3;
serverIDs[productID.LIVE_CASINO] = 4;
serverIDs[productID.ALL] = 10000;

export const serverProductID = serverIDs;
