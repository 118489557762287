import * as actionTypes from '../../../casino/store/actions/actionTypes';
import { liveConstants, ticketConstants } from '../actions/constants';
import { produce } from 'immer';
import { ticketReloadPrematch, ticketReloadLive } from '../actions/tickets';
import { debug } from '../../utils';

const initialState = {
	lastError: '',
	ticketsOpened: [],
	ticketsSettled: [],
	ticketsSettledFetched: [],
	ticketsReserved: [],
	showMore: true,
	requestInProgress: {
		opened: false,
		settled: false,
		reserved: false
	},
	ticketsSettledData: {
		prematchOffset: 0,
		prematchAvailable: 0,
		prematchMore: true,
		liveOffset: 0,
		liveAvailable: 0,
		liveMore: true,
		cashoutPrematchOffset: 0,
		cashoutPrematchAvailable: 0,
		cashoutPrematchMore: true,
		digitainLiveOffset: 0,
		digitainLiveAvailable: 0,
		digitainLiveMore: true
	},
	ticketCheckCodeStatus: '',
	ticketCodeData: null,
	ticketCode: null,
	matches: {
		prematch: {},
		live: {}
	},
	meta: {
		prematch: {
			sports: {},
			categories: {},
			tournaments: {}
		},
		live: {
			sports: {},
			categories: {},
			tournaments: {}
		}
	}
};

const ticketsReducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {
			case ticketConstants.REQUESTING_DATA: {
				draft.requestInProgress[action.data.key] = action.data.val;
				break;
			}
			case ticketConstants.SETTLED_LIST_RECEIVED: {
				const page = action.data.page;
				if (page === 1) {
					draft.ticketsSettled = action.data.rows;
				} else {
					draft.ticketsSettled = draft.ticketsSettled.concat(action.data.rows);
				}

				draft.ticketsSettledData = action.data.tsd;
				draft.ticketsSettledFetched = action.data.fetched;
				draft.showMore =
					action.data.tsd.liveMore ||
					action.data.tsd.prematchMore ||
					action.data.tsd.cashoutPrematchMore ||
					action.data.tsd.digitainLiveMore ||
					action.data.fetched.length > 0;

				//draft.showMore = action.data.rows.length === 0 ? false : true;

				draft.requestInProgress['settled'] = false;
				draft.lastError = '';
				break;
			}
			case ticketConstants.OPENED_LIST_RECEIVED: {
				draft.ticketsOpened = action.data;
				draft.requestInProgress['opened'] = false;
				draft.lastError = '';
				break;
			}
			case ticketConstants.RESERVED_LIST_RECEIVED: {
				draft.ticketsReserved = action.data;
				draft.requestInProgress['reserved'] = false;
				draft.lastError = '';
				break;
			}
			case ticketConstants.LIST_ERROR: {
				draft.lastError = action.error;
				draft.requestInProgress = {
					opened: false,
					settled: false,
					reserved: false
				};
				break;
			}
			case ticketConstants.STORE_OPEN: {
				draft.ticketsOpened.unshift(...action.data);
				break;
			}
			case ticketConstants.CHECK_CODE_PENDING: {
				draft.ticketCheckCodeStatus = 'pending';
				draft.ticketCodeData = null;
				draft.ticketCode = null;
				break;
			}
			case ticketConstants.CHECK_CODE_DATA: {
				//console.log("check code data", action);
				draft.ticketCheckCodeStatus = 'done';
				draft.ticketCodeData = action.data;
				draft.ticketCode = action.code;
				break;
			}
			case ticketConstants.CHECK_CODE_ERROR: {
				draft.ticketCheckCodeStatus = 'error';
				draft.ticketCodeData = action.error;
				draft.ticketCode = null;
				break;
			}
			case ticketConstants.CHECK_CODE_CLOSE: {
				draft.ticketCheckCodeStatus = '';
				draft.ticketCodeData = null;
				draft.ticketCode = null;
				break;
			}
			case liveConstants.REMOVE_MATCHES: {
				//debug(`live match finished`, action.data);

				draft.ticketsOpened
					.filter(t => t.product === 'Live')
					.forEach(t => {
						if (t.bets.find(b => b.idMatch === action.data.idMatch)) {
							debug(`need to reload live ticket ${t.id}`, t);

							if (!t.requestUuid) {
								debug('ticket has no requestUuid');
								return;
							}

							action.asyncDispatch(
								ticketReloadLive({
									id: t.id,
									requestUuid: t.requestUuid
								})
							);
						}
					});

				draft.ticketsOpened
					.filter(t => t.product === 'PreMatch')
					.forEach(t => {
						if (t.bets.find(b => b.brId === action.data.idMatch)) {
							debug(`need to reload prematch ticket ${t.id}`, t);

							if (!t.requestUuid) {
								debug('ticket has no requestUuid');
								return;
							}

							action.asyncDispatch(
								ticketReloadPrematch({
									id: t.id,
									requestUuid: t.requestUuid
								})
							);
						}
					});

				break;
			}
			case ticketConstants.HANDLE_UPDATED: {
				debug('handle updated tickets', action.ts);

				const ut = action.ts[0];

				let ticketsOpened = [];
				let doneReset = true;

				draft.ticketsOpened.forEach(t => {
					// check updated ticket ID
					if (t.id !== ut.id) {
						ticketsOpened.push(t);
						return;
					}

					// found the updated ticket
					if (ut.status === 'OPEN') {
						// ticket is still open so keep it
						ticketsOpened.push(ut);
						return;
					}

					// since the status is no longer open we need to reset the list of settled tickets
					if (!doneReset) {
						draft.ticketsSettled = [];
						draft.ticketsSettledFetched = [];
						draft.ticketsSettledData = {
							prematchOffset: 0,
							prematchAvailable: 0,
							prematchMore: true,
							liveOffset: 0,
							liveAvailable: 0,
							liveMore: true
						};
						draft.showMore = true;
						doneReset = true;
					}
				});

				draft.ticketsOpened = ticketsOpened;

				break;
			}
			case actionTypes.wallet.CORE_SET_BALANCE: {
				debug('core set balance', action.data);

				/*
				if (
					action.data.source &&
					(action.data.source === "LiveBetting" ||
						action.data.source === "SportsbookSM") &&
					action.data.referenceId
				)
				*/

				if (action.data.referenceId) {
					draft.ticketsOpened = draft.ticketsOpened.filter(
						t => t.id !== action.data.referenceId
					);
				}

				break;
			}
			case ticketConstants.STORE_META: {
				debug('store ticket meta', action);

				draft.matches.prematch = Object.assign(
					draft.matches.prematch,
					action.matches.prematch
				);
				draft.matches.live = Object.assign(draft.matches.live, action.matches.live);

				draft.meta.prematch.sports = Object.assign(
					draft.meta.prematch.sports,
					action.meta.prematch.sports
				);
				draft.meta.prematch.categories = Object.assign(
					draft.meta.prematch.categories,
					action.meta.prematch.categories
				);
				draft.meta.prematch.tournaments = Object.assign(
					draft.meta.prematch.tournaments,
					action.meta.prematch.tournaments
				);

				draft.meta.live.sports = Object.assign(
					draft.meta.live.sports,
					action.meta.live.sports
				);
				draft.meta.live.categories = Object.assign(
					draft.meta.live.categories,
					action.meta.live.categories
				);
				draft.meta.live.tournaments = Object.assign(
					draft.meta.live.tournaments,
					action.meta.live.tournaments
				);

				break;
			}
			case ticketConstants.HANDLE_CASHOUTED: {
				//debug("handle cashouted", action.ticketHash);

				/*
				const tHash = action.ticketHash;

				let ticketsOpened = [];

				draft.ticketsOpened.forEach(t => {
					// check updated ticket ID
					if (t.idHash !== tHash) {
						ticketsOpened.push(t);
					}
				});

				draft.ticketsOpened = ticketsOpened;
				*/

				break;
			}
			default:
				break;
		}
	});

export default ticketsReducer;
