import React from 'react';
import styled from 'styled-components';

const defaultProps = {
  className: '',
  styleText: `
  width: 100%;
  height: 100%;
  `,
};

const ContainerDiv = styled.div((props) => props.$styleText);

export const RootWrapper = (componentProps) => {
  return (
    <ContainerDiv className={`root-wrapper ${componentProps.className ?? ''}`} $styleText={componentProps.styleText} style={componentProps.style}>
      {componentProps.children}
    </ContainerDiv>
  );
};
