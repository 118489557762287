import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { saveAvatar, saveNickname, clearError } from '@/store/slices/social';

//import './index.scss';

type SocialProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Social = (componentProps: SocialProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const avatars = useAppSelector((state) => state.social.avatars);
  const nickname = useAppSelector((state) => state.social.nickname);
  const avatar = useAppSelector((state) => state.social.avatar);
  const saving = useAppSelector((state) => state.social.saving);
  const saved = useAppSelector((state) => state.social.saved);
  const error = useAppSelector((state) => state.social.error);
  const dispatch = useAppDispatch();

  const [value, setValue] = React.useState(nickname?.nickname ? nickname.nickname : nickname?.default_nickname);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setValue(nickname?.nickname ? nickname.nickname : nickname?.default_nickname);
  }, [nickname]);

  const onSelectAvatar = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.code) {
      const code = e.currentTarget.dataset.code;
      dispatch(saveAvatar({ code }));
    }
  }, []);

  const onSaveNickname = React.useCallback(() => {
    if (value && value !== nickname?.nickname) {
      dispatch(saveNickname({ nickname: value }));
    }
  }, [value, nickname]);

  const onToggleOpen = React.useCallback(() => {
    if (open) {
      dispatch(clearError());
      setValue(nickname?.nickname ? nickname.nickname : nickname?.default_nickname);
    }
    setOpen((v) => !v);
  }, [open, nickname]);

  const onChangeValue = React.useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const value = e.target ? (e.target as HTMLInputElement).value : '';
    setValue(value?.substring(0, 13) ?? '');
  }, []);

  React.useEffect(() => {
    if (saved && !error) {
      setOpen(false);
      dispatch(clearError());
      setValue(nickname?.nickname ? nickname.nickname : nickname?.default_nickname);
    }
  }, [saved, error]);

  const contextValue = React.useMemo(() => {
    return {
      nickname,
      avatar,
      unlockedAvatars: avatars?.filter((el) => el.state === 0) ?? [],
      lockedAvatars: avatars?.filter((el) => el.state === 1) ?? [],
      onSelectAvatar,
      value,
      onChangeValue,
      saving,
      open,
      onToggleOpen,
      onSaveNickname,
      error: error ? `Error: ${error}` : null,
      errorStatus: error ? 'error' : 'default',
      saved,
    };
  }, [
    avatars,
    nickname,
    avatar,
    componentProps,
    onSelectAvatar,
    value,
    onChangeValue,
    saving,
    open,
    onToggleOpen,
    onSaveNickname,
    error,
    saved,
  ]);

  console.log('Social[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Social;
