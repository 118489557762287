import { takeEvery, put } from 'redux-saga/effects';
import {
  loadingHistory,
  loadedHistory,
  loadingLeaderBoard,
  loadedLeaderBoard,
  claimedPrize,
  loadPrizes,
  claimPrizeByIdSuccess,
  claimPrizeByIdFailed,
  loadedPrizes,
} from '../actions/history';
import { historyConstants } from '../actions/constants';
import getStore from '..';
import axios from 'axios';

import { requestWallet } from '../../../../modules/casino/store/actions/wallet';
import { freeBetsFetch } from '../../../../modules/casino/store/actions/free_bets';
import { freeSpinsFetch } from '../../../../modules/casino/store/actions/free_spins';
import prizeCurrencyFormat from '../../../../modules/casino/utils/prizeCurrencyFormat';

let apiTournamentsUrl = window.config.betsApiUrl + '/missions';
let apiPrizesUrl = window.config.betsApiUrl + '/api/prizes/v1';

const listRequests = {};
function* loadHistorySaga(action) {
  if (listRequests[action.groupId]) return;

  const { authentication } = getStore().getState();

  const groupId = action.groupId;
  if (!groupId) return;
  listRequests[action.groupId] = true;

  yield put(loadingHistory());

  let headers = {};
  if (authentication.access_token) {
    headers = {
      Authorization: 'Bearer ' + authentication.access_token,
    };
  } else {
    return;
  }

  try {
    const resp = yield axios.get(apiTournamentsUrl + '/tournaments/history/list/' + groupId, { headers });
    yield put(loadedHistory({ groupId, data: resp.data }));
    delete listRequests[action.groupId];
  } catch (err) {
    console.log('loadLeaderBoardSaga[ERR]:', err);
    yield put(loadedHistory({ groupId, data: [] }));
    delete listRequests[action.groupId];
  }
}

const leadRequests = {};
function* loadLeaderBoardSaga(action) {
  if (leadRequests[action.tournamentId]) return;

  const { authentication } = getStore().getState();

  const tournamentId = action.tournamentId;
  if (!tournamentId) return;
  leadRequests[tournamentId] = true;

  yield put(loadingLeaderBoard());

  let headers = {};
  if (authentication.access_token) {
    headers = {
      Authorization: 'Bearer ' + authentication.access_token,
    };
  }
  try {
    const resp = yield axios.get(apiTournamentsUrl + '/tournaments/history/leaderboard/' + tournamentId, { headers });
    yield put(loadedLeaderBoard({ tournamentId, data: resp.data }));
    delete leadRequests[tournamentId];
  } catch (err) {
    console.log('loadPlayerLeaderBoardSaga[ERR]:', err);
    yield put(loadedLeaderBoard({ tournamentId, data: {} }));
    delete leadRequests[tournamentId];
  }
}

function* claimPrizeSaga(action) {
  const { authentication } = getStore().getState();

  const tournamentId = action.tournamentId;
  const groupId = action.groupId;
  const cb =
    action.cb && typeof action.cb === 'function'
      ? action.cb
      : () => {
          /* */
        };

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  let headers = {
    Authorization: 'Bearer ' + authentication.access_token,
  };

  try {
    const resp = yield axios.get(apiTournamentsUrl + '/tournament/claim/' + tournamentId, { headers });
    if (resp && resp.data && resp.data.failed_count === 0) {
      yield put(claimedPrize({ tournamentId, groupId }));
      cb(true);
    } else {
      cb(false);
    }
  } catch (err) {
    console.log('claimPrizeSaga[ERR]:', err);
    cb(false);
  }
}

function* claimPrizeByIdSaga(action) {
  const { authentication } = getStore().getState();

  // console.log('DEBUG CLAIM PRIZE BY ID SAGA:', action);

  const id = action.id;
  const cb =
    action.cb && typeof action.cb === 'function'
      ? action.cb
      : () => {
          /* */
        };
  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  let headers = {
    Authorization: 'Bearer ' + authentication.access_token,
  };

  try {
    const resp = yield axios.get(apiPrizesUrl + '/player/claim/' + id, { headers });
    if (resp && resp.data && resp.data.success) {
      cb(true);
      // console.log('claimPrizeByIdSaga response SUCCESS TRUE:', resp);
      yield put(loadPrizes({ data: resp.data }));
      yield put(claimPrizeByIdSuccess());

      // ****
      yield put(requestWallet());
      yield put(freeSpinsFetch());
      yield put(freeBetsFetch());
    } else {
      // console.log('claimPrizeByIdSaga response SUCCESS FALSE:', resp);
      yield put(claimPrizeByIdFailed());
      cb(false);
    }
  } catch (err) {
    console.log('claimPrizeByIdSaga[ERR]:', err);
    yield put(claimPrizeByIdFailed());
    cb(false);
  }
}

function* loadPrizesSaga(action) {
  const { authentication } = getStore().getState();

  const cb =
    action.cb && typeof action.cb === 'function'
      ? action.cb
      : () => {
          /* */
        };

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }
  let headers = {
    Authorization: 'Bearer ' + authentication.access_token,
  };

  try {
    // const resp = false;
    const resp = yield axios.get(apiPrizesUrl + '/player/list', { headers });

    if (resp && resp.data) {
      // console.log('loadPrizesSaga[SUCCESS]:', resp.data);
      const prizes = [];

      resp.data.forEach((prize) => {
        prizes.push({
          ...prize,
          // formated prize
          id: prize.id,
          claim_end_time: prize.claim_end_time,
          reward_class_type: prize.reward_class_type,
          reward_type: prize.reward_type,
          reward_currency: prize.reward_currency,
          reward_value: prize.reward_value,
          reward_metadata: prize.reward_metadata,
          meta: prize.meta,
          art_bundle: prize.art_bundle,
          currency: prize.currency,
          currency_display_settings: prizeCurrencyFormat(prize.currency, prize.reward_value),

          // activate_action: 'pendingBonuses.activatePrize',
        });
      });

      yield put(loadedPrizes({ data: prizes }));
      // yield put(loadedPrizes({ data: resp.data }));
      cb(true);
    } else {
      cb(false);
    }
  } catch (err) {
    console.log('loadPrizes[ERR]:', err);
    cb(false);
  }
}

export default function* watchLeaderBoardSaga() {
  yield takeEvery(historyConstants.LOAD, loadHistorySaga);
  yield takeEvery(historyConstants.LOAD_LEADER_BOARD, loadLeaderBoardSaga);
  yield takeEvery(historyConstants.CLAIM_PRIZE, claimPrizeSaga);
  yield takeEvery(historyConstants.CLAIM_PRIZE_BY_ID, claimPrizeByIdSaga);
  yield takeEvery(historyConstants.LOAD_PRIZES, loadPrizesSaga);
}
