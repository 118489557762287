import React from 'react';

interface LiveGameIframeProps {
  gameUrl?: string;
  onIframeMessage?: (m: any) => void;
}

interface Message {
  command?: string;
}

export enum GameActions {
  CloseGame,
}

const LiveGameIframe = (props: LiveGameIframeProps) => {
  const style: Record<string, string> = {};
  if (props.gameUrl == null || !props.gameUrl) {
    style.display = 'none';
  }

  return (
    <iframe
      id="evolution-game"
      src={props.gameUrl}
      style={style}
      scrolling="no"
      allowFullScreen={true}
      title="Live Casino Game"
    />
  );
};

export default React.memo(LiveGameIframe);
