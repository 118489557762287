import React from 'react';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { chooseTextLanguage, styleToObj, choseImage } from "../../utils/functions";

const Current = (props) => {
  const { seasons, t, i18n } = props;
  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const imageType = isDesktop1024 ? "desktop" : "mobile";


  const buildEvent = (info, key, type) => {
    const now = moment();
    const sd = moment(parseInt(info.startDate), "x");
    const ed = moment(parseInt(info.endDate), "x");
    const days = ed.diff(sd, "days");
    let progress = 0;
    let isBefore = false;

    if (now.isBefore(sd)) {
      progress = 0;
      isBefore = true;
    } else if (now.isAfter(ed)) {
      progress = 100;
    } else {// in between
      progress = Math.floor(now.diff(sd, "days") * 100 / days);
    }

    return <div className="wf-content" key={key}>
      <div className="wf-icon">
        <img src={choseImage(info.icon, imageType)} alt="event icon" />
      </div>
      <div className="wf-info">
        <div className="wf-summary">
          <div className="d-flex align-items-start justify-content-between">
            <div className="d-flex flex-column">
              <div className="wf-title" style={styleToObj(info.name.style)}>{chooseTextLanguage(info.name.text, i18n.language)}</div>
              <div className="wf-subtitle">{info.type === "season" ? t("SEASON") : t("COMPETITION")}</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="wf-days d-flex flex-column justify-content-center align-items-center">
                <div className="wf-mt">{days}</div>
                <div>{t("wf.days")}</div>
              </div>
              <div className="wf-period d-flex flex-column justify-content-center align-items-start">
                <div className="wf-start">{sd.format("MMM, D")}</div>
                <div className="wf-end">{ed.format("MMM, D")}</div>
              </div>
            </div>
          </div>
          {!isBefore && <div className={`wf-progress ${progress > 0 ? 'wf-started' : 'wf-ended'}`}>
            <LinearProgress variant="determinate" value={progress} />
          </div>}
          {isBefore && <div className="wf-soon">{t("COMING SOON")}</div>}
          <div className={`wf-ranking d-flex align-items-center justify-content-between`}>
            <div className="wf-ranking-left d-flex flex-column justify-content-center align-items-start">
              <div className="wf-label">{t("RANKING")}</div>
              <div className="wf-value" style={styleToObj(info.ranking.style)}>{chooseTextLanguage(info.ranking.text, i18n.language)}</div>
            </div>
            <div className="wf-ranking-right d-flex flex-column justify-content-center align-items-start">
              <div className="wf-label">{t("AWARDS")}</div>
              <div className="wf-value" style={styleToObj(info.awards.style)}>{chooseTextLanguage(info.awards.text, i18n.language)} <span className="wf-currency">Lei</span></div>
            </div>
          </div>
        </div>
        <div className="wf-title" style={styleToObj(info.description.title.style)}>{chooseTextLanguage(info.description.title.text, i18n.language)}</div>
        <div className="wf-description" style={styleToObj(info.description.text.style)} dangerouslySetInnerHTML={{ __html: chooseTextLanguage(info.description.text.text, i18n.language) }} />
      </div>

    </div>
  };

  const buildEvents = (events, type) => {
    return events.map((event, i) => buildEvent(event, `event-${i}`, type));
  };

  const buildSeasons = (seasons, type) => {
    const res = [];

    const now = moment().valueOf();

    seasons.forEach((season, i) => {
      if (parseInt(season.endDate) < now) return;

      const style = {
        backgroundImage: `url("${choseImage(season.headerBG, imageType)}")`
      };

      res.push(<div className="wf-calendar-event" key={`season-${i}`}>
        <div className="wf-calendar-header" style={style}>
          <div className="wf-title" style={styleToObj(season.headerTitle.style)}>
            {chooseTextLanguage(season.headerTitle.text, i18n.language)}
          </div>
          <div className="wf-subtitle" style={styleToObj(season.headerSubtitle.style)}>
            {chooseTextLanguage(season.headerSubtitle.text, i18n.language)}
          </div>
        </div>
        {buildEvent(season, "main-event", type)}
        {buildEvents(season.events, type)}
      </div>);
    });
    return res;
  };

  return buildSeasons(seasons);
}

export default withTranslation()(Current);