import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import { evaluatePropFunction } from '../utils/evaluatePropFunction';
import { evaluateVisibilityFunction } from '../utils/evaluateVisibilityFunction';
import { applyLinksValues } from '../utils/applyLinksValues';

export const defaultProps = {
  className: '',
  styleText: '',
};

const WheelCanvasDiv = styled.canvas((props) => props.$styleText);

export const WheelCanvas = (componentProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  let props = JSON.parse(JSON.stringify(tmpProps));

  const dataElementContext = useContext(DataElementContext);
  if (dataElementContext && props?.properties['links'] && Object.values(props?.properties['links']).length) {
    applyLinksValues(props?.properties, dataElementContext);
  }

  if (props?.properties && props?.properties['propFunction'] != null && props?.properties['propFunction'])
    evaluatePropFunction(props, dataElementContext);

  if ((props?.visibility && props?.visibility['visibilityFunction'] != null) || props.hide != null || props.properties.__display != null) {
    const visible = evaluateVisibilityFunction(props, dataElementContext);
    if (visible != null && !visible) return null;
  }

  let onClick = null;
  if (props.properties && props.properties.onClick && typeof props.properties.onClick === 'function') {
    onClick = props.properties.onClick;
  } else if (
    componentProps.properties &&
    componentProps.properties.onClick &&
    typeof componentProps.properties.onClick === 'function'
  ) {
    onClick = componentProps.properties.onClick;
  }

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  return (
    <WheelCanvasDiv
      ref={props.properties.elementRef}
      className={`wheel wheel-canvas-element ${props.className ?? ''}`}
      onClick={onClick}
      style={props.style}
      width="324"
      height="430"
      {...extraDataProps}
    />
  );
};
