import React, { useContext } from 'react';

import { DataElementContext } from '../common/DataElementContext';
import DrawerLib from './lib/Drawer';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    isOpen: false,
    fullscreen: null,
    size: null,
    backdrop: 'true',
    fade: true,
    halign: 'center',
    valign: 'center',
  },
  visibility: {},
};

export const Drawer = (componentProps) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const onRequestClose = (close) => {
    if (typeof props?.properties?.onRequestClose === 'function') {
      props.properties.onRequestClose(close);
    } else {
      setIsOpen(close);
    }
  };

  const render = typeof props?.properties?.onRequestClose === 'function' ? props.properties.open : isOpen;

  if (!props?.properties?.preventCloseRender && !render) {
    return null;
  }
  if (!isVisible) return null;

  return <DrawerLib
    open={typeof props?.properties?.onRequestClose === 'function' ? props.properties.open : isOpen}
    onRequestClose={onRequestClose}
    notifyWillOpen={props?.properties?.notifyWillOpen}
    notifyWillClose={props?.properties?.notifyWillClose}
    top={props?.properties?.top ?? false}
    openOffset={props?.properties?.openOffset ? parseFloat(props?.properties?.openOffset) : -70}
    closeOffset={props?.properties?.closeOffset ? parseFloat(props?.properties?.closeOffset) : 70}
    dontApplyListeners={props?.properties?.dontApplyListeners ?? false}
    modalElementClass={props?.properties?.modalElementClass ?? ''}
    style={props.style}
  >
    {componentProps.children}
  </DrawerLib>;
};
