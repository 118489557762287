import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ModalHeader from '../ModalHeader';
import { getLottoState } from '../../store/selectors/lottoData';
import SearchIcon from './assets/search.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      backgroundColor: '#EDF0F0'
    },
    '&.MuiDialog-root': {
      zIndex: '1304!important',
    }
  },
  minHeight: {
    '& .MuiPaper-root.MuiDialog-paper': {
      minHeight: 'calc(100vh - 64px)',
      width: '1024px'
    }
  },
  minHeightSmall: {
    '& .MuiPaper-root.MuiDialog-paper': {
      minHeight: 'calc(100vh - 64px)',
      width: '612px'
    }
  },
  search: {
    backgroundColor: '#fff',
    padding: '8px 23px',
    height: '46px',
    position: 'relative',
    borderBottom: '1px solid #EDF0F0'
  },
  searchInput: {
    width: 'calc(100% - 30px)',

    fontSize: '20px',
    color: '#000',
    border: '0',
    backgroundColor: '#fff',
    marginRight: '10px',
    padding: '4px 10px',
  },
  searchIcon: {
    verticalAlign: 'middle',
    '& path': {
      fill: '#2545BD!important'
    }
  },
  content: {
    height: 'calc(100% - 102px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '12px 14px'
  },
  dialogItem: {
    cursor: 'pointer',
    backgroundColor: '#fff',
    padding: '17px 26px 8px',
    borderBottom: '1px solid #EDF0F0',
    '&:active': {
      backgroundColor: '#ccc',
      position: 'relative',
      top: '3px'
    }
  },
  title: {
    fontSize: '16px',
    color: '#FF196E',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  subtitle: {
    fontSize: '12px',
    color: '#17214D',
    fontWeight: '600'
  },
  dialogItemActive: {
    backgroundColor: '#06D2BD',
    '& .title': {
      color: '#fff',
      '& .bullet': {
        backgroundColor: '#c6f5f0',
        color: '#457e78',
      }
    },
    '& .subtitle': {
      color: '#fff'
    }
  },
  betsWrapper: {
    backgroundColor: '#fff',
    borderRadius: '13px',
    '& > div:first-child': {
      borderTopLeftRadius: '13px',
      borderTopRightRadius: '13px'
    },
    '& > div:last-child': {
      borderBottomLeftRadius: '13px',
      borderBottomRightRadius: '13px',
      borderBottomWidth: '0'
    }
  },
  header: {
    borderLeft: '3px solid #F8991C',
    paddingLeft: '16px',
    fontSize: '22',
    color: '#17214D',
    fontWeight: '600',
    margin: '14px 0'
  },
  probe: {
    width: '1px',
    height: '1px',
  },
  dropShadow: {
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  bullet: {
    borderRadius: '50%',
    backgroundColor: '#FF196E',
    color: '#fff',
    fontSize: '10px',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 auto',
    marginLeft: '10px'
  },
  mt: {
    marginTop: '25px'
  },
  pb: {
    paddingBottom: '17px'
  }
});

const BetSearch = props => {
  const classes = useStyles();
  const { selected, lottoSystems, lottoEvents, open, onClose, direct, t, active } = props;

  const isDesktop = useMediaQuery('(min-width:1024px)');
  const fullScreen = isDesktop ? false : true;

  const [openDialog, setOpenDialog] = React.useState(open);
  const [filter, setFilter] = React.useState('');
  const [dropShadow, setDropShadow] = React.useState(false);


  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const inputRef = React.useCallback(node => {
    if (node !== null) {
      setTimeout(() => {
        node.focus();
      }, 0);
    }
  }, []);

  const probe = React.createRef();

  React.useEffect(() => {
    const fireEvent = (stuck, targetInfo, rootBoundsInfo) => {
      setDropShadow(oldVal => {
        if (stuck !== oldVal) {
          return stuck;
        }
        return oldVal;
      });
    };

    let observer = null;
    let probeRef = probe && probe.current ? probe.current : null;

    if (probeRef) {
      observer = new IntersectionObserver(
        (records, observer) => {
          for (const record of records) {
            const targetInfo = record.boundingClientRect;
            const rootBoundsInfo = record.rootBounds;

            if (targetInfo && rootBoundsInfo) {

              // Started sticking.
              if (targetInfo.bottom < rootBoundsInfo.top) {
                fireEvent(true, targetInfo, rootBoundsInfo);
              }

              // Stopped sticking.
              if (
                targetInfo.bottom >= rootBoundsInfo.top &&
                targetInfo.bottom < rootBoundsInfo.bottom
              ) {
                fireEvent(false, targetInfo, rootBoundsInfo);
              }
            }
          }
        },
        { threshold: [0], root: probeRef.parentElement }
      );

      observer.observe(probeRef); // attach observer to our probe
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [probe]);

  if (!open) return null;

  const handleClose = () => {
    if (direct) {
      onClose(selected);
      return;
    }
    onClose();
  };

  const filterChange = e => {
    setFilter(e.target.value);
  };

  const counts = {};
  lottoEvents.forEach(e => {
    if (typeof counts[e.event_name] === 'undefined') {
      counts[e.event_name] = 1;
    } else {
      counts[e.event_name] += 1;
    }
  });

  const systems = [...lottoSystems];
  systems.forEach((s, i) => {
    const tmp = { ...s };
    tmp.count = counts[s.system_name] ? counts[s.system_name] : 0;
    if (s.system_name.toLowerCase() === selected.toLowerCase()) {
      tmp.selected = true;
    } else {
      tmp.selected = false;
    }
    systems[i] = tmp;
  });

  systems.sort(function (a, b) {
    return a.system_name.localeCompare(b.system_name);
  });

  let selectSystems = systems;
  if (filter) {
    selectSystems = selectSystems.filter(s => s.system_name.toLowerCase().indexOf(filter.toLowerCase()) > -1);
  }
  if (active) {
    selectSystems = selectSystems.filter(s => s.count > 0);
  }

  //const selectSystemsPart1 = selectSystems.filter(s => s.count > 0);
  //const selectSystemsPart2 = selectSystems.filter(s => s.count === 0);
  //selectSystems = [...selectSystemsPart1, ...selectSystemsPart2];

  const handleOnClose = (i) => () => {
    onClose(selectSystems[i].system_name);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      disableEnforceFocus
      open={openDialog}
      TransitionComponent={Transition}
      maxWidth={!fullScreen ? 'sm' : false}
      className={`${classes.root} ${isDesktop ? classes.minHeight : ''} lottoModal`}
      onClose={handleClose}
    >
      <ModalHeader title={t('Search Lottery Systems')} type="none" goBack={handleClose} />
      <div className={`${classes.search} ${dropShadow ? classes.dropShadow : ''}`}>
        <input
          ref={inputRef}
          onChange={filterChange}
          value={filter}
          placeholder={t('search lottery systems')}
          type="text"
          className={classes.searchInput}
        />
        <img src={SearchIcon} className={classes.searchIcon} alt='' />
      </div>
      <div className={classes.content}>
        <div className={classes.probe} ref={probe}></div>
        {/*
        <div className={classes.header}>{t("Recommended")}</div>
        <div className={classes.betsWrapper}>
          {selectSystems.slice(0, 5).map((s, i) => (
            <div key={i} className={`${classes.dialogItem} ${classes.pb} ${s.selected ? classes.dialogItemActive : ''}`} onClick={() => handleOnClose(i)}>
              <div className={`${classes.title} title d-flex flex-row flex-nowrap align-items-center justify-content-between`}>
                <div>{s.system_name}</div>
                {s.count > 0 && <div className={`${classes.bullet} bullet`}>{s.count}</div>}
              </div>
            </div>
          ))}
        </div>
          */}

        {selectSystems.length > 0 && (
          <React.Fragment>
            {/*<div className={`${classes.header} ${classes.mt}`}>{t("All")}</div>*/}
            <div className={classes.betsWrapper}>
              {/* eslint-disable-next-line */}
              {selectSystems.map((s, i) => (<div key={i} className={`${classes.dialogItem} ${classes.pb} ${s.selected ? classes.dialogItemActive : ''}`} onClick={handleOnClose(i)}>
                <div className={`${classes.title} title d-flex flex-row flex-nowrap align-items-center justify-content-between`}>
                  <div>{s.system_name}</div>
                  {!direct && s.count > 0 && <div className={`${classes.bullet} bullet`}>{s.count}</div>}
                </div>
              </div>))}
            </div>
          </React.Fragment>
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => {
  const bst = getLottoState(state);
  return {
    lottoEvents: bst.lottoEvents.items,
    lottoSystems: bst.lottoEvents.systems,
  };
};

const actionCreators = {
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(BetSearch));
