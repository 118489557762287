interface Props {
  [key: string]: any;
}
interface Context {
  [key: string]: any;
}

interface EvaluatePropFunction {
  (props: Props, context?: Context): boolean;
}

export const evaluateVisibilityFunction: EvaluatePropFunction = (props, context) => {
  if (props?.hide != null && props?.hide === true) return false;
  if (props?.properties?.__display != null && props?.properties?.__display === false) return false;

  const visibilityFunction =
    props?.visibility?.['visibilityFunction'] != null ? props?.visibility['visibilityFunction'] : null;

  if (props != null && visibilityFunction != null) {
    if (typeof visibilityFunction === 'string') {
      if (visibilityFunction === '') return null;
      try {
        const code = `"use strict"; return ${visibilityFunction}`;
        const fn = window.Function(code)();
        // this function might change the props values
        return fn(props, context);
      } catch (err) {
        console.log('evaluatePropFunction: function call', err);
      }
    } else if (typeof visibilityFunction === 'boolean') {
      return visibilityFunction;
    }
    return false;
  }
  return null;
};
