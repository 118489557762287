import { lottoResultsConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  latestResults: [],
  results: [],
  total: [],
  loadingLatest: false,
  loading: false,
};

const lottoResultsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case lottoResultsConstants.LOADED_LATEST:
        action.rows.sort((a, b) => {
          if (a && a.event_date && b && b.event_date) return b.event_date.localeCompare(a.event_date);
          return 0;
        });
        draft.latestResults = action.rows;
        draft.loadingLatest = false;
        break;
      case lottoResultsConstants.LOADED:
        action.rows.sort((a, b) => {
          if (a && a.event_date && b && b.event_date) return b.event_date.localeCompare(a.event_date);
          return 0;
        });
        if (action.add) {
          draft.results = [...draft.results, ...action.rows];
        } else {
          draft.results = action.rows;
        }
        draft.total = action.total;
        draft.loading = false;
        break;
      case lottoResultsConstants.LOADING:
        draft.loading = action.data;
        break;
      default:
      /* noop */
    }
  });

export default lottoResultsReducer;
