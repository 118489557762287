import { cloneDeep } from 'lodash-es';

export interface SourceItem {
  view?: string;
  priority?: number;
  [key: string]: any;
}

export enum RepeatType {
  repeat_all = 'repeat_all',
  repeat_last = 'repeat_last',
  repeat_last_n = 'repeat_last_n',
}

export interface RepeaterItem {
  cmId?: string;
  view?: string;
}

export interface Repeater {
  items: RepeaterItem[];
  type: RepeatType | string;
  n: number;
  order: string[];
}

interface SortSourcesProps {
  (items: SourceItem[], repeater?: Repeater): any[];
}

export const sortSources: SortSourcesProps = (items, repeater) => {
  if (!items) return items;

  if (repeater != null && repeater.items.length > 0 && items.length > 0) {
    const res: RepeaterItem[] = [];
    const copy = cloneDeep(items);

    if (items[0].priority != null) {
      copy.sort((a, b) => {
        if (a.priority != null && b.priority != null) {
          return b.priority - a.priority;
        }
        return 0;
      });
    }

    const viewsKeys: { [id: string]: any[] } = {};
    repeater.items.forEach((r) => {
      if (r.view) viewsKeys[r.view] = [];
    });

    let viewsPrioritiesArr: (string | number)[] = repeater.order;

    if (!viewsPrioritiesArr || !viewsPrioritiesArr.length) {
      const viewsPriorities: { [id: string]: number } = {};
      viewsPrioritiesArr = Object.keys(viewsKeys);

      viewsPrioritiesArr.forEach((view) => {
        viewsPriorities[view] = viewsKeys[view].length;
      });

      viewsPrioritiesArr.sort((a, b) => viewsPriorities[a] - viewsPriorities[b]);
    }

    const UNKNOWN_VIEW = '_internal_unknonw_view';

    viewsKeys[UNKNOWN_VIEW] = [];

    copy.forEach((i) => {
      if (i.view != null && viewsKeys[i.view] != null) {
        viewsKeys[i.view].push(i);
        return;
      }

      viewsKeys[UNKNOWN_VIEW].push(i);
    });

    copy.forEach((_, i) => {
      let view = UNKNOWN_VIEW;
      if (repeater && Array.isArray(repeater.items) && repeater.items.length) {
        let repeaterItemConfig = null;

        if (repeater.type === 'repeat_last') {
          // if in mode "repeat_last" so for every item in the list on a position bigger than the
          // repeater config list will have the config from teh last position
          if (i > repeater.items.length - 1) {
            repeaterItemConfig = repeater.items[repeater.items.length - 1];
          } else {
            repeaterItemConfig = repeater.items[i];
          }
        } else if (repeater.type === 'repeat_last_n') {
          const n = repeater.n;
          if (n && !isNaN(n) && n <= repeater.items.length) {
            if (i >= repeater.items.length) {
              const offset = repeater.items.length - n;
              const pos = (i - repeater.items.length) % n;
              repeaterItemConfig = repeater.items[pos + offset];
            } else {
              repeaterItemConfig = repeater.items[i];
            }
          }
        } else {
          // is in mode "repeat_all", so for every item in the list on a position bigger than the
          // repeater config list will have the config from the position that will normally be when
          // repeating the entire list
          const pos = i % repeater.items.length;
          repeaterItemConfig = repeater.items[pos];
        }

        if (repeaterItemConfig && repeaterItemConfig.view) {
          view = repeaterItemConfig.view;
        } else {
          view = UNKNOWN_VIEW;
        }
      }

      if (viewsKeys[view] != null && viewsKeys[view].length > 0) {
        // get next item with the associated view
        const elem = viewsKeys[view].shift();
        elem.__old_view = elem.view;
        elem.view = view;
        res.push(elem);
      } else {
        let elem = null;
        // we don't have an element so take in the order of view priority (in this case priority is
        // given by the number of items in them; the more items a view has the less the priority)
        const found = viewsPrioritiesArr.find((v) => {
          if (viewsKeys[v] != null && viewsKeys[v].length > 0) {
            elem = viewsKeys[v].shift();
            return true;
          }
          return false;
        });

        if (!found) {
          let tmp = null;
          viewsPrioritiesArr.find((view) => {
            const index = viewsKeys[UNKNOWN_VIEW].findIndex((el: any) => el.view === view);
            if (index > -1) {
              tmp = viewsKeys[UNKNOWN_VIEW].splice(index, 1)[0];
              return true;
            }
            return false;
          });
          if (tmp) {
            elem = tmp;
          } else {
            elem = viewsKeys[UNKNOWN_VIEW].shift();
          }
        }

        if (elem) {
          elem.__old_view = elem.view;
          elem.view = view;
          res.push(elem);
        }
      }
    });

    return res;
  }
  return items;
};
