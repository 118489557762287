import * as actionTypes from './actionTypes';

export const scanDocument = (files) => {
  return {
    type: actionTypes.druid.SCAN_DOCUMENT,
    files: files,
  };
};

export const scanComplete = (data) => {
  return {
    type: actionTypes.druid.SCAN_COMPLETE,
    data: data,
  };
};
