const PasswordValidator = (
  password: string,
  config?: {
    minimum_length: number;
    maximum_length: number;
    medium_length: number;
    long_length: number;
    uppercase: boolean;
    lowercase: boolean;
    digit: boolean;
    special: boolean;
    letter: boolean;
    labels: any[];
  },
) => {
  if (!config) {
    config = window.config.password;
  }
  const { labels, minimum_length, maximum_length, uppercase, lowercase, digit, special, medium_length, long_length } =
    config;
  // score based on required fields from config
  let score = 0;
  // total score based on all possible fields from config
  let totalScore = 0;
  // maximum score represents the total number of possible requirements, not only the required ones
  const maxScoreLimit = Object.keys(config).length - 2; // ignore labels and maximum_length
  // list of conditions not met
  const conditionsNotMet = [];
  // ignore minimum_length and medium_length; this will decrease in the next lines of code
  let minimumScoreRequired = maxScoreLimit - 2;
  // check length
  if (password.length >= minimum_length && password.length <= maximum_length) {
    score++;
    totalScore++;
    if (password.length >= medium_length) {
      totalScore++;
    }
    if (password.length >= long_length) {
      totalScore++;
    }
  } else {
    conditionsNotMet.push('length');
  }

  // check uppercase
  if (!uppercase) {
    minimumScoreRequired--;
  }
  if (/[A-Z]/.test(password)) {
    totalScore++;
    if (uppercase) {
      score++;
    }
  } else {
    if (uppercase) {
      conditionsNotMet.push('uppercase');
    }
  }

  // check letter
  if (!config.letter) {
    minimumScoreRequired--;
  }
  if (/[A-Za-z]/.test(password)) {
    totalScore++;
    if (config.letter) {
      score++;
    }
  } else {
    if (config.letter) {
      conditionsNotMet.push('letter');
    }
  }

  // check lowercase
  if (!lowercase) {
    minimumScoreRequired--;
  }
  if (/[a-z]/.test(password)) {
    totalScore++;
    if (lowercase) {
      score++;
    }
  } else {
    if (lowercase) {
      conditionsNotMet.push('lowercase');
    }
  }

  // check digit
  if (!digit) {
    minimumScoreRequired--;
  }
  if (/[0-9]/.test(password)) {
    totalScore++;
    if (digit) {
      score++;
    }
  } else {
    if (digit) {
      conditionsNotMet.push('digit');
    }
  }

  // check special characters
  if (!special) {
    minimumScoreRequired--;
  }
  if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
    totalScore++;
    if (special) {
      score++;
    }
  } else {
    if (special) {
      conditionsNotMet.push('special');
    }
  }

  let finalScore = Math.floor((totalScore / maxScoreLimit) * 100);

  // minimum score should not be 0
  // this is to prevent the password strength meter from showing the CMS default values
  if (finalScore == 0) {
    finalScore = 0.001;
  }

  const passwordAccepted = score >= minimumScoreRequired;
  let shortLabel = labels?.[0]?.label ?? 'Labels not found.';
  labels.forEach((label) => {
    if (finalScore >= label.minimum_score) {
      shortLabel = label.label;
    }
  });
  const longLabel = `Password strength is ${shortLabel.toLowerCase()}.`; // used as placeholder for the message

  let message = 'Password must contain:';

  for (const cond of conditionsNotMet) {
    if (cond === 'uppercase') message += ' an uppercase letter,';
    if (cond === 'lowercase') message += ' a lowercase letter,';
    if (cond === 'letter') message += ' a letter,';
    if (cond === 'digit') message += ' a digit,';
    if (cond === 'special') message += ' a special character,';
    if (cond === 'length') message += ` ${minimum_length} to ${maximum_length} characters,`;
  }

  if (message.endsWith(',')) {
    message = message.slice(0, -1);
    message += '.';
  } else {
    message = longLabel;
  }

  return {
    score: finalScore,
    success: passwordAccepted,
    conditionsNotMet: conditionsNotMet,
    message: message,
    labels: {
      short: shortLabel,
      long: longLabel,
    },
  };
};

export default PasswordValidator;
