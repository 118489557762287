import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import ExpiringLocalStorage from '@/modules/casino/utils/ExpiringLocalStorage';
import { useMediaQuery } from '@/components/utils/useQueryMedia';

//import './index.scss';

type EuroPopupProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const EuroPopup = (componentProps: EuroPopupProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);
  const uriMatches = useMatches();
  const isDesktop = useMediaQuery('(min-width:900px)');

  const [state, setState] = React.useState({
    shown: localStorage.getItem('eps_2024') === 'true' ? true : false,
    showPopup: false,
  });

  React.useEffect(() => {
    if (window.config.euroLeagueEnabled === '1' && window.config.euroLeagueTutorialEnabled === '1') {
      document.body.classList.remove('euro-popup-open');
      const cookies = ExpiringLocalStorage.get('cookieAgreement');

      let pathName = '/';

      if (uriMatches && uriMatches.length) {
        const uriMatch = uriMatches[uriMatches.length - 1];
        pathName = uriMatch.pathname;
      }

      if (!isDesktop && pathName === '/' && cookies && localStorage.getItem('eps_2024') !== 'true') {
        setTimeout(() => {
          setState((v) => ({ ...v, shown: false, showPopup: true }));
          document.body.classList.add('euro-popup-open');
        }, 1000);
      } else if (pathName !== '/' || isDesktop) {
        setState((v) => {
          if (v.showPopup) {
            localStorage.setItem('eps_2024', 'true');
            return { ...v, shown: true, showPopup: false };
          }
          return { ...v, showPopup: false };
        });
      }
    }
  }, [uriMatches, isDesktop]);

  const onHidePopup = React.useCallback(() => {
    setState({ ...state, showPopup: false });
    localStorage.setItem('eps_2024', 'true');
    document.body.classList.remove('euro-popup-open');
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...state,
      onHidePopup,
    };
  }, [dataElementContext, componentProps, state, onHidePopup]);

  if (window.config.euroLeagueEnabled !== '1' || window.config.euroLeagueTutorialEnabled !== '1') return null;
  if (state.shown) return null;

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default EuroPopup;
