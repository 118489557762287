import React from 'react';
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from '@material-ui/core/CircularProgress';

import "./GratuitiesHourly.scss";
import WinnerCoinImg from "../../assets/winner-fun-logo.svg";
import { getWinnerFunState } from "../../store/selectors/winnerFunData";
import SimpleTimer from "../SimpleTimer/SimpleTimer";

import { winCoinsCollectHourly, winCoinsSystemsLoad } from "../../store/actions/wincoins";
import { chooseTextLanguage, styleToObj, choseImage } from "../../utils/functions";

const GratuitiesHourly = (props) => {
  const { t, i18n, walletTotal, className, onCollected, origData, winCoinsCollectHourly, winCoinsSystemsLoad } = props;

  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const imageType = isDesktop1024 ? "desktop" : "mobile";

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    if (origData) {
      setData(origData)
    }
  }, [origData]);

  const onCollect = () => {
    if (data.amount !== 0) {
      setLoading(true);
      (function (amount, walletTotal) {
        winCoinsCollectHourly({
          id: data.coin_system.id,
          cb: (success) => {
            if (success) {
              onCollected(amount, walletTotal);
            }
            setLoading(false);
          }
        });
      })(data.amount, walletTotal);
    }
  };

  if (!data || !data.coin_system) return null;
  const dateTime = moment(data.next_token_time, "x").format("YYYY-MM-DD HH:mm:ss");

  const handleExpired = () => {
    setTimeout(() => {
      winCoinsSystemsLoad();
    }, 1000);
  };


  let containers = {
    tokenOff: {},
    tokenOn: {},
    tokens: {},
    winCoins: {},
  };

  if (data && data.coin_system && data.coin_system.meta && data.coin_system.meta.containers) {
    const cs = data.coin_system.meta.containers;
    containers = {
      tokenOff: styleToObj(cs.tokenOff),
      tokenOn: styleToObj(cs.tokenOn),
      tokens: styleToObj(cs.tokens),
      winCoins: styleToObj(cs.winCoins),
    };
  }

  const dt = moment(data.next_token_time, "x");
  const dtNow = moment();
  let isExpired = false;

  if (dt.isBefore(dtNow) && data.coin_system.prizes.length === data.tokens) { // already expired
    isExpired = true;
  }

  let style = {};
  if (data.coin_system.meta.background) {
    style = styleToObj(data.coin_system.meta.background.style);
    style.backgroundImage = `url("${choseImage(data.coin_system.meta.background, imageType)}")`;
  }

  return (
    <div className={`wf-gratuities-hourly ${className ? className : ""}`}>
      <div className="wf-header">
        <div className="wf-icon" style={styleToObj(data.coin_system.meta.headerIcon.style)}>
          <img src={choseImage(data.coin_system.meta.headerIcon, imageType)} alt="header icon" />
        </div>
        <div className="wf-title" style={styleToObj(data.coin_system.meta.headerTitle.style)}>{chooseTextLanguage(data.coin_system.meta.headerTitle.text, i18n.language)}</div>
      </div>
      <div className="wf-body" style={style}>
        <div className="wf-first">
          <img src={choseImage(data.coin_system.meta.image, imageType)} style={styleToObj(data.coin_system.meta.image.style)} alt="collect" />

          <div className={`wf-wrapper ${isExpired ? 'wf-full' : ''}`} style={containers.winCoins}>
            <div className="wf-inner-wrapper">
              <img src={WinnerCoinImg} alt="winner coin" />
              <div className={"wf-text"}>
                +{data.amount} <span className="wf-subtext">WinCoins</span>
              </div>
            </div>
          </div>
          <div className={`wf-tokens ${isExpired ? 'wf-full' : ''}`} style={containers.tokens}>
            <div className="wf-inner-tokens">
              {[...Array(data.tokens).keys()].map((t) => (<div style={containers.tokenOn} className={`wf-token wf-token-${t}`} key={`tok-${t}`}></div>))}
              {[...Array(data.coin_system.prizes.length - data.tokens).keys()].map((t) => (<div style={containers.tokenOff} className="wf-token wf-disabled" key={`tok-${t}`}></div>))}
            </div>
          </div>
        </div>
        {!isExpired && <div className="wf-second">
          <div className="wf-timer-text">{t("NEXT IN")}:</div>
          <SimpleTimer date={dateTime} onExpired={handleExpired} render={
            (timer) => (<div className="wf-timer">
              <div>
                <div className={`wf-title ${!timer.hours.enabled ? "off" : ""}`}>{timer.hours.value}</div>
                <div className={`wf-subtitle`}>{t("HOURS")}</div>
              </div>
              <div>
                <div className={`wf-title ${!timer.mins.enabled ? "off" : ""}`}>{timer.mins.value}</div>
                <div className={`wf-subtitle`}>{t("MIN")}</div>
              </div>
              <div>
                <div className={`wf-title ${!timer.secs.enabled ? "off" : ""}`}>{timer.secs.value}</div>
                <div className={`wf-subtitle`}>{t("SEC")}</div>
              </div>
            </div>)
          } />

          <div className={`wf-collect ${loading ? 'wf-loading' : ''} ${data.amount ? 'wf-ready' : ''} `} onClick={onCollect} style={styleToObj(data.coin_system.meta.button.style)}>
            {chooseTextLanguage(data.coin_system.meta.button.text, i18n.language)}
            <div className={`wf-loader`}><CircularProgress /></div>
          </div>
        </div>}

        {isExpired && <div className="wf-second">
          <div className="wf-title">{t("The bag is full!")}</div>
          <div className="wf-text">{t("Empty the bag in time to make room for other automatically gathering WinCoins.")}</div>


          <div className={`wf-collect ${loading ? 'wf-loading' : ''} ${data.amount ? 'wf-ready' : ''} `} onClick={onCollect} style={styleToObj(data.coin_system.meta.button.style)}>
            {chooseTextLanguage(data.coin_system.meta.button.text, i18n.language)}
            <div className={`wf-loader`}><CircularProgress /></div>
          </div>
        </div>}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);
  return {
    walletTotal: wfs && wfs.wallet && wfs.wallet.total ? wfs.wallet.total : 0
  };
};

const mapActions = {
  winCoinsCollectHourly,
  winCoinsSystemsLoad
};

export default withTranslation()(connect(mapStateToProps, mapActions)(GratuitiesHourly));
