import { momentumConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  value: null,
  eligibleGames: [],
  source: '',
  momentumDown: false,
  loading: false,
  loaded: false,
};

const winterReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case momentumConstants.SET_MOMENTUM_SOURCE:
        draft.source = action.source;
        break;
      case momentumConstants.SET_MOMENTUM_BONUSES:
        draft.loading = false;
        draft.loaded = true;
        draft.value = action.data?.award ? action.data.award : {};
        draft.eligibleGames = action.data?.eligible_games ? action.data.eligible_games : {};
        if (action.data?.is_up != null) {
          draft.momentumDown = !action.data.is_up;
        }
        draft.source = action.source;
        if (action.data?.active) {
          draft.momentumDown = false;
        }
        break;
      case momentumConstants.UPDATE_MOMENTUM_STATUS:
        draft.momentumDown = action.down;
        break;
      case momentumConstants.GET_MOMENTUM_BONUSES:
        draft.loading = true;
        break;
      case momentumConstants.CLEAR_STATE:
        draft.loading = false;
        draft.loaded = action?.loaded != null ? action.loaded : false;
        draft.value = null;
        draft.source = '';
        break;
      case momentumConstants.UPDATE_MOMENTUM_BONUS:
        if (draft.value && action.data) {
          Object.keys(action.data).forEach((key) => {
            if (action.data[key]) {
              draft.value[key] = action.data[key];
            }
          });
        }
        if (action?.loaded != null) {
          draft.loaded = action.loaded;
        }
        draft.momentumDown = false;
        break;
      default:
      /* noop */
    }
  });


export default winterReducer;