import { put } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import * as paths from '../../ClientAPI/paths';
import Utils from '../../utils/Utils';
import RequestManager from '../../utils/RequestManager';
import PlayerAbuseChecker from '../../utils/PlayerAbuseChecker';
import { useAppSelector } from '../../../../store';
import { freeSpinsFetch } from '@/modules/casino/store/actions/free_spins';
import { freeBetsFetch } from '@/modules/casino/store/actions/free_bets';

export function* routeListenerSaga(action) {
  let authType = yield localStorage.getItem('auth_type');
  if (authType === 'user') {
    let authDate = yield localStorage.getItem('auth_date');
    let expiresIn = localStorage.getItem('expires_in');
    if (!expiresIn) {
      localStorage.setItem('redirect_after_login', action.pathname);
      yield put(actions.logout());
      window.location.pathname = '/connect';
    } else {
      let actualDate = yield Date.now();
      let timePassed = actualDate - authDate;
      if (timePassed > expiresIn * 1000) {
        localStorage.setItem('redirect_after_login', action.pathname);
        yield put(actions.logout());
        window.location.pathname = '/connect';
      }
    }
  }

  let pathname = action.pathname;
  let routePath = pathname.split('/');
  if (!(routePath.length && routePath.length > 1)) {
    return;
  }

  let pageType = 'root-level';
  let pageCode = routePath[1];
  if (routePath.length > 2) {
    pageCode = routePath[2];
    pageType = routePath[1];
  }

  if ('conectare' === pageCode) {
    pageType = 'authentication';
    pageCode = 'login';
  }

  if ('' === pageCode) {
    pageType = 'root-level';
    pageCode = 'home';
  }

  if ('cazino' === pageCode) {
    pageType = 'cazino';
    pageCode = 'jocuri';
  }

  let pageClassName = 'lighten';
  if ('home' === pageCode) {
    pageClassName = 'darken';
  }
  if ('jocuri' === pageCode) {
    pageClassName = 'white';
  }

  yield put(actions.setPageType(pageType));
  yield put(actions.setPageCode(pageCode));
  yield put(actions.setPageClassName(pageClassName));
}

export function* changeLanguageSaga(action) {
  const axios = ClientAPI.getInstance();

  try {
    const response = yield axios({
      url: paths.profile.CHANGE_LANGUAGE,
      method: 'post',
      data: {
        iso: action.language,
      },
    });

    if (!response.status) {
      throw new Error('[ERROR] CHANGE LANGUAGE missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] CHANGE LANGUAGE message not sent!');
    }
  } catch (error) {
    return;
  }
}

export function* getPromotionsSaga(action) {
  const axios = yield Utils.getAxiosWithToken();

  try {
    const response = yield axios({
      url: paths.promotions.GET,
    });

    if (!response.status) {
      throw new Error('[ERROR] GET_PROMOTIONS missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] GET_PROMOTIONS message not sent!');
    }

    yield put(actions.setPromotions(response.result));
  } catch (error) {
    return;
  }
}

function* callback_getEligibleBonuses(response, id) {
  try {
    if (!response.status) {
      throw new Error('[ERROR] GET_ELIGIBLE_BONUSES missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] GET_ELIGIBLE_BONUSES message not sent!');
    }

    yield put(actions.setEligibleBonuses(response.result));
  } catch (error) {
    yield put(actions.requestFailed(id));
  }
}

export function* getEligibleBonusesSaga(action) {
  const state = ClientAPI.getStore().getState();

  try {
    yield RequestManager.request(
      'get-eligible-bonuses',
      {
        url: paths.eligible_bonuses.GET,
        method: 'post',
        data: {
          platform: window.config.platformType,
          currencyCode: state.currencies.currentCurrency,
          paymentMethodList: [],
        },
      },
      callback_getEligibleBonuses,
    );
  } catch (error) {}
}

function* callback_getBonusAvailable(response, id) {
  try {
    if (!response.status) {
      throw new Error('[ERROR] GET_AVAILABLE_BONUSES missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] GET_AVAILABLE_BONUSES message not sent!');
    }

    yield put(actions.setAvailableBonuses(response.result));
  } catch (error) {
    yield put(actions.requestFailed(id));
  }
}

export function* getBonusAvailableSaga(action) {
  const state = ClientAPI.getStore().getState();

  try {
    const response = yield RequestManager.request(
      'get-bonus-available',
      {
        url: paths.bonus_available.GET,
        method: 'post',
        data: {
          platform: window.config.platformType,
          currencyCode: state.currencies.currentCurrency,
        },
      },
      callback_getBonusAvailable,
    );
  } catch (error) {
    return;
  }
}
function* callback_getBonuses(response, id) {
  try {
    if (!response.status) {
      throw new Error('[ERROR] GET_AVAILABLE_BONUSES missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] GET_AVAILABLE_BONUSES message not sent!');
    }

    yield put(actions.setBonuses(response.result));
  } catch (error) {
    yield put(actions.requestFailed(id));
  }
}
export function* getBonusesSaga(action) {
  const state = ClientAPI.getStore().getState();
  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }
  // const axios = yield Utils.getAxiosWithToken();
  try {
    const platformType = window.config.cordova
      ? window.config.native_platform === 'ios'
        ? 3
        : 4
      : window.config.platformType;
    let data = action.data;
    data.platform = platformType;
    data.currencyCode = state.currencies.currentCurrency;
    data.paymentMethodList = [];

    const response = yield RequestManager.request(
      'get-bonuses',
      {
        url: paths.eligible_bonuses.GET,
        method: 'post',
        data: data,
      },
      callback_getBonuses,
    );
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* getBonusCalculatorSaga(action) {
  const axios = yield Utils.getAxiosWithToken();

  try {
    let data = {
      bonusAvailableId: action.bonusId,
      currency: action.currency,
      depositAmount: action.depositAmount * 100,
    };

    const response = yield axios({
      url: paths.bonuses.GET_BONUS_CALCULATOR,
      method: 'post',
      data: data,
    });

    if (!response.status) {
      throw new Error('[ERROR] GET_AVAILABLE_BONUSES missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] GET_AVAILABLE_BONUSES message not sent!');
    }

    yield put(actions.setAwardBonus(action.bonusId, response.result));
  } catch (error) {
    return;
  }
}

export function* claimBonusSaga(action) {
  const axios = yield Utils.getAxiosWithToken();

  try {
    let data = {
      bonusAvailableId: action.bonusId,
    };
    if (action.force) {
      data.force = 1;
    }

    let pac = PlayerAbuseChecker.getInfo();
    data = {
      ...pac,
      ...data,
    };

    const response = yield axios({
      url: paths.bonus_available.CLAIM,
      method: 'post',
      data: data,
    });

    if (!response.status) {
      throw new Error('[ERROR] GET_AVAILABLE_BONUSES missing status!');
    }

    if (!('OK' === response.status)) {
      throw new Error('[ERROR] GET_AVAILABLE_BONUSES message not sent!');
    }

    if (
      (typeof response.result.ResponseCode !== 'undefined' && response.result.ResponseCode !== 6) ||
      typeof response.result.http !== 'undefined'
    ) {
      if (typeof response.result.detail === 'undefined' || response.result.detail === null) {
        throw new Error('[ERROR] GET_AVAILABLE_BONUSES server error');
      }
    }

    yield put(actions.bonusClaimed(response.result));
    yield put(actions.getBonusAvailable());
    yield put(actions.requestWallet());
    yield put(freeBetsFetch());
    yield put(freeSpinsFetch());
  } catch (error) {
    yield put(actions.bonusClaimed({ ResponseCode: 1 }));
    return;
  }
}
