import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import {
  momentumProgressDetails,
  starThresholds,
  triggerGTMEvent,
} from '../../../../modules/momentum/utils/functions.js';
import { getMomentumBonuses } from '../../../../modules/momentum/store/actions/momentum';
import { formatCurrency } from '@/modules/bets/utils/formatters';

type MomentumLobbyProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);
const emptyArray: any = [];

const MomentumLobby = (componentProps: MomentumLobbyProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const momentumPrize = useAppSelector<any>((state) => state.momentum.prize?.value);
  const momentumEligibleGames = useAppSelector<any>((state) =>
    state.momentum?.prize?.eligibleGames ? state.momentum.prize.eligibleGames : emptyArray,
  );
  const momentumPrizeLoaded = useAppSelector<any>((state) => state.momentum.prize?.loaded);
  const authentication = useAppSelector((state) => state.authentication);

  const isExpired = (momentumPrize: any) => {
    let prizeExpired = false;
    if (momentumPrize?.bonus_expires_at) {
      const now = new Date().valueOf();
      const prizeExpiresAt = new Date(momentumPrize?.bonus_expires_at).valueOf();
      if (now - prizeExpiresAt > 0) prizeExpired = true;
    }
    return prizeExpired;
  };

  const [expired, setExpired] = React.useState(isExpired(momentumPrize));

  React.useEffect(() => {
    if (!momentumPrizeLoaded && ['user', 'token'].indexOf(authentication.auth_type) > -1) {
      dispatch(getMomentumBonuses());
    }
  }, [momentumPrizeLoaded, authentication]);

  React.useEffect(() => {
    setExpired(isExpired(momentumPrize));
  }, [momentumPrize]);

  const handleExpired = () => {
    setExpired(true);
  };

  const onContinue = () => {
    if (momentumEligibleGames.length > 0) {
      const game: any = localStorage.getItem('momentumGame');

      if (game) {
        // eslint-disable-next-line
        let [gameId, name] = game.split('|');
        gameId = parseInt(gameId, 10);
        if (!isNaN(gameId) && momentumEligibleGames.includes(gameId)) {
          navigate(`/slot-game/${gameId}/${name}`);

          triggerGTMEvent({ event: 'tw-continue' });
          return;
        }
      }

      const randomGame = momentumEligibleGames[Math.floor(Math.random() * momentumEligibleGames.length)];
      if (randomGame) {
        navigate(`/slot-game/${randomGame}/game`);
      }
    }
    triggerGTMEvent({ event: 'tw-continue' });
  };

  if (
    window.config.momentumEnabled !== '1' ||
    !momentumPrizeLoaded ||
    !momentumPrize ||
    !momentumPrize?.active ||
    expired
  ) {
    return null;
  }

  const toFixedWithoutZeros = (num: number, precision: number) => `${Number.parseFloat(num.toFixed(precision))}`;

  const starProgress = momentumProgressDetails(momentumPrize?.wager_progress ?? 0, starThresholds);

  const stars = [...Array(starThresholds.length).keys()].map((index) => {
    return {
      active: index < starProgress.stars,
    };
  });

  const value = toFixedWithoutZeros((momentumPrize?.reward?.value || 0) / 100, 2);
  const currency = formatCurrency(momentumPrize?.reward?.currency || '');
  const progress = starProgress?.progress ?? 0;

  const contextValue = {
    momentumPrize,
    handleExpired,
    onContinue,
    value,
    currency,
    progress,
    stars,
  };

  //console.log('MomentumLobby[contextValue]: ', contextValue);

  if (window.config.momentumEnabled !== '1') return null;

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default MomentumLobby;
