import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { list } from 'postcss';

type PaginationProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Pagination = (componentProps: PaginationProps) => {
  let props = componentProps;
  let isVisible = true;

  const dataElementContext = React.useContext(DataElementContext);
  [props, isVisible] = processComponentProps(props, dataElementContext);

  const defaultIncrement = 3;
  let increment = parseInt(props.properties?.increment ?? defaultIncrement);
  increment = isNaN(increment) ? defaultIncrement : increment;

  const defaultVisible =
    props.properties.defaultVisible && !isNaN(parseInt(props.properties.defaultVisible))
      ? parseInt(props.properties.defaultVisible)
      : increment;

  //console.log('Pagination[props]', props);

  const [state, setState] = React.useState({
    increment: increment,
    showing: props.properties.list?.length
      ? Math.min(increment, props.properties.list?.length ?? 1000)
      : defaultVisible,
    total: props.properties.list?.length ?? 0,

    page: 0,
    perPage: increment,
    totalPages: Math.ceil(props.properties.list?.length / increment),
  });

  React.useEffect(() => {
    if (state.total !== props.properties.list?.length) {
      setState((prev) => ({
        ...prev,
        total: props.properties.list?.length,

        page: 0,
        perPage: prev.increment,
        totalPages: Math.ceil(props.properties.list?.length / prev.increment),
      }));
    }
  }, [state.total, props.properties.list]);

  React.useEffect(() => {
    if (state.increment !== props.properties.increment) {
      setState((prev) => {
        let increment = parseInt(props.properties?.increment ?? defaultIncrement);
        increment = isNaN(increment) ? defaultIncrement : increment;

        return {
          ...prev,
          increment: increment,
          showing: increment,
        };
      });
    }
  }, [state.increment, props.properties.increment]);

  const onShowMore = React.useCallback(() => {
    setState((prev) => {
      let showing = prev.showing + prev.increment;
      if (showing > prev.total) {
        showing = prev.total;
      }

      return { ...prev, showing: showing };
    });
  }, []);

  const onSetPage = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.page) {
      const page = e.currentTarget.dataset.page;
      const parsedPage = parseInt(e.currentTarget.dataset.page);
      setState((prev: any) => {
        if (page === 'next') {
          let nextPage = prev.page + 1;
          if (nextPage > prev.totalPages) {
            nextPage = prev.totalPages;
          }
          return { ...prev, page: nextPage };
        } else if (page === 'prev') {
          let prevPage = prev.page - 1;
          if (prevPage < 0) {
            prevPage = 0;
          }
          return { ...prev, page: prevPage };
        }

        if (parsedPage >= 0 && parsedPage < prev.totalPages) {
          return { ...prev, page: parsedPage };
        }
      });
    }
  }, []);

  const contextValue = React.useMemo(() => {
    const data: any = {
      showing: state.showing,
      total: state.total,
      list: props.properties.list.slice(0, state.showing),
      onShowMore,
      parentContext: dataElementContext,
    };

    const usePagination = props.properties.usePagePagination;
    if (usePagination) {
      data['page'] = state.page;
      data['perPage'] = state.perPage;
      data['totalPages'] = state.totalPages;
      data['onSetPage'] = onSetPage;
      data.list = props.properties.list.slice(state.page * state.perPage, state.page * state.perPage + state.perPage);
    }

    return data;
  }, [state, props.properties.list, onShowMore, onSetPage, dataElementContext, props.properties.usePagePagination]);

  //console.log('Pagination[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Pagination;
