import { authConstants } from './constants';

export const authInitialize = () => ({
  type: authConstants.INITIALIZE,
});

export const authSuccess = (token) => ({
  type: authConstants.SUCCESS,
  token,
});

export const authClear = () => ({
  type: authConstants.CLEAR,
});

export const authEndCycle = () => ({
  type: authConstants.END_CYCLE,
});

export const lottoPlayerLogin = (token) => ({
  type: authConstants.PLAYER_LOGIN,
  token,
});

export const lottoPlayerLogout = () => ({
  type: authConstants.PLAYER_LOGOUT,
});
