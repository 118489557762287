import { appConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {};

const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case appConstants.INITIALIZE:
        break;
      default:
        break;
    }
  });

export default appReducer;
