import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { requestGameRules } from '@/modules/casino/store/actions';
import i18next from 'i18next';

type GameRulesProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const GAME_RULE_TYPE_LIVE_CASINO = 'liveCasino';
const GAME_RULE_TYPE_BETS = 'bets';
const GAME_RULE_TYPE_VIRTUALS = 'virtuals';
const GAME_RULE_TYPE_SLOTS = 'slots';

const CDN_STORAGE_URL = window.location.protocol + '//' + window.config.lobbyCDNAddress;

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const GameRules = (componentProps: GameRulesProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const dispatch = useAppDispatch();

  const customerSupport = useAppSelector((state) => state.customerSupport);

  React.useEffect(() => {
    dispatch(requestGameRules());
  }, []);

  const [layout, setLayout] = React.useState('slots');
  const [filter, setFilter] = React.useState('');

  const onChangeLayout = (event: any) => {
    if (event?.currentTarget?.dataset?.['layoutId']) {
      setLayout(event.currentTarget.dataset['layoutId']);
    }
  };
  const onChangeFilter = (event: any) => {
    setFilter(event?.target?.value);
  };

  const getGameRules = (type: string, filter: string | null) => {
    if (customerSupport && (!customerSupport.gameRulesLoading || !i18next.language)) {
      if (customerSupport.groupedRules) {
        const groupedDocuments: any = customerSupport.groupedRules;
        let stateGroups: any = null;

        const lang = i18next.language;
        if (groupedDocuments[type]) {
          if (groupedDocuments[type][lang]) {
            stateGroups = groupedDocuments[type][lang];
            if (stateGroups[0]) {
              delete Object.assign(stateGroups, { ['#']: stateGroups[0] })[0];
            }
          }
        }

        if (!stateGroups) {
          return null;
        }

        const ret: any = {};
        const groupsKey = Object.keys(stateGroups);

        groupsKey.forEach((groupKey) => {
          const stateGroup = stateGroups[groupKey];
          const items: any = [];
          stateGroup.forEach((item: any, index: number) => {
            const newItem: any = { ...item };

            newItem.url = CDN_STORAGE_URL + '/' + item?.path;
            newItem.group = item?.group === 0 ? '#' : item?.group.toUpperCase();
            if (filter) {
              if (item.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
                items.push(newItem);
              }
            } else {
              items.push(newItem);
            }
          });

          if (items.length) {
            ret[groupKey] = items;
          }
        });

        return ret;
      }
    }
  };

  const contextValue = React.useMemo(() => {
    const data: any = {
      liveCasino: null,
      bets: null,
      virtuals: null,
      slots: null,
    };
    if (window?.config?.liveCasinoEnabled === '1') {
      data.liveCasino = getGameRules(GAME_RULE_TYPE_LIVE_CASINO, filter);
    }
    if (window?.config?.betsEnabled === '1' || window?.config?.nsoftEnabled === '1') {
      data.bets = getGameRules(GAME_RULE_TYPE_BETS, filter);
    }
    if (window?.config?.virtualsEnabled === '1') {
      data.virtuals = getGameRules(GAME_RULE_TYPE_VIRTUALS, filter);
    }
    data.slots = getGameRules(GAME_RULE_TYPE_SLOTS, filter);

    return {
      gameRules: data,
      loading: customerSupport.gameRulesLoading,
      layout: layout,
      filter: filter,
      onChangeLayout: onChangeLayout,
      onChangeFilter: onChangeFilter,
    };
  }, [getGameRules, onChangeLayout, onChangeFilter, layout, filter]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default GameRules;
