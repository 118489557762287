import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { expiredLottoEvents } from '../../../../modules/lotto/store/actions/lotto-events';
import images from '../../../../modules/lotto/assets/countries';

import './index.scss';

type LottoEventsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LottoEventsPromoted = (componentProps: LottoEventsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const lottoEvents = useAppSelector((state) => state.lotto.lottoEvents.items);
  const homepageLottoPromoted = useAppSelector((state) => state.lotto.config.homepageLottoPromoted);
  const openedTickets = useAppSelector((state) => state.lotto.tickets.ticketsOpened);
  const favNumbers = useAppSelector((state) => state.lotto.favNumbers.items);

  const [count, setCount] = React.useState(0);
  const [selectedFavorites, setSelectedFavorites] = React.useState<any>(null);

  React.useEffect(() => {
    const tid = window.setInterval(() => {
      setCount((c) => c + 1);
    }, 1000);

    return () => {
      clearTimeout(tid);
    };
  }, [lottoEvents]);

  const onToggleFavorites = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (e?.currentTarget?.dataset?.event_id) {
      const event_id = e.currentTarget.dataset.event_id;
      setSelectedFavorites(event_id);
    } else {
      setSelectedFavorites(null);
    }
  }, []);

  const onSelectFavorites = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (e?.currentTarget?.dataset?.event_id && e?.currentTarget?.dataset?.index != null) {
      const event_id = e.currentTarget.dataset.event_id;
      const index = e.currentTarget.dataset.index;
      // TODO: add numbers to betslip
    }
  }, []);

  const contextValue = React.useMemo(() => {
    const eventsFromTickets: any = {};
    const now = Date.now();
    const tickets = openedTickets.filter((t) => Math.floor(t.event_time / 1e6) > now);
    tickets.forEach((t) => {
      eventsFromTickets[t.event_name] = true;
    });

    let promoted: any[] = [];
    lottoEvents.forEach((e) => {
      if (typeof eventsFromTickets[e.event_name] !== 'undefined') {
        promoted.push(e);
        delete eventsFromTickets[e.event_name];
      }
    });

    if (homepageLottoPromoted && homepageLottoPromoted.length) {
      const filtered = lottoEvents.filter((e) => {
        if (homepageLottoPromoted.indexOf(e.event_name) > -1) {
          let exists = false;
          promoted.forEach((p) => {
            if (p.event_name === e.event_name) {
              exists = true;
            }
          });
          if (!exists) {
            promoted.push(e);
          }
        }
        return false;
      });
      if (filtered && filtered.length) {
        promoted = [...promoted, ...filtered];
      }
    }

    if (promoted.length < 3) {
      const counts: any = {};

      lottoEvents.forEach((e) => {
        if (typeof counts[e.event_name] === 'undefined') counts[e.event_name] = 0;
        counts[e.event_name] += 1;
      });

      const keys = Object.keys(counts);
      keys.sort(function (a, b) {
        return counts[b] - counts[a];
      });

      const promLength = promoted.length;

      for (let i = 0; i < 4 - promLength; i++) {
        const event_name = keys[i];
        const exists = promoted.filter((e) => e.event_name === event_name); // check if already promoted
        if (!exists.length) {
          const evs = lottoEvents.filter((e) => e.event_name === event_name);
          if (evs.length) promoted.push(evs[0]);
        }
      }
    }

    promoted = JSON.parse(JSON.stringify(promoted));
    promoted.find((event, i) => {
      const dt = moment(event.event_date, 'YYYY-MM-DD HH:mm:ss');

      const dtNow = moment();

      const hours = dt.diff(dtNow, 'hours');
      const mins = dt.diff(dtNow, 'minutes') - hours * 60;
      const secs = dt.diff(dtNow, 'seconds') - mins * 60;

      if (hours < 0 || mins < 0 || secs < 0) {
        event.counter = {
          hours: '00',
          mins: '00',
          secs: '00',
          time: dt.format('D MMM HH:mm'),
          moreThan1Hour: false,
        };
      } else {
        event.counter = {
          hours: hours < 10 ? `0${hours}` : hours,
          mins: mins < 10 ? `0${mins}` : mins,
          secs: secs < 10 ? `0${secs}` : secs,
          time: dt.format('D MMM HH:mm'),
          moreThan1Hour: secs >= 60,
        };
      }

      // @ts-ignore
      if (images[event.iso2] || images[event.iso2.toLowerCase()]) {
        // @ts-ignore
        event.country_flag = images[event.iso2] || images[event.iso2.toLowerCase()];
      }

      return false;
    });

    let selectedEvent = null;
    if (selectedFavorites) {
      const tmp = promoted.find((e) => e.event_id === selectedFavorites);
      if (tmp) {
        selectedEvent = tmp;
      }
    }

    return {
      list: promoted,
      listTotal: promoted.length,
      favoritesNumbers: favNumbers,
      selectedEvent,
      onToggleFavorites,
    };
  }, [lottoEvents, homepageLottoPromoted, openedTickets, favNumbers, selectedFavorites, onToggleFavorites, count]);

  //console.log('LottoEventsPromoted[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LottoEventsPromoted;
