import React from 'react';
import styled from 'styled-components';

const defaultProps = {
  className: '',
  styleText: `
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin:0;
  margin-left: 2px;
  width: 100%;
  min-width: 137px;
  max-width: 216px;
  height: auto;
  aspect-ratio: 1/ 1.46;
  `,
};

const StateGridItemDiv = styled.div`
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  ${(props) => props.$styleText}
`;

export const StateGridItem = (componentProps) => {
  return (
    <StateGridItemDiv
      className={componentProps.className ?? ''}
      $styleText={componentProps.styleText != null ? componentProps.styleText : defaultProps.styleText}
      style={componentProps.style}
    >
      {componentProps.children}
    </StateGridItemDiv>
  );
};
