import { statScoreLiveAPI, statScorePreMatchAPI } from './config/statScore';
import { digitainConfig } from './config/digitain';

const digitainConfigValues = digitainConfig();

export const statScoreLiveURL = (idMatch) => {
  return `${statScoreLiveAPI}${idMatch}`;
};

export const statScorePreMatchURL = (idMatch) => {
  return `${statScorePreMatchAPI}${idMatch}`;
};

export const digitainWidgetLiveUrl = (idMatch, language, hasScout, hasLiveTV, viewType) => {
  //console.log("digitainWidgetLiveUrl", idMatch, language, hasScout, hasLiveTV);

  let url = '';
  if (hasScout && viewType === 1) {
    url = digitainConfigValues.widgetUrl.replace('[MATCHID]', idMatch);
    url = url.replace('[LANGUAGE]', language);
  } else if (hasLiveTV && viewType === 2) {
    url = digitainConfigValues.widgetLiveTvUrl.replace('[MATCHID]', idMatch);
    url = url.replace('[LANGUAGE]', language);
  }
  return url;
};

export const digitainWidgetCheckLiveUrl = (idMatch) => {
  return digitainConfigValues.widgetCheckUrl.replace('[MATCHID]', idMatch);
};
