interface ProductType {
  [key: string]: number;
}

interface ProductTypeNames {
  [key: number]: string;
}

export const ProductType: ProductType = {
  casino: 1,
  sport: 2,
  lotto: 3,
  live_casino: 4,
};

export const ProductTypeNames: ProductTypeNames = {
  [ProductType.casino]: 'Casino',
  [ProductType.sport]: 'Sport',
  [ProductType.lotto]: 'Lotto',
  [ProductType.live_casino]: 'Live Casino',
};
