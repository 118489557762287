import * as actionTypes from './actionTypes';
import ClientAPI from '../../ClientAPI/ClientAPI';

export const requestGamesList = (listType) => {
  let loading = ClientAPI.getStore().getState().games.loadingGamesList;

  if (loading) return { type: 'EMPTY' };
  return {
    type: actionTypes.games.REQUEST_GAMES_LIST,
    listType: listType,
  };
};

export const receivedGamesList = (games, listType) => {
  return {
    type: actionTypes.games.RECEIVED_GAMES_LIST,
    listType: listType,
    games: games,
  };
};

export const showGamesLoadingProgress = () => {
  return {
    type: actionTypes.games.SHOW_LOADING,
  };
};

export const hideGamesLoadingProgress = () => {
  return {
    type: actionTypes.games.HIDE_LOADING,
  };
};

export const setGamesListType = (listType) => {
  return {
    type: actionTypes.games.SET_LIST_TYPE,
    listType: listType,
  };
};

export const openGameLauncher = (gameId) => {
  return {
    type: actionTypes.games.OPEN_GAME_LAUNCHER,
    gameId: gameId,
  };
};

export const setGameOpened = (gameObject) => {
  return {
    type: actionTypes.games.SET_GAME_OBJECT,
    value: gameObject,
  };
};

export const setGameLauncher = (open = false) => {
  return {
    type: actionTypes.games.SET_GAME_LAUNCHER,
    gameLauncher: open,
  };
};

export const setGameId = (id = null) => {
  return {
    type: actionTypes.games.SET_GAME_ID,
    gameId: id,
  };
};

export const requestGameUrl = (id, provider, extra) => {
  return {
    type: actionTypes.games.REQUEST_GAME_URL,
    gameId: id,
    gameProvider: provider,
    extra: extra,
  };
};

export const setGameUrl = (url = null) => {
  return {
    type: actionTypes.games.RECEIVED_GAME_URL,
    gameURL: url === 'error' ? false : url,
    gameLaunchError: url === 'error',
  };
};

export const closeGameLauncher = () => {
  return {
    type: actionTypes.games.CLOSE_GAME_LAUNCHER,
  };
};
export const reloadWalletsData = () => {
  return {
    type: actionTypes.games.CLOSE_GAME_LAUNCHER,
  };
};

export const closeAndRedirectGL = (callback) => {
  return {
    type: actionTypes.games.CLOSE_REDIRECT_GAME_LAUNCHER,
    callback: callback,
  };
};

export const openGame = () => {
  return () => {
    /**/
  };
};
export const requestGameCollections = () => {
  let loading = ClientAPI.getStore().getState().games.loadingGameCollections;
  let games = ClientAPI.getStore().getState().games.gameCollections;

  if (loading || games) return { type: 'EMPTY' };

  return {
    type: actionTypes.games.REQUEST_GAME_COLLECTIONS,
  };
};

export const receivedGameCollections = (data) => {
  return {
    type: actionTypes.games.RECEIVED_GAME_COLLECTIONS,
    data: data,
  };
};


export const setGameIsRunning = (isRunning) => {
  return {
    type: actionTypes.games.SET_GAME_IS_RUNNING,
    isRunning: isRunning,
  };
};

