import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import { expiredLottoEvents } from '../../../store/actions/lotto-events';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  topTitle: {
    fontSize: '18px',
    fontWeight: '600',
    margin: '0 4px',
    textAlign: 'center',
  },
  topSubtitle: {
    fontSize: '9px',
    margin: '0 4px',
    textAlign: 'center',
  },
  topTitleDetails: {
    fontSize: '21px',
    color: '#121934',
    fontWeight: '600',
    margin: '0 4px',
    textAlign: 'center',
  },
  topSubtitleDetails: {
    fontSize: '10px',
    margin: '0 4px',
    color: '#78809F',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  topTitleHome: {
    fontSize: '18px',
    color: '#213047',
    fontWeight: '600',
    margin: '0 4px',
    textAlign: 'center',
  },
  topSubtitleHome: {
    fontSize: '9px',
    margin: '0 4px',
    color: '#686868',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  timer: {
    display: 'flex',
    flexDirection: 'row',
  },
  progress: {
    color: '#F2B604',
  },
});

const TimerExtended = (props) => {
  const classes = useStyles();
  const { date, t, className, details, home, timeBetween, expiredEvents } = props;

  const [expired, setExpired] = React.useState(false);
  const [state, setState] = React.useState({
    hours: '--',
    mins: '--',
    secs: '--',
  });

  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    let mounted = true;
    let intervalID = 0;

    if (mounted) {
      const calcTime = () => {
        const dt = moment(date, 'YYYY-MM-DD HH:mm:ss');
        const dtNow = moment();

        const hours = dt.diff(dtNow, 'hours');
        const mins = dt.diff(dtNow, 'minutes') - hours * 60;
        const secs = dt.diff(dtNow, 'seconds') - mins * 60;

        const newData = {
          hours: ('0' + hours).slice(-2),
          mins: ('0' + mins).slice(-2),
          secs: ('0' + secs).slice(-2),
        };

        //console.log(`${newData.hours}:${newData.mins}:${newData.secs}`);

        if ((hours === 0 && mins === 0 && secs === 0) || hours < 0 || mins < 0 || secs < 0) {
          setExpired(dt.format('HH:mm'));
          //console.log("document.hidden", document.hidden);
          if (document.hidden) {
            setExpired(false);
          } else {
            let delay = 90 * 1000;
            if (timeBetween > 6 * 60) {
              delay = 30 * 60 * 1000;
            }

            setTimeout(() => {
              if (mounted.current) {
                setExpired(false);
              }
            }, delay);
          }
          clearInterval(intervalID);
          expiredEvents();
          return;
        }

        setState(newData);
      };

      clearInterval(intervalID);
      intervalID = setInterval(() => {
        calcTime();
      }, 1000);
      calcTime();
    }

    return () => {
      mounted = false;
      clearInterval(intervalID);
    };
  }, [date]); // eslint-disable-line

  if (!date) return null;

  let root = classes.root;
  let topTitle = classes.topTitle;
  let topSubtitle = classes.topSubtitle;
  if (details) {
    topTitle = classes.topTitleDetails;
    topSubtitle = classes.topSubtitleDetails;
  } else if (home) {
    topTitle = classes.topTitleHome;
    topSubtitle = classes.topSubtitleHome;
  }

  return (
    <div className={`${root} ${className ? className : ''}`}>
      {!expired && (
        <React.Fragment>
          <div className={'drawing-text'}>{t('Next draw:')}</div>
          <div className={classes.timer}>
            <div>
              <div className={`${topTitle} title ${state.hours === '00' ? 'off' : ''}`}>{state.hours}</div>
              <div className={`${topSubtitle} subtitle`}>{t('HOURS')}</div>
            </div>
            <div>
              <div className={`${topTitle} title ${state.hours === '00' && state.mins === '00' ? 'off' : ''}`}>
                :{state.mins}
              </div>
              <div className={`${topSubtitle} subtitle`}>{t('MIN')}</div>
            </div>
            <div>
              <div
                className={`${topTitle} title ${state.hours === '00' && state.mins === '00' && state.secs === '00' ? 'off' : ''}`}
              >
                :{state.secs}
              </div>
              <div className={`${topSubtitle} subtitle`}>{t('SEC')}</div>
            </div>
          </div>
        </React.Fragment>
      )}
      {expired && (
        <React.Fragment>
          <div className={`${classes.topTitle} title-drawing`}>
            {t('Drawing')} {expired}
          </div>
          <CircularProgress variant="indeterminate" size="20px" className={`${classes.progress}`} />
        </React.Fragment>
      )}
    </div>
  );
};

const actions = {
  expiredEvents: expiredLottoEvents,
};

export default withTranslation()(connect(null, actions)(TimerExtended));
