// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';
import { fastEqual } from '../../utils/fastDeepEqual';

export interface ConfigReducer {
  bonuses: any[];
  main: number;
  bonus: number;
  total: number;
  ringFence: number;
  currency: string;
  cardBonus: any;
  loadingWallet: boolean;
  loadingRemoveBonus: boolean;
  bonusRemoved: any;
  walletManagement: any[];
  totalBonusPerProducts: any;
}

export interface MultiCurrencyWalletReducer {
  [currency: string]: ConfigReducer;
}

const initialWallet: ConfigReducer = {
  bonuses: [],
  main: 0,
  bonus: 0,
  total: 0,
  ringFence: 0,
  currency: '',
  cardBonus: null,
  loadingWallet: false,
  loadingRemoveBonus: false,
  bonusRemoved: null,
  walletManagement: [],
  totalBonusPerProducts: {},
};

const initialState: MultiCurrencyWalletReducer = {};

window.config.currencies.forEach((currency: string) => {
  initialState[currency] = { ...initialWallet, currency: currency };
});

export const multiCurrencyWalletSlice = createSlice({
  name: 'multiCurrencyWallet',
  initialState: { ...initialState },
  reducers: {
    receivedWallet: (state, action) => {
      // const rawWallet = { ...action.payload.wallet };
      const rawWallet = JSON.parse(JSON.stringify(action.payload.wallet));

      for (const walletCurrency of Object.keys(initialState)) {
        if (rawWallet[walletCurrency] == null) {
          rawWallet[walletCurrency] = { main: 0 };
        }

        const wallet = rawWallet[walletCurrency];

        let cardBonus = null;
        let main = 0.0;
        let total = main;
        let bonus = main;
        let ringFence = main;
        const walletManagement: any[] = [];

        if (typeof wallet['main'] !== 'undefined') {
          main += parseFloat(wallet.main);
          total += main;
        }

        let bonuses = [];
        const totalBonusPerProducts = {};
        if (typeof wallet['bonus'] !== 'undefined' && wallet.bonus) {
          bonuses = wallet.bonus;

          wallet.bonus.forEach((bonusRow: any) => {
            const bAmount: any = bonusRow.amount ? bonusRow.amount : '0';
            if (!(1 === Number.parseInt(bonusRow.status, 10))) {
              return;
            }

            if (1 === Number.parseInt(bonusRow.type, 10)) {
              cardBonus = bonusRow;
            }
            if (typeof bonusRow['eligibleProducts'] !== 'undefined' && bonusRow.eligibleProducts) {
              bonusRow.eligibleProducts.forEach((eligibleProduct: any) => {
                let bonusAmount = bAmount;
                if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].bonusAmount) {
                  bonusAmount = parseFloat(walletManagement[eligibleProduct].bonusAmount) + parseFloat(bAmount);
                }

                let bProductAmount: any = 0;

                if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                  bProductAmount = parseFloat(walletManagement[eligibleProduct].productAmount) + parseFloat(bAmount);
                } else {
                  bProductAmount = bonusAmount;
                }
                walletManagement[eligibleProduct] = {
                  ...walletManagement[eligibleProduct],
                  bonusAmount: Number(parseFloat(bonusAmount).toFixed(2)),
                  productAmount: Number(parseFloat(bProductAmount).toFixed(2)),
                };
              });
            }
            bonus += Number.parseFloat(bAmount);
          });

          total += bonus;
        }

        let ringFences: any[] = [];
        if (typeof wallet['ringFence'] !== 'undefined' && wallet['ringFence']) {
          ringFences = wallet.ringFence;

          wallet['ringFence'].forEach((ringFenceRow: any) => {
            const rfAmount = ringFenceRow.amount ? ringFenceRow.amount : '0';
            if (!(1 === Number.parseInt(ringFenceRow.status, 10))) {
              return;
            }

            if (typeof ringFenceRow['eligibleProducts'] !== 'undefined' && ringFenceRow.eligibleProducts) {
              ringFenceRow.eligibleProducts.forEach((eligibleProduct: any) => {
                let ringFenceAmount = rfAmount;
                if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].ringFenceAmount) {
                  ringFenceAmount =
                    parseFloat(walletManagement[eligibleProduct].ringFenceAmount) + parseFloat(rfAmount);
                }

                let rfProductAmount = ringFenceAmount;
                if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
                  rfProductAmount = parseFloat(walletManagement[eligibleProduct].productAmount) + parseFloat(rfAmount);
                }

                walletManagement[eligibleProduct] = {
                  ...walletManagement[eligibleProduct],
                  ringFenceAmount: Number(parseFloat(ringFenceAmount).toFixed(2)),
                  productAmount: Number(parseFloat(rfProductAmount).toFixed(2)),
                };
              });
            }

            ringFence += Number.parseFloat(rfAmount);
          });

          total += ringFence;
        }

        Object.keys(walletManagement).forEach((eligibleProduct: any) => {
          if (walletManagement[eligibleProduct] && walletManagement[eligibleProduct].productAmount) {
            walletManagement[eligibleProduct].productAmount += main;
          }
        });

        if (bonuses.length > 0 && ringFences.length > 0) {
          bonuses.forEach((bonus: any) => {
            ringFences.forEach((ringFence) => {
              if (ringFence.id === bonus.id) {
                bonus.ringFenceAmount = ringFence.amount;
              }
            });
          });
        }

        state[walletCurrency].main = main;
        state[walletCurrency].bonus = bonus;
        state[walletCurrency].total = total;
        state[walletCurrency].ringFence = ringFence;
        state[walletCurrency].walletManagement = walletManagement;
        state[walletCurrency].cardBonus = cardBonus;
        state[walletCurrency].loadingWallet = false;
        state[walletCurrency].totalBonusPerProducts = totalBonusPerProducts;

        if (!fastEqual(state[walletCurrency].bonuses, bonuses)) {
          // check if they're not the same
          state[walletCurrency].bonuses = bonuses;
        }
      }
    },
    // requestWallet: (state, action) => {
    //   state.currentWallet = action.payload.wallet;
    // },
  },
});

export const { receivedWallet } = multiCurrencyWalletSlice.actions;

export default multiCurrencyWalletSlice.reducer;
