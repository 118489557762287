import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

type SimpleSwitchProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const SimpleSwitch = (componentProps: SimpleSwitchProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const [simpleSwitch, setSimpleSwitch] = React.useState(
    props.properties?.useInitialValue ? props.properties?.initialValue : false,
  );

  const onToggle = () => {
    setSimpleSwitch(!simpleSwitch);
  };

  const contextValue = {
    onToggle: onToggle,
    simpleSwitch: simpleSwitch,
  };

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default SimpleSwitch;
