import React, { useContext } from 'react';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

type ModuleStateProps = {
  scriptLoaded: boolean;
  mapLoaded: boolean;
};

const apiKey = 'AIzaSyAWizasp8Yz9PrRNlnjzOeUTAT6QNpFk60';
export const GoogleMap = (componentProps: any) => {
  let props = componentProps;

  const initialState = {
    mapLoaded: false,
    scriptLoaded: false,
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  React.useEffect(() => {
    // inject Google Maps script if not already loaded
    if (!document.getElementById('google-maps-script')) {
      const googleMapsScript = document.createElement('script');
      googleMapsScript.id = 'google-maps-script';
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
      window.document.body.appendChild(googleMapsScript);

      // wait for script to load
      googleMapsScript.addEventListener('load', () => {
        // trigger state change of component
        setState((prevState) => ({
          ...prevState,
          scriptLoaded: true,
        }));
      });
    } else {
      setState((prevState) => ({
        ...prevState,
        scriptLoaded: true,
      }));
    }

    // listen for mapLoaded event and trigger state change of component
    window.addEventListener('mapLoaded', () => {
      setState((prevState) => ({
        ...prevState,
        mapLoaded: true,
      }));
    });
  }, []);

  const getMapElement = () => {
    return (
      <div style={{ height: '100%' }}>
        <div id={'map'}></div>
      </div>
    );
  };

  // check if Google Maps script is loaded
  if (!state.scriptLoaded) {
    return getMapElement();
  }

  const google = window.google;
  const map = window.map;

  // set map options
  const mapOptions = {
    styles: props.mapStyle,
    disableDefaultUI: true,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };

  // set map options
  map.setOptions(mapOptions);

  const showInfo = (marker: any, info: { title: string | null; content: string | null }) => {
    const title = info.title ? `<h1 id="firstHeading" class="firstHeading">${info.title}</h1>` : '';
    const content = info.content ? `<div id="bodyContent"><p>${info.content}</p></div>` : '';

    // TODO: find out if we need to create a component for info window?
    const contentString = `<div id="content">
                <div id="siteNotice">
                    ${title}
                    ${content}
                </div>
            </div>`;

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
      ariaLabel: 'Uluru',
    });

    infowindow.open({
      anchor: marker,
      map,
    });
  };

  if (props?.markers) {
    props.markers.forEach((feature: any) => {
      const marker = new google.maps.Marker({
        position: feature.position,
        icon: feature.icon,
        map: map,
      });
      if (feature.infoBox) {
        marker.addListener('click', () => {
          const info: { content: string | null; title: string | null } = {
            content: feature.infoBox.content ?? null,
            title: feature.infoBox.title ?? null,
          };

          showInfo(marker, info);
        });
      }
    });
  }

  if (!isVisible) return null;

  return getMapElement();
};

// initMap is called by Google Maps script when loaded
const initMap = () => {
  const google = window.google;
  window.map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
    center: { lat: 46, lng: 28 },
    zoom: 7,
  });

  // trigger mapLoaded event
  const event = new CustomEvent('mapLoaded');
  window.dispatchEvent(event);
};

declare global {
  interface Window {
    initMap: () => void;
    map: any;
    google: any;
  }
}
window.initMap = initMap;
