import moment from 'moment';

const INTERVAL_TICKET_TIMER_RESOLUTION = 1000;
let intervalTickersTimerId = 0;
let generateTickerId = 0;
const intervalTickers = [];

const TIMEOUT_TIMER_RESOLUTION = 1000;
let timeoutTimerId = 0;
let generateTimeoutId = 0;
const timeouts = [];

const DEBUG = false;

export const createIntervalTicker = ({ name, interval, add, callback, options }) => {
  add = add || 0;

  DEBUG && console.log(`creating interval ticker '${name}'`, interval, add, options);

  const now = new Date();
  const nowsd = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDay());

  // compute difference from day start until current time
  const diff = now.getTime() - nowsd.getTime();

  // number of full intervals from day start until current time
  const nrid = Math.floor(diff / interval);

  // round time for full intervals
  const rtfi = nrid * interval;

  // compute time to wait
  const toWait = interval - (diff - rtfi);

  //console.log("diff", diff, "nrid", nrid, "rtfi", rtfi, "toWait", toWait);

  DEBUG && console.log('need to wait', toWait, `until interval start for '${name}'`);

  // check if we need to replace
  if (options && options.replace) {
    DEBUG && console.log(`looking to replace ticker '${name}'`);
    removeIntervalTicker(name);
  }

  // check if we have a timer already and we should keep it
  if (options && options.keep && intervalTickers.find(it => it.name === name)) {
    return;
  }

  const tickerId = ++generateTickerId;

  intervalTickers.push({
    id: tickerId,
    name,
    interval,
    add,
    callback,
    nextTick: nowsd.getTime() + rtfi + interval
  });

  // setup watcher for all tickers when the first one is created
  if (intervalTickersTimerId === 0) {
    DEBUG && console.log('starting main ticker');

    intervalTickersTimerId = setInterval(() => {
      // current time
      const now = new Date().getTime();

      // go through each interval entry
      intervalTickers.forEach(it => {
        // check interval entry next tick
        if (it.nextTick + it.add > now) {
          // not yet
          return;
        }

        DEBUG &&
          console.log(
            it.interval,
            'tick for',
            it.name,
            'at',
            new Date(),
            'tick',
            moment(it.nextTick).format()
          );

        // time difference between now and the time the callback should have been called
        const timeDiff = now - (it.nextTick + it.add);

        // execute callback
        it.callback({
          timeDiff,
        });

        // compute the time for the next tick
        while (it.nextTick + it.add <= now) {
          it.nextTick += it.interval;
        }
      });
    }, INTERVAL_TICKET_TIMER_RESOLUTION);
  }

  DEBUG && console.log(`added ticker ${tickerId} for '${name}', total ${intervalTickers.length}`);

  return tickerId;
};

export const createTimeout = ({ name, timeout, callback, options }) => {
  DEBUG && console.log(`creating timeout '${name}'`, timeout, options);

  const now = new Date();

  // check if we need to replace
  if (options && options.replace) {
    DEBUG && console.log(`looking to replace ticker '${name}'`);
    removeTimeout(name);
  }

  const timeoutId = ++generateTimeoutId;

  timeouts.push({
    id: timeoutId,
    name,
    timeout,
    callback,
    expires: now.getTime() + timeout
  });

  // setup watcher for all tickers when the first one is created
  if (timeoutTimerId === 0) {
    DEBUG && console.log('starting main ticker');

    timeoutTimerId = setInterval(() => {
      // current time
      const now = new Date().getTime();

      // timeouts to remove
      const toRemove = [];

      // go through each interval entry
      timeouts.forEach(it => {
        // check expired timeout
        if (it.expires > now) {
          // not yet
          return;
        }

        DEBUG &&
          console.log(
            timeout,
            'timeout for',
            it.name,
            'at',
            new Date(),
            'expired',
            moment(it.expires).format()
          );

        // execute callback
        it.callback();

        // remove expired timeout
        toRemove.push(it.id);
      });

      if (toRemove.length > 0) {
        toRemove.forEach(id => removeTimeout(id));
      }
    }, TIMEOUT_TIMER_RESOLUTION);
  }

  DEBUG && console.log(`added timeout ${timeoutId} for '${name}', timeout ${timeout}, total ${timeouts.length}`);

  return timeoutId;
};

export const removeIntervalTicker = id => {
  let count = 0;
  const go = true;

  while (go) {
    // search by id
    let idx = intervalTickers.findIndex(it => it.id === id);

    // search by name
    if (idx === -1) {
      idx = intervalTickers.findIndex(it => it.name === id);
    }

    // found something?
    if (idx === -1) {
      break;
    }

    count++;

    // remove ticker if found
    intervalTickers.splice(idx, 1);

    // stop watcher if there are no tickers left
    if (intervalTickers.length === 0) {
      DEBUG && console.log('stopping main ticker');

      clearInterval(intervalTickersTimerId);
      intervalTickersTimerId = 0;
    }

    DEBUG && console.log(`removed ticker ${id}, remaining ${intervalTickers.length}`);
  }

  if (DEBUG) {
    if (count > 0) {
      console.log(`removed ${count} tickers for id/name ${id}`);
    } else {
      console.log(`ticker ${id} not found`);
    }
  }
};

export const queryTimeout = id => {
  // search by id
  let idx = timeouts.findIndex(it => it.id === id);

  // search by name
  if (idx === -1) {
    idx = timeouts.findIndex(it => it.name === id);
  }

  // found something?
  if (idx === -1) {
    return null;
  }

  // current time
  const now = new Date().getTime();
  let left = timeouts[idx].expires - now;
  if (left < 0) left = 0;
  let done = timeouts[idx].timeout - left;

  return {
    timeout: timeouts[idx].timeout,
    done,
    left,
  };
};

export const removeTimeout = id => {
  let count = 0;

  const go = true;

  while (go) {
    // search by id
    let idx = timeouts.findIndex(it => it.id === id);

    // search by name
    if (idx === -1) {
      idx = timeouts.findIndex(it => it.name === id);
    }

    // found something?
    if (idx === -1) {
      break;
    }

    count++;

    // remove ticker if found
    timeouts.splice(idx, 1);

    // stop watcher if there are no tickers left
    if (timeouts.length === 0) {
      DEBUG && console.log('stopping main timeout ticker');

      clearInterval(timeoutTimerId);
      timeoutTimerId = 0;
    }

    DEBUG && console.log(`removed timeout ${id}, remaining ${timeouts.length}`);
  }

  if (DEBUG) {
    if (count > 0) {
      console.log(`removed ${count} timeouts for id/name ${id}`);
    } else {
      console.log(`timeout ${id} not found`);
    }
  }
};
