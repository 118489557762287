import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';

//import './index.scss';

type PathChangeProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const PathChange = (componentProps: PathChangeProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const uriMatches = useMatches();

  const contextValue = React.useMemo(() => {
    let pathName = '/';
    let params = {};

    if (uriMatches && uriMatches.length) {
      const uriMatch = uriMatches[uriMatches.length - 1];
      pathName = uriMatch.pathname;
      params = uriMatch.params;
    }

    return {
      pathName,
      params,
    };
  }, [uriMatches]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default PathChange;
