import {
  Card,
  PaymentDetailsInterface,
  PaymentProviderInterface,
} from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import { AmountButtonInterface } from '@/components/classes/PaymentProviders/Interfaces/AmountButtonInterface';
import { useAppDispatch } from '@/store';
import { formatCurrency } from '@/modules/bets/utils/formatters';
import cordovaRedirect from '../utils/cordovaRedirect';

export interface PaymentProviderConstructor {
  triggerError: () => void;
  triggerSuccess: () => void;
  triggerPending: () => void;
  selectCard?: (card: Card) => void;
  hideLoader: () => void;
  showLoader: () => void;
  dispatch: (action: any) => void;
  showInputsLoader?: () => void;
  hideInputsLoader?: () => void;
  paymentDetails?: PaymentDetailsInterface | undefined;
  paymentProvider?: number;
  settings?: any;
}

class PaymentProvider implements PaymentProviderInterface {
  paymentDetails: PaymentDetailsInterface = {
    amount: 0,
    currency: '',
    bonusId: null,
  };
  triggerError: () => void;
  triggerSuccess: () => void;
  triggerPending: () => void;
  hideLoader: () => void;
  showLoader: () => void;
  selectCard: ((card: Card) => void) | undefined;
  type = 0;
  openedPopupUrl = '';
  debugActive: false | undefined;
  hideInputsLoader: () => void;
  showInputsLoader: () => void;

  constructor(data: PaymentProviderConstructor) {
    this.setPaymentDetails(
      data?.paymentDetails ?? {
        amount: 0,
        currency: '',
        bonusId: null,
      },
    );
    this.triggerError = data.triggerError;
    this.triggerSuccess = data.triggerSuccess;
    this.triggerPending = data.triggerPending;
    this.hideLoader = data.hideLoader;
    this.showLoader = data.showLoader;
    this.dispatch = data.dispatch;
    this.selectCard = data.selectCard;

    this.hideInputsLoader = data.hideInputsLoader ?? (() => {});
    this.showInputsLoader = data.showInputsLoader ?? (() => {});
    this.debug('PaymentProvider constructor');
  }

  cards?: Card[] | undefined;

  debug(data: any): void {
    if (this.debugActive) {
      console.log(data);
    }
  }

  init(data: any): void {
    this.debug('PaymentProvider init');
    if (data.currency) {
      this.setCurrency(data.currency);
    }
  }
  destroy(): void {
    // implement this function in child class if needed
    this.debug('PaymentProvider destroy');
  }

  dispatch(action: any): void {
    const dispatch = useAppDispatch();
    dispatch(action);
    this.debug('PaymentProvider dispatch');
  }

  getAmountButtons(
    state: any,
    opt: {
      currentCurrency: string;
      setStateCallback: (state: any) => any;
      onDepositButtonClick: (e: any) => any;
      ignoreBonus?: boolean;
    },
  ): AmountButtonInterface[] {
    this.debug('PaymentProvider getAmountButtons');

    const buttons: AmountButtonInterface[] = [];

    let steps = window.config?.depositSettings?.defaultPresets?.[opt.currentCurrency]
      ? [...window.config.depositSettings.defaultPresets[opt.currentCurrency]]
      : [];

    if (this.type !== 0) {
      steps = window.config?.depositSettings?.providers?.[this.type]?.defaultPresets?.[opt.currentCurrency]
        ? [...window.config.depositSettings.providers[this.type].defaultPresets[opt.currentCurrency]]
        : steps;
    }
    const currency = formatCurrency(opt.currentCurrency);

    const gridSettings =
      window.config?.depositSettings?.providers?.[this.type]?.grid ?? window.config?.depositSettings?.defaultGrid ?? {};

    const bonusMin: number = state.selectedBonusInfo?.deposit?.min ?? 0; // TODO: add min and max bonus on predefined pills. DO NOT CREATE A NEW ONE if it already.
    const bonusMax: number = state.selectedBonusInfo?.deposit?.max ?? 0;
    let minUsed = bonusMin < 1;
    let maxUsed = bonusMax < 1;

    const remainingColumns = gridSettings?.maximumColumns ? steps.length % gridSettings.maximumColumns : false;
    const rows = Math.ceil(steps.length / (gridSettings?.maximumColumns ?? 1));
    steps.forEach((val: number, key: any) => {
      // @ts-ignore
      val = parseInt(val, 10);
      if (gridSettings?.injectBonusButtons) {
        if (bonusMin || bonusMax) {
          if (!minUsed && val >= bonusMin) {
            val = bonusMin;
            minUsed = true;
          }

          if (!maxUsed && val >= bonusMax && minUsed) {
            val = bonusMax;
            maxUsed = true;
          }

          if (key === 5) {
            if (!minUsed) {
              if (bonusMin > 0) {
                val = bonusMin;
              }
            } else {
              if (!maxUsed) {
                if (bonusMax > 0) {
                  val = bonusMax;
                }
              }
            }
          }
          steps[key] = val;
        }
      }
      if (state.depositValue === 0 && val === bonusMax && !state.initialized) {
        opt.setStateCallback({
          ...state,
          depositValue: val,
          initialized: true,
        });
      }

      let className = gridSettings?.maximumColumns ? 'buttons-grid-' + gridSettings.maximumColumns : '';
      if (remainingColumns) {
        const defaultGrid = gridSettings?.maximumColumns ?? 1;
        if (key < defaultGrid * (rows - 1)) {
          // check if remainingColumns is not accepted
          if (gridSettings?.columnsNotAccepted?.includes(remainingColumns)) {
            className = 'buttons-grid-' + gridSettings.fallbackColumns ?? gridSettings.maximumColumns;
          }
        } else {
          className = 'buttons-grid-' + remainingColumns;
        }
      } else {
        className = 'buttons-grid-3';
      }
      const prefix = window.config?.depositSettings?.presetsType === 'incremental' ? '+' : '';
      buttons.push({
        value: val ?? 0,
        valueRendered: `${prefix}${val} ${currency}`,
        id: key,
        onClickHandler: opt.onDepositButtonClick,
        active: window.config?.depositSettings?.presetsType !== 'incremental' && parseInt(state.depositValue, 10) === val,
        description: val === bonusMax ? 'max bonus' : val === bonusMin ? 'min bonus' : '',
        className,
      });
    });

    return buttons;
  }

  setPaymentDetails(paymentDetails: PaymentDetailsInterface, setPaymentDetailsCallBack?: () => void): any {
    this.debug('PaymentProvider setPaymentDetails');

    this.paymentDetails = paymentDetails;
    if (paymentDetails?.card?.token) {
      const cards = this.getCards();
      const cardsCopy: Card[] = [];
      // mark as selected
      cards.forEach((card: Card) => {
        // make a copy of the card
        const c: Card = { ...card };
        c.selected = card.token === paymentDetails?.card?.token;
        cardsCopy.push(c);
      });
      this.setCards(cardsCopy);
      this.paymentDetails.cardInfo = undefined;
    } else {
      this.paymentDetails.card = undefined;
    }
    if (setPaymentDetailsCallBack) {
      setPaymentDetailsCallBack();
    }
  }

  createCard(card: any): Card {
    this.debug('PaymentProvider createCard');

    const c: Card = {
      token: card.card_token,
      type: card.card_type_id,
      number: card.card_number,
      expiration_date: card.expiration_date,
      name: card.card_full_name ?? '',
      verified: false,
      selected: false,
      providerId: this.type,
      selectCard: () => {
        // nothing
      },
      bin: card.bin ?? '',
    };
    c.selectCard = () => {
      this.setPaymentDetails({
        ...this.paymentDetails,
        card: c,
      });
      if (this.selectCard) {
        // c.selected = true;
        this.selectCard(c);
      }
    };
    return c;
  }

  getPaymentDetails(): PaymentDetailsInterface {
    this.debug('PaymentProvider getPaymentDetails');

    return this.paymentDetails;
  }
  setCurrency(currency: string): void {
    this.debug('PaymentProvider setCurrency');

    if (this.paymentDetails) {
      this.paymentDetails.currency = currency;
    }
  }
  getCurrency(): string {
    this.debug('PaymentProvider getCurrency');

    return this.paymentDetails?.currency ?? '';
  }
  setAmount(amount: number): void {
    this.debug('PaymentProvider setAmount');

    if (this.paymentDetails) {
      this.paymentDetails.amount = amount;
    }
  }
  getAmount(): number {
    this.debug('PaymentProvider getAmount');

    return this.paymentDetails?.amount ?? 0;
  }

  confirmDepositValue(): void {
    this.debug('PaymentProvider confirmDepositValue');

    // implement this function in child class if needed
    this.confirmPayment();
  }

  confirmPayment(): void {
    this.debug('PaymentProvider confirmPayment');

    // implement this function in child class if needed
  }

  setBonusId(bonusId: number | null): void {
    this.debug('PaymentProvider setBonusId');

    this.paymentDetails.bonusId = bonusId;
  }
  getBonusId(): number | null {
    this.debug('PaymentProvider getBonusId');

    return this.paymentDetails?.bonusId ?? null;
  }

  setType(type: number): void {
    this.debug('PaymentProvider setType');

    this.type = type;
  }

  getType(): number {
    this.debug('PaymentProvider getType');

    return this.type;
  }

  getLimits(): { min: number; max: number } {
    this.debug('PaymentProvider getLimits');

    let min = window.config?.depositSettings?.defaultLimits?.[this.paymentDetails.currency]?.min ?? 0;
    let max = window.config?.depositSettings?.defaultLimits?.[this.paymentDetails.currency]?.max ?? 0;

    if (this.type !== 0) {
      min =
        window.config?.depositSettings?.providers?.[this.type]?.defaultLimits?.[this.paymentDetails.currency]?.min ??
        min;
      max =
        window.config?.depositSettings?.providers?.[this.type]?.defaultLimits?.[this.paymentDetails.currency]?.max ??
        max;
    }
    // TODO: replace max limit with user limit;
    return {
      min,
      max,
    };
  }

  setCards(cards: Card[]): void {
    this.debug('PaymentProvider setCards');

    this.cards = cards;
  }

  getCards(): Card[] {
    this.debug('PaymentProvider getCards');

    return this.cards ?? [];
  }

  redirect(url: string): void {
    this.debug('PaymentProvider redirect');

    if (window.config.cordova) {
      this.openedPopupUrl = cordovaRedirect(url, this.openedPopupUrl);
      return;
    }
    window.location.href = url;
  }

  sendConfirmation(data: any): void {
    this.debug('PaymentProvider sendConfirmation');

    // implement this function in child class if needed
    // to be used by VIVA (for example), when 3DS is used in new tab/window
  }

  initInputsForNewCard(): void {
    this.debug('PaymentProvider initInputsForNewCard');

    // implement this function in child class if needed
  }
  initInputsForSavedCards(): void {
    this.debug('PaymentProvider initInputsForSavedCard');

    // implement this function in child class if needed
  }

  quickDeposit(data: any): void {
    // implement this function in child class if needed
    console.error('quickDeposit not implemented');
  }
}

export default PaymentProvider;
