import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import "./ChooseNickname.scss";
import PersonIcon from "./assets/person.svg";
import { savePlayerNickname } from "../../store/actions/player";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChooseNickname = props => {
  const { open, handleClose, player, t, savePlayerNickname } = props;

  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const saveNickname = () => {
    setError("");
    if (value) {
      setLoading(true);
      savePlayerNickname({
        nickname: value,
        cb: (data) => {
          setLoading(false);
          if (typeof data.success !== "undefined" && !data.success) {
            if (typeof data.data !== "undefined" && typeof data.data.msg !== "undefined" && data.data.msg === "already registered") {
              if (data.data.suggest) {
                setError(t("The public name already exists. You may choose: {{SUGGEST}}", { SUGGEST: data.data.suggest }));
                setValue(data.data.suggest);
                return;
              }
            }
          }

          handleClose();
        }
      });
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      const input = document.querySelector(".wf-input-control");
      if (input) input.focus();
    }, 200);

  }, []); // eslint-disable-line

  if (!open) return null;

  //console.log("player", player);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winter-fun-modal-choose-nickname betsModal betsModalDialog`}
    >
      <DialogContent className="wf-dialog-content">
        <div className="wf-modal-header">
          <div className="wf-h-wrapper">
            <div className="wf-h-w-left">
              <div className="wf-h-w-l-title">{t("Winner Fun")}</div>
            </div>
          </div>
        </div>

        <div className="wf-title">{t("Choose a public name")}</div>

        <div className="wf-input-wrapper">
          <div className="wf-icon">
            <img src={PersonIcon} alt="person icon" />
          </div>
          <div className="wf-input">
            {player.approved === false && (player.nickname !== "" && player.nickname !== null) &&
              <input placeholder={t("Public name")} defaultValue={player.nickname} readOnly />
            }
            {player.approved === false && (player.nickname === "" || player.nickname === null) &&
              <input placeholder={t("Public name")} value={value} onChange={handleChange} className="wf-input-control" maxLength="25" />
            }
          </div>
        </div>

        <div className="wf-info">
          <div className="wf-icon">
            <div>i</div>
          </div>
          <div className="wf-text">
            {error !== "" && <div className="wf-error">{error}</div>}
            {player.approved === false && (player.nickname !== "" && player.nickname !== null) && <div className="wf-warning">{t("Nickname not approved yet.")}</div>}
            <div className="wf-op">{t("Anyone can see this name. It takes up to 48 hours until we approve it and it can become public.")}</div>
          </div>
        </div>

        <div className="wf-nickname">
          <div className="wf-nick">{player.default_nickname}</div>
          <div className="wf-description">{t("YOUR TEMPORARY NAME")}</div>
        </div>

        {player.approved === false && (player.nickname === "" || player.nickname === null) &&
          <React.Fragment>
            <div className="wf-button-wrapper">
              <ButtonBase className={`wf-button ${loading ? 'wf-loading' : ''}`} onClick={saveNickname} disabled={!value}>
                {t("Continue")}
                <div className="wf-loader"><CircularProgress /></div>
              </ButtonBase>
            </div>

            <div className="wf-link" onClick={handleClose}>
              {t("I'll change it later")}
            </div>
          </React.Fragment>
        }
        {(player.nickname !== "" && player.nickname !== null) && <div className="wf-button-wrapper">
          <ButtonBase className="wf-button" onClick={handleClose}>
            {t("Close")}
          </ButtonBase>
        </div>}
      </DialogContent>
    </Dialog>
  );
};

const mapActions = {
  savePlayerNickname
};

export default withTranslation()(connect(null, mapActions)(ChooseNickname));
