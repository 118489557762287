import mediaQueries from './mediaQueries';

const propertiesByMediaQuery = (props, key, indicator) => {

  const indicators = {
    6: 'XXL',
    5: 'XL',
    4: 'LG',
    3: '<900px',
    2: 'MD',
    1: 'SM',
  };
  const translatedIndicator = indicators[indicator];

  if (indicator && translatedIndicator !== mediaQueries[0].indicator) {
    const widthMediaIndex = mediaQueries.findIndex((el) => el.indicator === translatedIndicator);

    if (widthMediaIndex > -1) {
      for (let i = widthMediaIndex; i > -1; i--) {
        const previousWidthMedia = mediaQueries[i].indicator;
        if (typeof props[`${key}${previousWidthMedia}`] !== 'undefined' && props[`${key}${previousWidthMedia}`]) return props[`${key}${previousWidthMedia}`];
      }
    }
  }

  return props[key];
};

export default propertiesByMediaQuery;
