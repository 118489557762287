import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { testValues, getTr } from '../../utils/functions';
import { SportType } from '../../utils/types';
import { useMediaQuery } from '../../../../utils/useQueryMedia';
import { appToggleCompetitions } from '../../../../../modules/bets/store/actions/app';
import { navSportsUpdate } from '../../../../../modules/bets/store/actions/navSports';

import './index.scss';
import { shallowEqual } from 'react-redux';

type NavigatorDesktopProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ChangedData = (props: any) => {
  const [data, setData] = React.useState<any>(props.data);

  React.useEffect(() => {
    if (JSON.stringify(props.data) !== JSON.stringify(data)) setData(props.data);
  }, [data, props.data]);

  return data;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const NavigatorDesktop = (componentProps: NavigatorDesktopProps) => {
  const uriMatches = useMatches();

  const dispatch = useAppDispatch();
  const { matchData, sportsMeta, categoriesMeta, tournamentsMeta, matches } = useAppSelector(
    (state) => ({
      matchData: state.bets.live.data,
      sportsMeta: state.bets.live.sports,
      categoriesMeta: state.bets.live.categories,
      tournamentsMeta: state.bets.live.tournaments,
      matches: state.bets.live.matches,
    }),
    shallowEqual,
  );

  const [deferedSearch, setSearch] = React.useState<string>('');
  const search = React.useDeferredValue(deferedSearch);
  const { i18n } = useTranslation();

  const isMobile = useMediaQuery('(max-width:900px)');

  const [collapsed, setCollapsed] = React.useState<any>({
    '1': false,
  });

  const getFavoriteListFromLocalStorage = () => {
    const favs = localStorage.getItem('favorite-live-sport-competitions') ?? '[]';
    return JSON.parse(favs);
  };

  const [favoriteList, setFavoriteList] = React.useState<any[]>(getFavoriteListFromLocalStorage());

  const onChangeSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const onToggleCollapse = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.currentTarget.dataset.sport) {
      const sport = e.currentTarget.dataset.sport;
      const category = e.currentTarget.dataset.category;
      const tournament = e.currentTarget.dataset.tournament;

      const parts = [];
      if (sport) parts.push(sport);
      if (category) parts.push(category);
      if (tournament) parts.push(tournament);

      const key = parts.join('-');

      setCollapsed((prev: any) => {
        return {
          ...prev,
          [key]: prev[key] != null ? !prev[key] : false,
        };
      });
    }
  }, []);

  const onToggleIsFavorite = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (e.currentTarget.dataset.sport && e.currentTarget.dataset.category && e.currentTarget.dataset.tournament) {
        const sport = e.currentTarget.dataset.sport;
        const category = e.currentTarget.dataset.category;
        const tournament = e.currentTarget.dataset.tournament;

        const numberOfEvents: any = {};

        const parts = [];
        if (sport) parts.push(sport);
        if (category) parts.push(category);
        if (tournament) parts.push(tournament);
        const key = parts.join('-');

        //@ts-ignore
        const data = matchData[parts[0]]?.[parts[1]]?.[parts[2]] ?? {};
        const total = Object.values(data).filter((event: any) => {
          return event.active === true || !(!event?.active && event?.currentStatus?.IsLiveFinished);
        });

        numberOfEvents[key] = Array.isArray(total) ? total.length : 0;

        const favorites = getFavoriteListFromLocalStorage();

        const exists = favorites.findIndex((fav: any) => {
          if (fav.idSport === sport && fav.idCategory === category && fav.idTournament === tournament) {
            return true;
          }
          return false;
        });

        if (exists !== -1) {
          favorites.splice(exists, 1);
        } else {
          favorites.push({
            key: key,
            idSport: sport,
            idCategory: category,
            idTournament: tournament,
            // @ts-ignore
            name: tournamentsMeta?.[tournament].tournamentName ?? '',
            // @ts-ignore
            numberOfEvents: numberOfEvents[key] ?? 0,
            // @ts-ignore
            categoryName: parts[1] && categoriesMeta[parts[1]] ? categoriesMeta[parts[1]].categoryName : '',
            // @ts-ignore
            categoryDLId: parts[1] && categoriesMeta[parts[1]] ? categoriesMeta[parts[1]].categoryDLId : '',
          });
        }

        localStorage.setItem('favorite-live-sport-competitions', JSON.stringify(favorites));

        setFavoriteList(favorites);
      }
    },
    [matchData],
  );

  let idSport = '1';
  if (uriMatches?.[0]?.params?.idSport) {
    idSport = uriMatches[0].params.idSport;
  }

  const contextValue = React.useMemo(() => {
    const final: any[] = [];

    if (!search || search.length < 3) {
      const allSports: SportType[] = JSON.parse(JSON.stringify(Object.values(sportsMeta)));

      // only active sports
      const sports = allSports.filter((sport: SportType) => {
        return sport?.sportActive === true;
      });

      // sort sport by backend sport position
      sports.sort((a, b) => {
        return testValues(a?.sportPosition, b?.sportPosition);
      });

      const cats: { [isSport: string]: any[] } = {};
      Object.keys(categoriesMeta).forEach((idCategory: any) => {
        // @ts-ignore
        const category = categoriesMeta[idCategory];
        if (!cats[category.idSport]) cats[category.idSport] = [];
        cats[category.idSport].push(category);
      });

      const tours: { [idCategory: string]: any[] } = {};
      Object.keys(tournamentsMeta).forEach((idTournament: any) => {
        // @ts-ignore
        const tournament = tournamentsMeta[idTournament];
        if (!tours[tournament.idCategory]) tours[tournament.idCategory] = [];
        tours[tournament.idCategory].push(tournament);
      });

      sports.forEach((sport: SportType) => {
        sport.numberOfEvents = 0;
        const cs: any[] = [];

        cats[sport.idSport] &&
          cats[sport.idSport].forEach((c: any) => {
            if (!(c && c.categoryActive)) return;
            const category = { ...c };
            category.numberOfEvents = 0;

            if (category.idSport !== sport.idSport) return;

            const ts: any[] = [];

            tours[category.idCategory] &&
              tours[category.idCategory].forEach((t: any) => {
                if (!(t && t.tournamentActive)) return;
                const tournament = { ...t };

                const key = `${sport.idSport}-${category.idCategory}-${tournament.idTournament}`;
                tournament.collapsed = collapsed[key] != null ? collapsed[key] : true;

                tournament.numberOfEvents = 0;

                try {
                  // @ts-ignore
                  const data = matchData[sport.idSport]?.[category.idCategory]?.[tournament.idTournament];
                  const total = Object.values(data).filter((event: any) => {
                    return event.active === true || !(!event?.active && event?.currentStatus?.IsLiveFinished);
                  });

                  tournament.numberOfEvents = total.length;
                } catch (err) {}

                sport.numberOfEvents += tournament.numberOfEvents;
                category.numberOfEvents += tournament.numberOfEvents;

                if (tournament.numberOfEvents) ts.push(tournament);
              });

            if (category.numberOfEvents) {
              category.tournaments = ts;
              const key = `${sport.idSport}-${category.idCategory}`;
              category.collapsed = collapsed[key] != null ? collapsed[key] : true;

              !category.collapsed &&
                ts.sort((a, b) => {
                  return testValues(a?.tournamentPosition, b?.tournamentPosition);
                });

              cs.push(category);
            }
          });

        // @ts-ignore
        // sport.collapsed = collapsed[sport.idSport] != null ? collapsed[sport.idSport] : true;
        sport.collapsed = false;
        // @ts-ignore
        if (!sport.collapsed) {
          cs.sort((a, b) => {
            return testValues(a?.categoryPosition, b?.categoryPosition);
          });
        }

        // @ts-ignore
        sport.categories = cs;

        if (sport.numberOfEvents) final.push(sport);
      });
    }

    const filteredLeagues: any[] = [];
    if (search && search.length >= 3) {
      Object.values(tournamentsMeta).forEach((tournament: any) => {
        if (!tournament) return;
        if (!tournament?.tournamentActive) return;

        const text = getTr(tournament.tournamentName, i18n.language);

        if (text?.toLowerCase?.().indexOf(search.toLowerCase()) !== -1) {
          filteredLeagues.push({
            idSport: tournament.idSport,
            idCategory: tournament.idCategory,
            idTournament: tournament.idTournament,
            tournamentName: tournament.tournamentName,
          });
        }
      });
    }

    const filteredMatches: any[] = [];
    if (search && search.length >= 3) {
      Object.values(matches).forEach((match: any) => {
        if (!match) return;
        if (!match?.active && match?.currentStatus?.IsLiveFinished) return;

        let found = false;

        const team1Name = getTr(match.team1Name, i18n.language);
        if (team1Name?.toLowerCase?.().indexOf(search.toLowerCase()) !== -1) found = true;

        if (!found) {
          const team2Name = getTr(match.team2Name, i18n.language);
          if (team2Name?.toLowerCase?.().indexOf(search.toLowerCase()) !== -1) found = true;
        }

        if (found) {
          filteredMatches.push({
            idSport: match.idSport,
            idCategory: match.idCategory,
            idTournament: match.idTournament,
            idMatch: match.idMatch,
            team1Name: match.team1Name,
            team2Name: match.team2Name,
          });
        }
      });
    }

    const isFavorite: any = {};
    favoriteList.forEach((fav: any) => {
      if (fav.idSport && fav.idCategory && fav.idTournament) {
        isFavorite[`${fav.idSport}-${fav.idCategory}-${fav.idTournament}`] = true;
      }
    });

    return {
      sports: final.filter((sport: SportType) => {
        return sport?.idSport === idSport;
      }),
      onToggleCollapse: onToggleCollapse,
      search: search ?? '',
      onChangeSearch,
      filteredMatches,
      filteredLeagues,
      isMobile,
      onToggleCompetitions: () => {
        dispatch(appToggleCompetitions());
      },

      isFavorite,
      onToggleIsFavorite,
      favorites: favoriteList,
    };
  }, [
    matchData,
    sportsMeta,
    categoriesMeta,
    tournamentsMeta,
    collapsed,
    onToggleCollapse,
    matches,
    search,
    i18n.language,
    idSport,
    isMobile,

    onToggleIsFavorite,
    favoriteList,
  ]);

  //console.timeEnd('NavigatorDesktopLive');
  //console.log('NavigatorDesktop[contextValue] LIVE', { contextValue, isFavorite, favs });

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default NavigatorDesktop;
