import React from 'react';
import { connect } from 'react-redux';

import "./Gratuities.scss";

import { getWinnerFunState } from "../store/selectors/winnerFunData";
import { winCoinsSystemsLoad, winCoinsSystemsClear } from "../store/actions/wincoins";

import GratuitiesDaily from "../components/GratuitiesDaily/GratuitiesDaily";
import GratuitiesHourly from "../components/GratuitiesHourly/GratuitiesHourly";
import GratuitiesBoost from "../components/GratuitiesBoost/GratuitiesBoost";
import CalendarSummary from "../components/CalendarSummary/CalendarSummary";
import ReferralCode from "../components/ReferralCode/ReferralCode";
import WinnerLoader from "../../winner-loader";

import YouCollectedModal from "./modals/YouCollected";

const Gratuities = (props) => {
  const { className, winCoinsSystems, winCoinsSystemsLoad, opened } = props;

  const [state, setState] = React.useState({
    open: false,
    amount: 0,
  });

  React.useEffect(() => {
    winCoinsSystemsLoad();

    return () => {
      //winCoinsSystemsClear();
    }
  }, []); // eslint-disable-line

  const onCollected = (amount, walletTotal) => {
    setState({
      open: true,
      amount: amount,
      walletTotal: walletTotal,
    });
  };
  const handleCloseCollected = () => {
    setState({
      open: false,
      amount: 0,
    });
  };

  const buildWinCoinSystems = () => {
    let isFirst = true;

    return winCoinsSystems.data.map((system, i) => {
      let ret = null;

      if (system.type === "daily") {
        ret = <GratuitiesDaily className={!isFirst ? `wf-mt-28` : ''} opened={opened} onCollected={onCollected} key={`system-${i}`} origData={system} />
      } else if (system.type === "boost") {
        ret = <GratuitiesBoost className={!isFirst ? `wf-mt-28` : ''} opened={opened} key={`system-${i}`} origData={system} />
      } else if (system.type === "token") {
        ret = <GratuitiesHourly className={!isFirst ? `wf-mt-28` : ''} onCollected={onCollected} key={`system-${i}`} origData={system} />
      }

      isFirst = false;

      return ret;
    });
  };

  return (
    <div className={`wf-gratuities ${className ? className : ''}`}>
      {!!winCoinsSystems && !!winCoinsSystems.data && winCoinsSystems.data.length > 0 &&
        <React.Fragment>
          {buildWinCoinSystems()}
          <ReferralCode onCollected={onCollected} />
          <CalendarSummary className="wf-mt-28" onTabChange={props.onTabChange} />
          <div className="wf-spacer"></div>
        </React.Fragment>
      }
      {!(!!winCoinsSystems && !!winCoinsSystems.data && winCoinsSystems.data.length > 0) && <div><WinnerLoader /></div>}
      {state.open && <YouCollectedModal open={state.open} handleClose={handleCloseCollected} amount={state.amount} walletTotal={state.walletTotal} />}
    </div>
  );
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);
  return {
    winCoinsSystems: wfs.winCoinsSystems,
  };
};
const mapActions = {
  winCoinsSystemsLoad,
  winCoinsSystemsClear
};

export default connect(mapStateToProps, mapActions)(Gratuities);