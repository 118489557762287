import { tournamentConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  enrollError: null,
  activateError: null,
};

const tournamentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case tournamentConstants.ENROLL_ERROR: {
        draft.enrollError = action.error;
        break;
      }
      case tournamentConstants.ENROLL_CLEAR_ERROR: {
        draft.enrollError = null;
        break;
      }
      case tournamentConstants.ACTIVATE_ERROR: {
        draft.activateError = action.error;
        break;
      }
      case tournamentConstants.ACTIVATE_CLEAR_ERROR: {
        draft.activateError = null;
        break;
      }
      default:
      /* noop */
    }
  });

export default tournamentReducer;
