import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './keys_status.scss';
import IconKey from '../assets/icon-key.svg';

const KeysStatus = (props) => {
  const { wheelStatus, t, hideInfo } = props;

  if (!wheelStatus) return null;
  if (!wheelStatus.keys_spin) return null;
  if (wheelStatus && wheelStatus.status === 'not_eligible') {
    if (wheelStatus.reason && wheelStatus.reason.conflicts && typeof wheelStatus.reason.conflicts[1002] !== 'undefined') {
      return null;
    }
  }

  const style = {
    width: `${wheelStatus.keys_spin.next_key_progress}%`
  };

  const shouldHide = wheelStatus.keys_spin.available >= wheelStatus.keys_spin.max;
  if (shouldHide) return null;

  return (
    <div className="wheel-keys-status">
      <div className="ProductsSection AwardActivity">
        <div className="ProductsContainer">
          <div className="ProductBox keys-status">
            <div className="BoxContent keys-status">
              <table className="box-content-table">
                <tbody>
                  <tr className="first-row">
                    <td colSpan="2" className="tar">
                      <p className="text-header">{t('Play activity for the next key:')}</p>
                    </td>
                  </tr>
                  <tr className="first-row">
                    <td className="spc">
                      {wheelStatus.keys_spin.next_key_progress > -1 ? `${wheelStatus.keys_spin.next_key_progress}%` : ''}
                      <span className="wager-txt">({wheelStatus.keys_spin.current_key_wager}/{wheelStatus.keys_spin.complete_key_wager} Lei)</span>
                    </td>
                    <td className="tar">
                      <h2 className="BoxAmount">
                        <span>{wheelStatus.keys_spin.available}</span>
                      </h2>
                    </td>
                  </tr>
                  {wheelStatus.keys_spin.next_key_progress !== -1 && <tr className="last-row">
                    <td colSpan="2">
                      <div className="prg-wrapper">
                        <div className="prg" style={style}></div>
                      </div>
                    </td>
                  </tr>}
                </tbody>
              </table>
              <div className="BoxIcon">
                <img src={IconKey} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    wheelStatus: state.wheel && state.wheel.wheel && state.wheel.wheel.status ? state.wheel.wheel.status : null,
  };
};

export default connect(mapStateToProps)(withTranslation()(KeysStatus));