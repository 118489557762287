import { useAppSelector } from '../store';

const cdnSimpleImagesUrl = `https://${window.config.lobbyCDNAddress}/winner`;

const providers: { [id: number]: string } = {
  1: 'egt',
  2: 'amatic',
  3: 'skywind',
  4: 'ctgaming',
  5: 'novomatic',
  6: 'spribe',
  7: 'pragmatic',
  9: 'isoftbet',
};

const stringToSlug = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

const gameIdsToObjects = (items: any[] | null, gameIds: number[], handleJoinPlayAndGame?: any) => {
  if (Array.isArray(items) && Array.isArray(gameIds)) {
    const mappedIds: any = {};
    items.forEach((game, index) => {
      mappedIds[game.id] = index;
    });

    let newGames = gameIds.map((id) => {
      return typeof items[mappedIds[id]] != null ? items[mappedIds[id]] : null;
    });

    newGames = newGames.filter((el) => el != null);

    return newGames;
  }

  return [];
};

export { gameIdsToObjects, stringToSlug };
