import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { getBetslipData } from '../../utils/hooks';
import { useMediaQuery } from '../../../../utils/useQueryMedia';

import { betsSlipSetTicketType } from '../../../../../modules/bets/store/actions/betsSlip';

type BetslipSwitchProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetslipSwitch = (componentProps: BetslipSwitchProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));

  const data = getBetslipData();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery('(min-width:900px)');
  const uriMatches = useMatches();
  const betBuilder = useAppSelector((state) => state.bets.betBuilder);

  const onChangeTicketType = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.type && ['single', 'system'].includes(e.currentTarget.dataset.type)) {
      const type = e.currentTarget.dataset.type;
      dispatch(betsSlipSetTicketType(type));
    }
  };

  const contextValue = React.useMemo(() => {
    let pathName = '/';

    if (uriMatches && uriMatches.length) {
      const uriMatch = uriMatches[uriMatches.length - 1];
      pathName = uriMatch.pathname;
    }

    const contextValue = {
      ticketType: data.ticketType,
      onChangeTicketType,
      isDesktop,
      pathName,
    };

    //console.log('BetslipSwitch[contextValue]', contextValue, data);

    return contextValue;
  }, [data, componentProps, isDesktop, uriMatches]);

  if (betBuilder.selected?.length > 0 && betBuilder.open && window.config.betBuilderEnable === '1') return null;
  if (window.config.betsEnabled !== '1') return null;

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetslipSwitch;
