import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import fetchStatsByIdMatch from './utils/genericFetch';
import { digitainConfig } from '@/modules/bets/api/config/digitain';
import { processFootballStatsData } from './utils/processStatsData';

type BetsMatchStatsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetsMatchStats = (componentProps: BetsMatchStatsProps) => {
  let props = componentProps;
  const betsConfig = digitainConfig();

  const uriMatches = useMatches();
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  let idMatch = props.properties?.idMatch ?? '';
  if (props.properties.targetIdFromPath) {
    if (uriMatches && uriMatches.length) {
      const match = uriMatches[0];

      let pathParamKey = props.properties.pathParamKey ?? 'idMatch';
      if (pathParamKey[0] === ':') pathParamKey = pathParamKey.substr(1);
      if (match.params && pathParamKey && match.params[pathParamKey] != null) {
        idMatch = match.params[pathParamKey];
      }
    }
  }

  let panelIndex = parseInt(props.properties?.panelIndex ?? 10);
  panelIndex = isNaN(panelIndex) ? 1 : panelIndex;
  const onPanelChange = props.properties?.onPanelChange ?? null;

  const [state, setState] = React.useState({
    standingsIndex: 0,
    h2hIndex: 0,
  });

  const onTabChange = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.standingsTabIndex != null) {
      const tabIndex = parseInt(e.currentTarget.dataset.standingsTabIndex, 10) ?? 0;
      setState((v) => ({ ...v, standingsIndex: tabIndex }));
    }
    if (e.currentTarget.dataset.h2hTabIndex != null) {
      const tabIndex = parseInt(e.currentTarget.dataset.h2hTabIndex, 10) ?? 0;
      setState((v) => ({ ...v, h2hIndex: tabIndex }));
    }
  }, []);

  const data = fetchStatsByIdMatch({
    elementId: idMatch,
    apiUrl: `${betsConfig.statsUrl}/${idMatch}`,
  });

  const contextValue = React.useMemo(() => {
    const tmp: any = processFootballStatsData(data);

    const tabs: any = {};
    if (tmp.form || tmp.half || tmp.liveStats || tmp.historyStats || tmp.matchInfo) {
      tabs.summary = true;
    }
    if (tmp.liveStats || tmp.half) {
      tabs.stats = true;
    }
    if (tmp.h2h?.overall?.h2hTeam1 || tmp.h2h?.overall?.h2hTeam2 || tmp.h2h?.home || tmp.h2h?.away) {
      tabs.h2h = true;
    }
    if (tmp.standingsOverall || tmp.standingsHome || tmp.standingsAway) {
      tabs.standings = true;
    }
    if (tmp.lineupsFormations || tmp.substitutedPlayers || tmp.reservePlayers || tmp.coaches) {
      tabs.lineups = true;
    }

    const res = [];

    switch (panelIndex) {
      case 1: // summary
        if (tmp.form) {
          res.push(tmp.form);
        }

        const stats = data?.data?.data;
        if (stats?.summary?.event_time) {
          const now = new Date().valueOf();
          const eventTime = new Date(stats.summary.event_time).valueOf();
          const status = stats?.summary?.status_result?.status ?? '';

          if (now > eventTime && status !== 'FT' && status !== 'Pst') {
            if (tmp.half) {
              res.push(tmp.half);
            }

            if (tmp.liveStats) {
              res.push(tmp.liveStats);
            }
          } else {
            if (tmp.historyStats) {
              res.push(tmp.historyStats);
            }
          }
        }

        if (tmp.matchInfo) {
          res.push(tmp.matchInfo);
        }
        break;
      case 2: // stats
        if (tmp.liveStats) {
          tmp.liveStats.liveStats.simple = true;
          res.push(tmp.liveStats);
        }

        if (tmp.half) {
          res.push(tmp.half);
        }

        break;
      case 3: // h2h
        res.push({
          layout: 'h2hTabs',
          h2hTabs: {
            team1: tmp.h2h?.team1,
            team2: tmp.h2h?.team2,
            h2hIndex: state.h2hIndex,
            onSubTabChange: onTabChange,
          },
        });

        if (state.h2hIndex === 0 && tmp.h2h?.overall?.direct) {
          res.push(tmp.h2h?.overall?.direct);
        }

        if (state.h2hIndex === 0 && tmp.h2h?.overall?.h2hTeam1) {
          res.push(tmp.h2h?.overall?.h2hTeam1);
        }

        if (state.h2hIndex === 0 && tmp.h2h?.overall?.h2hTeam2) {
          res.push(tmp.h2h?.overall?.h2hTeam2);
        }

        if (state.h2hIndex === 1 && tmp.h2h?.home) {
          res.push(tmp.h2h?.home);
        }

        if (state.h2hIndex === 2 && tmp.h2h?.away) {
          res.push(tmp.h2h?.away);
        }

        break;
      case 4: // standings
        res.push({
          layout: 'standingsTabs',
          standingsTabs: {
            standingsIndex: state.standingsIndex,
            onSubTabChange: onTabChange,
          },
        });

        if (state.standingsIndex === 0 && tmp.standingsOverall) {
          res.push(tmp.standingsOverall);
        }
        if (state.standingsIndex === 1 && tmp.standingsHome) {
          res.push(tmp.standingsHome);
        }
        if (state.standingsIndex === 2 && tmp.standingsAway) {
          res.push(tmp.standingsAway);
        }
        break;
      case 5: // lineups
        if (tmp.lineupsFormations) {
          res.push(tmp.lineupsFormations);
        }

        if (tmp.substitutedPlayers) {
          res.push(tmp.substitutedPlayers);
        }

        if (tmp.reservePlayers) {
          res.push(tmp.reservePlayers);
        }

        if (tmp.coaches) {
          res.push(tmp.coaches);
        }
        break;
      default:
        break;
    }

    let hasInfo = 0;
    res.find((item) => {
      if (item.layout !== 'standingsTabs' && item.layout !== 'h2hTabs') {
        hasInfo = 1;
        return true;
      }
      return false;
    });

    if (!hasInfo) {
      res.push({
        layout: 'empty',
        empty: {},
      });
    }

    return {
      summary: res,
      teams: tmp.teams,
      tabs: tabs,
      tabIndex: panelIndex,
      onTabChange: onPanelChange ?? onTabChange,
      emptyFunction: () => {},
    };
  }, [data, panelIndex, onPanelChange, state, onTabChange]);

  //console.log('BetsMatchStats[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetsMatchStats;
