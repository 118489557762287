import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
// @ts-ignore
import { gsap, TimelineMax, TweenMax, TweenLite, Power3, Power2, Linear, Draggable } from 'gsap/all';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { uniqueId } from 'lodash-es';

import styled from 'styled-components';

import './index.scss';

import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import BgLoop from '../../../../modules/lotto/components/TicketsDetails/assets/loop2.png';
import evBus from '../../../../modules/lotto/utils/evbus';
import { getLottoState } from '../../../../modules/lotto/store/selectors/lottoData';
import BallBg from '../../../../modules/lotto/components/TicketsDetails/assets/ballBg.svg';
import NumberBg from '../../../../modules/lotto/components/TicketsDetails/assets/numberBg.svg';
import BgNextDraw from '../../../../modules/lotto/components/TicketsDetails/assets/bg-next-draw.png';
import LotteryResults from '../../../../modules/lotto/components/LotteryResults';
import TimerExtended from '../../../../modules/lotto/components/TicketsDetails/component/TimerExtended';

import HistoryButtonIcon from './_assets/history_button.svg';
import LottoClockIcon from './_assets/lotto_clock.svg';
import ShowHideIcon from './_assets/show_hide_trailing_icon.svg';

import Flags from '../../../../modules/lotto/components/TicketsDetails/component/flags';
import { RootState } from '@/store';
import { applyLinksValues } from '../../../../page-components/utils/applyLinksValues';

gsap.registerPlugin(Draggable, TweenMax, TimelineMax, TweenLite, Power2, Power3, Linear);

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const useStylesProgress = makeStyles({
  root: {
    position: 'relative',
  },
  top: {
    color: '#fff',
  },
  bottom: {
    color: '#ccc',
    animationDuration: '550ms',
    position: 'absolute',
    // left: '-2px',
    // top: '-2px',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > svg': {
      width: '32px !important',
      height: '32px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  bottomIos: {
    left: '-2px',
    top: '-2px',
  },
});

function DrawingProgress(props: any) {
  const classes = useStylesProgress();

  const userAgent = window.navigator.userAgent;
  let extra = '';
  let isIos = false;

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    extra = classes.bottomIos;
    isIos = true;
  }

  return (
    <div className={`${classes.root} ${props.cls}`}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={`${classes.bottom} ${extra}`}
        size={isIos ? 32 : 29}
        thickness={3}
        {...props}
      />
    </div>
  );
}

const frames = 70; //157; //74;

// @ts-ignore
const useStyles = makeStyles((props: any) => ({
  rooter: {
    // borderRadius: '12px',
    position: 'relative',
    overflow: 'hidden',
    '& *': {
      boxSizing: 'border-box',
    },
  },
  rooterOnDesktop: {
    borderRadius: '12px',
  },

  root: {
    minHeight: '199px',
    background: 'linear-gradient(180deg, #04C4D9 0%, #03A688 100%)',
    backgroundSize: 'cover',
  },
  pr: {
    position: 'relative',
  },
  loop: {
    width: '200px',
    height: '200px',
    backgroundImage: `url(${BgLoop})`,
    margin: '0 auto',
    backgroundSize: 'cover',
    backgroundRepeat: 'initial',
    marginTop: '11px',
    position: 'relative',
    top: '-7px',
    //borderRadius: "50%",
    //backgroundColor: "#0a0963",
    //border: "5px solid #42836f",
  },
  loopAnimate: {
    animation: `animate-drum-loop 2s steps(${frames}, end) infinite`,
  },
  dropper: {
    position: 'relative',
    top: '-26px',
    left: '-7px',
    width: '0',
    height: '0',
    margin: '0 auto',
    //paddingTop: "5px",
    '& > div': {
      borderRadius: '50%',
      width: '15px',
      height: '15px',
      margin: '0 auto',
      backgroundColor: 'transparent',
    },
  },
  ballWrapper: {
    width: '190px',
    minWidth: '190px',
    maxWidth: '190px',
    height: '190px',
    borderRadius: '50%',
    backgroundImage: `url(${BallBg})`,
    backgroundSize: 'cover',
    //background: "radial-gradient(circle at 0% 0%, #fff, #bababa)",
    overflow: 'hidden',
    transform: 'translateZ(0)',
  },
  ball: {
    position: 'relative',
    width: '32px',
    minWidth: '32px',
    maxWidth: '32px',
    height: '32px',
    borderRadius: '50%',
    textAlign: 'center',
    margin: '3px 0',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: '#A2AAB1',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '18px',

    border: '2px solid var(--Surface-on-surface-03, #A2AAB1)',
    background: 'var(--Surface-surface-dim, #F9F9FC)',
  },
  ballWinner: {
    color: '#39A981',
    border: '2px solid #31B07A',
    background: 'rgba(49, 176, 122, 0.10)',
  },
  ballWinnerNext: {
    color: '#fff',
    border: '2px solid #2E3133',
    background: '#707070',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ballWinnerNow: {
    color: '#fff',
    border: '0',
    background: `url(${NumberBg})`,
    fontSize: '16px',
    backgroundSize: 'cover',
    paddingTop: '3px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.57)',
  },
  ballWinnerNowBall: {
    left: '-165px',
    top: '25px',
    width: '140px',
    height: '140px',
    maxWidth: '190px',
    maxHeight: '190px',
    fontSize: '90px',
    paddingTop: '7px',

    color: '#fff',
    border: '0',
    background: `url(${NumberBg})`,
    //fontSize: "16px",
    backgroundSize: 'cover',
    //paddingTop: "6px",
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.57)',
  },
  ballEmpty: {
    opacity: '.15',
    border: '1.5px solid #182424',
    background: '#182424',
    color: '#A7A9AF',
  },
  bs: {
    background: '#fff',
    position: 'relative',
    overflow: 'visible',
    padding: '13px 0 4px 0',
    minHeight: '40px',
  },
  balls: {
    //width: "83px",
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    padding: '0px 9px',
    maxWidth: '360px',
    margin: '0 auto',
    marginTop: '12px',
  },
  ballsBoxShadowLeft: {
    //boxShadow: "-3px 0 6px 0 rgba(0,0,0,.16)",
  },
  m0: {
    margin: '0',
    left: '-100%',
    boxShadow: 'none',
  },
  spinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  animTitle: {
    fontSize: '20px',
    color: '#fff',
    fontWeight: '600',
  },
  animSubtitle: {
    opacity: 0.8,
    fontSize: '12px',
    color: '#fff',
  },
  posAbsolute: {
    position: 'absolute',
    left: 'calc(50% - 95px)',
    bottom: '10px',
    transform: 'scale(0)',
    opacity: 1,
  },
  empty: {
    width: '100%',
    fontSize: '18px',
    color: '#17214d',
    textAlign: 'center',
    marginTop: '30px',
    paddingBottom: '10px',
  },
  muted: {
    fontSize: '12px',
    marginTop: '10px',
  },
  dNone: {
    display: 'none',
  },
  titleWrapper: {
    position: 'absolute',
    top: '5px',
    left: '5px',
    background: 'rgba(0,0,0,.2)',
    padding: '5px',
    borderRadius: '10px',
  },
  drawTitle: {
    // fontSize: '11px',
    // color: '#828EA8',
    textAlign: 'center',
    // margin: '0 0 6px',

    color: '#1A1C1E',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px' /* 100% */,
    letterSpacing: '0.045px',
  },
  hideShowButton: {
    position: 'absolute',
    width: '102px',
    height: '29px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',

    // background: 'linear-gradient(90grad, #00D6B9 0%, #2E7AFF 100%)',
    background: 'unset',
    border: 'none',
    right: '16px',
    top: '8px',

    color: '#969CA3',
    fontSize: '14px',
    fontWeight: '600',
    // borderRadius: '5px',
    cursor: 'pointer',
    lineHeight: '16px',
  },
  hideShowButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',

    '& img': {
      width: '24px',
      height: '24px',
      flexShrink: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  hideShowButtonIconDown: {
    transform: 'rotate(180deg)',
  },

  progressWrapper: {
    position: 'absolute',
    background: '#F5F5F5',
    borderRadius: '5px',
    height: '7px',
    left: '7px',
    top: '2px',
    right: '116px',
  },
  progress: {
    width: '0px',
    height: '7px',
    background: '#0BC1AA',
    borderRadius: '5px',
    transition: 'width .5s',
  },
  progressHide: {
    opacity: 0,
  },
  nextDraw: {
    // background: `url(${BgNextDraw}) no-repeat left top, linear-gradient(180deg, #04081e 0%, #031a89 57%, #162670 76%, #061245 100%)`,
    background: 'linear-gradient(203deg, #0F30B6 12.2%, #432082 100.78%)',
    // height: '86px',
    height: '119px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    flexDirection: 'column',
  },
  nextDrawImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: '-26px',
    bottom: '8px',
    width: '98px',
    height: '101px',
    '& svg': {
      fill: '#24207B',
    },
  },
  nextDrawText: {
    color: '#78809F',
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  timer: {
    marginBottom: '5px',

    '& .title': {
      overflow: 'hidden',
      color: '#FFF',
      textAlign: 'right',
      textOverflow: 'ellipsis',

      fontSize: '40px',
      fontStyle: 'italic',
      fontWeight: '600',
      lineHeight: '120%',
      letterSpacing: '3.6px',
    },
    '& .title-drawing': {
      fontSize: '18px',
      color: '#F2B604',
      fontWeight: '600',
      lineHeight: '28px',
      marginBottom: '5px',
    },
    '& .title.off': {
      opacity: '.2',
      overflow: 'hidden',
      color: '#FFF',
      textAlign: 'right',
      textOverflow: 'ellipsis',
      fontSize: '40px',
      fontStyle: 'italic',
      fontWeight: '600',
      lineHeight: '120%',
      letterSpacing: '3.6px',
    },
    '& .subtitle': {
      overflow: 'hidden',
      color: '#FFF',
      textOverflow: 'ellipsis',
      opacity: '0.6',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    '& .drawing-text': {
      color: '#FFF',
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'italic',
      fontWeight: '600',
      lineHeight: '120%',
      letterSpacing: '1.26px',
      alignSelf: 'stretch',
      marginBottom: '8px',
    },
  },
  historyButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: '16px',
    bottom: '16px',
    textTransform: 'uppercase',

    display: 'flex',
    width: 'var(--Size---Default-btn_default_height, 40px)',
    height: 'var(--Size---Default-btn_default_height, 40px)',
    padding: '12px',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: 'var(--carousel_right_padding, 60px)',
    background: '#00197D',

    '& img': {
      width: '16px',
      height: '16px',
      flexShrink: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

const generateDefaultArray = () => {
  const ret: any = [];
  [...Array(20).keys()].forEach((e) => {
    ret.push({ n: 0, s: 0 });
  });
  return ret;
};

const Presentation = (props: any) => {
  const { events, systems, currentDrawings, t } = props;
  const dataElementContext = React.useContext(DataElementContext);

  const properties = { ...props?.properties };
  if (dataElementContext && properties['links'] && Object.values(properties['links']).length) {
    applyLinksValues(properties, dataElementContext);
  }
  const { systemId, eventId, eventName, onDrawing } = properties;

  const items = events.filter((e: any) => eventName && e.event_name === eventName);
  const system = eventName && typeof systems[eventName] !== 'undefined' ? systems[eventName] : { iso3: 'EUR' };

  const isDesktop = useMediaQuery('(min-width:900px)');
  const classes = useStyles();

  const [historyOpened, setHistoryOpened] = React.useState(false);
  const [started, setStarted] = React.useState(false);
  const [numbers, setNumbers] = React.useState(generateDefaultArray());
  const [showNumbers, setShowNumbers] = React.useState(true);
  const hasNumbers = React.useRef(true);
  const [evInfo, setEvInfo] = React.useState({
    date: '',
    code: '',
  });
  const mounted = React.useRef(false);

  const [ids] = React.useState({
    presentation: uniqueId('presentation-'),
    dropper: uniqueId('dropper-'),
    currentNumber: uniqueId('currentNumber-'),
    currentNumberText: uniqueId('currentNumberText-'),
    progress: uniqueId('progress-'),
    no: uniqueId('no-'),
  });

  const handleShowNumbers = () => {
    setShowNumbers((v) => {
      hasNumbers.current = !v;
      return !v;
    });
  };

  const openHistory = () => {
    setHistoryOpened(true);
  };
  const closeHistory = () => {
    setHistoryOpened(false);
  };

  const tl = React.useRef<any>(null);
  const tl2 = React.useRef<any>(null);

  const handleEvent = React.useCallback(
    (data: any, event: any) => {
      if (event === 'LOTTO_DRAW') {
        //console.log("event", data);
        if (data) {
          if (data.event_name !== eventName) return;

          if (data.status === 'start') {
            setNumbers(generateDefaultArray());
            setStarted(true);
            setEvInfo({
              date: data.event_date,
              code: data.event_code,
            });
            if (typeof onDrawing === 'function') onDrawing(true);
            return;
          } else if (data.status === 'end') {
            if (typeof onDrawing === 'function') onDrawing(false);
            setStarted(false);
          } else if (data.status === 'draw') {
            if (typeof onDrawing === 'function') onDrawing(true);
            if (!started) {
              setNumbers(generateDefaultArray());
              setStarted(true);
              setEvInfo({
                date: data.event_date,
                code: data.event_code,
              });
            }
          }

          const tmp = generateDefaultArray();

          data.drawn.forEach((n: any, i: any) => {
            tmp[i].n = n;
            tmp[i].s = 1;
          });

          const progress = document.getElementById(ids.progress);
          if (progress) {
            const len = (data.current ? 1 : 0) + data.drawn.length;
            const total = data.r ? data.r : 20;
            const percent = (len * 100) / total;
            progress.style.width = `${percent}%`;
          }

          if (window.document.hidden) {
            if (tl.current && typeof tl.current.kill === 'function') {
              tl.current.kill();
              tl.current = null;
            }
            if (tl2.current && typeof tl2.current.kill === 'function') {
              tl2.current.kill();
              tl2.current = null;
            }
            const cn = document.getElementById(ids.currentNumber);
            if (cn) {
              cn.removeAttribute('style');
              cn.style.transform = 'scale(0)';
            }

            if (data.current) {
              tmp[data.drawn.length].n = data.current;
              tmp[data.drawn.length].s = 1;
              setNumbers(tmp);
              return;
            }
            setNumbers(tmp);
            return;
          }

          if (data.current) {
            const cn = document.getElementById(ids.currentNumber);
            const cnt = document.getElementById(ids.currentNumberText);
            if (cnt) cnt.innerHTML = data.current;

            tl2.current = gsap.timeline();
            tl.current = gsap.timeline({
              onCompleteParams: [[...tmp], data.drawn.length, data.current],
              onComplete: (tmp: any, len: any, current: any) => {
                if (tl.current) tl.current.kill();
                if (tl2.current) tl2.current.kill();
                if (cn) {
                  cn.removeAttribute('style');
                  cn.style.transform = 'scale(0)';
                }
                tmp[len].n = current;
                tmp[len].s = 1;
                if (mounted.current) {
                  setNumbers(tmp);
                }
              },
            });

            const presentation = document.getElementById(ids.presentation)?.getBoundingClientRect();
            const dropper = document.getElementById(ids.dropper)?.getBoundingClientRect();

            const offsetX = 88;
            const offsetY = 90;

            if (dropper && presentation) {
              tl.current.fromTo(
                `#${ids.currentNumber}`,
                {
                  top: dropper.top - presentation.top - offsetY,
                  left: dropper.left - presentation.left - offsetX,
                  scale: 0,
                  opacity: 1,
                },
                {
                  scale: 0.1,
                  duration: 0.5,
                },
              );
              tl.current.fromTo(
                `#${ids.currentNumber}`,
                {
                  scale: 0.1,
                  top: dropper.top - presentation.top - offsetY,
                  left: dropper.left - presentation.left - offsetX,
                },
                {
                  top: dropper.top - presentation.top - offsetY + 15,
                  scale: 0.15,
                  duration: 0.3,
                },
              );
              tl.current.to(`#${ids.currentNumber}`, {
                top: dropper.top - presentation.top - offsetY - 85,
                scale: 1.05,
                duration: 1,
                ease: 'elastic',
              });
            }

            if (hasNumbers.current) {
              const nextNo = document.getElementById(`${ids.no}-${data.drawn.length + 1}`)?.getBoundingClientRect();

              if (nextNo && presentation) {
                tl.current.to(`#${ids.currentNumber}`, {
                  bottom: 'auto',
                  top: nextNo.top - presentation.top - offsetY + 9,
                  left: nextNo.left - presentation.left - offsetX + 7,
                  scale: 0.15,
                  duration: 0.5,
                  delay: 1.5,
                  ease: 'power4',
                });
              }
            } else {
              tl.current.fromTo(
                `#${ids.currentNumber}`,
                {
                  opacity: 1,
                },
                {
                  opacity: 0,
                  duration: 0.5,
                  delay: 1.5,
                },
              );
            }

            tl2.current.fromTo(
              `#${ids.currentNumberText}`,
              {
                scale: 1,
                left: '-170px',
              },
              {
                //scale: .7,
                left: 25,
                duration: 0.5,
                delay: 0.8,
              },
            );
            if (hasNumbers.current) {
              tl2.current.fromTo(
                `#${ids.currentNumberText}`,
                {
                  scale: 1,
                },
                {
                  scale: 1.4,
                  duration: 0.5,
                  delay: 2,
                },
              );
            }
          }

          setNumbers(tmp);
        }
      }

      return () => {
        if (tl.current && typeof tl.current.kill === 'function') {
          tl.current.kill();
          tl.current = null;
        }
        if (tl2.current && typeof tl2.current.kill === 'function') {
          tl2.current.kill();
          tl2.current = null;
        }
      };
    },
    [eventName, started, hasNumbers, onDrawing, ids],
  );

  React.useEffect(() => {
    mounted.current = true;
    evBus.on('LOTTO_DRAW', handleEvent);

    for (let i = 0; i < currentDrawings.length; i++) {
      const e = currentDrawings[i];
      if (eventName) {
        if (e.event_name === eventName) {
          handleEvent({ ...e, status: 'start' }, 'LOTTO_DRAW');
          const tmp = generateDefaultArray();

          e.drawn.forEach((n: any, i: any) => {
            tmp[i].n = n;
            tmp[i].s = 1;
          });

          if (
            e.current &&
            e.drawn.length > 0 &&
            e.drawn[e.drawn.length - 1] !== e.current &&
            e.drawn.length < tmp.length
          ) {
            tmp[e.drawn.length].n = e.current;
            tmp[e.drawn.length].s = 1;
          }
          setNumbers(tmp);
          break;
        }
      }
    }

    return () => {
      mounted.current = false;
      evBus.remove('LOTTO_DRAW', handleEvent);
    };
  }, []); // eslint-disable-line

  const leftNumbers = numbers.slice(0, isDesktop ? 20 : 10).map((no: any, i: any) => {
    if (no.n) {
      const rootCls = `${classes.ball} ${no.s === 1 ? classes.ballWinner : ''} ${
        no.s === 2 ? `${classes.ballWinnerNow}` : ''
      }`;
      return (
        <div key={i} id={`${ids.no}-${i + 1}`} className={rootCls}>
          {no.n}
        </div>
      );
    }

    if ((typeof numbers[i - 1] != 'undefined' && numbers[i - 1].n !== 0) || (started && i === 0)) {
      return (
        <div key={i} id={`${ids.no}-${i + 1}`} className={`${classes.ball} ${classes.ballWinnerNext}`}>
          ?
          <DrawingProgress cls={classes.spinner} />
        </div>
      );
    }
    return (
      <div key={i} id={`${ids.no}-${i + 1}`} className={`${classes.ball} ${classes.ballEmpty}`}>
        -
      </div>
    );
  });

  let rightNumbers = null;
  if (!isDesktop) {
    rightNumbers = numbers.slice(10, 20).map((no: any, ix: any) => {
      const i = ix + 10;
      if (no.n) {
        const rootCls = `${classes.ball} ${no.s === 1 ? classes.ballWinner : ''} ${
          no.s === 2 ? `${classes.ballWinnerNow}` : ''
        }`;
        return (
          <div key={i} id={`${ids.no}-${i + 1}`} className={rootCls}>
            {no.n}
          </div>
        );
      }

      if (typeof numbers[i - 1] != 'undefined' && numbers[i - 1].n !== 0) {
        return (
          <div key={i} id={`${ids.no}-${i + 1}`} className={`${classes.ball} ${classes.ballWinnerNext}`}>
            ?
            <DrawingProgress cls={classes.spinner} />
          </div>
        );
      }
      return (
        <div key={i} id={`${ids.no}-${i + 1}`} className={`${classes.ball} ${classes.ballEmpty}`}>
          -
        </div>
      );
    });
  }

  let nextDraw = '';
  let nextDate = '';
  let timeBetween = 0;

  if (!started && items.length) {
    nextDraw = items[0].event_date.split(' ')[1].replace(/:00$/, '');
    nextDate = items[0].event_date;

    if (items.length >= 2) {
      const first = moment(items[0].event_date, 'YYYY-MM-DD HH:mm:ss');
      const next = moment(items[1].event_date, 'YYYY-MM-DD HH:mm:ss');

      timeBetween = next.diff(first, 'seconds');
    }
  }

  const url = typeof Flags[system.iso3] !== 'undefined' ? Flags[system.iso3].center : null;
  const bgLeft = typeof Flags[system.iso3] !== 'undefined' ? Flags[system.iso3].left : null;
  const bgRight = typeof Flags[system.iso3] !== 'undefined' ? Flags[system.iso3].right : null;

  const style = {
    //background: `radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,130,130,0.95) 65%, rgba(255,168,168,0.5) 84%, rgba(255,49,49,0) 100%),url(${UKFlag})`,
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'center',
    width: '360px',
    height: '211px',
    minWidth: '360px',
    flex: '1 1 0',
    margin: '0 auto',
  };

  const styleSLeft = {
    backgroundImage: bgLeft,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    flex: '1 1 auto',
    width: 'calc(100% + 1px)',
    height: '211px',
    marginRight: '-1px',
  };
  const styleSRight = {
    backgroundImage: bgRight,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    flex: '1 1 auto',
    width: 'calc(100% + 1px)',
    height: '211px',
    marginLeft: '-1px',
  };

  const contextValue = React.useMemo(() => {
    return {
      eventName,
      historyOpened,
      onToggleHistory: closeHistory,
    };
  }, [historyOpened, closeHistory, eventName]);

  //console.log('LottoDrawing[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <div
        className={isDesktop ? `${classes.rooter} ${classes.rooterOnDesktop}` : classes.rooter}
        id={ids.presentation}
      >
        {started && (
          <React.Fragment>
            <div className="d-flex flex-row flex-nowrap align-items-center justify-between">
              <div style={styleSLeft}></div>
              <div className={classes.root} style={style}>
                <div className="d-flex flex-row flex-nowrap align-items-center justify-content-around">
                  <div>
                    <div className={`${classes.loop} ${started ? classes.loopAnimate : classes.loopAnimate}`}></div>
                    <div>
                      <div className={`${classes.dropper}`}>
                        <div id={ids.dropper}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={styleSRight}></div>
            </div>
            <div className={classes.bs}>
              <div className={classes.drawTitle}>
                {t('Draw')} {moment(evInfo.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}
              </div>
              {showNumbers && <div className={classes.balls}>{leftNumbers}</div>}
              {showNumbers && !isDesktop && (
                <div className={`${classes.balls} ${classes.ballsBoxShadowLeft}`}>{rightNumbers}</div>
              )}
              <div className={`${classes.progressWrapper} ${showNumbers ? classes.progressHide : ''}`}>
                <div id={ids.progress} className={classes.progress}></div>
              </div>
              {/* eslint-disable-next-line */}
              <div className={classes.hideShowButton} onClick={handleShowNumbers}>
                {showNumbers ? t('Hide') : t('Show')}
                <div className={classes.hideShowButtonIcon}>
                  <img src={ShowHideIcon} alt="history" className={showNumbers ? '' : classes.hideShowButtonIconDown} />
                </div>
              </div>
            </div>
            <div id={ids.currentNumber} className={`${classes.ballWrapper} ${classes.posAbsolute}`}>
              <div
                id={ids.currentNumberText}
                className={`${classes.ball} ${classes.ballWinnerNowBall} ${classes.m0}`}
              ></div>
            </div>
          </React.Fragment>
        )}
        {!started && nextDraw === '' && (
          <div className={classes.nextDraw}>
            <div className={classes.nextDrawText}>{t('No current lottery draw')}</div>
          </div>
        )}
        {!started && nextDraw !== '' && (
          <div className={classes.nextDraw}>
            <TimerExtended className={classes.timer} date={nextDate} extended={true} timeBetween={timeBetween} />
            {/* eslint-disable-next-line */}
            <div className={classes.historyButton} onClick={openHistory}>
              {/* {t('historic')} */}
              <img src={HistoryButtonIcon} alt="history" />
            </div>

            <div className={classes.nextDrawImage}>
              <img src={LottoClockIcon} alt="clock" />
            </div>
          </div>
        )}
        {historyOpened && (
          <LotteryResults eventName={eventName} events={items} open={historyOpened} onClose={closeHistory} />
        )}
      </div>
    </ModuleElementDiv>
  );
};

const mapStateToProps = (state: RootState, props: any) => {
  const lst = getLottoState(state);
  return {
    events: lst.lottoEvents.items,
    systems: lst.lottoEvents.systemsMap,
    currentDrawings: lst.currentDrawings.items,
  };
};

export default connect(mapStateToProps)(withTranslation()(Presentation));
