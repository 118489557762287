// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import mainEventBus from '@/utils/evbus';
import axios from 'axios';

import { RootState } from '../index';
import { reloadWalletsData } from '../../modules/casino/store/actions/games';

const DEBUG = true;

export interface ShopItemReducer {
  buying: boolean;
  bought: boolean;
  error: any;
}

type FetchConfigProps = {
  id: string;
  name: string;
};

type FetchConfigResult = {
  data: any;
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

const apiUrl = window.config.shopApiUrl;

export const buyShopItem = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'shopItem/buy',
  async (props, { rejectWithValue, getState, dispatch }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.get(`${apiUrl}/shop-item/buy/${props.id}`, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        dispatch(reloadWalletsData());
        return { data: { name: props.name }, success: true };
      }

      return rejectWithValue({
        error: "Couldn't buy shop item",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

// mainEventBus.emit("NOTIFICATION", {message: `You have bought {{VALUE}}`, placeholders: {VALUE: 'Small pack'}, progressBar:false, type: 'success'})

export const shopItemSlice = createSlice({
  name: 'shopItem',
  initialState: <ShopItemReducer>{
    buying: false,
    bought: false,
    error: null,
  },
  reducers: {
    clearState: (state, action) => {
      state.buying = false;
      state.bought = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(buyShopItem.fulfilled, (state, action) => {
        state.buying = false;
        state.bought = true;

        mainEventBus.emit('NOTIFICATION', {
          message: 'You have bought "{{VALUE}}" successfully!',
          placeholders: { VALUE: action.payload.data.name },
          progressBar: false,
          type: 'success',
        });

        DEBUG && console.timeEnd('buyShopItem.query');
        DEBUG && console.log('buyShopItem.fulfilled', action.payload);
      })
      .addCase(buyShopItem.pending, (state) => {
        DEBUG && console.time('buyShopItem.query');
        state.buying = true;
      })
      .addCase(buyShopItem.rejected, (state, action) => {
        state.buying = false;
        state.error = action.payload?.error;

        mainEventBus.emit('NOTIFICATION', {
          message: action.payload?.error,
          progressBar: false,
          type: 'error',
        });

        DEBUG && console.log('buyShopItem.rejected', action.payload);
      });
  },
});

export const { clearState } = shopItemSlice.actions;

export default shopItemSlice.reducer;
