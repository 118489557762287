import React from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';

import { useAppSelector } from '../../store';
import getExtraMeta from './extra-meta';

type PageMetaTags = {
  meta?: {
    [key: string]: any;
  };
};

const PageMetaTags = (props: PageMetaTags) => {
  const { meta } = props;
  const config = useAppSelector((state) => state.templatesConfig);

  const extra = getExtraMeta(meta);

  const extraMeta = React.useMemo(() => {
    return {
      ...meta,
      ...extra,
    };
  }, [extra, meta]);

  React.useEffect(() => {
    document.documentElement.classList.remove('ligh-mode', 'dark-mode');

    if (meta && meta['themeMode'] != null && meta['themeMode'] && meta['themeMode'] !== config.themeMode) {
      if (meta['themeMode'] === 'dark') {
        document.documentElement.classList.add('dark-mode');
      } else {
        document.documentElement.classList.add('light-mode');
      }
    } else {
      document.documentElement.classList.remove('dark-mode');
      document.documentElement.classList.remove('light-mode');
    }

    const bodyClass = meta?.['className'] ?? '';

    if (bodyClass) {
      document.body.classList.add(...bodyClass.split([' ']));
    }

    return () => {
      if (bodyClass) {
        document.body.classList.remove(...bodyClass.split([' ']));
      }
    };
  }, [meta, config]);

  if (!meta) return null;

  let keys = Object.keys(extraMeta);
  if (!keys || !keys.length) return null;
  keys = keys.filter((key: any) => ['pathname', 'priority', 'lastmod', 'changefreq'].indexOf(key) === -1);

  const list = keys.map((key: any, i) => {
    if (!extraMeta[key]) return null;
    if (key === 'themeMode' || key === 'className') return null;

    if (key === 'canonical') return <link key={key} rel={key} href={extraMeta[key]} />;
    if (key === 'title') return <title key={key}>{extraMeta[key]}</title>;
    if (key === 'description' || key === 'keywords' || key === 'thumbnail')
      return <meta key={key} name={key} content={extraMeta[key]} />;

    return <meta key={key} property={key} content={extraMeta[key]} />;
  });

  if (typeof extraMeta['canonical'] === 'undefined' || !extraMeta['canonical']) {
    list.push(<link key={'canonical'} rel={'canonical'} href={window.location.href} />);
  }
  if (typeof extraMeta['og:url'] === 'undefined' || !extraMeta['og:url']) {
    list.push(<link key={'og:url'} rel={'og:url'} href={window.location.href} />);
  }
  if (typeof extraMeta['twitter:url'] === 'undefined' || !extraMeta['twitter:url']) {
    list.push(<link key={'twitter:url'} rel={'twitter:url'} href={window.location.href} />);
  }

  if (list.length === 0) return null;

  return <Helmet>{list}</Helmet>;
};

export default PageMetaTags;
