/*
    expected input properties:
numbers:            array of integer            - numbers picked by player
systems:            array of integer            - winning combo types picked by player
r:                  integer                     - event count of winning/drawn numbers
n:                  integer                     - event highest drawable number
odds:               array of number             - event winning odds per combo length
custom_odds:        array of [string, number]   - overrides to normal odds, element: [csv rule with wildcards, custom odds value]
stake_amount:       number                      - actual amount bet by player, after shop taxes
max_winning:        number                      - maximum allowed winning amount per ticket regardless of odds
drawn_numbers:      array of numbers            - event result list of numbers drawn

    return response properties:
lines_played:       integer                     - total win-eligible combinations on ticket
system_lines_won:   array of [integer, integer] - element: [system played, number of lines(combos) that won]
system_win_amount:  array of [integer, number]  - element: [system played, amount won (rounded @ 2 decimals) in system]
total_win_amount:   number                      - can be lower than sum of system_win_amount (rounded @ 2 decimals)
    OPTIONAL response property (sent right now):
success:            boolean                     - does input data pass sanity checks succesfully? if not, results may be wrong
*/

const computeWinningTicket = ticketdata => {

    // math utility functions    
    function factorial(x) {
        if (x <= 1) return 1;
        let res = 1;
        for (let i = 2; i <= x; i += 1) res = res * i;
        return res;
    }
    function combinations(n, k) {
        return factorial(n) / (factorial(k) * factorial(n - k));
    }

    // init input-derived data
    const numbers = new Set(ticketdata.numbers);
    const systems = new Set(ticketdata.systems);
    const played_systems = ticketdata.systems.length;
    const drawn_numbers = new Set(ticketdata.drawn_numbers);

    // init blanks for return response
    let lines_played = 0;
    const system_lines_won = [];
    const system_win_amount = [];
    let total_win_amount = 0;

    // if input data is completely valid, then all of the following hold true:
    // won_numbers.size <= min(ticketdata.r, max-systems-value) <= numbers.size
    const won_numbers = new Set([...drawn_numbers].filter(x => numbers.has(x)));

    // above conditions can be inserted as sanity checks (optionally, comment-out section)
    let success = false;
    if (won_numbers.size <= Math.min(ticketdata.r, ticketdata.systems[systems.size - 1])
        && Math.min(ticketdata.r, ticketdata.systems[systems.size - 1]) <= numbers.size) { success = true }

    // compute total played/won lines and stake value per played line
    for (let s of systems) {
        lines_played += combinations(numbers.size, s); // numbers.size is always >= s if input is ok
        if (won_numbers.size < s) {                    // won_numbers.size can easily be < s
            system_lines_won.push([s, 0])
        } else {
            system_lines_won.push([s, combinations(won_numbers.size, s)])
        }
    }
    const stake_per_line = +ticketdata.stake_amount / lines_played;

    // init blank for custom odds differences from regular odds per system played
    // element: [system #, custom odds count, total sum of custom odds]
    const system_deltavs = new Array(played_systems);
    for (let i = 0; i < played_systems; i += 1)
        system_deltavs[i] = [+ticketdata.systems[i], 0, 0];

    // compute into system_deltavs the custom odds that do apply to picked numbers that won (were drawn)
    const exceptionset = new Set();
    if (ticketdata.custom_odds) for (let rule of ticketdata.custom_odds) parseRule(rule);
    function parseRule(rule) {
        const splitrule = rule[0].split(",");
        if (!systems.has(splitrule.length)) return; // pointless to process rule if rule length does not apply to any played systems
        splitrule.forEach((element, index) => {
            if (element === "*") { // prepare for recursive call if we have a wildcard in rule
                let tempmin = 0;
                if (index > 0) tempmin = +splitrule[index - 1];
                let tempmax = ticketdata.n;
                if (index <= splitrule.length - 2 && splitrule[index + 1] !== "*")
                    tempmax = +splitrule[index + 1];
                for (let i = tempmin + 1; i < tempmax; i += 1) {
                    if (won_numbers.has(i)) { // pointless to start recursion if number hasn't won
                        let temprule = [...splitrule];
                        temprule[index] = `${i}`;
                        parseRule([`${temprule.join(",")}`, rule[1]]);
                    }
                }
            } else if (!won_numbers.has(+element)) return; // pointless to process rule if number hasn't won
        });
        // if we get here all we have in initial rule are winners but we might still have wildcards on recursive return
        if (rule[0].indexOf("*") === -1) { // so skip processing for any rules with wildcards still in them
            if (!exceptionset.has(rule[0])) { // don't double-process a combo, only first matching rule aplies
                exceptionset.add(rule[0]);
                for (let i = 0; i < played_systems; i += 1) { // find proper system_deltavs index and increment relevant info
                    if (system_deltavs[i][0] === splitrule.length) {
                        system_deltavs[i][1]++;
                        system_deltavs[i][2] += rule[1];
                    }
                }
            }
        }
    }

    // compute actual winnings per system (clamping to max allowed payout value, optionally per-system, always in the end)
    for (let i = 0; i < played_systems; i += 1) {
        const s = system_deltavs[i][0]; // played system being computed
        const coc = system_deltavs[i][1]; // custom odds count for system
        const rotv = (system_lines_won[i][1] - coc) * ticketdata.odds[s - 1]; // regular odds total value for system
        const cotv = system_deltavs[i][2]; // custom odds total value for system
        let swa = Math.round((rotv + cotv) * stake_per_line * 100) / 100;
        if (swa > ticketdata.max_winning) swa = ticketdata.max_winning; // comment out if NOT trimming here but just the total
        system_win_amount.push([s, swa]);
        total_win_amount += swa;
    }
    if (total_win_amount > ticketdata.max_winning) total_win_amount = ticketdata.max_winning;

    // prepare and send response
    const response = {};
    response.success = success; // optional, comment out if unnecessary
    response.lines_played = lines_played;
    response.system_lines_won = system_lines_won;
    response.system_win_amount = system_win_amount;
    response.total_win_amount = Math.round(total_win_amount * 100) / 100;
    return response;
};

export default computeWinningTicket;
