import store, { RootState } from '../../store';

import transferPropertiesToComponent from '../../page-components/ComponentData/transferPropertiesToComponent';

type Map = {
  [id: string]: any;
};

type parseDataSourcesProps = {
  craftState: string | Map;
};

const parseDataSources = (props: parseDataSourcesProps) => {
  const { craftState } = props;
  const craftStateJson = typeof craftState === 'string' ? JSON.parse(craftState) : craftState;
  const state: RootState = store.getState();

  const dataSources: Map = {};
  const dataElements: Map = {};

  craftStateJson &&
    Object.keys(craftStateJson).forEach((nodeId) => {
      const node = craftStateJson[nodeId];

      if (node.type === 'ComponentData' && node.props?.properties?.componentId) {
        const componentId = node.props.properties.componentId;
        const componentDataId = node.props.properties.componentDataId;

        if (componentId && state.templatesConfig?.components[componentId] != null && componentDataId) {
          const projectData = state.templatesConfig?.components[componentId].projectData;
          const cdProperties =
            componentDataId && state.templatesConfig?.componentsData[componentDataId] != null
              ? componentDataId && state.templatesConfig?.componentsData[componentDataId]
              : {};

          const setCompontsDefaultsValue =
            state.templatesConfig?.setComponentsDefaults[componentId] != null
              ? state.templatesConfig?.setComponentsDefaults[componentId]
              : {};

          const setCompontsDataDefaultsValue =
            state.templatesConfig?.setComponentsDataDefaults[`${componentId}|${componentDataId}`] != null
              ? state.templatesConfig?.setComponentsDataDefaults[`${componentId}|${componentDataId}`]
              : {};

          const finalCDProperties = { ...cdProperties, ...setCompontsDataDefaultsValue };

          const data = JSON.parse(projectData);

          if (setCompontsDefaultsValue && Object.keys(setCompontsDefaultsValue).length)
            transferPropertiesToComponent(data, setCompontsDefaultsValue);
          if (finalCDProperties && Object.keys(finalCDProperties).length)
            transferPropertiesToComponent(data, finalCDProperties);

          const [ds, de] = parseDataSources({ craftState: data });
          if (ds && Object.keys(ds).length) {
            ds.forEach((k) => {
              dataSources[k] = true;
            });
          }
          if (de && Object.keys(de).length) {
            de.forEach((k) => {
              dataElements[k] = true;
            });
          }
        }
      }

      if (node.type === 'ComponentRef' && node.props?.properties?.componentId) {
        const componentId = node.props.properties.componentId;

        if (componentId && state.templatesConfig?.components[componentId] != null) {
          const projectData = state.templatesConfig?.components[componentId].projectData;

          const setCompontsDefaultsValue =
            state.templatesConfig?.setComponentsDefaults[componentId] != null
              ? state.templatesConfig?.setComponentsDefaults[componentId]
              : {};

          const data = JSON.parse(projectData);

          if (setCompontsDefaultsValue && Object.keys(setCompontsDefaultsValue).length)
            transferPropertiesToComponent(data, setCompontsDefaultsValue);

          const [ds, de] = parseDataSources({ craftState: data });
          if (ds && Object.keys(ds).length) {
            ds.forEach((k) => {
              dataSources[k] = true;
            });
          }
          if (de && Object.keys(de).length) {
            de.forEach((k) => {
              dataElements[k] = true;
            });
          }
        }
      }

      if (node.props?.properties?.dsId) {
        dataSources[node.props?.properties?.dsId] = true;
      }
      if (node.props?.properties?.deId) {
        dataElements[node.props?.properties?.deId] = true;
      }

      if (node.props?.properties?.componentDataId) {
        const cdId = node.props?.properties?.componentDataId;
        if (state.templatesConfig?.componentsData[cdId] != null) {
          const keys = Object.keys(state.templatesConfig?.componentsData[cdId]);
          keys.forEach((k: any) => {
            if (k && k.startsWith('dsId[')) {
              const dsId = state.templatesConfig.componentsData[cdId][k] as string;
              if (dsId) dataSources[dsId] = true;
            } else if (k && k.startsWith('deId[')) {
              const deId = state.templatesConfig.componentsData[cdId][k] as string;
              if (deId) dataElements[deId] = true;
            }
          });
        }
      }
    });

  return [Object.keys(dataSources), Object.keys(dataElements)];
};

export default parseDataSources;
