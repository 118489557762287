import { navSportsConstants } from '../actions/constants';
import { produce } from 'immer';
import { digitainConfig } from '../../api/config/digitain';

const cfg = digitainConfig();

const initialState = {
  home: '0',
  prematch: cfg.defaultSportId,
  live: '1',
  prematchTopLeagues: cfg.defaultSportId,
};

const navSportsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case navSportsConstants.SAVE: {
        //console.log("navsport save", action);
        draft[action.navbar] = action.index;
        break;
      }
      default:
        break;
    }
  });

export default navSportsReducer;
