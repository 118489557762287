import React from 'react';
import styled from 'styled-components';

const StateListItemDiv = styled.div((props) => props.$styleText);

export const StateListItem = (componentProps) => {
  return (
    <StateListItemDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText} style={componentProps.style}>
      {componentProps.children}
    </StateListItemDiv>
  );
};
