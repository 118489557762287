import React from 'react';
import { withTranslation } from "react-i18next";
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import LockIcon from '@material-ui/icons/Lock';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from '@material-ui/core/CircularProgress';

import "./GratuitiesDaily.scss";
import { getWinnerFunState } from "../../store/selectors/winnerFunData";
import { winCoinsCollectDaily, winCoinsSystemsLoad } from "../../store/actions/wincoins";
import { chooseTextLanguage, styleToObj, choseImage } from "../../utils/functions";

import LockOpenSVG from "../../assets/lock-open@3x.png";
import SimpleTimer from "../SimpleTimer/SimpleTimer";
import moment from 'moment';

const pathBetweenPoints = (elem, p1x, p1y, p2x, p2y) => {
  // mid-point of line:
  let mpx = (p2x + p1x) * 0.5;
  let mpy = (p2y + p1y) * 0.5;

  // angle of perpendicular to line:
  let theta = Math.atan2(p2y - p1y, p2x - p1x) - Math.PI / 2;

  // distance of control point from mid-point of line:
  let offset = 40;

  // location of control point:
  let c1x = mpx + (offset - 10) * Math.cos(theta);
  let c1y = mpy + offset * Math.sin(theta);

  // construct the command to draw a quadratic curve
  let curve = "M" + p1x + " " + p1y + " Q " + c1x + " " + c1y + " " + p2x + " " + p2y;
  let curveElement = document.getElementById(elem);
  curveElement.setAttribute("d", curve);
};

const calculatePaths = (svg) => {
  try {
    const days = document.querySelector(".wf-days");
    const day1 = document.querySelector(".wf-d-1");
    const day2 = document.querySelector(".wf-d-2");
    const day3 = document.querySelector(".wf-d-3");
    const day4 = document.querySelector(".wf-d-4");
    const day5 = document.querySelector(".wf-d-5");
    if (days && day1 && day2 && day3 && day4 && day5) {
      const daysBB = days.getBoundingClientRect();
      const day1BB = day1.getBoundingClientRect();
      const day2BB = day2.getBoundingClientRect();
      const day3BB = day3.getBoundingClientRect();
      const day4BB = day4.getBoundingClientRect();
      const day5BB = day5.getBoundingClientRect();

      pathBetweenPoints("path1", day2BB.left - daysBB.left + (day2BB.width / 2), day2BB.bottom - daysBB.top, day1BB.left - daysBB.left + (day1BB.width / 2), day1BB.bottom - daysBB.top);
      pathBetweenPoints("path2", day2BB.left - daysBB.left + (day2BB.width / 2), day2BB.top - daysBB.top, day3BB.left - daysBB.left + (day3BB.width / 2), day2BB.top - daysBB.top);
      pathBetweenPoints("path3", day4BB.left - daysBB.left + (day4BB.width / 2), day4BB.bottom - daysBB.top, day3BB.left - daysBB.left + (day3BB.width / 2), day3BB.bottom - daysBB.top);
      pathBetweenPoints("path4", day4BB.left - daysBB.left + (day4BB.width / 2), day4BB.top - daysBB.top, day5BB.left - daysBB.left + (day5BB.width / 2), day5BB.top - daysBB.top);

      svg.setAttribute("viewBox", `0 0 ${daysBB.width} ${daysBB.height}`)
    }
  } catch (err) { }
};

const noop = () => { };

const GratuitiesDaily = (props) => {
  const { t, i18n, walletTotal, opened, onCollected, origData, winCoinsCollectDaily, winCoinsSystemsLoad } = props;

  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const imageType = isDesktop1024 ? "desktop" : "mobile";

  const [data, setData] = React.useState(null);
  const [days, setDays] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (origData) {

      let defDays = [];
      let progress = 0;

      if (origData && origData.coin_system && origData.coin_system.prizes) {
        let selectedCurrent = false;

        let last_collect_index = origData.last_collect_index;
        if (last_collect_index + 1 >= origData.coin_system.prizes.length) {
          last_collect_index = -1;
        }

        origData.coin_system.prizes.forEach((p, index) => {
          const prize = { amount: parseFloat(p.amount) };
          if (index <= last_collect_index) {
            prize.selected = true;
          } else {
            if (!selectedCurrent) {
              if (origData.amount !== 0 && origData.amount === parseFloat(p.amount)) {
                prize.current = true;
              } else {
                prize.next = true;

                const ed = moment(origData.next_collect_time, "x");
                const st = ed.subtract(1, "day");
                const diff = moment().diff(st, "hours");
                progress = diff * 100 / 24;

              }
              selectedCurrent = true;
            }
          }
          defDays.push(prize);
        });
      }

      setData(origData);
      setDays(defDays);
      setProgress(progress);
    }
  }, [origData]);

  const svgRef = React.useRef(null);
  React.useEffect(() => {
    if (svgRef.current) {
      calculatePaths(svgRef.current);
    }
  }, [svgRef, days]);

  React.useEffect(() => {
    if (svgRef.current) {
      calculatePaths(svgRef.current);
    }
  }, [opened]);

  const onCollect = () => {
    setLoading(true);
    try {
      document.querySelector(".wf-current").classList.add("wf-animate");
    } catch (err) { }

    setTimeout(() => {

      setDays(v => {
        const newDays = JSON.parse(JSON.stringify(v));
        const index = newDays.findIndex(e => e.current === true);
        let amount = newDays[index].amount;

        setTimeout(() => {
          (function (amount, walletTotal) {
            winCoinsCollectDaily({
              id: data.coin_system.id,
              cb: (success) => {
                if (success) {
                  onCollected(amount, walletTotal);
                }
                setLoading(false);
              }
            });
          })(amount, walletTotal);
        }, 0);
        return v;
      });
    }, 500);
  };
  const handleExpired = () => {
    setTimeout(() => {
      winCoinsSystemsLoad();
    }, 1000);
  };

  const buildFooterText = () => {
    let text = t("Collect WinCoins daily for free.");

    let canCollect = false;
    days.forEach(d => {
      if (d.current) canCollect = true;
    })

    return <React.Fragment>
      <div className="wf-text">{text}</div>
      {canCollect && <div className={`wf-button ${loading ? 'wf-loading' : ''}`} onClick={onCollect}>{t("COLLECT")}<div className={`wf-loader`}><CircularProgress /></div></div>}
      {!canCollect && <div className="wf-timer"><SimpleTimer onExpired={handleExpired} date={moment(data.next_collect_time, "x").format("YYYY-MM-DD HH:mm:ss")} render={(state) => (<span>{state.hours.value}:{state.mins.value}:{state.secs.value}</span>)} /></div>}
    </React.Fragment>
  };

  if (days.length === 0) return null;

  let style = {};
  if (data.coin_system.meta.background) {
    style = styleToObj(data.coin_system.meta.background.style);
    style.backgroundImage = `url("${choseImage(data.coin_system.meta.background, imageType)}")`;
  }

  return (
    <div className="wf-gratuities-daily">
      <div className="wf-header">
        <div className="wf-icon" style={styleToObj(data.coin_system.meta.headerIcon.style)}>
          <img src={choseImage(data.coin_system.meta.headerIcon, imageType)} alt="header icon" />
        </div>
        <div className="wf-title" style={styleToObj(data.coin_system.meta.headerTitle.style)}>{chooseTextLanguage(data.coin_system.meta.headerTitle.text, i18n.language)}</div>
      </div>
      <div className="wf-body" style={style}>
        <div>
          <div className="wf-days">
            <svg fill="none" className="wf-paths" height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none" ref={svgRef}>
              {days.length > 1 && <path id="path1" d="M0 0" stroke={days[1].selected || days[1].current ? "#9AFF50" : "#C4CAFF"} strokeWidth="3" strokeDasharray="3 8" strokeLinecap="round" fill="transparent"></path>}
              {days.length > 2 && <path id="path2" d="M0 0" stroke={days[2].selected || days[2].current ? "#9AFF50" : "#C4CAFF"} strokeWidth="3" strokeDasharray="3 8" strokeLinecap="round" fill="transparent"></path>}
              {days.length > 3 && <path id="path3" d="M0 0" stroke={days[3].selected || days[3].current ? "#9AFF50" : "#C4CAFF"} strokeWidth="3" strokeDasharray="3 8" strokeLinecap="round" fill="transparent"></path>}
              {days.length > 4 && <path id="path4" d="M0 0" stroke={days[4].selected || days[4].current ? "#9AFF50" : "#C4CAFF"} strokeWidth="3" strokeDasharray="3 8" strokeLinecap="round" fill="transparent"></path>}
            </svg>
            {days.map((day, i) => {
              let buttons = {
                styleCurrent: {},
                styleLocked: {},
                styleSelected: {},
                styleNext: {},
              };

              if (data && data.coin_system && data.coin_system.meta && data.coin_system.meta.buttons) {
                const bs = data.coin_system.meta.buttons;
                buttons = {
                  styleCurrent: styleToObj(bs.styleCurrent),
                  styleLocked: styleToObj(bs.styleLocked),
                  styleSelected: styleToObj(bs.styleSelected),
                  styleNext: styleToObj(bs.styleNext),
                };
              }

              let style = buttons.styleLocked;

              if (day.next) {
                style = buttons.styleCurrent;
              } else if (day.styleNext) {
                style = buttons.styleCurrent;
              } else if (day.selected) {
                style = buttons.styleSelected;
              }

              return (
                <div
                  key={`day_${i}`}
                  className={`wf-day ${day.selected ? "wf-selected" : ""} ${day.current ? "wf-current" : ""} ${day.next ? "wf-next" : ""} wf-d-${i + 1}`}
                  onClick={day.current ? onCollect : noop}
                  style={style}
                >
                  {day.selected && <div className="wf-icon"><CheckIcon /></div>}
                  {!day.selected && !day.current && <div className="wf-lock-icon"><LockIcon /></div>}
                  {!day.selected && day.current && <div className="wf-lock-open-icon">
                    <img src={LockOpenSVG} alt="lock open icon" />
                  </div>}
                  <div className="wf-title">{day.amount}</div>
                  {day.next && <LinearProgress className={"wf-progress"} variant="determinate" value={progress} />}
                </div>
              );
            })}
          </div>
          <div className="wf-footer">
            {buildFooterText()}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);
  return {
    walletTotal: wfs && wfs.wallet && wfs.wallet.total ? wfs.wallet.total : 0
  };
};

const mapActions = {
  winCoinsCollectDaily,
  winCoinsSystemsLoad
};

export default withTranslation()(connect(mapStateToProps, mapActions)(GratuitiesDaily));