import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';

import * as actionTypes from '../../../casino/store/actions/actionTypes';
import getStore from './../index';
import { walletConstants } from './../actions/constants';
import { walletReceived, resetWallet } from './../actions/wallet';
import { activeWinnerFunCampaigns } from '../../../bets/utils/winnerFunUtils';
import { getBetsState } from '../../../bets/store/selectors/betData';

export const winnerFunWalletAPIUrl = window.config.appApiUrl;

export function* requestWalletSaga(action) {
  const store = getStore();
  const state = store.getState();
  const bts = getBetsState(state);

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }

  let winnerData = {};

  try {
    winnerData = activeWinnerFunCampaigns(bts.config.winnerFunCampaigns);
  } catch (err) {
    console.log('requestWinnerFunWalletSaga[err]:', err);
    return;
  }

  if (!winnerData.season_id) return;

  try {
    const response = yield axios.get(winnerFunWalletAPIUrl + '/fun-player-wallet', {
      params: {
        mainSeasonId: winnerData.season_id,
      },
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    });

    if (!response || !response.data) {
      throw new Error('[ERROR] Wallet response is empty!');
    }

    //console.log("requestWinnerFunWalletSaga[response]:", response.data);

    yield put(walletReceived(response.data));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* authRequestWalletSaga(action) {
  if (typeof action['authType'] === 'undefined') {
    return;
  }

  if (!['user', 'token'].includes(action.authType)) {
    return;
  }

  if (window.location.pathname.indexOf('/winnerfun') === -1) return;

  yield requestWalletSaga();
}

export function* resetWalletSaga(action) {
  yield put(resetWallet());
}

export default function* templateInit() {
  yield takeEvery(walletConstants.REQUEST_WALLET, requestWalletSaga);
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, authRequestWalletSaga);
  yield takeEvery('WINNER_FUN_CONFIG_LOADED', requestWalletSaga);
  yield takeEvery(actionTypes.authentication.CLEAR, resetWalletSaga);
}
