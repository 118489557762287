import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { getMultiBetData } from '../../utils/hooks';

import './index.scss';

type MultiBetProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const MultiBet = (componentProps: MultiBetProps) => {
  const contextValue = getMultiBetData();

  //console.log('MultiBet[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default MultiBet;
