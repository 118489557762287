import React from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../../../../store';
import { betsSlipClear } from '../../../../../modules/bets/store/actions/betsSlip';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const DeleteBets = (componentProps: any) => {
  const dispatch = useAppDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const toggleDeleteDialog = () => {
    setOpenDeleteDialog((v) => !v);
  };
  const liveBets = useAppSelector((state) => state.bets.betsSlip.tickets?.[0].live?.selected?.length);
  const prematchBets = useAppSelector((state) => state.bets.betsSlip.tickets?.[0].prematch?.selected?.length);

  const contextValue = React.useMemo(() => {
    return {
      totalBets: (liveBets ?? 0) + (prematchBets ?? 0),
      openDeleteDialog,
      toggleDeleteDialog,
      handleDeleteButton: () => {
        dispatch(betsSlipClear());
        toggleDeleteDialog();
      },
    };
  }, [openDeleteDialog, toggleDeleteDialog, liveBets, prematchBets]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

// @ts-ignore
export default DeleteBets;
