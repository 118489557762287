import { takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { ticketsConstants } from './../actions/constants';
import getStore from './../index';

export const winnerFunAPIUrl = window.config.betsApiUrl;

export function* requestTicketsSaga(action) {
  const store = getStore();
  const state = store.getState();

  let data = action.data;

  let postParams = {
    player_id: data.playerId,
  };

  if (data.type === 'season') {
    postParams.season_id = data.seasonId;
  } else if (data.type === 'event') {
    postParams.season_id = data.seasonId;
    postParams.event_id = data.eventId;
  }

  postParams.offset = data.page * 5;

  const cb =
    typeof data.cb === 'function'
      ? data.cb
      : () => {
          //console.log("requestTicketsSaga: CALLBACK NOT SET")
        };

  if (!postParams.season_id || !postParams.player_id) return;

  try {
    const response = yield axios.post(winnerFunAPIUrl + '/winner-fun/player/winning-tickets', postParams, {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    });

    if (!response || !response.data) {
      throw new Error('requestTicketsSaga[ERROR]: response is empty!');
    }

    //console.log("requestTicketsSaga[response]:", response.data);

    yield cb(response.data);
    return;
  } catch (error) {
    console.log(error);
  }
  yield cb(null);
}

export default function* templateInit() {
  yield takeEvery(ticketsConstants.REQUEST_TICKETS, requestTicketsSaga);
}
