import React from 'react';
import { useAppSelector, useAppDispatch } from '../../store';

import craftJsParser from '../utils/craftJsParser';

interface Props {
  name?: string;
}

const Popups: React.FC<Props> = (props) => {
  const popups = useAppSelector((state) => state.templatesConfig.popups);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user') as boolean,
  );

  const toRender = React.useMemo(() => {
    const ret: any = [];
    if (popups) {
      Object.keys(popups).forEach((key: any) => {
        const popup = popups[key];
        try {
          const projectData = JSON.parse(popup.projectData);

          if (projectData && projectData['ROOT']) {
            projectData['ROOT'].props.styleText = '';
          }

          const content = craftJsParser({
            craftState: projectData,
            rootNodeId: 'ROOT',
            pageId: key,
            pageType: 'popups',
            options: {
              authenticated,
            },
            withProvider: true,
            rootKey: `popup-${key}`,
          });

          if (popup && popup.a) {
            if (authenticated) {
              ret.push(content);
            }
          } else {
            ret.push(content);
          }
        } catch (err) {}
      });
    }
    return ret;
  }, [popups, authenticated]);

  return toRender;
};

export default Popups;
