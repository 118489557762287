import * as actionTypes from './actionTypes';

export const getMarketingOffer = (moid, productId) => {
  return {
    type: actionTypes.marketingOffer.GET,
    moid: moid,
    productId: productId,
  };
};

export const setMarketingOffer = (mo) => {
  return {
    type: actionTypes.marketingOffer.SET,
    mo: mo,
  };
};
