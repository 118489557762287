import React from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';

import { useAppSelector } from '../store';

const apiUrl = window.config.dataSourceApiUrl;

const cache: any = {
  loading: {},
  ref: {},
};
window._de_cache = cache;

type ResolveDataElementProps = {
  deId?: string;
  deIds?: string[];
};

const resolveDataElement = ({ deId, deIds }: ResolveDataElementProps) => {
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);

  const [state, setState] = React.useState<{ data: any; loading: boolean; loaded: boolean }>({
    data: {
      id: null,
      data: null,
    },
    loading: true,
    loaded: false,
  });

  React.useEffect(() => {
    return () => {
      if (deId) {
        cache.ref[deId] -= 1;
        if (cache.ref[deId] <= 0) {
          delete cache.ref[deId];
          delete cache[deId];
          delete cache.loading[deId];
        }
      }
    };
  }, []);

  React.useEffect(() => {
    let stillMounted = true;

    if (deId && authenticationToken) {
      if (cache.ref[deId] == null) cache.ref[deId] = 0;
      cache.ref[deId] += 1;

      if (cache[deId]) {
        const data = cloneDeep(cache[deId]);
        setState({
          data: data,
          loading: false,
          loaded: true,
        });
        return;
      } else if (cache.loading[deId]) {
        cache.loading[deId].promise
          .then((data: any) => {
            if (!stillMounted) return;

            setState({
              data: data,
              loading: false,
              loaded: true,
            });
          })
          .catch((e: Error) => {
            console.log(e);
            setState((v) => ({ ...v, loading: false }));
          });
        return;
      } else {
        cache.loading[deId] = {};
        cache.loading[deId].promise = new Promise((resolve, reject) => {
          axios
            .get(`${apiUrl}/resolve/element?id=${encodeURIComponent(deId)}`, {
              headers: {
                Authorization: 'Bearer ' + authenticationToken,
              },
            })
            .then((resp) => {
              let data = null;
              if (resp && resp.data) {
                data = resp.data;
              }

              const final = {
                id: deId,
                data: data && data.data ? data.data : [],
              };

              if (cache.ref[deId]) cache[deId] = final;

              if (stillMounted) {
                setState({
                  data: final,
                  loading: false,
                  loaded: true,
                });
              }

              resolve(final);
            })
            .catch((e: Error) => {
              console.log(e);
              setState((v) => ({ ...v, loading: false }));
              reject(e);
            });
        });
      }
    }

    return () => {
      stillMounted = false;
    };
  }, [deId, authenticationToken]);

  return state;
};

export default resolveDataElement;
