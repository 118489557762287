import { historyConstants } from './constants';

export const historyLoaded = (seasons) => ({
  type: historyConstants.LOADED,
  seasons,
});

export const historyLoad = () => ({
  type: historyConstants.LOAD,
});
