/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { DataElementContext } from '../../../../../page-components/common/DataElementContext';

import { formatCurrency, formatAmount, formatAmountWithDecimals } from '../../../../../modules/lotto/utils/formatters';
import evaluateTicket from '../../../../../modules/lotto/utils/evaluateTicket';
import {
  betsSlipClearLottoTicket,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betsSlipSetTicketOnline,
  betSlipLottoInitializeTickets,
  betSlipLottoSetFreeBetIndex,
  betSlipLottoFreeBetClear,
  betSlipLottoBonusRequestEvaluation,
} from '../../../../../modules/lotto/store/actions/betsSlip';

import { getLottoState, makeGetBonuses } from '../../../../../modules/lotto/store/selectors/lottoData';
import { getLottoBonuses, getLottoRingFences } from '../../../../../modules/lotto/store/selectors/wallet';
import { RootState } from '@/store';
import evBus from '../../../../../utils/evbus';

const twoDecimalsFloat = (vf: any) => {
  let v = parseFloat(vf);
  if (isNaN(v)) {
    return v;
  }
  v = parseFloat(v.toFixed(2));
  return v;
};
const formatMessage = (...args: any) => {
  const txt = args[0];
  const parts = txt.split(/(%[dfs])/);
  let res: any = [];
  let found = 0;
  parts.forEach((p: any, i: any) => {
    if (/^%[dfs]$/.test(p)) {
      res.push(args[found + 1]);
      found += 1;
    } else {
      res.push(p);
    }
  });
  return res.join('');
};
const roundThousands = (value: any) => {
  if (typeof value === 'string') return value;
  if (!value) return '0.00';

  const decimals = 2;
  const tmp = value / 10000;

  let res = Number(
    // @ts-ignore
    Math.round(tmp + 'e' + decimals) + 'e-' + decimals,
  ).toFixed(decimals);

  return parseFloat(res);
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LottoSoldCard = (props: any) => {
  const { t } = useTranslation();

  const {
    amount,

    stakeInc,
    stakeDec,
    stakeSet,

    placeTicketEnabled,

    clearBets,
    auth,
    ticketOnline,
    betsSlipSetTicketOnline,
    lottoTicket,

    wallet,
    profile,

    exitFull,
    betSlipLottoInitializeTickets,

    betSlipLottoSetFreeBetIndex,
    betSlipLottoFreeBetClear,

    freeBets,
    selectedFreeBet,
    bonusGivers,

    bonus,
    walletBonusEvaluate,
    walletBonuses,
    walletRingFences,
    requestBonusEvaluation,
    lottoEvents,
  } = props;

  const history = useNavigate();
  const [stakeAmount, setStakeAmount] = React.useState(amount);
  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openLuckyNumberInfo, setOpenLuckyNumberInfo] = React.useState(false);

  const [systems, setSystems] = React.useState([]);
  const [amounts, setAmounts] = React.useState<any>({
    totalLines: 0,
    winAmount: 0,
    minWinAmount: 0,
  });

  let lottoBonusGivers = [];

  if (selectedFreeBet === -1) {
    if (bonusGivers && typeof bonusGivers.lotto_lucky_number !== 'undefined') {
      lottoBonusGivers.push({
        name: 'Lucky Number',
        type: 'lucky-number',
      });
    }

    if (bonusGivers && typeof bonusGivers.lotto_chance !== 'undefined') {
      lottoBonusGivers.push({
        name: 'Lotto Chance',
        type: 'lotto-chance',
      });
    }
  }

  React.useEffect(() => {
    if (lottoTicket) {
      const systems = lottoTicket.systems;
      if (systems?.length) {
        const ticket = Object.assign({}, lottoTicket.event, {
          stake_amount: Math.round(amount * 100) / 100,
          stk: amount,
          tx: 0,
          max_winning: 100000,
          numbers: [...lottoTicket.numbers].sort(),
          systems: [...systems].sort(),
        });
        const res = evaluateTicket(ticket);

        setAmounts({
          totalLines: res.lines,
          winAmount: roundThousands(res.max_winning),
          minWinAmount: roundThousands(res.min_winning),
        });
      } else {
        setAmounts({
          totalLines: 0,
          winAmount: 0,
          minWinAmount: 0,
        });
      }
    } else {
      setAmounts({
        totalLines: 0,
        winAmount: 0,
        minWinAmount: 0,
      });
    }
  }, [lottoTicket, amount]);

  const handleLuckyNumberInfo = (event: any) => {
    if (typeof event.stopPropagation === 'function') event.stopPropagation();
    setOpenLuckyNumberInfo(true);
  };
  const closeLuckyNumberInfo = () => {
    setOpenLuckyNumberInfo(false);
  };

  React.useEffect(() => {
    setStakeAmount(amount);
  }, [amount]);

  React.useEffect(() => {
    //console.log("wallet update", wallet);
    requestBonusEvaluation();
  }, [wallet, profile, lottoTicket, amount, requestBonusEvaluation]); // eslint-disable-line

  const handlePlaceBetClose = (clear: any) => {
    setOpenPlaceBet(false);
    if (clear) {
      evBus.emit('CLEAR-LOTTO-BETS', null);
      clearBets();
    }
  };

  const handleTicketLocation = (online: any) => {
    betsSlipSetTicketOnline(online);
  };

  const onStakeInc = (s: any, e: any) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    betSlipLottoFreeBetClear();
    stakeInc(s);
  };

  const onStakeDec = (s: any, e: any) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    betSlipLottoFreeBetClear();
    stakeDec(s);
  };

  const handleStakeChange = (e: any) => {
    let ev = e.target.value;
    if (selectedFreeBet !== -1) return;

    // let them input an empty string
    if (ev === '') {
      setStakeAmount('');
      stakeSet('');
      return;
    }

    // don't let them put an amount < 1
    if (ev === '0') {
      return;
    }

    // convert , to .
    ev = ev.split(',').join('.');

    // only one .
    const sev = ev.split('.');
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      return;
    }

    // only valid numbers
    if (isNaN(+ev)) {
      return;
    }

    let v: any = '';

    if (ev !== '') {
      // amounts ending with . have the same value
      if (ev.endsWith('.')) {
        setStakeAmount(ev);
        return;
      }

      // convert amount
      v = parseFloat(ev);
      if (isNaN(v)) return;
    }

    // update amount
    setStakeAmount(ev);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  const createTicket = () => {
    const tickets: any = [];
    lottoTicket.allSelectedEvents.forEach((s: any, i: number) => {
      if (
        lottoTicket.allSelectedEventsFull &&
        lottoTicket.allSelectedEventsFull[i] &&
        lottoTicket.allSelectedEventsFull[i].event_date
      ) {
        const ticketData: any = {
          numbers: lottoTicket.numbers,
          systems: lottoTicket.systems,
          event_id: s,
          amount: amount,
          date: lottoTicket.allSelectedEventsFull[i].event_date,
        };

        if (lottoTicket.extra) {
          const tmp = { ...lottoTicket.extra };

          if (selectedFreeBet > -1) {
            const freeBet = freeBets[selectedFreeBet];
            tmp.free_bet = freeBet.code;
          }

          const bonus_user_data = [];

          if (tmp.free_bet) {
            bonus_user_data.push({
              type: 'free_bet',
              id: tmp.free_bet,
              data: '{}',
            });
          } else {
            if (tmp.luckyNumber && bonusGivers && typeof bonusGivers.lotto_lucky_number !== 'undefined') {
              bonus_user_data.push({
                type: 'lotto_lucky_number',
                id: bonusGivers.lotto_lucky_number.code,
                data: JSON.stringify({
                  number: parseInt(tmp.luckyNumber, 10),
                }),
              });
            }

            if (bonusGivers && typeof bonusGivers.lotto_chance !== 'undefined') {
              bonus_user_data.push({
                type: 'lotto_chance',
                id: bonusGivers.lotto_chance.code,
                data: '{}',
              });
            }
          }

          ticketData.bonus_user_data = JSON.stringify(bonus_user_data);
        }
        tickets.push(ticketData);
      }
    });

    const event = {
      event_name: lottoTicket.event.event_name,
    };

    betSlipLottoInitializeTickets({ event, tickets });
    setOpenPlaceBet(true);
  };

  const handleLogin = () => {
    if (typeof exitFull === 'function') exitFull();
    localStorage.setItem('redirect_after_login', props.location.pathname);
    history('/login');
  };

  let loggedIn = false;
  if (auth && auth.auth_type === 'user') {
    loggedIn = true;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleYesClose = () => {
    setOpen(false);
    evBus.emit('CLEAR-LOTTO-BETS', null);
    clearBets();
  };

  const onFreeBet = (index: number) => () => {
    if (selectedFreeBet === index) {
      betSlipLottoFreeBetClear();
    } else {
      betSlipLottoSetFreeBetIndex(index, freeBets[index]);
      const freeBetAmount = freeBets[index].amount_small === 1 ? freeBets[index].amount / 100 : freeBets[index].amount;
      stakeSet(freeBetAmount);
    }
  };

  const lottoBonusWarning = [];
  let lottoBonusDisable = false;
  let lottoLuckyBetDisable = false;

  if (freeBets && freeBets.length) {
    if (lottoTicket && lottoTicket.numbers && lottoTicket.numbers.length < 3) {
      lottoBonusWarning.push({
        text: 'Minimum 3 numbers to use <strong>Lucky Bet/Free Bet</strong>',
      });
      lottoLuckyBetDisable = true;
      if (selectedFreeBet !== -1) {
        betSlipLottoFreeBetClear();
      }
    } else if (
      lottoTicket &&
      lottoTicket.numbers &&
      lottoTicket.systems &&
      (lottoTicket.systems.length !== 1 || lottoTicket.systems[0] !== lottoTicket.numbers.length)
    ) {
      lottoBonusWarning.push({
        text: 'You need a simple ticket for <strong>Lucky Bet/Free Bet</strong>',
      });
      lottoLuckyBetDisable = true;
      if (selectedFreeBet !== -1) {
        betSlipLottoFreeBetClear();
      }
    }
  }

  if (lottoBonusGivers && lottoBonusGivers.length) {
    if (lottoTicket && lottoTicket.numbers && lottoTicket.numbers.length < 3) {
      lottoBonusWarning.push({
        text: 'Minimum 3 numbers to unlock <strong>Lucky Number</strong>',
      });
      lottoBonusDisable = true;
    } else if (
      lottoTicket &&
      lottoTicket.numbers &&
      lottoTicket.systems &&
      (lottoTicket.systems.length !== 1 || lottoTicket.systems[0] !== lottoTicket.numbers.length)
    ) {
      lottoBonusWarning.push({
        text: 'You need a simple ticket for <strong>Lucky Number</strong>',
      });
      lottoBonusDisable = true;
    }
  }

  const buildFreeBets = () => {
    const buttons: any = [];

    if (!ticketOnline) return buttons;
    const now = Math.floor(+new Date() / 1000);

    freeBets.forEach((fb: any, i: number) => {
      if (fb && fb.product === 'lotto' && fb.count && fb.expires > now) {
        if (lottoTicket && lottoTicket.event && lottoTicket.event.system_id) {
          if (fb.lottery_systems && fb.lottery_systems.indexOf(lottoTicket.event.system_id) > -1) {
            const amount = fb.amount_small === 1 ? fb.amount / 100 : fb.amount;

            buttons.push({
              selected: selectedFreeBet === i,
              disabled: lottoBonusDisable,
              clickHandler: onFreeBet(i),
              count: fb.count,
              amount: amount,
              name: fb.name && fb.name.toLowerCase() === 'lucky bet' ? t(fb.name) : 'Free Bet',
              title: t(fb.name),
            });

            /*
            buttons.push((<ButtonBase
              key={`freeBet_${i}`}
              onClick={onFreeBet(i)}
              variant="contained"
              className={`${classes.increment} ${classes.freeBetButton} ${selectedFreeBet === i ? classes.freeBetButtonSelected : ''} ${lottoLuckyBetDisable ? classes.disable : ''}`}
            >
              <b>{fb.count}</b>&nbsp;x&nbsp;<b>{amount}</b>&nbsp;Lei
              <div className={classes.freeBetTicker} title={t(fb.name)}>{fb.name && fb.name.toLowerCase() === "lucky bet" ? t(fb.name) : "Free Bet"}</div>
            </ButtonBase>));
            */
          }
        }
      }
    });

    return buttons;
  };

  let ticketAppliedBonus = null;
  if (bonus && bonus.ticketAppliedBonus) {
    ticketAppliedBonus = {
      maxPercentage: bonus.ticketAppliedBonus.maxPercentage,
      // @ts-ignore
      amountMax: formatAmount(bonus.ticketAppliedBonus.amountMax, false, false),
      currency: formatCurrency(wallet.currency),
    };

    /*
    ticketAppliedBonus = (
      <div className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}>
        <div className={classes.soldSmall}>
          {t('Progressive Bonus')} {bonus.ticketAppliedBonus.maxPercentage}%
        </div>
        <div className={classes.soldSmallAmount}>
          +{formatAmount(bonus.ticketAppliedBonus.amountMax, false, false)} {formatCurrency(wallet.currency)}
        </div>
      </div>
    );
    */
  }

  let ticketBonusEligibles = null;
  if (bonus && bonus.ticketBonusEligibles && bonus.ticketBonusEligibles.length) {
    const bonuses: any = [];
    bonus.ticketBonusEligibles.forEach((b: any) => {
      if (b.eligible) {
        bonuses.push(b.bonus.name);
      }
    });
    ticketBonusEligibles = { bonuses: bonuses.join(' + ') };
    /*
    ticketBonusEligibles = (
      <div className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}>
        <div className={classes.soldSmall}>{t('Eligible Bonuses')}</div>
        <div className={classes.soldSmallAmount}>{bonuses.join(' + ')}</div>
      </div>
    );
    */
  }

  const walletBonusesHash: any = {};
  if (walletBonuses) {
    walletBonuses.forEach((wb: any) => {
      walletBonusesHash[wb.id] = wb.name;
    });
  }

  let bonusEvaluateMessagesEligible = null;
  let hasBonusEvaluateMessages = false;
  let stakeErrorBonuses = [];

  let free_money_used = 0;
  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  const can_ticket_be_evalued =
    lottoTicket &&
    lottoTicket.numbers &&
    lottoTicket.systems &&
    lottoTicket.numbers.length &&
    lottoTicket.systems.length
      ? true
      : false;

  if (window.config.useBonusEvaluation === '1' && can_ticket_be_evalued) {
    const bonuses: any = [];
    let usedBonuses: any = [];
    let usedRingFences: any = [];
    let errorBonuses: any = [];

    if (
      walletBonusEvaluate &&
      walletBonusEvaluate.success &&
      walletBonusEvaluate.data &&
      walletBonusEvaluate.data.bonus
    ) {
      bLimit = free_money_used = twoDecimalsFloat(walletBonusEvaluate.data.free_money_used);
      usedBonuses = walletBonusEvaluate.data.bonus ? walletBonusEvaluate.data.bonus : [];
      usedRingFences = walletBonusEvaluate.data.ring_fence ? walletBonusEvaluate.data.ring_fence : [];
      errorBonuses = walletBonusEvaluate.data.details ? walletBonusEvaluate.data.details : [];
      valid = walletBonusEvaluate.data.valid;
    } else {
      valid = bLimit >= amount;
    }

    walletBonuses.forEach((wb: any) => {
      let total_used = 0;
      let balance_total = wb.amount;

      // collect what would the total amount ring fence + bonus would be
      const rf = walletRingFences.find((rf: any) => wb.id === rf.id);
      if (rf) {
        balance_total += rf.amount;
      }

      // used ring fence
      const urf = usedRingFences.find((rf: any) => wb.id === rf.id);
      if (urf) {
        bLimit += twoDecimalsFloat(urf.balance_used);
        total_used += twoDecimalsFloat(urf.balance_used);
      }

      // used bonus
      const ub = usedBonuses.find((b: any) => wb.id === b.id);
      if (ub) {
        bLimit += twoDecimalsFloat(ub.balance_used);
        total_used += twoDecimalsFloat(ub.balance_used);
      }

      total_used = twoDecimalsFloat(total_used);
      bonuses.push({ id: wb.id, name: wb.name, balance: balance_total, balance_used: total_used ? -total_used : 0 });
    });

    bLimit = twoDecimalsFloat(bLimit);
    //bonuses.sort((a, b) => a.balance - b.balance);

    //console.log("bonuses", bonuses, errorBonuses);

    bonusEvaluateMessagesEligible = bonuses.map((b: any, i: number) => {
      const eb = errorBonuses.filter((eb: any) => eb.id === b.id);
      hasBonusEvaluateMessages = true;

      return {
        hasErrors: !valid && eb.length > 0,
        hasWarnings: valid && eb.length > 0,
        name: b.name,
        // @ts-ignore
        balance: formatAmountWithDecimals(b.balance, 2, true),
        // @ts-ignore
        balance_used: formatAmountWithDecimals(b.balance_used, 2, true),
        currency: formatCurrency(wallet.currency),
        errors: eb
          .filter((e: any) => e.error && e.error.type === 'ticket')
          .map((e: any, i: number) => {
            const args = [...e.error.args];
            args[0] = (walletBonusesHash[eb.id] ? walletBonusesHash[eb.id] + ': ' : '') + t(args[0]); // see if we have a translation for that message

            if (e.error.section === 'generic') {
              return { message: formatMessage(...args) };
            } else if (e.error.section === 'stake') {
              stakeErrorBonuses.push({ message: formatMessage(...args) });
            }
            return null;
          }),
      };
    });

    /*
    bonusEvaluateMessagesEligible = (
      <div>
        {bonuses.map((b, i) => {
          const eb = errorBonuses.filter((eb) => eb.id === b.id);
          //console.log("errorBonuses", eb);

          hasBonusEvaluateMessages = true;

          return (
            <div key={'bonus_' + i}>
              <div
                className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
                key={i}
              >
                <div className={`${classes.soldSmall} ${classes.ellipsis}`}>
                  {!valid && eb.length > 0 && <span className={classes.bonusHasErrors}>!</span>}
                  {valid && eb.length > 0 && <span className={`${classes.bonusHasWarnings}`}>!</span>}
                  {b.name}
                </div>
                <div className={classes.soldSmallAmount}>
                  {formatAmountWithDecimals(b.balance, 2, true)} Lei (
                  <span className={classes.messageErr}>{formatAmountWithDecimals(b.balance_used, 2, true)}</span>)
                </div>
              </div>
              {!valid && eb.length > 0 && (
                <div className={`${classes.bonusErrorBar}`}>
                  <div className="txt">{t('Conditiile BONUS nu sunt indeplinite')}</div>
                  <div className="bar-question-mark">?</div>
                </div>
              )}
              {valid && eb.length > 0 && (
                <div className={`${classes.bonusErrorBar} ${classes.bonusWarningBar}`}>
                  <div className="txt">{t('Acest bilet nu va contribui la rulajul bonusului')}</div>
                  <div className="bar-question-mark">?</div>
                </div>
              )}
              <div className={classes.bonusBalanceErrors}>
                {!valid &&
                  eb
                    .filter((e) => e.error && e.error.type === 'ticket')
                    .map((e, i) => {
                      const args = [...e.error.args];
                      args[0] = (walletBonusesHash[eb.id] ? walletBonusesHash[eb.id] + ': ' : '') + t(args[0]); // see if we have a translation for that message

                      if (e.error.section === 'generic') {
                        return (
                          <div key={eb.id + '_' + i} className={classes.message}>
                            {formatMessage(...args)}
                          </div>
                        );
                      } else if (e.error.section === 'stake') {
                        stakeErrorBonuses.push(
                          <div key={eb.id + '_' + i} className={classes.message}>
                            {formatMessage(...args)}
                          </div>,
                        );
                      }
                      return null;
                    })}
              </div>
            </div>
          );
        })}
      </div>
    );
    */
  }

  let disableLottoTicketInShop = false;
  if (typeof window.config.disableLottoTicketInShop !== 'undefined' && window.config.disableLottoTicketInShop === '1') {
    disableLottoTicketInShop = true;
    if (!ticketOnline) {
      setTimeout(() => {
        betsSlipSetTicketOnline(true);
      }, 0);
    }
  }

  const contextValue = {
    loggedIn,
    valid,
    ticketOnline,
    hasLuckyNumber: lottoTicket?.extra?.luckyNumber !== 0,
    luckyNumber: lottoTicket?.extra?.luckyNumber,
    lottoBonusGivers: lottoBonusGivers.map((b: any) => {
      return {
        name: b.name,
        type: b.type,
        onClick: handleLuckyNumberInfo,
      };
    }),
    selectedFreeBet,
    lottoBonusWarning,
    // @ts-ignore
    walletMain: formatAmountWithDecimals(wallet.main, 2, true),
    walletCurrency: formatCurrency(wallet.currency),
    hasFreeMoneyUsed: !!free_money_used,
    // @ts-ignore
    freeMoneyUsed: formatAmountWithDecimals(free_money_used, 2, true),
    ticketBonusEligibles,
    bonusEvaluateMessagesEligible,
    ticketAppliedBonus,
    hasBonusEvaluateMessages,
    extraLotteries: lottoTicket?.allSelectedEvents?.length ?? 0,
    amount,
    winAmount: amounts.winAmount === '' ? 0 : formatAmountWithDecimals(amounts.winAmount),
    minWinAmount: formatAmountWithDecimals(amounts.minWinAmount),
    onStakeDec: (e: any) => onStakeDec(-1, e),
    onStakeInc: (e: any) => onStakeInc(-1, e),
    stakeAmount,
    handleStakeChange,
    freeBets: freeBets.length !== 0 ? buildFreeBets() : [],
    onStakePillInc: (e: any) => {
      if (e.target.dataset.inc) {
        onStakeInc(parseInt(e.target.dataset.inc, 10), e);
      }
    },
    onDeletePopup: handleClickOpen,
    onPlaceTicket: createTicket,
    onHandleLogin: handleLogin,
    disableLottoTicketInShop,
    handleTicketLocation,
    openPlaceBet,
    handlePlaceBetClose,
    openDelete: open,
    onHandleCloseDelete: handleClose,
    onHandleYesNoCloseDelete: handleYesClose,
    openLuckyNumberInfo,
    onHandleCloseLuckyNumberInfo: closeLuckyNumberInfo,
    canPlaceTicket: !(
      !placeTicketEnabled ||
      (!valid && selectedFreeBet === -1) ||
      amount === 0 ||
      amount === '' ||
      lottoTicket?.allSelectedEvents?.length === 0
    ),
  };

  //console.log('LottoSoldCard[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{props.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );

  /*
  return {
    <div className={`${classes.root} ${ticketOnline && lottoBonusGivers.length ? classes.hasBonuses : ""}`}>
      {loggedIn && (
        <div className={classes.pr}>
          {valid && ticketOnline && <div className={classes.bonuses}>
            {lottoBonusGivers.map((b, i) => {

              let clickHandler = noop;
              if (b.type === "lucky-number") clickHandler = handleLuckyNumberInfo;

              return <div className={`${classes.bonus} ${b.type} ${lottoBonusDisable ? classes.disable : ''}`} key={`bonus_${i}`} onClick={clickHandler}>
                <div className="bonus-icon"><DiamondSVG /></div>
                <div className="bonus-name">{t(b.name)}{b.type === "lucky-number" && lottoTicket && lottoTicket.extra && lottoTicket.extra.luckyNumber !== 0 && <span>&nbsp;({lottoTicket.extra.luckyNumber})</span>}</div>
                <div className="bonus-q">?</div>
              </div>;
            })}
          </div>}
          {lottoTicket && !valid && (selectedFreeBet === -1) && <InsufficientBalance />}
          {lottoBonusWarning.length > 0 && <div className={`${classes.soldHolder} ${lottoBonusGivers.length && valid && ticketOnline ? "has-bonuses" : ""}`}>
            {lottoBonusWarning}
          </div>}
          <div
            className={`${classes.soldHolder} ${lottoBonusGivers.length && valid && ticketOnline ? "has-bonuses" : ""} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
          >
            <div className={classes.sold}>{t("AVAILABLE")}</div>
            <div className={classes.sold}>
              {formatAmountWithDecimals(wallet.main, 2, true)} {formatCurrency(wallet.currency)}
              {!!free_money_used && <span>
                &nbsp;(<span className={classes.messageErr}>-{formatAmountWithDecimals(free_money_used, 2, true)}</span>)
              </span>}
            </div>
          </div>
        </div>
      )}
      {ticketBonusEligibles}
      {bonusEvaluateMessagesEligible}
      {(!!ticketAppliedBonus || !!ticketBonusEligibles || hasBonusEvaluateMessages) && <div className={classes.hr2}></div>}
      <div className={classes.stakeTitle}>{t("Choose Stake")}</div>
      <div
        className={`${classes.stakeHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
      >
        <div className={classes.textCenter}>
          <div className={classes.stakeSubtitle}>{t("Total Stake")} ({extraLotteries} {t("draws")})</div>
          <div className={classes.stakeAmount}>
            {amount * extraLotteries}{" "}
            <span className={`${classes.currency}`}>{formatCurrency(wallet.currency)}</span>
          </div>
        </div>
        <div className={`${classes.textCenter} ${classes.mask} ${bgCls}`}>
          <div className={`${classes.stakeSubtitle} subtitle`}>{t("Max. Winning per Ticket")}</div>
          <div className={`${classes.stakeAmount} title`}>
            {winAmount === "" ? 0 : formatAmount2(winAmount)}{" "}
            <span className={`${classes.currency} currency`}>
              {formatCurrency(wallet.currency)}
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row flex-nowrap justify-content-between">
        <div>
          &nbsp;
        </div>
        <div>
          <div className={classes.minWin}>
            {t("Min. Winning")}: {formatAmount2(minWinAmount)} {formatCurrency(wallet.currency)}
          </div>
        </div>
      </div>
      <div className={classes.xtra}>x{extraLotteries} {t("draws")}</div>
      <div
        className={`${classes.payHolder} d-flex flex-row flex-nowrap align-items-center justify-content-center`}
      >
        <BounceButton>
          <ButtonBase onClick={e => onStakeDec(-1, e)} variant="contained" className={classes.but} disabled={selectedFreeBet !== -1}>
            -
          </ButtonBase>
        </BounceButton>
        <InputBase
          type="text"
          onChange={handleStakeChange}
          className={classes.amount}
          value={stakeAmount}
          inputProps={{
            className: classes.amountInput,
            "aria-label": "stake",
            pattern: "[0-9\.]*"
          }}
          endAdornment={
            <InputAdornment className={classes.amountEnd} position="end">
              Lei&nbsp;<span style={{ fontWeight: "normal" }}>/ {t("draw")}</span>
            </InputAdornment>
          }
        />
        <BounceButton>
          <ButtonBase onClick={e => onStakeInc(-1, e)} variant="contained" className={classes.but} disabled={selectedFreeBet !== -1}>
            +
          </ButtonBase>
        </BounceButton>
      </div>
      <div
        className={`${classes.incrementsHolder} ${freeBets && freeBets.length !== 0 ? 'has-free-bets' : ''} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
      >
        {freeBets.length !== 0 && buildFreeBets()}
        <ButtonBase
          onClick={e => onStakeInc(5, e)}
          variant="contained"
          className={classes.increment}
        >
          +5 Lei
        </ButtonBase>
        <ButtonBase
          onClick={e => onStakeInc(10, e)}
          variant="contained"
          className={classes.increment}
        >
          +10 Lei
        </ButtonBase>
        <ButtonBase
          onClick={e => onStakeInc(20, e)}
          variant="contained"
          className={classes.increment}
        >
          +20 Lei
        </ButtonBase>
        <ButtonBase
          onClick={e => onStakeInc(50, e)}
          variant="contained"
          className={classes.increment}
        >
          +50 Lei
        </ButtonBase>
      </div>
      <div className={`${classes.payButtonHolder} d-flex align-items-center ${disableLottoTicketInShop ? 'pb-4' : ''}`}>
        <div className={classes.deleteButton} onClick={handleClickOpen}>
          <img src={Delete} alt="" />
        </div>
        {(loggedIn || !ticketOnline) && (
          <React.Fragment>
            {ticketOnline && (
              <ButtonBase
                variant="contained"
                className={`${classes.payButton} ${!placeTicketEnabled || (!valid && selectedFreeBet === -1) || (amount === 0 || amount === "") || extraLotteries === 0 ? classes.disabledButton : ""}`}
                onClick={createTicket}
                disabled={!placeTicketEnabled || (!valid && selectedFreeBet === -1) || (amount === 0 || amount === "") || extraLotteries === 0}
              >
                <div>
                  <div className={classes.buttonTitle}>{t("PLACE TICKET ONLINE")}</div>
                </div>
              </ButtonBase>
            )}
            {!ticketOnline && (
              <ButtonBase
                variant="contained"
                className={`${classes.payAgencyButton}`}
                onClick={createTicket}
                disabled={!placeTicketEnabled || (amount === 0 || amount === "")}
              >
                <div>
                  <div className={classes.buttonTitle}>{t("PLACE TICKET IN SHOP")}</div>
                </div>
              </ButtonBase>
            )}
          </React.Fragment>
        )}
        {!loggedIn && ticketOnline && (
          <ButtonBase
            variant="contained"
            className={`${classes.loggedInButton}`}
            onClick={handleLogin}
          >
            <div>
              <div className={classes.buttonTitle}>
                <img src={avatar} alt="" className={classes.avatar} /> {t("ENTER YOUR ACCOUNT")}
              </div>
            </div>
          </ButtonBase>
        )}
      </div>
      {!disableLottoTicketInShop && <div className={classes.footer}>
        <TicketSwitch online={ticketOnline} onChange={handleTicketLocation} />
      </div>}
      <TicketModal openPlaceBet={openPlaceBet} handlePlaceBetClose={handlePlaceBetClose} />
      <DeleteModal open={open} handleClose={handleClose} handleYesClose={handleYesClose} />
      {openLuckyNumberInfo && <LuckyNumberDialog open={openLuckyNumberInfo} onClose={closeLuckyNumberInfo} />}
    </div>
  };
  */
};

const getBonuses = makeGetBonuses();

const mapStateToProps = (state: RootState) => {
  const bst = getLottoState(state);
  const ct = bst.betsSlip;

  let event_id = '';
  if (bst.betsSlip.lottoTicket && bst.betsSlip.lottoTicket.event && bst.betsSlip.lottoTicket.event.event_id) {
    event_id = bst.betsSlip.lottoTicket.event.event_id;
  }

  return {
    wallet: state.wallet,
    profile: state.profile,

    amount: ct.amount,
    placeTicketEnabled: ct.placeTicketEnabled,
    placeTicketErrorMessage: ct.placeTicketErrorMessage,
    errorMessage: ct.errorMessage,
    auth: state.authentication,
    ticketOnline: ct.ticketOnline,
    lottoTicket: ct.lottoTicket,
    freeBets: state.freeBets.freeBets,
    selectedFreeBet: ct.selectedFreeBet,

    bonus: ct.lottoTicket && ct.lottoTicket.bonus ? ct.lottoTicket.bonus : null,
    walletBonusEvaluate: ct.lottoTicket && ct.lottoTicket.bonusEvaluate ? ct.lottoTicket.bonusEvaluate : null,
    walletBonuses: getLottoBonuses(state),
    walletRingFences: getLottoRingFences(state),
    bonusGivers: event_id !== '' ? getBonuses(state, { event_id }) : [],
  };
};

const actionCreators = {
  clearBets: betsSlipClearLottoTicket,
  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  betsSlipSetTicketOnline: betsSlipSetTicketOnline,
  betSlipLottoInitializeTickets: betSlipLottoInitializeTickets,
  betSlipLottoSetFreeBetIndex: betSlipLottoSetFreeBetIndex,
  betSlipLottoFreeBetClear: betSlipLottoFreeBetClear,
  requestBonusEvaluation: betSlipLottoBonusRequestEvaluation,
};

// @ts-ignore
export default connect(mapStateToProps, actionCreators)(LottoSoldCard);
