import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import {
  loginUsername,
  loginPassword,
  loginStart,
  requestSmsCode,
  loginError,
} from '../../../modules/casino/store/actions';
import { IS_EMAIL, IS_PHONE } from '../../../modules/casino/utils/LoginMethods';
import PhoneNumberValidator from '../../../modules/casino/utils/PhoneNumberValidator';
import { useTranslation } from 'react-i18next';

import './index.scss';

type LoginProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
    preventRedirect: boolean;
    title: string;
    subTile: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
    preventRedirect: false,
    title: '',
    subTile: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Login = (componentProps: LoginProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.authentication);
  const login = useAppSelector((state) => state.login);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (authentication.auth_type === 'user' && !componentProps?.properties?.preventRedirect) {
      // is logged in so let's redirect him back to homepage or some redirect url
      if (localStorage.getItem('redirect')) {
        const redirect = localStorage.getItem('redirect');
        localStorage.removeItem('redirect');
        navigate(redirect ?? '/');
        return;
      }
      navigate('/');
    }
  }, [authentication, componentProps]);

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [SMSCode, setSMSCode] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const deferredUsername = React.useDeferredValue(username);
  const deferredPassword = React.useDeferredValue(password);
  const deferredSMSCode = React.useDeferredValue(SMSCode);

  React.useEffect(() => {
    if (deferredUsername && username === deferredUsername) {
      dispatch(loginUsername(deferredUsername));
    }
  }, [username, deferredUsername]);

  React.useEffect(() => {
    if (deferredPassword && password === deferredPassword) {
      dispatch(loginPassword(deferredPassword));
    }
  }, [password, deferredPassword]);

  React.useEffect(() => {
    if (deferredSMSCode && SMSCode === deferredSMSCode) {
      dispatch(loginPassword(deferredSMSCode));
    }
  }, [SMSCode, deferredSMSCode]);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const resendTempSMSCodeHandler = () => {
    if (login.etaSmsRequest <= 1) {
      dispatch(requestSmsCode());
      isSmsCodeInRequest();
      setSMSCode('');
    }
  };

  const [smsCodeInRequest, setSmsCodeInRequest] = React.useState(false);
  const [hasMadeOneRequest, setHasMadeOneRequest] = React.useState(false);

  const isSmsCodeInRequest = () => {
    if (!hasMadeOneRequest) {
      setHasMadeOneRequest(true);
    }
    setSmsCodeInRequest(true);

    setTimeout(() => {
      setSmsCodeInRequest(false);
    }, 2000);
  };

  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const phoneValidator = (phone: string) => {
    const valid = PhoneNumberValidator.validate(phone);
    setIsValidPhone(valid);
  };

  React.useEffect(() => {
    if (login.method === IS_PHONE && username) {
      phoneValidator(username);
    }
  }, [username, login.method]);

  // dispatch clear login error if username or password changes
  React.useEffect(() => {
    dispatch(loginError(false));
  }, [username, password, SMSCode]);

  const getUserNameErrorMsg = () => {
    const phoneRegex = /^\d+$/;
    let err = '';
    if (username && username.length >= 4 && !phoneRegex.test(username)) {
      if (login.errorUsername || !username.includes('@')) {
        if (login.errorUsername) {
          err = t('Invalid email format, verify your email and try again');
        }
        if (!username.includes('@')) {
          err = t("Invalid email format. Missing '@' character");
        }
      }
    }

    if (username && username.length > 4 && phoneRegex.test(username)) {
      if (login.errorUsername) {
        err = t('Please enter a valid phone number');
      }
    }

    return err;
  };

  const contextValue = {
    title: props.properties?.title,
    subTile: props.properties?.subTile,
    usernameError: false,
    usernameErrorMsg: getUserNameErrorMsg(),
    passwordError: false,

    smsCodeError: false,
    loginError: login.loginError,
    canLogin: true,
    username: username,
    password: password,
    SMSCode: SMSCode,
    resendTempSMSCode: resendTempSMSCodeHandler,
    inRequest: login.inRequest,
    smsCodeInRequest: smsCodeInRequest,
    hasMadeOneRequest: hasMadeOneRequest,
    isValidPhone: isValidPhone,
    etaSmsRequest: login.etaSmsRequest ? login.etaSmsRequest : '',
    showPassword: showPassword,
    loginMethod: login.method,
    devMode: import.meta.env.MODE === 'development',
    showPasswordHandler: showPasswordHandler,
    onChangeUsername: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      setUsername(value);
      if (!value) {
        setPassword('');
        setSMSCode('');
      }
    },
    onChangePassword: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      setPassword(value);
    },
    onChangeSMSHandler: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      setSMSCode(value);
    },
    onDoLogin: (e: any) => {
      // console.log('running doLogin:', { e, username, password, login, SMSCode });

      if (username && password && login.method === IS_EMAIL && login.allowLoginButton) {
        if (e.key === 'Enter' || e.currentTarget.dataset.id === 'login') {
          dispatch(loginStart(undefined));
        }
      }
      if (username && login.method === IS_PHONE && (login.allowSMS || login.allowLoginButton)) {
        if (e.key === 'Enter' || e.currentTarget.dataset.id === 'login') {
          if (SMSCode.length >= 4) {
            dispatch(loginStart(undefined));
          } else {
            dispatch(requestSmsCode());
            isSmsCodeInRequest();
          }
        }
        return false;
      }
    },
  };

  if (login.errorUsername) {
    contextValue.usernameError = true;
  }
  if (login.errorPassword) {
    contextValue.passwordError = true;
  }

  if (!login.allowLoginButton || (login.method === IS_PHONE && SMSCode.length < 4)) {
    contextValue.canLogin = false;
  }

  if (SMSCode.length > 0 && SMSCode.length < 4) {
    contextValue.smsCodeError = true;
  }

  // console.log('LOGIN contextValue', {
  //   login,
  //   contextValue,
  //   SMSCode,
  //   // smsCodeInRequest,
  //   // isValidPhone,
  //   // hasMadeOneRequest,
  // });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Login;
