import { takeEvery, takeLatest, put } from 'redux-saga/effects';

import { alerts } from '../actions/actionTypes';
import ClientAPI from './../../ClientAPI/ClientAPI';

export function updateMarketingAgreementsSaga(action) {
  if (typeof action.params.sendToServer !== 'undefined') {
    if (action.params.sendToServer === false) {
      return;
    }
  }

  const axios = ClientAPI.getInstance();
  const store = ClientAPI.getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return new Promise((resolve, reject) => {
      reject(new Error('Not Authenticated'));
    });
  }
  const postData = {
    channel: 0,
    value: action.params.value ?? 0,
  };

  if (typeof action.params.all !== 'undefined') {
    if (action.params.marketing_receive_sms != null) postData.value_sms = action.params.marketing_receive_sms;
    if (action.params.marketing_receive_email != null) postData.value_email = action.params.marketing_receive_email;
    if (action.params.marketing_receive_phone != null) postData.value_phone = action.params.marketing_receive_phone;
    if (action.params.marketing_partners != null) postData.value_partners = action.params.marketing_partners;
  } else if (typeof action.params.marketing_receive_sms !== 'undefined') {
    postData.value = action.params.marketing_receive_sms;
  } else {
    postData.value = action.params.value;
    switch (action.params.channel) {
      case 'marketing_receive_sms':
        postData.channel = 1;
        break;
      case 'marketing_receive_email':
        postData.channel = 2;
        break;
      case 'marketing_receive_phone':
        postData.channel = 3;
        break;
      case 'marketing_partners':
        postData.channel = 4;
        break;
      default:
        break;
    }
  }

  return new Promise((resolve) => {
    axios({
      url: '/api/profile/updateMarketingAgreement',
      method: 'post',
      data: postData,
    });
  });
}

export default function* watchAlertsSaga() {
  yield takeEvery(alerts.SET_MARKETING_AGREEMENTS, updateMarketingAgreementsSaga);
}
