import React from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import * as EmailValidator from 'email-validator';

import './index.scss';
import {
  resetPasswordSendEmail,
  resetPasswordSendNewPassword,
  resetPasswordSetToken,
} from '../../../modules/casino/store/actions';
import PasswordValidator from '../../../utils/PasswordValidator';
import { resetPasswordClearErrorMessage } from '../../../modules/casino/store/actions/resetPassword';

type ForgotPasswordProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ForgotPassword = (componentProps: ForgotPasswordProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const dispatch = useAppDispatch();

  const resetPassword = useAppSelector((state) => state.resetPassword);

  const [email, setEmail] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [retypePassword, setRetypePassword] = React.useState('');
  const [newPasswordIsValid, setNewPasswordIsValid] = React.useState(false);
  const [retypePasswordIsValid, setRetypePasswordIsValid] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [step, setStep] = React.useState('step-1');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showReTypePassword, setShowReTypePassword] = React.useState(false);
  const [token, setToken] = React.useState('');

  React.useEffect(() => {
    if (!resetPassword.loading && resetPassword && email) {
      if (resetPassword.token === 'sent' && resetPassword.errorMessage === '') {
        setEmailSent(true);
        setStep('step-2');
      }
    }

    if (!resetPassword.loading && resetPassword && newPassword && retypePassword) {
      if (resetPassword.success) {
        setStep('step-4');
      }
    }
  }, [resetPassword.loading]);

  React.useEffect(() => {
    getToken();

    return () => {
      setEmailSent(false);
    };
  }, []);

  const getToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token && token !== 'undefined' && token !== '' && token.length > 30) {
      dispatch(resetPasswordSetToken(token));
      setToken(token);
      setStep('step-3');
    } else {
      return;
    }
  };

  const passwordValidator = (password: string) => {
    const passwordIsValid = PasswordValidator(password);
    // return passwordIsValid.success;
    return passwordIsValid;
  };

  const validator: any = PasswordValidator(newPassword);

  const checkErrors = () => {
    if (step === 'step-3' && !newPassword) {
      return { hasErrors: true, errorMessage: validator.message };
    } else if (step === 'step-3' && newPassword) {
      // 6 or config.min ?
      if (validator && validator.success && newPassword !== retypePassword) {
        return { hasErrors: true, errorMessage: 'Passwords are different' };
      } else {
        if (validator && validator.success === false) {
          return { hasErrors: true, errorMessage: validator.message };
        }
      }
    }
    return { hasErrors: false, errorMessage: validator.message };
  };

  const contextValue = {
    email: email,
    newPassword: newPassword,
    retypePassword: retypePassword,
    isValid: isValid,
    newPasswordIsValid: newPasswordIsValid,
    retypePasswordIsValid: retypePasswordIsValid,
    loading: resetPassword.loading,
    emailSent: emailSent,
    showPassword: showPassword,
    showReTypePassword: showReTypePassword,
    step: step,

    errorMessage:
      resetPassword.errorMessage || step === 'step-1' ? resetPassword.errorMessage : checkErrors().errorMessage,

    passwordStrength: validator.score,
    passwordShortStrengthLabel: validator.labels.short,
    passwordLongStrengthLabel: validator.labels.long,
    passwordMessage: validator.message,
    passwordAccepted: validator.success,
    passwordConditionsNotMet: validator.conditionsNotMet,
    // hasErrors: false,
    hasErrors: checkErrors().hasErrors,

    onChangeEmail: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      setIsValid(EmailValidator.validate(value));
      if (resetPassword.errorMessage) {
        dispatch(resetPasswordClearErrorMessage());
      }
      setEmail(value);
    },
    onChangeNewPassword: (e: React.FormEvent<HTMLInputElement>) => {
      let value = e.target ? (e.target as HTMLInputElement).value : '';
      value = value.replace(/\s/g, '');
      setNewPasswordIsValid(passwordValidator(value).success);
      if (resetPassword.errorMessage) {
        dispatch(resetPasswordClearErrorMessage());
      }
      setNewPassword(value);
    },
    onChangeRetypePassword: (e: React.FormEvent<HTMLInputElement>) => {
      let value = e.target ? (e.target as HTMLInputElement).value : '';
      value = value.replace(/\s/g, '');
      setRetypePasswordIsValid(passwordValidator(value).success);
      if (resetPassword.errorMessage) {
        dispatch(resetPasswordClearErrorMessage());
      }
      setRetypePassword(value);
    },
    onSendEmail: () => email !== '' && isValid && dispatch(resetPasswordSendEmail(email)),
    onPreviousStep: () => {
      setStep('step-1');
      setEmail('');
    },
    showPasswordHandler: () => setShowPassword(!showPassword),
    showReTypePasswordHandler: () => setShowReTypePassword(!showReTypePassword),
    onSubmitNewPasswordHandler: () => {
      if (
        token &&
        newPassword &&
        newPasswordIsValid &&
        retypePassword &&
        retypePasswordIsValid &&
        newPassword === retypePassword
      ) {
        dispatch(resetPasswordSendNewPassword(token, newPassword));
      }
    },
  };

  // console.log('Forgot password: ', { contextValue, validator, resetPassword });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default ForgotPassword;
