/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';
import moment from 'moment';

import getStore from '../../store';
import { getLottoState } from '../selectors/lottoData';

import { appConstants, lottoEventsConstants } from '../actions/constants';
import { loadLottoEvents, loadLottoSystems, loadingLottoEvents } from '../actions/lotto-events';

import { lottoConfig } from '../../api/config/lotto';

function axiosObject(url) {
  return axios
    .get(url)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
}

const apiCall = (url, params) => {
  //console.log("apiCall", url, params);
  return axios
    .post(url, params)
    .then((response) => response.data)
    .catch((err) => {
      throw err;
    });
};

function* fetchLottoEventsSaga(action) {
  const { lottoEvents } = getLottoState(getStore().getState());
  yield put(loadingLottoEvents(true));

  try {
    if (lottoEvents.systems.length === 0) {
      let { data } = yield call(axiosObject, lottoConfig.publicSystems);
      if (data) {
        const rows = data.systems;
        yield put(loadLottoSystems(rows ? rows : []));
      }
    }
    //console.log(lottoConfig.publicOffer, { "limit": 0, "offset": 0, "date_to": moment().add(7, "days").format() });
    let { data } = yield call(apiCall, lottoConfig.publicOffer, {
      limit: 0,
      offset: 0,
      date_from: moment().startOf('day').format(),
      date_to: moment().startOf('day').add(7, 'days').format(),
    });
    if (data) {
      const rows = data.events ?? [];
      rows.forEach((row) => {
        row.event_date_ts = moment(row.event_date, "YYYY-MM-DD HH:mm:ss").valueOf();
      });
      yield put(loadLottoEvents(rows ? rows : []));
    }
  } catch (e) {
    console.log('[fetchLottoEventsSaga] err', e);
  }

  yield put(loadingLottoEvents(false));
}

function* fetchLottoSystemsSaga(action) {
  try {
    let { data } = yield call(axiosObject, lottoConfig.publicSystems);
    if (data) {
      const rows = data.systems;
      yield put(loadLottoSystems(rows ? rows : []));
    }
  } catch (e) {
    //console.log("[fetchLottoSystemsSaga] err", e);
  }
}

export default function* watchLottoEvents() {
  yield takeEvery(appConstants.INITIALIZE, fetchLottoEventsSaga);
  yield takeEvery(lottoEventsConstants.FETCH, fetchLottoEventsSaga);
  yield takeEvery(lottoEventsConstants.FETCH_SYSTEMS, fetchLottoSystemsSaga);
}
