// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';
import { RootState } from '../index';

type RankGroup = {
  id: number;
  cms_data: any;
  name: void | null | string;
  current_rank: {
    rank_index: number;
    total_ranks: number;
    id: number;
    name: string;
    points: number;
    min_points_to_hold: number;
    min_points_to_upgrade: number;
    valid_until: number;
    cms_data: void | null | string;
  };
  next_rank: {
    id: number;
    name: string;
    min_points_to_hold: number;
    min_points_to_upgrade: number;
    cms_data: void | null | string;
  };
};

type Wallet = {
  id: string;
  amount: number;
  cms_data: void | null | string; //{\"name\":\"Loyalty Points\"}"
};

type FetchConfigResult = {
  rank_groups: RankGroup[];
  wallets: Wallet[];
  error?: string;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

type UpdateRankGroupAction = {
  rank_group_id: number;
  data: any;
};

type UpdateLoyaltyWalletsAction = {
  data: any;
};

export const fetchRankSystems = createAsyncThunk<FetchConfigResult, void, FetchConfigError>(
  'rankSystems/list',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.get(endpoints.rankSystems.list, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        return response.data;
      }

      return rejectWithValue({
        error: "Couldn't fetch rank systems list",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface RankSystemsState {
  rankGroups: RankGroup[];
  wallets: Wallet[];
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const rankSystemsSlice = createSlice({
  name: 'rankSystems',
  initialState: <RankSystemsState>{
    rankGroups: [],
    wallets: [],
    loading: false,
    loaded: true,
    error: null,
  },
  reducers: {
    updateRankGroup: (state, action) => {
      const rankGroupId = (action.payload as UpdateRankGroupAction).rank_group_id;

      const index = state.rankGroups.findIndex((el: RankGroup) => {
        return el.id === rankGroupId;
      });

      if (index > -1) {
        state.rankGroups[index] = (action.payload as UpdateRankGroupAction).data;
      }
    },
    updateCurrentRank: (state, action) => {
      const rankGroupId = (action.payload as UpdateRankGroupAction).rank_group_id;

      const index = state.rankGroups.findIndex((el: RankGroup) => {
        return el.id === rankGroupId;
      });

      if (index > -1) {
        const data = (action.payload as UpdateRankGroupAction).data;

        state.rankGroups[index].current_rank = {
          ...state.rankGroups[index].current_rank,
          ...data,
        };
      }
    },
    updateLoyaltyWallets: (state, action) => {
      state.wallets = (action.payload as UpdateLoyaltyWalletsAction).data;
    },
    clearLoyaltyState: (state, action) => {
      state.rankGroups = [];
      state.wallets = [];
      state.loading = false;
      state.loaded = true;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRankSystems.fulfilled, (state, action) => {
        state.loading = false;

        state.rankGroups = action.payload.rank_groups;
        state.wallets = action.payload.wallets;

        console.log('fetchRankSystems.fulfilled', action.payload);
      })
      .addCase(fetchRankSystems.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRankSystems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        console.log('fetchRankSystems.rejected', action.payload);
      });
  },
});

export const { updateRankGroup, updateCurrentRank, updateLoyaltyWallets, clearLoyaltyState } = rankSystemsSlice.actions;

export default rankSystemsSlice.reducer;
