import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';

import './index.scss';

type LottoSystemsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LottoSystems = (componentProps: LottoSystemsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const contextValue = {};

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default LottoSystems;
