import { teamsConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  teams: [],
  loading: false,
};

const teamsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case teamsConstants.LOAD:
        draft.loading = true;
        draft.teams = [];
        break;
      case teamsConstants.LOADED:
        draft.loading = false;
        if (action.teams) {
          draft.teams = action.teams;
        }
        break;
      default:
      /* noop */
    }
  });

export default teamsReducer;
