import React from 'react';
import axios from 'axios';

import store from '@/store';
import { digitainConfig } from '../../../../modules/bets/api/config/digitain';
const apiUrl = window.config.dataSourceApiUrl;

const cache: any = {
  loading: {},
  ref: {},
};
window._gsi_cache = cache;

type TR = {
  '0'?: string;
  '2'?: string;
  '42'?: string;
};

type APIRequest = {
  query?: string;
  language: string;
  limit: number;
  sport_id?: string[];
  country_id?: string[];
  tournament_id?: string[];
  timeFrom?: string; // ISO 8601 date time format. ex. new Date().toISOString() => "2021-08-25T08:00:00.000Z"
  timeTo?: string; // ISO 8601 date time format. ex. new Date().toISOString() => "2021-08-25T08:00:00.000Z"
};

type BetsResult = {
  sport: {
    idSport: string;
    sportName: TR;
    sportShortName: TR;
    sportActive: boolean;
    sportPosition: number;
    sportType: string;
  };
  sport_id: {
    idSport: string;
    sportName: TR;
    sportShortName: TR;
    sportActive: boolean;
    sportPosition: number;
    sportType: string;
  };
  categories: {
    idCategory: string;
    idSport: string;
    categoryName: TR;
    categoryShortName: TR;
    categoryPosition: number;
    categoryIsoName: string;
    categoryActive: boolean;
    categoryDLId: number;
  }[];
  tournaments: {
    idTournament: string;
    idCategory: string;
    idSport: string;
    tournamentName: TR;
    tournamentShortName: TR;
    tournamentPosition: number;
    tournamentActive: boolean;
    tournamentPrefix: string;
  }[];
  markets: {
    bet: {
      idBet: string;
      idSport: string;
      betDisplayId: string;
      betName: TR;
      betShortName: TR;
      betActive: boolean;
      betCollapsed: boolean;
      betGroups: number[];
      betPosition: number;
      betGroupingEnabled: boolean;
      betType: string;
      headerBet: boolean;
      betOutcomes: any[];
    };
    mtype: string;
    match_date_time: number;
    id_sport: string;
    id_category: string;
    id_tournament: string;
    id_match: string;
    team1_name: TR;
    team2_name: TR;
  }[];
  events: {
    provider: string;
    matchType: number;
    mType: string;
    parentIdMatch: string;
    idMatch: string;
    partTypeId: number;
    periodId: number;
    matchDateTime: number;
    idSport: string;
    sportName: TR;
    sportShortName: TR;
    idCategory: string;
    categoryName: TR;
    categoryShortName: TR;
    idTournament: string;
    tournamentName: TR;
    tournamentShortName: TR;
    bettingStatus: boolean;
    active: boolean;
    position: number;
    team1Name: TR;
    idTeam1: number;
    team1ShortName: TR;
    team1Logo: number;
    team2Name: TR;
    idTeam2: number;
    team2ShortName: TR;
    team2Logo: number;
    hasLiveTV: boolean;
    hasScout: boolean;
    head2HeadId: number;
    headToHeadSportId: number;
    matchBets: [];
    DDTTCKS: number;
    currentStatus:
      | {
          HomeTeamScore: number;
          AwayTeamScore: number;
          LiveEventTime: number;
          LiveEventSecond: number;
          LiveSetScore: string;
          LiveSetAltScore: string;
          LiveGameScore: string;
          LiveServer: number;
          LiveMatchStatus: TR;
          CurrentPeriod: number;
          LiveScoresRCard: string;
          EventAddedExtraTime: number;
          MatchInfo: TR;
          IsLiveFinished: boolean;
          IsLiveStarted: boolean;
          LiveMatchShortStatus: TR;
        }
      | null
      | undefined;
    marketCount: number;
    competitors: {
      type: number;
      id: number;
      name: TR;
      shortName: TR;
    }[];
    periods: [];
    periodShortName: TR;
  }[];
};

export type SearchAPIResponse = {
  success?: boolean;
  result?: BetsResult[];
  bets: {
    success: boolean;
    result: BetsResult[];
  };
  lotto: {
    success: boolean;
    result: {
      events: {
        event_id: string;
        system_id: string;
        event_name: string;
        event_code: number;
        event_date: string;
        n: number;
        r: number;
        k: number;
        m: number;
        odds: number[];
      }[];
      total: number;
    };
  };
  casino: {
    success: boolean;
    result: {
      data: {
        id: string;
        provider_id: string;
        name: string;
        url: string;
        art_bundle: any;
        metadata_game_attribute_game_type: string;
        metadata_game_attribute_product_type: string;
        metadata_game_attribute_vibe: string[];
        metadata_game_attribute_visual_style: string[];
        metadata_game_attribute_visual_theme: string[];
      }[];
    };
  };
  promotions: {
    success: boolean;
    result: {
      data: {
        id: string;
        priority: 7;
        view: string;
        linked_object_type: string;
        linked_object_id: string;
        view_type: string;
        category_short: string;
        message_primary_short: string;
        message_secondary_short: string;
        category_long: string;
        message_primary_long: string;
        message_secondary_long: string;
        preview_description: string;
        full_description: string;
        cta_text_primary: string;
        cta_href_primary: string;
        cta_action_type_primary: string;
        cta_event_name_primary: string;
        cta_text_secondary: string;
        cta_href_secondary: string;
        cta_action_type_secondary: string;
        cta_event_name_secondary: string;
        start_date: string;
        end_date: string;
        image_type: string;
        art_bundle: any;
        metadata_tags: string;
      }[];
    };
  };
  id: string;
};

export const searchDigitainApiByQuery = async ({
  request,
}: {
  request: APIRequest;
}): Promise<{ data: SearchAPIResponse }> => {
  const dCfg = digitainConfig();
  const searchUrl = dCfg.searchUrl;

  const accessToken = store.getState().authentication?.access_token;
  const headers: any = {};
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return await axios.post(searchUrl, request, { headers: headers });
};

export const searchApiByQuery = async ({ request }: { request: APIRequest }): Promise<{ data: SearchAPIResponse }> => {
  const searchUrl = window.config.searchApiUrl;

  const accessToken = store.getState().authentication?.access_token;
  const headers: any = {};
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return await axios.post(`${searchUrl}/player/search`, request, { headers: headers });
};

export const getSearchInfo = async (): Promise<{ data: any }> => {
  const searchUrl = window.config.searchApiUrl;

  const accessToken = store.getState().authentication?.access_token;
  const headers: any = {};
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return await axios.get(`${searchUrl}/player/info`, { headers: headers });
};

export const cancelSearch = async ({ uuid }: { uuid: string }): Promise<{ data: any }> => {
  const searchUrl = window.config.searchApiUrl;

  const accessToken = store.getState().authentication?.access_token;
  const headers: any = {};
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return await axios.get(`${searchUrl}/player/cancel/${uuid}`, { headers: headers });
};

export const missSearch = async ({ uuid }: { uuid: string }): Promise<{ data: any }> => {
  const searchUrl = window.config.searchApiUrl;

  const accessToken = store.getState().authentication?.access_token;
  const headers: any = {};
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return await axios.get(`${searchUrl}/player/miss/${uuid}`, { headers: headers });
};

export const hitSearch = async ({
  uuid,
  action,
  params,
}: {
  uuid: string;
  action: string;
  params: any;
}): Promise<{ data: any }> => {
  const searchUrl = window.config.searchApiUrl;

  const accessToken = store.getState().authentication?.access_token;
  const headers: any = {};
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return await axios.post(`${searchUrl}/player/hit`, { uuid, action, params }, { headers: headers });
};
