import React from 'react';

import { DataElementContext } from '../common/DataElementContext';
import { PageDataContext } from '../../components/utils/PageDataProvider';

export const defaultProps = {
  properties: {
    componentId: null,
  },
  visibility: {},
};

export const ChildrenSlot = (componentProps) => {

  const dataElementContext = React.useContext(DataElementContext);
  const pageDataContext = React.useContext(PageDataContext);

  console.log('ChildrenSlot[pageDataContext]', pageDataContext);
  console.log('ChildrenSlot[dataElementContext]', dataElementContext);

  if (!pageDataContext.children) return null;
  return <DataElementContext.Provider value={dataElementContext}>{pageDataContext.children}</DataElementContext.Provider>;
};
