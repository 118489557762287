import store from '@/store';

interface GetLinkValueProperties {
  links: {
    [key: string]: any;
  };
  [key: string]: any;
}

export const applyLinksValues = (properties: GetLinkValueProperties, dataElementContext: any) => {
  const links = properties['links'] != null ? properties['links'] : null;

  if (links) {
    Object.keys(links).forEach((key) => {
      const p = links[key] && Array.isArray(links[key]) && links[key].length ? [...links[key]] : [];
      if (p && Array.isArray(p) && p.length) {
        const pv = p.shift();

        let go = true;
        let data;
        if (pv === '_site') {
          const state = store.getState();
          data = state.dataElements?.items?._site ? { _site: state.dataElements?.items?._site } : {};
        } else {
          data = dataElementContext;
        }
        let index = 0;
        const MAX_NESTED = 10;

        while (go && index < MAX_NESTED) {
          let hasValue = false;

          if (!Array.isArray(data)) {
            if (data[pv] != undefined) {
              let value = data[pv];

              while (p.length > 0) {
                const pv = p.shift();
                if (
                  typeof value === 'object' &&
                  value['element_type'] != null &&
                  value['data'] != null &&
                  value['data'][pv] != null
                ) {
                  value = value['data'][pv];
                } else if (value[pv] != null) {
                  value = value[pv];
                } else {
                  break;
                }
              }

              if (value != null) {
                try {
                  properties[key] = value;
                } catch (e) {
                  console.log('applyLinksValues', e);
                }
                if (typeof value === 'object' && value['element_type'] != null && value['data'] != null) {
                  properties[key] = value['data'];
                }
                hasValue = true;
              }
            }
          }

          if (hasValue) {
            go = false;
          } else {
            // maybe we need to check parent context
            if (data.parentContext != null) {
              data = data.parentContext;
            } else {
              go = false;
            }
          }

          index += 1;
        }
      }
    });
  }
};

export const propsApplyLinksValues = (props: GetLinkValueProperties, dataElementContext: any) => {
  const links = props?.properties['links'] != null ? props?.properties['links'] : null;

  if (links) {
    Object.keys(links).forEach((key) => {
      const p = links[key] && Array.isArray(links[key]) && links[key].length ? [...links[key]] : [];
      if (p && Array.isArray(p) && p.length) {
        const pv = p.shift();

        let go = true;
        let data;
        if (pv === '_site') {
          const state = store.getState();
          data = state.dataElements?.items?._site ? { _site: state.dataElements?.items?._site } : {};
        } else {
          data = dataElementContext;
        }
        let index = 0;
        const MAX_NESTED = 10;

        while (go && index < MAX_NESTED) {
          let hasValue = false;

          if (!Array.isArray(data)) {
            if (data[pv] != undefined) {
              let value = data[pv];

              while (p.length > 0) {
                const pv = p.shift();
                if (
                  typeof value === 'object' &&
                  value['element_type'] != null &&
                  value['data'] != null &&
                  value['data'][pv] != null
                ) {
                  value = value['data'][pv];
                } else if (value[pv] != null) {
                  value = value[pv];
                } else {
                  break;
                }
              }

              if (value != null) {
                try {
                  props.properties[key] = value;
                } catch (e) {
                  console.log('applyLinksValues', e);
                }
                if (typeof value === 'object' && value['element_type'] != null && value['data'] != null) {
                  props.properties[key] = value['data'];
                }
                hasValue = true;
              }
            }
          }

          if (hasValue) {
            go = false;
          } else {
            // maybe we need to check parent context
            if (data.parentContext != null) {
              data = data.parentContext;
            } else {
              go = false;
            }
          }

          index += 1;
        }
      }
    });
  }
};
