import React from 'react';
import styled from 'styled-components';
import { range } from 'lodash-es';
import { Carousel as CarouselStrap, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';

import './Carousel.scss';
import { useAppSelector } from '../../store';
import craftJsParser from '../../components/utils/craftJsParser';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    slidesNo: 3,
    dark: false,
    slide: true,
    fade: false,
    interval: 5000,
    ride: 'carousel',
    pause: false,
    enableTouch: true,
  },
};

const CarouselDiv = styled.div((props) => props.$styleText);

export const Carousel = (componentProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  let props = JSON.parse(JSON.stringify(tmpProps));

  const { properties } = props;

  const pageDataContext = React.useContext(PageDataContext);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === slidesNo - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slidesNo - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  let content = null;
  let slidesNo = Number(properties.slidesNo);
  let linkedNodes = props.linkedNodes;
  let keys = linkedNodes ? Object.keys(linkedNodes).sort() : [];
  if (keys && keys.length) {
    try {
      const data = getProjectDataFromContext(pageDataContext);

      content = keys.map((k, i) => {
        const slideContent = craftJsParser({
          craftState: data,
          rootNodeId: linkedNodes[k],
          pageId: props.refType === 'layout' ? null : props.refId,
          pageLayoutId: props.refType === 'layout' ? props.refId : null,
          pageType: props.refType,
        });

        return (
          <CarouselItem
            key={`slide_${i}`}
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            style={{ height: '100%' }}
          >
            {slideContent}
          </CarouselItem>
        );
      });
    } catch (err) {
      console.error(err);
    }
  }

  if (content == null) return null;

  return (
    <CarouselDiv className={`page-components-carousel ${props.className ?? ''}`} $styleText={props.styleText} style={props.style}>
      <CarouselStrap
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        dark={properties.dark}
        slide={properties.slide}
        fade={properties.fade}
        interval={properties.interval}
        ride={properties.ride}
        pause={properties.pause}
        enableTouch={properties.enableTouch}
        style={{ height: '100%' }}
      >
        <CarouselIndicators
          items={range(slidesNo).map((i) => ({ key: `slide_${i}` }))}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {content}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </CarouselStrap>
    </CarouselDiv>
  );
};
