import * as React from 'react';
import store, { useAppDispatch } from '../../../store';
import { getBonusAvailable, getEligibleBonuses } from '../../../modules/casino/store/actions/application';
import { requestWallet } from '../../../modules/casino/store/actions/wallet';
import { requestPendingWithdrawals } from '../../../modules/casino/store/actions/withdrawals';
import { freeSpinsFetch, freeSpinsFetchHistory } from '../../../modules/casino/store/actions/free_spins';
import { loadPrizes } from '../../../modules/tournaments-missions/store/actions/history';
import { freeBetsFetch, freeBetsFetchHistory } from '../../../modules/casino/store/actions/free_bets';
import { requestTransactionsHistory } from '../../../modules/casino/store/actions/transactions';
import { getAvatars, getData } from '../../../store/slices/social';

const triggerStateLoaderIfNeeded = (stateKey: string, options: any = {}) => {
  const dispatch = useAppDispatch();
  const [done, setDone] = React.useState(0);

  React.useEffect(() => {
    switch (stateKey) {
      case 'state.application.availableBonuses':
        dispatch(getBonusAvailable());
        break;
      case 'state.application.eligibleBonuses':
        dispatch(getEligibleBonuses());
        break;
      case 'state.pendingWidrawals':
        dispatch(requestPendingWithdrawals(null));
        break;
      case 'state.activeBonuses':
        dispatch(requestWallet());
        dispatch(freeSpinsFetch());
        dispatch(freeBetsFetch());
        break;
      case 'state.pendingBonuses':
        dispatch(loadPrizes(null));
        // dispatch(getBonusAvailable());
        break;
      case 'state.transactionsHistory':
        dispatch(requestTransactionsHistory(2));
        break;
      case 'state.bonusesHistory':
        dispatch(requestTransactionsHistory(3));
        break;
      case 'state.freeBetsHistory':
        dispatch(freeBetsFetchHistory(options.currentPage));
        break;
      case 'state.freeSpinsHistory':
        dispatch(freeSpinsFetchHistory(options.currentPage));
        break;
      case 'state.gameSessionsHistory':
        dispatch(requestTransactionsHistory(1));
        break;
      case 'state.social.avatars':
        if (store.getState().social.avatars.length === 0) dispatch(getAvatars({}));
        break;
      case 'state.social.nickname':
        if (store.getState().social.nickname === null) dispatch(getData({}));
        break;
      case 'state.social.avatar':
        if (store.getState().social.avatar === null) dispatch(getData({}));
        break;
      default:
        console.error('[triggerStateLoaderIfNeeded] Unknown state key', stateKey);
    }
  }, [stateKey]);

  return done;
};

export default triggerStateLoaderIfNeeded;
