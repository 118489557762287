import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';
import { uniqueId } from 'lodash-es';

import { useAppSelector, useAppDispatch } from '../../../store';
import { fetchSources } from '../../../store/slices/dataSources';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import Providers from '../../../common/providers';

import './index.scss';

type FilterOption = {
  id: number;
  name: string;
  label: string;
};

type SortOption = {
  id: string;
  label: string;
  icon: string;
};

type DataSplitterProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type Item = {
  id: string;
  name: string;
  provider_id: string;
};

type Items = Item[];

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const DataSplitterDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const DataSplitter = (componentProps: DataSplitterProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  //console.log('props', props);

  const navigate = useNavigate();
  const matches = useMatches();
  const dispatch = useAppDispatch();
  const [__id] = React.useState(uniqueId('unique-'));

  // try to initialize sourceId from the properties object
  let sourceId = props.properties?.targetId;

  if (props.properties && props.properties.targetIdFromPath && props.properties.pathParamKey) {
    if (matches && matches.length) {
      // there is no sourceId set as props; maybe were in a route that includes a :gameId param
      const match = matches[0];
      let pathParamKey = props.properties.pathParamKey;
      if (pathParamKey != null && pathParamKey[0] === ':') {
        pathParamKey = pathParamKey.slice(1);
      }
      if (match.params && match.params[pathParamKey] != null) {
        sourceId = match.params[pathParamKey];
        if (props?.properties?.pathParamKeyPrefix) {
          sourceId = `${props?.properties?.pathParamKeyPrefix}${sourceId}`;
        }
      }
    }
  }

  const sources = useAppSelector((state) => state.dataSources.items);
  const loading = useAppSelector((state) => state.dataSources.loading);
  const error = useAppSelector((state) => state.dataSources.error);
  const accessToken = useAppSelector((state) => state.authentication.access_token);
  const [viewStory, setViewStory] = React.useState<string | null>(null);
  const [viewIndex, setViewIndex] = React.useState<number | null>(null);
  const [viewIndexSecondary, setViewIndexSecondary] = React.useState<number | null>(null);
  const splideRefs = React.useRef<any>({
    main: null,
    secondary: null,
  });

  React.useEffect(() => {
    const hash = window.location.hash;

    if (!hash) return;
    if (hash.indexOf('#story:') === -1) return;

    const parts = hash.split('|');
    const story = hash.replace('#story:', '');
    const index = localStorage.getItem('storyIndex') ?? '0';

    setViewStory(story);
    setViewIndex(parseInt(index));
    setViewIndexSecondary(parseInt(index));
  }, []);

  React.useEffect(() => {
    if (accessToken != null) dispatch(fetchSources({ ids: [sourceId] }));
  }, [accessToken, matches]);

  const splitData = React.useMemo<{ [id: string]: any[] }>(() => {
    const ret: any = {};
    const items: any[] = sources[sourceId]?.data ?? [];

    const values = (props.properties?.values ?? '').split(',').map((v: string) => v.trim().toLocaleLowerCase());
    const field = props.properties?.field ?? '';
    const defaultValue = (props.properties?.defaultValue ?? '').trim();

    if (field && values.length) {
      items.forEach((item: any) => {
        const value = (item[field] ?? '').toLocaleLowerCase();
        if (values.includes(value)) {
          if (!ret[value]) ret[value] = [];
          ret[value].push(item);
        } else {
          if (!ret[defaultValue]) ret[defaultValue] = [];
          ret[defaultValue].push(item);
        }
      });
    }

    return ret;
  }, [sources[sourceId], props.properties?.field, props.properties?.values, props.properties?.defaultValue]);

  const onShowStory = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e?.currentTarget?.dataset?.storyId) {
      setViewStory(e.currentTarget.dataset.storyId);
      setViewIndex(parseInt(e.currentTarget.dataset.index ?? '0'));
      setViewIndexSecondary(parseInt(e.currentTarget.dataset.index ?? '0'));
    }
    // @ts-ignore
    else if (e?.target?.dataset?.storyId) {
      // @ts-ignore
      setViewStory(e.target.dataset.storyId);
      // @ts-ignore
      setViewIndex(parseInt(e.target.dataset.index ?? '0'));
      // @ts-ignore
      setViewIndexSecondary(parseInt(e.target.dataset.index ?? '0'));
    } else {
      setViewStory('');
      setViewIndex(null);
      setViewIndexSecondary(null);
      navigate('');
    }
  }, []);

  const onSetIndexSecondary = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e?.currentTarget?.dataset?.index != null) {
      setViewIndexSecondary(parseInt(e.currentTarget.dataset.index ?? '0'));
    }
    // @ts-ignore
    else if (e?.target?.dataset?.index != null) {
      // @ts-ignore
      setViewIndexSecondary(parseInt(e.target.dataset.index ?? '0'));
    }
  }, []);

  const onSplideMounted = (type: string, splide: any) => {
    splideRefs.current[type] = splide;
    if (splideRefs.current.main && splideRefs.current.secondary) {
      splideRefs.current.main.sync(splideRefs.current.secondary);
    }
  };

  React.useEffect(() => {
    if (viewIndexSecondary == null || viewIndex == null || viewStory == null) return;
    navigate('#story:' + viewStory);
  }, [viewIndexSecondary, viewIndex, viewStory]);

  const contextValue = React.useMemo(() => {
    let total = 0;
    splitData && Object.keys(splitData).forEach((key: any) => (total += splitData[key].length));

    return {
      splitData,
      total,
      loading: loading && total === 0,
      error: error,
      onShowStory,
      viewStory,
      viewIndex,
      onSetIndexSecondary,
      viewIndexSecondary,
      onSplideMounted,
      __id,
    };
  }, [
    splitData,
    loading,
    error,
    matches,
    onShowStory,
    viewStory,
    viewIndex,
    onSetIndexSecondary,
    viewIndexSecondary,
    onSplideMounted,
  ]);

  if (!sourceId) return null;

  //console.log('DataSplitter[contextValue]', contextValue, 'viewIndexSecondary', contextValue.viewIndexSecondary);

  return (
    <DataSplitterDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </DataSplitterDiv>
  );
};

export default DataSplitter;
