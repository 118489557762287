import ad from './ad.svg';
import ae from './ae.svg';
import af from './af.svg';
import ag from './ag.svg';
import ai from './ai.svg';
import al from './al.svg';
import am from './am.svg';
import an from './an.svg';
import ao from './ao.svg';
import aq from './aq.svg';
import ar from './ar.svg';
import as from './as.svg';
import at from './at.svg';
import au from './au.svg';
import aw from './aw.svg';
import ax from './ax.svg';
import az from './az.svg';
import ba from './ba.svg';
import bb from './bb.svg';
import bd from './bd.svg';
import be from './be.svg';
import bf from './bf.svg';
import bg from './bg.svg';
import bh from './bh.svg';
import bi from './bi.svg';
import bj from './bj.svg';
import bl from './bl.svg';
import bm from './bm.svg';
import bn from './bn.svg';
import bo from './bo.svg';
import bq from './bq.svg';
import br from './br.svg';
import bs from './bs.svg';
import bt from './bt.svg';
import bv from './bv.svg';
import bw from './bw.svg';
import by from './by.svg';
import bz from './bz.svg';
import ca from './ca.svg';
import cc from './cc.svg';
import cd from './cd.svg';
import cf from './cf.svg';
import cg from './cg.svg';
import ch from './ch.svg';
import ci from './ci.svg';
import ck from './ck.svg';
import cl from './cl.svg';
import cm from './cm.svg';
import cn from './cn.svg';
import co from './co.svg';
import cr from './cr.svg';
import cu from './cu.svg';
import cv from './cv.svg';
import cw from './cw.svg';
import cx from './cx.svg';
import cy from './cy.svg';
import cz from './cz.svg';
import de from './de.svg';
import dj from './dj.svg';
import dk from './dk.svg';
import dm from './dm.svg';
import DO from './do.svg';
import dz from './dz.svg';
import ec from './ec.svg';
import ee from './ee.svg';
import eg from './eg.svg';
import eh from './eh.svg';
import er from './er.svg';
import es from './es.svg';
import et from './et.svg';
import eu from './eu.svg';
import fi from './fi.svg';
import fj from './fj.svg';
import fk from './fk.svg';
import fm from './fm.svg';
import fo from './fo.svg';
import fr from './fr.svg';
import ga from './ga.svg';
import gb from './gb.svg';
import gd from './gd.svg';
import ge from './ge.svg';
import gf from './gf.svg';
import gg from './gg.svg';
import gh from './gh.svg';
import gi from './gi.svg';
import gl from './gl.svg';
import gm from './gm.svg';
import gn from './gn.svg';
import gp from './gp.svg';
import gq from './gq.svg';
import gr from './gr.svg';
import gs from './gs.svg';
import gt from './gt.svg';
import gu from './gu.svg';
import gw from './gw.svg';
import gy from './gy.svg';
import hk from './hk.svg';
import hm from './hm.svg';
import hn from './hn.svg';
import hr from './hr.svg';
import ht from './ht.svg';
import hu from './hu.svg';
import id from './id.svg';
import ie from './ie.svg';
import il from './il.svg';
import im from './im.svg';
import IN from './in.svg';
import io from './io.svg';
import iq from './iq.svg';
import ir from './ir.svg';
import is from './is.svg';
import it from './it.svg';
import je from './je.svg';
import jm from './jm.svg';
import jo from './jo.svg';
import jp from './jp.svg';
import ke from './ke.svg';
import kg from './kg.svg';
import kh from './kh.svg';
import ki from './ki.svg';
import km from './km.svg';
import kn from './kn.svg';
import kp from './kp.svg';
import kr from './kr.svg';
import kw from './kw.svg';
import ky from './ky.svg';
import kz from './kz.svg';
import la from './la.svg';
import lb from './lb.svg';
import lc from './lc.svg';
import li from './li.svg';
import lk from './lk.svg';
import lr from './lr.svg';
import ls from './ls.svg';
import lt from './lt.svg';
import lu from './lu.svg';
import lv from './lv.svg';
import ly from './ly.svg';
import ma from './ma.svg';
import mc from './mc.svg';
import md from './md.svg';
import me from './me.svg';
import mf from './mf.svg';
import mg from './mg.svg';
import mh from './mh.svg';
import mk from './mk.svg';
import ml from './ml.svg';
import mm from './mm.svg';
import mn from './mn.svg';
import mo from './mo.svg';
import mp from './mp.svg';
import mq from './mq.svg';
import mr from './mr.svg';
import ms from './ms.svg';
import mt from './mt.svg';
import mu from './mu.svg';
import mv from './mv.svg';
import mw from './mw.svg';
import mx from './mx.svg';
import my from './my.svg';
import mz from './mz.svg';
import na from './na.svg';
import nc from './nc.svg';
import ne from './ne.svg';
import nf from './nf.svg';
import ng from './ng.svg';
import ni from './ni.svg';
import nl from './nl.svg';
import no from './no.svg';
import np from './np.svg';
import nr from './nr.svg';
import nu from './nu.svg';
import nz from './nz.svg';
import om from './om.svg';
import pa from './pa.svg';
import pe from './pe.svg';
import pf from './pf.svg';
import pg from './pg.svg';
import ph from './ph.svg';
import pk from './pk.svg';
import pl from './pl.svg';
import pm from './pm.svg';
import pn from './pn.svg';
import pr from './pr.svg';
import ps from './ps.svg';
import pt from './pt.svg';
import pw from './pw.svg';
import py from './py.svg';
import qa from './qa.svg';
import re from './re.svg';
import ro from './ro.svg';
import rs from './rs.svg';
import ru from './ru.svg';
import rw from './rw.svg';
import sa from './sa.svg';
import sb from './sb.svg';
import sc from './sc.svg';
import sd from './sd.svg';
import se from './se.svg';
import sg from './sg.svg';
import sh from './sh.svg';
import si from './si.svg';
import sj from './sj.svg';
import sk from './sk.svg';
import sl from './sl.svg';
import sm from './sm.svg';
import sn from './sn.svg';
import so from './so.svg';
import sr from './sr.svg';
import ss from './ss.svg';
import st from './st.svg';
import sv from './sv.svg';
import sx from './sx.svg';
import sy from './sy.svg';
import sz from './sz.svg';
import tc from './tc.svg';
import td from './td.svg';
import tf from './tf.svg';
import tg from './tg.svg';
import th from './th.svg';
import tj from './tj.svg';
import tk from './tk.svg';
import tl from './tl.svg';
import tm from './tm.svg';
import tn from './tn.svg';
import to from './to.svg';
import tr from './tr.svg';
import tt from './tt.svg';
import tv from './tv.svg';
import tw from './tw.svg';
import tz from './tz.svg';
import ua from './ua.svg';
import ug from './ug.svg';
import um from './um.svg';
import us from './us.svg';
import uy from './uy.svg';
import uz from './uz.svg';
import va from './va.svg';
import vc from './vc.svg';
import ve from './ve.svg';
import vg from './vg.svg';
import vi from './vi.svg';
import vn from './vn.svg';
import vu from './vu.svg';
import wf from './wf.svg';
import ws from './ws.svg';
import xk from './xk.svg';
import ye from './ye.svg';
import yt from './yt.svg';
import za from './za.svg';
import zm from './zm.svg';
import zw from './zw.svg';

import gbENG from './gb-eng.svg';
import gbSCT from './gb-sct.svg';
import gbWLS from './gb-wls.svg';
import gbNIR from './gb-nir.svg';

export default {
  gbENG,
  gbSCT,
  gbWLS,
  gbNIR,
  ad,
  ae,
  af,
  ag,
  ai,
  al,
  am,
  an,
  ao,
  aq,
  ar,
  as,
  at,
  au,
  aw,
  ax,
  az,
  ba,
  bb,
  bd,
  be,
  bf,
  bg,
  bh,
  bi,
  bj,
  bl,
  bm,
  bn,
  bo,
  bq,
  br,
  bs,
  bt,
  bv,
  bw,
  by,
  bz,
  ca,
  cc,
  cd,
  cf,
  cg,
  ch,
  ci,
  ck,
  cl,
  cm,
  cn,
  co,
  cr,
  cu,
  cv,
  cw,
  cx,
  cy,
  cz,
  de,
  dj,
  dk,
  dm,
  DO,
  dz,
  ec,
  ee,
  eg,
  eh,
  er,
  es,
  et,
  eu,
  fi,
  fj,
  fk,
  fm,
  fo,
  fr,
  ga,
  gb,
  gd,
  ge,
  gf,
  gg,
  gh,
  gi,
  gl,
  gm,
  gn,
  gp,
  gq,
  gr,
  gs,
  gt,
  gu,
  gw,
  gy,
  hk,
  hm,
  hn,
  hr,
  ht,
  hu,
  id,
  ie,
  il,
  im,
  IN,
  io,
  iq,
  ir,
  is,
  it,
  je,
  jm,
  jo,
  jp,
  ke,
  kg,
  kh,
  ki,
  km,
  kn,
  kp,
  kr,
  kw,
  ky,
  kz,
  la,
  lb,
  lc,
  li,
  lk,
  lr,
  ls,
  lt,
  lu,
  lv,
  ly,
  ma,
  mc,
  md,
  me,
  mf,
  mg,
  mh,
  mk,
  ml,
  mm,
  mn,
  mo,
  mp,
  mq,
  mr,
  ms,
  mt,
  mu,
  mv,
  mw,
  mx,
  my,
  mz,
  na,
  nc,
  ne,
  nf,
  ng,
  ni,
  nl,
  no,
  np,
  nr,
  nu,
  nz,
  om,
  pa,
  pe,
  pf,
  pg,
  ph,
  pk,
  pl,
  pm,
  pn,
  pr,
  ps,
  pt,
  pw,
  py,
  qa,
  re,
  ro,
  rs,
  ru,
  rw,
  sa,
  sb,
  sc,
  sd,
  se,
  sg,
  sh,
  si,
  sj,
  sk,
  sl,
  sm,
  sn,
  so,
  sr,
  ss,
  st,
  sv,
  sx,
  sy,
  sz,
  tc,
  td,
  tf,
  tg,
  th,
  tj,
  tk,
  tl,
  tm,
  tn,
  to,
  tr,
  tt,
  tv,
  tw,
  tz,
  ua,
  ug,
  um,
  us,
  uy,
  uz,
  va,
  vc,
  ve,
  vg,
  vi,
  vn,
  vu,
  wf,
  ws,
  xk,
  ye,
  yt,
  za,
  zm,
  zw,
};
