class YahooPixel {
  static init(debug: boolean) {
    if (this.isActive()) {
      // @ts-ignore
      window.dotq = window.dotq || [];
      // @ts-ignore
      window.dotq.push({
        projectId: window.config.yahoo_projectid,
        properties: { pixelId: window.config.yahoo_pixelid, userEmail: 'skybax_dc@yahoo.com' },
      });
    } else {
      if (debug) {
        window.config.MarketingEvents.push('YahooPixel not enabled');
      }
    }
  }

  static isActive() {
    return window.config?.yahoo_projectid && window.config?.yahoo_pixelid;
  }
  static pushEvent(event: {
    projectId: string;
    properties: { pixelId: string; qstrings: { et: string; ec: string } };
  }) {
    if (this.isActive()) {
      // @ts-ignore
      window.dotq = window.dotq || [];
      // @ts-ignore
      window.dotq.push(event);
    }
  }
  static pushCustomEvent(ec: string) {
    if (this.isActive()) {
      this.pushEvent({
        // @ts-ignore
        projectId: window.config.yahoo_projectid,
        properties: {
          // @ts-ignore
          pixelId: window.config.yahoo_pixelid,
          qstrings: {
            et: 'custom',
            ec: ec,
          },
        },
      });
    }
  }
}

export default YahooPixel;
