import { configConstants } from './constants';

export const configLoaded = (data) => ({
  type: configConstants.LOADED,
  data,
});

export const configReload = () => ({
  type: configConstants.RELOAD,
});
