import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { useAppSelector } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { testValues } from '../../utils/functions';
import { SportType } from '../../utils/types';
import { navSportsUpdate } from '../../../../../modules/bets/store/actions/navSports';

import './index.scss';

type NavigatorProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Navigator = (componentProps: NavigatorProps) => {
  const uriMatches = useMatches();
  const sportsMeta = useAppSelector<any>((state) => state.bets.live.sports);
  const [extraTotal, setExtraTotal] = React.useState(0);
  const tid = React.useRef<any>(0);

  let sports: SportType[] = Object.values(sportsMeta);

  // only active sports
  sports = sports.filter((sport: SportType) => {
    return sport?.sportActive === true;
  });

  // sort sport by backend sport position
  sports.sort((a, b) => {
    return testValues(a?.sportPosition, b?.sportPosition);
  });

  let idSport = '';
  if (uriMatches?.length > 0) {
    uriMatches.find((match: any) => {
      if (match.params?.idSport) {
        idSport = match.params.idSport;
        return true;
      }
      return false;
    });
  }

  if (!idSport) idSport = '1';

  let selectedIndexSport = -1;
  let selectedSport = null;
  if (idSport) {
    selectedSport = sports.find((sport: SportType, index: number) => {
      if (sport?.idSport === idSport) {
        selectedIndexSport = index;
      }
      return sport?.idSport === idSport;
    });
  }

  const handleResize = (timeout: any) => {
    window.clearTimeout(tid.current);
    tid.current = window.setTimeout(
      () => {
        let baseTop = 0;
        let items: any = document.querySelectorAll('.wl-prematch-navigator.mobile>.list-wrapper>.list>.item-wrapper');
        if (items.length) baseTop = items[0].offsetTop;

        const total = sports.length;
        let extraTotal = 0;

        if (items) {
          items = Array.from(items);
          items.find((item: any, index: number) => {
            if (item.offsetTop > baseTop) {
              extraTotal = total - index;
              return true;
            }

            return false;
          });

          setExtraTotal(extraTotal);
        }
      },
      typeof timeout === 'number' ? timeout : 100,
    );
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    handleResize(10);
  }, [sportsMeta]);

  const contextValue = {
    sports,
    selectedSport,
    hiddenSport:
      selectedIndexSport > -1 && sports.length && extraTotal > 0 && selectedIndexSport >= sports.length - extraTotal
        ? true
        : false,
    extraTotal,
  };

  //console.log('Navigator[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Navigator;
