import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { incJackpotRefCount, decJackpotRefCount } from '../../../modules/casino/store/actions/jackpot';

import './index.scss';

type JackpotsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Jackpots = (componentProps: JackpotsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    //dispatch(incJackpotRefCount());
    return () => {
      //dispatch(decJackpotRefCount());
    };
  }, []);

  const { jackpotList } = useAppSelector((state) => ({
    jackpotList: state.jackpotList.list ? state.jackpotList.list : null,
  }));

  if (!jackpotList) return null;

  let jackpots = JSON.parse(JSON.stringify(Object.values(jackpotList)));

  jackpots = jackpots.map((jackpot: any) => {
    return {
      ...jackpot,
      maxLevel: jackpot.levels[0],
      remaningLevels: jackpot.levels.slice(1),
    };
  });

  const contextValue = {
    jackpots: jackpots,
  };

  //console.log(contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Jackpots;
