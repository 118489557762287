import React from 'react';
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MaterialCircularProgress from '@material-ui/core/CircularProgress';

import "./GratuitiesBoost.scss";

import Image from "./assets/image.png";

import { getWinnerFunState } from "../../store/selectors/winnerFunData";
import SimpleTimer from "../SimpleTimer/SimpleTimer";
import CircularProgress from "../CircularProgress/CircularProgress";

import { winCoinsCollectBoost, winCoinsSystemsLoad } from "../../store/actions/wincoins";
import { chooseTextLanguage, styleToObj, choseImage } from "../../utils/functions";

const GratuitiesBoost = (props) => {
  const { t, i18n, walletTotal, seasons, className, origData, winCoinsCollectBoost, winCoinsSystemsLoad } = props;

  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const imageType = isDesktop1024 ? "desktop" : "mobile";

  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (origData) {
      setData(origData);
      setProgress(0);
    }
  }, [origData]);

  const onCollect = () => {
    if (data.amount !== 0) {
      setLoading(true);
      (function (amount, walletTotal) {
        winCoinsCollectBoost({
          id: data.coin_system.id,
          cb: (success) => {
            setLoading(false);
          }
        });
      })(data.amount, walletTotal);
    }
  };

  React.useEffect(() => {
    if (data) {
      if (data.next_boost_time !== 0) {
        const timer = setInterval(() => {
          const dt = moment(data.next_boost_time, "x");
          const dtNow = moment();

          if (dt.isBefore(dtNow)) {
            setProgress(100);
          } else {
            let dtStart = moment(data.last_collect_time, "x").valueOf();

            let current = dtNow.valueOf() - dtStart;
            let want = dt.valueOf() - dtStart;

            setProgress((current * 100) / want);
          }
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [data]);

  let hasActiveEvent = false;
  let futureEventDate = null;

  const now = moment().valueOf();

  seasons.forEach((season, i) => {
    if (parseInt(season.endDate) < now) return;
    season.events.forEach((event) => {
      if (parseInt(event.startDate) <= now && now < parseInt(event.endDate)) {
        hasActiveEvent = true;
        return;
      }
      if (parseInt(event.endDate) < now) return;
      if (now < parseInt(event.startDate)) {
        if (!futureEventDate) futureEventDate = parseInt(event.startDate);
        return;
      }
    });
  });

  if (!futureEventDate && !hasActiveEvent) return null;
  if (!data || !data.coin_system) return null;

  if (futureEventDate) {
    futureEventDate = moment(futureEventDate, "x").format("YYYY-MM-DD HH:mm:ss");
  }

  const dateTime = moment(data.next_boost_time, "x").format("YYYY-MM-DD HH:mm:ss");

  const handleExpired = () => {
    setTimeout(() => {
      winCoinsSystemsLoad();
    }, 1000);
  };

  const dt = moment(data.next_boost_time, "x");
  const dtNow = moment();
  let isExpired = false;
  let isCollectable = false;

  if (dt.isBefore(dtNow) || data.next_boost_time === 0) {
    if (data.coin_system.prizes === data.boost) { // already expired
      isExpired = true;
    } else {
      isCollectable = true;
    }
  }

  let style = {};
  if (data.coin_system.meta.background) {
    style = styleToObj(data.coin_system.meta.background.style);
    style.backgroundImage = `url("${choseImage(data.coin_system.meta.background, imageType)}")`;
  }

  let content = null;

  try {
    content = (
      <div className={`wf-gratuities-boost ${className ? className : ""}`}>
        <div className="wf-header">
          <div className="wf-icon" style={styleToObj(data.coin_system.meta.headerIcon.style)}>
            <img src={choseImage(data.coin_system.meta.headerIcon, imageType)} alt="header icon" />
          </div>
          <div className="wf-title" style={styleToObj(data.coin_system.meta.headerTitle.style)}>{chooseTextLanguage(data.coin_system.meta.headerTitle.text, i18n.language, "Winner Boost")}</div>
        </div>
        {!hasActiveEvent && <div className="wf-notification">
          <div className="wf-wrapper">
            {t("Collect Winner BOOST and use them on your betslip to INCREASE the WIN. Begin collecting as soon as the next FUN event begins.")}
          </div>
        </div>}
        <div className="wf-body" style={style}>
          <div className="wf-first">
            <div className="wf-wrapper">
              <div className="wf-top">
                <img src={Image} alt="" />
              </div>
              {hasActiveEvent && <div className="wf-bottom">
                <div className="wf-boosts">
                  {[...Array(data.boost).keys()].map((t) => (<div key={t} className="wf-boost wf-claimed"></div>))}
                  {[...Array(data.coin_system.prizes - data.boost).keys()].map((t) => {
                    if (t === 0) {
                      return <div key={`unclaimed-${t}`} className={`wf-boost ${progress === 100 ? 'wf-claim pulse-anim' : 'wf-unclaimed'}`}>
                        <CircularProgress progress={progress} />
                      </div>;
                    }

                    return <div key={`unclaimed-${t}`} className="wf-boost wf-unclaimed">
                      <CircularProgress progress={0} />
                    </div>;
                  })}
                </div>
              </div>}
              {!hasActiveEvent && <div className="wf-bottom">
                <div className="wf-boosts">
                  {[...Array(data.coin_system.prizes).keys()].map((t) => {
                    return <div key={`unclaimed-${t}`} className="wf-boost wf-locked">
                    </div>;
                  })}
                </div>
              </div>}
            </div>
          </div>
          {hasActiveEvent && !isExpired && !isCollectable && <div className="wf-second">
            <div className="wf-timer-text">{t("NEXT IN")}:</div>
            <SimpleTimer date={dateTime} onExpired={handleExpired} render={
              (timer) => (<div className="wf-timer">
                <div>
                  <div className={`wf-title ${!timer.hours.enabled ? "off" : ""}`}>{timer.hours.value}</div>
                  <div className={`wf-subtitle`}>{t("HOURS")}</div>
                </div>
                <div>
                  <div className={`wf-title ${!timer.mins.enabled ? "off" : ""}`}>{timer.mins.value}</div>
                  <div className={`wf-subtitle`}>{t("MIN")}</div>
                </div>
                <div>
                  <div className={`wf-title ${!timer.secs.enabled ? "off" : ""}`}>{timer.secs.value}</div>
                  <div className={`wf-subtitle`}>{t("SEC")}</div>
                </div>
              </div>)
            } />

            <div className={`wf-collect ${loading ? 'wf-loading' : ''} ${data.amount ? 'wf-ready' : ''} `} onClick={onCollect} style={styleToObj(data.coin_system.meta.button.style)}>
              {chooseTextLanguage(data.coin_system.meta.button.text, i18n.language)}
              <div className={`wf-loader`}><MaterialCircularProgress /></div>
            </div>
          </div>}

          {hasActiveEvent && isExpired && <div className="wf-second">
            <div className="wf-title">{t("The bag is full!")}</div>
            <div className="wf-text">{t("Use Boost to place tickets to make room for the next ones.")}</div>


            <div className={`wf-collect ${loading ? 'wf-loading' : ''} ${data.boost !== 3 ? 'wf-ready' : ''} `} onClick={onCollect} style={styleToObj(data.coin_system.meta.button.style)}>
              {chooseTextLanguage(data.coin_system.meta.button.text, i18n.language)}
              <div className={`wf-loader`}><MaterialCircularProgress /></div>
            </div>
          </div>}

          {hasActiveEvent && isCollectable && <div className="wf-second">
            <div className="wf-title">{t("BOOST READY!")}</div>
            <div className="wf-text">{t("Collect the boost in order to start recharging the next one.")}</div>


            <div className={`wf-collect ${loading ? 'wf-loading' : ''} ${data.boost !== 3 ? 'wf-ready' : ''} `} onClick={onCollect} style={styleToObj(data.coin_system.meta.button.style)}>
              {chooseTextLanguage(data.coin_system.meta.button.text, i18n.language)}
              <div className={`wf-loader`}><MaterialCircularProgress /></div>
            </div>
          </div>}

          {!hasActiveEvent && <div className="wf-second">

            <SimpleTimer date={futureEventDate} onExpired={handleExpired} render={
              (timer) => {
                if (timer && timer.days && timer.days.value) {
                  return <React.Fragment>
                    <div className="wf-timer-text">{t("STARTING IN")}:</div>
                    <div className="wf-timer">
                      <div className="wf-days">
                        {timer.days.value} {t("wf.days")}
                      </div>
                    </div>
                  </React.Fragment>
                }
                return <React.Fragment>
                  <div className="wf-timer-text">{t("NEXT IN")}:</div>
                  <div className="wf-timer">
                    <div>
                      <div className={`wf-title ${!timer.hours.enabled ? "off" : ""}`}>{timer.hours.value}</div>
                      <div className={`wf-subtitle`}>{t("HOURS")}</div>
                    </div>
                    <div>
                      <div className={`wf-title ${!timer.mins.enabled ? "off" : ""}`}>{timer.mins.value}</div>
                      <div className={`wf-subtitle`}>{t("MIN")}</div>
                    </div>
                    <div>
                      <div className={`wf-title ${!timer.secs.enabled ? "off" : ""}`}>{timer.secs.value}</div>
                      <div className={`wf-subtitle`}>{t("SEC")}</div>
                    </div>
                  </div>
                </React.Fragment>
              }
            } />

            <div className={`wf-collect`} style={styleToObj(data.coin_system.meta.button.style)}>
              {chooseTextLanguage(data.coin_system.meta.button.text, i18n.language)}
            </div>
          </div>}
        </div>
      </div>
    );
  } catch (err) {
    console.log(err);
  }

  return content;
}

const mapStateToProps = state => {
  const wfs = getWinnerFunState(state);
  return {
    seasons: wfs.config && wfs.config.seasons ? wfs.config.seasons : [],
    walletTotal: wfs && wfs.wallet && wfs.wallet.total ? wfs.wallet.total : 0
  };
};

const mapActions = {
  winCoinsCollectBoost,
  winCoinsSystemsLoad
};

export default withTranslation()(connect(mapStateToProps, mapActions)(GratuitiesBoost));
