import * as actionTypes from '../actions/actionTypes';

const initialState = {
  requests: {},
  cooldown:
    window.config && window.config.cache_requests_ttl
      ? parseInt(window.config && window.config.cache_requests_ttl)
      : 600,
};

const reducer = (state = initialState, action) => {
  let requests = { ...state.requests };
  switch (action.type) {
    case actionTypes.requestManager.CREATE_REQUEST:
      requests[action.id] = {
        last_sent: parseInt(new Date().getTime() / 1000, 10),
      };
      return {
        ...state,
        requests: requests,
      };
    case actionTypes.requestManager.FAILED_REQUEST:
    case actionTypes.requestManager.RESET_ONE:
      requests[action.id] = {
        last_sent: 0,
      };
      return {
        ...state,
        requests: requests,
      };
    case actionTypes.requestManager.RESET:
      return {
        ...state,
        requests: {},
      };
    default:
      return state;
  }
};

export default reducer;
