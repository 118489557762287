import { digitainConstants } from './constants';

export const digitainInitialize = () => ({
    type: digitainConstants.INITIALIZE,
});

export const digitainSetMiniState = data => ({
    type: digitainConstants.SET_MINI_STATE,
    data,
});

export const digitainSetState = data => ({
    type: digitainConstants.SET_STATE,
    data,
});

export const digitainQueueUpdates = data => ({
    type: digitainConstants.QUEUE_UPDATES,
    data,
});

export const digitainMergeQueue = data => ({
    type: digitainConstants.MERGE_QUEUE,
    data,
});

export const digitainClearQueue = data => ({
    type: digitainConstants.CLEAR_QUEUE,
    data,
});

export const digitainPlayerLogin = data => ({
    type: digitainConstants.PLAYER_LOGIN,
    data,
});

export const digitainPlayerLogout = () => ({
    type: digitainConstants.PLAYER_LOGOUT,
});

export const digitainLiveUpdates = data => ({
    type: digitainConstants.LIVE_UPDATES,
    data,
});

export const digitainPrematchUpdates = data => ({
    type: digitainConstants.PREMATCH_UPDATES,
    data,
});

export const digitainSetBonusRules = data => ({
    type: digitainConstants.SET_BONUS_RULES,
    data,
});

export const digitainSetProgressiveState = (data, reset, last) => ({
    type: digitainConstants.SET_PROGRESSIVE_STATE,
    data,
    reset,
    last,
});

export const digitainSetBetsCacheKey = bkey => ({
    type: digitainConstants.SET_BETS_CACHE_KEY,
    bkey,
});
