import { authConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  token: null,
};

const authReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case authConstants.SUCCESS: {
        draft.token = action.token;
        break;
      }
      default:
        break;
    }
  });

export default authReducer;
