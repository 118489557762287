import store from '@/store';
import { betsSlipConstants } from '@/modules/bets/store/actions/constants';
import { cloneDeep } from 'lodash-es';

export const onBetToggle = (e: React.MouseEvent<HTMLElement>) => {
  e.stopPropagation();
  e.preventDefault();

  const target = e.currentTarget;

  if (target) {
    const mType = target.dataset.mtype;
    const idSport = target.dataset.idsport;
    const idMatch = target.dataset.idmatch;
    const idBet = target.dataset.idbet;
    const idMb = target.dataset.idmb;
    const idBo = target.dataset.idbo;
    const idMbo = target.dataset.idmbo;
    const shareTicketNumber = target.dataset.shareticketnumber;

    if (!idMatch) return;

    const bst: any = store.getState().bets;

    let m = null;

    if (mType === 'live') {
      if (idMatch in bst.live.matches) {
        m = bst.live.matches[idMatch];
      }
    } else {
      if (idMatch in bst.prematch.matches) {
        m = bst.prematch.matches[idMatch];
      }
    }

    if (m === null) {
      return;
    }

    const clonedMatch = cloneDeep(m);

    store.dispatch({
      type: betsSlipConstants.TOGGLE,
      mType,
      idSport,
      idMatch,
      idBet,
      idMb,
      idBo,
      idMbo,
      shareTicketNumber,
      match: clonedMatch,
      matches: {
        live: bst.live.matches,
        prematch: bst.prematch.matches,
      },
      combiningAllowed: bst.nsoft.combiningAllowed,
    });
  }
};
