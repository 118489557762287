import { put, delay, call } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as paths from './../../ClientAPI/paths';
import Utils from '../../utils/Utils';
import { fetchRankSystems } from '../../../../store/slices/rankSystems';

export function* requestGamesSaga(action) {
  const axios = yield Utils.getAxiosWithToken();
  // ClientAPI.getInstance();

  // yield console.log(ClientAPI.getStore());

  try {
    const response = yield axios({
      url: paths.games.LIST_GAMES,
      method: 'post',
      data: {
        responseType: 'games',
        listType: action.listType,
      },
    });

    if (!response) {
      throw new Error('[ERROR] Games response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error('[ERROR] Server response has no result!');
    }

    // yield console.log(response.result);
    yield put(actions.receivedGamesList(response.result, action.listType));
    yield delay(3000);
    yield put(actions.hideGamesLoadingProgress());
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* openGameLauncherSaga(action) {
  yield put(actions.setGameLauncher(true));
  yield put(actions.setGameId(action.gameId));
}

export function* requestGameUrlSaga(action) {
  // const store = ClientAPI.getStore().getState();
  // console.log(store);
  // if (!("user" === store.authentication.auth_type)) {
  //     return;
  // }

  const axios = ClientAPI.getInstance();

  try {
    const response = yield axios({
      url: paths.games.LAUNCH_URL,
      method: 'post',
      data: {
        platformType: window.config.platformType,
        gameId: action.gameId,
      },
    });

    if (!response) {
      throw new Error('[ERROR] URL response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error('[ERROR] Server response has no result!');
    }

    yield console.log(response.result);

    let responseData = response.result;
    if (typeof responseData['link'] === 'undefined') {
      throw new Error('[ERROR] URL link not found!');
    }

    yield console.log(response.result);
    yield put(actions.setGameUrl(responseData.link));
  } catch (error) {
    yield put(actions.setGameUrl('error'));
    return;
  }
}

export function* closeGameLauncherSaga(action) {
  yield put(actions.setGameUrl());
  yield put(actions.requestWallet());
  yield put(actions.resetOneRequest('get-bonus-available'));
  yield put(actions.getBonusAvailable());
  yield put(actions.resetOneRequest('get-eligible-bonuses'));
  yield put(actions.getEligibleBonuses());
  yield put(actions.freeSpinsFetch());
  yield put(actions.freeBetsFetch());
  yield delay(500);
  yield put(actions.setGameLauncher());
}

export function* reloadWalletsDataSaga(action) {
  yield put(actions.requestWallet());
  yield put(actions.resetOneRequest('get-bonus-available'));
  yield put(actions.getBonusAvailable());
  yield put(actions.resetOneRequest('get-eligible-bonuses'));
  yield put(actions.getEligibleBonuses());
  yield put(actions.freeSpinsFetch());
  yield put(actions.freeBetsFetch());
}

export function* closeRedirectGameLauncherSaga(action) {
  yield put(actions.setGameUrl());
  yield put(actions.setGameLauncher());
  yield delay(1000);
  yield call(action.callback);
}

export function* requestGameCollectionsSaga(action) {
  const axios = yield Utils.getAxiosWithToken();

  // const loading = ClientAPI.getStore().getState().games.requestInProgress;
  // const gameCollections = ClientAPI.getStore().getState().games;
  //
  // if (loading || gameCollections) return ;

  // if (alreadyRequested) {
  //     return ;
  // }

  try {
    const response = yield axios({
      url: paths.games.GAME_COLLECTIONS,
      method: 'post',
    });

    if (!response) {
      throw new Error('[ERROR] Game Collections response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Game Collections response has no 'result' property");
    }

    yield put(actions.receivedGameCollections(response.result));
  } catch (error) {
    console.log(error);
    return;
  }
}
