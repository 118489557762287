import { all, call } from 'redux-saga/effects';

import templateSaga from './template';
import playerSaga from './player';
import scoreSaga from './score';
import winCoinsSaga from './wincoins';
import walletSaga from './wallet';
import ticketSaga from './tickets';
import historySaga from './history';
import teamsSaga from './teams';

export function* winnerFunSaga() {
  //console.log("[saga] winnerFunSaga");
  yield all([
    call(templateSaga),
    call(playerSaga),
    call(scoreSaga),
    call(winCoinsSaga),
    call(walletSaga),
    call(ticketSaga),
    call(historySaga),
    call(teamsSaga),
  ]);
}
