import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { setMarketingAgreements } from '@/modules/casino/store/actions';

type ProfileSettingsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const ProfileSettings = (componentProps: ProfileSettingsProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const marketing_agreements = useAppSelector((state) => state.alerts.settings.marketing_agreements);

  const [marketingData, setMarketingData] = React.useState({
    marketing_sms: {
      checked: false,
    },
    marketing_email: {
      checked: false,
    },
    marketing_phone: {
      checked: false,
    },
    marketing_partners: {
      checked: false,
    },
  });

  React.useEffect(() => {
    setMarketingData({
      marketing_sms: {
        checked: marketing_agreements?.marketing_receive_sms === 1,
      },
      marketing_email: {
        checked: marketing_agreements?.marketing_receive_email === 1,
      },
      marketing_phone: {
        checked: marketing_agreements?.marketing_receive_phone === 1,
      },
      marketing_partners: {
        checked: marketing_agreements?.marketing_partners === 1,
      },
    });
  }, [marketing_agreements]);

  const handleCTAClick = (newMarketingData: any) => {
    const postData: any = {
      all: true,
      value: 0,
    };

    if (
      newMarketingData.marketing_sms.checked &&
      newMarketingData.marketing_email.checked &&
      newMarketingData.marketing_phone.checked &&
      newMarketingData.marketing_partners.checked
    ) {
      postData.value = 1;
    } else {
      if (newMarketingData.marketing_sms.checked) postData.marketing_receive_sms = 1;
      if (newMarketingData.marketing_email.checked) postData.marketing_receive_email = 1;
      if (newMarketingData.marketing_phone.checked) postData.marketing_receive_phone = 1;
      if (newMarketingData.marketing_partners.checked) postData.marketing_partners = 1;
    }
    dispatch(setMarketingAgreements(postData));
  };

  const toggleSwitch = (e: React.MouseEvent<HTMLElement>) => {
    const newMarketingData: any = { ...marketingData };
    const target = e.target as HTMLInputElement;

    newMarketingData[target.name] = {
      checked: target.checked,
    };
    setMarketingData(newMarketingData);
    handleCTAClick(newMarketingData);
  };

  const contextValue = React.useMemo(() => {
    return {
      toggleSwitch: toggleSwitch,
      marketingData: marketingData,
    };
  }, [toggleSwitch, marketingData]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default ProfileSettings;
