import { combineReducers } from 'redux';

import ticketsReducer from './tickets';

export const virtualsRootReducer = combineReducers({
  tickets: ticketsReducer,
});

const rootReducer = combineReducers({
  virtuals: virtualsRootReducer,
});

export default rootReducer;
