import React from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { incJackpotRefCount, decJackpotRefCount } from '../../../modules/casino/store/actions/jackpot';

import './index.scss';

type JackpotProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Jackpot = (componentProps: JackpotProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    //dispatch(incJackpotRefCount());
    return () => {
      //dispatch(decJackpotRefCount());
    };
  }, []);

  const matches = useMatches();
  let jackpotId = props.properties.targetId;

  if (props.properties.targetIdFromPath && props.properties.pathParamKey) {
    if (matches && matches.length) {
      const match = matches[0];
      if (match.params && match.params[props.properties.pathParamKey] != null) {
        jackpotId = match.params[props.properties.pathParamKey];
      }
    }
  }

  if (!jackpotId) return null;

  const jackpot = useAppSelector((state) =>
    state.jackpotList.list && state.jackpotList.list[jackpotId] != null ? state.jackpotList.list[jackpotId] : null,
  );

  if (!jackpot) return null;

  const contextValue = {
    jackpot: {
      ...jackpot,
      maxLevel: jackpot.levels[0],
      remaningLevels: jackpot.levels.slice(1),
    },
  };

  //console.log(contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Jackpot;
