import * as actionTypes from '../actions/actionTypes';

const initialState = {
  settings: {
    played_events: true,
    winning_ticket: false,
    winner_announcements: true,
    happy_hour: true,
    personalized_bonus: true,
    new_promotions: true,
    marketing_agreements: {
      marketing_receive_sms: false,
      marketing_receive_email: false,
      marketing_receive_phone: false,
      marketing_partners: false,
    },
  },
  messages: [
    {
      id: '1',
      read: false,
      title: 'Deposit and Receive a Surprise Bonus!',
      description: 'PLAY and WIN at the best casino around. These are your benefits:',
      date: '2020-11-20 12:33:00',
      link: '',
    },
    {
      id: '2',
      read: false,
      title: 'Deposit and Receive a Surprise Bonus!',
      description: 'PLAY and WIN at the best casino around. These are your benefits:',
      date: '2020-11-20 09:33:00',
      link: '',
    },
    {
      id: '3',
      read: true,
      title: 'Welcome to Lucky Casino!',
      description: 'PLAY and WIN at the best casino around. These are your benefits:',
      date: '2020-11-19 08:33:00',
      link: '',
    },
    {
      id: '4',
      read: true,
      title: 'Welcome to Lucky Casino!',
      description: 'PLAY and WIN at the best casino around. These are your benefits:',
      date: '2020-11-19 08:33:00',
      link: '',
    },
    {
      id: '5',
      read: true,
      title: 'Welcome to Lucky Casino!',
      description: 'PLAY and WIN at the best casino around. These are your benefits:',
      date: '2020-11-19 08:33:00',
      link: '',
    },
  ],
  notifications: [
    {
      id: '1',
      seen: false,
      read: false,
      text: 'Ti-a iesit inca un pariu (3 ramase).',
      date: '2020-11-20 10:16:00',
      link: '',
      type: 'sport',
    },
    {
      id: '2',
      seen: true,
      read: false,
      text: 'Ti-a iesit inca un pariu (3 ramase).',
      date: '2020-11-20 08:41:00',
      link: '',
      type: 'sport',
    },
    {
      id: '3',
      seen: true,
      read: false,
      text: 'Ti-a iesit inca un pariu (3 ramase).',
      date: '2020-11-20 10:16:00',
      link: '',
      type: 'wallet',
    },
    {
      id: '4',
      seen: false,
      read: false,
      text: 'Ti-a iesit inca un pariu (3 ramase).',
      date: '2020-11-18 08:41:00',
      link: '',
      type: 'wallet',
    },
    {
      id: '7',
      seen: true,
      read: false,
      text: 'Ti-a iesit inca un pariu (3 ramase).',
      date: '2020-11-18 08:41:00',
      link: '',
      type: 'wallet',
    },
    {
      id: '5',
      seen: false,
      read: false,
      text: 'Ti-a iesit inca un pariu (3 ramase).',
      date: '2020-11-20 10:16:00',
      link: '',
      type: 'bonus',
    },
    {
      id: '6',
      seen: false,
      read: false,
      text: 'Ti-a iesit inca un pariu (3 ramase).',
      date: '2020-11-19 08:41:00',
      link: '',
      type: 'bonus',
    },
  ],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.alerts.SET_NOTIFICATION_SETTING: {
      const key = action.params.value;
      const value = action.params.value;

      if (typeof state.settings[key] !== 'undefined' && state.settings[key] !== value) {
        const settings = { ...state.settings };
        settings[key] = value;

        return {
          ...state,
          settings
        };
      }

      return state;
    }
    case actionTypes.alerts.SET_NOTIFICATIONS_SEEN: {
      const ids = action.params.ids;
      const notifications = [...state.notifications];

      let hasChanged = false;
      notifications.forEach((n, i) => {
        if (ids.indexOf(n.id) > -1) {
          n.seen = true;
          hasChanged = true;
        }
      });

      if (!hasChanged) return state;

      return {
        ...state,
        notifications: notifications,
      };
    }
    case actionTypes.alerts.SET_NOTIFICATION_READ: {
      const id = action.params.id;
      const notifications = [...state.notifications];
      const index = notifications.findIndex(n => n.id === id);
      if (index > -1) {
        notifications[index].read = true;
      } else {
        return state;
      }

      return {
        ...state,
        notifications: notifications,
      };
    }
    case actionTypes.alerts.SET_MESSAGE_READ: {
      const id = action.params.id;
      const messages = [...state.messages];
      const index = messages.findIndex(n => n.id === id);
      if (index > -1) {
        messages[index].read = true;
      } else {
        return state;
      }

      return {
        ...state,
        messages: messages,
      };
    }
    case actionTypes.profile.RECEIVED_ACCOUNT: {
      const marketing_agreements = action?.data?.marketing_agreement ?? null;

      if (!marketing_agreements) return state;

      return {
        ...state,
        settings: {
          ...state.settings,
          marketing_agreements: {
            ...state.settings.marketing_agreements,
            ...marketing_agreements
          }
        }
      };
    }
    case actionTypes.alerts.SET_MARKETING_AGREEMENTS:
      if (action?.params?.all) {
        const newData = {
          ...state.settings.marketing_agreements,
          marketing_receive_sms: action.params.marketing_receive_sms ? action.params.marketing_receive_sms : action.params.value,
          marketing_receive_email: action.params.marketing_receive_email ? action.params.marketing_receive_email : action.params.value,
          marketing_receive_phone: action.params.marketing_receive_phone ? action.params.marketing_receive_phone : action.params.value,
          marketing_partners: action.params.marketing_partners ? action.params.marketing_partners : action.params.value,
        };

        return {
          ...state,
          settings: {
            ...state.settings,
            marketing_agreements: newData
          }
        };
      }

      if (typeof action.params.marketing_receive_sms !== 'undefined') {
        return {
          ...state,
          settings: {
            ...state.settings,
            marketing_agreements: {
              ...state.settings.marketing_agreements,
              ...action.params
            }

          }
        };

      } else {
        return {
          ...state,
          settings: {
            ...state.settings,
            marketing_agreements: {
              ...state.settings.marketing_agreements,
              [action.params.channel]: action.params.value
            }

          }
        };
      }

    default:
      return state;
  }
};

export default reducer;
