import { gamesConstants } from './constants';

export const loadGames = () => ({
  type: gamesConstants.LOAD,
});

export const loadingGames = () => ({
  type: gamesConstants.LOADING,
});

export const loadedGames = (games) => ({
  type: gamesConstants.LOADED,
  games,
});
