import { historyConstants } from './constants';

export const loadHistory = ({ groupId }) => ({
  type: historyConstants.LOAD,
  groupId: groupId,
});

export const loadingHistory = () => ({
  type: historyConstants.LOADING,
});

export const loadedHistory = ({ groupId, data }) => ({
  type: historyConstants.LOADED,
  groupId: groupId,
  data,
});

export const loadLeaderBoard = ({ tournamentId }) => ({
  type: historyConstants.LOAD_LEADER_BOARD,
  tournamentId: tournamentId,
});

export const loadingLeaderBoard = () => ({
  type: historyConstants.LOADING_LEADER_BOARD,
});

export const loadedLeaderBoard = ({ tournamentId, data }) => ({
  type: historyConstants.LOADED_LEADER_BOARD,
  tournamentId: tournamentId,
  data,
});

export const loadPrizes = (cb) => ({
  type: historyConstants.LOAD_PRIZES,
  cb: cb
    ? cb
    : () => {
        /**/
      },
});

export const loadedPrizes = ({ data }) => ({
  type: historyConstants.LOADED_PRIZES,
  data,
});
export const claimPrizeById = ({ id, cb }) => ({
  type: historyConstants.CLAIM_PRIZE_BY_ID,
  id,
  cb:
    cb && typeof cb === 'function'
      ? cb
      : () => {
          /**/
        },
});

export const claimPrizeByIdSuccess = () => ({
  type: historyConstants.CLAIM_PRIZE_BY_ID_SUCCESS,
});

export const claimPrizeByIdFailed = () => ({
  type: historyConstants.CLAIM_PRIZE_BY_ID_FAILED,
});

export const claimPrizeByIdLoading = () => ({
  type: historyConstants.CLAIM_PRIZE_BY_ID_LOADING,
});

export const claimPrizeByIdLoaded = () => ({
  type: historyConstants.CLAIM_PRIZE_BY_ID_LOADED,
});

export const claimPrize = ({ tournamentId, groupId, cb }) => ({
  type: historyConstants.CLAIM_PRIZE,
  tournamentId,
  groupId,
  cb:
    cb && typeof cb === 'function'
      ? cb
      : () => {
          /**/
        },
});

export const claimedPrize = ({ tournamentId, groupId }) => ({
  type: historyConstants.CLAIMED_PRIZE,
  tournamentId,
  groupId,
});

export const resetClaimPrizeError = () => ({
  type: historyConstants.RESET_CLAIM_PRIZE_ERROR,
});
