import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';

import { useAppSelector, useAppDispatch, RootState } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { digitainConfig } from '../../../../modules/bets/api/config/digitain';
import { betBuilderBetsUpdate, betBuilderOpenUpdate } from '../../../../modules/bets/store/actions/betBuilder';
import evbus from '../../../../utils/evbus';

import { buildOutcomes } from '../match-details';

type BetBuilderProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const extractUriParams = (match: any, props: any) => {
  let mType = props.properties.mType;
  let idSport = props.properties.idSport;
  let idCategory = props.properties.idCategory;
  let idTournament = props.properties.idTournament;
  let idMatch = props.properties.idMatch;

  let paramMType = props.properties.paramMType ?? 'mType';
  if (paramMType[0] === ':') paramMType = paramMType.substr(1);
  if (match.params && paramMType && match.params[paramMType] != null) {
    mType = match.params[paramMType];
  }

  if (mType === 'live-match') {
    mType = 'live';
  } else if (mType === 'pre-match') {
    mType = 'prematch';
  }

  let paramIdSport = props.properties.paramIdSport ?? 'idSport';
  if (paramIdSport[0] === ':') paramIdSport = paramIdSport.substr(1);
  if (match.params && paramIdSport && match.params[paramIdSport] != null) {
    idSport = match.params[paramIdSport];
  }

  let paramIdCategory = props.properties.paramIdCategory ?? 'idCategory';
  if (paramIdCategory[0] === ':') paramIdCategory = paramIdCategory.substr(1);
  if (match.params && paramIdCategory && match.params[paramIdCategory] != null) {
    idCategory = match.params[paramIdCategory];
  }

  let paramIdTournament = props.properties.paramIdTournament ?? 'idTournament';
  if (paramIdTournament[0] === ':') paramIdTournament = paramIdTournament.substr(1);
  if (match.params && paramIdTournament && match.params[paramIdTournament] != null) {
    idTournament = match.params[paramIdTournament];
  }

  let paramIdMatch = props.properties.paramIdMatch ?? 'idMatch';
  if (paramIdMatch[0] === ':') paramIdMatch = paramIdMatch.substr(1);
  if (match.params && paramIdMatch && match.params[paramIdMatch] != null) {
    idMatch = match.params[paramIdMatch];
  }
  return [mType, idSport, idCategory, idTournament, idMatch];
};

const getPrematchMatches = (state: RootState) => state.bets.prematch.matches;
const getLiveMatches = (state: RootState) => state.bets.live.matches;
const getMType = (state: RootState, mType: string, idMatch: string) => mType;
const getIDMatch = (state: RootState, mType: string, idMatch: string) => idMatch;

const getMatch = createSelector(
  [getPrematchMatches, getLiveMatches, getMType, getIDMatch],
  (prematchMatches: any, liveMatches: any, mType: string, idMatch: string) => {
    if (mType === 'live') {
      if (liveMatches[idMatch]) {
        return liveMatches[idMatch];
      }
    } else if (mType === 'prematch') {
      if (prematchMatches[idMatch]) {
        return prematchMatches[idMatch];
      }
    }
    return null;
  },
);

const getPrematchBets = (state: RootState) => state.bets.prematch.bets;
const getLiveBets = (state: RootState) => state.bets.live.bets;

const getBets = createSelector([getPrematchBets, getLiveBets, getMType], (prematchBets, liveBets, mType: string) => {
  if (mType === 'live') {
    return liveBets;
  } else if (mType === 'prematch') {
    return prematchBets;
  }
  return null;
});

const BetBuilder = (componentProps: BetBuilderProps) => {
  let props = componentProps;

  const uriMatches = useMatches();
  const dispatch = useAppDispatch();
  const authentication = useAppSelector((state) => state.authentication);
  const { i18n } = useTranslation();

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  // try to initialize gameId from the properties object
  let mType = props.properties.mType;
  let idSport = props.properties.idSport;
  let idCategory = props.properties.idCategory;
  let idTournament = props.properties.idTournament;
  let idMatch = props.properties.idMatch;
  const selectedMarketGroup = props.properties.selectedMarketGroup;

  if (props.properties.targetIdFromPath) {
    if (uriMatches && uriMatches.length) {
      // there is no gameId set as props; maybe were in a route that includes a :gameId param
      const match = uriMatches[0];

      [mType, idSport, idCategory, idTournament, idMatch] = extractUriParams(match, props);
    }
  }

  const [expanded, setExpanded] = React.useState<{ [index: string]: boolean }>({});
  const match = useAppSelector((state) => getMatch(state, mType, idMatch));
  const bets = useAppSelector((state) => getBets(state, mType, idMatch));

  const [state, setState] = React.useState({
    idMatch: idMatch,
    mType: mType,
  });

  const [selected, setSelected] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [evaluating, setEvaluating] = React.useState(false);
  const [betBuilderBets, setBetBuilderBets] = React.useState([]);
  const [betInfo, setBetInfo] = React.useState({
    bbf: 0,
    bbsq: 0,
    mbbf: 0,
    ssec: 0,
  });
  const handleEvent = (data: number, event: string) => {
    if (event === 'betBuilderDeleteAllBets') {
      setSelected([]);
    } else if (event === 'betBuilderDeleteBet') {
      setSelected((selected) => {
        const tmp = [...selected];
        if (tmp[data] != null) tmp.splice(data, 1);
        return tmp;
      });
    }
  };

  React.useEffect(() => {
    evbus.on('betBuilderDeleteBet', handleEvent);
    evbus.on('betBuilderDeleteAllBets', handleEvent);

    return () => {
      evbus.remove('betBuilderDeleteBet', handleEvent);
      evbus.remove('betBuilderDeleteAllBets', handleEvent);
      dispatch(betBuilderOpenUpdate(false));
    };
  }, []);

  React.useEffect(() => {
    if (idMatch && idMatch !== state.idMatch) {
      setSelected([]);
      dispatch(betBuilderBetsUpdate(idMatch, mType, [], {}));
      setState({ idMatch, mType });
    }
  }, [idMatch, state.idMatch, mType]);

  React.useEffect(() => {
    if (idMatch && idMatch === state.idMatch && window.config.betBuilderEnable === '1') {
      const dCfg = digitainConfig();

      const stakes: any = [];
      const stakesById: any = {};

      selected.forEach((s: any) => {
        stakes.push(s.idMbo);
        stakesById[s.idMbo] = true;
      });
      setEvaluating(true);
      axios
        .post(dCfg.betBuilderUrl + '/evaluate', {
          stakes: stakes,
          eventId: idMatch,
          language: i18n.language,
          isLive: state.mType === 'live',
        })
        .then((response) => {
          const bets = response.data.bets;
          bets.forEach((b: any) => {
            b.mbOutcomes.forEach((o: any) => {
              if (stakesById[o.idMbo]) {
                o.mboOddValue = 1;
              }
            });
          });
          setBetBuilderBets(response.data.bets);

          setBetInfo({
            bbf: response.data.bbf,
            bbsq: response.data.bbsq,
            mbbf: response.data.mbbf,
            ssec: response.data.ssec,
          });

          dispatch(
            betBuilderBetsUpdate(idMatch, mType, selected, {
              bbf: response.data.bbf,
              bbsq: response.data.bbsq,
              mbbf: response.data.mbbf,
              ssec: response.data.ssec,
              totalBets: response.data.bets?.length ?? 0,
            }),
          );
          //console.log('[BetBuilder] response', response);
          //evBusMain.emit('MATCH_DETAILS_SCROLLED', { direction: 'up', scrollTop: 0, lastScrollPos: 0 });
        })
        .catch((err) => {
          console.log('[BetBuilder ERROR] betbuilder evaluate failed', err);
        })
        .finally(() => {
          setEvaluating(false);
          setLoaded(true);
        });
    }
  }, [authentication, state.idMatch, state.mType, idMatch, i18n, selected]);

  // opens or closes a market
  const onMarketToggle = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.idmb) {
      const idmb = e.currentTarget.dataset.idmb;
      const exp = e.currentTarget.dataset.exp;
      setExpanded((v) => ({
        ...v,
        // toggle expanded state for a market; some markets are by default expanded at
        // first so we need to check for that
        [idmb]: v[idmb] != null ? !v[idmb] : exp === 'true' ? false : true,
      }));
    }
  }, []);

  const onToggleBet = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const target = e.currentTarget;

    if (target.dataset.idmb) {
      const mBoActive = target.dataset.mboactive;
      const mType = target.dataset.mtype;
      const idSport = target.dataset.idsport;
      const idMatch = target.dataset.idmatch;
      const idBet = target.dataset.idbet;
      const idMb = target.dataset.idmb;
      const idBo = target.dataset.idbo;
      const idMbo = target.dataset.idmbo;
      const betTitle = target.dataset.bettitle;
      const oddName = target.dataset.oddname;

      setSelected((selected) => {
        const tmp: any = [...selected];
        const idx = tmp.findIndex((s: any) => s.idMbo === idMbo);
        if (idx === -1) {
          tmp.push({
            idSport,
            idMatch,
            mType,
            idBet,
            idMb,
            idBo,
            idMbo,
            mBoActive,
            betTitle,
            oddName,
          });
        } else {
          tmp.splice(idx, 1);
        }
        return tmp;
      });
    }
  }, []);

  const contextValue = React.useMemo(() => {
    const finalMarkets: any = betBuilderBets
      .map((m: any, index) => {
        let exp = false;
        if (expanded[m.idMb] != null) {
          exp = expanded[m.idMb];
        } else if (index < 3) {
          exp = true;
        }

        // build the list of outcomes for each market
        const { marketName, outcomes } = buildOutcomes(m, match, bets, selected, i18n.language);

        let hasActive = false;
        outcomes.forEach((o: any) => {
          if (o.oddValue || o.selected) {
            hasActive = true;
          }
        });

        if (!hasActive) {
          return null;
        }

        return {
          idBet: m.idBet,
          idMb: m.idMb,
          expanded: exp,
          name: marketName,
          outcomes: outcomes,
        };
      })
      .filter((m: any) => m !== null);

    return {
      loaded,
      evaluating,
      betBuilderBets: finalMarkets,
      mType,
      idSport,
      idMatch,
      onMarketToggle,
      onToggleBet,
      selectedMarketGroup,
    };
  }, [
    mType,
    idSport,
    idMatch,
    betInfo,
    loaded,
    evaluating,
    betBuilderBets,
    i18n,
    selected,
    bets,
    match,
    expanded,
    onMarketToggle,
    onToggleBet,
    selectedMarketGroup,
  ]);

  if (!(window.config.betBuilderEnable === '1')) return null;

  //console.log('BetBuilder[contextValue]', contextValue, { match, bets });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetBuilder;
