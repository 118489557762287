import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import {
  momentumProgressDetails,
  starThresholds,
  detectBrowser,
  triggerGTMEvent,
} from '../../../../modules/momentum/utils/functions.js';
import { exitSlotGame } from '../../../action-handler/actions';

type MomentumWalletProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const MomentumWallet = (componentProps: MomentumWalletProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const prize = useAppSelector<any>((state) => state.momentum.prize?.value);
  const momentumStatus = useAppSelector<any>((state) => state.momentum.prize?.momentumDown);
  const source = useAppSelector<any>((state) => state.momentum.prize.source);

  const initialStarProgress = momentumProgressDetails(prize?.wager_progress ?? 0, starThresholds);
  const isNoAnim = detectBrowser();

  const contextValue = {};

  //console.log('Momentum[contextValue]: ', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default MomentumWallet;
