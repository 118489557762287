import { MatchType } from './types';

export const betUtilsConstants: { [code: string]: number } = {
  MATCH_NULL: -1,
  MATCH_BLOCKED: -2,
  BET_NULL: -3,
  BET_BLOCKED: -4,
  ODD_NULL: -5,
  ODD_BLOCKED: -6,
  ODD_CHANGED: -7,
};

export const betUtilsErrors: { [code: string]: string } = {
  '-1': 'Evenimentul nu mai face parte din oferta',
  '-2': 'Evenimentul este blocat',
  '-3': 'Pariul nu mai face parte din oferta',
  '-4': 'Pariul este blocat',
  '-5': 'Cota nu mai face parte din oferta',
  '-6': 'Cota este blocata',
  '-7': 'Cota s-a schimbat',
};

export const getOutcomeValue = (m: MatchType, idMb: string, idMbo: string) => {
  if (!m) {
    return betUtilsConstants.MATCH_NULL;
  }

  if (!m.bettingStatus) {
    return betUtilsConstants.MATCH_BLOCKED;
  }

  const bet = m.matchBets ? m.matchBets.find((mb) => mb.idMb === idMb) : null;

  if (!bet) {
    return betUtilsConstants.BET_NULL;
  }

  if (!bet.mbActive) {
    return betUtilsConstants.BET_BLOCKED;
  }

  const bo = bet.mbOutcomes ? bet.mbOutcomes.find((mbo) => mbo.idMbo === idMbo) : null;

  if (!bo) {
    return betUtilsConstants.ODD_NULL;
  }

  if (!bo.mboActive) {
    return betUtilsConstants.ODD_BLOCKED;
  }

  return bo.mboOddValue;
};

export const formatMessage = (...args: any[]) => {
  const txt = args[0];
  const parts = txt.split(/(%[dfs])/);

  const res: any = [];

  let found = 0;
  parts.forEach((p: any, i: number) => {
    if (/^%[dfs]$/.test(p)) {
      res.push(args[found + 1]);
      found += 1;
    } else {
      res.push(p);
    }
  });

  return res.join('');
};
