import { historyConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  seasons: [],
  loading: false,
};

const configReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case historyConstants.LOAD:
        draft.loading = true;
        draft.seasons = [];
        break;
      case historyConstants.LOADED:
        if (action.seasons) {
          draft.seasons = action.seasons;
        }
        draft.loading = false;
        break;
      default:
      /* noop */
    }
  });

export default configReducer;
