export const generateRandomInRange = (pCount: number, pMin: number, pMax: number) => {
  const min = pMin < pMax ? pMin : pMax;
  const max = pMax > pMin ? pMax : pMin;
  const resultArr: number[] = [];
  let randNumber: number;

  while (pCount > 0) {
    randNumber = Math.round(min + Math.random() * (max - min));
    if (resultArr.indexOf(randNumber) === -1) {
      resultArr.push(randNumber);
      pCount--;
    }
  }
  return resultArr;
};
