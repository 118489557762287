import { all, call } from 'redux-saga/effects';

import watchLottoEvents from './lotto-events';
import watchLottoResults from './lotto-results';
import watchWS from './ws';
import watchAuthSaga from './auth';
import watchTicketsSaga from './tickets';
import watchConfig from './app';

export function* lottoSaga() {
  yield all([
    call(watchLottoEvents),
    call(watchLottoResults),
    call(watchWS),
    call(watchAuthSaga),
    call(watchTicketsSaga),
    call(watchConfig),
  ]);
}
