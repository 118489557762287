import React from 'react';
import { withTranslation } from 'react-i18next';

import './puzzle.scss';
import Puzzle1 from '../assets/puzzle-left.png';
import Puzzle2 from '../assets/puzzle-center.png';
import Puzzle3 from '../assets/puzzle-right.png';
import DownArrowImg from '../assets/down-arrow.png';
import { getData, styleToObj } from '../../lobby/utils/functions';

const getParsedValue = (val, def) => {
  const parsedValue = parseFloat(val);
  if (!isNaN(parsedValue)) return parsedValue;
  return def;
};

const Puzzle = (props) => {
  const { cfg, status, t } = props;

  const [revealed, setRevealed] = React.useState(
    status && status.puzzle && status.puzzle.step ? status.puzzle.step : 0,
  );

  React.useEffect(() => {
    let timerId = 0;
    if (cfg && status) {
      timerId = setTimeout(() => {
        setRevealed(status.puzzle && status.puzzle.step ? status.puzzle.step : 0);
      }, 200);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [cfg, status]);

  let prizeImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  if (status && status.special_prize && cfg && cfg.prizes && revealed >= 1) {
    for (let i = 0; i < cfg.prizes.length; i++) {
      if (cfg.prizes[i].id === status.special_prize.id) {
        prizeImage = cfg.prizes[i].puzzleImage;
      }
    }
  }

  const style = {};
  if (cfg && cfg.parameters && cfg.parameters.puzzleInnerAnimation) {
    style.animationDuration = `${getParsedValue(cfg.parameters.puzzleInnerAnimation, 1400)}ms`;
  }

  const cover1 = getData(cfg, 'skinning.prize_reveal_image_1.url', '');
  const cover1Style = styleToObj(getData(cfg, 'skinning.prize_reveal_image_1.style', ''));
  const cover2 = getData(cfg, 'skinning.prize_reveal_image_2.url', '');
  const cover2Style = styleToObj(getData(cfg, 'skinning.prize_reveal_image_2.style', ''));
  const cover3 = getData(cfg, 'skinning.prize_reveal_image_3.url', '');
  const cover3Style = styleToObj(getData(cfg, 'skinning.prize_reveal_image_3.style', ''));

  const infoStyle = styleToObj(getData(cfg, 'skinning.info_prizes_css.style', ''));

  console.log({ cover1Style, cover2Style, cover3Style });

  return (
    <div className={'puzzle-container'}>
      <div className={`prize-image-wrapper ${revealed === 1 ? 'cut-half' : revealed === 2 ? 'cut-third' : ''}`}>
        <img src={prizeImage} alt="" className="prize-image" />
      </div>
      <div className={`puzzle1 ${revealed >= 1 ? 'animate' : ''}`} style={{ ...style, ...cover1Style }}>
        <img src={cover1 ? cover1 : Puzzle1} alt="" />
      </div>
      <div className={`puzzle2 ${revealed >= 2 ? 'animate' : ''}`} style={{ ...style, ...cover2Style }}>
        <img src={cover2 ? cover2 : Puzzle2} alt="" />
      </div>
      <div className={`puzzle3 ${revealed >= 3 ? 'animate' : ''}`} style={{ ...style, ...cover3Style }}>
        <img src={cover3 ? cover3 : Puzzle3} alt="" />
      </div>
      <div className="info">
        <div className="txt" style={infoStyle}>
          {t('Surprise prize at 3 spins with multiplier!')}
        </div>
        <div className="arrow-down">
          <img src={DownArrowImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Puzzle);
