import React from 'react';
import styled from 'styled-components';
import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

import {
  happyHourClearPrizeWon,
  happyHourClearStatusCompleted,
} from '../../../modules/casino/store/actions/happy_hour';
import { toggleHappyHoursSelected } from '../../../modules/tournaments-missions/store/actions/tournaments_groups';
import './index.scss';

type HappyHourWinPopupProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    hhType: string;
    dsType: string;
  };
};

const HappyHourWinPopupDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const HappyHourWinPopup = (componentProps: HappyHourWinPopupProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const dispatch = useAppDispatch();

  // the type of the HappyHour to be shown. eg. online-slots, bets-live, bets-prematch
  const hhType = props.properties?.hhType ?? 'online-slots';

  const [startDelay, setStartDelay] = React.useState(+new Date());

  const hhPrizeWon = useAppSelector<any>((state) => state.happyHour.prizeWon);
  const hhRunning = useAppSelector<any>((state) => state.happyHour.running);
  const playerProfile = useAppSelector<any>((state) => state.profile);
  const hhStatuses = useAppSelector<any>((state) => state.happyHour.status);
  const hhEnded = useAppSelector<any>((state) => state.happyHour.statusCompleted);

  const isPlayerHhWinner = () => {
    const prize = {
      me: false,
      isPrizeWon: false,
      amount: hhPrizeWon[hhType]?.amount ?? 0,
      playerInitials: hhPrizeWon[hhType]?.player_initials ?? '',
      prizeIndex: hhPrizeWon[hhType]?.prize_index ?? '',
      name: hhStatuses && hhStatuses[hhType] && hhStatuses[hhType].name ? hhStatuses[hhType].name : '',
      isSpecialPrize: false,
      isSpecialPrizeTitle: '',
      isSpecialPrizeImage: '',
      isSpecialPrizeDescription: '',
      isSpecialPrizeBackgroundImage: '',
    };

    if (playerProfile && hhPrizeWon && hhPrizeWon[hhType]) {
      prize.isPrizeWon = true;

      if (playerProfile.client_player_id === hhPrizeWon[hhType].winner_id) {
        prize.me = true;
      }

      if (typeof hhPrizeWon[hhType].custom !== 'undefined') {
        prize.isSpecialPrize = true;
        prize.isSpecialPrizeTitle = hhPrizeWon[hhType].custom.title ? hhPrizeWon[hhType].custom.title : '';
        prize.isSpecialPrizeImage = hhPrizeWon[hhType].custom.image ? hhPrizeWon[hhType].custom.image : '';
        prize.isSpecialPrizeDescription = hhPrizeWon[hhType].custom.description
          ? hhPrizeWon[hhType].custom.description
          : '';
        prize.isSpecialPrizeBackgroundImage = hhPrizeWon[hhType].custom.image_win_bg
          ? hhPrizeWon[hhType].custom.image_win_bg
          : '';
      }
    }

    return prize;
  };

  const isWinner = isPlayerHhWinner();

  const isHHCompleted = () => {
    if (hhEnded && hhEnded[hhType]) {
      return {
        endedEventName: hhEnded[hhType].event_name,
        totalAwarded: hhEnded[hhType].total_awarded,
        totalMissed: hhEnded[hhType].total_missed,
      };
    }
    return {
      endedEventName: '',
      totalAwarded: 0,
      totalMissed: 0,
    };
  };
  const isEnded = isHHCompleted();

  const hhIsRunning = hhRunning && hhRunning[hhType] ? hhRunning[hhType] : false;

  const clearHhState = () => {
    const now = +new Date();

    // can be dismissed after 2 seconds
    if (now - startDelay < 4000) {
      return;
    }

    dispatch(happyHourClearPrizeWon(hhType));
  };

  React.useEffect(() => {
    if (isWinner.isPrizeWon) {
      if (isWinner.me) {
        setStartDelay(+new Date());
      }

      setTimeout(() => {
        clearHhState();
      }, 10000);
    }
  }, [isWinner.isPrizeWon, happyHourClearPrizeWon]);

  const clearHhCompleted = () => {
    dispatch(toggleHappyHoursSelected(false));
    dispatch(happyHourClearStatusCompleted(hhType));
  };

  const clearNotAWinnerState = () => {
    dispatch(happyHourClearPrizeWon(hhType));
  };

  // console.log('DEBUG HappyHourWinPopup contextValue', { isEnded, hhEnded, hhType, hhPrizeWon });
  // if (hhPrizeWon && !hhPrizeWon?.[hhType]) return null;

  const contextValue = {
    me: isWinner.me,
    amount: isWinner.amount ? isWinner.amount / 100 : '',
    playerInitials: isWinner.playerInitials,
    prizeIndex: isWinner.prizeIndex,
    name: isWinner.name,
    endedEventName: isEnded.endedEventName,
    totalAwarded: isEnded.totalAwarded,
    totalMissed: isEnded.totalMissed,
    hhIsRunning: hhIsRunning,
    isPrizeWon: isWinner.isPrizeWon,
    // open winner popup
    openPopup: isWinner.isPrizeWon && isWinner.me,
    closePopup: () => clearHhState(),
    // open not a winner popup
    openNotAWinnerPopup: isWinner.isPrizeWon && !isWinner.me,
    closeNotAWinnerPopup: () => clearNotAWinnerState(),
    // open HH completed popup
    openHhEndPopup:
      isEnded.endedEventName && !isNaN(isEnded.totalAwarded) && !isNaN(isEnded.totalMissed) ? true : false,
    closeHhEndPopup: () => clearHhCompleted(),
    // special prize
    isSpecialPrize: isWinner.isSpecialPrize,
    isSpecialPrizeTitle: isWinner.isSpecialPrizeTitle,
    isSpecialPrizeImage: isWinner.isSpecialPrizeImage,
    isSpecialPrizeDescription: isWinner.isSpecialPrizeDescription,
    isSpecialPrizeBackgroundImage: isWinner.isSpecialPrizeBackgroundImage,
    selectedHHType: hhType,
  };

  // console.log('DEBUG HappyHourWinPopup contextValue', { contextValue, isEnded, hhEnded, hhType });

  return (
    <HappyHourWinPopupDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </HappyHourWinPopupDiv>
  );
};

export default HappyHourWinPopup;
