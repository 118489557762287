import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import craftJsParser from '../../components/utils/craftJsParser';
import { PageDataContext, getProjectDataFromContext } from '../../components/utils/PageDataProvider';
import { Collapse as CollapseComponent } from 'reactstrap';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

export const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    isOpen: false,
    horizontal: false,
  },
  visibility: {},
};

const CollapseDiv = styled.div((props) => props.$styleText);

export const Collapse = (componentProps) => {
  let props = componentProps;
  const pageDataContext = React.useContext(PageDataContext);

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const [isOpen, setIsOpen] = React.useState(props.properties.isOpen);
  React.useEffect(() => {
    setIsOpen(props.properties.isOpen);
  }, [props.properties.isOpen]);

  const onToggle = React.useCallback(
    (e) => {
      const target = e.target ?? e.currentTarget;

      if (target.dataset.collapsebutton === 'true') {
        if (typeof props.properties.onToggle === 'function') {
          e.stopPropagation();
          props.properties.onToggle();
        } else {
          e.stopPropagation();
          setIsOpen((v) => !v);
        }
        return;
      } else if (target.dataset.collapsebutton === 'false') {
        return;
      }

      e.stopPropagation();
      if (typeof props.properties.onToggle === 'function') {
        props.properties.onToggle();
      } else {
        setIsOpen((v) => !v);
      }
    },
    [props?.properties?.onToggle],
  );

  const contextValue = React.useMemo(() => {
    let buttonContent = null;
    let content = null;
    let hasError = false;

    try {
      const data = getProjectDataFromContext(pageDataContext);

      const buttonNodeId = props.linkedNodes['collapse-button'];

      if (!buttonNodeId) return null; // something is wrong; the referenced node to render this selected layout doesn't exists

      const contentNodeId = props.linkedNodes['collapse-content'];

      if (!contentNodeId) return null;

      buttonContent = craftJsParser({
        craftState: data,
        rootNodeId: buttonNodeId,
        pageId: props.refType === 'layout' ? null : props.refId,
        pageLayoutId: props.refType === 'layout' ? props.refId : null,
        pageType: props.refType,
        extendProps: {
          onClick: onToggle,
          className: isOpen ? 'is--open' : 'is--close',
        },
      });
      content = craftJsParser({
        craftState: data,
        rootNodeId: contentNodeId,
        pageId: props.refType === 'layout' ? null : props.refId,
        pageLayoutId: props.refType === 'layout' ? props.refId : null,
        pageType: props.refType,
      });
    } catch (err) {
      console.error(err);
      hasError = true;
    }

    return {
      buttonContent,
      content,
      hasError,
    };
  }, [onToggle, onToggle, pageDataContext, props.linkedNodes, props.refType, props.refId, isOpen]);

  if (!isVisible) return null;
  if (contextValue.hasError) return null;
  if (!contextValue.buttonContent) return null;
  if (!contextValue.content) return null;

  return (
    <CollapseDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      {contextValue.buttonContent}
      <CollapseComponent
        isOpen={typeof props.properties.onToggle === 'function' ? props.properties.isOpen : isOpen}
        horizontal={props.properties.horizontal}
      >
        {contextValue.content}
      </CollapseComponent>
    </CollapseDiv>
  );
};
