import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';
import getStore from '../../store';
import { ticketConstants } from '../actions/constants';
import { debug, uuidv4 } from '../../utils';
import moment from 'moment';

import {
  ticketWinnerFunOpenedListReceived,
  ticketWinnerFunSettledListReceived,
  ticketWinnerFunListError,
  ticketWinnerFunListRequesting,
  ticketWinnerFunHandleUpdated,
  ticketWinnerFunStoreOpen,
} from '../actions/tickets';
import { application as casinoAppConstants } from '../../../casino/store/actions/actionTypes';

import { getBetsState } from '../selectors/betData';

import { nSoftLiveWinnerFun, nSoftPrematch, nSoftReport } from '../../api/config/nsoft';
import { digitainWinnerFunConfig } from '../../api/config/digitain';
import {
  normalizeLiveTickets,
  normalizePreMatchTickets,
  adjustWinnerFunWinnings,
  //normalizeLiveReservedTickets,
  normalizePreMatchReservedTickets,
} from '../../utils/normalizeTickets';
import { normalizeDigitainLiveTicket, normalizeDigitainLiveTickets } from '../../utils/normalizeDigitainTickets';

//import preMatchTickets from "./pre-match-tickets.json";
//import preMatchTicketsReserved from "./pre-match-tickets-reserved.json";

import { playerApi, playerTickets } from './app';

import { appSaveErrorLog, appGetTicketMeta } from '../actions/app';

import { getLanguage } from '../../../utils/i18n';

//const ticketsUrl = "https://staging-sportsbook-sm-distribution-api.nsoft.com";
//const ticketsUrl = "https://services-ro2.7platform.com";
//const cpvUuid = "3657d0f3-f54d-4dc8-9077-578940875c8d";
//const cpvUuid = "d8d00130-5509-4e9c-b2b9-94af43812fc8";
//const productName = "LiveBetting";
//const socketCompanyUuid = "b99752b3-443c-4c80-b559-945a95c4b805";
//const companyUuid = "28ec1e93-68f0-49fd-9ab5-639d88169625";
//const companyUuid = "04301c5a-6b6c-4694-aaf5-f81bf665498c";
//const product = "live";
//const protocol = "sio1";
//const productInstanceId = 1017294;
//const productInstanceUuid = "8a3f73e2-0a45-42d5-9488-9b6ec164923a";

/*
https://services-staging.7platform.com/web/
ticketsHistory/${cpvUuid}.json
?count=1000&
cpvUuid=${cpvUuid}&
id_language=${language}&
isFullHistoryRequest=true
&product=LiveBetting
&timeFrom=2019-11-30+23:00:00&
timeTo=2019-12-04+23:00:00&
timezone=${timezone}
*/

function* handleRequestError(e, requestUuid) {
  yield put(appSaveErrorLog(JSON.stringify({ requestUuid }), 'Failed to get open ticket list', JSON.stringify(e)));
}

function* ticketOpenedListSaga(action) {
  debug('loading winner fun opened tickets');

  const state = getStore().getState();
  const bst = getBetsState(state);

  const { authentication } = state;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    //throw Error("not authenticated");
    return;
  }

  const { auth, app } = bst;

  // if (!auth || !auth.winnerFunDetails || !auth.winnerFunDetails.accessToken) {
  // 	return;
  // }

  yield put(ticketWinnerFunListRequesting({ val: true, key: 'opened' }));

  const requestUuid = uuidv4();

  try {
    // const liveReq = axios.get(
    // 	nSoftLiveWinnerFun.ticketsUrl + "/web/ticketsHistory/" + nSoftLiveWinnerFun.cpvUuid + ".json",
    // 	{
    // 		params: {
    // 			requestUuid,
    // 			count: 1000,
    // 			id_language: getLanguage(),
    // 			status: "OPEN,PENDING",
    // 			isFullHistoryRequest: true,
    // 			product: nSoftLiveWinnerFun.productName,
    // 			timezone: "Europe/Bucharest"
    // 		},
    // 		headers: {
    // 			Authorization: "Bearer " + auth.winnerFunDetails.accessToken,
    // 			"SEVEN-TP-TOKEN": auth.winnerFunDetails.tpToken,
    // 			"SEVEN-LOCALE": "en",
    // 			"HTTP-X-NAB-DP": "Web",
    // 			"HTTP-X-SEVEN-CLUB-UUID": nSoftLiveWinnerFun.companyUuid,
    // 			"HTTP-X-NAB-PRODUCTNAME": nSoftLiveWinnerFun.productName,
    // 			"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftLiveWinnerFun.productInstanceId
    // 		}
    // 	}
    // );

    const liveReq = Promise.resolve(null);

    // const prematchReq = axios.get(
    // 	nSoftPrematch.gatewayUrl + "/report/punters/" + auth.details.Uuid + "/ticket-list",
    // 	{
    // 		params: {
    // 			companyUuid: nSoftPrematch.companyUuid,
    // 			requestUuid,
    // 			//"filter[status]": "PENDING,ACCEPTED",
    // 			"filter[resolutionStatus]": "OPEN",
    // 			"filter[language]": getLanguage(),
    // 			timezone: "Europe/Bucharest"
    // 		},
    // 		headers: {
    // 			Authorization: "Bearer " + auth.details.accessToken,
    // 			"SEVEN-LOCALE": getLanguage(),
    // 			companyUuid: nSoftPrematch.companyUuid
    // 			//"HTTP-X-NAB-DP": "Web",
    // 			//"HTTP-X-SEVEN-CLUB-UUID": nSoftPrematch.companyUuid,
    // 			//"HTTP-X-NAB-PRODUCTNAME": nSoftPrematch.productName,
    // 			//"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftPrematch.productInstanceId
    // 		}
    // 	}
    // );

    const prematchReq = Promise.resolve(null);

    const digitainReq = axios.post(
      digitainWinnerFunConfig().ticketsUrl + '/tickets/list',
      {
        status: ['open'],
        offset: 0,
        limit: 1000,
      },
      {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      }
    );

    const promises = [liveReq, prematchReq, digitainReq].map(p =>
      p.catch(e => {
        handleRequestError(e, requestUuid);
      })
    );

    const [liveRes, prematchRes, digitainRes] = yield Promise.all(promises);

    /*debug(
      "liveTicketsRes",
      liveRes ? liveRes.data.data : null,
      "prematchTicketsRes",
      prematchRes ? prematchRes.data.data : null
    );*/

    //const [liveRes] = yield Promise.all([liveReq]);

    const liveTickets = liveRes ? normalizeLiveTickets(liveRes.data.data) : [];
    //let prematchTickets = normalizePreMatchTickets(preMatchTickets.data, bst);
    let prematchTickets = prematchRes ? normalizePreMatchTickets(prematchRes.data.data) : [];
    prematchTickets = prematchTickets.filter(m => m.status === 'OPEN');
    const digitainTickets = digitainRes ? normalizeDigitainLiveTickets(digitainRes.data.tickets) : [];

    let tids = liveTickets.map(lt => lt.idHash);
    tids = tids.concat(prematchTickets.map(pt => pt.idHash));
    const wfTickets = yield playerTickets(authentication.access_token, tids);

    debug('wfTickets', wfTickets);

    // filter out real tickets
    /*
    let ltr = liveTickets;
    if (app.playerTickets.live !== null) {
      ltr = liveTickets.filter(t => app.playerTickets.live.tickets.indexOf(t.idHash) !== -1);
    }

    let ptr = prematchTickets;
    if (app.playerTickets.prematch !== null) {
      ptr = prematchTickets.filter(t => app.playerTickets.prematch.tickets.indexOf(t.idHash) !== -1);
    }
    */

    let ltr = [];
    liveTickets.forEach(t => {
      if (!(t.idHash in wfTickets) || wfTickets[t.idHash] === null) {
        return;
      }

      adjustWinnerFunWinnings(t, wfTickets[t.idHash]);
      ltr.push(t);
    });

    let ptr = [];
    prematchTickets.forEach(t => {
      if (!(t.idHash in wfTickets) || wfTickets[t.idHash] === null) {
        return;
      }

      adjustWinnerFunWinnings(t, wfTickets[t.idHash]);
      ptr.push(t);
    });

    const result = [...ltr, ...ptr, ...digitainTickets];

    result.sort((a, b) => {
      if (a.createdAt > b.createdAt) return -1;
      if (b.createdAt > a.createdAt) return 1;

      return 0;
    });

    let enableMeta = action && action.data && action.data.ticketsFetchMeta ? true : false;
    if (enableMeta) {
      const liveMatches = {};
      const prematchMatches = {};

      result.forEach(t => {
        if (t.product === 'Live') {
          t.bets.forEach(b => {
            if (b.idMatch) {
              liveMatches[b.idMatch] = true;
            }
          });
        } else if (t.product === 'PreMatch') {
          t.bets.forEach(b => {
            if (b.idMatch) {
              prematchMatches[b.idMatch] = true;
            }
          });
        }
      });

      const pmm = Object.keys(prematchMatches);
      const lmm = Object.keys(liveMatches);

      if (pmm.length > 0 || lmm.length > 0) {
        yield put(appGetTicketMeta(pmm, lmm));
      }
    }

    yield put(ticketWinnerFunOpenedListReceived(result));
  } catch (e) {
    console.error('failed to get ticket list', e);
    yield put(appSaveErrorLog(JSON.stringify({ requestUuid }), 'Failed to get open ticket list', JSON.stringify(e)));
    yield put(ticketWinnerFunListRequesting({ val: false, key: 'opened' }));
    yield put(ticketWinnerFunListError(e.toString()));
  }
}

function fetchLive(app, auth, authentication, liveLimit, liveOffset) {
  return new Promise((resolve, reject) => {
    (async () => {
      const res = {
        tickets: [],
        filtered: 0,
        offset: liveOffset,
      };

      try {
        while (res.tickets.length < liveLimit) {
          debug(`fetching ${liveLimit} winner fun live tickets from ${res.offset}`);

          const requestUuid = uuidv4();

          const { data } = await axios.get(
            nSoftLiveWinnerFun.ticketsUrl + '/web/ticketsHistory/' + nSoftLiveWinnerFun.cpvUuid + '.json',
            {
              params: {
                requestUuid,
                count: liveLimit,
                offset: res.offset,
                id_language: getLanguage(),
                status: 'PAYEDOUT,WON,LOST',
                isFullHistoryRequest: true,
                product: nSoftLiveWinnerFun.productName,
                timezone: 'Europe/Bucharest',
              },
              headers: {
                Authorization: 'Bearer ' + auth.winnerFunDetails.accessToken,
                'SEVEN-TP-TOKEN': auth.winnerFunDetails.tpToken,
                'SEVEN-LOCALE': 'en',
                'HTTP-X-NAB-DP': 'Web',
                'HTTP-X-SEVEN-CLUB-UUID': nSoftLiveWinnerFun.companyUuid,
                'HTTP-X-NAB-PRODUCTNAME': nSoftLiveWinnerFun.productName,
                'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftLiveWinnerFun.productInstanceId,
              },
            }
          );

          if (!data || !data.data || data.data.length === 0) {
            break;
          }

          res.offset += data.data.length;

          let tickets = normalizeLiveTickets(data.data);
          let valid = [];
          let stop = false;

          const tids = tickets.map(t => t.idHash);
          const wfTickets = await playerTickets(authentication.access_token, tids);

          tickets.forEach(t => {
            if (!(t.idHash in wfTickets) || wfTickets[t.idHash] === null) {
              return;
            }

            adjustWinnerFunWinnings(t, wfTickets[t.idHash]);
            valid.push(t);
          });

          if (app.playerTickets.live !== null) {
            const lt = data.data[data.data.length - 1];
            const ct = lt.ticketDateTimeUTC;

            const first_ct = parseInt(app.playerTickets.live.first_ct, 10) * 1000;

            debug(
              `last live fetched ticket created at ${lt.ticketDateTimeUTC}, ${ct}, first_ct ${first_ct}, diff ${ct - first_ct
              }`
            );

            if (ct <= first_ct) {
              stop = true;
            }
          }

          debug(`got ${valid.length} live winner fun tickets`);

          res.tickets = res.tickets.concat(valid);

          if (data.data.length < liveLimit) stop = true;

          if (stop) break;
        }
      } catch (e) {
        reject(e);
      }

      resolve(res);
    })();
  });
}

function fetchPrematch(app, auth, authentication, prematchLimit, prematchOffset) {
  return new Promise((resolve, reject) => {
    (async () => {
      const res = {
        tickets: [],
        filtered: 0,
        offset: prematchOffset,
      };

      try {
        while (res.tickets.length < prematchLimit) {
          debug(`fetching ${prematchLimit} winner fun prematch tickets from ${res.offset}`);

          const requestUuid = uuidv4();

          const { data } = await axios.get(
            nSoftPrematch.gatewayUrl + '/report/punters/' + auth.details.Uuid + '/ticket-list',
            {
              params: {
                companyUuid: nSoftPrematch.companyUuid,
                requestUuid,
                //"filter[status]": "PENDING,ACCEPTED",
                'filter[resolutionStatus]': 'PAIDOUT,WON,LOST',
                'page[offset]': res.offset,
                'page[limit]': prematchLimit,
                'filter[language]': getLanguage(),
                timezone: 'Europe/Bucharest',
              },
              headers: {
                Authorization: 'Bearer ' + auth.details.accessToken,
                'SEVEN-LOCALE': getLanguage(),
                companyUuid: nSoftPrematch.companyUuid,
                //"HTTP-X-NAB-DP": "Web",
                //"HTTP-X-SEVEN-CLUB-UUID": nSoftPrematch.companyUuid,
                //"HTTP-X-NAB-PRODUCTNAME": nSoftPrematch.productName,
                //"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftPrematch.productInstanceId
              },
            }
          );

          if (!data || !data.data || data.data.length === 0) {
            break;
          }

          res.offset += data.data.length;

          let tickets = normalizePreMatchTickets(data.data);
          let valid = [];
          let stop = false;

          const tids = tickets.map(t => t.idHash);
          const wfTickets = await playerTickets(authentication.access_token, tids);

          tickets.forEach(t => {
            if (!(t.idHash in wfTickets) || wfTickets[t.idHash] === null) {
              return;
            }

            adjustWinnerFunWinnings(t, wfTickets[t.idHash]);
            valid.push(t);
          });

          if (app.playerTickets.prematch !== null) {
            const lt = data.data[data.data.length - 1];
            const ct = moment(lt.createdAt).utc().valueOf();

            const first_ct = parseInt(app.playerTickets.prematch.first_ct, 10) * 1000;

            debug(
              `last prematch fetched ticket created at ${lt.createdAt}, ${ct}, first_ct ${first_ct}, diff ${ct - first_ct
              }`
            );

            if (ct <= first_ct) {
              stop = true;
            }
          }

          debug(`got ${valid.length} prematch winner fun tickets`);

          res.tickets = res.tickets.concat(valid);

          if (data.data.length < prematchLimit) stop = true;

          if (stop) break;
        }
      } catch (e) {
        reject(res);
      }

      resolve(res);
    })();
  });
}

function fetchDigitain(auth, authentication, liveLimit, liveOffset) {
  return new Promise((resolve, reject) => {
    (async () => {
      const res = {
        tickets: [],
        filtered: 0,
        offset: liveOffset,
      };

      try {
        while (res.tickets.length < liveLimit) {
          debug(`fetching ${liveLimit} digitain live tickets from ${res.offset}`, authentication);

          const requestUuid = uuidv4();

          const { data } = await axios.post(
            digitainWinnerFunConfig().ticketsUrl + '/tickets/list',
            {
              status: ['won', 'lost', 'paidout'],
              offset: liveOffset,
              limit: liveLimit,
            },
            {
              headers: {
                Authorization: 'Bearer ' + authentication.access_token,
              },
            }
          );

          if (!data || !data.tickets || data.tickets.length === 0) {
            break;
          }

          res.offset += data.tickets.length;

          debug(`got ${data.tickets.length} live digitain tickets`);

          res.tickets = res.tickets.concat(data.tickets);

          if (data.tickets.length < liveLimit) break;
        }
      } catch (e) {
        reject(e);
      }

      resolve(res);
    })();
  });
}

function* ticketSettledListSaga(action) {
  //console.log("ticketSettledListSaga: action", action);
  const state = getStore().getState();
  const bst = getBetsState(state);

  debug('fetching winner fun settled tickets', action);

  const { authentication } = state;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    throw Error('not authenticated');
  }

  const { auth, app } = bst;

  //   if (!auth || !auth.winnerFunDetails || !auth.winnerFunDetails.accessToken) {
  //     return;
  //   }

  //console.log("bst", bst);
  yield put(ticketWinnerFunListRequesting({ val: true, key: 'settled' }));

  const requestUuid = uuidv4();

  let tsd = bst.ticketsWinnerFun.ticketsSettledData;

  // check if we need to reset
  if (action.data.page === 1) {
    tsd = {
      prematchOffset: 0,
      prematchAvailable: 0,
      prematchMore: true,
      liveOffset: 0,
      liveAvailable: 0,
      liveMore: true,
      digitainOffet: 0,
      digitainAvailable: 0,
      digitainMore: true,
    };
  }

  debug('tsd', tsd);

  const limit = 5;

  const liveLimit = 2 * limit - tsd.liveAvailable;
  let liveOffset = tsd.liveOffset;
  const prematchLimit = 2 * limit - tsd.prematchAvailable;
  let prematchOffset = tsd.prematchOffset;
  const digitainLimit = 2 * limit - tsd.digitainAvailable;
  let digitainOffset = tsd.digitainOffset;

  debug('tsd', tsd, 'liveLimit', liveLimit, 'prematchLimit', prematchLimit, 'digitainLimit', digitainLimit);

  try {
    let liveReq, prematchReq, digitainReq;

    if (false && tsd.liveMore && liveLimit > 0) {
      debug(`fetching ${liveLimit} live tickets from ${liveOffset}`);

      /*
            liveReq = axios.get(
        nSoftLiveWinnerFun.ticketsUrl + "/web/ticketsHistory/" + nSoftLiveWinnerFun.cpvUuid + ".json",
        {
          params: {
            requestUuid,
            count: liveLimit,
            offset: liveOffset,
            id_language: getLanguage(),
            status: "PAYEDOUT,WON,LOST",
            isFullHistoryRequest: true,
            product: nSoftLiveWinnerFun.productName,
            timezone: "Europe/Bucharest"
          },
          headers: {
            Authorization: "Bearer " + auth.winnerFunDetails.accessToken,
            "SEVEN-TP-TOKEN": auth.winnerFunDetails.tpToken,
            "SEVEN-LOCALE": "en",
            "HTTP-X-NAB-DP": "Web",
            "HTTP-X-SEVEN-CLUB-UUID": nSoftLiveWinnerFun.companyUuid,
            "HTTP-X-NAB-PRODUCTNAME": nSoftLiveWinnerFun.productName,
            "HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftLiveWinnerFun.productInstanceId
          }
        }
      );
            */

      liveReq = fetchLive(app, auth, authentication, liveLimit, liveOffset);
    } else {
      debug('no more live tickets to fetch or no need', tsd.liveMore, liveLimit);

      liveReq = Promise.resolve({
        tickets: [],
        offset: liveOffset,
      });
    }

    if (false && tsd.prematchMore && prematchLimit > 0) {
      debug(`fetching ${prematchLimit} prematch tickets from ${prematchOffset}`);

      /*
            prematchReq = axios.get(
        nSoftPrematch.gatewayUrl + "/report/punters/" + auth.winnerFunDetails.Uuid + "/ticket-list",
        {
          params: {
            companyUuid: nSoftPrematch.companyUuid,
            requestUuid,
            //"filter[status]": "PENDING,ACCEPTED",
            "filter[resolutionStatus]": "PAIDOUT,WON,LOST",
            "page[offset]": prematchOffset,
            "page[limit]": prematchLimit,
            timezone: "Europe/Bucharest"
          },
          headers: {
            Authorization: "Bearer " + auth.winnerFunDetails.accessToken,
            "SEVEN-LOCALE": getLanguage(),
            companyUuid: nSoftPrematch.companyUuid
            //"HTTP-X-NAB-DP": "Web",
            //"HTTP-X-SEVEN-CLUB-UUID": nSoftPrematch.companyUuid,
            //"HTTP-X-NAB-PRODUCTNAME": nSoftPrematch.productName,
            //"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftPrematch.productInstanceId
          }
        }
      );
            */

      prematchReq = fetchPrematch(app, auth, authentication, prematchLimit, prematchOffset);
    } else {
      debug('no more prematch tickets to fetch or no need', tsd.prematchMore, prematchLimit);

      prematchReq = Promise.resolve({
        tickets: [],
        offset: prematchOffset,
      });
    }

    if (tsd.digitainMore && digitainLimit > 0) {
      digitainReq = fetchDigitain(auth, authentication, digitainLimit, digitainOffset);
    } else {
      debug('no more digitain live tickets to fetch or no need', tsd.digitainMore, digitainLimit);

      digitainReq = Promise.resolve({
        tickets: [],
        offset: digitainOffset,
      });
    }

    const promises = [liveReq, prematchReq, digitainReq].map(p =>
      p.catch(e => {
        console.log('error fetching tickets', e);
        handleRequestError(e, requestUuid);
      })
    );

    const [liveRes, prematchRes, digitainRes] = yield Promise.all(promises);

    const liveResTickets = liveRes ? liveRes.tickets : [];
    let prematchResTickets = prematchRes ? prematchRes.tickets : [];
    let digitainResTickets = digitainRes ? digitainRes.tickets : [];

    /*debug(
      "settled",
      "liveTicketsRes",
      liveResTickets,
      "prematchTicketsRes",
      prematchResTickets
    );*/

    /*
    const liveTickets = normalizeLiveTickets(liveResTickets);
    let prematchTickets = normalizePreMatchTickets(prematchResTickets);
    */

    const liveTickets = liveResTickets;
    let prematchTickets = prematchResTickets;
    let digitainTickets = normalizeDigitainLiveTickets(digitainResTickets);

    // if we didn't get the number of tickets we asked for, then there are no more tickets
    const liveMore = tsd.liveMore && liveResTickets.length >= liveLimit;
    const prematchMore = tsd.prematchMore && prematchResTickets.length >= prematchLimit;
    const digitainMore = tsd.digitainMore && digitainResTickets.length >= digitainLimit;

    // advance offset pointers
    liveOffset = liveRes.offset;
    prematchOffset = prematchRes.offset;
    digitainOffset = digitainRes.offset;

    const previouslyFetched = action.data.page === 1 ? [] : bst.ticketsWinnerFun.ticketsSettledFetched;

    // put in the total result all tickets fetched now + tickets fetched last time
    const resultTotal = [...liveTickets, ...prematchTickets, ...digitainTickets, ...previouslyFetched];

    debug(
      'liveMore',
      liveMore,
      'prematchMore',
      prematchMore,
      'digitainMore',
      'new liveOffset',
      liveOffset,
      'new prematchOffset',
      prematchOffset,
      'new digitainOffset',
      digitainOffset,
      'result length',
      resultTotal.length
    );

    resultTotal.sort((a, b) => {
      if (a.createdAt > b.createdAt) return -1;
      if (b.createdAt > a.createdAt) return 1;

      return 0;
    });

    // take from the resulted total what we need
    const result = resultTotal.slice(0, 2 * limit);

    // remember as fetched what's left
    const fetched = resultTotal.slice(2 * limit);

    debug('result length', result.length, 'fetched length', fetched.length);

    // get events for which we need to get addtional metadata
    const liveMatches = {};
    const prematchMatches = {};

    result.forEach(t => {
      if (t.product === 'Live') {
        t.bets.forEach(b => {
          if (b.idMatch) {
            liveMatches[b.idMatch] = true;
          }
        });
      } else if (t.product === 'PreMatch') {
        t.bets.forEach(b => {
          if (b.idMatch) {
            prematchMatches[b.idMatch] = true;
          }
        });
      }
    });

    debug('liveMatches', liveMatches, 'prematchMatches', prematchMatches);

    const pmm = Object.keys(prematchMatches);
    const lmm = Object.keys(liveMatches);

    if (pmm.length > 0 || lmm.length > 0) {
      yield put(appGetTicketMeta(pmm, lmm));
    }

    // count the number of tickets we have left that are already fetched
    let liveAvailable = 0;
    let prematchAvailable = 0;
    let digitainAvailable = 0;

    fetched.forEach(t => {
      if (t.product === 'Live') {
        liveAvailable = liveAvailable + 1;
      } else if (t.product === 'PreMatch') {
        prematchAvailable = prematchAvailable + 1;
      } else if (t.product === 'DigitainLive') {
        digitainAvailable = digitainAvailable + 1;
      } else {
        getStore().dispatch(
          appSaveErrorLog(JSON.stringify({ requestUuid }), 'Ticket without product', JSON.stringify(t))
        );
      }
    });

    yield put(
      ticketWinnerFunSettledListReceived({
        rows: result,
        page: action.data.page,
        tsd: {
          prematchOffset,
          prematchAvailable,
          prematchMore,
          liveOffset,
          liveAvailable,
          liveMore,
          digitainOffset,
          digitainAvailable,
          digitainMore,
        },
        fetched,
      })
    );
  } catch (e) {
    console.log('failed to get ticket list', e);
    yield put(appSaveErrorLog(JSON.stringify({ requestUuid }), 'Failed to get settled ticket list', JSON.stringify(e)));
    yield put(ticketWinnerFunListRequesting({ val: false, key: 'settled' }));
    yield put(ticketWinnerFunListError(e.toString()));
  }
}

function* ticketReloadPrematchSaga(action) {
  const state = getStore().getState();
  const bst = getBetsState(state);
  const { auth } = bst;

  axios
    .get(
      nSoftPrematch.ticketsUrl +
      '/web/tickets/request/' +
      action.t.requestUuid +
      '/product/' +
      nSoftPrematch.productName +
      '/check.json',
      {
        headers: {
          Authorization: 'Bearer ' + auth.winnerFunDetails.accessToken,
          'SEVEN-TP-TOKEN': auth.winnerFunDetails.tpToken,
          //companyUuid: ticketCompanyUuid,
          'SEVEN-LOCALE': getLanguage(),
          'HTTP-X-NAB-DP': 'Web',
          'HTTP-X-SEVEN-CLUB-UUID': nSoftPrematch.companyUuid,
          'HTTP-X-NAB-PRODUCTNAME': nSoftPrematch.productName,
          'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftPrematch.productInstanceUuid,
        },
      }
    )
    .then(res => {
      debug(`reloaded prematch ticket ${action.t.id}`, res.data);
      getStore().dispatch(ticketWinnerFunHandleUpdated(normalizePreMatchTickets([res.data])));
    })
    .catch(e => {
      //console.log("prematch check ticket error", e);
      getStore().dispatch(
        appSaveErrorLog(JSON.stringify({ id: action.t.id }), 'Error reloading prematch ticket', JSON.stringify(e))
      );
    });
}

function* ticketReloadLiveSaga(action) {
  const state = getStore().getState();
  const bst = getBetsState(state);
  const { auth } = bst;

  axios
    .get(
      nSoftLiveWinnerFun.ticketsUrl +
      '/web/tickets/request/' +
      action.t.requestUuid +
      '/product/' +
      nSoftLiveWinnerFun.productName +
      '/check.json',
      {
        headers: {
          Authorization: 'Bearer ' + auth.winnerFunDetails.accessToken,
          'SEVEN-TP-TOKEN': auth.winnerFunDetails.tpToken,
          //companyUuid: ticketCompanyUuid,
          'SEVEN-LOCALE': getLanguage(),
          'HTTP-X-NAB-DP': 'Web',
          'HTTP-X-SEVEN-CLUB-UUID': nSoftLiveWinnerFun.companyUuid,
          'HTTP-X-NAB-PRODUCTNAME': nSoftLiveWinnerFun.productName,
          'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftLiveWinnerFun.productInstanceUuid,
        },
      }
    )
    .then(res => {
      debug(`reloaded live ticket ${action.t.id}`, res.data);
      const bst = getBetsState(getStore().getState());
      getStore().dispatch(ticketWinnerFunHandleUpdated(normalizeLiveTickets([res.data], bst)));
    })
    .catch(e => {
      //console.log("live check ticket error", e);
      getStore().dispatch(
        appSaveErrorLog(JSON.stringify({ id: action.t.id }), 'Error reeloading live ticket', JSON.stringify(e))
      );
    });
}

function* ticketStoreOpenPrepareSaga(action) {
  debug('winner fun prepare store open', action);

  if (action.data.length === 0) {
    return;
  }

  const newTicket = action.data[0];

  const state = getStore().getState();

  const { authentication } = state;

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  try {
    const wfTickets = yield playerTickets(authentication.access_token, [newTicket.idHash]);
    adjustWinnerFunWinnings(newTicket, wfTickets[newTicket.idHash]);
  } catch (e) {
    console.error(`failed to fetch created ticket ${newTicket.idHash}`, e);
  }

  yield put(ticketWinnerFunStoreOpen([newTicket]));
}

export default function* watchTicketsSaga() {
  //console.log("watching tickets");
  yield takeEvery(casinoAppConstants.REINITIALIZE, ticketOpenedListSaga);
  yield takeEvery(ticketConstants.WINNER_FUN_OPENED_LIST_REQUEST, ticketOpenedListSaga);
  yield takeEvery(ticketConstants.WINNER_FUN_SETTLED_LIST_REQUEST, ticketSettledListSaga);
  yield takeEvery(ticketConstants.WINNER_FUN_RELOAD_PREMATCH, ticketReloadPrematchSaga);
  yield takeEvery(ticketConstants.WINNER_FUN_RELOAD_LIVE, ticketReloadLiveSaga);
  yield takeEvery(ticketConstants.WINNER_FUN_STORE_OPEN_PREPARE, ticketStoreOpenPrepareSaga);
}
