import React from 'react';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import urlonStringify from './urlon';

import { useAppSelector } from '../store';

const apiUrl = window.config.dataSourceApiUrl;

const cache: any = {
  loading: {},
  ref: {},
};
window._gi_cache = cache;

type GameInfoProps = {
  dsId?: string;
  gameId: any;
  authenticationToken?: string;
};

export const updateGamesCache = (games: any[], dsId?: string) => {
  if (Array.isArray(games)) {
    games.forEach((g) => {
      cache[g.id] = {
        id: dsId ?? window.config.dataSourceAllGames,
        gameId: g.id,
        data: g,
      };
    });
  }
};

const resolveGameInfo = ({ dsId, gameId, authenticationToken }: GameInfoProps) => {
  //console.log('resolveGameInfo', { dsId, gameId, authenticationToken });
  const [state, setState] = React.useState<{ data: any; loaded: boolean }>({
    data: {
      id: null,
      data: null,
    },
    loaded: false,
  });

  React.useEffect(() => {
    return () => {
      if (gameId) {
        /*
        cache.ref[gameId] -= 1;
        if (cache.ref[gameId] <= 0) {
          delete cache.ref[gameId];
          delete cache[gameId];
          delete cache.loading[gameId];
        }
        */
      }
    };
  }, []);

  const processResponse = (stillMounted: boolean, resp: any, dsId: string, gameId: string) => {
    let data = null;
    if (resp && resp.data) {
      data = resp.data;
    }

    const final = {
      id: dsId,
      gameId: gameId,
      data: data && data.data && data.data.length === 1 ? data.data[0] : null,
    };

    if (cache.ref[gameId]) cache[gameId] = final;

    if (stillMounted) {
      setState({
        data: final,
        loaded: true,
      });
    }

    return { [gameId]: final };
  };

  React.useEffect(() => {
    let stillMounted = true;

    if (authenticationToken && dsId && gameId !== 'egt_switch_game' && gameId) {
      if (cache.ref[gameId] == null) cache.ref[gameId] = 0;
      cache.ref[gameId] += 1;

      if (cache[gameId]) {
        const data = cloneDeep(cache[gameId]);
        setState({
          data: data,
          loaded: true,
        });
        return;
      } else if (cache.loading[gameId]) {
        cache.loading[gameId].promise
          .then((data: any) => {
            if (!stillMounted) return;
            if (!data[gameId]) return;

            setState({
              data: data[gameId],
              loaded: true,
            });
          })
          .catch((e: Error) => {
            console.log(e);
            setState((v) => ({ ...v, loading: false }));
          });
        return;
      } else {
        //console.log('fetch', gameId);

        cache.loading[gameId] = {};
        cache.loading[gameId].promise = new Promise((resolve, reject) => {
          axios
            .get(`${apiUrl}/resolve/source`, {
              headers: {
                Authorization: 'Bearer ' + authenticationToken,
              },
              params: {
                q: urlonStringify({
                  id: dsId,
                  filter: [{ name: 'id', value: gameId }],
                }),
              },
            })
            .then((resp) => {
              resolve(processResponse(stillMounted, resp, dsId, gameId));
            })
            .catch((e: Error) => {
              console.log(e);
              setState((v) => ({ ...v, loading: false }));
              reject(e);
            });
        });

        setState({
          data: {
            id: null,
            data: null,
          },
          loaded: false,
        });
      }
    }

    if (gameId === 'egt_switch_game') {
      setState({
        data: {
          id: null,
          data: null,
        },
        loaded: false,
      });
    }

    return () => {
      stillMounted = false;
    };
  }, [dsId, gameId, authenticationToken]);

  return state;
};

export default resolveGameInfo;
