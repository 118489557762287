import moment from 'moment';
//import nsoftCats from './categories.json';
import { sortArrayByKey, pushUnique } from './';

//const nsoftCatsArray = Object.values(nsoftCats);
let language = typeof window !== 'undefined' ? window.config.defaultLanguage : 'en';

export const setLanguage = lang => {
  language = lang;
  try {
    //console.log('setLanguage', lang);
  } catch (err) { }
};

export const makeInitialState = () => {
  return {
    stateType: 'prematch',
    fullStateLoaded: false,
    loadingState: null,
    eventsDayCache: {},
    sports: {},
    sportsMeta: {},
    categories: {},
    categoriesMeta: {},
    tournaments: {},
    tournamentsMeta: {},
    tournamentGroups: {},
    tournamentGroupsMeta: {},
    bets: {},
    marketGroups: {
      0: {},
    },
    wpBets: {},
    waBets: {},
    wsBets: {},
    data: {},
    matches: {},
    matchesBySport: {},
    waMatches: {},
    wpMatches: {},
    wsMatches: {},
    topOffer: {
      sports: {},
      tournaments: {},
    },
    showTopOffer: false,
    updatesQueue: [],
  };
};

export const stdMatchStartTime = (pm, mapping, prop) => {
  if (!prop) {
    prop = 'startsAt';
  }

  if (mapping !== null) {
    let me = mapping['event'];

    if (me[prop] && pm[me[prop]]) {
      try {
        return moment(pm[me[prop]]).utc().valueOf();
      } catch (e) {
        console.error('failed to parse match start time', e);
      }
    } else {
      console.error('failed to get startsAt with mapping', pm, mapping);
    }

    return -1;
  }

  if (pm[prop]) {
    try {
      return moment(pm[prop]).utc().valueOf();
    } catch (e) {
      console.error('failed to parse match start time', e);
    }
  } else {
    console.error('failed to get startsAt', pm);
  }

  return -1;
};

export const getTr = s => {
  return s;
  /*
  if (!(s && typeof s === 'object')) {
    return s;
  }

  if (language === 'ro') {
    return s[42] ?? s[2];
  }

  return s[2];
  */
};

export const stdMatch = (pm, draft, mapping = null) => {
  if (pm.periods && Array.isArray(pm.periods)) {
    for (const p of pm.periods) {
      stdMatch(p, draft, mapping);
    }
  }

  pm.team1Name = getTr(pm.team1Name);
  pm.team1ShortName = getTr(pm.team1ShortName);
  pm.team2Name = getTr(pm.team2Name);
  pm.team2ShortName = getTr(pm.team2ShortName);

  pm.sportName = getTr(pm.sportName);
  pm.sportShortName = getTr(pm.sportShortName);

  pm.categoryName = getTr(pm.categoryName);
  pm.categoryShortName = getTr(pm.categoryShortName);

  pm.tournamentName = getTr(pm.tournamentName);
  pm.tournamentShortName = getTr(pm.tournamentShortName);

  if (pm.periodShortName) {
    pm.periodShortNameEN = pm.periodShortName?.[2] ?? pm.periodShortName?.[0];
    pm.periodShortName = getTr(pm.periodShortName);
  }

  let isWA = false;
  let isWP = false;
  let isWS = false;

  if (pm.matchBets && Array.isArray(pm.matchBets)) {
    pm.matchBets.forEach(mb => {
      mb.mbDisplayName = getTr(mb.mbDisplayName);

      // if (pm.periodShortName) {
      //   mb.mbDisplayName = pm.periodShortName + ' - ' + mb.mbDisplayName;
      // }

      if (mb.isDuplicate) {
        mb.headerBet = true;
        mb.winnerAdv = true;
        isWA = true;
      }

      if (mb.mbOutcomes && Array.isArray(mb.mbOutcomes)) {
        mb.mbOutcomes.forEach(mbo => {
          mbo.mboType = getTr(mbo.mboType);
          mbo.mboDisplayName = getTr(mbo.mboDisplayName);
          mbo.betName = getTr(mbo.betName);
        });
      }
    });
  }

  pm.winnerPlus = isWP;
  pm.winnerAdv = isWA;
  pm.winnerSpecial = isWS;

  pm.provider = 'digitain';
  return pm;

  /*
  let m,
    me = null,
    mm = null,
    mo = null,
    mc = null;

  //console.log("pm", pm, "mapping", mapping);

  if (mapping != null) {
    me = mapping["event"];
    mm = mapping["market"];
    mo = mapping["outcome"];
    mc = mapping["competitor"];
  }

  if (mapping !== null) {
    m = {
      mType: "prematch",
      idMatch: "" + pm[me["id"]],
      matchDateTime: moment(pm[me["startsAt"]]).utc().valueOf(),
      idSport: "" + pm[me["sportId"]],
      idCategory: "" + pm[me["categoryId"]],
      idTournament: "" + pm[me["tournamentId"]],
      idTournamentGroup: pm[me["tournamentGroupId"]] ? "" + pm[me["tournamentGroupId"]] : "",
      bettingStatus: true,
      active: pm[me["active"]] ? true : false,
      activeBets: pm[me["totalMarkets"]],
      //position: "" + i,
      position: 9999,
      matchBets: [],
      description: pm[me["description"]],
      brId: pm[me["rootEventId"]] ? pm[me["rootEventId"]] : null,
      displayId: pm[me["displayId"]],
    };
  } else {
    m = {
      mType: "prematch",
      idMatch: "" + pm.id,
      matchDateTime: moment(pm.startsAt).utc().valueOf(),
      idSport: "" + pm.sportId,
      idCategory: "" + pm.categoryId,
      idTournament: "" + pm.tournamentId,
      idTournamentGroup: pm.tournamentGroupId ? "" + pm.tournamentGroupId : "",
      bettingStatus: true,
      active: pm.active ? true : false,
      activeBets: pm.totalMarkets,
      //position: "" + i,
      position: 9999,
      matchBets: [],
      description: pm.description,
      brId: pm.rootEventId ? pm.rootEventId : null,
      displayId: pm.displayId,
    };
  }

  let oldM = null;
  if (m.idMatch in draft.matches) {
    oldM = draft.matches[m.idMatch];
  }

  let isWA = oldM !== null ? oldM.winnerAdv : false;
  let isWP = oldM !== null ? oldM.winnerPlus : false;
  let isWS = oldM !== null ? oldM.winnerSpecial : false;

  if (mapping !== null) {
    m.matchBets = pm[me["markets"]].map((mk, pos) => {
      let position = pos + 1;

      if (
        m.idSport in draft.bets &&
        mk[mm["marketId"]] in draft.bets[m.idSport] &&
        typeof draft.bets[m.idSport] !== "undefined" &&
        typeof draft.bets[m.idSport][mk[mm["marketId"]]] !== "undefined"
      ) {
        position = draft.bets[m.idSport][mk[mm["marketId"]]].betPosition;
      }

      let bet = {
        idMb: "" + mk[mm["id"]],
        idBet: "" + mk[mm["marketId"]],
        mbChanged: false,
        mostBalanced: false,
        mbPosition: "" + position,
        mbActive: mk[mm["active"]],
        mbSpecialValue: mk[mm["specialValues"]] || "",
        mbOutcomes: [],
        competitors: mk[mm["competitors"]] || [],
      };

      if (bet.idBet in draft.waBets) {
        isWA = true;
        bet.winnerAdv = true;
      } else if (bet.idBet in draft.wpBets) {
        isWP = true;
        bet.winnerPlus = true;
      }

      if (bet.idBet in draft.wsBets) {
        isWS = true;
        bet.winnerSpecial = true;
      }

      bet.mbOutcomes = mk[mm["outcomes"]].map(o => {
        return {
          idMbo: "" + o[mo["id"]],
          idBo: "" + o[mo["outcomeId"]],
          mboActive: o[mo["active"]],
          mboType: o[mo["name"]],
          mboDisplayName: o[mo["name"]],
          mboOddValue: parseFloat(o[mo["odd"]]),
          mboPosition: 0,
          description: o[mo["description"]],
        };
      });

      return bet;
    });
  } else {
    m.matchBets = pm.markets.map((mk, pos) => {
      let position = pos + 1;

      if (
        m.idSport in draft.bets &&
        mk["marketId"] in draft.bets[m.idSport] &&
        typeof draft.bets[m.idSport] !== "undefined" &&
        typeof draft.bets[m.idSport][mk["marketId"]] !== "undefined"
      ) {
        position = draft.bets[m.idSport][mk["marketId"]].betPosition;
      }

      let bet = {
        idMb: "" + mk.id,
        idBet: "" + mk.marketId,
        mbChanged: false,
        mostBalanced: false,
        mbPosition: "" + position,
        mbActive: mk.active,
        mbSpecialValue: mk.specialValues || "",
        mbOutcomes: [],
        competitors: mk.competitors || [],
      };

      if (bet.idBet in draft.waBets) {
        isWA = true;
        bet.winnerAdv = true;
      } else if (bet.idBet in draft.wpBets) {
        isWP = true;
        bet.winnerPlus = true;
      }

      if (bet.idBet in draft.wsBets) {
        isWS = true;
        bet.winnerSpecial = true;
      }

      bet.mbOutcomes = mk.outcomes.map(o => {
        return {
          idMbo: "" + o.id,
          idBo: "" + (o.outcomeId || o.marketOutcomeId),
          mboActive: o.active,
          mboType: o.name,
          mboDisplayName: o.name,
          mboOddValue: parseFloat(o.odd),
          mboPosition: o.position || 0,
          description: o.description,
        };
      });

      return bet;
    });
  }

  m.matchBets.forEach(mb => {
    mb.competitors = Array.isArray(mb.competitors) ? mb.competitors : Object.values(mb.competitors);
    mb.competitors = mb.competitors
      .map(c => {
        if (mapping !== null) {
          if (mc["name"] && mc["name"] in c) {
            return {
              name: mc["name"] && c[mc["name"]] ? c[mc["name"]] : "",
              id: mc["id"] && c[mc["id"]] ? c[mc["id"]] : "",
              teamId: mc["teamId"] && c[mc["teamId"]] ? c[mc["teamId"]] : "",
              type: mc["type"] && c[mc["type"]] ? c[mc["type"]] : "",
              shortName: mc["shortName"] && c[mc["shortName"]] ? c[mc["shortName"]] : "",
            };
          }

          return null;
        }

        if ("name" in c) {
          return c;
        }

        if ("playerId" in c && "players" in pm && pm.players[c.playerId]) {
          c.id = c.playerId;
          c.name = pm.players[c.playerId].name + " " + pm.players[c.playerId].surname;
        }

        return c;
      })
      .filter(c => c !== null);
  });

  m.winnerPlus = isWP;
  m.winnerAdv = isWA;
  m.winnerSpecial = isWS;

  if (mapping !== null) {
    Object.values(pm[me["competitors"]]).forEach(c => {
      if (c[mc["type"]] === 1) {
        m.idTeam1 = "" + c[mc["id"]];
        m.team1Name = c[mc["name"]];
        m.team1ShortName = c[mc["shortName"]];
        m.teamId1 = "" + c[mc["teamId"]];
      } else {
        m.idTeam2 = "" + c[mc["id"]];
        m.team2Name = c[mc["name"]];
        m.team2ShortName = c[mc["shortName"]];
        m.teamId2 = "" + c[mc["teamId"]];
      }
    });
  } else {
    Object.values(pm.competitors).forEach(c => {
      if (c.type === 1) {
        m.idTeam1 = "" + c.id;
        m.team1Name = c.name;
        m.team1ShortName = c.shortName;
        m.teamId1 = "" + c.teamId;
      } else {
        m.idTeam2 = "" + c.id;
        m.team2Name = c.name;
        m.team2ShortName = c.shortName;
        m.teamId2 = "" + c.teamId;
      }
    });
  }

  m.lastFetched = moment().utc().valueOf();

  //console.log("m", m);

  return m;
  */
};

export const stdSport = s => {
  return {
    idSport: '' + s.id,
    sportName: getTr(s.name),
    sportShortName: getTr(s.shortName),
    sportActive: typeof s.active !== 'undefined' ? (s.active ? true : false) : true,
    sportPosition: s.position,
    sportType: 'REGULAR',
    numberOfEvents: s.numberOfEvents,
  };
};

export const stdCategory = c => {
  const sc = {
    idCategory: '' + c.id,
    categoryName: getTr(c.name),
    categoryShortName: getTr(c.shortName),
    categoryPosition: c.position,
    categoryIsoName: c.isoCode,
    categoryActive: typeof c.active !== 'undefined' ? (c.active ? true : false) : true,
    idSport: '' + c.sportId,
    numberOfEvents: c.numberOfEvents,
  };

  if (c.DLId) {
    // used from digitain feed in order to map country images
    sc.categoryDLId = c.DLId;
  }

  /*
  if (sc.categoryIsoName === null || sc.categoryIsoName === '') {
    if (c.id in nsoftCats) {
      sc['categoryIsoName'] = nsoftCats[c.id].iso3;
      sc['icon'] = nsoftCats[c.id].icon;
    } else {
      const cat = nsoftCatsArray.find(cs => cs.originalName === c.name || cs.name === c.name);
      if (cat) {
        sc['categoryIsoName'] = cat.iso3;
        sc['icon'] = cat.icon;
      }
    }
  }
  */

  return sc;
};

const tName = name => {
  try {
    // this runs under web worker where we don't have window defined
    if (
      window &&
      typeof window.config.nsoftTheme !== 'undefined' &&
      window.config.nsoftTheme &&
      name &&
      name.toLowerCase().indexOf('winner') > -1
    ) {
      return name.replace('Winner', window.config.nsoftTheme);
    }
  } catch (err) { }

  return name;
};

export const stdTournament = t => {
  return {
    idTournament: '' + t.id,
    idCategory: t.categoryId ? '' + t.categoryId : null,
    idSport: '' + t.sportId,
    abbreviation: t.abbreviation,
    tournamentName: tName(getTr(t.name)),
    tournamentShortName: tName(getTr(t.shortName)),
    tournamentPosition: t.position,
    tournamentActive: typeof t.active !== 'undefined' ? (t.active ? true : false) : true,
    tournamentPrefix: '',
    description: t.description,
    brId: t.sourceTournamentId ? t.sourceTournamentId : null,
    numberOfEvents: t.numberOfEvents,
  };
};

export const stdTournamentGroup = t => {
  return {
    idTournamentGroup: '' + t.id,
    idTournament: t.tournamentId ? '' + t.tournamentId : null,
    tournamentGroupName: t.name,
    tournamentGroupShortName: t.shortName,
    tournamentGroupPosition: t.position,
  };
};

const headerBets = ['ultra cota', 'final'];

export const stdBet = mk => {
  //const mkn = getTr(mk.name).toLowerCase();
  const mkn = (mk.name[2] ?? mk.name[0]).toLowerCase();

  const wp = mkn === 'winner plus';
  const wa = mkn === 'ultra cota';
  const ws = mkn.indexOf('total cornere') !== -1;

  const headerBet = headerBets.findIndex(hb => hb === mkn) !== -1;

  let bet = {
    idBet: '' + mk.id,
    isSpecial: mk.isSpecial,
    betDisplayId: '1',
    betName: getTr(mk.name),
    betShortName: getTr(mk.shortName),
    idSport: '' + mk.sportId,
    betActive: true,
    betCollapsed: mk.collapsed,
    betGroups: mk.marketGroups,
    betPosition: mk.position,
    betGroupingEnabled: false,
    betType: 'REGULAR',
    betOutcomes: {},
    winnerPlus: wp,
    winnerAdv: wa,
    winnerSpecial: ws,
    headerBet,
  };

  bet.betOutcomes = mk.outcomes.reduce((acc, o) => {
    acc['' + o.id] = {
      idBetOutcome: '' + o.id,
      betOutcomeName: getTr(o.name),
      betOutcomeShortName: getTr(o.shortName),
      betOutcomeActive: true,
      betOutcomePosition: o.position,
      betOutcomeShortcut: '1',
    };

    return acc;
  }, {});

  return bet;
};

export const buildData = (draft, doBets) => {
  if (doBets) {
    Object.keys(draft.bets).forEach(bsk => {
      Object.keys(draft.bets[bsk]).forEach(bk => {
        if (draft.bets[bsk][bk].winnerPlus) {
          draft.wpBets[bk] = true;
        } else if (draft.bets[bsk][bk].winnerAdv) {
          draft.waBets[bk] = true;
        } else if (draft.bets[bsk][bk].winnerSpecial) {
          draft.wsBets[bk] = true;
        }
      });
    });
  }

  draft.data = Object.values(draft.matches).reduce((acc, m) => {
    if (m.winnerPlus) {
      draft.wpMatches[m.idMatch] = m;
    }

    if (m.winnerAdv) {
      draft.waMatches[m.idMatch] = m;
    }

    if (m.winnerSpecial) {
      draft.wsMatches[m.idMatch] = m;
    }

    if (!(m.idSport in acc)) {
      acc[m.idSport] = {};
    }

    if (!(m.idCategory in acc[m.idSport])) {
      acc[m.idSport][m.idCategory] = {};
    }

    if (!(m.idTournament in acc[m.idSport][m.idCategory])) {
      acc[m.idSport][m.idCategory][m.idTournament] = {};
    }

    acc[m.idSport][m.idCategory][m.idTournament][m.idMatch] = m;

    return acc;
  }, {});

  draft.matchesBySport = Object.values(draft.matches).reduce((acc, m) => {
    if (!(m.idSport in acc)) {
      acc[m.idSport] = [];
    }

    acc[m.idSport].push(m);

    return acc;
  }, {});

  Object.keys(draft.matchesBySport).forEach(k => sortArrayByKey(draft.matchesBySport[k], 'matchDateTime'));
};

export const buildState = (draft, action, skipBuildData) => {
  if (action.meta !== null) {
    draft.sportsMeta = action.meta.sports.reduce((acc, s) => {
      acc[s.id] = stdSport(s);
      return acc;
    }, {});

    if (draft.fullStateLoaded) {
      draft.sports = Object.assign(draft.sports, draft.sportsMeta);
    } else {
      draft.sports = draft.sportsMeta;
    }

    draft.categoriesMeta = action.meta.categories.reduce((acc, c) => {
      acc[c.id] = stdCategory(c);
      return acc;
    }, {});

    if (draft.fullStateLoaded) {
      draft.categories = Object.assign(draft.categories, draft.categoriesMeta);
    } else {
      draft.categories = draft.categoriesMeta;
    }

    draft.tournamentsMeta = action.meta.tournaments.reduce((acc, t) => {
      acc[t.id] = stdTournament(t);
      return acc;
    }, {});

    if (draft.fullStateLoaded) {
      draft.tournaments = Object.assign(draft.tournaments, draft.tournamentsMeta);
    } else {
      draft.tournaments = draft.tournamentsMeta;
    }

    draft.tournamentGroupsMeta = action.meta.tournamentGroups.reduce((acc, t) => {
      acc[t.id] = stdTournamentGroup(t);
      return acc;
    }, {});

    if (draft.fullStateLoaded) {
      draft.tournamentGroups = Object.assign(draft.tournamentGroups, draft.tournamentGroupsMeta);
    } else {
      draft.tournamentGroups = draft.tournamentGroupsMeta;
    }

    // draft.marketGroups = Object.assign(
    //   draft.marketGroups,
    //   action.meta.marketGroups.reduce((acc, mkg) => {
    //     const sportId = mkg.sportId || 0;
    //     acc[sportId] = acc[sportId] || {};
    //     acc[sportId][mkg.id] = mkg;

    //     return acc;
    //   }, {})
    // );

    if (action.meta.marketGroups) {
      const mkg = {};

      for (const [sid, sd] of Object.entries(action.meta.marketGroups)) {
        mkg[sid] = mkg[sid] || {};

        for (const [gid, gd] of Object.entries(sd)) {
          gd.nameEN = gd.name?.[2] ?? gd.name?.[0]; // we need the english name for sorting

          gd.name = getTr(gd.name);
          gd.shortName = getTr(gd.shortName);

          mkg[sid][gid] = gd;
        }
      }

      draft.marketGroups = mkg;
    }
    draft.marketGroups[0] = draft.marketGroups[0] || {};

    draft.bets = Object.assign(
      draft.bets,
      action.meta.markets.reduce((acc, mk) => {
        const b = stdBet(mk);
        acc[b.idSport] = acc[b.idSport] || {};
        acc[b.idSport][b.idBet] = b;

        if (b.winnerPlus) {
          draft.wpBets[b.idBet] = 1;
        } else if (b.winnerAdv) {
          draft.waBets[b.idBet] = 1;
        } else if (b.winnerSpecial) {
          draft.wsBets[b.idBet] = 1;
        }

        return acc;
      }, {})
    );
  }

  if (action.events !== null) {
    draft.matches = Object.assign(
      draft.matches,
      action.events.events.reduce((acc, e) => {
        const m = stdMatch(e, draft, action.shortProps ? action.events._mapping : null);

        if (draft.matches && draft.matches[m.idMatch]) {
          const existentMatch = draft.matches[m.idMatch]; // if it's fully loaded do not overwrite it in state
          if (existentMatch._loaded) return acc;
        }

        acc[m.idMatch] = m;

        return acc;
      }, {})
    );

    if (!skipBuildData) {
      buildData(draft, false);
    }
  }

  let topOffer = action.meta && action.meta.topOffer ? action.meta.topOffer : null;

  if (topOffer) {
    const cats = Object.values(draft.categories);

    draft.topOffer = {
      sports: topOffer.sports.reduce((acc, s) => {
        acc[s.id] = stdSport(s);
        return acc;
      }, {}),
      tournaments: topOffer.topOffer.reduce((acc, t) => {
        const idSport = t.sportId;

        /*
        const [idCategory] = Object.entries(draft.data[idSport]).find(([cid, cdata]) => {
          if (Object.keys(cdata).find(tid => tid === t.id + "")) {
            return true;
          }
          return false;
        });
        */

        const ct = cats.find(c => c.idCategory === t.categoryId && c.idSport === t.sportId + '');

        if (!ct) {
          //console.log("failed to get category for tournament", t);
          return acc;
        } else {
          //t.categoryId = ct.idCategory;
        }

        //console.log(t);

        acc[t.id] = stdTournament(t);

        return acc;
      }, {}),
    };
  }
};

export const stdWebworkerMatch = (md, draft) => {
  const [team1, team2] = md.name.split(' - ');

  const team1Data = {
    type: 1,
    id: 'tbd',
    name: team1,
    shortName: team1,
  };

  const team2Data = {
    type: 2,
    id: 'tdb',
    name: team2,
    shortName: team2,
  };

  if ('teams' in md) {
    Object.values(md.teams).forEach(td => {
      if (td.name == team1Data.name) {
        team1Data.id = td.id;
        team1Data.shortName = td.shortName;
      }

      if (td.name == team2Data.name) {
        team2Data.id = td.id;
        team2Data.shortName = td.shortName;
      }
    });
  }

  md.totalMarkets = md.markets.length;
  md.position = 9999;

  md.competitors = [team1Data, team2Data];

  return stdMatch(md, draft);
};
