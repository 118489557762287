import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';

import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { getLottoState } from '../../../../../modules/lotto/store/selectors/lottoData';
import {
  betSlipLottoProcessNextTicket,
  betSlipLottoTicketCheckAuthorization,
  betSlipLottoIncrementCurrentIndex,
} from '../../../../../modules/lotto/store/actions/betsSlip';
import { ticketStoreOpen, ticketStoreReserved } from '../../../../../modules/lotto/store/actions/tickets';
import evBus from '../../../../../modules/lotto/utils/evbus';
import { RootState } from '@/store';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const TicketModal = (props: any) => {
  const {
    openPlaceBet,
    ticketRegistration,
    ticketRegistrationIndex,
    incrementCurrentIndex,
    handlePlaceBetClose,
    processNextTicket,
    checkAuthorization,
    ticketStoreOpen,
    ticketStoreReserved,
    auth,
  } = props;

  const { t } = useTranslation();

  const [state, setState] = React.useState(ticketRegistration);
  const [status, setStatus] = React.useState('pending');
  const dataElementContext = React.useContext(DataElementContext);

  React.useEffect(() => {
    const handleWebsocketAuthorization = (data: any) => {
      if (ticketRegistration) {
        const currentIndex = ticketRegistrationIndex;
        const st = ticketRegistration.state[currentIndex];

        if (st && st.ticketResult && st.ticketResult.id && data.ticket_id) {
          //console.log("handleWebsocketAuthorization: data", data);
          if (data.ticket_id === st.ticketResult.id) {
            checkAuthorization(st.ticketResult.id);
          }
        }
      }
    };

    evBus.on('ws.notification.authorization', handleWebsocketAuthorization);

    setState(ticketRegistration);

    const currentIndex = ticketRegistrationIndex;
    const st = ticketRegistration.state[currentIndex];

    if (currentIndex === 0 && st.statusCode === 0) {
      processNextTicket();
      return;
    }

    if (st.statusCode === 200) {
      if (st.ticketResult.ticketOnline) {
        ticketStoreOpen([st.ticketResult]);
      } else {
        ticketStoreReserved([st.ticketResult]);
      }
    }

    let overallStatus = 'pending';

    if (st.statusCode === 200 || st.statusCode === 400) {
      if (currentIndex + 1 === ticketRegistration.tickets.length) {
        overallStatus = 'done';
      }
      if (overallStatus !== 'done') {
        incrementCurrentIndex();
        processNextTicket();
      }
    }

    setStatus(overallStatus);

    return () => {
      evBus.remove('ws.notification.authorization', handleWebsocketAuthorization);
    };
  }, [
    ticketRegistration,
    openPlaceBet,
    ticketRegistrationIndex,
    processNextTicket,
    ticketStoreOpen,
    incrementCurrentIndex,
    checkAuthorization,
    ticketStoreReserved,
  ]);

  const handleCheckAuthorization = () => {
    const st = state.state[ticketRegistrationIndex];
    const ticket_id = st.ticketResult.id;
    checkAuthorization(ticket_id);
  };

  //if (!openPlaceBet) return null;

  let loggedIn = false;
  if (auth && auth.auth_type === 'user') {
    loggedIn = true;
  }

  let successNumber = 0;
  let failedNumber = 0;
  let totalProcessed = 0;
  ticketRegistration.state.forEach((s: any) => {
    if (s.statusCode === 200) {
      successNumber += 1;
    } else if (s.statusCode === 400) {
      failedNumber += 1;
    }
    if (s.statusCode !== 0) {
      totalProcessed += 1;
    }
  });

  const percent = (totalProcessed * 100) / ticketRegistration.state.length;
  const progressStyle = {
    width: `${percent}%`,
  };

  const contextValue = {
    //ticketsLength: state.tickets.length,
    status, // 'pending' | 'done' | 'failed'
    successNumber,
    failedNumber,
    totalProcessed,
    authorizationWaiting: state?.authorization?.waiting,
    eventName: ticketRegistration?.event?.event_name,
    ticketsLength: ticketRegistration.state.length,
    ticketRegistrationIndex,
    authorizationMaxWaiting: state?.authorization?.maxWaiting,
    authorizationEndTime: state?.authorization?.endTime,
    handleCheckAuthorization,
    progressValue: percent,

    tickets: ticketRegistration.tickets.map((ticket: any, i: number) => {
      const st: any = Object.assign({}, ticketRegistration.state[i]);

      if (st.status.indexOf('Too late to place') !== -1) {
        st.status = st.status.replace('Too late to place ticket for event', t('Too late to place ticket for event'));
      }

      const animated = st.status === 'Pending' || st.status === 'Registering' ? true : false;

      let statusMsg = t(st.status);
      if (st.statusCode === 400 && st.ticketResult && st.ticketResult.refusal_reason !== '') {
        statusMsg = `${t(st.status)}: ${st.ticketResult.refusal_reason}`;
      }

      return {
        index: i + 1,
        date: moment(st.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
        status: st.status,
        statusCode: st.statusCode,
        animated,
        statusMsg,
      };
    }),
    loggedIn,
    handlePlaceBetClose,
    parentContext: dataElementContext,
    onAuthorizationWaitingExpired: handleCheckAuthorization,
  };

  //console.log('TicketModal[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{props.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );

  /*
  return (
    <Dialog
      open={openPlaceBet}
      fullWidth={true}
      maxWidth={'sm'}
      TransitionComponent={Transition}
      keepMounted
      onClose={handlePlaceBetClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.root}
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">
        {status === 'pending' && (
          <div className={classes.dialogHeaderInProgress}>
            <div>
              <img src={TicketInProgress} className="dh-logo" alt="" />
            </div>
            {state.tickets.length === 1 && <div className="dh-text">{t('Placing the ticket in progress')}</div>}
            {state.tickets.length > 1 && <div className="dh-text">{t('Placing tickets in progress')}</div>}
          </div>
        )}
        {status === 'done' && failedNumber === 0 && (
          <div className={classes.dialogHeaderSuccess}>
            <div>
              <img src={TicketSuccess} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t('Success')}</div>
          </div>
        )}
        {status === 'done' && failedNumber !== 0 && totalProcessed !== failedNumber && (
          <div className={classes.dialogHeaderFailed}>
            <div>
              <img src={TicketFailed} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t('Placing partially failed')}</div>
          </div>
        )}
        {status === 'done' && failedNumber !== 0 && totalProcessed === failedNumber && (
          <div className={classes.dialogHeaderFailed}>
            <div>
              <img src={TicketFailed} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t('Placing failed')}</div>
          </div>
        )}
        {status !== 'pending' && status !== 'done' && (
          <div className={classes.dialogHeaderFailed}>
            <div>
              <img src={TicketFailed} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t('Placing failed')}</div>
          </div>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!state.authorization.waiting &&
          <div className={classes.dialogBody}>
            <div className={`db-text ${classes.tac}`}>
              <strong>{ticketRegistration.event.event_name}</strong>
            </div>
            <div className={`${classes.progressWrapper} ${ticketRegistration && ticketRegistration.tickets.length < 10 ? classes.progressHide : ''}`}><div id="progress" style={progressStyle} className={classes.progress}></div></div>
            <div className={`${classes.progressText} ${ticketRegistration && ticketRegistration.tickets.length < 10 ? classes.progressHide : ''}`}>
              {totalProcessed} / {ticketRegistration.state.length}
            </div>
            <div className="db-text">
              <div className={classes.ticketsWrapper}>
                {ticketRegistration.tickets.map((ticket, i) => {
                  let st = Object.assign({}, ticketRegistration.state[i]);

                  if (st.status.indexOf('To late to place') !== -1) {
                    st.status = 'To late to place ticket for this event';
                  }

                  const animated = st.status === 'Pending' || st.status === 'Registering' ? true : false;

                  let statusMsg = t(st.status);
                  if (st.statusCode === 400 && st.ticketResult && st.ticketResult.refusal_reason !== '') {
                    statusMsg = `${t(st.status)}: ${st.ticketResult.refusal_reason}`;
                  }

                  return (<div key={i} className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
                    <div className={classes.leftSide}>{t('Ticket')} #{i + 1}</div>
                    <div className={classes.centerSide}>{moment(st.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</div>
                    <div className={`${classes.rightSide} ${st.statusCode === 400 ? classes.errorColor : ''}`}>
                      {animated && <span className={classes.textAnimation}>{statusMsg}</span>}
                      {!animated && <span>{statusMsg}</span>}
                    </div>
                  </div>);
                })}
              </div>
            </div>
            <div className={classes.mb}>
              {loggedIn && status === 'done' && <div className="db-status-text">{t('Registered successfully')}: {successNumber} {successNumber === 1 ? t('ticket') : t('tickets')}</div>}
              {loggedIn && status === 'done' && failedNumber !== 0 && <div className={`db-status-text ${classes.errorColor}`}>{t('Failed to register')}: {failedNumber} {failedNumber === 1 ? t('ticket') : t('tickets')}</div>}
            </div>
            {loggedIn && status === 'done' && <div
              className={`${classes.mt2} db-text db-link`}
              onClick={() => handlePlaceBetClose(true)}
            >
              {t('MY TICKETS')}
            </div>}
            {status !== 'pending' &&
              <div>
                <ButtonBase className={classes.dialogOK} onClick={handlePlaceBetClose}>
                  {t('OK')}
                </ButtonBase>
              </div>
            }
          </div>
        }
        {state.authorization.waiting &&
          <div className={classes.dialogBody}>
            <div className="db-text">
              Waiting manual authorization for ticket #{ticketRegistrationIndex + 1}
            </div>
            <div className="db-text">
              <Timer waitTime={state.authorization.maxWaiting} callback={handleCheckAuthorization} t={t} />
            </div>
          </div>
        }
      </DialogContent>
    </Dialog>
  );
  */
};

const mapStateToProps = (state: RootState, props: any) => {
  const bst = getLottoState(state);
  const ct = bst.betsSlip;

  return {
    ticketCreateStatus: ct.ticketCreateStatus,
    ticketOnline: ct.ticketOnline,
    ticketCode: ct.ticketCode,
    ticketRegistration: ct.ticketRegistration,
    ticketRegistrationIndex: ct.ticketRegistrationIndex,
    auth: state.authentication,
  };
};

const actions = {
  processNextTicket: betSlipLottoProcessNextTicket,
  checkAuthorization: betSlipLottoTicketCheckAuthorization,
  incrementCurrentIndex: betSlipLottoIncrementCurrentIndex,
  ticketStoreOpen,
  ticketStoreReserved,
};

export default connect(mapStateToProps, actions)(TicketModal);
