import React from 'react';
import { useAppSelector } from '../../store';
import { uniqueId } from 'lodash-es';

import craftJsParser from '../../components/utils/craftJsParser';
import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

export const defaultProps = {
  properties: {
    elementId: null,
  },
};

export const ElementData = (componentProps) => {
  let props = componentProps;

  const [__id] = React.useState(uniqueId('unique-'));
  const componentsElements = useAppSelector((state) => state.templatesConfig.componentsElements);
  const authenticated = useAppSelector((state) => state.authentication && state.authentication.auth_type === 'user');

  const dataElementContext = React.useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  const { elementId } = props.properties;
  const element = componentsElements?.[elementId];

  let content = React.useMemo(() => {
    try {
      if (!element) return null;
      const projectData = element.projectData;

      const data = JSON.parse(projectData);

      let rootNodeId = 'ROOT';

      if (rootNodeId) {
        data[rootNodeId].type = 'Container';
        data[rootNodeId].props = {
          'className': '',
          'styleText': 'display:contents',
          'properties': {},
        };

        if (data[rootNodeId].nodes && data[rootNodeId].nodes.length === 1) {
          rootNodeId = data[rootNodeId].nodes[0];
        }

        if (window !== window.parent && data?.[rootNodeId] && componentProps?.id) {
          data[rootNodeId].id = componentProps.id;
          data[componentProps.id] = data[rootNodeId];
          rootNodeId = componentProps.id;
        }

        return craftJsParser({
          craftState: data,
          rootNodeId: rootNodeId,
          pageId: elementId,
          pageType: 'componentsElements',
          options: {
            authenticated,
          },
          withProvider: true,
          children: componentProps.children ?? null,
        });
      }
    } catch (err) {
      console.error('ElementData', err);
    }
    return null;
  }, [element?.projectData, elementId, authenticated]);

  const contextValue = React.useMemo(() => {
    const skipProps = ['elementId', 'dsType', 'propFunction', 'links'];
    const contextValue = {};
    props?.properties && Object.keys(props.properties).forEach(key => {
      if (skipProps.indexOf(key) > -1) return;
      contextValue[key] = props.properties[key];
    });

    contextValue.parentContext = dataElementContext;
    contextValue.__id = __id;
    return contextValue;
  }, [componentProps, dataElementContext, componentsElements, authenticated]);

  if (!isVisible) return null;
  if (!content) return null;

  return <DataElementContext.Provider value={contextValue}>{content}</DataElementContext.Provider>;
};
