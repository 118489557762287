// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

const getCurrentCurrency = () => {
  const currency = localStorage.getItem('currentCurrency');
  if (currency) return currency;
  return window.config.defaultCurrency;
};

export interface ConfigReducer {
  currenciesList: string[];
  currentCurrency: string;
}

export const currenciesSlice = createSlice({
  name: 'currencies',
  initialState: <ConfigReducer>{
    currenciesList: window.config.currencies,
    currentCurrency: getCurrentCurrency(),
  },
  reducers: {
    changeCurrency: (state, action) => {
      /* example */
      state.currentCurrency = action.payload.currency;
      localStorage.setItem('currentCurrency', action.payload.currency);
    },
  },
});

export const { changeCurrency } = currenciesSlice.actions;

export default currenciesSlice.reducer;
