import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import type {
  SportType,
  CategoryType,
  TournamentType,
  MatchType,
  StateBetType,
  BetslipBetType,
} from '../../bets/utils/types';

import store, { useAppSelector, useAppDispatch } from '@/store';
import { prematchFetchMatches, prematchSetMatches } from '@/modules/bets/store/actions/prematch';

import { digitainConfig } from '@/modules/bets/api/config/digitain';

type SelectedBet = {
  idMbo: string;
};

interface GetMatchDataProps {
  mType: 'prematch' | 'live';
  idSport: string;
  idCategory: string;
  idTournament: string;
  idMatch: string;
}

interface GetMatchDataReturn {
  sport: SportType | null;
  category: CategoryType | null;
  tournament: TournamentType | null;
  matches: MatchType[];
  selectedBets: SelectedBet[];
  inPageIdMatch: string;
  loadingState: boolean;
  fullStateLoaded: boolean;
  matchIds?: { [id: string]: boolean };
}

export const getMatchesData = ({
  mType,
  idSport,
  idCategory,
  idTournament,
  idMatch,
}: GetMatchDataProps): GetMatchDataReturn => {
  // @ts-ignore
  const loadingState = useAppSelector((state) => state.bets[mType].loadingState);
  const fullStateLoaded = useAppSelector((state) => state.bets[mType].fullStateLoaded);
  const matches: any = useAppSelector((state) => state.bets[mType].matches);

  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [state, setState] = React.useState<GetMatchDataReturn>({
    sport: null,
    category: null,
    tournament: null,
    matches: [],
    selectedBets: [],
    inPageIdMatch: '',
    loadingState: false,
    fullStateLoaded: false,
    matchIds: {
      [idMatch ?? 'none']: true,
    },
  });

  const [requested, setRequested] = React.useState(false);

  React.useEffect(() => {
    if (
      idSport &&
      idCategory &&
      idTournament &&
      idMatch &&
      matches[idSport]?.[idCategory]?.[idTournament]?.[idMatch] == null
    ) {
      dispatch(prematchFetchMatches([idMatch]));
    }
  }, []);

  React.useEffect(() => {
    if (mType === 'prematch' && (idTournament || idCategory || idSport) && !requested) {
      setRequested(true);

      const getLeagueMatches = async () => {
        /*

        const postData: any = {
          request: {
            language: i18n.language,
            limit: 1000,
            timeFrom: new Date().toISOString(),
            timeTo: sixMonthsFromNow,
          },
        };

        if (idTournament) {
          postData.request.tournament_id = [idTournament];
        } else if (idCategory) {
          postData.request.country_id = [idCategory];
        } else if (idSport) {
          postData.request.sport_id = [idSport];
        }

        try {
          response = await searchApiByQuery(postData);
        } catch (e) {
          console.error('searchApiByQuery[error]', e);
        }

        http
        https://micros-prod1.gambling-solutions.ro/api/digitain-fetcher/v2/public/events
        timeFrom="2024-06-15T05:14:26.081Z" timeTo="2024-12-12T05:14:26.081Z" wantEuro:=true -v
        */

        let response: any = null;
        const sixMonthsFromNow = new Date(+new Date() + 6 * 30 * 24 * 60 * 60 * 1000).toISOString();

        const digitainConfigData = digitainConfig();

        const postData: any = {
          timeFrom: new Date().toISOString(),
          timeTo: sixMonthsFromNow,
          tournamentIds: [idTournament],
        };

        if (idTournament) {
          postData.tournamentIds = [idTournament];
        } else if (idCategory) {
          postData.categoryIds = [idCategory];
        } else if (idSport) {
          postData.sportIds = [idSport];
        }

        try {
          const accessToken = store.getState().authentication?.access_token;
          const headers: any = {};
          if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
          }

          response = await axios.post(digitainConfigData.apiURL + '/events', postData, { headers: headers });
        } catch (e) {
          console.error('searchApiByQuery[error]', e);
        }

        //console.log('searchApiByQuery[response]', response);

        if (Array.isArray(response?.data?.data?.events)) {
          const exists: { [id: string]: boolean } = {};
          const idList: any = [];
          const fetchIdList: any = [];

          response.data.data.events.forEach((match: any) => {
            const key = `${match.idSport}-${match.idCategory}-${match.idTournament}-${match.idMatch}`;
            if (exists[key]) return;
            exists[key] = true;

            if (!((match.team1Name?.[0] || match.team1Name?.[2]) && (match.team2Name?.[0] || match.team2Name?.[2]))) {
              return;
            }

            idList.push(match.idMatch);

            if (!matches[match.idMatch]) {
              fetchIdList.push(match.idMatch);
            }
          });

          dispatch(prematchSetMatches(response.data.data.events));

          if (idList.length) {
            setState((v) => ({
              ...v,
              matchIds: idList.reduce((a: any, v: any) => ({ ...a, [v]: true }), {}),
            }));
          }
        }
      };

      getLeagueMatches();
    } else if (mType === 'live' && (idTournament || idCategory || idSport)) {
      const idList: any = [];
      Object.values(matches).forEach((match: any) => {
        let idMatch = '';

        if (idTournament) {
          if (match.idTournament === idTournament) idMatch = match.idMatch;
        } else if (idCategory) {
          if (match.idCategory === idCategory) idMatch = match.idMatch;
        } else if (idSport) {
          if (match.idSport === idSport) idMatch = match.idMatch;
        }

        if (idMatch && match?.currentStatus?.IsLiveStarted) {
          idList.push(idMatch);
        }
      });

      //setRequested(true);
      setState((v: any) => {
        let changed = false;
        const newList = idList.reduce((a: any, v: any) => ({ ...a, [v]: true }), {});
        // compare old list with new list
        if (Object.keys(newList).length === Object.keys(v.matchIds).length) {
          Object.keys(newList).forEach((id: any) => {
            if (!v.matchIds[id]) {
              changed = true;
            }
          });
          Object.keys(v.matchIds).forEach((id: any) => {
            if (!newList[id]) {
              changed = true;
            }
          });
        } else {
          changed = true;
        }

        if (!changed) return v;

        return {
          ...v,
          matchIds: newList,
        };
      });
    }
  }, [mType, idSport, idCategory, idTournament, idMatch, matches, requested]);

  React.useEffect(() => {
    setState((v) => {
      const newV = { ...v };
      let changed = false;

      if (newV.matches.length === 0) {
        const mArray: MatchType[] = [];
        Object.keys(newV.matchIds).forEach((idMatch: any) => {
          if (matches[idMatch]) {
            mArray.push(matches[idMatch]);
          }
        });
        if (mArray.length) {
          mArray.sort((a, b) => a.matchDateTime - b.matchDateTime);
          if (newV.matches.length !== mArray.length) {
            newV.matches = mArray;
            changed = true;
          } else {
            for (let i = 0; i < mArray.length; i++) {
              if (newV.matches[i] !== mArray[i]) {
                newV.matches = mArray;
                changed = true;
                break;
              }
            }
          }
        }
      }

      if (newV.loadingState !== loadingState) {
        newV.loadingState = loadingState;
        changed = true;
      }

      if (newV.fullStateLoaded !== fullStateLoaded) {
        newV.fullStateLoaded = fullStateLoaded;
        changed = true;
      }

      if (!changed) return v;
      return newV;
    });
  }, [matches, loadingState, fullStateLoaded, state.matchIds]);

  return state;
};
