import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  formatCurrency,
  formatAmount2,
  formatAmountWithDecimals,
  formatTotalOddsValue,
  forceRoundIfAny,
} from '../../../../../modules/bets/utils/formatters';
import { formatMessage, twoDecimalsFloat } from '../../../../../modules/bets/utils';
import {
  betsSlipClear,
  betsSlipClearCreateStatus,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betsSlipToggleAutoAcceptOddChange,
  betsSlipToggleAllowTicketPlaceWithInvalidEvents,
  betsSlipSetTicketOnline,
  betsBonusRequestEvaluation,
  betsSlipToggleWinnerFunBoost,
  betSlipSetFreeBetIndex,
  betSlipFreeBetClear,
  betsSlipSetMultiBetDayNumber,
} from '../../../../../modules/bets/store/actions/betsSlip';

import { ticketWinnerFunOpenedListRequest } from '../../../../../modules/bets/store/actions/tickets';
import { prematchCreateTicketRequest } from '../../../../../modules/bets/store/actions/prematch';
import { liveCreateTicketRequest } from '../../../../../modules/bets/store/actions/live';
import { getBetsState } from '../../../../../modules/bets/store/selectors/betData';

import { doLogin } from '../../../../../modules/casino/store/actions/authentication';

import { getBetsBonuses, getBetsRingFences } from '../../../../../modules/bets/store/selectors/wallet';

import { getWinnerFunBoostNum } from '../../../../../modules/winner-fun/store/selectors/winnerFunData';
import { RootState } from '@/store';

import styled from 'styled-components';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

//import FixHorizontalScroll from './fix-horizontal-scroll';

const SoldCard = (props: any) => {
  const {
    amount,
    tax,
    taxPercent,
    totalOdds,
    winAmount,
    autoAcceptOddChange,
    stakeInc,
    stakeDec,
    stakeSet,
    toggleAutoAcceptOddChange,
    placeTicketEnabled,
    placeTicketErrorMessage,
    liveCreateTicket,
    prematchCreateTicket,
    clearBets,
    clearCreateStatus,
    auth,
    ticketOnline,
    betsSlipSetTicketOnline,
    minWinAmount,
    ticketType,
    wallet,
    profile,
    exitFull,
    liveBets,
    prematchBets,
    bonus,
    handleDeleteButton,
    walletBonusEvaluate,
    walletBonuses,
    walletRingFences,
    requestBonusEvaluation,
    currentTicket,
    isWinnerFun,
    winnerFunEvaluate,
    winnerFunBoostNum,
    toggleWinnerFunBoost,
    winnerFunBoostActive,
    ticketWinnerFunOpenedListRequest,
    allowTicketPlaceWithInvalidEvents,
    toggleAllowTicketPlaceWithInvalidEvents,

    freeBets,
    selectedFreeBet,
    selectedFreeBetSubIndex,
    betSlipFreeBetClear,
    betSlipSetFreeBetIndex,
    freeBetsEvaluate,
    dayMultiBetNumber,
    dayMultiBets,

    t,
  } = props;

  //const isDesktop1024 = useMediaQuery('(min-width:1024px)');
  const imageType = 'desktop'; // isDesktop1024 ? 'desktop' : 'mobile';

  const [stakeAmount, setStakeAmount] = React.useState(amount);
  const [maxBet, setMaxBet] = React.useState({
    enabled: false,
    oldStake: amount,
  });
  const [ruleDialog, setRuleDialog] = React.useState({
    open: false,
    eventName: '',
    type: '',
  });

  //console.log("sold card props", props);
  const [disableSwitch, setDisableSwitch] = React.useState(false);
  const classes = {};

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const toggleDeleteDialog = () => {
    setOpenDeleteDialog((v) => !v);
  };

  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const handlePlaceBetClose = (type = 0) => {
    setOpenPlaceBet(false);
    if (typeof type !== 'object') {
      if (type === 1) {
        //window.location.hash = '#tickets-details';
      }
      if (type !== 2) {
        clearBets();
      } else {
        clearCreateStatus();
      }
    }
  };

  /* TODO: reenable this
  const throttledFunction = React.useRef(
    debounce(ticketWinnerFunOpenedListRequest, 30 * 1000, { leading: true, maxWait: 30 * 1000, trailing: false })
  );

  React.useEffect(() => {
    if (currentTicket && isWinnerFun) {
      if (currentTicket.live && currentTicket.prematch) {
        if (currentTicket.live.selected.length + currentTicket.prematch.selected.length === 1) {
          throttledFunction.current();
        }
      }
    }
  }, [currentTicket, isWinnerFun]);
  */

  React.useEffect(() => {
    if (freeBetsEvaluate && freeBetsEvaluate.data && freeBets && selectedFreeBet !== -1) {
      try {
        if (freeBets[selectedFreeBet] && freeBets[selectedFreeBet].code) {
          const code = freeBets[selectedFreeBet].code;
          if (typeof freeBetsEvaluate.data[code] !== 'undefined' && !freeBetsEvaluate.data[code]) {
            betSlipFreeBetClear();
          }
        }
      } catch (err) {
        /*noop*/
      }
    }
  }, [freeBetsEvaluate, freeBets, selectedFreeBet, betSlipFreeBetClear]);

  React.useEffect(() => {
    if (liveBets && liveBets.length) {
      setDisableSwitch(true);
      if (!ticketOnline) {
        betsSlipSetTicketOnline(true);
      }
    } else {
      setDisableSwitch(false);
    }
  }, [ticketOnline, liveBets, betsSlipSetTicketOnline]);

  React.useEffect(() => {
    if (auth && ['user', 'token'].indexOf(auth.auth_type) === -1) {
      if (!isWinnerFun) {
        betsSlipSetTicketOnline(false);
      } else {
        betsSlipSetTicketOnline(true);
      }
    } else {
      betsSlipSetTicketOnline(true);
    }
  }, [auth, isWinnerFun, betsSlipSetTicketOnline]);

  React.useEffect(() => {
    let newAmount = amount;
    newAmount = forceRoundIfAny(newAmount);
    setStakeAmount(newAmount);
  }, [isWinnerFun, amount]);

  React.useEffect(() => {
    //console.log("wallet update", wallet);
    requestBonusEvaluation();
  }, [wallet, profile, requestBonusEvaluation]);

  React.useEffect(() => {
    //debug('maxBet', maxBet);

    if (maxBet.enabled) {
      let eligible = false;

      if (isWinnerFun) {
        if (winnerFunEvaluate && winnerFunEvaluate.success && winnerFunEvaluate.data) {
          eligible = winnerFunEvaluate.data.eligible;
        }
      } else {
        if (walletBonusEvaluate && walletBonusEvaluate.success && walletBonusEvaluate.data) {
          eligible = walletBonusEvaluate.data.eligible;
        }
      }

      const maxWin = 150000;

      let to = totalOdds;

      if (!isWinnerFun && bonus && bonus.digitainAppliedBonus && bonus.digitainAppliedBonus.success) {
        const db = bonus.digitainAppliedBonus.bonuses.find((b: any) => b.type === 'Express');
        //debug('appli digitian bonus', db);
        if (db) {
          to = to * (1 + db.percentage / 100);
        }
      }

      //debug('totalOdds', totalOdds, 'to', to);

      let stake = maxWin / to;

      stake = Math.floor(stake * 100) / 100;

      if (stake * to > maxWin) {
        stake -= 0.01;
        stake = Math.floor(stake * 100) / 100;
      }

      if (eligible) {
        if (stake > wallet.total) {
          stake = wallet.total;
        }
      } else {
        if (isWinnerFun) {
          if (stake > wallet.total) {
            stake = wallet.total;
          }
        } else if (stake > wallet.main) {
          stake = wallet.main;
        }
      }

      if (stake > 50000) {
        // max bet
        stake = 50000;
      }

      stake = forceRoundIfAny(stake);
      setStakeAmount(stake);
      stakeSet(stake);
    }
  }, [maxBet, totalOdds, wallet, walletBonusEvaluate]);

  const handleAutoAcceptChange = () => {
    toggleAutoAcceptOddChange();
  };

  const handleAllowTicketPlaceWithInvalidEvents = () => {
    toggleAllowTicketPlaceWithInvalidEvents();
  };

  //const [ticketOnline, setTicketOnline] = React.useState(true);
  const handleTicketLocation = (online: any) => {
    //console.log(online ? "online" : "agentie");
    //setTicketOnline(online);
    betsSlipSetTicketOnline(online);
  };

  //const inputProps = { "aria-label": "secondary checkbox" };

  const onStakeInc = (s: any, e: any) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    if (isWinnerFun && amount === wallet.total) return;
    betSlipFreeBetClear();
    stakeInc(s);
  };

  const onStakeDec = (s: any, e: any) => {
    try {
      e.preventDefault();
    } catch (err) {
      //noop
    }
    betSlipFreeBetClear();
    stakeDec(s);
  };

  const handleStakeChange = (e: any) => {
    //debug('stake change', e.target.value);

    if (selectedFreeBet !== -1) return;
    if (liveBets.length + prematchBets.length === 0) return;

    let ev = e.target.value;

    // let them input an empty string
    if (ev === '') {
      setStakeAmount('');
      stakeSet('');
      return;
    }

    // don't let them put an amount < 1
    if (ev === '0') {
      return;
    }

    // convert , to .
    ev = ev.split(',').join('.');

    // only one .
    const sev = ev.split('.');
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      ev = sev[0] + '.' + sev[1].substring(0, 2);
    }

    let v: string | number = '';

    if (ev !== '') {
      // amounts ending with . have the same value
      if (ev.endsWith('.')) {
        setStakeAmount(ev);
        return;
      }

      // only valid numbers
      if (isNaN(+ev)) {
        return;
      }

      // convert amount
      v = forceRoundIfAny(ev);
      if (isNaN(v)) return;
    }

    if (isWinnerFun && v > wallet.total) {
      v = forceRoundIfAny(wallet.total);
    }

    // update amount

    setStakeAmount(v);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  const toggleMaxBet = () => {
    betSlipFreeBetClear();
    setMaxBet((mbe) => {
      if (mbe.enabled) {
        setStakeAmount(mbe.oldStake);
        stakeSet(mbe.oldStake);

        return {
          enabled: false,
          oldStake: 0,
        };
      }

      return {
        enabled: true,
        oldStake: amount,
      };
    });
  };

  let bgCls = '';
  if (winAmount > 100) {
    bgCls = 'large';
  } else if (winAmount > 50) {
    bgCls = 'medium';
  }

  const createTicket = () => {
    setOpenPlaceBet(true);

    let free_bet_code = null;
    let free_bet_name = null;
    let free_bet_pid = null;
    let free_bet_redeemable = 0;
    if (selectedFreeBet > -1) {
      const freeBet = freeBets[selectedFreeBet];
      free_bet_code = freeBet.code;
      free_bet_name = freeBet.name;
      free_bet_pid = freeBet.fbid;
      free_bet_redeemable = freeBet.redeemable;
    }

    liveCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    // prematchCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    betSlipFreeBetClear();
  };

  const handleLogin = () => {
    if (typeof exitFull === 'function') exitFull();
    localStorage.setItem('redirect_after_login', props.location.pathname);

    props.doLogin();
  };

  let loggedIn = false;
  if (auth && ['user', 'token'].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const onFreeBet = (index: number) => (subIndex: number) => () => {
    if (selectedFreeBet === index && selectedFreeBetSubIndex === subIndex) {
      betSlipFreeBetClear();
      requestBonusEvaluation();
    } else {
      betSlipSetFreeBetIndex(index, freeBets[index], subIndex);
      const freeBetAmount = freeBets[index].amount_small === 1 ? freeBets[index].amount / 100 : freeBets[index].amount;
      stakeSet(freeBetAmount);
    }
  };

  const onFreeBetIndexes = (e: any) => {
    let index = -1;
    let subIndex = -1;
    let fbid = null;

    if (e.target.dataset.index) {
      index = Number(e.target.dataset.index);
    }
    if (e.target.dataset.subindex) {
      subIndex = Number(e.target.dataset.subindex);
    }
    if (e.target.dataset.id) {
      fbid = e.target.dataset.id;
    }

    if (index === -1 || subIndex === -1) {
      return;
    }

    if (e.target.dataset.disabled && e.target.dataset.disabled === 'true') {
      if (fbid) {
        handleRuleOpen('freeBet', fbid);
      }
      return;
    }

    if (selectedFreeBet === index && selectedFreeBetSubIndex === subIndex) {
      betSlipFreeBetClear();
      requestBonusEvaluation();
    } else {
      betSlipSetFreeBetIndex(index, freeBets[index], subIndex);
      const freeBetAmount = freeBets[index].amount_small === 1 ? freeBets[index].amount / 100 : freeBets[index].amount;
      stakeSet(freeBetAmount);
    }
  };

  const buildFreeBets = () => {
    const buttons: any = [];

    if (!ticketOnline) return buttons;
    const now = Math.floor(+new Date() / 1000);

    if (isWinnerFun) return buttons;

    const freeBetAllDisable = maxBet.enabled ? true : false;

    freeBets.forEach((fb: any, i: number) => {
      if (
        (fb.product === 'sport' || fb.product === 'sport.live' || fb.product === 'sport.prematch') &&
        fb.count &&
        fb.expires > now
      ) {
        const amount = fb.amount_small === 1 ? fb.amount / 100 : fb.amount;

        let freeBetDisabled = false;
        if (
          freeBetsEvaluate &&
          freeBetsEvaluate.data &&
          typeof freeBetsEvaluate.data[fb.code] !== 'undefined' &&
          !freeBetsEvaluate.data[fb.code]
        ) {
          freeBetDisabled = true;
        }

        if (fb.product !== 'sport') {
          if (liveBets && liveBets.length && fb.product !== 'sport.live') {
            freeBetDisabled = true;
          }
          if (prematchBets && prematchBets.length && fb.product !== 'sport.prematch') {
            freeBetDisabled = true;
          }
        }

        [...Array(fb.count).keys()].forEach((j) => {
          buttons.push(
            {
              fbIndex: i,
              fbSubIndex: j,
              name: fb.name,
              code: fb.code,
              fbid: fb.fbid,
              amount: amount,
              disabled: freeBetDisabled,
              disabledAll: freeBetAllDisable,
              selected: selectedFreeBet === i && selectedFreeBetSubIndex === j,
              clickHandler: onFreeBetIndexes,
            },
            /*
            <ButtonBase
              key={`freeBet_${i}_${j}`}
              onClick={clickHandler}
              variant="contained"
              className={`
              ${classes.increment} ${classes.freeBetButton} 
              ${selectedFreeBet === i && selectedFreeBetSubIndex === j ? classes.freeBetButtonSelected : ''} 
              ${freeBetDisabled ? classes.freeBetButtonDisable : ''}
              ${freeBetAllDisable ? classes.freeBetButtonDisable : ''}
            `}
            >
              <b>
                {amount}&nbsp;<span className={'smaller'}>Lei</span>
              </b>
              <div className={`${classes.freeBetTicker} freeBetTicker`} title={t(fb.name)}>
                Free Bet
              </div>
              {(freeBetDisabled || freeBetAllDisable) && (
                <div className={`${classes.freeBetQuery} pulse-anim freeBetQuery`} title={t(fb.name)}>
                  ?
                </div>
              )}
            </ButtonBase>,
            */
          );
        });
      }
    });

    return buttons;
  };

  //debug('BONUS', bonus, 'AMOUNT', amount);

  let ticketAppliedBonus = null;
  if (!isWinnerFun && bonus && bonus.ticketAppliedBonus) {
    ticketAppliedBonus = {
      percentage: bonus.ticketAppliedBonus.maxPercentage,
      amountMax: bonus.ticketAppliedBonus.amountMax,
      currency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
    };

    /*
    (
      <div className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}>
        <div className={classes.soldSmall}>
          {t('Progressive Bonus')} {bonus.ticketAppliedBonus.maxPercentage}%
        </div>
        <div className={classes.soldSmallAmount}>
          +{formatAmount(bonus.ticketAppliedBonus.amountMax, false, false)}{' '}
          {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
        </div>
      </div>
    );
    */
  }
  if (!isWinnerFun && bonus && bonus.digitainAppliedBonus && bonus.digitainAppliedBonus.success) {
    const db = bonus.digitainAppliedBonus.bonuses.find((b: any) => b.type === 'Express');
    if (db) {
      ticketAppliedBonus = {
        percentage: db.percentage,
        amountMax: db.maxAmount,
        currency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
      };
      /*
      ticketAppliedBonus = (
        <div className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}>
          <div className={classes.soldSmall}>
            {t('Progressive Bonus')} {db.percentage}%
          </div>
          <div className={classes.soldSmallAmount}>
            +{formatAmount(db.maxAmount, false, false)} {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
          </div>
        </div>
      );
      */
    }
  }

  let ticketBonusEligibles = null;
  if (!isWinnerFun && bonus && bonus.ticketBonusEligibles && bonus.ticketBonusEligibles.length) {
    const bonuses: any[] = [];
    bonus.ticketBonusEligibles.forEach((b: any) => {
      if (b.eligible) {
        bonuses.push(b.bonus.name);
      }
    });
    ticketBonusEligibles = {
      bonuses: bonuses.join(' + '),
    };
    /*
    ticketBonusEligibles = (
      <div className={`${classes.soldHolder} d-flex flex-nowrap align-items-center justify-content-between`}>
        <div className={classes.soldSmall}>{t('Eligible Bonuses')}</div>
        <div className={classes.soldSmallAmount}>{bonuses.join(' + ')}</div>
      </div>
    );
    */
  }

  const scrollToBonusError = () => {
    const wrapper = document.querySelector('.bets-scroller');
    const elem = document.querySelector('.scroll-to-this-bet');
    if (wrapper && elem) {
      // @ts-ignore
      const elemTop = elem.getBoundingClientRect().top - elem.offsetParent.getBoundingClientRect().top;
      wrapper.scrollTop = elemTop;
    }
  };

  let bonusEvaluateMessagesEligible = null;
  let hasBonusEvaluateMessages = false;
  const stakeErrorBonuses: any[] = [];
  let winnerFunErrors: any[] = [];

  let free_money_used = 0;
  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  const hasBets = liveBets.length + prematchBets.length > 0;

  if (isWinnerFun) {
    bLimit = wallet.total;
    if (winnerFunEvaluate && winnerFunEvaluate.success && winnerFunEvaluate.data) {
      if (winnerFunEvaluate.data.valid) {
        valid = bLimit >= amount;
      } else {
        valid = winnerFunEvaluate.data.valid;

        if (winnerFunEvaluate.data.details && winnerFunEvaluate.data.details.length) {
          winnerFunErrors = winnerFunEvaluate.data.details.map((err: any) => {
            return {
              message: t(err),
            };
          });
          /*
          winnerFunErrors = (
            <div>
              {winnerFunEvaluate.data.details.map((err, i) => {
                return (
                  <div className={`${classes.message} ${classes.tc}`} key={i}>
                    {t(err)}
                  </div>
                );
              })}
            </div>
          );
          */
        }
      }
      free_money_used = winnerFunEvaluate.data.main_amount;
    }
  } else if (window.config.useBonusEvaluation === '1' && hasBets) {
    const walletBonusesHash: any = {};
    if (walletBonuses) {
      walletBonuses.forEach((wb: any) => {
        walletBonusesHash[wb.id] = wb.name;
      });
    }

    const bonuses: any[] = [];
    let usedBonuses: any[] = [];
    let usedRingFences: any[] = [];
    let errorBonuses: any[] = [];
    //let eligible = false;

    if (
      walletBonusEvaluate &&
      walletBonusEvaluate.success &&
      walletBonusEvaluate.data &&
      walletBonusEvaluate.data.bonus
    ) {
      bLimit = free_money_used = twoDecimalsFloat(walletBonusEvaluate.data.free_money_used);
      usedBonuses = walletBonusEvaluate.data.bonus ? walletBonusEvaluate.data.bonus : [];
      usedRingFences = walletBonusEvaluate.data.ring_fence ? walletBonusEvaluate.data.ring_fence : [];
      errorBonuses = walletBonusEvaluate.data.details ? walletBonusEvaluate.data.details : [];
      //eligible = walletBonusEvaluate.data.eligible;
      valid = walletBonusEvaluate.data.valid;
    } else {
      valid = bLimit >= amount;
    }

    walletBonuses.forEach((wb: any) => {
      let total_used = 0;
      let balance_total = wb.amount;

      // collect what would the total amount ring fence + bonus would be
      const rf = walletRingFences.find((rf: any) => wb.id === rf.id);
      if (rf) {
        balance_total += rf.amount;
      }

      // used ring fence
      const urf = usedRingFences.find((rf) => wb.id === rf.id);
      if (urf) {
        bLimit += twoDecimalsFloat(urf.balance_used);
        total_used += twoDecimalsFloat(urf.balance_used);
      }

      // used bonus
      const ub = usedBonuses.find((b) => wb.id === b.id);
      if (ub) {
        bLimit += twoDecimalsFloat(ub.balance_used);
        total_used += twoDecimalsFloat(ub.balance_used);
      }

      total_used = twoDecimalsFloat(total_used);
      bonuses.push({ id: wb.id, name: wb.name, balance: balance_total, balance_used: total_used ? -total_used : 0 });
    });

    bLimit = twoDecimalsFloat(bLimit);
    //bonuses.sort((a, b) => a.balance - b.balance);

    //console.log("bonuses", bonuses, errorBonuses);
    bonusEvaluateMessagesEligible = bonuses.map((b, i) => {
      const eb: any = errorBonuses.filter((eb) => eb.id === b.id);
      hasBonusEvaluateMessages = true;

      const errors = eb
        .filter((e: any) => e.error && e.error.type === 'ticket')
        .map((e: any) => {
          const args = [...e.error.args];
          args[0] = (walletBonusesHash[eb.id] ? walletBonusesHash[eb.id] + ': ' : '') + t(args[0]); // see if we have a translation for that message

          const message = formatMessage(...args);
          if (e.error.section === 'stake') {
            stakeErrorBonuses.push(message);
            return null;
          } else if (e.error.section !== 'generic') {
            return null;
          }

          return {
            message,
          };
        })
        .filter((e: any) => e !== null);

      return {
        name: b.name,
        hasErrors: !valid && eb.length > 0,
        hasWarnings: valid && eb.length > 0,
        // @ts-ignore
        balance: formatAmountWithDecimals(b.balance, 2, true),
        // @ts-ignore
        balance_used: formatAmountWithDecimals(b.balance_used, 2, true),
        currency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
        errors: errors,
      };
    });

    /*
    bonusEvaluateMessagesEligible = (
      <div>
        {bonuses.map((b, i) => {
          const eb = errorBonuses.filter((eb) => eb.id === b.id);
          //console.log("errorBonuses", eb);

          hasBonusEvaluateMessages = true;

          return (
            <div key={i}>
              <div
                className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center}`}
              >
                <div className={`${classes.soldSmall} ${classes.ellipsis}`}>
                  {!valid && eb.length > 0 && <span className={classes.bonusHasErrors}>!</span>}
                  {valid && eb.length > 0 && <span className={`${classes.bonusHasWarnings}`}>!</span>}
                  {b.name}
                </div>
                <div className={classes.soldSmallAmount}>
                  {formatAmountWithDecimals(b.balance, 2, true)} {isWinnerFun ? 'W' : 'Lei'} (
                  <span className={classes.messageErr}>{formatAmountWithDecimals(b.balance_used, 2, true)}</span>)
                </div>
              </div>
              {!valid && eb.length > 0 && (
                <div className={`${classes.bonusErrorBar}`} onClick={scrollToBonusError}>
                  <div className="txt">{t('Conditiile BONUS nu sunt indeplinite')}</div>
                  <div className="bar-question-mark">?</div>
                </div>
              )}
              {valid && eb.length > 0 && (
                <div className={`${classes.bonusErrorBar} ${classes.bonusWarningBar}`} onClick={scrollToBonusError}>
                  <div className="txt">{t('Acest bilet nu va contribui la rulajul bonusului')}</div>
                  <div className="bar-question-mark">?</div>
                </div>
              )}
              <div className={classes.bonusBalanceErrors}>
                {!valid &&
                  eb
                    .filter((e) => e.error && e.error.type === 'ticket')
                    .map((e, i) => {
                      const args = [...e.error.args];
                      args[0] = (walletBonusesHash[eb.id] ? walletBonusesHash[eb.id] + ': ' : '') + t(args[0]); // see if we have a translation for that message

                      if (e.error.section === 'generic') {
                        return (
                          <div key={i} className={classes.message}>
                            {formatMessage(...args)}
                          </div>
                        );
                      } else if (e.error.section === 'stake') {
                        stakeErrorBonuses.push(
                          <div key={i} className={classes.message}>
                            {formatMessage(...args)}
                          </div>,
                        );
                      }
                      return null;
                    })}
              </div>
            </div>
          );
        })}
      </div>
    );
    */
  }

  const noop = () => {
    /**/
  };

  const handleRuleOpen = (type: any, eventName: any) => () => {
    //debug(`handleRuleOpen: type = ${type}, eventName = ${eventName}`);
    setRuleDialog({
      open: true,
      eventName: eventName,
      type: type,
    });
  };
  const handleRuleClose = () => {
    setRuleDialog({
      open: false,
      eventName: '',
      type: '',
    });
  };

  const generateWinnerFunChooseStakeText = () => {
    if (
      !winnerFunEvaluate ||
      !winnerFunEvaluate.data ||
      !winnerFunEvaluate.data.eligible ||
      !winnerFunEvaluate.data.eventPointsMultiplier ||
      winnerFunEvaluate.data.eventPointsMultiplier === 1
    ) {
      return {
        hasEvaluation: false,
      };
      //return <div className={classes.stakeTitle}>{t('Choose Stake')}</div>;
    }

    return {
      hasEvaluation: true,
      points: twoDecimalsFloat(winnerFunEvaluate.data.maxWinAmount - winnerFunEvaluate.data.originalMaxWinAmount),
      eligible: winnerFunEvaluate.data.eligible,
      eventName: winnerFunEvaluate.data.event_name.text,
    };

    /*
    return (
      <React.Fragment>
        <div
          className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-start wf-stake`}
        >
          <div className={`${classes.stakeTitle} wf-stake-title`}>{t('Choose Stake')}</div>
          <div className={'wf-points'}>
            <div className={'wf-points-text'}>
              + {twoDecimalsFloat(winnerFunEvaluate.data.maxWinAmount - winnerFunEvaluate.data.originalMaxWinAmount)} W
            </div>
            <div className={'wf-points-subtext'}>{t('win BOOST')}</div>
          </div>
          <div
            className={winnerFunEvaluate.data.eligible ? 'wf-help' : 'wf-no-help'}
            onClick={
              winnerFunEvaluate.data.eligible ? handleRuleOpen('balance', winnerFunEvaluate.data.event_name.text) : noop
            }
          >
            {winnerFunEvaluate.data.eligible ? '?' : ''}
          </div>
        </div>
      </React.Fragment>
    );
    */
  };

  let onlineTicketDisabled = false;
  if (selectedFreeBet === -1 && !(bLimit !== 0 && bLimit >= amount)) {
    onlineTicketDisabled = true;
  } else if (!placeTicketEnabled) {
    onlineTicketDisabled = true;
  } else if (amount < 2) {
    onlineTicketDisabled = true;
  } else if (isWinnerFun && !valid) {
    onlineTicketDisabled = true;
  } else {
    let hasBetBuilderBets = false;

    liveBets.forEach((b: any) => {
      if (b.betType === 'betBuilder') {
        hasBetBuilderBets = true;
      }
    });
    prematchBets.forEach((b: any) => {
      if (b.betType === 'betBuilder') {
        hasBetBuilderBets = true;
      }
    });

    if (hasBetBuilderBets && ticketType === 'system') {
      onlineTicketDisabled = true;
    }
  }

  /*
  debug(
    'onlineTicketDisabled',
    selectedFreeBet === -1 && !(bLimit !== 0 && bLimit >= amount),
    !placeTicketEnabled,
    amount < 2,
    isWinnerFun && !valid,
    selectedFreeBet === -1,
  );
  debug(
    'amount',
    amount,
    'bLimit',
    bLimit,
    twoDecimalsFloat(bLimit),
    'valid',
    valid,
    'onlineTicketDisabled',
    onlineTicketDisabled,
  );
  debug(
    'winnerFunEvaluate',
    winnerFunEvaluate ? winnerFunEvaluate.data : winnerFunEvaluate,
    'winnerFunBoostNum',
    winnerFunBoostNum,
  );
  */

  let hasBoostAvailable = false;
  let hasBoostEligible = false;

  if (isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.boostAvailable) {
    hasBoostAvailable = true;
    hasBoostEligible = true;
  }

  if (isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.duplicateEvent !== null) {
    hasBoostEligible = false;
    if (winnerFunBoostActive) {
      setTimeout(() => toggleWinnerFunBoost(false), 0);
    }
  }

  const genWinnerFunWalletDetails = () => {
    if (!winnerFunEvaluate || !winnerFunEvaluate.data)
      return {
        hasEvaluation: false,
      };

    return {
      hasEvaluation: true,
      // @ts-ignore
      walletMain: formatAmountWithDecimals(wallet.main, 2, true),
      currency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
      // @ts-ignore
      walletMainDeduct: formatAmountWithDecimals(winnerFunEvaluate.data.main_amount, 2, true),
      eligible: winnerFunEvaluate.data.eligible,
      eventName: winnerFunEvaluate.data.event_name.text,
      // @ts-ignore
      walletSecondary: formatAmountWithDecimals(wallet.secondary, 2, true),
      walletSecondaryDeduct: winnerFunEvaluate.data.secondary_amount,
      seasonIcon: winnerFunEvaluate.data.season_icon,
      eventIcon: winnerFunEvaluate.data.event_icon,
      evalError: winnerFunEvaluate.data.evalError ?? 0, // -1: not eleigible with some events or -6: duplicate
      duplicateEvent: winnerFunEvaluate.data.duplicateEvent,
    };
    /*
    return (
      <React.Fragment>
        <div
          className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center wf-balance-main`}
        >
          <div className={'wf-icon'}>
            <img src={choseImage(winnerFunEvaluate.data.season_icon, imageType)} alt="icon" />
          </div>
          <div className={'wf-title'}>{t('MAIN BALANCE')}</div>
          <div className={'wf-sold'}>
            {formatAmountWithDecimals(wallet.main, 2, true)} {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
            &nbsp;(
            <span className={'wf-deduct'}>
              -{formatAmountWithDecimals(winnerFunEvaluate.data.main_amount, 2, true)}
            </span>
            )
          </div>
          <div
            className={winnerFunEvaluate.data.eligible ? 'wf-help' : 'wf-no-help'}
            onClick={
              winnerFunEvaluate.data.eligible ? handleRuleOpen('event', winnerFunEvaluate.data.event_name.text) : noop
            }
          >
            {winnerFunEvaluate.data.eligible ? '?' : ''}
          </div>
        </div>
        {winnerFunEvaluate.data.event_name && (
          <div
            className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center wf-balance-secondary`}
          >
            <div className={'wf-icon'}>
              <img src={choseImage(winnerFunEvaluate.data.event_icon, imageType)} alt="icon" />
            </div>
            <div className={'wf-title'}>
              {chooseTextLanguage(winnerFunEvaluate.data.event_name.text, props.i18n.language)}
            </div>
            <div className={'wf-sold'}>
              {formatAmountWithDecimals(wallet.secondary, 2, true)}{' '}
              {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
              &nbsp;(
              <span className={'wf-deduct'}>
                -{formatAmountWithDecimals(winnerFunEvaluate.data.secondary_amount, 2, true)}
              </span>
              )
            </div>
            <div className={winnerFunEvaluate.data.eligible ? 'wf-empty-help' : 'wf-no-help'}>&nbsp;</div>
          </div>
        )}
        {isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.evalError === -1 && (
          <div className={classes.boostErr}>{t('Winner Boost: cannot be applied to some events on the ticket')}</div>
        )}
        {isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.evalError === -6 && (
          <div className={classes.boostErr}>
            {t('You cannot use a Winner BOOST again on event: {{EVENT_NAME}}', {
              EVENT_NAME: winnerFunEvaluate.data.duplicateEvent,
            })}
          </div>
        )}
        <div className={classes.hr}></div>
      </React.Fragment>
    );
    */
  };

  let disableBetTicketInShop = false;
  if (typeof window.config.disableBetTicketInShop !== 'undefined' && window.config.disableBetTicketInShop === '1') {
    disableBetTicketInShop = true;
    if (!ticketOnline) {
      setTimeout(() => {
        betsSlipSetTicketOnline(true);
      }, 0);
    }
  }

  React.useEffect(() => {
    let setTicket = false;

    if (!isWinnerFun && prematchBets.length) {
      const exists: any = {};

      prematchBets.forEach((bet: any) => {
        exists[`${bet.idMatch}-${bet.idMbo}`] = true;
      });

      dayMultiBets.find((multiBet: any) => {
        if (multiBet.stakes.length === prematchBets.length) {
          let isComplete = true;

          multiBet.stakes.forEach((ticket: any) => {
            const key = `${ticket.event_id}-${ticket.stake_id}`;
            if (!exists[key]) isComplete = false;
          });

          if (isComplete) {
            props.betsSlipSetMultiBetDayNumber(multiBet.number);
            setTicket = true;
            return true;
          }
        }

        return false;
      });
    }

    if (!setTicket) {
      props.betsSlipSetMultiBetDayNumber(0);
    }
  }, [isWinnerFun, dayMultiBets, prematchBets, props.betsSlipSetMultiBetDayNumber]);

  let overrideTotalOdds = null;
  let overrideWinning = null;
  if (
    !isWinnerFun &&
    dayMultiBetNumber > 0 &&
    dayMultiBets &&
    dayMultiBets.length &&
    currentTicket.ticketType === 'single'
  ) {
    const dayMultiBet = dayMultiBets.find((dmb: any) => dmb.number === dayMultiBetNumber);

    if (dayMultiBet && dayMultiBet.express_factor) {
      overrideTotalOdds = totalOdds * dayMultiBet.express_factor;
      overrideWinning = winAmount * dayMultiBet.express_factor;
    }
  }

  const contextValue = {
    overrideTotalOdds,
    overrideTotalOddsStr: formatTotalOddsValue(overrideTotalOdds),
    overrideWinning,
    overrideWinningStr: formatAmount2(overrideWinning),

    hasBets,
    valid,
    loggedIn,

    walletMain: wallet.main,
    // @ts-ignore
    walletMainStr: formatAmountWithDecimals(wallet.main, 2, true),
    walletCurrency: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
    freeMoneyUsed: free_money_used,
    freeMoneyUsedAmount: selectedFreeBet !== -1 ? 0 : free_money_used,
    // @ts-ignore
    freeMoneyUsedAmountStr: formatAmountWithDecimals(selectedFreeBet !== -1 ? 0 : free_money_used, 2, true),

    ticketAppliedBonus,
    ticketBonusEligibles,
    bonusEvaluateMessagesEligible,
    placeTicketErrorMessage,

    hasBoostAvailable,
    hasBoostEligible,

    isWinnerFun,
    winnerFunTotalOdds: winnerFunEvaluate ? winnerFunEvaluate.data.wfTotalOdds : 0,
    winnerFunTotalOddsStr: winnerFunEvaluate ? formatTotalOddsValue(winnerFunEvaluate.data.wfTotalOdds) : 0,
    winnerFunEvaluate,
    winnerFunDetails: genWinnerFunWalletDetails(),
    winnerFunErrors,
    winnerFunChooseStakeText: generateWinnerFunChooseStakeText(),
    winnerFunBoostActive,

    totalOdds: totalOdds,
    totalOddsStr: formatTotalOddsValue(totalOdds),
    amount,
    winning:
      isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data ? winnerFunEvaluate.data.maxWinAmount : winAmount,
    winningStr: formatAmount2(
      isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data ? winnerFunEvaluate.data.maxWinAmount : winAmount,
    ),

    minWinning:
      isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data ? winnerFunEvaluate.data.minWinAmount : minWinAmount,
    minWinningStr: formatAmount2(
      isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data ? winnerFunEvaluate.data.minWinAmount : minWinAmount,
    ),

    ticketType,
    tax,
    taxStr: formatAmount2(tax),
    taxPercent,
    stakeAmount,

    stakeErrorBonuses,
    handleStakeChange,
    onClickStakeDec: (e: any) => onStakeDec(-1, e),
    onClickStakeInc: (e: any) => onStakeInc(-1, e),
    onStakeDec,
    onStakeInc,

    maxBet: maxBet.enabled,
    toggleMaxBet,

    selectedFreeBet,
    selectedFreeBetName: selectedFreeBet !== -1 ? freeBets[selectedFreeBet]?.name ?? '' : '',
    freeBets: buildFreeBets(),

    ticketOnline,
    onlineTicketDisabled,
    placeTicketEnabled,
    createTicket,
    handleLogin,

    autoAcceptOddChange,
    handleAutoAcceptChange,

    allowTicketPlaceWithInvalidEvents,
    handleAllowTicketPlaceWithInvalidEvents,

    disableBetTicketInShop,
    openPlaceBet,
    handlePlaceBetClose,
    currentTicket: JSON.parse(JSON.stringify(currentTicket)),

    ruleDialogOpen: ruleDialog.open,
    handleRuleClose,
    ruleDialogEventName: ruleDialog.eventName,
    ruleDialogType: ruleDialog.type,

    openDeleteDialog,
    toggleDeleteDialog,
    handleDeleteButton: () => {
      // TODO: not defined. we should show a popup asking if the user wants to delete the ticket
      clearBets();
      toggleDeleteDialog();
    },
  };

  //console.log('SoldCard[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{props.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );

  /*
  return (
    <div className={`${classes.root} ${hasBoostAvailable && hasBets && valid ? classes.hasBonuses : ''}`}>
      {loggedIn && (
        <div>
          {hasBoostAvailable && hasBets && valid && (
            <div className={classes.bonuses}>
              <div
                className={`${classes.bonus} winner-boost ${winnerFunBoostNum &&
                  winnerFunEvaluate &&
                  winnerFunEvaluate.data &&
                  winnerFunEvaluate.data.boostAvailable &&
                  hasBoostEligible
                  ? classes.disable
                  : ''
                  }`}
                onClick={handleRuleOpen(
                  'boost',
                  winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.event_name
                    ? winnerFunEvaluate.data.event_name.text
                    : null
                )}
              >
                <div className="bonus-icon">
                  <DiamondSVG />
                </div>
                <div className="bonus-name">
                  Winner Boost <span>&nbsp;({winnerFunBoostNum})</span>
                </div>
                <div className="bonus-q">?</div>
              </div>
            </div>
          )}
          {hasBets && !valid && selectedFreeBet === -1 && (
            <InsufficientBalance isWinnerFun={isWinnerFun} exitFull={exitFull} />
          )}
          {(true || amount <= bLimit) && !isWinnerFun && (
            <React.Fragment>
              <div
                className={`${classes.soldHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center`}
              >
                <div className={classes.sold}>{t('AVAILABLE')}</div>
                <div className={classes.sold}>
                  {formatAmountWithDecimals(wallet.main, 2, true)} {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
                  {!!free_money_used && (
                    <span>
                      &nbsp;(
                      <span className={classes.messageErr}>
                        -{formatAmountWithDecimals(selectedFreeBet !== -1 ? 0 : free_money_used, 2, true)}
                      </span>
                      )
                    </span>
                  )}
                </div>
              </div>
              <div className={classes.hr}></div>
            </React.Fragment>
          )}
          {(true || amount <= bLimit) && isWinnerFun && genWinnerFunWalletDetails()}
        </div>
      )}
      {ticketAppliedBonus}
      {ticketBonusEligibles}
      {bonusEvaluateMessagesEligible}
      {(!!ticketAppliedBonus || !!ticketBonusEligibles || hasBonusEvaluateMessages) && (
        <div className={classes.hr2}></div>
      )}

      {isWinnerFun && winnerFunErrors}

      {!isWinnerFun && <div className={classes.stakeTitle}>{t('Choose Stake')}</div>}
      {isWinnerFun && generateWinnerFunChooseStakeText()}

      {hasBoostEligible && winnerFunEvaluate && (
        <WinnerFunBoostProgress
          state={winnerFunBoostActive}
          value={winnerFunEvaluate.data.eventPointsMultiplier}
          progress={Math.round(
            ((winnerFunEvaluate.data.eventPointsMultiplier - 1) * 100) / (winnerFunEvaluate.data.maxMultiplier - 1)
          )}
        />
      )}

      {isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data && winnerFunEvaluate.data.evalError === -2 && (
        <div className={classes.boostErr}>
          {t('Winner Boost: the minimum odds for the multiplier is {{MIN_ODD}}', {
            MIN_ODD: winnerFunEvaluate.data.event_min_odd_cap,
          })}
        </div>
      )}

      <div
        className={`${classes.stakeHolder
          } d-flex flex-row flex-nowrap justify-content-between align-items-center scip ${hasBoostEligible ? 'has-boost' : ''
          } ${hasBoostEligible ? (winnerFunBoostActive ? 'active-boost' : 'inactive-boost') : ''}`}
      >
        {winnerFunBoostActive ? (
          <div className={`${classes.textCenter} scip-first`}>
            <div className={classes.stakeSubtitle}>{t('BOOST Odds')}</div>
            <div className={`${classes.stakeAmount} tov`}>
              {winnerFunEvaluate ? formatTotalOddsValue(winnerFunEvaluate.data.wfTotalOdds) : 0}
            </div>
          </div>
        ) : (
          <div className={`${classes.textCenter} scip-first`}>
            <div className={classes.stakeSubtitle}>{t('Total Odds')}</div>
            <div className={`${classes.stakeAmount} tov`}>{formatTotalOddsValue(totalOdds)}</div>
          </div>
        )}
        <div className={`${classes.textCenter} scip-second`}>
          <div className={classes.stakeSubtitle}>{t('Stake')}</div>
          <div className={classes.stakeAmount}>
            {amount}{' '}
            <span className={`${classes.currency}`}>{isWinnerFun ? 'W' : formatCurrency(wallet.currency)}</span>
          </div>
        </div>
        <div className={`${classes.textCenter} ${classes.mask} ${bgCls} scip-third`}>
          <div className={`${classes.stakeSubtitle} subtitle`}>{t('Winning')}</div>
          <div className={`${classes.stakeAmount} title`}>
            {formatAmount2(
              isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data
                ? winnerFunEvaluate.data.maxWinAmount
                : winAmount
            )}{' '}
            <span className={`${classes.currency} currency`}>
              {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
            </span>
          </div>
        </div>
        {hasBoostEligible && <WinnerFunBoostButton state={winnerFunBoostActive} toggleState={toggleWinnerFunBoost} />}
      </div>
      {(tax > 0 || ticketType === 'system') && (
        <div className="d-flex flex-row flex-nowrap justify-content-between">
          <div>
            {tax > 0 && (
              <div className={classes.tax}>
                {t('Tax')} ({taxPercent}%): {formatAmount2(tax)} {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
              </div>
            )}
          </div>
          <div>
            {ticketType === 'system' && (
              <div className={classes.minWin}>
                {t('Min. Winning')}:{' '}
                {formatAmount2(
                  isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data
                    ? winnerFunEvaluate.data.minWinAmount
                    : minWinAmount
                )}{' '}
                {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
              </div>
            )}
          </div>
        </div>
      )}

      {window.config.useBonusEvaluation === '1' && stakeErrorBonuses.length !== 0 && (
        <div className={classes.stakeBonusBalanceErrors}>{stakeErrorBonuses}</div>
      )}

      <div className={`${classes.payHolder} d-flex flex-row flex-nowrap align-items-center justify-content-center`}>
        <BounceButton>
          <ButtonBase
            onClick={e => onStakeDec(-1, e)}
            variant="contained"
            className={`${classes.butWrapperLeft} ${maxBet.enabled ? classes.disabledButtons : ''}`}
          >
            <div className={classes.but}>-</div>
          </ButtonBase>
        </BounceButton>
        {selectedFreeBet !== -1 && (
          <div className={classes.freeBetStake}>
            Free Bet
            <span className={'bigger'}>&nbsp;{stakeAmount}&nbsp;</span>
            {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
          </div>
        )}
        {selectedFreeBet === -1 && (
          <InputBase
            type="text"
            onChange={handleStakeChange}
            className={`${classes.amount} ${amount < 2 ? classes.warning : ''} ${stakeErrorBonuses.length !== 0 ? classes.amountErr : ''
              } ${maxBet.enabled ? classes.disabledInput : ''}`}
            value={stakeAmount}
            inputProps={{
              className: `${classes.amountInput}`,
              'aria-label': 'stake',
              pattern: '[0-9.]+',
            }}
            endAdornment={
              <InputAdornment
                className={`${classes.amountEnd} ${amount < 2 ? classes.warning : ''} ${stakeErrorBonuses.length !== 0 ? classes.warning : ''
                  }`}
                position="end"
              >
                {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
              </InputAdornment>
            }
          />
        )}
        <BounceButton>
          <ButtonBase
            onClick={e => onStakeInc(-1, e)}
            variant="contained"
            className={`${classes.butWrapperRight} ${maxBet.enabled ? classes.disabledButtons : ''}`}
          >
            <div className={classes.but}>+</div>
          </ButtonBase>
        </BounceButton>
        <div
          className={`${classes.maxBetButtonWrapper} ${maxBet.enabled ? classes.maxBetButtonActive : ''} ${!loggedIn ? classes.dNone : ''
            }`}
        >
          <div
            onClick={toggleMaxBet}
            className={`${classes.maxBetButton} ${maxBet.enabled ? classes.maxBetButtonActive : ''}`}
          >
            {t('Max bet')}
          </div>
        </div>
      </div>
      {amount < 2 && (
        <div className={classes.warningText}>
          {t('Minim stake of')} 2 {isWinnerFun ? 'W' : 'Lei'}
        </div>
      )}
      {selectedFreeBet !== -1 && (
        <div className={classes.freeBetInfo}>
          {t('Place ticket with')}: {freeBets[selectedFreeBet].name}
        </div>
      )}
      <FixHorizontalScroll
        className={`${classes.incrementsHolder} ${freeBets && freeBets.length !== 0 ? 'has-free-bets' : ''
          } d-flex flex-row flex-nowrap justify-content-between align-items-center`}
      >
        {freeBets.length !== 0 && buildFreeBets()}
        <div
          className={`${classes.incrementWrapper} ${maxBet.enabled ? classes.disabledButtons : ''}`}
          onClick={e => onStakeInc(5, e)}
        >
          <ButtonBase variant="contained" className={classes.increment}>
            +5 {isWinnerFun ? 'W' : 'Lei'}
          </ButtonBase>
        </div>

        <div
          className={`${classes.incrementWrapper} ${maxBet.enabled ? classes.disabledButtons : ''}`}
          onClick={e => onStakeInc(10, e)}
        >
          <ButtonBase variant="contained" className={classes.increment}>
            +10 {isWinnerFun ? 'W' : 'Lei'}
          </ButtonBase>
        </div>

        <div
          className={`${classes.incrementWrapper} ${maxBet.enabled ? classes.disabledButtons : ''}`}
          onClick={e => onStakeInc(20, e)}
        >
          <ButtonBase variant="contained" className={classes.increment}>
            +20 {isWinnerFun ? 'W' : 'Lei'}
          </ButtonBase>
        </div>

        <div
          className={`${classes.incrementWrapper} ${maxBet.enabled ? classes.disabledButtons : ''}`}
          onClick={e => onStakeInc(50, e)}
        >
          <ButtonBase variant="contained" className={classes.increment}>
            +50 {isWinnerFun ? 'W' : 'Lei'}
          </ButtonBase>
        </div>
      </FixHorizontalScroll>
      <div className={classes.payButtonHolder}>
        <div className={classes.deleteButtonFixed} onClick={handleDeleteButton}>
          <img src={Delete} alt="" />
        </div>
        {(loggedIn || !ticketOnline) && (
          <React.Fragment>
            {ticketOnline && (
              <ButtonBase
                variant="contained"
                className={`${classes.payButton} ${onlineTicketDisabled ? classes.disabledButton : ''}`}
                onClick={createTicket}
                disabled={onlineTicketDisabled}
              >
                <div>
                  <div className={classes.buttonTitle}>{t('PLACE TICKET ONLINE')}</div>
                </div>
              </ButtonBase>
            )}
            {!ticketOnline && (
              <ButtonBase
                variant="contained"
                className={`${classes.payAgencyButton} ${!placeTicketEnabled || amount < 2 ? classes.disabledButton : ''
                  }`}
                onClick={createTicket}
                disabled={!placeTicketEnabled || amount < 2}
              >
                <div>
                  <div className={classes.buttonTitle}>{t('PLACE TICKET IN SHOP')}</div>
                </div>
              </ButtonBase>
            )}
          </React.Fragment>
        )}
        {!loggedIn && ticketOnline && (
          <ButtonBase variant="contained" className={`${classes.loggedInButton}`} onClick={handleLogin}>
            <div>
              <div className={classes.buttonTitle}>
                <img src={avatar} alt="" className={classes.avatar} /> {t('ENTER YOUR ACCOUNT')}
              </div>
            </div>
          </ButtonBase>
        )}
      </div>
      {!placeTicketEnabled && placeTicketErrorMessage !== '' && (
        <div className={classes.error}>{t(placeTicketErrorMessage)}</div>
      )}
      <div
        className={`${classes.switchHolder} d-flex flex-row flex-nowrap justify-content-between align-items-center betslip-footer-odds-change`}
        onClick={handleAutoAcceptChange}
      >
        <div className={classes.switchLabel}>{t('Automatically accept odds changes')}</div>
        <SimpleSwitch checked={autoAcceptOddChange} onChange={handleAutoAcceptChange} />
      </div>
      <div
        className={`${classes.switchHolder
          } d-flex flex-row flex-nowrap justify-content-between align-items-center betslip-footer-odds-change ${disableBetTicketInShop ? classes.mb15 : ''
          }`}
        onClick={handleAllowTicketPlaceWithInvalidEvents}
      >
        <div className={classes.switchLabel}>{t('Place automatically without unavailable events')}</div>
        <SimpleSwitch checked={allowTicketPlaceWithInvalidEvents} onChange={handleAllowTicketPlaceWithInvalidEvents} />
      </div>
      {!disableBetTicketInShop && (
        <div className={`${classes.footer} betslip-footer`}>
          {disableSwitch && <div className={classes.footerTextLink}>{t('Live tickets can only be played online.')}</div>}
          <TicketSwitch online={ticketOnline} isDisabled={disableSwitch} onChange={handleTicketLocation} />
        </div>
      )}
      {openPlaceBet && (
        <TicketModal
          openPlaceBet={openPlaceBet}
          handlePlaceBetClose={handlePlaceBetClose}
          currentTicket={JSON.parse(JSON.stringify(currentTicket))}
        />
      )}
      {ruleDialog.open && (
        <WinnerFunHelpDialog
          open={ruleDialog.open}
          onClose={handleRuleClose}
          eventName={ruleDialog.eventName}
          type={ruleDialog.type}
        />
      )}
    </div>
  );

  */
};

const mapStateToProps = (state: RootState, props: any) => {
  const bst = getBetsState(state);
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  return {
    isWinnerFun: bst.app.isWinnerFun,
    wallet: bst.app.isWinnerFun ? state.winnerFun.wallet : state.wallet,
    profile: state.profile,
    amount: ct.amount,
    tax: ct.tax,
    taxPercent: ct.taxPercent,
    winAmount: ct.totalMaxWinAmount,
    minWinAmount: ct.totalMinWinAmount,
    totalOdds: ct.totalOdds,
    autoAcceptOddChange: ct.autoAcceptOddChange,
    allowTicketPlaceWithInvalidEvents: ct.allowTicketPlaceWithInvalidEvents,
    placeTicketEnabled: ct.placeTicketEnabled,
    placeTicketErrorMessage: ct.placeTicketErrorMessage,
    errorMessage: ct.errorMessage,
    auth: state.authentication,
    ticketOnline: ct.ticketOnline,
    ticketType: ct.ticketType,
    liveBets: ct.live.selected,
    prematchBets: ct.prematch.selected,
    bonus: ct.bonus,
    walletBonusEvaluate: ct.bonusEvaluate,
    walletBonuses: getBetsBonuses(state),
    walletRingFences: getBetsRingFences(state),
    currentTicket: ct,
    winnerFunEvaluate: ct.winnerFunEvaluate,
    winnerFunBoostNum: bst.app.isWinnerFun ? getWinnerFunBoostNum(state) : 0,
    winnerFunBoostActive: ct.useWinnerFunBoost,

    freeBets: state.freeBets.freeBets,
    selectedFreeBet: bst.betsSlip.selectedFreeBet,
    selectedFreeBetSubIndex: bst.betsSlip.selectedFreeBetSubIndex,
    freeBetsEvaluate: ct.freeBetsEvaluate,
    dayMultiBetNumber: ct.dayMultiBetNumber,
    dayMultiBets: bst.config.dayMultiBets,
  };
};

const actionCreators = {
  clearBets: betsSlipClear,
  clearCreateStatus: betsSlipClearCreateStatus,
  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  toggleAutoAcceptOddChange: betsSlipToggleAutoAcceptOddChange,
  toggleAllowTicketPlaceWithInvalidEvents: betsSlipToggleAllowTicketPlaceWithInvalidEvents,
  liveCreateTicket: liveCreateTicketRequest,
  prematchCreateTicket: prematchCreateTicketRequest,
  betsSlipSetTicketOnline: betsSlipSetTicketOnline,
  doLogin: doLogin,
  requestBonusEvaluation: betsBonusRequestEvaluation,
  toggleWinnerFunBoost: betsSlipToggleWinnerFunBoost,
  ticketWinnerFunOpenedListRequest: ticketWinnerFunOpenedListRequest,
  betSlipSetFreeBetIndex: betSlipSetFreeBetIndex,
  betSlipFreeBetClear: betSlipFreeBetClear,
  betsSlipSetMultiBetDayNumber: betsSlipSetMultiBetDayNumber,
};

// @ts-ignore
export default withTranslation()(connect(mapStateToProps, actionCreators)(SoldCard));
