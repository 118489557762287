import * as actionTypes from './../actions/actionTypes';

const DEFAULT_CURRENCY = 'lei';

const initialState = {
  refCnt: 0,
  requestActive: false,
  currency: DEFAULT_CURRENCY,
  maxValue: 0,
  minValue: 0,
  levels: [],
  levelsValues: [],
  lastFetchTime: 0,
  latestWinners: [],
  latestWinnersLastFetchTime: 0,
  latestJackpotWinners: [],
  latestJackpotWinnersLastFetchTime: 0,
};

const parseJackpotHistoryData = (data) => {
  const currency = data['currency'] || DEFAULT_CURRENCY;

  const thresholdLevels = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
  const jdKeys = [
    'current',
    'wins',
    'largestWin',
    'largestWinDate',
    'largestWinUser',
    'lastWin',
    'lastWinDate',
    'lastWinUser',
    'topMonthlyWinners',
    'topYearlyWinners',
  ];

  let levels = [];
  let levelsValues = [];

  for (const tlevel of thresholdLevels) {
    // format key name as keyLevelX
    let kfn = (kn) => kn + 'Level' + tlevel;

    // get value for formatted key
    let kfv = (kn) => data[kfn(kn)];

    // if we don't have data for the current level, stop
    if (!(kfn(jdKeys[0]) in data)) {
      break;
    }

    // new jackpot level
    let level = {};

    // get level data from received history
    jdKeys.forEach((key) => {
      level[key] = kfv(key);
    });

    // store level
    levels.push(level);
  }

  // compute max value for all levels
  let maxValue = 0;
  let minValue = Number.MAX_SAFE_INTEGER;

  levels.forEach((level) => {
    if (level.current > maxValue) {
      maxValue = level.current;
    }
    if (level.current < minValue) {
      minValue = level.current;
    }
    levelsValues.push(level.current);
  });

  levelsValues.sort(function (a, b) {
    return b - a;
  });

  if (minValue === Number.MAX_SAFE_INTEGER) {
    minValue = 0;
  }

  return {
    maxValue,
    minValue,
    currency,
    levels,
    levelsValues,
  };
};

export const jackpot = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.jackpot.REQUEST_DETAILS:
      return {
        ...state,
        requestActive: true,
      };
    case actionTypes.jackpot.RECEIVED_DETAILS:
      const parsedData = parseJackpotHistoryData(action.data);
      return {
        ...state,
        requestActive: false,
        currency: parsedData.currency,
        maxValue: parsedData.maxValue,
        minValue: parsedData.minValue,
        levels: parsedData.levels,
        levelsValues: parsedData.levelsValues,
        lastFetchTime: new Date().getTime(),
      };
    case actionTypes.jackpot.REQUEST_LATEST_WINNERS:
      return state;
    case actionTypes.jackpot.UPDATE_LATEST_WINNERS_LAST_FETCH_TIME:
      return {
        ...state,
        latestWinnersLastFetchTime: action.time,
      };
    case actionTypes.jackpot.RECEIVED_LATEST_WINNERS:
      return {
        ...state,
        latestWinners: action.data,
        //latestWinnersLastFetchTime: new Date().getTime()
      };
    case actionTypes.jackpot.REQUEST_LATEST_JACKPOT_WINNERS:
      return state;
    case actionTypes.jackpot.UPDATE_LATEST_JACKPOT_WINNERS_LAST_FETCH_TIME:
      return {
        ...state,
        latestJackpotWinnersLastFetchTime: action.time,
      };
    case actionTypes.jackpot.RECEIVED_LATEST_JACKPOT_WINNERS:
      return {
        ...state,
        latestJackpotWinners: action.data,
        //latestJackpotWinnersLastFetchTime: new Date().getTime()
      };
    case actionTypes.jackpot.INC_REF_CNT:
      //console.log("inc ref cnt");
      return {
        ...state,
        refCnt: state.refCnt + 1,
      };
    case actionTypes.jackpot.DEC_REF_CNT:
      //console.log("dec ref cnt");
      return {
        ...state,
        refCnt: state.refCnt - 1,
      };
    default:
      return state;
  }
};

export default jackpot;
