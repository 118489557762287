export const filtersToLinks = (items: any[] | null, handleJoinPlayAndGame?: any) => {
  if (!items) return [];
  let links: any[] = [];

  links = items
    .filter((t) => typeof t.idBet === 'undefined')
    .slice(0, 20)
    .map((filter, i) => {
      if (!filter) return null;
      let id = '';

      if (typeof filter.idSport != 'undefined') id = filter.idSport;
      if (typeof filter.idCategory != 'undefined') id += '/' + filter.idCategory;
      if (typeof filter.idTournament != 'undefined') id += '/' + filter.idTournament;

      return {
        name: filter.name,
        url: `/bets/pre-match/${id}`,
      };
    });
  // console.log('debug links', links);
  return links;
};
