import * as actionTypes from './../actions/actionTypes';
import { produce } from 'immer';
import { fastEqual } from '../../../../utils/fastDeepEqual';

const initialState = {
  freeBets: [],
  freeBetsHistory: [],
  freeBetsHistoryHasMore: true,
  freeBetsFilters: {},
  loading: false,
  loadingHistory: false,
};

const freeBetsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.freeBets.FREE_BETS_LOADED: {
        if (!fastEqual(draft.freeBets, action.freeBets)) {
          // don't update if they're teh same
          draft.freeBets = action.freeBets;
        }
        draft.loading = false;
        break;
      }
      case actionTypes.freeBets.FREE_BETS_LOADING: {
        if (action.loadingType === 1) {
          draft.loading = true;
        } else {
          draft.loadingHistory = true;
        }
        break;
      }
      case actionTypes.freeBets.FREE_BETS_FILTERS_LOADED: {
        draft.freeBetsFilters = Object.assign(draft.freeBetsFilters, action.freeBetsFilters);
        break;
      }
      case actionTypes.freeBets.FREE_BETS_FILTERS_UPDATE: {
        draft.freeBetsFilters = Object.assign(draft.freeBetsFilters, action.freeBetsFilters);
        break;
      }
      case actionTypes.freeBets.FREE_BETS_SET_HISTORY: {
        if (action.page === 0) {
          draft.freeBetsHistory = action.items;
          draft.freeBetsHistoryHasMore = action.items.length > 0 ? true : [];
        } else {
          if (action.items.length) {
            draft.freeBetsHistory = [...draft.freeBetsHistory, ...action.items];
            draft.freeBetsHistoryHasMore = true;
          } else {
            draft.freeBetsHistoryHasMore = false;
          }
        }

        draft.loadingHistory = false;

        break;
      }
      default:
        break;
    }
  });

export default freeBetsReducer;
