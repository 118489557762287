import React from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../store';
import LinkedComponent from '../../components/linked-component';

const DataCarouselItemDiv = styled.div((props) => props.$styleText);

export const DataCarouselItem = (componentProps) => {
  const components = useAppSelector((state) => state.templatesConfig.components);

  let content = componentProps.children;

  if (componentProps.cmId) {
    if (components[componentProps.cmId] != null) {
      content = <LinkedComponent componentId={componentProps.cmId} />;
    } else {
      content = null;
    }
  }

  const classes = [];
  if (componentProps.className) classes.push(componentProps.className);
  if (componentProps.rClassName) classes.push(componentProps.rClassName);

  return (
    <DataCarouselItemDiv className={classes.join(' ')} $styleText={componentProps.styleText} style={componentProps.style}>
      {content}
    </DataCarouselItemDiv>
  );
};
