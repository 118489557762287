import { digitainConfig } from '@/modules/bets/api/config/digitain';
import DefaultCrest from '@/modules/bets/assets/default-crest.svg';
import moment from 'moment';

const crest = (teamLogoNo: number) => {
  if (teamLogoNo) {
    return `${digitainConfig().apiURL}/team-logo2/${teamLogoNo}`;
  }

  return DefaultCrest;
};

const toPercentage = (value: number | null, total: number) => {
  if (total == null) return 0;
  const tmp = value ?? 0;
  return (tmp / total) * 100;
};

export const statsLabels: any = {
  matches: { 2: 'Matches played', 42: 'Meciuri jucate' },
  clean_sheets: { 2: 'Clean Sheets', 42: 'Clean sheets' },
  goals_scored: { 2: 'Goals Scored', 42: 'Goluri marcate' },
  goals_conceded: { 2: 'Goals Conceded', 42: 'Goluri primite' },
  corners: { 2: 'Corners', 42: 'Cornere' },
  offside: 'Offside',
  possession: { 2: 'Possession (%)', 42: 'Posesie (%)' },
  xg: 'Expected Goals (xG)',
  yc: { 2: 'Yellow Cards', 42: 'Cartonaşe galbene' },
  rc: { 2: 'Red Cards', 42: 'Cartonaşe roşii' },
  penalty: 'Penalty',
  aerial_won: { 2: 'Aerial Won', 42: 'Dueluri aeriene câştigate' },
  blocked_shots: { 2: 'Blocked Shots', 42: 'Şuturi blocate' },
  ball_possession_pct: { 2: 'Ball Possession (%)', 42: 'Posesie (%)' },
  cautions: { 2: 'Cautions', 42: 'Avertismente' },
  clearances: { 2: 'Clearances', 42: 'Degajări' },
  completed_passes: { 2: 'Completed Passes', 42: 'Pase reuşite' },
  corner_kicks: { 2: 'Corner Kicks', 42: 'Cornere' },
  expulsions: { 2: 'Expulsions', 42: 'Eliminări' },
  fouls: { 2: 'Fouls', 42: 'Faulturi' },
  free_kicks: { 2: 'Free Kicks', 42: 'Lovituri libere' },
  hit_woodwork: { 2: 'Hit Woodwork', 42: 'Bară' },
  interceptions: { 2: 'Interceptions', 42: 'Interceptări' },
  offsides: 'Offsides',
  saves: { 2: 'Saves', 42: 'Parade' },
  shots: { 2: 'Shots', 42: 'Şuturi' },
  shots_inside_box: { 2: 'Shots Inside Box', 42: 'Şuturi din careu' },
  shots_on_goal: { 2: 'Shots on Goal', 42: 'Şuturi pe poartă' },
  shots_outside_box: { 2: 'Shots Outside Box', 42: 'Şuturi de la distanţă' },
  throw_ins: { 2: 'Throw Ins', 42: 'Aruncări de la margine' },
  total_passes: { 2: 'Total Passes', 42: 'Total pase' },
  tackles: { 2: 'Tackles', 42: 'Tacklinguri' },
};

export const statsAbbrev: any = {
  matches: { '2': 'MP', '42': 'MJ' },
  clean_sheets: { '2': 'CS', '42': 'CS' },
  goals_scored: { '2': 'GS', '42': 'GM' },
  goals_conceded: { '2': 'GC', '42': 'GP' },
  corners: { '2': 'C', '42': 'C' },
  offside: { '2': 'OFF', '42': 'OFF' },
  possession: { '2': 'P (%)', '42': 'P (%)' },
  xg: 'xG',
  yc: { '2': 'YC', '42': 'CG' },
  rc: { '2': 'RC', '42': 'CR' },
  penalty: { '2': 'PEN', '42': 'PEN' },
  aerial_won: { '2': 'AW', '42': 'DAC' },
  blocked_shots: { '2': 'BS', '42': 'SB' },
  ball_possession_pct: { '2': 'P (%)', '42': 'P (%)' },
  cautions: { '2': 'CA', '42': 'A' },
  clearances: { '2': 'CL', '42': 'D' },
  completed_passes: { '2': 'CP', '42': 'PC' },
  corner_kicks: { '2': 'C', '42': 'C' },
  expulsions: { '2': 'RC', '42': 'CR' },
  fouls: 'F',
  free_kicks: { '2': 'FK', '42': 'LL' },
  hit_woodwork: { '2': 'WK', '42': 'BR' },
  interceptions: 'I',
  offsides: 'OFF',
  saves: { '2': 'S', '42': 'PAR' },
  shots: 'S',
  shots_inside_box: { '2': 'SIB', '42': 'SC' },
  shots_on_goal: { '2': 'SG', '42': 'SP' },
  shots_outside_box: { '2': 'SOB', '42': 'SD' },
  throw_ins: { '2': 'TH', '42': 'AM' },
  total_passes: { '2': 'TP', '42': 'TP' },
  tackles: { '2': 'TCK', '42': 'TCK' },
};

export const processFootballStatsData = (data: any) => {
  const panels: any = {};

  if (!data?.data?.data) return [];

  const { data: stats } = data.data;

  if (!stats) return [];

  const team1Id = stats.summary?.team1_id ?? '';
  const team2Id = stats.summary?.team2_id ?? '';
  const team1Name = stats.summary?.team1_name ?? 'Team 1';
  const team2Name = stats.summary?.team2_name ?? 'Team 2';
  const team1Logo = crest(stats.summary?.team1_logo);
  const team2Logo = crest(stats.summary?.team2_logo);

  const scores: any = {
    final: [0, 0],
  };

  if (stats.summary?.status_result) {
    const sr = stats.summary.status_result;

    if (sr.result1 != null && sr.result1 != null) {
      scores.final = [sr.result1, sr.result2];
    }

    if (sr.resultH1 != null && sr.resultH2 != null) {
      scores.HT = [sr.resultH1, sr.resultH2];
    }
    if (sr.resultF1 != null && sr.resultF2 != null) {
      scores.FT = [sr.resultF1, sr.resultF2];
    }
    if (sr.resultE1 != null && sr.resultE2 != null) {
      scores.ET = [sr.resultE1, sr.resultE2];
    }
    if (sr.resultP1 != null && sr.resultP2 != null) {
      scores.PT = [sr.resultP1, sr.resultP2];
    }
  }

  if (stats.summary) {
    let team1Pos = 0;
    let team2Pos = 0;
    if (stats.standings?.overall?.length) {
      team1Pos = stats.standings?.overall.findIndex((item: any) => item.team_id === stats.summary?.team1_id) + 1;
      team2Pos = stats.standings?.overall.findIndex((item: any) => item.team_id === stats.summary?.team2_id) + 1;
    }

    panels.form = {
      layout: 'form',
      form: {
        team1: {
          position: team1Pos,
          name: team1Name,
          image: team1Logo,
          gameConclusions: (stats.history_stats?.team1_last_matches ?? []).map((item: any) => {
            const ret = {
              status: 'unknown',
            };
            switch (item) {
              case 'w':
                ret.status = 'win';
                break;
              case 'l':
                ret.status = 'loss';
                break;
              case 'd':
                ret.status = 'draw';
                break;
            }
            return ret;
          }),
        },
        team2: {
          position: team2Pos,
          name: team2Name,
          image: team2Logo,
          gameConclusions: (stats.history_stats?.team2_last_matches ?? []).map((item: any) => {
            const ret = {
              status: 'unknown',
            };
            switch (item) {
              case 'w':
                ret.status = 'win';
                break;
              case 'l':
                ret.status = 'loss';
                break;
              case 'd':
                ret.status = 'draw';
                break;
            }
            return ret;
          }),
        },
      },
    };
  }

  if (stats.summary?.events?.length) {
    const half: any = [];

    let score1Current = 0;
    let score2Current = 0;

    stats.summary.events.forEach((event: any) => {
      let tmp: any = null;

      switch (event.type) {
        case 'goal':
          tmp = {
            type: 'goal',
            team: event.club === '1' ? 'first' : 'second',
            player1: event.player,
            time: event.minute,
          };

          if (event.assist) {
            tmp.player2 = event.assist;
          }

          if (event.club === '1') {
            score1Current += 1;
          } else {
            score2Current += 1;
          }

          tmp.value = `${score1Current} - ${score2Current}`;

          half.push(tmp);
          break;
        case 'og':
          tmp = {
            type: 'auto-goal',
            team: event.club === '1' ? 'first' : 'second',
            player1: event.player,
            time: event.minute,
          };

          if (event.club === '1') {
            score1Current += 1;
          } else {
            score2Current += 1;
          }

          tmp.value = `${score1Current} - ${score2Current}`;

          half.push(tmp);
          break;
        case 'pen':
          tmp = {
            type: 'goal',
            team: event.club === '1' ? 'first' : 'second',
            player1: event.player,
            time: event.minute,
          };

          if (event.club === '1') {
            score1Current += 1;
          } else {
            score2Current += 1;
          }

          tmp.value = `${score1Current} - ${score2Current}`;

          half.push(tmp);
          break;
        case 'var-disable': // TODO: add UI for var disallowed goals
          tmp = {
            type: 'var',
            team: event.club === '1' ? 'first' : 'second',
            player1: event.player,
            time: event.minute,
          };

          half.push(tmp);
          break;
        case 'yc':
          tmp = {
            type: 'yellow-card',
            team: event.club === '1' ? 'first' : 'second',
            player: event.player,
            time: event.minute,
          };

          half.push(tmp);
          break;
        case 'rc':
          tmp = {
            type: 'red-card',
            team: event.club === '1' ? 'first' : 'second',
            player: event.player,
            time: event.minute,
          };

          half.push(tmp);
          break;
        case '2yc':
          tmp = {
            type: 'yellow-to-red',
            team: event.club === '1' ? 'first' : 'second',
            player: event.player,
            time: event.minute,
          };

          half.push(tmp);
          break;
        default:
          break;
      }
    });

    if (stats.players.substitution?.length) {
      stats.players.substitution.forEach((event: any) => {
        const tmp = {
          type: 'substitution',
          team: event.club === '1' ? 'first' : 'second',
          player1: event.player_in,
          player2: event.player_out,
          time: event.minute,
        };

        half.push(tmp);
      });
    }

    half.sort((a: any, b: any) => {
      if (a?.time?.startsWith?.('90+') && b?.time?.startsWith?.('90+')) {
        const [aTime, aExtra] = a?.time?.split?.('+') ?? [a?.time, 0];
        const aVal = parseInt(aTime, 10) + parseInt(aExtra, 10);

        const [bTime, bExtra] = b?.time?.split?.('+') ?? [b?.time, 0];
        const bVal = parseInt(bTime, 10) + parseInt(bExtra, 10);

        return aVal - bVal;
      } else if (a?.time?.startsWith?.('90+')) {
        const [aTime, aExtra] = a?.time?.split?.('+') ?? [a?.time, 0];
        const aVal = parseInt(aTime, 10) + parseInt(aExtra, 10);
        const bVal = parseInt(b?.time, 10);

        return aVal - bVal;
      } else if (b?.time?.startsWith?.('90+')) {
        const aVal = parseInt(a?.time, 10);
        const [bTime, bExtra] = b?.time?.split?.('+') ?? [b?.time, 0];
        const bVal = parseInt(bTime, 10) + parseInt(bExtra, 10);

        return aVal - bVal;
      }

      const aVal = parseInt(a?.time, 10);
      const bVal = parseInt(b?.time, 10);
      return aVal - bVal;
    });

    half.forEach((event: any) => {
      if (event.time.includes('+')) {
        const [time, extra] = event.time.split('+');
        event.time = `${time}' +${extra}`;
      } else {
        event.time = `${event.time}'`;
      }
    });

    if (half.length) {
      panels.half = {
        layout: 'half',
        half: {
          label: 'Events',
          value: '',
          team1: team1Name,
          team2: team2Name,
          data: half,
        },
      };
    }
  }

  if (stats.summary) {
    const matchInfo = [];

    if (stats.summary?.referee?.name) {
      const name = stats.summary.referee.name;
      if (name !== ' ' && name !== '') {
        // name !== '-' &&
        matchInfo.push({
          type: 'referee',
          value: name,
          //image: 'https://micros-t.b-cdn.net/de-1715937321368.svg',
        });
      }
    }

    if (stats.summary?.stadium?.name) {
      const name = stats.summary.stadium.name;
      if (name !== ' ' && name !== '') {
        // name !== '-' &&
        matchInfo.push({
          type: 'venue',
          value: name,
        });
      }
    }

    if (matchInfo.length) {
      panels.matchInfo = {
        layout: 'matchInfo',
        matchInfo: {
          data: matchInfo,
        },
      };
    }
  }

  if (stats.history_stats) {
    const data: any = [];
    Object.keys(stats.history_stats?.team1_avgs).forEach((key) => {
      const team1Value = stats.history_stats?.team1_avgs[key];
      const team2Value = stats.history_stats?.team2_avgs[key];

      if (!team1Value && !team2Value) return;

      const totalValue = (team1Value ?? 0) + (team2Value ?? 0);

      data.push({
        label: statsLabels[key] ?? key,
        team1: {
          value: team1Value,
          percent: toPercentage(team1Value, totalValue),
          image: team1Logo,
        },
        team2: {
          value: team2Value,
          percent: toPercentage(team2Value, totalValue),
          image: team2Logo,
        },
      });
    });

    if (data.length) {
      panels.historyStats = {
        layout: 'historyStats',
        historyStats: {
          total: data.length,
          data: data,
        },
      };
    }
  }

  if (stats.stats) {
    const data: any = [];
    Object.keys(stats.stats).forEach((key) => {
      const team1Value = stats.stats[key].home ? parseFloat(stats.stats[key].home) : null;
      const team2Value = stats.stats[key].away ? parseFloat(stats.stats[key].away) : null;
      const totalValue = (team1Value ?? 0) + (team2Value ?? 0);

      data.push({
        label: statsLabels[key] ?? key,
        team1: {
          value: team1Value,
          percent: toPercentage(team1Value, totalValue),
        },
        team2: {
          value: team2Value,
          percent: toPercentage(team2Value, totalValue),
        },
      });
    });

    if (data.length) {
      panels.liveStats = {
        layout: 'liveStats',
        liveStats: {
          simple: true,
          total: data.length,
          data: data,
        },
      };
    }
  }

  if (stats.players?.lineup?.length && stats.players?.formation?.home && stats.players?.formation?.away) {
    let home = stats.players.formation.home.split('-');
    home.unshift(1);
    home = home.map((item: string) => parseInt(item, 10));

    let away = stats.players.formation.away.split('-');
    away.unshift(1);
    away = away.map((item: string) => parseInt(item, 10));

    const formation1: any = [];
    const formation2: any = [];

    const homeLineup = stats.players.lineup.filter((item: any) => item.club === '1');
    const awayLineup = stats.players.lineup.filter((item: any) => item.club === '2');

    let start = 0;
    home.forEach((item: any, index: number) => {
      let players = homeLineup.slice(start, start + item);
      players = players.map((item: any) => ({
        name: item.player,
        playerNumber: item.no,
        image: 'https://micros-t.b-cdn.net/genericPlayer-1715935546249.svg',
      }));

      formation1.push({
        row: players.reverse(),
      });
      start += item;
    });
    start = 0;
    away.forEach((item: any, index: number) => {
      let players = awayLineup.slice(start, start + item);
      players = players.map((item: any) => ({
        name: item.player,
        playerNumber: item.no,
        image: 'https://micros-t.b-cdn.net/genericPlayer-1715935546249.svg',
      }));

      formation2.push({
        row: players,
      });
      start += item;
    });

    panels.lineupsFormations = {
      layout: 'lineupsFormations',
      lineupsFormations: {
        formation1,
        formation2: formation2.reverse(),
      },
    };
  }

  if (stats.players?.substitution?.length) {
    const team1: any[] = [];
    const team2: any[] = [];

    stats.players.substitution.forEach((sub: any) => {
      const tmp = {
        time: sub.minute,
        playerInImage: 'https://micros-t.b-cdn.net/genericPlayer-1715935546249.svg',
        playerInRating: null,
        playerIn: sub.player_in,
        playerOut: sub.player_out,
      };

      if (sub.club === '1') {
        team1.push(tmp);
      } else {
        team2.push(tmp);
      }
    });

    if (team1.length || team2.length) {
      panels.substitutedPlayers = {
        layout: 'substitutedPlayers',
        substitutedPlayers: {
          team1: {
            team: team1Name,
            total: team1.length,
            data: team1,
          },
          team2: {
            team: team2Name,
            total: team2.length,
            data: team2,
          },
        },
      };
    }
  }

  if (stats.players?.bench?.length) {
    const team1: any[] = [];
    const team2: any[] = [];

    const replacedPlayers: any = {};

    stats.players?.substitution?.forEach?.((sub: any) => {
      replacedPlayers[sub.playerID_in] = true;
    });

    stats.players.bench.forEach((sub: any) => {
      const tmp = {
        time: sub.minute,
        player: sub.player,
        playerNumber: sub.no,
        replaced: replacedPlayers[sub.playerID],
      };

      if (sub.club === '1') {
        team1.push(tmp);
      } else {
        team2.push(tmp);
      }
    });

    if (team1.length || team2.length) {
      panels.reservePlayers = {
        layout: 'reservePlayers',
        reservePlayers: {
          team1: {
            team: team1Name,
            total: team1.length,
            data: team1,
          },
          team2: {
            team: team2Name,
            total: team2.length,
            data: team2,
          },
        },
      };
    }
  }

  if (stats.players?.coach?.home || stats.players?.coach?.away) {
    const team1 = [];
    const team2 = [];

    if (stats.players.coach.home) {
      team1.push({
        coach: stats.players.coach.home,
        image: team1Logo,
      });
    }

    if (stats.players.coach.away) {
      team2.push({
        coach: stats.players.coach.away,
        image: team2Logo,
      });
    }

    if (team1.length || team2.length) {
      panels.coaches = {
        layout: 'coaches',
        coaches: {
          team1: {
            team: team1Name,
            data: team1,
          },
          team2: {
            team: team2Name,
            data: team2,
          },
        },
      };
    }
  }

  if (stats.standings) {
    const overall: any[] = [];
    const home: any[] = [];
    const away: any[] = [];

    if (stats.standings?.overall) {
      stats.standings.overall.forEach?.((team: any, index: number) => {
        overall.push({
          image: crest(team.logo),
          team: team.name,
          note: team.n ?? '',
          position: index + 1,
          matches: team.p ?? 0, // MP - Matches Played; MJ - Meciuri Jucate
          wins: team.w ?? 0, // W - Wins
          draws: team.d ?? 0, // D - Draws
          losses: team.l ?? 0, // L - Losses
          goalsFor: team.gf ?? 0, // GF - Goals For
          goalsAgainst: team.ga ?? 0, // GA - Goals Against
          //goalsDifference: 2, // GD - Goals Difference
          points: team.pnt ?? 0, // P - Points
        });
      });
    }
    if (stats.standings?.home) {
      stats.standings.home.forEach?.((team: any, index: number) => {
        home.push({
          image: crest(team.logo),
          team: team.name,
          note: team.n ?? '',
          position: index + 1,
          matches: team.p ?? 0, // MP - Matches Played; MJ - Meciuri Jucate
          wins: team.w ?? 0, // W - Wins
          draws: team.d ?? 0, // D - Draws
          losses: team.l ?? 0, // L - Losses
          goalsFor: team.gf ?? 0, // GF - Goals For
          goalsAgainst: team.ga ?? 0, // GA - Goals Against
          //goalsDifference: 2, // GD - Goals Difference
          points: team.pnt ?? 0, // P - Points
        });
      });
    }
    if (stats.standings?.away) {
      stats.standings.away.forEach?.((team: any, index: number) => {
        away.push({
          image: crest(team.logo),
          team: team.name,
          note: team.n ?? '',
          position: index + 1,
          matches: team.p ?? 0, // MP - Matches Played; MJ - Meciuri Jucate
          wins: team.w ?? 0, // W - Wins
          draws: team.d ?? 0, // D - Draws
          losses: team.l ?? 0, // L - Losses
          goalsFor: team.gf ?? 0, // GF - Goals For
          goalsAgainst: team.ga ?? 0, // GA - Goals Against
          //goalsDifference: 2, // GD - Goals Difference
          points: team.pnt ?? 0, // P - Points
        });
      });
    }

    if (overall.length) {
      panels.standingsOverall = {
        layout: 'teamsStats',
        teamsStats: {
          data: overall,
        },
      };
    }
    if (home.length) {
      panels.standingsHome = {
        layout: 'teamsStats',
        teamsStats: {
          data: home,
        },
      };
    }
    if (away.length) {
      panels.standingsAway = {
        layout: 'teamsStats',
        teamsStats: {
          data: away,
        },
      };
    }
  }

  if (stats?.h2h) {
    const final: any = {};
    const bases = ['overall', 'home', 'away'];

    const status: any = {
      w: 'win',
      l: 'loss',
      d: 'draw',
    };

    const processItem = (item: any, key: any, teams: any) => {
      const date = new Date(item.event_time);

      // generate cod to extract DD.MM.YYYY
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const currentYear = new Date().getFullYear();

      if (!teams[key]) teams[key] = [];

      teams[key].push({
        dateTime: `${day}.${month}`,
        year: year === currentYear ? '' : year,
        teams: [
          {
            name: item.team1_name,
            image: crest(item.team1_logo),
          },
          {
            name: item.team2_name,
            image: crest(item.team2_logo),
          },
        ],
        scores: [{ score: [item.team1_score ?? 0, item.team2_score ?? 0] }],
        status: status[item.event_result] ?? 'unknown',
      });
    };

    bases.forEach((base) => {
      const teams: any = {};

      if (base === 'overall') {
        const keys = ['direct', 'team1_last_matches', 'team2_last_matches'];
        keys.forEach((key) => {
          stats.h2h?.[base]?.[key]?.forEach?.((item: any) => {
            processItem(item, key, teams);
          });
        });
        final[base] = {};

        if (teams.direct) {
          final[base].direct = {
            layout: 'lastMatches',
            lastMatches: {
              directMatches: true,
              total: teams.direct?.length ?? 0,
              data: teams.direct ?? [],
            },
          };
        }

        if (teams.team1_last_matches) {
          final[base].h2hTeam1 = {
            layout: 'lastMatches',
            lastMatches: {
              team: team1Name,
              total: teams.team1_last_matches?.length ?? 0,
              data: teams.team1_last_matches ?? [],
            },
          };
        }

        if (teams.team2_last_matches) {
          final[base].h2hTeam2 = {
            layout: 'lastMatches',
            lastMatches: {
              team: team2Name,
              total: teams.team2_last_matches?.length ?? 0,
              data: teams.team2_last_matches ?? [],
            },
          };
        }
      } else {
        stats.h2h?.[base]?.forEach?.((item: any) => {
          processItem(item, base === 'home' ? 'team1_last_matches' : 'team2_last_matches', teams);
        });

        if (teams?.[base === 'home' ? 'team1_last_matches' : 'team2_last_matches']) {
          final[base] = {
            layout: 'lastMatches',
            lastMatches: {
              team: base === 'home' ? team1Name : team2Name,
              total: teams?.[base === 'home' ? 'team1_last_matches' : 'team2_last_matches']?.length ?? 0,
              data: teams?.[base === 'home' ? 'team1_last_matches' : 'team2_last_matches'] ?? [],
            },
          };
        }
      }
    });

    final.team1 = team1Name;
    final.team2 = team2Name;

    panels.h2h = final;
  }

  panels.teams = {
    team1: {
      name: team1Name,
      image: team1Logo,
    },
    team2: {
      name: team2Name,
      image: team2Logo,
    },
    scores: scores,
    eventTime: moment(stats.summary?.event_time).format('DD.MM.YYYY - HH:mm'),
  };

  //console.log('BetsStats[processStatsData:panels]', panels);

  return panels;
};
