interface BonusType {
  [key: string]: number;
}

interface BonusTypeName {
  [key: number]: string;
}

interface BonusConflict {
  [key: string]: number;
}

interface BonusConflictReason {
  [key: number]: string;
}

export const BonusConflict: BonusConflict = {
  unknown: 0,
  bonus_active: 6,
  email_verification: 19,
  identity_verification: 20,
};

export const BonusType: BonusType = {
  freeBetLotto: 5,
  freeBetSport: 9,
  freeSpin: 8,
  scalar: 1,
  percentage: 2,
};

export const BonusTypeName: BonusTypeName = {
  [BonusType.freeBetLotto]: 'Free Bet Lotto',
  [BonusType.freeBetSport]: 'Free Bet Sport',
  [BonusType.freeSpin]: 'Free Spin',
  [BonusType.scalar]: 'Scalar',
  [BonusType.percentage]: 'Percentage',
};

export const BonusConflictReason: BonusConflictReason = {
  [BonusConflict.bonus_active]: 'Wager the active bonuses first',
  [BonusConflict.email_verification]: 'Requires email verification',
  [BonusConflict.identity_verification]: 'Requires identity verification',
  [BonusConflict.unknown]: 'Temporary unavailable',
};
