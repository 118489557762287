import store from '../../store';
import { logout } from '../../modules/casino/store/actions/login';
import evBusMain from '../../utils/evbus';
import { requestRemoveBonus } from '../../modules/casino/store/actions/wallet';
import { freeSpinsRemove } from '../../modules/casino/store/actions/free_spins';
import { freeBetRemove } from '../../modules/casino/store/actions/free_bets';
import { claimPrizeById, loadPrizes } from '../../modules/tournaments-missions/store/actions/history';
import { buyShopItem } from '../../store/slices/shopItem';
import { betsSlipRemove } from '../../modules/bets/store/actions/betsSlip';
import { claimBonus } from '@/modules/casino/store/actions/application';
import { storedRouter } from '@/routes';

interface NavigateFunction {
  (
    to: any,
    options?: {
      replace?: boolean;
      state?: any;
      relative?: any;
    },
  ): void;
  (delta: number): void;
}

const stringToSlug = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

export const exitSlotGame = (navigate: NavigateFunction) => {
  const routerHistory = store.getState().application.routerHistory;
  if (routerHistory) {
    if (routerHistory.pages > 1 && routerHistory.pagesHistory?.length > 0) {
      let gotoPage = '';

      routerHistory.pagesHistory.find((page: string) => {
        if (
          page.indexOf('/slot-game/') === -1 &&
          page.indexOf('/deposit') === -1 &&
          page.indexOf('/login') === -1 &&
          page.indexOf('/payment/') === -1
        ) {
          gotoPage = page;
          return true;
        }
        return false;
      });
      if (gotoPage) {
        evBusMain.emit('route-change', { url: gotoPage });
        /*
        if (storedRouter?.navigate) {
          storedRouter.navigate(gotoPage);
          return;
        }
        navigate(gotoPage);
        */
        return;
      }
    }
  }
  if (storedRouter?.navigate) {
    storedRouter.navigate('/casino');
    return;
  }
  navigate('/casino');
};
export const exitLiveGame = (navigate: NavigateFunction) => {
  const routerHistory = store.getState().application.routerHistory;
  if (routerHistory) {
    if (routerHistory.pages > 1 && routerHistory.pagesHistory?.length > 0) {
      let gotoPage = '';

      routerHistory.pagesHistory.find?.((page: string) => {
        if (
          page.indexOf('/live-game/') === -1 &&
          page.indexOf('/deposit') === -1 &&
          page.indexOf('/login') === -1 &&
          page.indexOf('/payment/') === -1
        ) {
          gotoPage = page;
          return true;
        }
        return false;
      });

      if (gotoPage) {
        evBusMain.emit('route-change', { url: gotoPage });
        /*
        if (storedRouter?.navigate) {
          //storedRouter.navigate(gotoPage);
          evBusMain.emit('route-change', { url: gotoPage });
          return;
        }
        navigate(gotoPage);
        */
        return;
      }
    }
  }
  if (storedRouter?.navigate) {
    storedRouter.navigate('/live-casino');
    return;
  }
  navigate('/live-casino');
};

export const handleActions = (action: string, props: any, navigate: NavigateFunction, context: any, e: any) => {
  switch (action) {
    case 'logout':
      e.preventDefault();
      store.dispatch(logout());
      break;
    case 'delete-match-bet': {
      e.preventDefault();
      const mType = props.properties['data-mtype'];
      const idMbo = props.properties['data-idmbo'];

      if (mType && idMbo) {
        store.dispatch(betsSlipRemove(mType, idMbo));
      }
      break;
    }
    case 'show-match-details': {
      const mType = props.properties['data-mtype'];
      const idSport = props.properties['data-idsport'];
      const idCategory = props.properties['data-idcategory'];
      const idTournament = props.properties['data-idtournament'];
      const idMatch = props.properties['data-idmatch'];
      const notInPage = props.properties['data-notinpage'];
      if (mType && idSport && idCategory && idTournament && idMatch && notInPage !== true) {
        const w = document.body.clientWidth;

        if (w >= 1800) {
          evBusMain.emit('SHOW_MATCH_DETAILS', {
            mType,
            idSport,
            idCategory,
            idTournament,
            idMatch,
          });
          e.preventDefault();
        }
      }
      break;
    }
    case 'launch-slot-game': {
      e.preventDefault();
      const gameId = props.properties['data-id'];
      const gameName = props.properties['data-name'];
      if (gameId) {
        navigate(`/slot-game/${gameId}/${stringToSlug(gameName)}`);
      }
      break;
    }
    case 'launch-slot-game-info': {
      const href = props.properties.href;
      const [gameId, slug] = href.split('/').slice(-2);

      if (gameId) {
        e.preventDefault();
        evBusMain.emit('launch-slot-game-info', { id: gameId, slug: slug });
      }
      break;
    }
    case 'exit-slot-game':
      e.preventDefault();
      exitSlotGame(navigate);
      break;
    case 'exit-live-game':
      e.preventDefault();
      exitLiveGame(navigate);
      break;

    case 'page_go_back_action':
      e.preventDefault();
      navigate(-1);
      break;

    case 'activeBonuses.deleteBonus':
      e.preventDefault();
      const bonusId = props.properties['data-id'];
      if (bonusId) {
        store.dispatch(requestRemoveBonus(bonusId));
      }

      break;

    case 'activeBonuses.deleteFreebet':
      e.preventDefault();
      const freeBetId = props.properties['data-id'];

      if (freeBetId) {
        store.dispatch(freeBetRemove(freeBetId));
      }
      break;

    case 'shopItem.buy':
      e.preventDefault();
      const shopItemId = props.properties['data-id'];
      const shopItemName = props.properties['data-name'] ?? '';
      if (shopItemId) {
        store.dispatch(buyShopItem({ id: shopItemId, name: shopItemName }));
        // console.log('DISPATCH shopItem.buy', { action, props, context, shopItemId, shopItemName });
      }
      break;

    case 'pendingBonuses.activateBonus':
      e.preventDefault();
      const avBonusId: string = props.properties['data-id'];
      if (avBonusId) {
        store.dispatch(claimBonus(avBonusId, true));

        // console.log('DEBUG DISPATCH activate bonus', { action, props, context, avBonusId });
      }
      break;

    case 'pendingBonuses.activatePrize':
      e.preventDefault();
      const prizeId: string = props.properties['data-id'];
      if (prizeId) {
        store.dispatch(
          claimPrizeById({
            id: prizeId,
            cb: (success: boolean) => {
              if (success) {
                store.dispatch(loadPrizes(null));
              }

              /* */
            },
          }),
        );
        // console.log('TODO DEBUG DISPATCH activate prize', { action, props, context, prizeId });
      }
      break;

    // TODO : other cases
    default:
      console.log('handleActions', { action, props, context });
    /* */
  }
};
