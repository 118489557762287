import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash-es';

import { useAppDispatch, useAppSelector } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { getMatchesData } from './utils/hooks';
import { liveScore, formatDate } from '../bets/utils/functions';
import { canCashout, buildOutcomes } from '../bets/match-card';
import crests from '@/modules/bets/utils/crests';

//import './index.scss';

type BetsLeagueMatchesProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const extractFromIdUrl = (props: any) => {
  let mType = props.properties.mType;
  let idSport = props.properties.idSport;
  let idCategory = props.properties.idCategory;
  let idTournament = props.properties.idTournament;
  let idMatch = props.properties.idMatch;

  const idUrl = props.properties.idUrl ?? '';

  if (idUrl) {
    const idUrlParts = idUrl.split('/');

    if (idUrlParts[0] === '') {
      idUrlParts.shift();
    }

    if (idUrlParts.length > 0) {
      mType = idUrlParts[0];
      if (mType === 'live-match') {
        mType = 'live';
      } else if (mType === 'pre-match') {
        mType = 'prematch';
      }
    }
    if (idUrlParts.length > 1) {
      idSport = idUrlParts[1];
    }
    if (idUrlParts.length > 2) {
      idCategory = idUrlParts[2];
    }
    if (idUrlParts.length > 3) {
      idTournament = idUrlParts[3];
    }
    if (idUrlParts.length > 4) {
      idMatch = idUrlParts[4];
    }
  }

  return [mType, idSport, idCategory, idTournament, idMatch];
};

const BetsLeagueMatches = (componentProps: BetsLeagueMatchesProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const { i18n } = useTranslation();

  let mType = props.properties?.mType ?? '';
  let idSport = props.properties?.idSport ?? '';
  let idCategory = props.properties?.idCategory ?? '';
  let idTournament = props.properties?.idTournament ?? '';
  let idMatch = props.properties?.idMatch ?? '';

  [mType, idSport, idCategory, idTournament, idMatch] = extractFromIdUrl(props);

  const selectedBets = useAppSelector<any>(
    (state: any) => state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket][mType].selected,
  );

  const { matches } = getMatchesData({
    mType: mType ?? 'prematch',
    idSport,
    idCategory,
    idTournament,
    idMatch,
  });

  const contextValue = React.useMemo(() => {
    const list: any = [];

    matches.find((origMatch: any, index: number) => {
      if (index > 5) return true;
      const match = cloneDeep(origMatch);

      match.canCashout = canCashout(match);

      match.matchDateTimeString = formatDate(match.matchDateTime, i18n.language, false);
      if (match.mType === 'live') match.scoreInfo = liveScore(match, match.currentStatus);

      match.outcomes = buildOutcomes(match, selectedBets, i18n.language, '');

      match.team1LogoUrl = crests(match.team1Name, match, 1);
      match.team2LogoUrl = crests(match.team2Name, match, 2);

      list.push(match);
      return false;
    });

    return { list: list };
  }, [matches, selectedBets]);

  //console.log('BetsLeagueMatches[contextValue]', contextValue);

  if (!mType && !idSport && (!idCategory || !idTournament || !idMatch)) {
    return null;
  }

  if (!contextValue.list || !contextValue.list.length) {
    return null;
  }

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetsLeagueMatches;
