import { cashoutConstants } from './constants';

export const cashoutUpdates = (message) => ({
  type: cashoutConstants.UPDATES,
  message,
});

export const cashoutMultipleUpdates = (messages) => ({
  type: cashoutConstants.MULTIPLE_UPDATES,
  messages,
});

export const cashoutSubscribe = (data) => ({
  type: cashoutConstants.SUBSCRIBE,
  data,
});

export const cashoutUnsubscribe = (data) => ({
  type: cashoutConstants.UNSUBSCRIBE,
  data,
});

export const cashoutStartProcess = () => ({
  type: cashoutConstants.START_PROCESS,
});

export const cashoutStopProcess = () => ({
  type: cashoutConstants.STOP_PROCESS,
});

export const cashoutTicket = ({ provider, ticket, amount, isWinnerFun, ticketType, agreeChanges }) => ({
  type: cashoutConstants.CASHOUT,
  provider,
  ticket,
  amount,
  isWinnerFun,
  ticketType,
  agreeChanges,
});

export const cashoutUpdateProgress = (progress) => ({
  type: cashoutConstants.CASHOUT_UPDATE_PROGRESS,
  progress
});

export const cashoutTicketResult = (result) => ({
  type: cashoutConstants.CASHOUT_RESULT,
  result,
});

export const cashoutClearState = (progress) => ({
  type: cashoutConstants.CASHOUT_CLEAR_STATE,
  progress
});

export const cashoutClearTickets = () => ({
  type: cashoutConstants.CASHOUT_CLEAR_TICKETS
});

export const cashoutRefetchOffer = (ticket) => ({
  type: cashoutConstants.REFETCH_CASHOUT_OFFER,
  ticket
});