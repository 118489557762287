import React from 'react';
import styled from 'styled-components';

const StateListItemsDiv = styled.div((props) => props.$styleText);

export const StateListItems = (componentProps) => {
  return (
    <StateListItemsDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText} style={componentProps.style}>
      {componentProps.children}
    </StateListItemsDiv>
  );
};
