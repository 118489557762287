export const ticketConstants = {
  SETTLED_LIST_REQUEST: 'VIRTUALS_TICKET_SETTLED_LIST_REQUEST',
  OPENED_LIST_REQUEST: 'VIRTUALS_TICKET_OPENED_LIST_REQUEST',
  LIST_REQUEST: 'VIRTUALS_TICKET_LIST_REQUEST',
  SETTLED_LIST_RECEIVED: 'VIRTUALS_TICKET_SETTLED_LIST_RECEIVED',
  OPENED_LIST_RECEIVED: 'VIRTUALS_TICKET_OPENED_LIST_RECEIVED',
  LIST_RECEIVED: 'VIRTUALS_TICKET_LIST_RECEIVED',
  LIST_ERROR: 'VIRTUALS_TICKET_LIST_ERROR',
  REQUESTING_DATA: 'VIRTUALS_REQUESTING_TICKET_DATA',
  CHECK_CODE: 'VIRTUALS_TICKET_CHECK_CODE',
  CHECK_CODE_PENDING: 'VIRTUALS_TICKET_CHECK_PENDING',
  CHECK_CODE_DATA: 'VIRTUALS_TICKET_CHECK_CODE_DATA',
  CHECK_CODE_ERROR: 'VIRTUALS_TICKET_CHECK_CODE_ERROR',
  CHECK_CODE_CLOSE: 'VIRTUALS_TICKET_CHECK_CODE_CLOSE',
};
