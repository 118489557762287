import { createContext } from 'react';
import store from '../../store';

import transferPropertiesToComponent from '../../page-components/ComponentData/transferPropertiesToComponent';

export const PageDataContext = createContext<any | null>(null);

export const getProjectDataFromContext = (context: any) => {
  const templatesConfig = store.getState().templatesConfig;

  try {
    switch (context.pageType) {
      case 'popups':
        return JSON.parse(templatesConfig.popups[context.pageId].projectData);
      case 'components': {
        const componentId = context.pageId;
        const projectData = templatesConfig.components[componentId].projectData;

        const setComponentsDefaultsValue =
          templatesConfig.setComponentsDefaults[componentId] != null
            ? templatesConfig.setComponentsDefaults[componentId]
            : {};

        const data = JSON.parse(projectData);

        if (setComponentsDefaultsValue && Object.keys(setComponentsDefaultsValue).length)
          transferPropertiesToComponent(data, setComponentsDefaultsValue);

        return data;
      }
      case 'componentsDataRef': {
        const componentDataId = context.pageId;
        const componentId = context.pageLayoutId;

        const projectData = templatesConfig.components[componentId].projectData;
        const cdProperties =
          templatesConfig.componentsData[componentDataId] != null
            ? templatesConfig.componentsData[componentDataId]
            : {};

        const setCompontsDefaultsValue =
          templatesConfig.setComponentsDefaults[componentId] != null
            ? templatesConfig.setComponentsDefaults[componentId]
            : {};

        const visualIdentity =
          templatesConfig.setComponentsDataDefaults[`${componentId}|${componentDataId}`] != null
            ? templatesConfig.setComponentsDataDefaults[`${componentId}|${componentDataId}`]
            : {};

        const finalCDProperties = { ...cdProperties, ...visualIdentity };

        const data = JSON.parse(projectData);

        //console.log(`setCompontsDefaults[${componentId}]`, setCompontsDefaults);

        if (setCompontsDefaultsValue && Object.keys(setCompontsDefaultsValue).length)
          transferPropertiesToComponent(data, setCompontsDefaultsValue);
        if (finalCDProperties && Object.keys(finalCDProperties).length)
          transferPropertiesToComponent(data, finalCDProperties);

        return data;
      }
      case 'componentsElements':
        return JSON.parse(templatesConfig.componentsElements[context.pageId].projectData);
      case 'page':
        return JSON.parse(templatesConfig.pages[context.pageId].projectData);
      case 'layout':
        return JSON.parse(templatesConfig.pageLayouts[context.pageLayoutId].projectData);
      default:
        console.error('[getProjectDataFromContext] Unknown page type', context.pageType);
        return null;
    }
  } catch (e) {
    console.error('[getProjectDataFromContext] err', e);
    return null;
  }
};
