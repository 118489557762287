import React from 'react';
import MainEventBus from '../../utils/evbus';
import styled from 'styled-components';
import CustomToast, { TOAST_TIMER, Notification } from './CustomToast';

const NotificationElementDiv = styled.div({
  position: 'fixed',
  bottom: '10px',
  left: '10px',
  zIndex: '9999',
});
const Notifications = () => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  const removeNotification = () => {
    setNotifications((v) => {
      const newNotifications = [...v];
      // remove notification from the end of the array
      newNotifications.pop();
      return newNotifications;
    });
  };

  const processNotification = (data: Notification) => {
    setNotifications((v) => {
      const newNotifications = [...v];

      // set type to info by default
      if (!data.type) {
        data.type = 'info';
      }

      // set progress bar to true by default
      if (typeof data.progressBar === 'undefined') {
        data.progressBar = true;
      }

      // set icon to true by default
      if (typeof data.icon === 'undefined') {
        data.icon = true;
      }

      data.id = Math.random().toString(36).substr(2, 9);
      // push new notification to the beginning of the array
      newNotifications.unshift(data);
      // remove notification after duration
      setTimeout(() => {
        removeNotification();
      }, data.duration ?? TOAST_TIMER);
      return newNotifications;
    });
  };

  React.useEffect(() => {
    MainEventBus.on('NOTIFICATION', processNotification);
    return () => {
      MainEventBus.remove('NOTIFICATION', processNotification);
    };
  }, []);

  return (
    <NotificationElementDiv>
      {notifications.map((notification) => {
        return <CustomToast key={notification.id} notification={notification} />;
      })}
    </NotificationElementDiv>
  );
};

export default Notifications;
