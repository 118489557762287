import React from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../../store';
import LinkedComponent from '../../../components/linked-component';

const defaultProps = {
  className: '',
  styleText: `
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin:0;
  margin-left: 2px;
  width: 100%;
  min-width: 137px;
  max-width: 216px;
  height: auto;
  aspect-ratio: 1/ 1.46;
  `,
};

const DataGridItemDiv = styled.div`
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  ${(props) => props.$styleText}
`;

export const DataGridItem = (componentProps) => {
  const components = useAppSelector((state) => state.templatesConfig.components);

  let content = componentProps.children;

  if (componentProps.cmId) {
    if (components[componentProps.cmId] != null) {
      content = <LinkedComponent componentId={componentProps.cmId} />;
    } else {
      content = null;
    }
  }

  const classes = [];
  if (componentProps.className) classes.push(componentProps.className);
  if (componentProps.rClassName) classes.push(componentProps.rClassName);

  return (
    <DataGridItemDiv
      className={classes.join(' ')}
      $styleText={componentProps.styleText != null ? componentProps.styleText : defaultProps.styleText}
      style={componentProps.style}>
      {content}
    </DataGridItemDiv>
  );
};
