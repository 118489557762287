class ResponseErrorMessages {
  get(errorCode) {
    errorCode = errorCode ? errorCode : 0;
    // console.log('DEBUG running response errorCode', errorCode);
    switch (errorCode.toString()) {
      case '1':
        return 'The application has encountered an unknown error.';
      case '2':
        return 'This player is already registered.';
      case '3':
        return 'Invalid phone number.';
      case '4':
        return 'Invalid email address.';
      case '5':
        return 'The minimum age requirement is not met.';
      case '6':
        return 'The cnp is not valid.';
      case '7':
        return 'Email is already registered';
      case '8':
        return 'Phone number is already registered.';
      case '9':
        return 'The application has encountered a saving error.';
      case '10':
        return 'Inserted code is invalid.';
      case '11':
        return 'Current password is invalid.';
      case '12':
        return 'Authentication expired! Please login and try again.';
      case '13':
        return 'Invalid token.';
      case '14':
        return 'Phone number SMS limit was reached.';
      case '15':
        return 'Insufficient funds.';
      case '16':
        return 'Invalid request.';
      case '17':
        return 'Duplicate request.';
      case '18':
        return 'Negative amount.';
      case '19':
        return 'Cannot withdraw while having active bonuses.';
      case '20':
        return 'This email address is not registered';
      case '21':
        return 'This account is suspended. Please contact support.';
      case '22':
        return 'This account is closed. Please contact support.';
      case '23':
        return 'This account is self excluded.';
      case '43':
        return 'Invalid SMS code';
      default:
        return 'The application has encountered an unknown error.';
    }
  }
}

export default new ResponseErrorMessages();
