import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { getText } from '@/components/modules/wheel/utils';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

const interceptClickEvent = (onClick) => (e) => {
  const target = e.target || e.srcElement;
  if (target.tagName === 'A' && window.config.cordova) {
    const href = target.getAttribute('href');

    if (href.indexOf('http') > -1) {
      console.log('Opening external link in system browser', href);
      e.preventDefault();
      window.cordova.InAppBrowser.open(href, '_system');
    }
  }
  onClick?.(e);
};

const TextComponent = styled.div((props) => props.$styleText);

export const RichText = (componentProps) => {
  let props = componentProps;

  const { i18n, t } = useTranslation();

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  if (!isVisible) return null;

  let text = props.properties.richText;

  if (typeof text === 'object') {
    if (text['0'] != null || text['2'] != null || text['42'] != null) {
      const lang = {
        en: '2',
        ro: '42',
      };

      if (text[lang[i18n.language]]) {
        text = text[lang[i18n.language]];
      } else {
        text = text['2'] ?? text['0'] ?? '';
      }
    }
  } else if (text?.[0] === '{' && text?.[text.length - 1] === '}') {
    try {
      const tmp = JSON.parse(text);
      if (tmp.en != null || tmp.ro !== null) {
        text = getText(tmp, i18n.language, '');
      }
    } catch (e) { }
  }

  text = text != null ? t(text, props.properties.placeholders ? props.properties.placeholders : {}) : '';

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  let onClick = props.properties.onClick ?? null;

  if (window.config.cordova === true) {
    onClick = interceptClickEvent(onClick);
  }

  return (
    <TextComponent
      className={props.className ?? ''}
      $styleText={props.styleText}
      style={props.style}
      dangerouslySetInnerHTML={{ __html: text }}
      data-node-id={props.nodeId}
      onClick={onClick}
      {...extraDataProps}
    />
  );
};

export default React.memo(RichText, equalWithoutChildren);
