import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

export const defaultProps = {
  className: '',
  styleText: '',
};

const StyledObject = styled.object((props) => props.$styleText);
const StyledIframe = styled.iframe((props) => props.$styleText);

export const HtmlObject = (componentProps) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  if (!isVisible) return null;

  let onClick = null;
  if (props.properties && props.properties.onClick && typeof props.properties.onClick === 'function') {
    onClick = props.properties.onClick;
  } else if (
    componentProps.properties &&
    componentProps.properties.onClick &&
    typeof componentProps.properties.onClick === 'function'
  ) {
    onClick = componentProps.properties.onClick;
  }

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  if (props?.properties?.objectType === 'text/html') {
    // use an iframe for text/html; object doesn't work properly
    return <StyledIframe
      className={props.className ?? ''}
      $styleText={props.styleText}
      onClick={onClick}
      style={props.style}
      id={props?.properties?.id}
      src={props?.properties?.data}
      width={props?.properties?.width}
      height={props?.properties?.height}
      scrolling={props?.properties?.scrolling ?? 'no'}
      frameBorder={props?.properties?.frameBorder ?? 'no'}
      {...extraDataProps}
    />;
  }

  return (
    <StyledObject
      className={props.className ?? ''}
      $styleText={props.styleText}
      onClick={onClick}
      style={props.style}
      id={props?.properties?.id}
      data={props?.properties?.data}
      type={props?.properties?.objectType}
      width={props?.properties?.width}
      height={props?.properties?.height}
      scrolling="no"
      {...extraDataProps}
    />
  );
};
