import React from 'react';
import styled from 'styled-components';
import { shallowEqual } from 'react-redux';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';

import WinnerFunDrawer from '@/modules/winner-fun/views/WinnerFunDrawer';
import SeasonStart from '@/modules/winner-fun/views/modals/SeasonStart';
import SeasonEnd from '@/modules/winner-fun/views/modals/SeasonEnd';
import { historyLoad } from '@/modules/winner-fun/store/actions/history';
import { lastActiveWinnerFunCampaign, activeWinnerFunCampaign } from './utils/functions';

import './index.scss';

type WinnerFunModal = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const WinnerFunModal = (componentProps: WinnerFunModal) => {
  const { isWinnerFun, winnerFunCampaigns, oldWinnerFunCampaigns } = useAppSelector(
    (state) => ({
      isWinnerFun: state.bets.app.isWinnerFun,
      winnerFunCampaigns:
        state.winnerFun.config && state.winnerFun.config.seasons ? state.winnerFun.config.seasons : [],
      oldWinnerFunCampaigns:
        state.winnerFun.history && state.winnerFun.history.seasons ? state.winnerFun.history.seasons : [],
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();
  const [showWinnerFunModal, setShowWinnerFunModal] = React.useState(false);
  const [showSeasonStart, setShowSeasonStart] = React.useState({
    open: false,
    campaign: null,
  });
  const [showSeasonEnd, setShowSeasonEnd] = React.useState({
    open: false,
    campaign: null,
  });

  const onCloseSeasonStart = () => {
    setShowSeasonStart({
      open: false,
      campaign: null,
    });
  };
  const onCloseSeasonEnd = () => {
    setShowSeasonEnd({
      open: false,
      campaign: null,
    });
  };

  React.useEffect(() => {
    if (componentProps.properties.withSeasons) dispatch(historyLoad());
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (isWinnerFun && componentProps.properties.withSeasons) {
      const seenData = localStorage.getItem('winner_fun_seen');
      let seen = {
        lastActiveCampaign: '',
        lastInactiveCampaign: '',
      };

      try {
        if (seenData && seenData !== 'null') {
          seen = JSON.parse(seenData);
        }
      } catch (err) {
        /* noop */
      }

      let skip = false;

      if (oldWinnerFunCampaigns && oldWinnerFunCampaigns.length !== 0) {
        const inactiveCampaign = lastActiveWinnerFunCampaign(oldWinnerFunCampaigns);
        if (inactiveCampaign) {
          if (seen.lastInactiveCampaign !== '' && seen.lastInactiveCampaign !== inactiveCampaign.id) {
            seen.lastInactiveCampaign = inactiveCampaign.id;
            setShowSeasonEnd({
              open: true,
              campaign: inactiveCampaign,
            });
            skip = true;
          } else {
            seen.lastInactiveCampaign = inactiveCampaign.id;
          }
        }
      }

      if (!skip && winnerFunCampaigns && winnerFunCampaigns.length !== 0) {
        const activeCampaign = activeWinnerFunCampaign(winnerFunCampaigns);
        if (activeCampaign) {
          if (seen.lastActiveCampaign !== '' && seen.lastActiveCampaign !== activeCampaign.id) {
            seen.lastActiveCampaign = activeCampaign.id;
            setShowSeasonStart({
              open: true,
              campaign: activeCampaign,
            });
          } else {
            seen.lastActiveCampaign = activeCampaign.id;
          }
        }
      }

      localStorage.setItem('winner_fun_seen', JSON.stringify(seen));
    }
  }, [winnerFunCampaigns, oldWinnerFunCampaigns, isWinnerFun, componentProps.properties.withSeasons]);

  const contextValue = {
    isWinnerFun,
    showWinerFunModal: () => setShowWinnerFunModal(true),
    hideWinerFunModal: () => setShowWinnerFunModal(false),
  };

  return (
    <React.Fragment>
      <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
        <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
      </ModuleElementDiv>
      {showWinnerFunModal && (
        <WinnerFunDrawer open={showWinnerFunModal} onModalClose={() => setShowWinnerFunModal(false)} />
      )}
      {componentProps.properties.withSeasons && (
        <React.Fragment>
          {showSeasonStart.open && (
            <SeasonStart
              open={showSeasonStart.open}
              campaign={showSeasonStart.campaign}
              handleClose={onCloseSeasonStart}
            />
          )}
          {showSeasonEnd.open && (
            <SeasonEnd open={showSeasonEnd.open} campaign={showSeasonEnd.campaign} handleClose={onCloseSeasonEnd} />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default WinnerFunModal;
