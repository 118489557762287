import React from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../../store';
import craftJsParser from '../../../components/utils/craftJsParser';

import { StateScrollerItems } from '../StateScrollerItems/StateScrollerItems';
import { DataElementContext } from '../../common/DataElementContext';
import { getStateValue } from '../../utils/getStateValue';

import triggerStateLoaderIfNeeded from '../../utils/hooks/triggerStateLoaderIfNeeded';
import { PageDataContext, getProjectDataFromContext } from '../../../components/utils/PageDataProvider';

const defaultProps = {
  className: '',
  styleText: `
  width: 100%;
  height: auto;
  `,
  properties: {
    items: 6,
    dsType: '',
  },
};

const StateScrollerDiv = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  ${(props) => props.$styleText}
`;

export const StateScroller = (componentProps) => {
  const pageDataContext = React.useContext(PageDataContext);
  const config = useAppSelector((state) => state.templatesConfig);

  const { items } = componentProps.properties;

  let hasError = false;
  let content = null;

  try {
    const dsType = componentProps.properties?.dsType ?? '';
    const det = dsType ? config.dataElementTypes[dsType] : null;

    let stateKey = '';

    if (det && det.internal && det.internal.length) {
      const from = det.internal.find((el) => el.id === 'from');
      const path = det.internal.find((el) => el.id === 'path');
      if (from && from.value === 'state') {
        if (path && path.value) stateKey = path.value;
      }
    }

    if (!stateKey) return null;
    triggerStateLoaderIfNeeded(stateKey);

    const stateItems = useAppSelector((state) => getStateValue(state, stateKey));
    if (!stateItems) return null;

    const data = getProjectDataFromContext(pageDataContext);

    const dsWrapperData = data[componentProps.linkedNodes['ds-lister-items']];

    const itemContent = craftJsParser({
      craftState: data,
      rootNodeId: dsWrapperData.nodes[0],
      pageId: componentProps.refType === 'layout' ? null : componentProps.refId,
      pageLayoutId: componentProps.refType === 'layout' ? componentProps.refId : null,
      pageType: componentProps.refType,
    });

    const rendered =
      stateItems?.slice(0, items).map((item, i) => {
        return (
          <DataElementContext.Provider key={i} value={item}>
            {itemContent}
          </DataElementContext.Provider>
        );
      }) || [];

    content = <StateScrollerItems {...dsWrapperData.props}>{rendered}</StateScrollerItems>;
  } catch (err) {
    console.error(err);
    hasError = true;
  }

  if (hasError) return null;

  return (
    <StateScrollerDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText} style={componentProps.style}>
      {content}
    </StateScrollerDiv>
  );
};
