import * as actionTypes from './actionTypes';

export const requestDocuments = () => {
  return {
    type: actionTypes.documents.REQUEST_DOCUMENTS,
  };
};

export const receivedDocuments = (data) => {
  return {
    type: actionTypes.documents.RECEIVED_DOCUMENTS,
    documents: data,
  };
};

export const requestDocumentsUpload = (file, docType, documentID) => {
  return {
    type: actionTypes.documents.REQUEST_DOCUMENTS_UPLOAD,
    file: file,
    docType: docType,
    documentID: documentID,
  };
};

export const receivedDocumentsUpload = (data) => {
  return {
    type: actionTypes.documents.RECEIVED_DOCUMENTS_UPLOAD,
    documentsUpload: data,
  };
};

export const requestDocumentsMaxFileSize = () => {
  return {
    type: actionTypes.documents.REQUEST_DOCUMENTS_MAX_FILE_SIZE,
  };
};

export const receivedDocumentsMaxFileSize = (data) => {
  return {
    type: actionTypes.documents.RECEIVED_DOCUMENTS_MAX_FILE_SIZE,
    documentsMaxFileSize: data,
  };
};
