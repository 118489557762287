import getStore from '../store';
// import * as storeActions from '../../store/actions';
// import history from '../../history';
import moment from 'moment';
import localization from 'moment/locale/ro.js';

// import LuckySixImage from '../../virtuals/assets/img-thumbnail-luckysix@2x.png';
// import LuckyXImage from '../../virtuals/assets/img-thumbnail-luckyx@2x.png';
// import NextSixImage from '../../virtuals/assets/img-thumbnail-nextsix@2x.png';
// import GreyhoundImage from '../../virtuals/assets/img-thumbnail-grayoundraces@2x.png';
// import VGreyhoundImage from '../../virtuals/assets/img-thumbnail-virtualgrayhounds@2x.png';
// import VHorseImage from '../../virtuals/assets/img-thumbnail-virtualhorseracing@2x.png';
// import VMSImage from '../../virtuals/assets/img-thumbnail-virtualmoto@2x.png';
// import { iosGames } from '../../common/functions';

//TODO: fix imports and uncomment

export const emptyImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

export const emptyHash = {};

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const styleToObj = (style) => {
  const ret = {};

  if (!style) return ret;
  if (typeof style !== 'string') return ret;

  const parts = style.split(';');

  parts.forEach((p) => {
    const line = p.trim();

    if (line && line.indexOf(':') > -1) {
      let [key, value] = line.split(':', 2);
      key = toCamel(key.trim());
      value = value.trim();
      ret[key] = value;
    }
  });

  return ret;
};

export const getData = (data, path, def) => {
  const elements = path.split('.');
  let tmp = data;
  if (data) {
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];

      if (element.indexOf('[') > -1) {
        // array
        let parts = element.split(/\[|\]/);
        if (parts.length === 3) {
          if (typeof tmp[parts[0]] !== 'undefined') {
            tmp = tmp[parts[0]];
            element = parseInt(parts[1]);
          } else {
            tmp = null;
            break;
          }
        }
      }

      if (typeof tmp[element] !== 'undefined') {
        tmp = tmp[element];
      } else {
        tmp = null;
        break;
      }
    }
  }

  if (tmp) return tmp;
  return def;
};

export const getText = (data, path, def) => {
  const elements = path.split('.');
  let tmp = data;
  if (data) {
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];

      if (element.indexOf('[') > -1) {
        // array
        let parts = element.split(/\[|\]/);
        if (parts.length === 3) {
          if (typeof tmp[parts[0]] !== 'undefined') {
            tmp = tmp[parts[0]];
            element = parseInt(parts[1]);
          } else {
            tmp = null;
            break;
          }
        }
      }

      if (typeof tmp[element] !== 'undefined') {
        if (element === 'ro' || element === 'en') {
          // there is no translation for that language check the remaining
          let txtLang = tmp[element];
          if (!txtLang) {
            if (element === 'ro') {
              txtLang = tmp['en'];
            } else if (element === 'en') {
              txtLang = tmp['ro'];
            }
          }
          tmp = txtLang;
        } else {
          tmp = tmp[element];
        }
      } else {
        tmp = null;
        break;
      }
    }
  }

  if (tmp) return tmp;
  return def;
};

export const getStyle = (data, path, def) => {
  const elements = path.split('.');
  let tmp = data;
  if (data) {
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];

      if (element.indexOf('[')) {
        // array
        let parts = element.split(/\[|\]/);
        if (parts.length === 3) {
          if (typeof tmp[parts[0]] !== 'undefined') {
            tmp = tmp[parts[0]];
            element = parseInt(parts[1]);
          } else {
            tmp = null;
            break;
          }
        }
      }

      if (typeof tmp[element] !== 'undefined') {
        tmp = tmp[element];
      } else {
        tmp = null;
        break;
      }
    }
  }

  if (tmp) return tmp;
  return def;
};

export const toJSON = (txt) => {
  let res = {};
  try {
    res = JSON.parse(txt);
  } catch (err) {
    /*noop*/
  }
  return res;
};

export const launchSlotGame = (item, event, localActions) => {
  const store = getStore();

  try {
    const game = {
      id: item.id,
      game_id: item.gameId,
      provider: item.providerId,
      name: item.name,
      promoted: false,
    };

    if (window.config.platformType.toString() === '1') {
      // desktop
      store.dispatch(localActions.openGame(game, event.clientX, event.clientY));
    } else {
      store.dispatch(localActions.openGameLauncher(game.game_id ? game.game_id : game.id));
      store.dispatch(localActions.setGameOpened(game));
    }

    history.push(`/play/${game.game_id ? game.game_id : game.id}`);
  } catch (err) {
    /*noop*/
  }
};

export const openItem = (event) => {
  event.stopPropagation();

  const target = event.currentTarget;

  if (target) {
    const type = target.dataset.type;
    const item = toJSON(target.dataset.item);

    switch (type) {
      case 'slot_game':
        if (item.id && item.providerId) {
          // launchSlotGame(item, event, storeActions);
        }

        break;

      case 'live_casino_game':
        if (item.id) {
          history.push(`/cazino_live/All/${item.id}`);
        }
        break;

      case 'virtual_game':
        if (item.id) {
          history.push(`/virtuale/jocuri/${item.id}/?x=${event.clientX}&y=${event.clientY}`);
        }
        break;

      case 'navigate': {
        if (item.url) {
          let url = item.url;
          if (url.indexOf('/lobby') === 0) {
            let rootPath = '/lobby';
            if (window.config.rootPaths && window.config.rootPaths.casino) {
              rootPath = '/' + window.config.rootPaths.casino;
            }
            url = url.replace(/^(\/lobby)/, rootPath);
          }
          if (url.indexOf('/cazino') === 0) {
            let rootPath = '/cazino';
            if (window.config.rootPaths && window.config.rootPaths.casino) {
              rootPath = '/' + window.config.rootPaths.casino;
            }
            url = url.replace(/^(\/cazino)/, rootPath);
          }
          history.push(url);
        }
        break;
      }
      case 'breadcrumbs_back': {
        if (item.redirect_history_back) {
          history.goBack();
        } else if (item.url) {
          let url = item.url;
          if (url.indexOf('/lobby') === 0) {
            let rootPath = '/lobby';
            if (window.config.rootPaths && window.config.rootPaths.casino) {
              rootPath = '/' + window.config.rootPaths.casino;
            }
            url = url.replace(/^(\/lobby)/, rootPath);
          }
          if (url.indexOf('/cazino') === 0) {
            let rootPath = '/cazino';
            if (window.config.rootPaths && window.config.rootPaths.casino) {
              rootPath = '/' + window.config.rootPaths.casino;
            }
            url = url.replace(/^(\/cazino)/, rootPath);
          }
          history.push(url);
        }
        break;
      }
      default:
        break;
    }
  }
};

function iOS() {
  return (
    ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

let isDesktop = window.config.platformType === 1 ? true : false;
let isMobile = window.config.platformType === 2 && !window.config.cordova ? true : false;
let isIos = isMobile && iOS() && !window.config.cordova ? true : false;
let isAndroid = isMobile && !isIos && !window.config.cordova ? true : false;
let isNativeApp = window.config.cordova ? true : false;
let isNativeIosApp = window.config.native_platform === 'ios';
let isNativeAndroidApp = window.config.native_platform === 'android';

export const checkItemAvailability = (item) => {
  if (
    item.type === 'slot_game' &&
    window.config.platformType.toString() !== '1' &&
    typeof item.desktopOnly !== 'undefined' &&
    item.desktopOnly
  ) {
    return false;
  }

  if (window.config && window.config.cordova && window.config.native_platform === 'ios') {
    if (item.type === 'slot_game') {
      // if (typeof iosGames[item.gameId] === 'undefined') {
      //   return false;
      // }
    }
  }

  if (!item || !(item.data && item.data.active)) return false;

  if (item && item.data && item.data.timeEnabled) {
    const currentTime = Math.floor(+new Date() / 1000);

    if (!(currentTime >= item.data.startTime && currentTime <= item.data.endTime)) {
      return false;
    }
  }

  if (item && item.data && item.data.where) {
    let thisPlatform = false;

    item.data.where.forEach((platform) => {
      switch (platform) {
        case 'all':
          thisPlatform = true;
          break;
        case 'desktop':
          if (isDesktop) thisPlatform = true;
          break;
        case 'mobile':
          if (isMobile) thisPlatform = true;
          break;
        case 'mobile-android':
          if (isAndroid) thisPlatform = true;
          break;
        case 'mobile-ios':
          if (isIos) thisPlatform = true;
          break;
        case 'native-app':
          if (isNativeApp) thisPlatform = true;
          break;
        case 'native-app-ios':
          if (isNativeIosApp) thisPlatform = true;
          break;
        case 'native-app-android':
          if (isNativeAndroidApp) thisPlatform = true;
          break;
        default:
        /* noop */
      }
    });

    if (!thisPlatform) return false;
  }

  return true;
};

export const checkComponentAvailability = (component) => {
  if (component) {
    if (!component.active) return false;

    if (component.timeEnabled) {
      const currentTime = Math.floor(+new Date() / 1000);

      if (!(currentTime >= component.startTime && currentTime <= component.endTime)) {
        return false;
      }
    }

    if (component.where) {
      let thisPlatform = false;

      component.where.forEach((platform) => {
        switch (platform) {
          case 'all':
            thisPlatform = true;
            break;
          case 'desktop':
            if (isDesktop) thisPlatform = true;
            break;
          case 'mobile':
            if (isMobile) thisPlatform = true;
            break;
          case 'mobile-android':
            if (isAndroid) thisPlatform = true;
            break;
          case 'mobile-ios':
            if (isIos) thisPlatform = true;
            break;
          case 'native-app':
            if (isNativeApp) thisPlatform = true;
            break;
          case 'native-app-ios':
            if (isNativeIosApp) thisPlatform = true;
            break;
          case 'native-app-android':
            if (isNativeAndroidApp) thisPlatform = true;
            break;
          default:
          /* noop */
        }
      });

      if (!thisPlatform) return false;
    }
  }

  return true;
};

const months = {
  1: 'Ian',
  2: 'Feb',
  3: 'Mart',
  4: 'Apr',
  5: 'Mai',
  6: 'Iun',
  7: 'Iul',
  8: 'Aug',
  9: 'Sept',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const formatDateWithTime = (date, lang) => {
  let refDate = moment();
  let tomorrow = moment().add(1, 'days');
  let dt = moment(date);
  if (lang === 'ro') {
    dt = moment(date).locale('ro', localization);
  }

  if (refDate.isSame(dt, 'day')) {
    return `${lang === 'ro' ? 'Azi' : 'Today'} ${dt.format('HH:mm')}`;
  }
  if (tomorrow.isSame(dt, 'day')) {
    return `${lang === 'ro' ? 'Maine' : 'Tomorrow'} ${dt.format('HH:mm')}`;
  }

  if (lang === 'ro') {
    return `${dt.format('ddd, D ')}${months[dt.format('M')].toUpperCase().replace('.', '')} ${dt.format('HH:mm')}`;
  }
  return `${dt.format('ddd, ')}${dt.format('MMM').toUpperCase().replace('.', '')}${dt.format(' D')} ${dt.format(
    'HH:mm',
  )}`;
};

export const fixUrlProtocol = (url) => {
  let tmp = url;

  if (tmp) {
    if (tmp.indexOf('//') === 0) {
      tmp = 'https:' + tmp;
    } else if (tmp.indexOf('http://') === 0) {
      tmp = tmp.replace('http://', 'https://');
    }
  }
  return tmp;
};

const cdnImagesUrl = `https://${window.config.cdn_address}/${window.config.brand_name_short.toLowerCase()}/${
  window.config.platformType === 1 ? 'desktop' : 'mobile'
}`;
const cdnSimpleImagesUrl = `https://${window.config.lobbyCDNAddress}/winner`;

const providers = {
  1: 'egt',
  2: 'amatic',
  3: 'skywind',
  4: 'ctgaming',
  5: 'novomatic',
  6: 'spribe',
  7: 'pragmatic',
  9: 'isoftbet',
};

const string_to_slug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  let to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

export const itemThumbnail = (item) => {
  const image = item && item.data && item.data.thumbnail ? item.data.thumbnail : null;
  const type = item.type;
  const name = item.name;
  const providerId = item.providerId;

  if (type === 'slot_game') {
    if (!image && name) {
      if (providerId && typeof providers[providerId] !== 'undefined') {
        return `${cdnSimpleImagesUrl}/game-thumbnails/${providers[providerId]}/${
          string_to_slug(name).toLowerCase() + '.png'
        }`;
      }

      return `${cdnImagesUrl}/games/small/${name.replace(/\s/g, '-').toLowerCase() + '.jpg'}`;
    }
    return image;
  } else if (type === 'live_casino_game') {
    if (!image && item.label) {
      return `${cdnSimpleImagesUrl}/game-thumbnails/evolution/${string_to_slug(item.label).toLowerCase() + '.png'}`;
    }
  } else if (type === 'virtual_game') {
    switch (item.internalId) {
      // case 'luckysix': {
      //   return LuckySixImage;
      // }
      // case 'greyhound': {
      //   return GreyhoundImage;
      // }
      // case 'nextsix': {
      //   return NextSixImage;
      // }
      // case 'vhorse': {
      //   return VHorseImage;
      // }
      // case 'vms': {
      //   return VMSImage;
      // }
      // case 'luckyx': {
      //   return LuckyXImage;
      // }
      // case 'vgreyhound': {
      //   return VGreyhoundImage;
      // }
      default:
      /* noop */
    }
  }

  return image;
};

export const formatNumber = (value) => {
  if (typeof value === 'string') return value;
  if (!value) return '0.00';

  const decimals = 2;

  let res = Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);
  res = res.replace(/\./g, ',');
  res = res.replace(/\d(?=(\d{3})+,)/g, '$&.');
  return res.replace(/,00$/, '');
};

export const getIndexById = (items, id, key) => {
  try {
    let actualKey = key ? key : 'id';
    return items.findIndex((el) => el && id && el[actualKey] && el[actualKey].toString() === id.toString());
  } catch (err) {
    /* */
  }

  return -1;
};
