import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { makeGetMatchesForBets, makeGetBets } from '../../../../../modules/bets/store/selectors/betData';

import { winCoinsSystemsLoad } from '../../../../../modules/winner-fun/store/actions/wincoins';
import { RootState } from '@/store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const TicketModal = (componentProps: any) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const { t } = useTranslation();
  const { handlePlaceBetClose } = props.properties;
  const { ticketCreateStatus, auth, errorDetails } = props.state;

  React.useEffect(() => {
    if (ticketCreateStatus !== 'pending') {
      componentProps.winCoinsSystemsLoad();
    }
  }, [ticketCreateStatus, componentProps.winCoinsSystemsLoad]);

  let loggedIn = false;
  if (auth && ['user', 'token'].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const getErrorMessage = (errorDetails: any[]) => {
    const messages: any[] = [];

    /*
    {
        "type": "place_status_exception",
        "data": {
            "Limit": 0,
            "MinAmount": 0,
            "MaxAmount": 0,
            "ErrorCode": 131,
            "ErrorMessage": "BetBlockedForPrematch"
        }
    }
    */

    errorDetails.forEach((e: any) => {
      if (e.ErrorCode) {
        switch (e.ErrorCode) {
          case 57: {
            messages.push(t('Maximum stake accepted: {{LIMIT}} Lei', { LIMIT: e.Limit }));
            break;
          }
          default:
            // don't display anything
            break;
        }
      }
    });
    return messages;
  };

  const contextValue = {
    ticketCreateStatus,
    loggedIn,
    errorMessages: errorDetails.length > 0 ? getErrorMessage(errorDetails) : [],
    closeDialog: () => {
      handlePlaceBetClose(3);
    },
    onKeepTicket: () => {
      handlePlaceBetClose(2);
    },
    onOpenMyTickets: () => {
      handlePlaceBetClose(1);
    },
  };

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
  /*
  return (
    <Dialog
      open={openPlaceBet}
      fullWidth={true}
      maxWidth={'sm'}
      TransitionComponent={Transition}
      keepMounted
      onClose={handlePlaceBetClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`${classes.root} betsModal betsModalDialog`}
    >
      <DialogTitle className={`${classes.dialogTitle} ${!ticketOnline && ticketCode && ticketCreateStatus === 'done' ? classes.d_none : ''}`} id="alert-dialog-slide-title">
        {ticketCreateStatus === 'pending' && (
          <div className={classes.dialogHeaderInProgress}>
            <div>
              <img src={TicketInProgress} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t('Placing the ticket in progress')}</div>
          </div>
        )}
        {ticketCreateStatus === 'done' && (
          <div className={classes.dialogHeaderSuccess}>
            <div>
              <img src={TicketSuccess} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t('Success')}</div>
          </div>
        )}
        {ticketCreateStatus === 'reoffered' && (
          <div className={classes.dialogHeaderReoffered}>
            <div>
              <img src={TicketReoffered} className="dh-logo" alt="" />
            </div>
            <div className="dh-text">{t('Ticket reoffered')}</div>
          </div>
        )}
        {ticketCreateStatus !== 'pending' &&
          ticketCreateStatus !== 'done' &&
          ticketCreateStatus !== 'reoffered' && (
            <div className={classes.dialogHeaderFailed}>
              <div>
                <img src={TicketFailed} className="dh-logo" alt="" />
              </div>
              <div className="dh-text">{t('Placing failed')}</div>
            </div>
          )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {ticketCreateStatus === 'pending' && (
          <div className={classes.dialogBodyInProgress}>
            <div className="db-text">
              {t('Your ticket will be placed in a few moments. Please wait.')}
            </div>
          </div>
        )}
        {ticketCreateStatus === 'done' && (
          <div className={classes.dialogBodySuccess}>
            {ticketOnline && loggedIn && (
              <div className="db-text">
                {t(
                  'The ticket was placed successfully and you can find it in the "My Tickets" section.'
                )}
              </div>
            )}
            {ticketOnline && !loggedIn && <div className="db-text">{t('The ticket was placed successfully.')}</div>}
            {!ticketOnline && ticketCode && (
              <div className={`${classes.mt} ${classes.ticket_qr} db-text db-bold`} id="ticket-barcode">
                <Barcode value={ticketCode} format="CODE128" />
              </div>
            )}
            {!ticketOnline && ticketCode && (
              <div className={`${classes.reservedTicketsWrapper}`}>
                <div className={`${classes.eventsNo}`}>
                  <span className={`${classes.eventsNoTitle}`}>{t('Events')}: {ticket && ticket.prematch ? ticket.prematch.selected.length : 0}</span>
                  {loggedIn && <span>&nbsp;|&nbsp;<span className={classes.my_tickets} onClick={() => handlePlaceBetClose(1)}>{t('MY TICKETS')}</span></span>}
                </div>
              </div>
            )}
            {!ticketOnline && ticketCode && (
              <div className={`${classes.reservedTicketsWrapper}`}>
                <div className={`${classes.events}`}>
                  {!isDesktop && ticket && ticket.prematch && ticket.prematch.selected.map((bet, i) => {
                    return <div className={`${classes.event}`} key={i}>
                      <div className={`${classes.match_info}`}>
                        <div className={`${classes.teams}`}>{bet.match.team1Name} - {bet.match.team2Name}</div>
                        <div className={`${classes.date}`}>{moment(bet.match.matchDateTime).format('DD/MM HH:mm')}</div>
                      </div>
                      {getFormattedBet(bet)}
                    </div>;
                  })}
                  {isDesktop && <table className={classes.tbl}>
                    <thead>
                      <tr className={`${classes.eventLine} odd`}>
                        <td className={`${classes.teams}`}>{t('Match')}</td>
                        <td className={`${classes.teams}`}>{t('Date/Hour')}</td>
                        <td className={`${classes.teams}`}>{t('Bet')}</td>
                        <td className={`${classes.teams}`}>{t('Outcome')}</td>
                        <td className={`${classes.odd_value}`}>{t('Odd')}</td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ticket.prematch.selected.map((bet, i) => {
                          return <tr className={`${classes.eventLine} ${i % 2 !== 0 ? 'odd' : ''}`} key={i}>
                            <td className={`${classes.teams}`}>{bet.match.team1Name} - {bet.match.team2Name}</td>
                            <td className={`${classes.date}`}>{moment(bet.match.matchDateTime).format('DD/MM/YYYY HH:mm')}</td>
                            {getFormattedBet(bet, true)}
                          </tr>;
                        })
                      }
                    </tbody>
                  </table>
                  }
                </div>
              </div>
            )}
            {!ticketOnline && ticketCode && (
              <div className={`${classes.reservedTicketsWrapper}`}>
                <div className={`${classes.stake_info}`}>
                  <div className={`${classes.stake_line}`}>
                    <div className={`${classes.stake_name}`}>{t('Stake')}</div>
                    <div className={`${classes.stake_value}`}>{ticket.amount}</div>
                  </div>
                  <div className={`${classes.stake_line}`}>
                    <div className={`${classes.stake_name} big`}>{t('Possible Winning')}</div>
                    <div className={`${classes.stake_value} big`}>{ticket.totalMaxWinAmount}</div>
                  </div>
                  {ticket.bonus && ticket.bonus.ticketAppliedBonus && <div className={`${classes.stake_line}`}>
                    <div className={classes.stake_name}>
                      {t('Progressive Bonus')} {ticket.bonus.ticketAppliedBonus.maxPercentage}%
                    </div>
                    <div className={classes.stake_value}>
                      +{formatAmount(ticket.bonus.ticketAppliedBonus.amountMax, false, false)}{' '}
                    </div>
                  </div>}
                  {ticket.bonus && ticket.bonus.ticketBonusEligibles && ticket.bonus.ticketBonusEligibles.length !== 0 &&
                    <div
                      className={`${classes.stake_line}`}
                    >
                      <div className={classes.stake_name}>{t('Eligible Bonuses')}</div>
                      <div className={classes.stake_value}>{getBonuses(ticket.bonus.ticketBonusEligibles)}</div>
                    </div>
                  }
                </div>
              </div>
            )}
            {ticketOnline && loggedIn && (
              <div
                className={`${classes.mt2} db-text db-link`}
                onClick={() => handlePlaceBetClose(1)}
              >
                {t('MY TICKETS')}
              </div>
            )}
            <div className={`d-flex ${classes.mbut} ${!ticketOnline && ticketCode && !isDesktop ? 'flex-column justify-content-center' : 'flex-row justify-content-between'} flex-nowrap align-items-center`}>
              {isDesktop && !ticketOnline && ticketCode && <ButtonBase className={`${classes.dialogOK} ${classes.m0} ${classes.dialogOKOutline}`} onClick={() => printTicket(ticketCode)}>
                {t('Print')}
              </ButtonBase>}
              <ButtonBase className={`${classes.dialogOK} ${classes.m0} ${classes.dialogOKOutline}`} onClick={() => handlePlaceBetClose(2)}>
                {t('Keep Ticket')}
              </ButtonBase>
              <ButtonBase className={`${classes.dialogOK} ${classes.m0}`} onClick={handlePlaceBetClose}>
                {t('New Ticket')}
              </ButtonBase>
            </div>
          </div>
        )}
        {ticketCreateStatus === 'reoffered' && (
          <div className={classes.dialogBodyReoffered}>
            <div className="db-text">
              {t('The ticket was reoffered. Please check the bet slip for changes')}
            </div>
            <div>
              <ButtonBase className={classes.dialogOK} onClick={() => handlePlaceBetClose(2)}>
                {t('OK')}
              </ButtonBase>
            </div>
          </div>
        )}
        {ticketCreateStatus !== 'pending' &&
          ticketCreateStatus !== 'done' &&
          ticketCreateStatus !== 'reoffered' && (
            <div className={classes.dialogBodyFailed}>
              <div className="db-text">
                {t('An error occurred: Error creating ticket')}
              </div>
              {errorDetails.length > 0 && getErrorMessage(errorDetails)}
              <div className={`${classes.mt} db-text`}>
                {t('If the problem persists, please contact the Customer Support department.')}
              </div>
              {loggedIn && (
                <div
                  className={`${classes.mt2} db-text db-link`}
                  onClick={() => handlePlaceBetClose(1)}
                >
                  {t('MY TICKETS')}
                </div>
              )}
              <div>
                <ButtonBase className={classes.dialogOK} onClick={() => handlePlaceBetClose(2)}>
                  {t('OK')}
                </ButtonBase>
              </div>
            </div>
          )}
      </DialogContent>
    </Dialog>
  );
  */
};

const getBets = makeGetBets();

const mapStateToProps = (state: RootState) => {
  const bst = state.bets;
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  const getPrematchMatches = makeGetMatchesForBets('prematch', ct.prematch.selected);

  return {
    state: {
      ticketCreateStatus: ct.ticketCreateStatus,
      errorDetails: ct.errorDetails,
      ticketOnline: ct.ticketOnline,
      ticketCode: ct.ticketCode,
      auth: state.authentication,
      prematchMatches: getPrematchMatches(state),
      // @ts-ignore
      bets: getBets(state, { mType: 'prematch' }),
    },
  };
};

const mapActions = {
  winCoinsSystemsLoad: winCoinsSystemsLoad,
};

// @ts-ignore
export default connect(mapStateToProps, mapActions)(TicketModal);
