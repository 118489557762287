import { takeEvery, put } from 'redux-saga/effects';

import * as actionTypes from '../../modules/casino/store/actions/actionTypes';
import { getAvatars, getData, clearState } from '../slices/social';

function* fetchAvatars() {
  // @ts-ignore
  yield put(getAvatars());
}

function* fetchSocialData() {
  // @ts-ignore
  yield put(getData());
}

function* clearSocial() {
  yield put(clearState());
}

export default function* watchSocialSaga() {
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, fetchAvatars);
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, fetchSocialData);
  yield takeEvery(actionTypes.authentication.CLEAR, clearSocial);
}
