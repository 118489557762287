import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { useNavigate, useMatches } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { saveCalendarSelection } from '../../../../../modules/bets/store/actions/calendar';
import { prematchFetchSportByDay } from '../../../../../modules/bets/store/actions/prematch';
import { appToggleCompetitions } from '../../../../../modules/bets/store/actions/app';

import './index.scss';

type PrematchCalendar = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Calendar = (componentProps: PrematchCalendar) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const showCompetitions = useAppSelector<any>((state) => state.bets.app.showCompetitions);
  const filterByDate = useAppSelector<any>((state) => state.bets.calendar.filterBy);
  let selectedSport = useAppSelector<any>((state) => state.bets.navSports.prematch);
  const prematchSports = useAppSelector<any>((state) => state.bets.prematch.sportsMeta);
  const liveSports = useAppSelector<any>((state) => state.bets.live.sports);

  const navigate = useNavigate();
  const matches = useMatches();

  if (matches?.length > 0) {
    const match = matches[matches?.length - 1];
    if (match?.params?.idSport) {
      selectedSport = match?.params?.idSport;
    }
  }

  React.useEffect(() => {
    return () => {
      // @ts-ignore
      dispatch(appToggleCompetitions(false));
    };
  }, []);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    let live = false;
    if (e.currentTarget.dataset.live === 'true') {
      live = true;
    } else {
      e.stopPropagation();
    }

    let date = null;
    if (e.currentTarget.dataset.date) {
      date = e.currentTarget.dataset.date;
    }

    if (date != null) {
      dispatch(saveCalendarSelection(date === '0' ? 0 : date));
    }

    if (!live) {
      dispatch(prematchFetchSportByDay({ day: date === '0' ? 0 : date, idSport: selectedSport, noDays: 1 }));
      if (window.location.href.indexOf('pre-match') === -1) {
        if (window.location.href.indexOf('/bets/') > -1) {
          navigate(`/bets/pre-match/${selectedSport}`);
        } else if (window.location.href.indexOf('/winnerfun/') > -1) {
          navigate(`/winnerfun/pre-match/${selectedSport}`);
        }
      }
    }
  };

  const onToggleCompetitions = () => {
    dispatch(appToggleCompetitions());
  };

  const days: any = [];
  for (let i = 0; i < 7; i++) {
    const ts = moment().add(i, 'days').startOf('day').valueOf() + '';
    days.push(ts);
  }

  let items: any = [];

  let firstWeekDay = false;
  Array.isArray(days) &&
    days.forEach((d) => {
      // eslint-disable-next-line
      if (d && filterByDate != null && d.toString() === filterByDate.toString()) firstWeekDay = true;
    });

  if (filterByDate.toString() === '0') firstWeekDay = true;

  if (Array.isArray(days) && firstWeekDay) {
    items = days.map((date) => {
      //console.log("prematch date", date);
      const di = parseInt(date, 10);
      let d;
      if (i18n.language === 'ro') {
        // @ts-ignore
        d = moment(di).locale('ro');
      } else {
        d = moment(di).locale('en');
      }

      let title = d.format('ddd D MMM');
      const diff = moment().startOf('day').diff(d, 'days');
      if (diff > -2) {
        if (diff === 0) title = 'Today';
        if (diff === -1) title = 'Tomorrow';
      }

      return {
        active:
          window.location.href.indexOf('live-match') === -1 &&
          date &&
          filterByDate != null &&
          date.toString() === filterByDate.toString(),
        all: false,
        subtitle: d.format('dddd'),
        title: title,
        formatted: d.format('YYYY-MM-DD'),
        date,
        hot: false,
        onClick,
      };
    });
  } else {
    let genDays = days > 0 ? days : 1;

    let offsetDays = 0;
    if (!firstWeekDay) {
      const now = moment().startOf('day');
      const selectedDay = moment(filterByDate).startOf('day');
      const diffDays = selectedDay.diff(now, 'days');

      if (diffDays > 7) {
        genDays = 7;
        offsetDays = Math.floor(diffDays / 7) * 7;
      }
    }

    let d;
    if (i18n.language === 'ro') {
      if (offsetDays) {
        d = moment().add(offsetDays, 'd').locale('ro');
      } else {
        d = moment().locale('ro');
      }
    } else {
      if (offsetDays) {
        d = moment().add(offsetDays, 'd').locale('en');
      } else {
        d = moment().locale('en');
      }
    }

    for (let i = 0; i < genDays; i++) {
      let title = d.format('ddd D MMM');
      const diff = moment().startOf('day').diff(d, 'days');
      if (diff > -2) {
        if (diff === 0) title = 'Today';
        if (diff === -1) title = 'Tomorrow';
      }

      items.push({
        active:
          window.location.href.indexOf('live-match') === -1 &&
          filterByDate != null &&
          d.valueOf().toString() === filterByDate.toString(),
        all: false,
        subtitle: d.format('dddd'),
        title: title,
        formatted: d.format('YYYY-MM-DD'),
        date: d.startOf('day').valueOf().toString(),
        hot: false,
        onClick,
      });
      d = d.add(1, 'd');
    }
  }

  if (window.config.euroLeagueEnabled === '1') {
    items.unshift({
      euro: true,
    });
  }

  items.unshift({
    live: true,
    date: items[0].date,
    onClick,
  });

  items.unshift({
    active: showCompetitions,
    filter: true,
    date: items[0].date,
    onClick: onToggleCompetitions,
  });

  items.push({
    active: filterByDate != null && '0' === filterByDate.toString(),
    subtitle: '',
    all: true,
    title: t('All days'),
    formatted: '',
    date: 0,
    onClick,
  });

  const contextValue = {
    days: items,
    selectedSport: selectedSport,
    hasPrematchSport: prematchSports?.[selectedSport]?.numberOfEvents > 0,
    hasLiveSport: liveSports?.[selectedSport] != null,
  };

  //console.log('Calendar[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Calendar;
