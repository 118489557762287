import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useMatches } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { loadTournamentsGroups } from '../../../modules/tournaments-missions/store/actions/tournaments_groups';
import { loadLeaderBoard } from '../../../modules/tournaments-missions/store/actions/leader_board';
import {
  loadHistory,
  loadLeaderBoard as loadHistoryLeaderBoard,
  claimPrize,
} from '../../../modules/tournaments-missions/store/actions/history';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { tournamentActivate, tournamentEnroll } from '../../../modules/tournaments-missions/store/actions/tournament';
import { gameIdsToObjects, stringToSlug } from '../../../utils/gameIdsToObjects';
import { getData, getText } from '../wheel/utils';
import StatsGlow from './assets/stats-glowing.svg';
import DefaultAvatar from '../_common/assets/img-avatar-male.png';
import MainEventBus from '../../../utils/evbus';
import fetchGamesInfo from '../../../utils/games-info-api-cache';

import './index.scss';

export interface Group {
  id: number;
  meta: any;
  priority: number;
  type: number;
  url: string;
  name: string;
  currentTournament: any;
  tournaments: any[];
}

interface FetchGroupReturn {
  group?: Group | null;
  loading?: boolean;
  loaded?: boolean;
  error?: Error | null;
}

const transformGroup = (group: Group, lang: string) => {
  const copy = JSON.parse(JSON.stringify(group));

  copy.tournaments &&
    copy.tournaments.forEach((tournament: any, index: number) => {
      const newMetaUI: any = {};

      tournament.meta.ui &&
        Object.keys(tournament.meta.ui).forEach((metaUIKey) => {
          if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].url != null) {
            newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].url;
          } else if (tournament.meta.ui[metaUIKey] != null && tournament.meta.ui[metaUIKey].text != null) {
            if (tournament.meta.ui[metaUIKey].text[lang] != null && tournament.meta.ui[metaUIKey].text[lang]) {
              newMetaUI[metaUIKey] = tournament.meta.ui[metaUIKey].text[lang];
            } else {
              let value = '';

              const keys = Object.keys(tournament.meta.ui[metaUIKey].text);
              for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                if (tournament.meta.ui[metaUIKey].text[k]) {
                  value = tournament.meta.ui[metaUIKey].text[k];
                  break;
                }
              }

              newMetaUI[metaUIKey] = value;
            }
          }
        });

      copy.tournaments[index].meta.ui = newMetaUI;
    });

  const newMetaUI: any = {};

  group.meta.ui &&
    Object.keys(group.meta.ui).forEach((metaUIKey) => {
      if (group.meta.ui[metaUIKey] != null && group.meta.ui[metaUIKey].url != null) {
        newMetaUI[metaUIKey] = group.meta.ui[metaUIKey].url;
      } else if (group.meta.ui[metaUIKey] != null && group.meta.ui[metaUIKey].text != null) {
        if (group.meta.ui[metaUIKey].text[lang] != null && group.meta.ui[metaUIKey].text[lang]) {
          newMetaUI[metaUIKey] = group.meta.ui[metaUIKey].text[lang];
        } else {
          let value = '';

          const keys = Object.keys(group.meta.ui[metaUIKey].text);
          for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (group.meta.ui[metaUIKey].text[k]) {
              value = group.meta.ui[metaUIKey].text[k];
              break;
            }
          }

          newMetaUI[metaUIKey] = value;
        }
      }
    });

  copy.meta.ui = newMetaUI;

  return copy;
};

const filtersToLinks = (items: any[] | null, handleJoinPlayAndGame?: any) => {
  if (!items) return [];
  let links: any[] = [];

  links = items
    .filter((t) => typeof t.idBet === 'undefined')
    .slice(0, 20)
    .map((filter, i) => {
      if (!filter) return null;
      let id = '';

      if (typeof filter.idSport != 'undefined') id = filter.idSport;
      if (typeof filter.idCategory != 'undefined') id += '/' + filter.idCategory;
      if (typeof filter.idTournament != 'undefined') id += '/' + filter.idTournament;

      return {
        name: filter.name,
        url: `/bets/pre-match/${id}`,
      };
    });
  // console.log('debug links', links);
  return links;
};

const emptyArr: any[] = [];
const emptyObj: any = {};

const fetchGroupId = (groupId: string, force = 0, lang = 'en') => {
  const [groupData, setGroupData] = React.useState<FetchGroupReturn>({
    group: null,
    error: null,
    loaded: false,
  });

  const dispatch = useAppDispatch();

  const groups = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.list : emptyArr,
  );
  const groupsLoaded = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.loaded : false,
  );
  const rerenderGroup = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.groups.rerender : 0,
  );

  React.useEffect((): any => {
    if (!groupsLoaded) {
      dispatch(loadTournamentsGroups());
    }
  }, []);

  React.useEffect((): any => {
    if (groupsLoaded && groupId) {
      const group = groups.find((g) => g.url === `/${groupId}` || g.id.toString() === groupId.toString());

      if (!group) {
        setGroupData({ group: null, loaded: groupsLoaded, error: Error('Group Not Found') });
        return;
      }

      const tournaments = [...group.tournaments];
      tournaments.sort((a, b) => {
        if (a && a.start_date && b && b.end_date) {
          return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
        }
        return 0;
      });

      const now = moment().valueOf();
      const activeTournaments = tournaments.filter((t) => {
        if (t && parseInt(t.end_date, 10) > now) return true;
        return false;
      });
      if (!activeTournaments.length) {
        setGroupData({ group: transformGroup(group, lang), loaded: groupsLoaded, error: null });
        return;
      }

      const tournament = activeTournaments[0];

      if (tournament.start_date <= now && now < tournament.end_date) {
        dispatch(loadLeaderBoard({ tournamentId: tournament.id }));
      }
      dispatch(loadHistory({ groupId: group.id }));

      setGroupData({ group: transformGroup(group, lang), loaded: groupsLoaded, error: null });
    }
  }, [groupId, groups, force, groupsLoaded, rerenderGroup]);

  return groupData;
};

type TrounamentGroupProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const TournamentGroup = (componentProps: TrounamentGroupProps) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const emptyImage = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  const matches = useMatches();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [currentTab, setCurrentTab] = React.useState('details');

  const authentication = useAppSelector((state) => state.authentication);
  const ranking: any = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.leaderboard.top : emptyObj,
  );
  const historyTournaments: any = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.history.tournaments : emptyObj,
  );
  const historyLeaderboards: any = useAppSelector((state) =>
    state.tournamentsMissions ? state.tournamentsMissions.history.leaderboards : emptyObj,
  );

  let groupId = props.properties.targetId;

  if (props.properties.targetIdFromPath && props.properties.pathParamKey) {
    if (matches && matches.length) {
      const match = matches[0];
      if (match.params && match.params[props.properties.pathParamKey] != null) {
        groupId = match.params[props.properties.pathParamKey];
      }
    }
  }

  const { group, loaded } = fetchGroupId(groupId, 0, i18n.language);

  const [showHistoryLeaderBoard, setShowHistoryLeaderBoard] = React.useState<{
    open: boolean;
    tournamentId: number | null;
    title?: string | null;
    hasClaimAvailable: boolean;
    error: boolean;
  }>({
    open: false,
    tournamentId: null,
    title: null,
    hasClaimAvailable: false,
    error: false,
  });

  const tournaments = group && group.tournaments ? [...group.tournaments] : [];
  tournaments.sort((a, b) => {
    if (a && a.start_date && b && b.end_date) {
      return parseInt(a.start_date, 10) - parseInt(b.end_date, 10);
    }
    return 0;
  });

  const now = moment().valueOf();
  const activeTournaments = tournaments.filter((t) => {
    if (t && parseInt(t.end_date, 10) > now) return true;
    return false;
  });

  const tournament = activeTournaments.length ? activeTournaments[0] : null;
  // console.log('DEBUG tournament', { tournament, activeTournaments, tournaments, group });

  const {
    data: { data: allTournamentGames },
  } = fetchGamesInfo({
    dsId: window.config.dataSourceAllGames,
    gameIds: tournament?.meta?.games,
    authenticationToken: authentication?.access_token,
  });

  const allTournamentRecommandedGames: any = [];
  if (allTournamentGames?.length) {
    const hashIds: any = {};
    allTournamentGames.forEach((g: any, index: number) => {
      hashIds[g.id] = index;
    });
    tournament?.meta?.recommended.forEach((id: number) => {
      if (hashIds[id.toString()] != null)
        allTournamentRecommandedGames.push(allTournamentGames[hashIds[id.toString()]]);
    });
  }

  const me =
    ranking && tournament && typeof ranking[tournament.id] !== 'undefined' && ranking[tournament.id].me
      ? ranking[tournament.id].me
      : {};

  const players: any[] =
    ranking && tournament && typeof ranking[tournament.id] !== 'undefined' && ranking[tournament.id].top
      ? JSON.parse(JSON.stringify(ranking[tournament.id].top))
      : [];

  let howManyToSlice = 5;
  if (!me || !me.pos) {
    howManyToSlice = 3;
  }
  const lobbyPlayers = players.slice(0, howManyToSlice);

  if (me && me.pos && me.pos > lobbyPlayers.length) {
    const m = JSON.parse(JSON.stringify(me));
    m.outside = true;
    m.posStr = `${players.length}+`;
    m.award = '0 Lei';
    if (lobbyPlayers.length === 5) lobbyPlayers.pop();
    lobbyPlayers.push(m);
  }

  if (players && players.length && me && me.pos) {
    if (players.length < me.pos) {
      const m = JSON.parse(JSON.stringify(me));
      m.outside = true;
      m.posStr = `${players.length}+`;
      m.award = '0 Lei';
      players.push(m);
    }
  }

  const playersList = players.map((p: any) => {
    const image = tournament?.meta?.ui?.avatar_image ?? DefaultAvatar;

    return {
      ...p,
      me: me && me.pos === p.pos,
      avatar: p.avatar && typeof p.avatar === 'string' ? p.avatar : p.avatar && p.avatar.url ? p.avatar.url : image,
    };
  });

  const lobbyPlayersList = lobbyPlayers.map((p: any) => {
    const image = tournament?.meta?.ui?.avatar_image ?? DefaultAvatar;
    return {
      ...p,
      me: me && me.pos === p.pos,
      avatar: p.avatar && typeof p.avatar === 'string' ? p.avatar : p.avatar && p.avatar.url ? p.avatar.url : image,
    };
  });

  const showHistoryRank = (e: React.FormEvent<HTMLDivElement>) => {
    if (e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.id != null) {
      const id = parseInt(e.currentTarget.dataset.id);

      if (historyLeaderboards && historyLeaderboards[id] == null) {
        dispatch(loadHistoryLeaderBoard({ tournamentId: id }));
      }

      let title = '';
      let hasClaimAvailable = false;
      if (historyTournaments && group && historyTournaments[group.id]) {
        const tournament = historyTournaments[group.id].find((t: any) => t.id == id);
        //console.log(tournament);
        title = tournament.name;
        hasClaimAvailable = tournament.me ? tournament.me.claim_available : false;
      }

      setShowHistoryLeaderBoard({
        open: true,
        tournamentId: id,
        title: title,
        hasClaimAvailable: hasClaimAvailable,
        error: false,
      });
    }
  };

  const generateHistoryLeaderboardList = (historyLeaderboard: any) => {
    return (
      historyLeaderboard?.leaderboard?.map((p: any) => {
        const tmp = { ...p };
        const image = tournament?.meta?.ui?.avatar_image ?? DefaultAvatar;
        tmp.avatar = image;

        return tmp;
      }) ?? []
    );
  };

  const checkIfLogin = () => {
    // check if suer is logged in. if not redirect to the login page (with redirect back configured)
    if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
      navigate('/login');
      return true;
    }
    return false;
  };

  const navigateToEvent = () => {
    if (tournament?.meta?.sport_filters?.prematch && tournament?.meta?.sport_filters?.prematch.length > 0) {
      const links = filtersToLinks(tournament.meta.sport_filters.prematch, null);
      if (links.length) {
        navigate(links[0].url);
      }
    }
  };

  const playGame = (gameId?: any) => {
    // launch game identified by id or choose a random one if id is not given
    if (!gameId && tournament.meta && tournament.meta.games) {
      gameId = tournament.meta.games[Math.floor(Math.random() * tournament.meta.games.length)];
    }
    const found = allTournamentGames?.find((g: any) => {
      if (g.id.toString() === gameId.toString()) return true;
      return false;
    });

    if (found) {
      navigate(`/slot-game/${gameId}/${stringToSlug(found.name)}`);
    }
  };

  const handleJoinPlay = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    let id: string | undefined;
    const target = e.currentTarget;
    if (target) id = target.dataset.id;

    if (checkIfLogin()) return;

    if (!tournament.enrolled) {
      dispatch(
        tournamentEnroll({
          tournamentId: tournament.id,
          activate: true,
          cb: () => {
            // TODO: navigate to sport if sport tournament
            if (tournament.type === 3) {
              navigateToEvent();
              return;
            }
            playGame();
          },
        }),
      );
    } else if (!tournament.activated) {
      dispatch(
        tournamentActivate({
          tournamentId: tournament.player_mission_id,
          cb: () => {
            // TODO: navigate to sport
            if (tournament.type === 3) {
              navigateToEvent();
              return;
            }
            playGame();
          },
        }),
      );
    } else {
      // TODO: navigate to sport
      if (tournament.type === 3) {
        navigateToEvent();
        return;
      }
      playGame();
    }
  };

  const handleJoinPlayAndGame = (e: React.MouseEvent<HTMLElement>) => {
    let id: string | undefined;

    const target = e.currentTarget;
    if (target) id = target.dataset.id;
    if (id == null) return;

    e.stopPropagation();
    e.preventDefault();

    if (checkIfLogin()) return;

    if (!tournament.enrolled) {
      dispatch(
        tournamentEnroll({
          tournamentId: tournament.id,
          activate: true,
          cb: () => {
            if (tournament.type === 3) {
              if (id) navigate(id);
              return;
            }
            playGame(id);
          },
        }),
      );
    } else if (!tournament.activated) {
      dispatch(
        tournamentActivate({
          tournamentId: tournament.player_mission_id,
          cb: () => {
            if (tournament.type === 3) {
              if (id) navigate(id);
              return;
            }
            playGame(id);
          },
        }),
      );
    } else {
      if (tournament.type === 3) {
        if (id) navigate(id);
        return;
      }
      playGame(id);
    }
  };

  const getTexts = () => {
    // if (!players || !tournament) return [];
    const topPlayers = playersList ? playersList.slice(0, 3) : [];
    const texts = [];

    if (tournament) {
      // texts.push(tournament.name);

      if (tournament.meta && tournament.meta.ui) {
        texts.push(`${tournament.meta.ui.prize_1} ${tournament.meta.ui.prize_2}`);
      }
    }

    if (Array.isArray(topPlayers)) {
      for (let i = 0; i < topPlayers.length; i++) {
        if (topPlayers.length > i && topPlayers[i] && topPlayers[i].award) {
          texts.push(`${t('Position')} #${topPlayers[i].pos}: ${topPlayers[i].award}`);
        }
      }
    }

    return texts;
  };

  const getHistoryLeaderboardList = () => {
    const list: any = {
      allHistoryTournaments: [],
      enrolledTournaments: [],
      missedTournaments: [],
    };

    if (
      group &&
      historyTournaments &&
      typeof historyTournaments[group.id] !== 'undefined' &&
      Array.isArray(historyTournaments[group.id]) &&
      historyTournaments[group.id].length
    ) {
      const allHistoryTournaments = historyTournaments[group.id].map((tr: any) => {
        const dateString = `${moment(tr.start_date).format('D MMM')} - ${moment(tr.end_date).format('D MMM')}`;
        let games_image = getData(tr, 'meta.ui.games_image.url', emptyImage);
        let top_image = getData(tr, 'meta.ui.top_image.url', emptyImage);

        games_image = games_image.replace('https://t5a4q7k3.stackpathcdn.com', 'https://micros-t.b-cdn.net');
        top_image = top_image.replace('https://t5a4q7k3.stackpathcdn.com', 'https://micros-t.b-cdn.net');

        const prize1 = getText(tr, `meta.ui.prize_1.text.${i18n.language}`, '');
        const prize2 = getText(tr, `meta.ui.prize_2.text.${i18n.language}`, '');

        let position = null;
        let award = '';
        let hasAward = false;
        let hasClaimAvailable = false;
        if (tr.me && tr.me.pos && !tr.me.disqualified) {
          position = tr.me.pos;
          hasAward = tr.me.award ? true : false;
          hasClaimAvailable = tr.me.claim_available;
          award = tr.me.award;
        }

        return {
          id: tr.id,
          name: tr.name,
          dateString: dateString,
          startDate: tr.start_date,
          endDate: tr.end_date,
          gamesImage: games_image,
          position: position,
          topImage: top_image,
          hasAward,
          award,
          hasClaimAvailable,
          showHistoryRank: showHistoryRank,
          prize1,
          prize2,
        };
      });
      list.allHistoryTournaments = allHistoryTournaments;
      list.enrolledTournaments = allHistoryTournaments.filter((t: any) => t.position != null);
      list.missedTournaments = allHistoryTournaments.filter((t: any) => t.position == null);

      return list;
    } else {
      return list;
    }
  };

  const contextValue = {
    currentTab: currentTab,
    id: group ? group.id : null,
    priority: group ? group.type : null,
    hasGroup: !!group,
    groupLoaded: loaded,
    metaUI: {
      element_type: 'internal',
      data: group ? group.meta.ui : null,
    },
    url: group ? group.url : null,
    name: group ? group.name : null,
    currentTournament: {
      element_type: 'internal',
      data: tournament
        ? {
            ...tournament,
            meExists: me.pos != null,
            mePos: me.pos != null ? me.pos : '?',
            meScore: me.score != null ? me.score : '',
            meAward: me.award != null ? me.award : '',
            meNickname: me.nickname != null ? me.nickname : '',
            meta: tournament.meta
              ? {
                  ...tournament.meta,
                  games:
                    tournament.meta &&
                    tournament.meta.games &&
                    gameIdsToObjects(allTournamentGames, tournament.meta.games, handleJoinPlayAndGame),
                  recommended:
                    tournament.meta &&
                    tournament.meta.recommended &&
                    gameIdsToObjects(allTournamentRecommandedGames, tournament.meta.recommended, handleJoinPlayAndGame),
                  links:
                    (tournament?.meta?.sport_filters?.prematch &&
                      tournament?.meta?.sport_filters?.prematch.length > 0 &&
                      filtersToLinks(tournament.meta.sport_filters.prematch, handleJoinPlayAndGame)) ??
                    [],
                }
              : {},
          }
        : null,
    },
    tournaments: activeTournaments,
    activeTournaments: activeTournaments
      ? activeTournaments.map((tr) => {
          if (tr.end_date < now) return null;
          let activeNow = false;
          if (tr.start_date <= now && now <= tr.end_date) activeNow = true;

          const dateString = `${moment(tr.start_date).format('D MMM')} - ${moment(tr.end_date).format('D MMM')}`;
          const prize1 = getText(tr, 'meta.ui.prize_1', '');
          const prize2 = getText(tr, 'meta.ui.prize_2', '');
          const games_image = getData(tr, 'meta.ui.games_image', emptyImage);
          const gamesTotal = tr.meta && tr.meta.games && tr.meta.games.length ? tr.meta.games.length : 0;
          const top_image = getData(tr, 'meta.ui.top_image', emptyImage);
          const scores_image = getData(tr, 'meta.ui.scores_image', emptyImage);
          const levels = getData(tr, 'meta.levels', 0);
          const statsImage = getData(tr, 'meta.ui.stats_image', StatsGlow);

          return {
            activeNow: activeNow,
            dateString: dateString,
            startDate: tr.start_date,
            endDate: tr.end_date,
            gamesImage: games_image,
            gamesTotal: gamesTotal,
            prize1: prize1,
            prize2: prize2,
            topImage: top_image,
            levels: levels,
            scoresImage: scores_image,
            statsImage,
            name: tr.name,
          };
        })
      : [],
    historyTournaments: getHistoryLeaderboardList().allHistoryTournaments,
    historyEnrolledTournaments: getHistoryLeaderboardList().enrolledTournaments,
    historyNotEnrolledTournaments: getHistoryLeaderboardList().missedTournaments,

    players: playersList,
    lobbyPlayers: lobbyPlayersList,
    handleJoinPlay: handleJoinPlay,
    handleJoinPlayAndGame: handleJoinPlayAndGame,
    showCalendar: () => {
      MainEventBus.emit('TAB-CHANGE', 'calendar');
    },
    onTabChange: (selectedLayout: string) => {
      setCurrentTab(selectedLayout);
      if (selectedLayout === 'ranking' && tournament) {
        dispatch(loadLeaderBoard({ tournamentId: tournament.id }));
      }
    },
    showHistoryLeaderBoard: showHistoryLeaderBoard.open,
    onCloseHistoryLeaderBoard: () => {
      setShowHistoryLeaderBoard({
        open: false,
        tournamentId: null,
        title: null,
        hasClaimAvailable: false,
        error: false,
      });
    },
    historyLeaderboardList:
      showHistoryLeaderBoard.tournamentId &&
      historyLeaderboards &&
      typeof historyLeaderboards[showHistoryLeaderBoard.tournamentId] !== 'undefined'
        ? generateHistoryLeaderboardList(historyLeaderboards[showHistoryLeaderBoard.tournamentId])
        : [],
    historyLeaderboardTitle: showHistoryLeaderBoard.title,
    historyLeaderboardHasClaim: showHistoryLeaderBoard.hasClaimAvailable,
    historyLeaderboardHasError: showHistoryLeaderBoard.error,
    handleClaimAward: () => {
      dispatch(
        claimPrize({
          tournamentId: showHistoryLeaderBoard.tournamentId,
          groupId: group?.id,
          cb: (success: boolean) => {
            if (!success) setShowHistoryLeaderBoard((v) => ({ ...v, error: true }));
          },
        }),
      );
    },

    scrollingTexts: getTexts(),
  };

  if (contextValue.id == null) return null;

  // console.log('TournamentGroup[contextValue]', { contextValue, historyLeaderboards, group, ranking, tournament });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default TournamentGroup;
