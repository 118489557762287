import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import PhoneNumberValidator from '../../../modules/casino/utils/PhoneNumberValidator';
import { checkPhone, checkPhoneCode, resetPhoneCheck } from '../../../modules/casino/store/actions/profile';
import ResponseErrorMessages from '../../../modules/casino/store/errors/ResponseErrorMessages';

import './index.scss';

type EditPhoneNumberProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  isOpen: boolean;
  currentStep: string;
  newPhoneNumber: string;
  smsCode: string;
  validPhoneNumber: boolean;
  validSmsCode: boolean;
  validationMessagePhone: string | JSX.Element;
  validationMessageSms: string | JSX.Element;
  phoneCode: string;
  counter: number;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const EditPhoneNumber = (componentProps: EditPhoneNumberProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const timerId = React.useRef(0);

  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.profile);

  const initialState = {
    isOpen: false,
    currentStep: 'step1',
    newPhoneNumber: '',

    smsCode: '',
    validPhoneNumber: false,
    validSmsCode: false,
    validationMessagePhone: '',
    validationMessageSms: '',
    phoneCode: '',
    counter: 10, // todo: change to 60
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  const checkValidSMSCode = (val: string) => {
    // remove empty spaces:
    const value = val.replace(/\s/g, '');
    // check if it has at least 4 chars:
    if (value && value.length >= 4) {
      return true;
    } else {
      return false;
    }
  };

  const [runReset, setRunReset] = React.useState(false);

  const resetCounter = () => {
    if (state.currentStep !== 'step2' || !state.isOpen || state.counter <= 1) {
      return;
    }

    if (state.currentStep === 'step2' && state.counter > 0) {
      timerId.current = window.setInterval(() => {
        setState((s) => {
          if (s.counter <= 1) {
            clearInterval(timerId.current);
          }
          return {
            ...s,
            counter: s.counter - 1,
          };
        });
      }, 1000);
    }

    if (state.counter <= 1) {
      setState((s) => ({
        ...s,
        counter: 0,
        currentStep: 'step2',
      }));
    }
  };

  const onResendCode = (e: React.MouseEvent<HTMLElement>) => {
    dispatch(checkPhone(('00' + state.phoneCode + state.newPhoneNumber).replace(/\s/g, '')));
    setState((v) => ({
      ...v,
      counter: 10, // todo: change to 60
      currentStep: 'step2',
    }));
    setRunReset((r) => !r);
  };

  const contextValue = {
    ...state,
    loading: profile.loading,
    onChangeNewPhoneNumber: (e: React.FormEvent<HTMLInputElement>) => {
      if (contextValue.validationMessagePhone !== '') {
        dispatch(resetPhoneCheck());
      }
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      let phoneCode = '';
      if (e.currentTarget?.dataset?.country) {
        phoneCode = e.currentTarget?.dataset?.country;
      }
      const phone = value.replace(/\s/g, '');

      let validPhoneNumber = false;
      if (PhoneNumberValidator.validate(phone)) {
        validPhoneNumber = true;
      }

      setState((v) => ({
        ...v,
        newPhoneNumber: value,
        validPhoneNumber: validPhoneNumber,
        phoneCode: phoneCode,
      }));
    },

    onChangeSmsCode: (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.target ? (e.target as HTMLInputElement).value : '';
      dispatch(resetPhoneCheck());
      setState((v) => ({
        ...v,
        smsCode: value,
        validSmsCode: checkValidSMSCode(value),
      }));
    },

    onNextStep: (e: React.MouseEvent<HTMLElement>) => {
      if (state.currentStep === 'step1') {
        dispatch(checkPhone(('00' + state.phoneCode + state.newPhoneNumber).replace(/\s/g, '')));
        resetCounter();
      }
    },
    onValidateSmsCode: (e: React.MouseEvent<HTMLElement>) => {
      if (state.currentStep === 'step2') {
        dispatch(checkPhoneCode(state.newPhoneNumber, state.smsCode));
      }
    },
    onResetForm: (e: React.MouseEvent<HTMLElement>) => {
      dispatch(resetPhoneCheck());
      setState({
        ...initialState,
      });
    },
    onToggle: (e: React.MouseEvent<HTMLElement>) => {
      if (state.isOpen) dispatch(resetPhoneCheck());
      setState((v) => ({
        ...initialState,
        isOpen: !v.isOpen,
      }));
    },

    onResendCode,
  };

  React.useEffect(() => {
    if (state.currentStep !== 'step2' || !state.isOpen || state.counter <= 1) {
      return;
    }
    resetCounter();
  }, [state.currentStep, runReset]);

  React.useEffect(() => {
    if (Array.isArray(profile.receivedPhone) && profile.receivedPhone.length === 0 && state.currentStep === 'step1') {
      contextValue.validationMessagePhone = 'Phone number is valid';
      setState((v) => {
        if (v.currentStep === 'step1') {
          return { ...v, currentStep: 'step2' };
        }
        return v;
      });
    }
    if (
      Array.isArray(profile.receivedPhoneCode) &&
      profile.receivedPhoneCode.length === 0 &&
      state.currentStep === 'step2' &&
      state.isOpen
    ) {
      contextValue.validationMessageSms = 'Phone number is valid';

      dispatch(resetPhoneCheck());
      setState((v) => ({
        ...initialState,
        isOpen: false,
      }));
    }
  }, [state.currentStep, state.isOpen, profile.receivedPhone, profile.receivedPhoneCode]);

  if (
    profile.receivedPhone &&
    !(Array.isArray(profile.receivedPhone) && profile.receivedPhone.length === 0 && state.currentStep === 'step1')
  ) {
    contextValue.validationMessagePhone = ResponseErrorMessages.get(profile.receivedPhone.ResponseCode);
  }

  if (
    profile.receivedPhoneCode &&
    !(
      Array.isArray(profile.receivedPhoneCode) &&
      profile.receivedPhoneCode.length === 0 &&
      state.currentStep === 'step2' &&
      state.isOpen
    )
  ) {
    contextValue.validationMessageSms = ResponseErrorMessages.get(profile.receivedPhoneCode.ResponseCode);
  }

  // console.log('debug change phone no: ', { contextValue, profile, state });

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default EditPhoneNumber;
