import { ticketConstants } from './constants';

export const ticketOpenedListRequest = () => ({
  type: ticketConstants.OPENED_LIST_REQUEST,
});
export const ticketSettledListRequest = (data) => ({
  type: ticketConstants.SETTLED_LIST_REQUEST,
  data,
});
export const ticketReservedListRequest = () => ({
  type: ticketConstants.RESERVED_LIST_REQUEST,
});

export const ticketOpenedListReceived = (data) => ({
  type: ticketConstants.OPENED_LIST_RECEIVED,
  data,
});

export const ticketSettledListReceived = (data) => ({
  type: ticketConstants.SETTLED_LIST_RECEIVED,
  data,
});

export const ticketReservedListReceived = (data) => ({
  type: ticketConstants.RESERVED_LIST_RECEIVED,
  data,
});

export const ticketListError = (error) => ({
  type: ticketConstants.LIST_ERROR,
  error,
});

export const ticketListRequesting = (data) => ({
  type: ticketConstants.REQUESTING_DATA,
  data,
});

export const ticketCancelRequest = (ticketId) => ({
  type: ticketConstants.CANCEL_REQUEST,
  ticketId,
});

export const ticketCancelSuccess = () => ({
  type: ticketConstants.CANCEL_SUCCESS,
});

export const ticketCancelError = (error) => ({
  type: ticketConstants.CANCEL_ERROR,
  error,
});

export const ticketStoreOpen = (data) => ({
  type: ticketConstants.STORE_OPEN,
  data,
});

export const ticketStoreReserved = (data) => ({
  type: ticketConstants.STORE_RESERVED,
  data,
});

export const ticketStoreRecentlyWon = (ticket) => ({
  type: ticketConstants.STORE_RECENTLY_WON,
  ticket,
});

export const ticketDrawFinished = (event) => ({
  type: ticketConstants.DRAW_FINISHED,
  event,
});

export const ticketDrawsFinished = (events) => ({
  type: ticketConstants.DRAWS_FINISHED,
  events,
});

export const ticketCheckCode = (code) => ({
  type: ticketConstants.CHECK_CODE,
  code,
});

export const ticketCheckCodePending = () => ({
  type: ticketConstants.CHECK_CODE_PENDING,
});

export const ticketCheckCodeData = (data, code) => ({
  type: ticketConstants.CHECK_CODE_DATA,
  data,
  code,
});

export const ticketCheckCodeError = (error) => ({
  type: ticketConstants.CHECK_CODE_ERROR,
  error,
});

export const ticketCheckCodeClose = () => ({
  type: ticketConstants.CHECK_CODE_CLOSE,
});
