import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch, RootState } from '../../../../../store';
import { DataElementContext } from '../../../../../page-components/common/DataElementContext';

import { digitainConfig } from '../../../../../modules/bets/api/config/digitain';
import DefaultCrest from '../../../../../modules/bets/assets/default-crest.svg';

import {
  betsSlipClear,
  betsSlipClearCreateStatus,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betsSlipComputeWin,
  betSlipSetFreeBetIndex,
  betSlipFreeBetClear,
} from '../../../../../modules/bets/store/actions/betsSlip';

import { formatCurrency, formatAmount2 } from '../../../../../modules/bets/utils/formatters';

import { formatBetTitle, formatOddName } from '../../utils/functions';

import { prematchCreateTicketRequest } from '../../../../../modules/bets/store/actions/prematch';
import { liveCreateTicketRequest } from '../../../../../modules/bets/store/actions/live';

import { twoDecimalsFloat } from '../../../../../modules/bets/utils';

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const crests = (name: string, match: any, teamNo: number) => {
  if (match && match.provider === 'digitain') {
    if (match[`team${teamNo}Logo`]) {
      return `${digitainConfig().apiURL}/team-logo2/${match[`team${teamNo}Logo`]}`;
    }
  }

  return DefaultCrest;
};

const BetLine = (props: any) => {
  const classes: any = {};

  const {
    bets,
    matches,
    bet,
    clearBet,
    clearCreateStatus,
    amount,
    winAmount,
    stakeInc,
    stakeDec,
    stakeSet,
    onToggle,
    computeWin,
    liveCreateTicket,
    prematchCreateTicket,
    winnerPlus,
    wallet,
    auth,
    walletBonusEvaluate,
    currentTicket,
    isWinnerFun,
    winnerFunEvaluate,

    liveBets,
    prematchBets,
    ticketType,

    freeBets,
    selectedFreeBet,
    betSlipFreeBetClear,
    placeTicketEnabled,
    i18n,
  } = props;

  //console.log("quickbet props", props);

  const [stakeAmount, setStakeAmount] = React.useState(amount);
  const [open, setOpen] = React.useState(false);
  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    setStakeAmount(amount);
  }, [amount]);

  useEffect(() => {
    computeWin(matches);
  }, [computeWin, matches]);

  if (!matches) return null;

  let match = matches[bet.idMatch];

  if (!match) return null;

  // check for period
  if (bet.match.idMatch !== bet.idMatch) {
    if (Array.isArray(match.periods)) {
      for (const p of match.periods) {
        if (p.idMatch === bet.match.idMatch) {
          match = p;
          break;
        }
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePlaceBetClose = (type = 0) => {
    setOpenPlaceBet(false);
    if (type === 1) {
      window.location.hash = '#tickets-details';
    }
    if (type !== 2) {
      clearBet();
    } else {
      clearCreateStatus();
    }
  };

  const handleYesClose = () => {
    setOpen(false);
    clearBet();
  };

  let intervalLetter = '';
  let intervalTime = '';
  let intervalNumber = '';

  if (match && match.mType === 'live') {
    intervalLetter = 'R';
    intervalTime = '';
    intervalNumber =
      match && match.currentStatus && match.currentStatus.setScoreDetails
        ? match.currentStatus.setScoreDetails.length
        : 0;

    if (match.currentStatus.status === 'PAUSED') {
      intervalLetter = 'P';
      intervalNumber = '';
    } else {
      if (match.idSport !== '1' && match.idSport !== '2') {
        intervalLetter = 'S';
      } else {
        intervalTime = match.currentStatus && match.currentStatus.matchtime ? match.currentStatus.matchtime + "'" : '';
      }
    }
  }

  const matchBets = match && match.matchBets ? [...match.matchBets] : [];
  let oddSelectedIndex = -1;
  let betSelected: any = {};

  if (bet && bet.idMbo !== '') {
    for (let i = 0; i < matchBets.length; i++) {
      const bs = matchBets[i].mbOutcomes.filter((bo: any) => bo.idMbo === bet.idMbo);
      if (bs.length > 0) {
        oddSelectedIndex = i;
        betSelected = bs[0];
        break;
      }
    }
  }

  const onStakeInc = () => {
    if (selectedFreeBet !== -1) {
      betSlipFreeBetClear();
    }
    stakeInc(-1);
  };

  const onStakeDec = () => {
    if (selectedFreeBet !== -1) {
      betSlipFreeBetClear();
    }
    stakeDec(-1);
  };
  const handleStakeChange = (e: any) => {
    let ev = e.target.value;
    if (selectedFreeBet !== -1) {
      betSlipFreeBetClear();
    }

    // let them input an empty string
    if (ev === '') {
      setStakeAmount('');
      stakeSet('');
      return;
    }

    // don't let them put an amount < 1
    if (ev === '0') {
      return;
    }

    // convert , to .
    ev = ev.split(',').join('.');

    // only one .
    const sev = ev.split('.');
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      return;
    }

    // only valid numbers
    if (isNaN(+ev)) {
      return;
    }

    let v: any = '';

    if (ev !== '') {
      // amounts ending with . have the same value
      if (ev.endsWith('.')) {
        setStakeAmount(ev);
        return;
      }

      // convert amount
      v = parseFloat(ev);
      if (isNaN(v)) return;
    }

    // update amount
    setStakeAmount(ev);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  const createTicket = () => {
    //console.log("create ticket");
    setOpenPlaceBet(true);

    let free_bet_code = null;
    let free_bet_name = null;
    let free_bet_pid = null;
    let free_bet_redeemable = 0;
    if (selectedFreeBet > -1) {
      const freeBet = freeBets[selectedFreeBet];
      free_bet_code = freeBet.code;
      free_bet_name = freeBet.name;
      free_bet_pid = freeBet.fbid;
      free_bet_redeemable = freeBet.redeemable;
    }

    liveCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    // prematchCreateTicket({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
    betSlipFreeBetClear();
  };

  const handleLogin = () => {
    navigate('/login');
  };

  let loggedIn = false;
  if (auth && ['user', 'token'].indexOf(auth.auth_type) > -1) {
    loggedIn = true;
  }

  const logoTeam1 = match && match.idSport === '1' ? crests(match.team1Name, match, 1) : '';
  const logoTeam2 = match && match.idSport === '1' ? crests(match.team2Name, match, 2) : '';

  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  if (isWinnerFun) {
    bLimit = wallet.total;
    valid = bLimit >= amount;
    if (winnerFunEvaluate && winnerFunEvaluate.success && winnerFunEvaluate.data) {
      if (winnerFunEvaluate.data.valid) {
        valid = bLimit >= amount;
      } else {
        valid = winnerFunEvaluate.data.valid;
      }
    }
  } else if (
    window.config.useBonusEvaluation === '1' &&
    walletBonusEvaluate &&
    walletBonusEvaluate.success &&
    walletBonusEvaluate.data
  ) {
    valid = walletBonusEvaluate.data.valid;
  }

  //console.log('QuickBet[betSelected]', bet);

  let marketName = formatBetTitle(matchBets[oddSelectedIndex], match, bets, i18n.language);
  let oddName = formatOddName(
    matchBets[oddSelectedIndex] ? matchBets[oddSelectedIndex].idBet : '',
    betSelected,
    match,
    bets,
    i18n.language,
  );
  let oddValue = betSelected.mboOddValue;
  if (bet?.betType === 'betBuilder') {
    marketName = 'Combined Odds';
    oddName = '';
    oddValue = bet?.odd;
  }

  let onlineTicketDisabled = false;
  if (selectedFreeBet === -1 && !(bLimit !== 0 && bLimit >= amount)) {
    onlineTicketDisabled = true;
  } else if (!placeTicketEnabled) {
    onlineTicketDisabled = true;
  } else if (amount < 2) {
    onlineTicketDisabled = true;
  } else if (isWinnerFun && !valid) {
    onlineTicketDisabled = true;
  } else {
    let hasBetBuilderBets = false;

    liveBets.forEach((b: any) => {
      if (b.betType === 'betBuilder') {
        hasBetBuilderBets = true;
      }
    });
    prematchBets.forEach((b: any) => {
      if (b.betType === 'betBuilder') {
        hasBetBuilderBets = true;
      }
    });

    if (hasBetBuilderBets && ticketType === 'system') {
      onlineTicketDisabled = true;
    }
  }

  const contextValue = {
    hasInterval: Number(intervalNumber) > 0 && typeof intervalTime !== 'undefined',
    intervalNumber,
    intervalLetter,
    intervalTime,
    logoTeam1,
    team1Name: match.team1Name,
    logoTeam2,
    team2Name: match.team2Name,
    handleDelete: (e: any) => {
      clearBet();
      e.stopPropagation();
    },
    marketName: marketName,
    oddName: oddName,
    oddValue: oddValue,
    onStakeDec,
    onStakeInc,
    stakeAmount,
    handleStakeChange,
    walletCurremcy: isWinnerFun ? 'W' : formatCurrency(wallet.currency),
    freeBetName: selectedFreeBet !== -1 && freeBets[selectedFreeBet] ? freeBets[selectedFreeBet].name : '',
    loggedIn,
    canPlaceTicket: !onlineTicketDisabled,
    createTicket,
    maxWinning: formatAmount2(
      isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data ? winnerFunEvaluate.data.maxWinAmount : winAmount,
    ),
    selectedFreeBet,

    openDelete: open,
    handleDeleteClose: handleClose,
    handleDeleteYesClose: handleYesClose,

    openPlaceBet,
    handlePlaceBetClose,
    currentTicket: JSON.parse(JSON.stringify(currentTicket)),
  };

  //console.log('QuickBet[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{props.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );

  /*
  return (
    <div className={classes.root}>
      <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
        <div
          className={`${classes.infoWrapper} d-flex flex-row flex-nowrap flex-grow-1`}
          onClick={onToggle}
        >
          {intervalNumber > 0 && typeof intervalTime !== 'undefined' && (
            <React.Fragment>
              <div className={classes.round}>
                {intervalLetter}
                {intervalNumber}
              </div>
              <div className={classes.time}>{intervalTime}</div>
            </React.Fragment>
          )}
          {logoTeam1 !== '' && (
            <div className={classes.teamFlag}>
              <img src={logoTeam1} alt="" />
            </div>
          )}
          <div className={classes.teamName}>{match.team1Name}</div>
          <div className={classes.divider}>:</div>
          {logoTeam2 !== '' && (
            <div className={classes.teamFlag}>
              <img src={logoTeam2} alt="" />
            </div>
          )}
          <div className={classes.teamName}>{match.team2Name}</div>
        </div>
        <div>
          <div className={classes.p0} onClick={handleClickOpen}>
            <img src={Delete} alt="" />
          </div>
        </div>
      </div>
      <div
        className={`${classes.betHolder} d-flex flex-row flex-nowrap align-items-center justify-content-between`}
      >
        <div className={`${classes.betName} flex-grow-1`}>
          {formatBetTitle(matchBets[oddSelectedIndex], match, bets)}
        </div>
        <div
          className={`${classes.betWrapper} d-flex flex-row flex-nowrap align-items-center  justify-content-between`}
        >
          <div className={classes.betType}>
            {formatOddName(
              matchBets[oddSelectedIndex] ? matchBets[oddSelectedIndex].idBet : '',
              betSelected,
              match,
              bets
            )}
          </div>
          <div className={classes.betValue}>{betSelected.mboOddValue}</div>
        </div>
      </div>
      <div className={classes.hr}></div>
      <div
        className={'{classes.payHolder} d-flex flex-row flex-nowrap align-items-center justify-content-between'}
      >
        <div className={classes.mr5}>
          <div className={`${classes.payAmount} d-flex flex-row flex-nowrap align-items-center`}>
            <BounceButton>
              <ButtonBase onClick={onStakeDec} variant="contained" className={classes.but}>
                -
              </ButtonBase>
            </BounceButton>
            <InputBase
              type="text"
              onChange={handleStakeChange}
              className={`${classes.amount} ${amount < 2 ? classes.warning : ''}`}
              value={stakeAmount}
              inputProps={{
                className: classes.amountInput,
                'aria-label': 'stake',
                pattern: '[0-9\.]*'
              }}
              endAdornment={
                <InputAdornment
                  className={`${classes.amountEnd} ${amount < 2 ? classes.warning : ''}`}
                  position="end"
                >
                  {isWinnerFun ? 'W' : formatCurrency(wallet.currency)}
                </InputAdornment>
              }
            />
            <BounceButton>
              <ButtonBase onClick={onStakeInc} variant="contained" className={classes.but}>
                +
              </ButtonBase>
            </BounceButton>
          </div>
          {selectedFreeBet !== -1 && (freeBets[selectedFreeBet] && freeBets[selectedFreeBet].name) && <div className={classes.freeBetText}>
            {freeBets[selectedFreeBet].name}
          </div>}
        </div>
        <div className={classes.payButtonHolder}>
          {loggedIn && (
            <ButtonBase
              variant="contained"
              className={`${classes.payButton} ${winnerPlus || !valid ? classes.payButtonDisabled : ''}`}
              onClick={createTicket}
              disabled={winnerPlus || !valid}
            >
              <div>
                <div className={classes.buttonTitle}>{t('PLACE TICKET')}</div>
                <div className={classes.buttonSubtitle}>
                  {t('Potential winning')} {formatAmount2(isWinnerFun && winnerFunEvaluate && winnerFunEvaluate.data ? winnerFunEvaluate.data.maxWinAmount : winAmount)} {isWinnerFun ? 'W' : 'lei'}
                </div>
              </div>
            </ButtonBase>
          )}
          {!loggedIn && (
            <ButtonBase
              variant="contained"
              className={`${classes.loginButton}`}
              onClick={handleLogin}
            >
              <div>
                <div className={classes.buttonTitle}>
                  <img src={avatar} alt="" className={classes.avatar} /> {t('ENTER YOUR ACCOUNT')}
                </div>
              </div>
            </ButtonBase>
          )}
        </div>
      </div>
      <DeleteModal open={open} handleClose={handleClose} handleYesClose={handleYesClose} />
      <TicketModal openPlaceBet={openPlaceBet} handlePlaceBetClose={handlePlaceBetClose} currentTicket={JSON.parse(JSON.stringify(currentTicket))} />
    </div>
  );
  */
};

const mapStateToProps = (state: RootState, props: any) => {
  let mType, bet, bets, matches;

  const bst = state.bets;
  const ct = bst.betsSlip.tickets[bst.betsSlip.currentTicket];

  if (ct.live.selected.length > 0) {
    mType = 'live';
    bet = ct.live.selected[0];
    bets = bst.live.bets;
    matches = bst.live.matches;
  }

  if (ct.prematch.selected.length > 0) {
    mType = 'prematch';
    bet = ct.prematch.selected[0];
    bets = bst.prematch.bets;
    matches = bst.prematch.matches;
  }

  const ret = {
    isWinnerFun: bst.app.isWinnerFun,
    wallet: bst.app.isWinnerFun ? state.winnerFun.wallet : state.wallet,
    bet: bet,
    bets: bets,
    matches: matches,
    mType,
    amount: ct.amount,
    winAmount: ct.totalMaxWinAmount,
    winnerPlus: ct.winnerPlus,
    auth: state.authentication,
    walletBonusEvaluate: ct.bonusEvaluate,
    currentTicket: ct,
    winnerFunEvaluate: ct.winnerFunEvaluate,

    freeBets: state.freeBets.freeBets,
    selectedFreeBet: bst.betsSlip.selectedFreeBet,
    placeTicketEnabled: ct.placeTicketEnabled,

    liveBets: ct.live.selected,
    prematchBets: ct.prematch.selected,
    ticketType: ct.ticketType,
  };

  return ret;
};

const actionCreators = {
  clearBet: betsSlipClear,
  clearCreateStatus: betsSlipClearCreateStatus,
  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  computeWin: betsSlipComputeWin,
  liveCreateTicket: liveCreateTicketRequest,
  prematchCreateTicket: prematchCreateTicketRequest,
  betSlipSetFreeBetIndex: betSlipSetFreeBetIndex,
  betSlipFreeBetClear: betSlipFreeBetClear,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(BetLine));
