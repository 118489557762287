import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { uniqueId } from 'lodash-es';

const calculateStateFromProps = (props) => {
  let { dateTo, dateFrom } = props;
  const currentDate = dateFrom ? new Date(dateFrom) : new Date();
  const targetDate = new Date(dateTo);
  const diff = targetDate - currentDate;

  let year = Math.floor(diff / 31104000000); // time diff in years
  let month = Math.floor((diff / 2592000000) % 12); // time diff in months (modulated to 12)
  let day = Math.floor((diff / 86400000) % 30); // time diff in days (modulated to 30)
  let hour = Math.floor((diff / 3600000) % 24); // time diff's hours (modulated to 24)
  let min = Math.floor((diff / 60000) % 60); // time diff's minutes (modulated to 60)
  let sec = Math.floor((diff / 1000) % 60); // time diff's seconds (modulated to 60)
  let allDays = Math.floor(diff / 86400000);

  if (year < 0) year = 0;
  if (month < 0) month = 0;
  if (day < 0) day = 0;
  if (hour < 0) hour = 0;
  if (min < 0) min = 0;
  if (sec < 0) sec = 0;
  if (allDays < 0) allDays = 0;

  return {
    diff,
    years: year,
    months: month,
    days: day,
    hours: hour,
    minutes: min,
    seconds: sec,
    allDays: allDays,
    dateTo,
    dateFrom,
  };
};

export const defaultProps = {
  className: '',
  styleText: '',
};

const CounterDiv = styled.div((props) => props.$styleText);

export const Counter = (componentProps) => {
  let props = componentProps;

  const [__id] = React.useState(uniqueId('unique-'));
  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  let onClick = null;
  if (props.properties && props.properties.onClick && typeof props.properties.onClick === 'function') {
    onClick = props.properties.onClick;
  } else if (
    componentProps.properties &&
    componentProps.properties.onClick &&
    typeof componentProps.properties.onClick === 'function'
  ) {
    onClick = componentProps.properties.onClick;
  }

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  const dateFrom = props.properties.dateFrom;
  const dateTo = props.properties.dateTo;
  let unit = props.properties.isMilliseconds ? 1 : 1000;
  const counterProps = {};

  if (dateFrom) {
    const parsedNumber = parseInt(dateFrom, 10);
    if (!isNaN(parsedNumber)) {
      counterProps['dateFrom'] = parsedNumber * unit;
    }
  }
  if (dateTo) {
    const parsedNumber = parseInt(dateTo, 10);
    if (!isNaN(parsedNumber)) {
      counterProps['dateTo'] = parsedNumber * unit;
    }
  }

  const [timerProps, setTimerProps] = React.useState(calculateStateFromProps(counterProps));
  const tickId = React.useRef(0);

  const tick = () => {
    const newTimerProps = calculateStateFromProps(counterProps);
    const { diff } = newTimerProps;

    if (!isNaN(diff)) {
      if (diff <= 0) {
        clearInterval(tickId.current);

        if (props.properties.onExpire && typeof props.properties.onExpire === 'function') {
          props.properties.onExpire();
        }
      }
    } else {
      clearInterval(tickId.current);
    }
    setTimerProps(newTimerProps);
  };

  React.useEffect(() => {
    const { diff } = timerProps;

    if (!isNaN(diff)) {
      if (diff > 0) {
        tickId.current = setInterval(tick, 1000);
      } else {
        if (tickId.current) window.clearInterval(tickId.current);

        if (props.properties.onExpire && typeof props.properties.onExpire === 'function') {
          props.properties.onExpire();
        }
      }
    }

    return () => {
      if (tickId.current) window.clearInterval(tickId.current);
    };
  }, []);

  React.useEffect(() => {
    const dateFrom = props.properties.dateFrom;
    const dateTo = props.properties.dateTo;
    let unit = props.properties.isMilliseconds ? 1 : 1000;
    const counterProps = {};

    if (dateFrom) {
      const parsedNumber = parseInt(dateFrom, 10);
      if (!isNaN(parsedNumber)) {
        counterProps['dateFrom'] = parsedNumber * unit;
      }
    }
    if (dateTo) {
      const parsedNumber = parseInt(dateTo, 10);
      if (!isNaN(parsedNumber)) {
        counterProps['dateTo'] = parsedNumber * unit;
      }
    }

    if (timerProps.dateFrom !== counterProps['dateFrom'] || timerProps.dateTo !== counterProps['dateTo']) {
      tickId.current = setInterval(tick, 1000);
      tick();
    }
  }, [props, timerProps]);

  const ctx = React.useMemo(() => {
    let contextValue = {
      ...timerProps,
      isMilliseconds: props.properties.isMilliseconds ? true : false,
      parentContext: dataElementContext,
      __id,
    };
    return contextValue;
  }, [props, dataElementContext, timerProps]);

  if (!isVisible) return null;
  if (!counterProps['dateTo']) return null;

  //console.log('Counter[contextValue]', ctx);

  return (
    <CounterDiv
      className={props.className ?? ''}
      $styleText={props.styleText}
      onClick={onClick}
      style={props.style}
      {...extraDataProps}
    >
      <DataElementContext.Provider value={ctx}>{componentProps.children}</DataElementContext.Provider>
    </CounterDiv>
  );
};
