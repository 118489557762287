import moment from 'moment';

export const generateDateRange = (language: string) => {
  const months: { [id: string]: string } = {
    '1': 'Ian',
    '2': 'Feb',
    '3': 'Mart',
    '4': 'Apr',
    '5': 'Mai',
    '6': 'Iun',
    '7': 'Iul',
    '8': 'Aug',
    '9': 'Sept',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
  };

  const currentDate = moment().locale('en').startOf('day');
  const dateRange = [];

  for (let i = -5; i <= 5; i++) {
    const date = currentDate.clone().add(i, 'days');
    dateRange.push({
      number: date.format('D'),
      title: language === 'ro' ? months[date.format('M')] : date.format('MMM'),
      current: i === 0,
    });
  }

  return dateRange;
};
