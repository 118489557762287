// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';
import { RootState } from '../index';

enum DisplayType {
  COUNTER = 'COUNTER',
  PROGRESS_BAR = 'PROGRESS_BAR',
  NONE = 'NONE',
}

type JackpotAccumulator = {
  is_main: boolean;
  name: string;
  value: number;
  display_type?: DisplayType;
  active_players?: number;
  participating_players?: number;
};

type JackpotMeta = {
  id: string;
  name: string;
  type: number;
  accumulator_states: JackpotAccumulator[];
  state: string;
  state_time: string;
};

type FetchConfigResult = {
  data: {
    rows: JackpotMeta[];
    total: number;
  };
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

type UpdateJackpotAction = {
  jackpot_id: string;
  accumulator_states: JackpotAccumulator[];
  no_win_message: string;
  timestamp: string;
};

export const fetchAllJackpotsMeta = createAsyncThunk<FetchConfigResult, void, FetchConfigError>(
  'jackpoptsMeta/list',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const response = await axios.get(endpoints.jackpotsMeta.list, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        return { data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch jackpots meta list",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface JackpotsMetaState {
  items: JackpotMeta[];
  loading: boolean;
  error: any;
}

export const jackpotsMetaSlice = createSlice({
  name: 'jackpoptsMeta',
  initialState: <JackpotsMetaState>{
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    updateJackpot: (state, action) => {
      const jackpotId = (action.payload as UpdateJackpotAction).jackpot_id;

      const index = state.items.findIndex((el: JackpotMeta) => {
        return el.id === jackpotId;
      });

      if (index > -1) {
        state.items[index].accumulator_states = (action.payload as UpdateJackpotAction).accumulator_states;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllJackpotsMeta.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          state.items = action.payload.data.rows;
        }
        console.log('fetchAllJackpotsMeta.fulfilled', action.payload);
      })
      .addCase(fetchAllJackpotsMeta.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllJackpotsMeta.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        console.log('fetchAllJackpotsMeta.rejected', action.payload);
      });
  },
});

export const { updateJackpot } = jackpotsMetaSlice.actions;

export default jackpotsMetaSlice.reducer;
