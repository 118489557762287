import { filterConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
    matchData: {},
    filterType: '',
    idSport: '',
    selected: {
        prematch: {},
        live: {}
    }
};

const preMatchFilterReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case filterConstants.LOAD: {
                draft.selected.prematch = action.pf || {};
                draft.selected.live = action.lf || {};
                break;
            }
            case filterConstants.SET_SELECTED: {
                draft.selected[draft.filterType][draft.idSport] = action.ids;
                break;
            }
            case filterConstants.UNSELECT: {
                let selected = draft.selected[action.fType][action.idSport];
                if (action.id in selected) {
                    delete selected[action.id];
                }
                break;
            }
            case filterConstants.MATCH_DATA: {
                draft.matchData = action.md;
                draft.filterType = action.fType;
                draft.idSport = action.idSport;
                break;
            }
            default:
                break;
        }
    });

export default preMatchFilterReducer;
