import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import evbus from '../../../../utils/evbus';
import { betsSlipSetBetBuilderBets } from '../../../../modules/bets/store/actions/betsSlip';

//import './index.scss';

type BetBuilderSummaryProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetBuilderSummary = (componentProps: BetBuilderSummaryProps) => {
  let props = componentProps;

  const dispatch = useAppDispatch();

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const betBuilder = useAppSelector((state) => state.bets.betBuilder);
  const bbBetslipCount = useAppSelector((state) => {
    const ct = state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket];

    let bbBetslipCount = 0;
    ct.live.selected.forEach((bet: any) => {
      if (bet?.betType === 'betBuilder') bbBetslipCount++;
    });
    ct.prematch.selected.forEach((bet: any) => {
      if (bet?.betType === 'betBuilder') bbBetslipCount++;
    });

    return bbBetslipCount;
  });
  const [expanded, setExpanded] = React.useState(props.properties?.expanded ?? false);

  React.useEffect(() => {
    if (props.properties?.expanded !== undefined) {
      setExpanded(props.properties?.expanded);
    }
  }, [props.properties?.expanded]);

  const onToggleExpanded = React.useCallback(() => {
    setExpanded((v: boolean) => !v);
  }, []);

  const deleteAllBets = React.useCallback(() => {
    evbus.emit('betBuilderDeleteAllBets', null);
  }, []);

  const deleteBet = React.useCallback((e: any) => {
    const index = e?.currentTarget?.dataset?.index;
    if (index != null) {
      evbus.emit('betBuilderDeleteBet', parseInt(index, 10));
    }
  }, []);

  const handleAddToBetslip = React.useCallback(() => {
    dispatch(
      betsSlipSetBetBuilderBets(betBuilder?.idMatch, betBuilder?.mType, betBuilder?.selected, betBuilder?.bbInfo),
    );
    evbus.emit('betBuilderDeleteAllBets', null);
  }, [betBuilder]);

  const contextValue = React.useMemo(() => {
    const total = betBuilder.selected?.length ?? 0;

    let error: boolean | number = false;
    if (total < 2) {
      error = 1;
    }
    /*
    if (bbBetslipCount >= 6) {
      error = 2;
    }
    */

    return {
      selected: betBuilder.selected?.slice(0, expanded ? total : 2),
      total: total,
      extra: total > 2 ? total - 2 : 0,
      bbInfo: betBuilder.bbInfo,
      open: betBuilder.open,
      expanded,
      onToggleExpanded,
      deleteBet,
      deleteAllBets,
      handleAddToBetslip,
      canPlaceTicket: total >= 2,
      error,
    };
  }, [betBuilder, expanded, onToggleExpanded, deleteBet, deleteAllBets, handleAddToBetslip, bbBetslipCount]);

  //console.log('BetBuilderSummary[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetBuilderSummary;
