const stringToSlug = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

const cdnSimpleImagesUrl = `https://${window.config.lobbyCDNAddress}/winner`;

const providers: any = {
  1: 'egt',
  2: 'amatic',
  3: 'skywind',
  4: 'ctgaming',
  5: 'novomatic',
  6: 'spribe',
  7: 'pragmatic',
  9: 'isoftbet',
};

export const gameThumbnail = (game: any, art_bundle_type: string) => {
  if (game && game.art_bundle && game.art_bundle.data && game.art_bundle.data[art_bundle_type] != null) {
    return game.art_bundle.data[art_bundle_type];
  }
};
