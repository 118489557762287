import React from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '../../../../../store';

import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import evBus from '../../../../../utils/evbus';
import { betsSlipClearLottoTicket } from '../../../../../modules/lotto/store/actions/betsSlip';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const DeleteBets = (componentProps: any) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));

  const dispatch = useAppDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const toggleDeleteDialog = () => {
    setOpenDeleteDialog((v) => !v);
  };
  const noBalls = useAppSelector((state) => {
    return state.lotto.betsSlip.lottoTicket?.numbers?.length ?? 0;
  });

  const contextValue = React.useMemo(() => {
    return {
      totalBets: noBalls,
      openDeleteDialog,
      toggleDeleteDialog,
      handleDeleteButton: () => {
        evBus.emit('CLEAR-LOTTO-BETS', null);
        dispatch(betsSlipClearLottoTicket());
        toggleDeleteDialog();
      },
    };
  }, [openDeleteDialog, toggleDeleteDialog, noBalls]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

// @ts-ignore
export default DeleteBets;
