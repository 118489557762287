import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

import './index.scss';

type ArrowProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Arrow = (componentProps: ArrowProps) => {
  let props = componentProps;

  const tid = React.useRef(0);

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const [changeDir, setChangeDir] = React.useState(
    typeof props.properties.changeDir !== 'undefined' ? props.properties.changeDir : '',
  );

  React.useEffect(() => {
    if (props.properties.changeDir) {
      setChangeDir(props.properties.changeDir);

      if (!props.properties.useAnimationEnd) {
        if (tid.current) window.clearTimeout(tid.current);

        const duration =
          props.properties.duration && !isNaN(parseInt(props.properties.duration))
            ? parseInt(props.properties.duration)
            : 6000;

        tid.current = window.setTimeout(() => {
          setChangeDir('');
        }, duration);
      }
    }
  }, [props.properties.changeDir, props.properties.duration, props.properties.useAnimationEnd]);

  const handleAnimationEnd = React.useCallback((event: React.AnimationEvent<HTMLDivElement>) => {
    if (event.animationName === 'animArrowGreenFrames' || event.animationName === 'animArrowRedFrames') {
      if (event.currentTarget) {
        setChangeDir('');
      }
    }
  }, []);

  const contextValue = {
    direction: changeDir,
    animationEnd: handleAnimationEnd,
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Arrow;
