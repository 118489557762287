import React from 'react';
import styled from 'styled-components';

import MainWheel from '@/modules/wheel/views/main-view';

//import './index.scss';

type TemplateProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Template = (componentProps: TemplateProps) => {
  return <MainWheel />;
};

export default Template;
