import { cashoutConstants } from '../actions/constants';
import { produce } from 'immer';

const initialState = {
  tickets: {},
  initialized: false,
  inProgress: false,
  cashoutResult: null,
  processState: false,
};

const cashoutReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case cashoutConstants.START_PROCESS: {
        draft.processState = true;
        break;
      }
      case cashoutConstants.STOP_PROCESS: {
        draft.processState = false;
        break;
      }
      case cashoutConstants.UPDATES: {
        const tickets = { ...draft.tickets };

        if (action.message && action.message.ticketHash) {
          tickets[action.message.ticketHash] = action.message;
          draft.tickets = tickets;
        }

        draft.processState = false;

        break;
      }
      case cashoutConstants.MULTIPLE_UPDATES: {
        const tickets = { ...draft.tickets };

        action.messages.forEach(message => {
          if (message && message.ticketHash) {
            tickets[message.ticketHash] = message;
          }
        });

        draft.tickets = tickets;
        draft.processState = false;
        break;
      }
      case cashoutConstants.CASHOUT_UPDATE_PROGRESS: {
        draft.inProgress = action.progress;
        if (action.progress) {
          draft.cashoutResult = null;
        }
        break;
      }
      case cashoutConstants.CASHOUT_RESULT: {
        draft.inProgress = false;
        draft.cashoutResult = action.result;
        break;
      }
      case cashoutConstants.CASHOUT_CLEAR_STATE: {
        draft.inProgress = false;
        draft.cashoutResult = null;
        break;
      }
      case cashoutConstants.CASHOUT_CLEAR_TICKETS: {
        draft.tickets = {};
        break;
      }
      default:
      /* noop */
    }
  });

export default cashoutReducer;
