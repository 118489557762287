import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { useParams } from 'react-router-dom';
import ClientAPI from '@/modules/casino/ClientAPI/ClientAPI';

type EmailValidationProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  loading: boolean;
  error: boolean;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const EmailValidation = (componentProps: EmailValidationProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;
  const { token } = useParams();

  const initialState = {
    loading: true,
    error: false,
  };

  const [state, setState] = React.useState<ModuleStateProps>(initialState);

  React.useEffect(() => {
    const axios = ClientAPI.getInstance();
    const request = axios({
      url: '/api/register/token',
      method: 'post',
      data: {
        platformType: window.config.platformType,
        token: token,
      },
    });

    request
      .then((response) => {
        let ok = false;
        // @ts-ignore
        if (response?.status === 'OK') {
          // @ts-ignore
          if (response?.result) {
            // @ts-ignore
            if (!response?.result?.ResponseMessage) {
              // @ts-ignore
              if (!response?.result?.http) {
                ok = true;
              }
            }
          }
        }
        setState({
          loading: false,
          error: !ok,
        });
      })
      .catch((error) => {
        setState({
          loading: false,
          error: true,
        });
      });
  }, []);

  const contextValue = {
    ...state,
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default EmailValidation;
