import React from 'react';
import moment from 'moment';
import { useAppSelector, useAppDispatch } from '../../../../store';

import { betsSlipAddLottoTicket, betsSlipClearLottoTicket } from '../../../../modules/lotto/store/actions/betsSlip';

export const useTicketUpdater = (props: any): any => {
  const { event, selectedBalls, selectedEventsDates, selectedNextEvents, luckyNumber, isDesktop } = props;
  const lottoTicket = useAppSelector((state) => state.lotto.betsSlip.lottoTicket);
  const lottoEvents = useAppSelector((state) => state.lotto.lottoEvents.items);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (event) {
      const selectedNumbers = [];
      for (const key in selectedBalls) {
        if (selectedBalls[key]) {
          selectedNumbers.push(parseInt(key));
        }
      }
      selectedNumbers.sort(function (a, b) {
        return a - b;
      });

      if (selectedNumbers.length) {
        const now = moment().format('YYYY-MM-DD HH:mm:ss');

        let found = false;
        let events = lottoEvents;

        if (event.event_id) {
          events = lottoEvents.filter((e) => {
            if (e.event_id === event.event_id) {
              found = true;
            }
            return found;
          });
        }

        if (!found) {
          events = lottoEvents;
        }
        events = events.filter((e) => e.system_id === event.system_id && event.event_date >= now);

        const selectedEvents = events.filter((e) => selectedEventsDates.indexOf(e.event_date) > -1);
        const originalSelectedEvents = [...selectedEvents];

        if (selectedNextEvents > 0) {
          const nextEvents = events.slice(5);
          for (let i = 0; i < selectedNextEvents; i++) {
            selectedEvents.push(nextEvents[i]);
          }
        }

        const allSelectedEvents = selectedEvents.map((e) => e.event_id);
        const allSelectedEventsFull = [...selectedEvents];

        let maxSystems = event.m;
        if (selectedNumbers.length < event.m) {
          maxSystems = selectedNumbers.length;
        }
        const systems = [maxSystems];

        const ticketData = {
          event: event,
          numbers: selectedNumbers,
          selectedEvents: originalSelectedEvents,
          //systems: systems,
          additionalEvents: selectedNextEvents,
          allSelectedEvents: allSelectedEvents,
          allSelectedEventsFull: allSelectedEventsFull,
          extra: { luckyNumber: luckyNumber ?? 0 },
          src: 'details',
        };

        dispatch(betsSlipAddLottoTicket(ticketData));
      } else {
        dispatch(betsSlipClearLottoTicket());
      }
    }
  }, [event, selectedBalls, selectedEventsDates, selectedNextEvents, luckyNumber, lottoEvents, isDesktop]);
  return lottoTicket;
};
