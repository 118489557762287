import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

type SimpleTabsSwitchProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const SimpleTabsSwitch = (componentProps: SimpleTabsSwitchProps) => {
  let props = componentProps;

  const uriMatches = useMatches();
  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  let valueFromPath: string | undefined = '';
  if (props.properties.targetIdFromPath) {
    if (uriMatches && uriMatches.length) {
      const match = uriMatches[0];

      let pathParamKey = props.properties.pathParamKey ?? 'idMatch';
      if (pathParamKey[0] === ':') pathParamKey = pathParamKey.substr(1);
      if (match.params && pathParamKey && match.params[pathParamKey] != null) {
        valueFromPath = match.params[pathParamKey];
      }
    }
  }

  const startTabIndex = parseInt(props.properties?.startTabIndex ?? 0);
  const [tabIndex, setTabIndex] = React.useState(startTabIndex);

  const onTabChange = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (e.currentTarget.dataset.tabIndex != null) {
      const tabIndex = parseInt(e.currentTarget.dataset.tabIndex, 10) ?? 0;
      setTabIndex(tabIndex);
    }
  }, []);

  const contextValue = React.useMemo(
    () => ({
      valueFromPath,
      tabIndex: tabIndex,
      onTabChange: onTabChange,
    }),
    [tabIndex, onTabChange, valueFromPath],
  );

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default SimpleTabsSwitch;
