import React from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonBase from '@material-ui/core/ButtonBase';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import './rules.scss';
import HeaderIcon from '../assets/dialog-icon.svg';
import EvBusMain from '../../../utils/evbus';

export const TYPE_CUSTOM = 5;
export const STATUS_APPROVED = 8;

const TEST_RULE_FAIL_MARKETING_AGREEMENT = 1003;
const TEST_RULE_FAIL_KYC_VERIFIED = 1004;
const TEST_RULE_FAIL_EMAIL_VERIFIED = 1005;
const TEST_RULE_FAIL_BONUS_CHECK = 1006;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Rules = props => {
  const {
    open,
    onClose,
    status,
    authentication,
    t,
  } = props;

  const navigate = useNavigate();

  const handleClose = () => {
    if (typeof onClose === 'function') onClose(false);
  };

  if (!open) return null;

  const getAmount = () => {
    if (status && status.free_spin && status.free_spin.wager_needed) {
      return status.free_spin.wager_needed;
    }

    return 0;
  };

  const wagerAmount = getAmount();

  let showMarketingError = false;
  let showKYCError = false;
  let showEmailVerifyError = false;
  let showBonusError = false;

  if (status && status.status === 'not_eligible' && status.reason && status.reason.conflicts) {
    if (typeof status.reason.conflicts[TEST_RULE_FAIL_MARKETING_AGREEMENT] !== 'undefined') {
      showMarketingError = true;
    }
    if (typeof status.reason.conflicts[TEST_RULE_FAIL_KYC_VERIFIED] !== 'undefined') {
      showKYCError = true;
    }
    if (typeof status.reason.conflicts[TEST_RULE_FAIL_EMAIL_VERIFIED] !== 'undefined') {
      showEmailVerifyError = true;
    }
    if (typeof status.reason.conflicts[TEST_RULE_FAIL_BONUS_CHECK] !== 'undefined') {
      showBonusError = true;
    }
  }

  const resolve = () => {
    EvBusMain.emit('CLOSE-WHEEL-DIALOG');
    if (typeof onClose === 'function') onClose(false);

    if (showMarketingError) {
      navigate('/profile/settings');
      return;
    }

    if (showKYCError || showEmailVerifyError) {
      navigate('/profile/documents');
      return;
    }

    if (showBonusError) {
      navigate('/profile/wallet');
      return;
    }
  };

  if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) return null;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={'wheel-rules'}
    >
      <DialogTitle className={'dialogTitle'} id="alert-dialog-slide-title">
        <div className={'dialogHeader'}>
          <div>
            <img src={HeaderIcon} className="dh-logo" alt="" />
          </div>
          <div className="dh-text">{t('Necessary conditions')}</div>
          <div className="dh-text muted">{t('To spin the wheel, you must:')}</div>
        </div>
      </DialogTitle>

      <DialogContent className={'dialogContent'}>
        <div className={'dialogBody'}>

          {showMarketingError && <div className={'dg-row warning'}>
            <div className="icon"></div>
            <div className="txt">
              {t('Agree to receive marketing information from us')}
            </div>
          </div>}
          {wagerAmount !== 0 && <div className={'dg-row warning'}>
            <div className="icon"></div>
            <div className="txt">
              {t('Meet the minimum wager requirement: {{AMOUNT}} left', { AMOUNT: wagerAmount })}
            </div>
          </div>}
          {showEmailVerifyError && <div className={'dg-row warning'}>
            <div className="icon"></div>
            <div className="txt">
              {t('Verify your email address')}
            </div>
          </div>}
          {showKYCError && <div className={'dg-row warning'}>
            <div className="icon"></div>
            <div className="txt">
              {t('Verify your identity')}
            </div>
          </div>}
          {showBonusError && <div className={'dg-row warning'}>
            <div className="icon"></div>
            <div className="txt">
              {t('There should not be any active bonuses in your account')}
            </div>
          </div>}

          {(showMarketingError || showKYCError || showEmailVerifyError || showBonusError) && <div className={'d-flex flex-row flex-nowrap align-items-center justify-content-center'}>
            <ButtonBase className={'dialogOK dialogOKOutline'} onClick={resolve}>
              {t('Solve Now')}
            </ButtonBase>
          </div>}
          <div className="db-link" onClick={handleClose}>{t('Close')}</div>
        </div>
      </DialogContent>

    </Dialog>
  );
};

export const noAction = () => {
  return {
    type: 'NO-ACTION',
  };
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
  };
};
const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Rules));



