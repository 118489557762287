// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';

const DEBUG = true;

enum PopupBonusType {
  Bonus = 'bonus',
  Marketing = 'marketing',
  TC = 'tc', // Terms & Conditions
}

export type PopupBonus = {
  id: string;
  priority: number;
  product_id: number;
  bonus_preset_id: number;
  art: string;
  cta_link: string;
  cta_text_label: string; // "{\"text\":{\"ro\":\"IA BONUS\",\"en\":\"\"},\"style\":\"\"}"
  tc_link: string;
  tc_text_label: string; // "{\"text\":{\"ro\":\"T&C\",\"en\":\"\"},\"style\":\"\"}"
  product_cooldown: number;
  item_cooldown: 130;
  display_count_cap: 5;
  timer: 0;
  timer_start: 0;
  rules: {
    en: string;
    ro: string;
  }[];
  type: PopupBonusType;
};

type PopupBonusesProps = {
  tenantId: string;
  siteId: string;
};

type PopupBonusesResult = {
  data: PopupBonus[];
  success: boolean;
};

type PopupBonusesError = {
  rejectValue: {
    error: string;
  };
};

export const fetchPopupBonuses = createAsyncThunk<PopupBonusesResult, PopupBonusesProps, PopupBonusesError>(
  'popupBonuses/list',
  async ({ tenantId, siteId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(endpoints.popupBonuses.list, {
        params: {
          tenantId,
          siteId,
        },
      });

      if (response.data) {
        return { data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch popup bonuses",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface PopupBonusReducer {
  list: PopupBonus[];
  inProgress: boolean;
  loaded: boolean;
  error: any;
}

export const popupBonusesSlice = createSlice({
  name: 'popupBonuses',
  initialState: <PopupBonusReducer>{
    list: [],
    inProgress: false,
    loaded: false,
    error: null,
  },
  reducers: {
    reset: (_state) => {
      /* example */
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPopupBonuses.fulfilled, (state, action) => {
        state.inProgress = false;
        state.loaded = true;
        if (action.payload.success) {
          const list = action.payload.data ?? [];
          list.sort((a, b) => b.priority - a.priority);
          state.list = list;
        }
      })
      .addCase(fetchPopupBonuses.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(fetchPopupBonuses.rejected, (state, action) => {
        state.inProgress = false;
        state.loaded = false;
        state.error = action.payload?.error;
        DEBUG && console.log('fetchPopupBonuses.rejected', action.payload);
      });
  },
});

export const { reset } = popupBonusesSlice.actions;

export default popupBonusesSlice.reducer;
