import React from 'react';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { chooseTextLanguage, styleToObj, choseImage } from "../../utils/functions";

const History = (props) => {
  const { seasons, t, i18n, handleOpen } = props;
  const isDesktop1024 = useMediaQuery("(min-width:1024px)");
  const imageType = isDesktop1024 ? "desktop" : "mobile";

  const buildEvent = (info, key, type, sid) => {
    const sd = moment(parseInt(info.startDate), "x");
    const ed = moment(parseInt(info.endDate), "x");
    const days = ed.diff(sd, "days");
    let event_id = "";
    let season_id = "";
    if (type === "season") {
      season_id = info.id;
    } else {
      event_id = info.id;
    }

    const name = chooseTextLanguage(info.name.text, i18n.language);

    return <div className="wf-content" key={key}>
      <div className="wf-icon">
        <img src={choseImage(info.icon, imageType)} alt="event icon" />
      </div>
      <div className="wf-info">
        <div className="wf-summary">
          <div className="d-flex align-items-start justify-content-between">
            <div className="d-flex flex-column">
              <div className="wf-title" style={styleToObj(info.name.style)}>{name}</div>
              <div className="wf-subtitle">{info.type === "season" ? t("SEASON") : t("COMPETITION")}</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="wf-days d-flex flex-column justify-content-center align-items-center">
                <div className="wf-mt">{days}</div>
                <div>{t("wf.days")}</div>
              </div>
              <div className="wf-period d-flex flex-column justify-content-center align-items-start">
                <div className="wf-start">{sd.format("MMM, D")}</div>
                <div className="wf-end">{ed.format("MMM, D")}</div>
              </div>
            </div>
          </div>
          <div className={`wf-progress wf-ended`}>
            <LinearProgress variant="determinate" value={100} />
          </div>
          <div className={`wf-ranking d-flex align-items-center justify-content-between`}>
            <div className="wf-ranking-left d-flex flex-column justify-content-center align-items-start">
              <div className="wf-label">{t("RANKING")}</div>
              <div className="wf-value" style={styleToObj(info.ranking.style)}>{chooseTextLanguage(info.ranking.text, i18n.language)}</div>
            </div>
            <div className="wf-ranking-right d-flex flex-column justify-content-center align-items-start">
              <div className="wf-label">{t("AWARDS")}</div>
              <div className="wf-value" style={styleToObj(info.awards.style)}>{chooseTextLanguage(info.awards.text, i18n.language)} <span className="wf-currency">Lei</span></div>
            </div>
          </div>
          <div className="d-flex justify-content-end w-100">
            <div className="wf-button" onClick={handleOpen(type, season_id, event_id, name)}>{t("Score")}</div>
          </div>
        </div>
      </div>

    </div>
  };

  const buildEvents = (events, type) => {
    return events.map((event, i) => buildEvent(event, `event-${i}`, type));
  };

  const buildSeasons = (seasons) => {
    const res = [];

    const now = moment().valueOf();

    seasons.forEach((season, i) => {
      const style = {
        backgroundImage: `url("${choseImage(season.headerBG, imageType)}")`
      };

      res.push(<div className="wf-calendar-event" key={`season-${i}`}>
        <div className="wf-calendar-header" style={style}>
          <div className="wf-title" style={styleToObj(season.headerTitle.style)}>
            {chooseTextLanguage(season.headerTitle.text, i18n.language)}
          </div>
          <div className="wf-subtitle" style={styleToObj(season.headerSubtitle.style)}>
            {chooseTextLanguage(season.headerSubtitle.text, i18n.language)}
          </div>
        </div>
        {parseInt(season.endDate) < now && buildEvent(season, "main-event", "season")}
        {buildEvents(season.events, "event")}
      </div>);
    });
    return res;
  };

  return buildSeasons(seasons);
}

export default withTranslation()(History);