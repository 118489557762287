import { walletConstants } from './constants';

export const requestWallet = () => {
  return {
    type: walletConstants.REQUEST_WALLET,
  };
};

export const walletReceived = (data) => {
  return {
    type: walletConstants.RECEIVED_WALLET,
    wallet: data,
  };
};

export const resetWallet = () => {
  return {
    type: walletConstants.RESET_WALLET,
  };
};
