import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';

import { processComponentProps } from '@/page-components/utils/processComponentProps';

import './index.scss';
import CircleWhiteBlur from './assets/circle-white-blur.png';
import CircleYellowBlur from './assets/circle-yellow-blur.png';
import ExplosionWhite from './assets/explosion-white.png';
import ExplosionYellow from './assets/explosion-yellow.png';
import StarGlowWhite from './assets/star-glow-white.png';
import StarGlowYellow from './assets/star-glow-yellow.png';
import StarYellow from './assets/star-yellow.png';

type MomentumProgressAnimationProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const MomentumProgressAnimation = (componentProps: MomentumProgressAnimationProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const styles = React.useMemo(() => {
    let path = '';

    const stars = props.properties?.stars ?? 0;

    switch (stars) {
      case 2: {
        path =
          'path("M 308.5 73.5 Q 308.5 73.5 308.5 73.5 C 308.5 73.5 297.925 73.5465 308.5 73.5 C 308 45 219 17.3715 219 17.3715")';
        break;
      }
      case 3: {
        path =
          'path("M 308.5 73.5 Q 308.5 73.5 308.5 73.5 C 308.5 73.5 297.925 73.5465 308.5 73.5 C 308 45 235 17.3715 235 17.3715")';
        break;
      }
      case 4: {
        path =
          'path("M 308.5 73.5 Q 308.5 73.5 308.5 73.5 C 308.5 73.5 297.925 73.5465 308.5 73.5 C 308 45 250 17.3715 250 17.3715")';
        break;
      }
      case 5: {
        path =
          'path("M 308.5 73.5 Q 308.5 73.5 308.5 73.5 C 308.5 73.5 297.925 73.5465 308.5 73.5 C 308 45 265 17.3715 265 17.3715")';
        break;
      }
      default: {
        path =
          'path("M 308.5 73.5 Q 308.5 73.5 308.5 73.5 C 308.5 73.5 297.925 73.5465 308.5 73.5 C 308 45 204 17.3715 204 17.3715")';
      }
    }

    return {
      stylePath: {
        offsetPath: path,
        offsetRotate: '0deg',
      },
      x: 10 + (stars ? stars - 1 : 0) * 15,
    };
  }, [props.properties.stars]);

  const styleLuminosity = React.useMemo(() => {
    return {
      mixBlendMode: 'luminosity',
    };
  }, []);
  const styleOverlay = React.useMemo(() => {
    return {
      mixBlendMode: 'overlay',
    };
  }, []);
  const styleScreen = React.useMemo(() => {
    return {
      mixBlendMode: 'screen',
    };
  }, []);
  const styleColorDodge = React.useMemo(() => {
    return {
      mixBlendMode: 'color-dodge',
    };
  }, []);

  const styleBlurFilter = React.useMemo(() => {
    return {
      filter: 'blur(0px)',
    };
  }, []);

  if (!props.properties.progressAnimation) return null;

  return (
    <svg
      id="star_travel_complet_v3_169x90"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="150 0 175 90"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <defs>
        <filter
          id="star_travel_complet_v3_169x90-u-star_exp_start-filter"
          x="-150%"
          width="400%"
          y="-150%"
          height="400%"
        >
          <feGaussianBlur
            id="star_travel_complet_v3_169x90-u-star_exp_start-filter-blur-0"
            stdDeviation="1.243022,0.414341"
            result="result"
          />
        </filter>
        <filter id="star_travel_complet_v3_169x90-u-star-filter" x="-150%" width="400%" y="-150%" height="400%">
          <feGaussianBlur
            id="star_travel_complet_v3_169x90-u-star-filter-inner-shadow-0-blur"
            in="SourceAlpha"
            stdDeviation="0.5,0.5"
          />
          <feOffset
            id="star_travel_complet_v3_169x90-u-star-filter-inner-shadow-0-offset"
            dx="-1"
            dy="0"
            result="tmp"
          />
          <feComposite
            id="star_travel_complet_v3_169x90-u-star-filter-inner-shadow-0-composite"
            operator="arithmetic"
            k2="-1"
            k3="1"
            in2="SourceGraphic"
          />
          <feColorMatrix
            id="star_travel_complet_v3_169x90-u-star-filter-inner-shadow-0-color-matrix"
            type="matrix"
            values="0 0 0 0 0.815686 0 0 0 0 0.513725 0 0 0 0 0 0 0 0 1 0"
          />
          <feMerge id="star_travel_complet_v3_169x90-u-star-filter-inner-shadow-0-merge" result="result">
            <feMergeNode
              id="star_travel_complet_v3_169x90-u-star-filter-inner-shadow-0-merge-node-1"
              in="SourceGraphic"
            />
            <feMergeNode id="star_travel_complet_v3_169x90-u-star-filter-inner-shadow-0-merge-node-2" />
          </feMerge>
        </filter>
      </defs>
      <g id="star_travel_complet_v3_169x90-u-land_shockwave" transform="translate(0 0.000001)">
        <g id="star_travel_complet_v3_169x90-u-fx" transform="matrix(.166068 0 0 0.166068 292 63.708265)">
          <image
            id="star_travel_complet_v3_169x90-u-radial_rays_3"
            // @ts-ignore
            style={styleLuminosity}
            width="184"
            height="183"
            xlinkHref={StarGlowWhite}
            preserveAspectRatio="xMidYMid meet"
            transform="translate(-7.672729-28.75)"
            opacity="0"
          />
          <g
            id="star_travel_complet_v3_169x90-u-radial_rays_2_ts"
            transform="translate(83.747579,62.543691) scale(0.652149,0.652159)"
          >
            <image
              id="star_travel_complet_v3_169x90-u-radial_rays_2"
              width="95"
              height="94"
              xlinkHref={StarGlowYellow}
              preserveAspectRatio="xMidYMid meet"
              transform="translate(-47.210154,-44.310046)"
              opacity="0"
            />
          </g>
          <image
            id="star_travel_complet_v3_169x90-u-glow_generic"
            // @ts-ignore
            style={styleOverlay}
            width="118"
            height="126"
            xlinkHref={CircleWhiteBlur}
            preserveAspectRatio="xMidYMid meet"
            transform="matrix(2.318749 0 0 2.030787-50.582518-65.189581)"
            opacity="0"
          />
          <image
            id="star_travel_complet_v3_169x90-u-glow_generic_yellow"
            // @ts-ignore
            style={styleOverlay}
            width="75"
            height="74"
            xlinkHref={CircleYellowBlur}
            preserveAspectRatio="xMidYMid meet"
            transform="matrix(2 0 0 2 9.159031-9.848521)"
            opacity="0"
          />
          <g
            id="star_travel_complet_v3_169x90-u-star_exp_start_ts"
            transform="translate(82.259562,63.430217) scale(0.054635,0.054635)"
          >
            <image
              id="star_travel_complet_v3_169x90-u-star_exp_start"
              // @ts-ignore
              style={styleScreen}
              width="164"
              height="164"
              xlinkHref={StarYellow}
              preserveAspectRatio="xMidYMid meet"
              transform="translate(-82,-82)"
              filter="url(#star_travel_complet_v3_169x90-u-star_exp_start-filter)"
            />
          </g>
        </g>
        <g transform={`translate(${styles.x},0)`}>
          <g
            id="star_travel_complet_v3_169x90-u-shockwave_yellow_ts"
            transform="translate(193.503151,17.30218) scale(0.012381,0.012381)"
          >
            <image
              id="star_travel_complet_v3_169x90-u-shockwave_yellow"
              // @ts-ignore
              style={styleColorDodge}
              width="246"
              height="251"
              xlinkHref={ExplosionYellow}
              preserveAspectRatio="xMidYMid meet"
              transform={'translate(-123,-125.5)'}
              opacity="0"
            />
          </g>
          <g
            id="star_travel_complet_v3_169x90-u-small_ts"
            transform="translate(193.503151,17.30218) scale(0.015504,0.015504)"
          >
            <image
              id="star_travel_complet_v3_169x90-u-small"
              width="230"
              height="231"
              xlinkHref={ExplosionWhite}
              preserveAspectRatio="xMidYMid meet"
              transform={'translate(-115,-115.5)'}
              opacity="0"
            />
          </g>
        </g>
        <g
          id="star_travel_complet_v3_169x90-u-star_to"
          style={styles.stylePath}
          onAnimationEnd={props.properties?.onAnimationEnd}
        >
          <g id="star_travel_complet_v3_169x90-u-star_tr" transform="rotate(0)">
            <g id="star_travel_complet_v3_169x90-u-star_ts" transform="scale(0.123881,0.124242)">
              <g
                id="star_travel_complet_v3_169x90-u-star"
                transform="translate(-10,-10)"
                filter="url(#star_travel_complet_v3_169x90-u-star-filter)"
              >
                <image
                  id="star_travel_complet_v3_169x90-u-star2"
                  style={styleBlurFilter}
                  width="164"
                  height="164"
                  xlinkHref={StarYellow}
                  preserveAspectRatio="xMidYMid meet"
                  transform="translate(-71.999998-72.764453)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MomentumProgressAnimation;
