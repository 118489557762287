import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import store, { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { digitainConfig } from '@/modules/bets/api/config/digitain';
import { normalizeDigitainLiveTicket } from '@/modules/bets/utils/normalizeDigitainTickets';
import { buildTicketLists } from '@/components/modules/my-tickets/utils/functions';
import { prematchSetMatches } from '@/modules/bets/store/actions/prematch';
import { liveSetMatches } from '@/modules/bets/store/actions/live';
import { onBetToggle } from '@/page-components/Container/onBetToggle';

//import './index.scss';

type BetsSharedTicketProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const useLiveMatchesSelector = (liveMatchesIds: any) => {
  const liveMatches = useAppSelector<any>((state) => state.bets.live.matches);
  const [state, setState] = React.useState<any>({
    ids: liveMatchesIds ?? [],
    matches: {},
  });

  React.useEffect(() => {
    if (!isEqual(state.ids, liveMatchesIds)) {
      setState((v: any) => ({
        ...v,
        ids: liveMatchesIds ?? [],
      }));
    }
  }, [state.ids, liveMatchesIds]);

  React.useEffect(() => {
    if (state.ids.length) {
      const matchesData: any = {};
      let changed = false;

      state.ids.forEach((id: any) => {
        if (liveMatches[id]) {
          matchesData[id] = liveMatches[id];
        }
      });

      Object.keys(matchesData).forEach((id) => {
        if (!state.matches[id]) {
          changed = true;
        } else if (state.matches[id] !== matchesData[id]) {
          changed = true;
        }
      });

      Object.keys(state.matches).forEach((id) => {
        if (!matchesData[id]) {
          changed = true;
        } else if (matchesData[id] !== state.matches[id]) {
          changed = true;
        }
      });

      if (changed) {
        setState((v: any) => ({
          ...v,
          matches: matchesData,
        }));
      }
    }
  }, [liveMatches, state]);

  return state.matches;
};

const BetsSharedTicket = (componentProps: BetsSharedTicketProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const authenticationToken = useAppSelector((state) => state.authentication.access_token);
  const currentTicket = useAppSelector((state) => state.bets.betsSlip.tickets[state.bets.betsSlip.currentTicket]);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [ticketData, setTicketData] = React.useState<any>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [extended, setExtended] = React.useState<any>({});
  const [placing, setPlacing] = React.useState(false);

  const ticketId = props.properties.ticketId;

  const liveMatchesIds = ticketData?.bets?.map((bet: any) => bet.idMatch) ?? [];
  const liveMatches = useLiveMatchesSelector(liveMatchesIds);

  React.useEffect(() => {
    if (ticketData === null && ticketId) {
      const getTicket = async () => {
        const digitainConfigData = digitainConfig();

        const headers: any = {};
        if (authenticationToken) {
          headers['Authorization'] = `Bearer ${authenticationToken}`;
        }

        let response;

        try {
          response = await axios.get(digitainConfigData.ticketsPublicUrl + '/tickets/get/' + ticketId, {
            headers: headers,
          });
        } catch (e) {
          console.error('shareTicket[error]: ', e);
          setLoaded(true);
          return;
        }

        if (response?.data?.ticket) {
          const ticketData = response.data;
          ticketData.amount = ticketData.amount / 100;
          ticketData.possible_win = ticketData.possible_win / 100;

          const normalizedTicket = normalizeDigitainLiveTicket(response.data);
          setTicketData(normalizedTicket);
        }
        setLoaded(true);
      };
      getTicket();
    }
  }, [ticketData, ticketId, authenticationToken]);

  const recoverTicket = (ticket: any) => {
    //console.log('recover', ticket);

    const exists: any = { prematch: {}, live: {} };
    currentTicket.prematch.selected.forEach((bet: any) => {
      exists.prematch[`${bet.idSport}/${bet.idMatch}/${bet.idMbo}`] = true;
    });
    currentTicket.live.selected.forEach((bet: any) => {
      exists.live[`${bet.idSport}/${bet.idMatch}/${bet.idMbo}`] = true;
    });

    ticket.bets.forEach((tb: any) => {
      if (exists[tb.mType][`${tb.idSport}/${tb.idMatch}/${tb.idMatchBetOutcome}`]) {
        // if bet is already on betslip skip over it
        return;
      }

      false &&
        console.log(
          'recover bet',
          tb.mType,
          tb.idSport + '',
          tb.idMatch + '',
          tb.idBet + '',
          tb.idMatchBet + '',
          tb.idBetOutcome + '',
          tb.idMatchBetOutcome + '',
        );

      const betData = {
        stopPropagation: () => {},
        preventDefault: () => {},
        currentTarget: {
          dataset: {
            mtype: tb.mType + '',
            idsport: tb.idSport + '',
            idmatch: tb.idMatch + '',
            idbet: tb.idBet + '',
            idmb: tb.idMatchBet + '',
            idbo: tb.idBetOutcome + '',
            idmbo: tb.idMatchBetOutcome + '',
            shareticketnumber: ticket.idHash,
          },
        },
      };

      // @ts-ignore
      onBetToggle(betData);
    });
  };

  const onAddToBetslip = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (placing) return;
      setPlacing(true);

      try {
        const digitainConfigData = digitainConfig();

        const matches = ticketData?.bets?.map((bet: any) => ({ idMatch: `${bet.idMatch}`, mType: bet.mType })) ?? [];

        const prematchMatches = matches
          .map((match: any) => {
            if (match.mType === 'prematch') {
              return match.idMatch;
            }
            return null;
          })
          .filter((match: any) => match !== null);

        const liveMatches = matches
          .map((match: any) => {
            if (match.mType === 'live') {
              return match.idMatch;
            }
            return null;
          })
          .filter((match: any) => match !== null);

        const promises = [];

        const headers: any = {};
        if (authenticationToken) {
          headers['Authorization'] = `Bearer ${authenticationToken}`;
        }

        if (prematchMatches.length) {
          promises.push(
            axios
              .post(
                digitainConfigData.apiURL + '/events',
                { ids: prematchMatches, mType: 'prematch' },
                { headers: headers },
              )
              .catch((e) => e),
          );
        }
        if (liveMatches.length) {
          promises.push(
            axios
              .post(digitainConfigData.apiURL + '/events', { ids: liveMatches, mType: 'live' }, { headers: headers })
              .catch((e) => e),
          );
        }

        Promise.all(promises).then((results) => {
          results?.forEach((res) => {
            if (res?.data?.data?.events?.length) {
              const mType = res?.data?.data?.events[0].mType === 'prematch' ? 'prematch' : 'live';

              if (mType === 'prematch') {
                res.data.data.events.forEach((e: any) => {
                  e._loaded = true;
                });
                dispatch(prematchSetMatches(res.data.data.events));
              } else if (mType === 'live') {
                res.data.data.events.forEach((e: any) => {
                  e._loaded = true;
                });
                dispatch(liveSetMatches(res.data.data.events));
              }
            }
          });

          // using timeout just to make sure the above matches are in state when doing the toggle
          setTimeout(() => {
            recoverTicket(ticketData);
          }, 0);

          setPlacing(false);
        });

        try {
          axios.get(digitainConfigData.ticketsPublicUrl + '/tickets/copy/' + ticketId, {
            headers: headers,
          });
        } catch (e) {
          console.error('shareTicket[error]: ', e);
        }

        // console.log('BetsSharedTicket[onAddToBetslip]', { prematchMatches, liveMatches });
      } catch (e) {
        setPlacing(false);
      }
    },
    [placing, ticketId, ticketData, authenticationToken, currentTicket],
  );

  const onExtend = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (e.currentTarget.dataset.hash != null && e.currentTarget.dataset.hash !== '') {
        const hash = e.currentTarget.dataset.hash;
        setExtended((prevState: any) => ({
          ...prevState,
          [hash]: prevState[hash] != null ? !prevState[hash] : true,
        }));

        const viewTicket = async () => {
          const digitainConfigData = digitainConfig();

          const headers: any = {};
          if (authenticationToken) {
            headers['Authorization'] = `Bearer ${authenticationToken}`;
          }
          try {
            await axios.get(digitainConfigData.ticketsPublicUrl + '/tickets/view/' + ticketId, {
              headers: headers,
            });
          } catch (e) {
            console.error('shareTicket[error]: ', e);
          }
        };
        if (!extended[ticketId]) viewTicket();
      }
    },
    [extended, ticketId, authenticationToken],
  );

  const contextValue = React.useMemo(() => {
    let ticket = null;

    if (ticketData) {
      const tickets = buildTicketLists([ticketData], extended, i18n.language, t, false, null, liveMatches);
      ticket = tickets[0];
    }

    return {
      ticket: ticket,
      ticketId,
      onAddToBetslip,
      extended,
      onExtend,
      placing,
      hasActiveMatches: ticket?.hasActiveMatches ?? false,
    };
  }, [
    dataElementContext,
    componentProps,
    ticketId,
    onAddToBetslip,
    ticketData,
    loaded,
    liveMatches,
    t,
    i18n.language,
    extended,
    onExtend,
    placing,
  ]);

  if (contextValue.ticket === null) return null;

  //console.log('BetsSharedTicket[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetsSharedTicket;
