/* eslint-disable */
import { takeEvery, call, put } from 'redux-saga/effects';
import axios from 'axios';

import { templateConstants } from '../actions/constants';

let apiUrl;
if (import.meta.env.MODE === 'development') {
  apiUrl = 'http://localhost:3001/betsapi/-';
} else {
  apiUrl = '/betsapi/-';
}

function axiosObject(url) {
  return axios.get(url);
}

function* templateSaga(action) {
  try {
    let { data } = yield call(axiosObject, apiUrl);
    if (data) {
      const rows = data.rows;
      yield put(loadHotDays(rows));
    }
  } catch (e) {
    //console.log("[templateSaga] err", e);
  }
}

export default function* templateInit() {
  //console.log("[saga] templateSaga");
  yield takeEvery(templateConstants.DEMO, templateSaga);
}
