import { configConstants } from './constants';

export const configLoaded = data => ({
	type: configConstants.LOADED,
	data
});

export const configReload = () => ({
	type: configConstants.RELOAD
});

export const configUpdate = data => ({
	type: configConstants.UPDATE,
	data
});

export const configUpdateDayMultiBets = dmbs => ({
	type: configConstants.UPDATE_DAY_MULTI_BETS,
	dmbs
});

export const configLoadBetsMarket = idSport => ({
	type: configConstants.LOAD_BETS_MARKETS_ORDER,
	idSport
});

export const configSetBetsMarket = (idSport, data) => ({
	type: configConstants.SET_BETS_MARKETS_ORDER,
	idSport,
	data
});

export const configSetAllBetsMarket = (data) => ({
	type: configConstants.SET_ALL_BETS_MARKETS_ORDER,
	data
});
