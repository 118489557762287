import { PaymentProviderInterface } from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import PaymentProvider, {
  PaymentProviderConstructor,
} from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import { PaymentProvider as PaymentProviderConstant } from '@/constants/paymentProvider';
import { AmountButtonInterface } from '@/components/classes/PaymentProviders/Interfaces/AmountButtonInterface';
import { aircashGetPaymentLink } from '@/modules/casino/store/actions/deposit';
import cordovaRedirect from '../../utils/cordovaRedirect';

class Okto extends PaymentProvider implements PaymentProviderInterface {
  constructor(data: PaymentProviderConstructor) {
    super(data);
    this.setType(PaymentProviderConstant.abon);
  }

  init(data: any): void {
    super.init(data);
  }

  confirmPayment(): void {
    this.dispatch(aircashGetPaymentLink(this.paymentDetails?.amount ?? 0, this.paymentDetails?.bonusId ?? null));
  }

  redirect(url: string): void {
    if (window.config.cordova) {
      this.openedPopupUrl = cordovaRedirect(url, this.openedPopupUrl);
      return;
    }
    window.location.href = url;
  }
}

export default Okto;
