/* eslint-disable */
import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';

import * as actionTypes from '../../../casino/store/actions/actionTypes';
import { winCoinsConstants } from '../actions/constants';
import { winCoinsSystemsLoaded, winCoinsCollectingStatus } from '../actions/wincoins';
import { playerLoad } from '../actions/player';
import { requestWallet } from '../actions/wallet';
import getStore from '../../store';

const apiUrl = window.config.betsApiUrl + '/winner-fun';

function* winCoinsSystemsSaga(action) {
  const store = getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }

  try {
    let { data } = yield axios.get(apiUrl + '/player/collect/status', {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    });
    if (data && data.data) {
      yield put(winCoinsSystemsLoaded(data.data));
    }
  } catch (e) {
    console.log('[WF:winCoinsSystemsSaga] err', e);
  }
}

function* winCoinsCollectDailySaga(action) {
  const store = getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }

  const cb = action && action.data && action.data.cb ? action.data.cb : () => {};

  yield put(winCoinsCollectingStatus({ collecting: true }));

  try {
    let { data } = yield axios.get(apiUrl + '/player/collect/daily', {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    });
    if (data) {
      yield winCoinsSystemsSaga();
      cb(true);
      yield put(requestWallet());
    }
  } catch (e) {
    console.log('[WF:winCoinsSystemsSaga] err', e);
    cb(false);
  }

  yield put(winCoinsCollectingStatus({ collecting: false }));
}

function* winCoinsCollectHourlySaga(action) {
  const store = getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }

  const cb = action && action.data && action.data.cb ? action.data.cb : () => {};

  yield put(winCoinsCollectingStatus({ collecting: true }));

  try {
    let { data } = yield axios.get(apiUrl + '/player/collect/token', {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    });
    if (data) {
      yield winCoinsSystemsSaga();
      cb(true);
      yield put(requestWallet());
    }
  } catch (e) {
    console.log('[WF:winCoinsSystemsSaga] err', e);
    cb(false);
  }

  yield put(winCoinsCollectingStatus({ collecting: false }));
}

function* winCoinsCollectBoostSaga(action) {
  const store = getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }

  const cb = action && action.data && action.data.cb ? action.data.cb : () => {};

  yield put(winCoinsCollectingStatus({ collecting: true }));

  try {
    let { data } = yield axios.get(apiUrl + '/player/collect/boost', {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    });
    if (data) {
      yield winCoinsSystemsSaga();
      cb(true);
    }
  } catch (e) {
    console.log('[WF:winCoinsSystemsSaga] err', e);
    cb(false);
  }

  yield put(winCoinsCollectingStatus({ collecting: false }));
}

function* winCoinsCollectReferrerSaga(action) {
  const store = getStore();
  const state = store.getState();

  if (['user', 'token'].indexOf(state.authentication.auth_type) === -1 || state.authentication.access_token === null) {
    return;
  }

  const cb = action && action.data && action.data.cb ? action.data.cb : () => {};
  const referrer_id = action && action.data && action.data.referrer_id ? action.data.referrer_id : '';
  let url = '/player/collect/referrer';
  if (referrer_id) url += `?referrer_id=${referrer_id}`;

  yield put(winCoinsCollectingStatus({ collecting: true }));

  try {
    let { data } = yield axios.get(apiUrl + url, {
      headers: {
        Authorization: 'Bearer ' + state.authentication.access_token,
      },
    });

    if (data) {
      yield put(playerLoad());
      cb(true);
      yield put(requestWallet());
    }
  } catch (e) {
    console.log('[WF:winCoinsSystemsSaga] err', e);
    cb(false);
  }

  yield put(winCoinsCollectingStatus({ collecting: false }));
}

export default function* winCoinsInit() {
  //console.log("[saga] winCoinsInit");
  yield takeEvery(winCoinsConstants.LOAD, winCoinsSystemsSaga);
  yield takeEvery(winCoinsConstants.COLLECT_DAILY, winCoinsCollectDailySaga);
  yield takeEvery(winCoinsConstants.COLLECT_HOURLY, winCoinsCollectHourlySaga);
  yield takeEvery(winCoinsConstants.COLLECT_BOOST, winCoinsCollectBoostSaga);
  yield takeEvery(winCoinsConstants.COLLECT_REFERRER, winCoinsCollectReferrerSaga);
  yield takeEvery(actionTypes.authentication.AUTHENTICATE, winCoinsSystemsSaga);
}
