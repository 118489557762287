import { momentumConstants } from './constants';

export const getMomentumBonuses = (data) => ({
  type: momentumConstants.GET_MOMENTUM_BONUSES,
  data
});

export const setMomentumBonuses = (data, source, event) => ({
  type: momentumConstants.SET_MOMENTUM_BONUSES,
  data,
  source,
  event,
});

export const forfeitMomentumBonus = (id) => ({
  type: momentumConstants.FORFEIT_MOMENTUM_BONUS,
  id
});

export const acceptMomentumBonus = (id) => ({
  type: momentumConstants.ACCEPT_MOMENTUM_BONUS,
  id
});

export const clearMomentumState = (loaded = false) => ({
  type: momentumConstants.CLEAR_STATE,
  loaded,
});

export const updateMomentumBonus = (data, loaded) => ({
  type: momentumConstants.UPDATE_MOMENTUM_BONUS,
  data,
  loaded,
});

export const setMomentumSource = (source) => ({
  type: momentumConstants.SET_MOMENTUM_SOURCE,
  source,
});

export const updateMomentumStatus = (down = false) => ({
  type: momentumConstants.UPDATE_MOMENTUM_STATUS,
  down,
});