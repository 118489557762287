import React from 'react';
import styled from 'styled-components';

const ModuleListItemDiv = styled.div((props) => props.$styleText);

export const ModuleListItem = (componentProps) => {
  return (
    <ModuleListItemDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText} style={componentProps.style}>
      {componentProps.children}
    </ModuleListItemDiv>
  );
};
