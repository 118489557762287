import React from 'react';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';
import evBus from '../../../../utils/evbus';
import { appToggleCompetitions, appSetInPageIdMatch } from '../../../../modules/bets/store/actions/app';

import './index.scss';

type InPageMatchDetails = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const InPageMatchDetails = (componentProps: InPageMatchDetails) => {
  const showCompetitions = useAppSelector((state) => state.bets.app.showCompetitions);

  const dispatch = useAppDispatch();
  const togleCompetitions = () => {
    dispatch(appToggleCompetitions());
  };

  const [state, setState] = React.useState({
    showMatchDetails: false,
    mType: '',
    idSport: '',
    idCategory: '',
    idTournament: '',
    idMatch: '',
    showCompetitions,
    togleCompetitions,
  });

  React.useEffect(() => {
    setState((v) => ({ ...v, showCompetitions: showCompetitions }));
  }, [showCompetitions]);

  const showMatchDetails = (data: any, event: string) => {
    if (event === 'SHOW_MATCH_DETAILS') {
      setState((v) => ({
        ...v,
        showMatchDetails: true,
        mType: data.mType,
        idSport: data.idSport,
        idCategory: data.idCategory,
        idTournament: data.idTournament,
        idMatch: data.idMatch,
      }));
      dispatch(appSetInPageIdMatch(data.idMatch));
    }
  };

  const hideMatchDetails = (data: any, event: string) => {
    if (event === 'HIDE_MATCH_DETAILS') {
      setState((v) => ({
        ...v,
        showMatchDetails: false,
        mType: '',
        idSport: '',
        idCategory: '',
        idTournament: '',
        idMatch: '',
      }));
      dispatch(appSetInPageIdMatch());
    }
  };

  React.useEffect(() => {
    evBus.on('SHOW_MATCH_DETAILS', showMatchDetails);
    evBus.on('HIDE_MATCH_DETAILS', hideMatchDetails);
    return () => {
      evBus.remove('SHOW_MATCH_DETAILS', showMatchDetails);
      evBus.remove('HIDE_MATCH_DETAILS', hideMatchDetails);
    };
  }, [componentProps]);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={state}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default InPageMatchDetails;
