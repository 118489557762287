import { all, call } from 'redux-saga/effects';

import watchTournamentsGroups from './tournaments_groups';
import watchLeaderBoard from './leader_board';
import watchTournament from './tournament';
import watchHistory from './history';

export function* tournamentsMissionsSaga() {
  yield all([call(watchTournamentsGroups), call(watchLeaderBoard), call(watchTournament), call(watchHistory)]);
}
