import React from 'react';
import styled from 'styled-components';
import { useMatches } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { digitainConfig } from '@/modules/bets/api/config/digitain';
import DefaultCrest from '@/modules/bets/assets/default-crest.svg';
import fetchStatsByIdMatch from '../bets-match-stats/utils/genericFetch';
import { useMediaQuery } from '../../utils/useQueryMedia';
import { flags } from '../bets-match-stats/utils/flags';

const crest = (teamLogoNo: number) => {
  if (teamLogoNo) {
    if (flags[teamLogoNo]) {
      return flags[teamLogoNo];
    }
    return `${digitainConfig().apiURL}/team-logo2/${teamLogoNo}`;
  }

  return DefaultCrest;
};

const toName = (name: any) => {
  if (!name) return '';

  if (typeof name === 'string') {
    try {
      const obj = JSON.parse(name);
      if (typeof obj === 'object' && (obj['2'] || obj['0'])) {
        return obj;
      }
    } catch (e) {
      /**/
    }
  }
  return name;
};

const padString = (str: any) => {
  return String(str).padStart(2, '0');
};

//import './index.scss';

type BetsLeagueMatchStatsProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const BetsLeagueMatchStats = (componentProps: BetsLeagueMatchStatsProps) => {
  let props = componentProps;
  const betsConfig = digitainConfig();

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const uriMatches = useMatches();
  const isDesktop = useMediaQuery('(min-width:900px)');

  const [state, setState] = React.useState({
    mainIndex: 0,
    subIndex: 0,
    showItems: 5,
    loading: false,
  });

  let idMatch = props.properties.idMatch;
  if (props.properties.targetIdFromPath) {
    if (uriMatches && uriMatches.length) {
      const match = uriMatches[0];

      let pathParamKey = props.properties.pathParamKey ?? 'idMatch';
      if (pathParamKey[0] === ':') pathParamKey = pathParamKey.substr(1);
      if (match.params && pathParamKey && match.params[pathParamKey] != null) {
        idMatch = match.params[pathParamKey];
      }
    }
  }

  const matchData = fetchStatsByIdMatch({
    elementId: idMatch,
    apiUrl: `${betsConfig.leagueStatsUrl}/${idMatch}`,
  });

  //console.log('data', matchData);

  const onIndexChange = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.mainIndex != null) {
      const tabIndex = parseInt(e.currentTarget.dataset.mainIndex, 10) ?? 0;
      setState((v) => ({ ...v, mainIndex: tabIndex }));
      return;
    }
    if (e.currentTarget.dataset.subIndex != null) {
      const tabIndex = parseInt(e.currentTarget.dataset.subIndex, 10) ?? 0;
      setState((v) => ({ ...v, subIndex: tabIndex }));
      return;
    }
  }, []);

  const processResult = React.useCallback((teams: any, baseTeamId: any) => {
    const date = teams.event_date ? new Date(teams.event_date) : new Date(teams.event_time);
    const dateShort = `${padString(date.getDate())}.${padString(date.getMonth() + 1)}`;

    const dateFullYear = date.getFullYear();
    const nowFullYear = new Date().getFullYear();
    const time = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    let note = '';
    if (teams.status_result?.result1 != null && teams.status_result?.result2 != null) {
      if (teams.status_result.result1 === teams.status_result.result2) {
        note = 'd';
      } else if (teams.status_result.result1 > teams.status_result.result2 && teams.team1_id === baseTeamId) {
        note = 'w';
      } else if (teams.status_result.result1 > teams.status_result.result2 && teams.team2_id === baseTeamId) {
        note = 'l';
      } else if (teams.status_result.result1 < teams.status_result.result2 && teams.team2_id === baseTeamId) {
        note = 'w';
      } else if (teams.status_result.result1 < teams.status_result.result2 && teams.team1_id === baseTeamId) {
        note = 'l';
      }
    }
    const url =
      teams.digitain_sport_id && teams.digitain_category_id && teams.digitain_tournament_id && teams.digitain_event_id
        ? `/bets/match/pre-match/${teams.digitain_sport_id}/${teams.digitain_category_id}/${teams.digitain_tournament_id}/${teams.digitain_event_id}`
        : '';

    return {
      date: date.getTime(),
      dateShort: dateShort,
      year: dateFullYear === nowFullYear ? '' : dateFullYear,
      time: time,
      note: note,
      matchId: teams.event_id,
      matchDetailsURL: url,
      team1: {
        id: teams.team1_id,
        name: toName(teams.team1_name),
        image: crest(teams.team1_logo),
        score: teams.status_result?.result1 ?? '',
        win: teams.status_result?.result1 > teams.status_result?.result2,
      },
      team2: {
        id: teams.team2_id,
        name: toName(teams.team2_name),
        image: crest(teams.team2_logo),
        score: teams.status_result?.result2 ?? '',
        win: teams.status_result?.result2 > teams.status_result?.result1,
      },
    };
  }, []);

  const processResults = React.useCallback((matches: any, baseTeamId: any) => {
    const fullResults: any = [];

    matches.forEach?.((match: any) => {
      fullResults.push(processResult(match, baseTeamId));
    });

    return fullResults;
  }, []);

  const processFixtures = React.useCallback((results: any, baseTeamId: any) => {
    const groups: any = {};

    results.forEach?.((match: any) => {
      const tournamentId = match.tournament_id ?? '1';
      const group_index = match.group_index ?? '0';
      if (match.group_id) {
        const key = `${group_index}_${tournamentId}-${match.group_id}`;
        if (!groups[key]) groups[key] = { matches: [] };

        groups[key].tournamentName = toName(match.tournament_name);
        groups[key].groupName = toName(match.group_name);
        groups[key].key = key;
        groups[key].matches.push(processResult(match, baseTeamId));
      } else {
        console.error('Match, without group_id', match);
      }
    });

    const fullResults: any = [];

    Object.keys(groups).forEach((key) => {
      groups[key].matches.sort((a: any, b: any) => {
        return a.date - b.date;
      });

      fullResults.push(groups[key]);
    });

    fullResults.sort((a: any, b: any) => {
      return a.key.localeCompare(b.key);
    });

    fullResults.reverse();

    return fullResults;
  }, []);

  const processForm = React.useCallback((form: any, matches: any) => {
    const matchesById: any = {};
    matches.forEach?.((match: any) => {
      matchesById[match.event_id] = match;
    });

    const res: any = {
      result: {
        label: 'Result',
        team: 'team name',
        logo: crest(idMatch),
        data: [],
      },
      ht_ft: {
        label: 'Halftime',
        data: [],
      },
      under_over: {
        label: 'Over / Under 2.5',
        data: [],
      },
      clean_sheet: {
        label: 'Clean Sheet',
        data: [],
      },
      fail_to_score: {
        label: 'Fail to Score',
        data: [],
      },
      opponent_scores_first: {
        label: 'Opponent Scores First',
        data: [],
      },
      early_goal: {
        label: 'Early Goal',
        data: [],
      },
    };
    const resHome = JSON.parse(JSON.stringify(res));
    const resAway = JSON.parse(JSON.stringify(res));

    form.sort((a: any, b: any) => {
      let aVal = '';
      let bVal = '';
      if (a.id && matchesById[a.id]) {
        aVal = matchesById[a.id].event_date;
      }
      if (b.id && matchesById[b.id]) {
        bVal = matchesById[b.id].event_date;
      }

      return bVal.localeCompare(aVal);
    });

    form.forEach?.((stats: any) => {
      /*
      {
        "id": "1332583",
        "result": "W",
        "home_away": "H",
        "under_over": "U",
        "clean_sheet": "+",
        "fail_to_score": "-",
        "opponent_scores_first": "-",
        "early_goal": "-",
        "ht_ft": "D/W",
      }
      */

      if (stats.id && !matchesById[stats.id]) return;

      const result = stats.result?.toLowerCase() ?? 'u';
      const ht_ft = (stats.ht_ft?.split('/')?.[0] ?? 'u').toLowerCase();
      let under_over = stats.under_over?.toLowerCase() ?? 'u';
      under_over = under_over === 'u' ? 'l' : under_over === 'o' ? 'w' : 'u';
      let clean_sheet = stats.clean_sheet?.toLowerCase() ?? 'u';
      clean_sheet = clean_sheet === '+' ? 'w' : clean_sheet === '-' ? 'l' : 'u';
      let fail_to_score = stats.fail_to_score?.toLowerCase() ?? 'u';
      fail_to_score = fail_to_score === '+' ? 'w' : fail_to_score === '-' ? 'l' : 'u';
      let opponent_scores_first = stats.opponent_scores_first?.toLowerCase() ?? 'u';
      opponent_scores_first = opponent_scores_first === '+' ? 'w' : opponent_scores_first === '-' ? 'l' : 'u';
      let early_goal = stats.early_goal?.toLowerCase() ?? 'u';
      early_goal = early_goal === '+' ? 'w' : early_goal === '-' ? 'l' : 'u';

      res.result.data.push({ value: result ?? 'u', home_away: stats.home_away });
      res.ht_ft.data.push({ value: ht_ft, home_away: stats.home_away });
      res.under_over.data.push({ value: under_over, home_away: stats.home_away });
      res.clean_sheet.data.push({ value: clean_sheet, home_away: stats.home_away });
      res.fail_to_score.data.push({ value: fail_to_score, home_away: stats.home_away });
      res.opponent_scores_first.data.push({ value: opponent_scores_first, home_away: stats.home_away });
      res.early_goal.data.push({ value: early_goal, home_away: stats.home_away });
    });

    res.result.data.forEach((_item: any, index: number) => {
      const to = res.result.data[index].home_away === 'H' ? resHome : resAway;
      to.result.data.push(res.result.data[index]);
      to.ht_ft.data.push(res.ht_ft.data[index]);
      to.under_over.data.push(res.under_over.data[index]);
      to.clean_sheet.data.push(res.clean_sheet.data[index]);
      to.fail_to_score.data.push(res.fail_to_score.data[index]);
      to.opponent_scores_first.data.push(res.opponent_scores_first.data[index]);
      to.early_goal.data.push(res.early_goal.data[index]);
    });

    return { all: res, home: resHome, away: resAway };
  }, []);

  const contextValue = React.useMemo(() => {
    const md = matchData.data?.data ?? {};
    const teamId = md.team?.team_id ?? 0;

    let mainIndex = state.mainIndex;
    if (mainIndex === 0 && md.matches?.length === 0) {
      mainIndex = 1;
    }

    if (mainIndex === 1 && md.form?.length === 0) {
      mainIndex = 2;
    }

    //console.log('md', md);
    let fullResults: any = [];
    let fullForm: any = {};
    let fullFixtures: any = [];

    if (mainIndex === 0) {
      fullResults = processResults(md.matches ?? [], teamId);
    }

    if (mainIndex === 1) {
      const result = processForm(md.form ?? [], md.matches);

      if (state.subIndex === 0) {
        fullForm = result.all;
      } else if (state.subIndex === 1) {
        fullForm = result.home;
      } else if (state.subIndex === 2) {
        fullForm = result.away;
      }
    }

    if (mainIndex === 2) {
      fullFixtures = processFixtures(md.fixtures ?? [], teamId);
    }

    const team = { ...md.team };
    if (team.team_logo) {
      team.team_logo = crest(team.team_logo);
    } else {
      team.team_logo = DefaultCrest;
      team.team_name = '...';
    }

    return {
      ...state,
      onIndexChange,

      showItems: isDesktop ? 10 : 5,

      team: team,

      noFormData: md.form?.length === 0,
      noMatchesData: md.matches?.length === 0,

      mainIndex: mainIndex,

      fullResults: {
        data: fullResults,
      },
      fullForm: {
        data: fullForm,
      },
      fullFixtures: {
        data: fullFixtures,
      },
    };
  }, [state, matchData, idMatch, isDesktop]);

  //console.log('BetsLeagueMatchStats[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={componentProps.className ?? ''} $styleText={componentProps.styleText}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default BetsLeagueMatchStats;
