import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../store';
import { DataElementContext } from '../../../../page-components/common/DataElementContext';

import './index.scss';

type StateProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const State = (componentProps: StateProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const [state, setState] = React.useState({
    indexType: '0',
    indexProduct: window.config.betsEnabled === '1' ? '0' : '2',
  });

  const onChangeIndexType = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.type != null && e.currentTarget.dataset.type !== '') {
      const type = e.currentTarget.dataset.type;
      setState((prevState) => ({
        ...prevState,
        indexType: type,
      }));
    }
  }, []);
  const onChangeIndexProduct = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.dataset.type != null && e.currentTarget.dataset.type !== '') {
      const type = e.currentTarget.dataset.type;
      setState((prevState) => ({
        ...prevState,
        indexProduct: type,
      }));
    }
  }, []);

  const contextValue = React.useMemo(() => {
    const contextValue = {
      ...state,
      onChangeIndexType,
      onChangeIndexProduct,
    };

    return contextValue;
  }, [state, onChangeIndexType, onChangeIndexProduct]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default State;
