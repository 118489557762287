import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';

import { tournamentEnrollResponse, tournamentActivateResponse } from '../actions/tournament';
import { tournamentConstants } from '../actions/constants';
import getStore from '../../store';

//let apiUrl = window.config.betsApiUrl + "/casinoapi/echo-service";
let apiUrl = window.config.betsApiUrl + '/missions';

const enroll_response = {
  success: true,
};
const activate_response = {
  success: true,
};

function* enrollTournamentSaga(action) {
  const { authentication } = getStore().getState();

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const tournamentId = action.tournamentId;
  if (!tournamentId) {
    return;
  }

  const activate = action.activate;

  const headers = {
    Authorization: 'Bearer ' + authentication.access_token,
  };

  try {
    const resp = yield axios.post(
      apiUrl + '/tournament/enroll',
      {
        mission_id: tournamentId,
        make_active: activate,
      },
      { headers },
    );
    yield put(tournamentEnrollResponse({ tournamentId, data: resp.data }));

    if (action.cb && typeof action.cb === 'function') action.cb();
  } catch (err) {
    console.log('enrollTournamentSaga[ERR]:', err);
  }
}

function* activateTournamentSaga(action) {
  const { authentication } = getStore().getState();

  if (!(authentication && ['user', 'token'].indexOf(authentication.auth_type) > -1)) {
    return;
  }

  const tournamentId = action.tournamentId;
  if (!tournamentId) {
    return;
  }

  const headers = {
    Authorization: 'Bearer ' + authentication.access_token,
  };

  try {
    const resp = yield axios.post(
      apiUrl + '/tournament/activate',
      {
        player_mission_id: tournamentId,
      },
      { headers },
    );
    yield put(tournamentActivateResponse({ tournamentId, data: resp.data }));

    if (action.cb && typeof action.cb === 'function') action.cb();
  } catch (err) {
    console.log('activateTournamentSaga[ERR]:', err);
  }
}

export default function* watchTournamentSaga() {
  yield takeEvery(tournamentConstants.ENROLL, enrollTournamentSaga);
  yield takeEvery(tournamentConstants.ACTIVATE, activateTournamentSaga);
}
