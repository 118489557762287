import * as actionTypes from '../actions/actionTypes';
import { produce } from 'immer';

const initialState = {
  running: {},
  status: {},
  prizeWon: {},
  statusCompleted: {},
  betInfo: {},
  showInfo: {},
};

const decodeCustom = (ev) => {
  const keys = ['next_special_prize', 'prize_before', 'prize_current', 'prize_after'];
  keys.forEach((k) => {
    if (typeof ev[k] !== 'undefined' && ev[k]) {
      if (typeof ev[k].custom !== 'undefined' && typeof ev[k].custom === 'string') {
        try {
          ev[k].custom = JSON.parse(ev[k].custom);
        } catch (err) {}
      }
    }
  });

  return ev;
};

const mergeObjects = (os, no, k) => {
  const rs = [...os];
  const idx = rs.findIndex((o) => o[k] === no[k]);
  if (idx !== -1) {
    rs[idx] = no;
  } else {
    rs.push(no);
  }
  return rs;
};

/*
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.happyHour.BET_INFO: {
            return {
                ...state,
                betInfo: mergeObjects(state.betInfo, action.data, "event_type")
            };
        }
        case actionTypes.happyHour.SHOW_INFO: {
            return {
                ...state,
                showInfo: {
                    ...state.showInfo,
                    [action.data]: true,
                },
            };
        }
        case actionTypes.happyHour.HIDE_INFO: {
            const si = { ...state.showInfo };
            delete (si[action.data]);

            return {
                ...state,
                showInfo: si,
            };
        }
        case actionTypes.happyHour.STATUS: {
            const data = action.data.map(ev => decodeCustom(ev));
            const nr = data.reduce((acc, ev) => {
                acc[ev.event_type] = true;
                return acc;
            }, {});

            return {
                ...state,
                status: data,
                statusCompleted: [],
                running: nr,
            };
        }
        case actionTypes.happyHour.UPDATE_STATUS: {
            const ev = decodeCustom(action.data);

            const nr = { ...state.running };
            nr[action.data.event_type] = true;

            return {
                ...state,
                status: mergeObjects(state.status, ev, "event_type"),
                running: nr
            };
        }
        case actionTypes.happyHour.STATUS_COMPLETED: {
            const ns = [...state.status];
            let idx = ns.findIndex(ev => ev.event_type === action.data.event_type);
            if (idx !== -1) {
                ns.splice(idx, 1);
            }

            const nr = { ...state.running };
            delete (nr[action.data.event_type]);

            const bi = [...state.betInfo];
            idx = bi.findIndex(b => b.event_type === action.data.event_type);
            if (idx !== -1) {
                bi.splice(idx, 1);
            }

            return {
                ...state,
                statusCompleted: mergeObjects(state.statusCompleted, action.data, "event_type"),
                status: ns,
                running: nr,
                betInfo: bi,
            };
        }
        case actionTypes.happyHour.PRIZE_WON: {
            const data = action.data;

            if (data && typeof data.custom !== "undefined" && data.custom) {
                if (typeof data.custom === "string") {
                    try {
                        data.custom = JSON.parse(data.custom);
                    } catch (err) { }
                }
            }
            return {
                ...state,
                prizeWon: mergeObjects(state.prizeWon, data, "event_type")
            }
        }
        case actionTypes.happyHour.CLEAR_PRIZE_WON: {
            const pw = [...state.prizeWon];
            let idx = pw.findIndex(p => p.event_type === action.data);
            if (idx !== -1) {
                pw.splice(pw, 1)
            }

            return {
                ...state,
                prizeWon: pw
            }
        }
        case actionTypes.happyHour.CLEAR_STATUS: {
            const ns = [...state.status];
            const idx = ns.findIndex(e => e.event_type === action.data);
            if (idx !== -1) {
                ns.splice(idx, 1);
            }

            return {
                ...state,
                running: false,
                status: {}
            }
        }
        case actionTypes.happyHour.CLEAR_STATUS_COMPLETED: {
            const ns = [...state.statusCompleted];
            const idx = ns.findIndex(e => e.event_type === action.data);
            if (idx !== -1) {
                ns.splice(idx, 1);
            }

            return {
                ...state,
                statusCompleted: ns,
            }
        }
        default:
            return {
                ...state,
            };
    }
};
*/

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.happyHour.BET_INFO: {
        draft.betInfo[action.data.event_type] = action.data;
        break;
      }
      case actionTypes.happyHour.SHOW_INFO: {
        draft.showInfo[action.data] = true;
        break;
      }
      case actionTypes.happyHour.HIDE_INFO: {
        delete draft.showInfo[action.data];
        break;
      }
      case actionTypes.happyHour.STATUS: {
        if (action.data) {
          draft.status = action.data.reduce((acc, ev) => {
            acc[ev.event_type] = decodeCustom(ev);
            return acc;
          }, {});

          draft.statusCompleted = {};

          draft.running = action.data.reduce((acc, ev) => {
            acc[ev.event_type] = true;
            return acc;
          }, {});
        } else {
          draft.status = {};
          draft.statusCompleted = {};
          draft.running = {};
        }

        break;
      }
      case actionTypes.happyHour.UPDATE_STATUS: {
        const ev = decodeCustom(action.data);

        draft.status[ev.event_type] = ev;
        draft.running[ev.event_type] = true;

        break;
      }
      case actionTypes.happyHour.STATUS_COMPLETED: {
        const evt = action.data.event_type;

        delete draft.status[evt];
        delete draft.running[evt];
        delete draft.betInfo[evt];

        draft.statusCompleted[evt] = action.data;
        break;
      }
      case actionTypes.happyHour.PRIZE_WON: {
        const data = action.data;

        if (data && typeof data.custom !== 'undefined' && data.custom) {
          if (typeof data.custom === 'string') {
            try {
              data.custom = JSON.parse(data.custom);
            } catch (err) {}
          }
        }

        draft.prizeWon[data.event_type] = data;

        break;
      }
      case actionTypes.happyHour.CLEAR_PRIZE_WON: {
        delete draft.prizeWon[action.data];
        break;
      }
      case actionTypes.happyHour.CLEAR_STATUS: {
        delete draft.status[action.data];
        delete draft.running[action.data];
        delete draft.betInfo[action.data];

        break;
      }
      case actionTypes.happyHour.CLEAR_STATUS_COMPLETED: {
        delete draft.statusCompleted[action.data];
        break;
      }
      default:
      /* noop */
    }
  });

export default reducer;
