import React from 'react';
import { withTranslation } from 'react-i18next';

import './index.scss';
// eslint-disable-next-line
import Loader from './assets/loader.svg?react';

const WinnerLoader = ({ t, theme }) => {
  return (<div className={`winner-loader ${theme ? theme : ''}`}>
    <div style={{ width: '150px' }}>
      <div>
        <div style={{ background: 'transparent', margin: '0px auto', outline: 'none', overflow: 'hidden', width: '150px', textAlign: 'center' }}>
          <Loader />
        </div>
      </div>
    </div>
  </div>);
};

export default withTranslation()(WinnerLoader);