import axios from 'axios';
import liveCasinoConfig from '../../modules/live-casino/api/config';
import ClientAPI from '../../modules/casino/ClientAPI/ClientAPI';

type LaunchUrlResponse = {
  data: {
    link: string;
  };
};

export const launchGameUrl = (gameCategory: string, gameTableId: string, accessToken: string) => {
  return axios
    .request<any, LaunchUrlResponse>({
      url: liveCasinoConfig.launchGame,
      method: 'get',
      params: {
        platformType: window.config.platformType,
        gameCategory: gameCategory,
        gameTableId: gameTableId,
        urls: window.config.liveCasinoUrls,
        currencyCode: ClientAPI.getStore().getState().wallet.currency
      },
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then((response) => {
      if (!response) {
        throw new Error('[ERROR] URL response is empty!');
      }

      if (response.data === undefined) {
        throw new Error('[ERROR] Server response has no result!');
      }

      const responseData = response.data;
      if (typeof responseData['link'] === 'undefined') {
        throw new Error('[ERROR] URL link not found!');
      }

      return responseData.link;
    });
};
