import Utils from '@/modules/casino/utils/Utils';
import VivaAPI from '@/modules/casino/ClientAPI/VivaAPI';
import ClientAPI from '@/modules/casino/ClientAPI/ClientAPI';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker';
import { requestVivaToken } from '@/modules/casino/store/actions/paymentCheckout';
import { HTML_3DS_IFRAME_ID, PaymentProvider } from '@/constants/paymentProvider';

class VivaApplePay {
  constructor(data: any) {
    this.init(data);
  }

  private amount: any;
  private bonusId: any;
  private triggerSuccess: any;
  private triggerError: any;
  private dispatch: any;
  injectScript(): void {
    setTimeout(() => {
      Utils.injectScript('https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js', 'apple-pay', () => {}, {});
    }, 3500);
  }

  destroy(): void {
    Utils.removeScript('apple-pay');
  }

  init(data: any): void {
    this.injectScript();
    this.amount = data.amount;
    this.bonusId = data.bonusId;
    this.triggerSuccess = data.triggerSuccess;
    this.triggerError = data.triggerError;
    this.dispatch = data.dispatch;
  }

  setAmount(amount: number): void {
    this.amount = amount;
  }

  setBonusId(bonusId: number | null): void {
    this.bonusId = bonusId;
  }

  isApplePayAvailable() {
    if (
      //@ts-ignore
      window.ApplePaySession &&
      window.config?.depositSettings?.providers?.[PaymentProvider.viva]?.isApplePayActive === '1'
    ) {
      //@ts-ignore
      if (window.ApplePaySession.canMakePayments()) {
        return true;
      }
    }
    return false;
  }

  onApplePayButtonClicked = () => {
    // @ts-ignore Ensure browser supports Apple PayEnsure browser supports Apple Pay
    if (!window.ApplePaySession) {
      return;
    }
    this.dispatch(requestVivaToken()); // auth token
    // Define ApplePayPaymentRequest
    /* Define the country code and currency being sent to the library, as well as
        the list of supported networks and the descriptor and amount of the transaction.*/
    const request = {
      countryCode: 'RO',
      currencyCode: 'RON',
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['visa', 'masterCard'],
      total: {
        label: 'Depunere',
        type: 'final',
        amount: this.amount,
      },
    };

    // Create ApplePaySession
    // @ts-ignore
    const session = new window.ApplePaySession(3, request);

    session.onvalidatemerchant = async (event: any) => {
      // Call your own server to request a new merchant session (call 2checkout API /rest/6.0/payments/startapplepaysession)
      fetch(window.config.front_url + '/api/pay-checkout/startAppleSession', {
        method: 'POST',
        body: JSON.stringify({
          u: event.validationURL,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => {
          return res.json();
        }) // Parse response as JSON.
        .then((merchantSession) => {
          session.completeMerchantValidation(merchantSession);
        })
        .catch((err) => {
          session.completePayment(session.STATUS_FAILURE);
        });
    };

    session.onpaymentmethodselected = (event: any) => {
      // Define ApplePayPaymentMethodUpdate based on the selected payment method.
      // No updates or errors are needed, pass an empty object.
      const update = {
        newTotal: {
          label: 'Depunere',
          type: 'final',
          amount: this.amount,
        },
      };
      session.completePaymentMethodSelection(update);
    };

    session.onshippingmethodselected = (event: any) => {
      // Define ApplePayShippingMethodUpdate based on the selected shipping method.
      // No updates or errors are needed, pass an empty object.
      const update = {
        newTotal: {
          label: 'Depunere',
          type: 'final',
          amount: this.amount,
        },
      };
      session.completeShippingMethodSelection(update);
    };

    session.onshippingcontactselected = (event: any) => {
      // Define ApplePayShippingContactUpdate based on the selected shipping contact.
      const update = {
        newTotal: {
          label: 'Depunere',
          type: 'final',
          amount: this.amount,
        },
      };
      session.completeShippingContactSelection(update);
    };

    session.onpaymentauthorized = (event: any) => {
      // Call your own server to request the Apply Pay token decryption and pass the decoded token to the place order call (call 2checkout APIs /rest/6.0/payments/decryptapplepaydata and /rest/6.0/orders/)
      // Define ApplePayPaymentAuthorizationResult based on the place order response

      const vivaAxios = VivaAPI.getInstance();
      const cardToken = event.payment.token;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const amount = this.amount;
      const bonusId = this.bonusId;
      const triggerError = this.triggerError;

      vivaAxios({
        url: '/nativecheckout/v2/chargetokens',
        method: 'POST',
        data: {
          amount: this.amount,
          token: JSON.stringify(cardToken),
          sessionRedirectUrl:
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '') +
            '/deposit',
        },
      })
        .then(function (response) {
          if (!response) {
            throw new Error('[ERROR] Viva Charge Token With Card response is empty!');
          }

          if (!Object.prototype.hasOwnProperty.call(response, 'data')) {
            throw new Error("[ERROR] Viva Charge Token With Card response has no 'chargeToken' property");
          }
          if (
            Object.prototype.hasOwnProperty.call(response.data, 'redirectToACSForm') &&
            response.data.redirectToACSForm
          ) {
            window.localStorage.setItem(
              'deposit',
              JSON.stringify({
                data: {
                  chargeToken: response.data.chargeToken,
                },
                amount: amount,
                bonusId: bonusId,
              }),
            );

            const wrapper = document.getElementById(HTML_3DS_IFRAME_ID);
            if (wrapper) {
              wrapper.className = 'show';
              const range = document.createRange();
              const documentFragment = range.createContextualFragment(response.data.redirectToACSForm);
              wrapper.appendChild(documentFragment);
            }
          } else {
            sendVivaChargeToken(response.data.chargeToken);
          }
        })
        .catch(function (error) {
          session.completePayment(session.STATUS_FAILURE);
          triggerError();
        });
    };

    session.oncancel = (event: any) => {
      // Define ApplePayPaymentAuthorizationResult with failure status
      session.completePayment(session.STATUS_FAILURE);
      this.triggerError();
    };
    session.begin();

    const sendVivaChargeToken = (chargeToken: any, sendHolderName = true) => {
      const axios = ClientAPI.getInstance();
      let data = {
        chargeToken: chargeToken,
        amount: this.amount * 100,
        bonusId: this.bonusId ? this.bonusId : null,
        vivaDigitalWalletId: 2,
      };

      const pac = PlayerAbuseChecker.getInfo();
      data = {
        ...pac,
        ...data,
      };
      axios({
        url: '/api/pay-checkout/charge-token',
        method: 'post',
        data: data,
      })
        .then((response) => {
          if (!response) {
            session.completePayment(session.STATUS_FAILURE);
            throw new Error('[ERROR] Viva Charge Token response is empty!');
          }
          if (!Object.prototype.hasOwnProperty.call(response, 'result')) {
            session.completePayment(session.STATUS_FAILURE);
            throw new Error("[ERROR] Viva Charge Tokenresponse has no 'data' property");
          }
          // @ts-ignore
          if (Object.prototype.hasOwnProperty.call(response.result, 'ResponseCode')) {
            session.completePayment(session.STATUS_FAILURE);
          } else {
            // @ts-ignore
            if (response.status === 'OK') {
              session.completePayment(session.STATUS_SUCCESS);
              this.triggerSuccess();
            } else {
              session.completePayment(session.STATUS_FAILURE);
              this.triggerError();
            }
          }
        })
        .catch((error) => {
          session.completePayment(session.STATUS_FAILURE);
          this.triggerError();
        });
    };
  };

  // return (<div className="apple-pay-button apple-pay-button-white-with-line add-money" onClick={onApplePayButtonClicked}></div>);
}

export default VivaApplePay;
