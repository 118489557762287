import { playerConstants } from './constants';

export const playerLoad = (data) => ({
  type: playerConstants.LOAD,
  data,
});

export const playerLoaded = (data) => ({
  type: playerConstants.LOADED,
  data,
});

export const savePlayerNickname = (data) => ({
  type: playerConstants.SAVE,
  data,
});
