import { favoriteConstants } from './constants';

export const favoriteLoad = (pm, lm, pl, ll) => ({
	type: favoriteConstants.LOAD,
	pm,
	lm,
	pl,
	ll
});

export const favoriteTogglePrematchMatch = idMatch => ({
	type: favoriteConstants.TOGGLE_PREMATCH_MATCH,
	idMatch
});

export const favoriteToggleLiveMatch = idMatch => ({
	type: favoriteConstants.TOGGLE_LIVE_MATCH,
	idMatch
});

export const favoriteTogglePrematchLeague = idTournament => ({
	type: favoriteConstants.TOGGLE_PREMATCH_LEAGUE,
	idTournament
});

export const favoriteToggleLiveLeague = idTournament => ({
	type: favoriteConstants.TOGGLE_LIVE_LEAGUE,
	idTournament
});
