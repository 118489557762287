import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { withTranslation } from "react-i18next";

import "./YouCollected.scss";
import YouCollectedLogo from "./assets/you-collected-logo.png";
import WinnerCoinImg from "../../assets/winner-fun-logo.svg";
import { animateValue } from "../../utils/functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const YouCollected = props => {
  const { open, handleClose, amount, walletTotal, t } = props;

  const [animatedState, setAnimatedState] = React.useState({
    balance: walletTotal,
    decAmount: 0,
  });

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  });

  React.useState(() => {
    if (amount) {
      let startBalance = walletTotal;
      let endBalance = walletTotal + amount;

      animateValue((progress) => {
        // stop animating if the component has been unmounted
        if (!mounted.current) return true;

        setAnimatedState({
          balance: (progress * (endBalance - startBalance) + startBalance).toFixed(2),
          decAmount: amount - Math.floor(progress * amount),
        });

        return false;
      }, 1500);
    }
  }, [amount]);

  if (!open) return null;

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={`winter-fun-modal-you-collected betsModal betsModalDialog`}
    >
      <DialogContent className="wf-dialog-content">
        <img src={YouCollectedLogo} className="wf-header-icon" alt="winner fun logo" />

        <div className="wf-title">{t("You've collected {{AMOUNT}} WinCoins", { AMOUNT: amount })}</div>

        <div className="wf-flex">
          <div className="wf-header">
            <div>{t("Your balance")}</div>
            {animatedState.decAmount > 0 && <div>+{animatedState.decAmount}</div>}
          </div>
          <div className="wf-coins">
            <div className="wf-inner-wrapper">
              <img src={WinnerCoinImg} alt="winner coin" />
              <div className={"wf-text"}>
                {animatedState.balance}
              </div>
            </div>
          </div>
        </div>

        <div className="wf-button-wrapper">
          <ButtonBase className="wf-button" onClick={handleClose}>
            {t("Good luck")}
          </ButtonBase>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withTranslation()(YouCollected);
