import * as actionTypes from './../actions/actionTypes';
import ResponseErrorMessages from '@/modules/casino/store/errors/ResponseErrorMessages.js';

const realInitialState = {
  errors: {
    phone: undefined,
    email: undefined,
    password: undefined,
    otp: undefined,
    global: undefined,
  },
  /*
  fields: {
    phone: window.localStorage.getItem('phone') ?? undefined, // undefined,
    email: window.localStorage.getItem('email') ?? undefined, // undefined,

    nin: window.localStorage.getItem('nin') ?? undefined, // undefined,
    firstName: window.localStorage.getItem('firstName') ?? undefined, // undefined,
    lastName: window.localStorage.getItem('lastName') ?? undefined, // undefined,
    address: window.localStorage.getItem('address') ?? undefined, // undefined,

    // moid: window.localStorage.getItem('moid'),
    // lpid: window.localStorage.getItem('lpid'),
  },
  */
  signedUp: undefined,
  signedUpErrors: undefined,
  partialAccount: {
    loading: false,
    error: false,
  },
};

const initialState = realInitialState;

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.register.VALIDATE_FIELD_RESPONSE:
      const errors: any = { ...state.errors };
      errors[action.field] = action.message;
      return {
        ...state,
        errors: errors,
      };
    case actionTypes.register.SET_PARTIAL_ACCOUNT:
      return {
        ...state,
        partialAccount: {
          ...state.partialAccount,
          loading: true,
        },
      };
    case actionTypes.register.PARTIAL_ACCOUNT_RESPONSE:
      return {
        ...state,
        partialAccount: {
          ...state.partialAccount,
          loading: false,
          error: !action.data.success,
        },
      };
    case actionTypes.register.RESET_REGISTER:
      return {
        ...realInitialState,
      };

    case actionTypes.register.REGISTER_RESPONSE:
      let signedUp = true;
      let signedUpErrors: any = undefined;

      // console.log('DEBUG reducer action:', action);
      // console.log('DEBUG reducer state:', state);
      // action.error = array of errors

      if (typeof action.errors !== 'undefined') {
        // case when we have a response code
        if (typeof action.errors.ResponseCode !== 'undefined') {
          // if is string
          signedUp = false;
          signedUpErrors = ResponseErrorMessages.get(action.errors.ResponseCode);
        } else {
          // case when we have a validation message
          if (typeof action.errors.validation_messages !== 'undefined') {
            signedUp = false;
            signedUpErrors = action.errors.validation_messages;
          } else {
            if (typeof action.errors.errors !== 'undefined') {
              signedUp = false;
              signedUpErrors = action.errors.errors[0];
              if (typeof signedUpErrors[1] !== 'undefined') {
                signedUpErrors = signedUpErrors[1];
              } else {
                // check if obj
                if (typeof signedUpErrors === 'object') {
                  signedUpErrors = Object.values(signedUpErrors).join(' , '); // TODO: check if we display only 1 error
                }
              }
            }
          }
        }
      } else {
        signedUp = action.value;
        if (!signedUp) {
          signedUpErrors = 'unknown sign up error';
        }
      }
      return {
        ...state,
        signedUp: signedUp,
        errors: {
          ...state.errors,
          global: signedUpErrors,
        },
      };
    /*
    case actionTypes.register.UPDATE_REGISTER_FIELDS:
      const fieldValues = { ...state.fields };

      console.log('DEBUG REGISTER REDUCER: ', action.data);

      Object.keys(action.data).forEach((fieldName: string) => {
        switch (fieldName) {
          case 'phone':
            fieldValues['phone'] = action.data[fieldName];
            break;
          case 'email':
            fieldValues['email'] = action.data[fieldName];
            break;
          case 'firstName':
            fieldValues['firstName'] = action.data[fieldName];
            break;
          case 'lastName':
            fieldValues['lastName'] = action.data[fieldName];
            break;
          case 'nin':
            fieldValues['nin'] = action.data[fieldName];
            break;
          case 'address':
            fieldValues['address'] = action.data[fieldName];
            break;
          
          // case 'moid':
          // case 'lpid':
          // window.localStorage.setItem(fieldName, action.data[fieldName]);
          // fieldValues[fieldName] = action.data[fieldName];
          // break;
          
          default:
            // @ts-ignore
            fieldValues[fieldName] = action.data[fieldName];
            break;
        }
      });

      return {
        ...state,
        fields: fieldValues,
      };
    */
    default:
      return state;
  }
};

export default reducer;
