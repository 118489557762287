import React from 'react';

import styled from 'styled-components';
import * as EmailValidator from 'email-validator';

import { useAppSelector, useAppDispatch } from '../../../store';
import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { getMarketingOffer } from '../../../modules/casino/store/actions/marketing_offers';

import PhoneNumberValidator from '../../../modules/casino/utils/PhoneNumberValidator';
import {
  validateField,
  sendRegisterSMS,
  register,
  // updateRegisterFields,
  setPartialAccount,
} from '../../../modules/casino/store/actions/register';
import ResponseErrorMessages from '../../../modules/casino/store/errors/ResponseErrorMessages';
import PasswordValidator from '@/utils/PasswordValidator';
import { loginMethod, loginPassword, loginStart, loginUsername } from '@/modules/casino/store/actions/login';
import { IS_EMAIL } from '@/modules/casino/utils/LoginMethods';
import NINValidator from '@/modules/casino/utils/NINValidator';
import ExpiringLocalStorage from '../../../modules/casino/utils/ExpiringLocalStorage';
import PlayOnline from '@/utils/marketing/Providers/PlayOnline';
import MarketingEvents from '@/utils/marketing/MarketingEvents';
import { requestDocumentsMaxFileSize } from '@/modules/casino/store/actions';
import { scanDocument } from '@/modules/casino/store/actions/druid';
import { useTranslation } from 'react-i18next';
import Utils from '@/modules/casino/utils/Utils';
import { getText } from '@/modules/lobby/utils/functions';
import i18n from '@/utils/i18n';

type RegisterProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

type ModuleStateProps = {
  showCheckboxes: boolean;
  currentStep: string;
  savedStep: string;
  tcAccepted: boolean;
  marketingAccepted: {
    email: boolean;
    sms: boolean;
    phone: boolean;
    partner: boolean;
  };
  errors: {
    email: string | undefined | boolean;
    phone: string | undefined | boolean;
    otp: string | undefined | boolean;
    password: string | undefined | boolean;
    nickname: string | undefined | boolean;
    firstName: string | undefined | boolean;
    lastName: string | undefined | boolean;
    nin: string | undefined | boolean;
    address: string | undefined | boolean;
    OCR: string | undefined | boolean;
  };
  fields: {
    email: string;
    phone: string;
    password: string;
    passwordConfirm: string;
    otp: string;
    nickname: string;
    firstName: string;
    lastName: string;
    nin: string;
    address: string;
    ocr_scan: string | null;
  };
  focusedFields: {
    phone: boolean;
    email: boolean;
    password: boolean;
    otp: boolean;
    nickname: boolean;
    firstName: boolean;
    lastName: boolean;
    nin: boolean;
    address: boolean;
  };
  bonusValueUnlocked: number;
  bonusValueToUnlock: number;
  resendTimer: number;
  passwordInputType: string;
  passwordStrengthScore: number;
  passwordStrengthShortLabel: string;
  passwordStrengthLongLabel: string;
  passwordInputIcon: string;
  showConfirmPassword: boolean;

  dialogOpen: boolean;
  loading: boolean;

  file: any;
  uploadedOCRFile: any;
  OCRModalOpen: boolean;
  OCRModalScreen: string;
  documentUploadError: boolean | string;
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const REGISTER_CURACAO_ONE_PAGE = '1';
const REGISTER_CURACAO_TWO_PAGE = '2';
const REGISTER_RO_THREE_PAGE = '3';

const Register = (componentProps: RegisterProps) => {
  const { t } = useTranslation();
  const NICKNAME_SERVER_VALIDATION = false;

  const DEFAULT_TRIGGER_TIME = 3000;
  const DEFAULT_INCREMENT_TIME = 1000;

  const stepBackMappping = {
    [REGISTER_RO_THREE_PAGE]: {
      step2: 'step1',
      step3: 'step2',
      OTP: 'step1',
      final: 'step3',
    },
    [REGISTER_CURACAO_ONE_PAGE]: {
      OTP: 'step1',
      final: 'step1',
    },
    [REGISTER_CURACAO_TWO_PAGE]: {
      OTP: 'step1',
      step2: 'step1',
      final: 'step2',
    },
  };

  const TYPE_EMAIL = 'email';
  const TYPE_PHONE = 'phone';
  const TYPE_OTP = 'otp';
  const TYPE_PASSWORD = 'password';
  const TYPE_NICKNAME = 'nickname';
  const TYPE_FIRST_NAME = 'firstName';
  const TYPE_LAST_NAME = 'lastName';
  const TYPE_NIN = 'nin';
  const TYPE_ADDRESS = 'address';

  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const dispatch = useAppDispatch();
  const { marketingOffer } = useAppSelector((state) => state.marketingOffer);
  const { errors, signedUp, signedUpErrors, partialAccount } = useAppSelector((state) => state.register);
  const { client_player_id } = useAppSelector((state) => state.profile);
  const { documentsMaxFileSize } = useAppSelector<any>((state) => state.documents);
  const druid = useAppSelector<any>((state) => state.druid);

  const loadRegisterFieldsFromStorage = () => {
    const loadedFields = ExpiringLocalStorage.get('registerFields');

    if (loadedFields) {
      return JSON.parse(loadedFields);
    }
    return {};
  };

  const initialState = {
    currentStep: 'step1',
    savedStep: 'step1',
    loading: false,
    errors: {
      email: false,
      phone: false,
      otp: false,
      password: false,
      nickname: false,
      firstName: false,
      lastName: false,
      nin: false,
      address: false,
      OCR: false,
    },
    fields: {
      email: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      otp: '',
      nickname: '',
      firstName: '',
      lastName: '',
      nin: '',
      address: '',
      ocr_scan: null,
    },
    focusedFields: {
      phone: false,
      email: false,
      password: false,
      otp: false,
      nickname: false,
      firstName: false,
      lastName: false,
      nin: false,
      address: false,
    },
    tcAccepted: false,
    // tcAccepted: true,
    marketingAccepted: {
      email: loadRegisterFieldsFromStorage().marketingAccepted?.email ?? false,
      sms: loadRegisterFieldsFromStorage().marketingAccepted?.sms ?? false,
      phone: loadRegisterFieldsFromStorage().marketingAccepted?.phone ?? false,
      partner: loadRegisterFieldsFromStorage().marketingAccepted?.partner ?? false,
    },
    bonusValueUnlocked: 0,
    bonusValueToUnlock: 0,
    resendTimer: 0,
    showCheckboxes: false,

    passwordInputType: 'password',
    passwordStrengthScore: 0,
    passwordStrengthShortLabel: 'Poor',
    passwordStrengthLongLabel: 'Password strength is poor',
    passwordInputIcon: 'bi-eye-fill',
    showConfirmPassword: false,

    dialogOpen: false,
    file: null,
    uploadedOCRFile: null,
    OCRModalOpen: false,
    OCRModalScreen: '',
    documentUploadError: false,
  };

  const BONUS_FIELDS: string[] = [];
  if (props.properties.emailBonus) {
    BONUS_FIELDS.push('email');
  }
  if (props.properties.phoneBonus) {
    BONUS_FIELDS.push('phone');
  }
  if (props.properties.nicknameBonus) {
    BONUS_FIELDS.push('nickname');
  }
  if (props.properties.firstNameBonus) {
    BONUS_FIELDS.push('firstName');
  }
  if (props.properties.lastNameBonus) {
    BONUS_FIELDS.push('lastName');
  }
  if (props.properties.ninBonus) {
    BONUS_FIELDS.push('nin');
  }
  if (props.properties.addressBonus) {
    BONUS_FIELDS.push('address');
  }
  const [state, setState] = React.useState<ModuleStateProps>(initialState);
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);
  const [iTimer, setITimer] = React.useState<NodeJS.Timeout | null>(null);

  const calculateBonusValueUnlocked = () => {
    if (!marketingOffer) {
      return 0;
    }
    const ui = JSON.parse(marketingOffer.ui_elements);
    let startValue = 0;
    if (ui?.register_starting_value) {
      startValue = parseInt(ui.register_starting_value, 10) ?? 0;
    }

    let fieldsValid = 0;
    BONUS_FIELDS.forEach((field) => {
      // @ts-ignore
      if (state.fields[field]) {
        // @ts-ignore
        fieldsValid += typeof state.errors[field] !== 'undefined' && state.errors[field] === false ? 1 : 0;
      }
    });
    const bonusValueToUnlock =
      startValue +
      Math.round(((marketingOffer.register_value_units_numeric - startValue) / BONUS_FIELDS.length) * fieldsValid);
    setState((prevState) => ({
      ...prevState,
      bonusValueToUnlock: isNaN(bonusValueToUnlock) ? 0 : bonusValueToUnlock,
    }));
  };

  const saveRegisterFieldsInStorage = () => {
    const fieldValues = {
      email: state.fields.email,
      phone: state.fields.phone,

      // nickname: state.fields.nickname,
      firstName: state.fields.firstName,
      lastName: state.fields.lastName,
      nin: state.fields.nin,
      address: state.fields.address,
      marketingAccepted: {
        email: state.marketingAccepted.email,
        sms: state.marketingAccepted.sms,
        phone: state.marketingAccepted.phone,
        partner: state.marketingAccepted.partner,
      },
    };

    const storageValues = loadRegisterFieldsFromStorage();

    if (storageValues) {
      if (JSON.stringify(fieldValues) !== JSON.stringify(storageValues)) {
        ExpiringLocalStorage.set('registerFields', JSON.stringify(fieldValues));
      }
    }
  };

  React.useEffect(() => {
    saveRegisterFieldsInStorage();
  }, [
    state.fields.phone,
    state.fields.email,
    state.fields.firstName,
    state.fields.lastName,
    state.fields.nin,
    state.fields.address,
    state.marketingAccepted,
  ]);

  React.useEffect(() => {
    if (druid.inProgress === false && druid.data.ResultId) {
      const globalConfidence = 0.6;

      let readingOK = druid.data.Status === 'Complete';
      if (readingOK) {
        // check confidence for each field
        readingOK =
          druid.data.Confidence >= globalConfidence &&
          druid.data.Data['Face.Confidence'] >= globalConfidence &&
          druid.data.Data['Nume.Confidence'] >= globalConfidence &&
          druid.data.Data['Prenume.Confidence'] >= globalConfidence &&
          druid.data.Data['CNP.Confidence'] >= globalConfidence &&
          druid.data.Data['Domiciliu.Confidence'] >= globalConfidence;

        if (readingOK) {
          setState((prevState) => ({
            ...prevState,
            fields: {
              ...prevState.fields,
              firstName: druid.data.Data.Prenume,
              lastName: druid.data.Data.Nume,
              nin: druid.data.Data.CNP,
              address: druid.data.Data.Domiciliu,
            },
          }));

          triggerValidator(TYPE_FIRST_NAME, druid.data.Data.Prenume, 0);
          triggerValidator(TYPE_LAST_NAME, druid.data.Data.Nume, 0);
          triggerValidator(TYPE_NIN, druid.data.Data.CNP, 0);
          triggerValidator(TYPE_ADDRESS, druid.data.Data.Domiciliu, 0);
        }
      }

      setState((prevState) => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          ocr_scan: readingOK ? druid.data.ResultId : null,
        },
        errors: {
          ...prevState.errors,
          OCR: readingOK ? false : t('Scan failed. Ensure the ID photo is clear and try again.'),
        },
        OCRModalOpen: false,
      }));
    } else {
      if (druid.data.error) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            OCR: t('Scan failed. Ensure the ID photo is clear and try again.'),
          },
          OCRModalOpen: false,
        }));
      }
    }
  }, [druid]);

  React.useEffect(() => {
    // force processing the url
    PlayOnline.processUrl(); // in some cases this function is triggered two times. This is not a problem
    // autocomplete from localstorage
    const loadedFields = loadRegisterFieldsFromStorage();

    const firstName = loadedFields.firstName ?? '';
    const lastName = loadedFields.lastName ?? '';
    const nin = loadedFields.nin ?? '';
    const address = loadedFields.address ?? '';

    setState((prevState) => ({
      ...prevState,
      ...initialState,
      fields: {
        ...prevState.fields,
        firstName: firstName,
        lastName: lastName,
        nin: nin,
        address: address,
      },
    }));

    if (firstName) {
      triggerValidator(TYPE_FIRST_NAME, firstName, 0);
    }
    if (lastName) {
      triggerValidator(TYPE_LAST_NAME, lastName, 0);
    }
    if (nin) {
      triggerValidator(TYPE_NIN, nin, 0);
    }
    if (address) {
      triggerValidator(TYPE_ADDRESS, loadedFields.address ?? '', 0);
    }
    dispatch(getMarketingOffer(PlayOnline.getSavedField('moid')));
    if (!documentsMaxFileSize || documentsMaxFileSize === 0) {
      dispatch(requestDocumentsMaxFileSize());
    }
  }, []);

  React.useEffect(() => {
    if (!partialAccount?.loading) {
      if (partialAccount?.error) {
        if (state.currentStep === 'OTP') {
          setState((prevState) => ({
            ...prevState,
            errors: { ...prevState.errors, otp: t('Invalid OTP') },
            loading: false,
            // fields: { ...prevState.fields, otp: '' },
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            currentStep: 'step1',
            errors: { ...prevState.errors, global: t('Unknown error') },
            loading: false,
          }));
        }
      } else {
        setState((prevState) => ({ ...prevState, currentStep: prevState.savedStep, loading: false }));
      }
    }
  }, [partialAccount]);

  React.useEffect(() => {
    if (props && props.properties && props.properties.tcAcceptedByDefault === state.tcAccepted) return;
    if (props.properties.tcAcceptedByDefault !== true) return;

    setState((prevState) => ({
      ...prevState,
      tcAccepted: true,
    }));
  }, [state, props.properties.tcAcceptedByDefault]);

  React.useEffect(() => {
    if (iTimer) {
      clearInterval(iTimer);
    }
    let nextValue = state.bonusValueUnlocked;
    const incrementType = state.bonusValueUnlocked < state.bonusValueToUnlock ? 1 : -1;
    const lTimer = setInterval(
      () => {
        if (nextValue >= state.bonusValueToUnlock || nextValue < 0) {
          if (iTimer) {
            clearInterval(iTimer);
          }
          setState((prevState) => ({
            ...prevState,
            bonusValueUnlocked: state.bonusValueToUnlock,
          }));
          return;
        } else {
          nextValue = nextValue + incrementType;
        }

        setState((prevState) => ({
          ...prevState,
          bonusValueUnlocked: nextValue,
        }));
      },
      DEFAULT_INCREMENT_TIME / (state.bonusValueToUnlock - state.bonusValueUnlocked),
    );

    setITimer(lTimer);
  }, [state.bonusValueToUnlock]);

  React.useEffect(() => {
    // loop through errors and set state

    const tmpErrorState = {};
    Object.keys(errors).forEach((key) => {
      let error: boolean | string | undefined = undefined;
      if (errors[key]) {
        // @ts-ignore
        const lError = errors[key];
        if (typeof lError.ResponseCode !== 'undefined') {
          error = ResponseErrorMessages.get(lError.ResponseCode);
        }
      }
      if (typeof error !== 'undefined') {
        // @ts-ignore
        tmpErrorState[key] = error;
      } else if (errors?.[key]?.length === 0) {
        // @ts-ignore
        tmpErrorState[key] = false;
      }
    });
    setState({
      ...state,
      errors: {
        ...state.errors,
        ...tmpErrorState,
      },
      loading: false,
    });
  }, [errors]);

  React.useEffect(() => {
    // timeout to decrease resend timer every second
    if (state.resendTimer > 0) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          resendTimer: prevState.resendTimer - 1,
        }));
      }, 1000);
    }
  }, [state.resendTimer]);

  React.useEffect(() => {
    calculateBonusValueUnlocked();
  }, [state.errors, marketingOffer]);

  React.useEffect(() => {
    if (signedUp) {
      setState({
        ...initialState,
        // replaced final step from page to modal
        currentStep: 'step3',
        dialogOpen: true,
        loading: false,
        // keep some info to be used after popup is open
        bonusValueToUnlock: marketingOffer?.register_value_units_numeric ?? 0,
        bonusValueUnlocked: marketingOffer?.register_value_units_numeric ?? 0,
      });
      dispatch(loginUsername(state.fields.email));
      dispatch(loginPassword(state.fields.password));
      dispatch(loginMethod(IS_EMAIL));
      dispatch(loginStart(undefined));
      ExpiringLocalStorage.set('registerFields', JSON.stringify({}), 0);
      PlayOnline.clearPaid();
      MarketingEvents.signUp();
    } else {
      setState({
        ...state,
        currentStep: initialState.currentStep,
        loading: false,
      });
    }
  }, [signedUp]);

  React.useEffect(() => {
    if (signedUp) {
      MarketingEvents.signUpCompleted({ playerId: client_player_id });
    }
  }, [client_player_id]);
  const goBack = () => {
    // @ts-ignore
    if (stepBackMappping?.[props.properties.registerType]?.[state.currentStep]) {
      setState((prevState) => ({
        ...prevState,
        // @ts-ignore
        currentStep: stepBackMappping[props.properties.registerType][prevState.currentStep],
      }));
    }
  };

  const triggerValidator = (type: string, value: string, time: number) => {
    // delayed validation to prevent multiple calls
    if (timer) {
      clearTimeout(timer);
    }
    const lTimer = setTimeout(() => {
      switch (type) {
        case TYPE_EMAIL:
          emailValidator(value);
          break;
        case TYPE_PHONE:
          phoneValidator(value);
          break;
        case TYPE_PASSWORD:
          passwordValidator(value);

          break;
        case TYPE_NICKNAME:
          nicknameValidator(value);
          break;
        case TYPE_FIRST_NAME:
          firstNameValidator(value);
          break;
        case TYPE_LAST_NAME:
          lastNameValidator(value);
          break;
        case TYPE_NIN:
          ninValidator(value);
          break;
        case TYPE_OTP:
          otpValidator(value);
          break;
        default:
          break;
      }
    }, time);
    setTimer(lTimer);
  };

  const emailValidator = (email: string) => {
    if (email === '') {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          email: undefined,
        },
      }));
      return;
    }
    const valid = EmailValidator.validate(email);
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          email: !valid ? t('Invalid email address') : false,
        },
      }));
    } else {
      if (props.properties.emailServerValidation) {
        dispatch(validateField(email, 'email'));
      } else {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            email: !valid ? t('Invalid email address') : false,
          },
        }));
      }
    }
  };

  const otpValidator = (otp: string) => {
    if (otp === '') {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          otp: undefined,
        },
      }));
      return;
    }
    const valid = otp.length > 3;
    setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        otp: !valid ? t('Invalid OTP') : false,
      },
    }));
  };

  const passwordValidator = (password: string) => {
    const passwordIsValid = PasswordValidator(password);

    setState((prevState) => ({
      ...prevState,
      passwordStrengthScore: passwordIsValid.score,
      passwordStrengthShortLabel: passwordIsValid.labels.short,
      passwordStrengthLongLabel: passwordIsValid.labels.long,
      passwordConditionsNotMet: passwordIsValid.conditionsNotMet,
      errors: {
        ...prevState.errors,
        password: !passwordIsValid.success ? passwordIsValid.message : false,
      },
    }));
  };

  const phoneValidator = (phone: string) => {
    const newState: ModuleStateProps = { ...state };
    if (phone === '') {
      setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            phone: undefined,
          },
        };
      });
    }
    const valid = PhoneNumberValidator.validate(phone);
    if (!valid) {
      setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            phone: !valid ? t('Invalid phone number') : false,
          },
        };
      });
    } else {
      if (props.properties.phoneServerValidation) {
        dispatch(validateField(phone, 'phone'));
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            errors: {
              ...prevState.errors,
              phone: !valid ? t('Invalid phone number') : false,
            },
          };
        });
      }
    }
  };

  const nicknameValidator = (nickname: string) => {
    // minimum 4 chars and maximum 100 chars
    const valid = nickname.length >= 4 && nickname.length <= 100;
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          nickname: !valid ? t('Invalid nickname') : false,
        },
      }));
    } else {
      if (NICKNAME_SERVER_VALIDATION) {
        dispatch(validateField(nickname, 'nickname'));
      } else {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            nickname: !valid ? t('Invalid nickname') : false,
          },
        }));
      }
    }
  };

  const firstNameValidator = (firstName: string) => {
    // minimum 3 chars and maximum 100 chars
    const valid = firstName.length >= 3 && firstName.length <= 100;
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          firstName: !valid ? t('Invalid first name') : false,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          firstName: false,
        },
      }));
    }
  };

  const lastNameValidator = (lastName: string) => {
    // minimum 3 chars and maximum 100 chars
    const valid = lastName.length >= 3 && lastName.length <= 100;
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          lastName: !valid ? t('Invalid last name') : false,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          lastName: false,
        },
      }));
    }
  };

  const addressValidator = (address: string) => {
    // minimum 3 chars and maximum 100 chars
    const valid = address.length >= 3 && address.length <= 100;
    if (!valid) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          address: !valid ? t('Invalid address') : false,
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          address: false,
        },
      }));
    }
  };

  const ninValidator = (nin: string) => {
    const newState: ModuleStateProps = { ...state };
    if (nin === '') {
      setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            nin: undefined,
          },
        };
      });
    }
    const valid = NINValidator.validate(nin);
    if (!valid) {
      setState((prevState) => {
        return {
          ...prevState,
          errors: {
            ...prevState.errors,
            nin: !valid ? t('Invalid national identification number') : false,
          },
        };
      });
    } else {
      if (props.properties.ninServerValidation) {
        dispatch(validateField(nin, 'nin'));
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            errors: {
              ...prevState.errors,
              nin: !valid ? t('Invalid national identification number') : false,
            },
          };
        });
      }
    }
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        email: email,
      },
    }));
    triggerValidator(TYPE_EMAIL, email, DEFAULT_TRIGGER_TIME);
  };

  const onNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nickname = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        nickname: nickname,
      },
    }));
    triggerValidator(TYPE_NICKNAME, nickname, DEFAULT_TRIGGER_TIME);
  };

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const firstName = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        firstName: firstName,
        ocr_scan: null,
      },
    }));
    triggerValidator(TYPE_FIRST_NAME, firstName, DEFAULT_TRIGGER_TIME);
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lastName = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        lastName: lastName,
        ocr_scan: null,
      },
    }));
    triggerValidator(TYPE_LAST_NAME, lastName, DEFAULT_TRIGGER_TIME);
  };

  const onNinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nin = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        nin: nin,
        ocr_scan: null,
      },
    }));
    triggerValidator(TYPE_NIN, nin, DEFAULT_TRIGGER_TIME);
  };

  const onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const address = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        address: address,
        ocr_scan: null,
      },
    }));
    triggerValidator(TYPE_ADDRESS, address, DEFAULT_TRIGGER_TIME);
  };

  const onEmailBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerValidator(TYPE_EMAIL, state.fields.email, 0);

    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        email: false,
      },
    }));
  };

  const onNicknameBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerValidator(TYPE_NICKNAME, state.fields.nickname, 0);
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        nickname: false,
      },
    }));
  };

  const onFirstNameBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerValidator(TYPE_FIRST_NAME, state.fields.firstName, 0);
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        firstName: false,
      },
    }));
  };

  const onLastNameBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerValidator(TYPE_LAST_NAME, state.fields.lastName, 0);
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        lastName: false,
      },
    }));
  };

  const onNinBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerValidator(TYPE_NIN, state.fields.nin, 0);
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        nin: false,
      },
    }));
  };

  const onAddressBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerValidator(TYPE_ADDRESS, state.fields.address, 0);
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        address: false,
      },
    }));
  };

  const onFocusEmail = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, email: undefined },
      focusedFields: { ...prevState.focusedFields, email: true },
    }));
  };

  const onFocusNickname = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, nickname: undefined },
      focusedFields: { ...prevState.focusedFields, nickname: true },
    }));
  };

  const onFocusFirstName = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, firstName: undefined },
      focusedFields: { ...prevState.focusedFields, firstName: true },
    }));
  };

  const onFocusLastName = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, lastName: undefined },
      focusedFields: { ...prevState.focusedFields, lastName: true },
    }));
  };

  const onFocusNin = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, nin: undefined },
      focusedFields: { ...prevState.focusedFields, nin: true },
    }));
  };

  const onFocusAddress = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, address: undefined },
      focusedFields: { ...prevState.focusedFields, address: true },
    }));
  };

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phone = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        phone: phone,
      },
    }));
    triggerValidator(TYPE_PHONE, phone, DEFAULT_TRIGGER_TIME);
  };

  const onPhoneBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerValidator(TYPE_PHONE, state.fields.phone, 0);
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        phone: false,
      },
    }));
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        password: password,
      },
    }));
    triggerValidator(TYPE_PASSWORD, password, 0);
  };

  const onPasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        passwordConfirm: password,
      },
    }));
  };

  const onOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const otp = event.target.value;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        otp: otp,
      },
      errors: {
        ...prevState.errors,
        otp: false,
      },
    }));
  };

  const onOtpBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerValidator(TYPE_OTP, state.fields.otp, 0);
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        otp: false,
      },
    }));
  };

  const onOtpFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, otp: undefined },
      focusedFields: { ...prevState.focusedFields, otp: true },
    }));
  };

  const onPasswordFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, password: undefined },
      focusedFields: { ...prevState.focusedFields, password: true },
    }));
  };

  const onPasswordBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerValidator(TYPE_PASSWORD, state.fields.password, 0);
    setState((prevState) => ({
      ...prevState,
      focusedFields: {
        ...prevState.focusedFields,
        password: false,
      },
    }));
  };

  const onTcChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tcAccepted = event.target.checked;

    if (props.properties.tcAcceptedByDefault === true) return;

    setState((prevState) => ({ ...prevState, tcAccepted }));
  };

  const onMarketingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        email: marketingAccepted,
        sms: marketingAccepted,
        phone: marketingAccepted,
        partner: marketingAccepted,
      },
    }));
  };

  const onMarketingEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        email: marketingAccepted,
      },
    }));
  };

  const onMarketingSmsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        sms: marketingAccepted,
      },
    }));
  };

  const onMarketingPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        phone: marketingAccepted,
      },
    }));
  };

  const toggleShowCheckboxes = () => {
    setState((prevState) => ({
      ...prevState,
      showCheckboxes: !prevState.showCheckboxes,
    }));
  };

  const onMarketingPartnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const marketingAccepted = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        partner: marketingAccepted,
      },
    }));
  };
  const onPhoneFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, phone: undefined },
      focusedFields: { ...prevState.focusedFields, phone: true },
    }));
  };

  const onNicknameFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, nickname: undefined },
      focusedFields: { ...prevState.focusedFields, nickname: true },
    }));
  };

  const onFirstNameFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, firstName: undefined },
      focusedFields: { ...prevState.focusedFields, firstName: true },
    }));
  };

  const onLastNameFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, lastName: undefined },
      focusedFields: { ...prevState.focusedFields, lastName: true },
    }));
  };

  const onNinFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, nin: undefined },
      focusedFields: { ...prevState.focusedFields, nin: true },
    }));
  };

  const onAddressFocus = () => {
    setState((prevState) => ({
      ...prevState,
      errors: { ...prevState.errors, address: undefined },
      focusedFields: { ...prevState.focusedFields, address: true },
    }));
  };

  const isDisabled = () => {
    switch (state.currentStep) {
      case 'step1': {
        switch (props.properties.registerType) {
          case REGISTER_CURACAO_TWO_PAGE:
            return !state.tcAccepted || state.errors.email || state.errors.phone;
          case REGISTER_RO_THREE_PAGE:
            return !state.tcAccepted || state.errors.email || state.errors.phone;
          case REGISTER_CURACAO_ONE_PAGE:
            return (
              !state.tcAccepted ||
              state.errors.email ||
              state.errors.phone ||
              !state.fields.password ||
              state.errors.password
            );
          default:
            console.warn('Register type not supported for this step!');
            return false;
        }
        break;
      }
      case 'step2': {
        switch (props.properties.registerType) {
          case REGISTER_CURACAO_TWO_PAGE:
            return !state.fields.password || state.errors.password;
          case REGISTER_RO_THREE_PAGE:
            return state.errors.firstName || state.errors.lastName || state.errors.nin || state.errors.address;
          default:
            console.warn('Register type not supported for this step!');
            return false;
        }
        break;
      }
      case 'step3': {
        switch (props.properties.registerType) {
          case REGISTER_RO_THREE_PAGE:
            return !state.fields.password || state.errors.password;
          default:
            console.warn('Register type not supported for this step!');
            return false;
        }
      }
      case 'OTP': {
        return !state.fields.otp || state.errors.otp;
      }
      default: {
        return true;
      }
    }
  };

  const sendSMS = () => {
    if (state.resendTimer > 0) {
      return;
    }
    dispatch(sendRegisterSMS(state.fields.phone));
    setState((prevState) => ({ ...prevState, resendTimer: 60 }));
  };

  const signUp = () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const data = {
      phone: state.fields.phone,
      phone_country_code: 'RO',
      nickname: state.fields.nickname,
      email: state.fields.email,
      password: state.fields.password,
      marketing_email: state.marketingAccepted.email ? 1 : 0,
      marketing_phone: state.marketingAccepted.phone ? 1 : 0,
      marketing_sms: state.marketingAccepted.sms ? 1 : 0,
      marketing_partners: state.marketingAccepted.partner ? 1 : 0,
      marketingAgreement:
        state.marketingAccepted.email ||
        state.marketingAccepted.phone ||
        state.marketingAccepted.sms ||
        state.marketingAccepted.partner
          ? 1
          : 0,
      moid: marketingOffer ? marketingOffer.moid : undefined,
      firstName: state.fields.firstName,
      lastName: state.fields.lastName,
      nin: state.fields.nin,
      address: state.fields.address,
      phoneValidationCode: state.fields.otp,
      paid: PlayOnline.getPaid(), // todo remove paid if not needed
      refferenceCode: PlayOnline.getSavedField('aff_id'),
      /** */
      sub_id: PlayOnline.getSavedField('sub_id'),
      gclid: PlayOnline.getSavedField('gclid'),
      lpid: PlayOnline.getSavedField('lpid'),
      // product_id: not implemented on FE yet;
      winner_referral_id: PlayOnline.getSavedField('winner_referral_id'),
      ocr_scan: state.fields.ocr_scan,
      // dvfp: send automatically from saga
      // nwfp: send automatically from saga
      // newfp: send automatically from saga
    };
    MarketingEvents.signUpStarted();
    dispatch(register(data));
  };

  const onNextSteptWithAllConditions = () => {
    if (state.currentStep !== 'step1' || isDisabled()) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      marketingAccepted: {
        ...prevState.marketingAccepted,
        email: true,
        sms: true,
        phone: true,
        partner: true,
      },
    }));

    nextStep();
  };

  const usePartialAccount = (nextStep: string) => {
    if (!props.properties.usePartialAccount) {
      // go to next step
      setState((prevState) => ({ ...prevState, currentStep: nextStep }));
      return;
    } else {
      // save next step for later
      setState((prevState) => ({ ...prevState, savedStep: nextStep }));
    }

    const data: any = {
      paid: PlayOnline.getPaid(),
      aff_id: PlayOnline.getSavedField('aff_id'),
      sub_id: PlayOnline.getSavedField('sub_id'),
      gclid: PlayOnline.getSavedField('gclid'),
      lpid: PlayOnline.getSavedField('lpid'),
      moid: PlayOnline.getSavedField('moid'),
      phone: state.fields.phone ?? null,
      email: state.fields.email ?? null,
      nin: state.fields.nin ?? null,
      first_name: state.fields.firstName ?? null,
      last_name: state.fields.lastName ?? null,
      marketing:
        (state.marketingAccepted?.email ||
          state.marketingAccepted.sms ||
          state.marketingAccepted.phone ||
          state.marketingAccepted.partner) ??
        null,
      marketing_sms: state.marketingAccepted.sms ?? null,
      marketing_email: state.marketingAccepted.email ?? null,
      marketing_phone: state.marketingAccepted.phone ?? null,
      marketing_partner: state.marketingAccepted.partner ?? null,
      address: state.fields.address ?? null,
      ocr_scan: state.fields.ocr_scan ?? null, // TODO: add ocr scan when implementing druid
    };
    if (state.fields.otp && state.currentStep === 'OTP') {
      data.phoneValidationCode = state.fields.otp; // !!!!use this only when you are on OTP screen!!!!
    }
    setState((prevState) => ({ ...prevState, loading: true }));
    dispatch(setPartialAccount(data));
  };

  const nextStep = () => {
    // check if we can go to next step

    if (isDisabled()) {
      return;
    }

    switch (state.currentStep) {
      case 'step1': {
        switch (props.properties.registerType) {
          case REGISTER_CURACAO_ONE_PAGE: {
            if (props.properties.otpActive) {
              sendSMS();
              setState((prevState) => ({ ...prevState, currentStep: 'OTP' }));
            } else {
              signUp();
            }
            break;
          }
          case REGISTER_CURACAO_TWO_PAGE: {
            usePartialAccount('step2');
            break;
          }
          case REGISTER_RO_THREE_PAGE: {
            if (props.properties.otpActive) {
              sendSMS();
              setState((prevState) => ({ ...prevState, currentStep: 'OTP' }));
            } else {
              usePartialAccount('step2');
            }
            break;
          }
          default:
            console.warn('Register type not supported for next step!');
            break;
        }
        break;
      }
      case 'step2': {
        switch (props.properties.registerType) {
          case REGISTER_CURACAO_TWO_PAGE: {
            if (props.properties.otpActive) {
              sendSMS();
              setState((prevState) => ({ ...prevState, currentStep: 'OTP' }));
            } else {
              signUp();
            }
            break;
          }
          case REGISTER_RO_THREE_PAGE: {
            // if (props.properties.otpActive) {
            //   sendSMS();
            //   setState((prevState) => ({ ...prevState, currentStep: 'step3' }));
            //   // setState((prevState) => ({ ...prevState, currentStep: 'OTP' }));
            // } else {
            usePartialAccount('step3');
            // }
            break;
          }
          default: {
            console.warn('Register type not supported for next step!');
          }
        }

        break;
      }
      case 'step3': {
        switch (props.properties.registerType) {
          case REGISTER_RO_THREE_PAGE: {
            signUp();
            break;
          }
          default: {
            console.warn('Register type not supported for next step!');
            break;
          }
        }
        break;
      }
      case 'OTP': {
        switch (props.properties.registerType) {
          case REGISTER_CURACAO_ONE_PAGE:
          case REGISTER_CURACAO_TWO_PAGE: {
            signUp();
            break;
          }
          case REGISTER_RO_THREE_PAGE: {
            usePartialAccount('step2');
            break;
          }
          default: {
            console.warn('Register type not supported for next step!');
            break;
          }
        }
        break;
      }
      default: {
        return;
      }
    }
  };

  const toggleShowPassword = () => {
    setState((prevState) => ({
      ...prevState,
      passwordInputType: state.passwordInputType === 'password' ? 'text' : 'password',
      passwordInputIcon: state.passwordInputType === 'password' ? 'bi-eye-slash-fill' : 'bi-eye-fill',
    }));
  };

  const toggleShowConfirmPassword = () => {
    setState((prevState) => ({
      ...prevState,
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };

  const showUploadScreen = (e: any) => {
    const el = e.currentTarget ?? (e.currentTarget as HTMLElement);
    if (!documentsMaxFileSize || documentsMaxFileSize === 0) {
      dispatch(requestDocumentsMaxFileSize());
    }

    setState({
      ...state,
      OCRModalOpen: true,
      OCRModalScreen: 'upload',
    });
  };

  const toggleUploadScreen = () => {
    setState({
      ...state,
      OCRModalOpen: !state.OCRModalOpen,
      OCRModalScreen: 'upload',
      file: null,
      uploadedOCRFile: null,
    });
  };

  const uploadDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    // upload document
    dispatch(scanDocument(state.file));
  };
  const openCamera = (e: any) => {
    // open camera
    onSelectFile(e);
  };

  const onSelectFile = (e: any) => {
    // get files from input event e
    const files = e?.target?.files;
    setState((v) => ({ ...v, documentUploadError: false }));

    const size = documentsMaxFileSize ? documentsMaxFileSize.replace('Mb', '') : '2';
    Object.keys(files).forEach((attr) => {
      if (files[attr].size / 1048576 < size) {
        // check if file is image
        if (files[attr].type.includes('image')) {
          // use file reader
          const reader = new FileReader();
          const currentFile = files[attr];
          reader.onload = (e: any) => {
            setState({
              ...state,
              uploadedOCRFile: e.target.result,
              file: currentFile,
              OCRModalScreen: 'confirm-upload',
              documentUploadError: false,
            });
          };
          return reader.readAsDataURL(files[attr]);
        } else {
          setState((v) => ({ ...v, documentUploadError: 'DOCUMENT_UPLOAD_FILE_TYPE_NOT_SUPPORTED' }));
        }
        return;
      } else {
        setState((v) => ({ ...v, documentUploadError: 'DOCUMENT_UPLOAD_FILE_TOO_BIG' }));

        console.warn('File size is too big');
      }
    });
  };

  const ui_elements = JSON.parse(marketingOffer?.ui_elements ?? '{}');
  ui_elements.bonusId = marketingOffer?.bonus_preset_id ?? false;

  const contextValue = {
    ...state,
    emailError: state.errors.email === undefined || !state.errors.email ? '' : state.errors.email,
    phoneError: state.errors.phone === undefined || !state.errors.phone ? '' : state.errors.phone,
    otpError: state.errors.otp === undefined || !state.errors.otp ? '' : state.errors.otp,
    passwordError: state.errors.password === undefined || !state.errors.password ? '' : state.errors.password,
    nicknameError: state.errors.nickname === undefined || !state.errors.nickname ? '' : state.errors.nickname,
    firstNameError: state.errors.firstName === undefined || !state.errors.firstName ? '' : state.errors.firstName,
    lastNameError: state.errors.lastName === undefined || !state.errors.lastName ? '' : state.errors.lastName,
    ninError: state.errors.nin === undefined || !state.errors.nin ? '' : state.errors.nin,
    addressError: state.errors.address === undefined || !state.errors.address ? '' : state.errors.address,
    globalError: errors.global === undefined || !errors.global ? '' : errors.global,
    OCRError: state.errors.OCR === undefined || !state.errors.OCR ? '' : state.errors.OCR,
    emailInputState: state.errors.email === undefined || !state.errors.email ? 'default' : 'error',
    phoneInputState: state.errors.phone === undefined || !state.errors.phone ? 'default' : 'error',
    otpInputState: state.errors.otp === undefined || !state.errors.otp ? 'default' : 'error',
    passwordInputState: state.errors.password === undefined || !state.errors.password ? 'default' : 'error',
    nicknameInputState: state.errors.nickname === undefined || !state.errors.nickname ? 'default' : 'error',
    firstNameInputState: state.errors.firstName === undefined || !state.errors.firstName ? 'default' : 'error',
    lastNameInputState: state.errors.lastName === undefined || !state.errors.lastName ? 'default' : 'error',
    ninInputState: state.errors.nin === undefined || !state.errors.nin ? 'default' : 'error',
    addressInputState: state.errors.address === undefined || !state.errors.address ? 'default' : 'error',
    email: state.fields.email,
    phone: state.fields.phone,
    otp: state.fields.otp,
    password: state.fields.password,
    passwordConfirm: state.fields.passwordConfirm,
    nickname: state.fields.nickname,
    firstName: state.fields.firstName,
    lastName: state.fields.lastName,
    nin: state.fields.nin,
    address: state.fields.address,
    emailFocused: () => state.focusedFields.email,
    phoneFocused: () => state.focusedFields.phone,
    passwordFocused: () => state.focusedFields.password,
    nicknameFocused: () => state.focusedFields.nickname,
    firstNameFocused: () => state.focusedFields.firstName,
    lastNameFocused: () => state.focusedFields.lastName,
    ninFocused: () => state.focusedFields.nin,
    addressFocused: () => state.focusedFields.address,
    otpFocused: () => state.focusedFields.otp,
    isAnyFieldFocused:
      state.focusedFields.email ||
      state.focusedFields.phone ||
      state.focusedFields.password ||
      state.focusedFields.otp ||
      state.focusedFields.nickname ||
      state.focusedFields.firstName ||
      state.focusedFields.lastName ||
      state.focusedFields.nin ||
      state.focusedFields.address,

    marketingAccepted:
      state.marketingAccepted.email ||
      state.marketingAccepted.sms ||
      state.marketingAccepted.phone ||
      state.marketingAccepted.partner,
    marketing_sms: state.marketingAccepted.sms,
    marketing_email: state.marketingAccepted.email,
    marketing_phone: state.marketingAccepted.phone,
    marketing_partner: state.marketingAccepted.partner,

    bonusValue: marketingOffer?.register_value_units_numeric ?? 0,
    bonusCurrency: marketingOffer?.register_value_units_text
      ? Utils.formatCurrency(marketingOffer.register_value_units_text)
      : '',
    bonusImage: marketingOffer?.register_image_wl ?? '',
    bonusUI: ui_elements,
    bonusUICTAText: ui_elements?.cta_text ? getText(ui_elements, `cta_text.${i18n.language}`, '') : '',
    bonusSubtitle: marketingOffer?.register_value_sub_title_text ?? '',

    onMarketingChange: onMarketingChange,
    onMarketingEmailChange: onMarketingEmailChange,
    onMarketingSmsChange: onMarketingSmsChange,
    onMarketingPhoneChange: onMarketingPhoneChange,
    onMarketingPartnerChange: onMarketingPartnerChange,
    onEmailChange: onEmailChange,
    onEmailBlur: onEmailBlur,

    onPhoneChange: onPhoneChange,
    onPhoneBlur: onPhoneBlur,
    onPasswordChange: onPasswordChange,
    onPasswordConfirmChange: onPasswordConfirmChange,
    onOtpChange: onOtpChange,
    onTcChange: onTcChange,
    onPhoneFocus: onPhoneFocus,
    onPasswordBlur: onPasswordBlur,
    onOtpBlur: onOtpBlur,
    onOtpFocus: onOtpFocus,
    onPasswordFocus: onPasswordFocus,
    onFocusEmail: onFocusEmail,
    nextStep: nextStep,
    onNextSteptWithAllConditions: onNextSteptWithAllConditions,
    // previousStep: previousStep,
    toggleShowPassword: toggleShowPassword,
    toggleShowConfirmPassword: toggleShowConfirmPassword,
    toggleShowCheckboxes: toggleShowCheckboxes,
    resendSMS: sendSMS,
    onNicknameChange: onNicknameChange,
    onNicknameBlur: onNicknameBlur,
    onNicknameFocus: onNicknameFocus,
    onFirstNameChange: onFirstNameChange,
    onFirstNameBlur: onFirstNameBlur,
    onFirstNameFocus: onFirstNameFocus,
    onLastNameChange: onLastNameChange,
    onLastNameBlur: onLastNameBlur,
    onLastNameFocus: onLastNameFocus,
    onNinChange: onNinChange,
    onNinBlur: onNinBlur,
    onNinFocus: onNinFocus,
    onAddressChange: onAddressChange,
    onAddressBlur: onAddressBlur,
    onAddressFocus: onAddressFocus,
    backHandler: goBack,

    nextButtonDisabled: isDisabled(),
    dialogOpen: state.dialogOpen,
    onCloseDialog: () => {
      // setState((prevState) => ({
      //   ...prevState,
      //   // dialogOpen: !state.dialogOpen
      //   dialogOpen: false,
      // }));
    },
    // @ts-ignore
    backDisabled: stepBackMappping[state.currentStep] === false,
    isTcAcceptedByDefault: props.properties.tcAcceptedByDefault,

    documents_max_file_size: documentsMaxFileSize,
    OCR_loading: druid.inProgress,
    showOCRUploadScreen: showUploadScreen,
    uploadOCRDocument: uploadDocument,
    openCamera: openCamera,
    onSelectOCRFile: onSelectFile,
    uploadedOCRFile: state.uploadedOCRFile ? state.uploadedOCRFile : '',
    toggleUploadOCRScreen: toggleUploadScreen,
    document_name: state?.file?.name ? state?.file?.name : '',
  };

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default Register;
