import React from 'react';
import styled from 'styled-components';

import { useAppSelector } from '../../../store';
import craftJsParser from '../../../components/utils/craftJsParser';

import { StateListItems } from '../StateListItems/StateListItems';
import { DataElementContext } from '../../common/DataElementContext';
import { getStateValue } from '../../utils/getStateValue';

import triggerStateLoaderIfNeeded from '../../utils/hooks/triggerStateLoaderIfNeeded';
import { PageDataContext, getProjectDataFromContext } from '../../../components/utils/PageDataProvider';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    items: 6,
    dsType: '',
  },
};

const StateListDiv = styled.div((props) => props.$styleText);

export const StateList = (componentProps) => {
  let props = componentProps;

  const config = useAppSelector((state) => state.templatesConfig);

  const { items } = props.properties;

  let hasError = false;
  let content = null;

  const pageDataContext = React.useContext(PageDataContext);
  const [currentPage, setCurrentPage] = React.useState(0);

  try {
    const dsType = props.properties?.dsType ?? '';
    const det = dsType ? config.dataElementTypes[dsType] : null;

    let stateKey = '';

    if (det && det.internal && det.internal.length) {
      const from = det.internal.find((el) => el.id === 'from');
      const path = det.internal.find((el) => el.id === 'path');
      if (from && from.value === 'state') {
        if (path && path.value) stateKey = path.value;
      }
    }

    if (!stateKey) return null;
    triggerStateLoaderIfNeeded(stateKey, { currentPage });

    const stateItems = useAppSelector((state) => getStateValue(state, stateKey));
    if (!stateItems) return null;
    //console.log(stateKey, stateItems);

    const data = getProjectDataFromContext(pageDataContext);

    const dsWrapperData = data[props.linkedNodes['ds-lister-items']];

    let itemContent = craftJsParser({
      craftState: data,
      rootNodeId: dsWrapperData.nodes[0],
      pageId: props.refType === 'layout' ? null : props.refId,
      pageLayoutId: props.refType === 'layout' ? props.refId : null,
      pageType: props.refType,
    });

    let maxItems = 0;
    if (!isNaN(parseInt(items))) maxItems = parseInt(items);

    const rendered =
      stateItems?.slice(0, maxItems < 0 ? stateItems.length : maxItems).map((item, i) => {
        return (
          <DataElementContext.Provider key={i} value={item}>
            {itemContent}
          </DataElementContext.Provider>
        );
      }) || [];

    content = <StateListItems {...dsWrapperData.props}>{rendered}</StateListItems>;
  } catch (err) {
    console.error(err);
    hasError = true;
  }

  if (hasError) return null;

  return (
    <StateListDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      {content}
    </StateListDiv>
  );
};
