import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CloseIcon from '@material-ui/icons/Close';

import "./RankingHistory.scss";
import { requestScoreHistory } from "../../store/sagas/history";
import { getBetsState } from "../../../bets/store/selectors/betData";
import WinnerLoader from "../../../winner-loader/index";

const RankingHistory = props => {
  const { handleClose, type, player, seasonId, eventId, name, t } = props;

  const [loading, setLoading] = React.useState(false);
  const [score, setScore] = React.useState({
    id: "",
    type: type,
    me: {},
    top: [],
  });

  const itemsPerPage = 20;

  const [show, setShow] = React.useState(itemsPerPage);
  const showMoreHandler = () => {
    let ns = show + itemsPerPage;
    if (ns > score.top.length) {
      ns = score.top.length;
    }
    setShow(ns);
  }


  React.useEffect(() => {
    setLoading(true);

    requestScoreHistory({
      type: type,
      season_id: seasonId,
      event_id: eventId,
      cb: (data) => {
        setLoading(false);
        setScore(data);
      }
    });
  }, []); // eslint-disable-line

  return (
    <div className="wf-ranking-history">
      <div className="wfrh-header">
        <div className="wfrh-info">{name}</div>
        <div className="wfrh-close" onClick={handleClose}><CloseIcon /></div>
      </div>
      {score.top.length !== 0 && <div className="wf-ranking">
        <div className="wf-row wfrh-header">
          <div className="wf-col wf-col1 wfrh-header wf.wf-center">{t("POS")}</div>
          {/*<div className="wf-col wf-col2 wf-header">
            </div>*/}
          <div className="wf-col wf-col3 wfrh-header wf-left">{t("PLAYER")}</div>
          <div className="wf-col wf-col4 wfrh-header wf-right">{t("AWARD")}</div>
          <div className="wf-col wf-col5 wfrh-header wf-right">{t("BALANCE")}</div>
        </div>
        <div className="wf-rows">
          {score.top.slice(0, show).map((pl, i) => {
            let level = "wf-regular";
            if (i === 0) level = "wf-gold";
            if (i === 1) level = "wf-silver";
            if (i === 2) level = "wf-bronze";

            let isCurrent = false;
            let name = pl.nickname;
            if (score.me && score.me.pos === pl.pos) {
              isCurrent = true;
            }

            return <div
              className={`wf-row ${isCurrent ? "wf-active" : ''} ${i < 3 ? `wf-row-${i}` : ''}`}
              key={`player-${i}`}
            >
              <div className="wf-col wf-col1">
                <div className={level}>{pl.pos}</div>
              </div>
              {/*<div className="wf-col wf-col2 wf-header">
                  <img src={pl.avatar ? pl.avatar : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="} alt="" />
                  </div>*/}
              <div className="wf-col wf-col3">{name}</div>
              <div className="wf-col wf-col4 wf-right">{pl.award ? pl.award + " Lei" : "-"}</div>
              <div className="wf-col wf-col5 wf-right">{pl.balance}</div>
            </div>
          })}
          {score.top.length > show && <div className="wf-row wf-show-more" onClick={showMoreHandler}>
            <div className="wf-col wf-col-full">
              {t("Show more")}
            </div>
          </div>}
          {
            score.me &&
            (
              (score.me.pos > show && score.me.pos < score.top.length)
              ||
              (score.me.pos > score.top.length)
            ) &&
            <div
              className={`wf-row wf-active`}
              key={`current-player`}
            >
              <div className="wf-col wf-col1">
                <div className={"wf-regular"}>{score.me.pos < score.top.length ? score.me.pos : '-'}</div>
              </div>
              <div className="wf-col wf-col3">
                {
                  score.me.nickname
                }
              </div>
              <div className="wf-col wf-col4 wf-right">{score.me.award ? score.me.award + " Lei" : "-"}</div>
              <div className="wf-col wf-col5 wf-right">{score.me.balance}</div>
            </div>}
        </div>
      </div>}


      {score.top.length === 0 && !loading && <div className="wf-empty">{t("There are no records")}</div>}

      {loading && <div>
        <WinnerLoader />
      </div>}

    </div>
  );
};

const mapStateToProps = state => {
  const bts = getBetsState(state);
  return {
    bts: bts,
  };
};

const mapActions = {

};

export default withTranslation()(connect(mapStateToProps, mapActions)(RankingHistory));
