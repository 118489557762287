import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { DataElementContext } from '../../../../../page-components/common/DataElementContext';
import { getLottoState, makeGetBonuses } from '../../../../../modules/lotto/store/selectors/lottoData';

import { formatCurrency } from '../../../../../modules/lotto/utils/formatters';
import {
  betsSlipClearLottoTicket,
  betsSlipStakeInc,
  betsSlipStakeDec,
  betsSlipStakeSet,
  betSlipLottoTicketCreate,
  betSlipLottoInitializeTickets,
  betSlipLottoBonusRequestEvaluation,
  betSlipLottoFreeBetClear,
} from '../../../../../modules/lotto/store/actions/betsSlip';

import evaluateTicket from '../../../../../modules/lotto//utils/evaluateTicket';
import { doLogin } from '../../../../../modules/casino/store/actions/authentication';
import { RootState } from '@/store';

const twoDecimalsFloat = (vf: any) => {
  let v = parseFloat(vf);
  if (isNaN(v)) {
    return v;
  }
  v = parseFloat(v.toFixed(2));
  return v;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const LottoQuickBet = (props: any) => {
  const {
    amount,
    stakeInc,
    stakeDec,
    stakeSet,
    clearBet,
    wallet,
    profile,
    auth,
    walletBonusEvaluate,
    lottoTicket,
    betSlipLottoInitializeTickets,
    freeBets,
    selectedFreeBet,
    betSlipLottoFreeBetClear,
    bonuses,
    requestBonusEvaluation,
    placeTicketEnabled,
    t,
  } = props;

  const [stakeAmount, setStakeAmount] = React.useState(amount);
  const [openPlaceBet, setOpenPlaceBet] = React.useState(false);
  const [amounts, setAmounts] = React.useState<any>({
    totalLines: 0,
    winAmount: 0,
    minWinAmount: 0,
  });

  React.useEffect(() => {
    //console.log("wallet update", wallet);
    requestBonusEvaluation();
  }, [wallet, profile, lottoTicket, amount, requestBonusEvaluation]); // eslint-disable-line

  const roundThousands = (value: any) => {
    if (typeof value == 'string') return value;
    if (!value) return '0.00';

    const decimals = 2;
    const tmp = value / 10000;

    // @ts-ignore
    const res: any = Number(Math.round(tmp + 'e' + decimals) + 'e-' + decimals).toFixed(decimals);

    return parseFloat(res);
  };

  React.useEffect(() => {
    if (lottoTicket) {
      const systems = lottoTicket.systems;
      if (systems.length) {
        const ticket = Object.assign({}, lottoTicket.event, {
          stake_amount: Math.round(amount * 100) / 100,
          stk: amount,
          tx: 0,
          max_winning: 100000,
          numbers: [...lottoTicket.numbers].sort(),
          systems: [...systems].sort(),
        });
        const res = evaluateTicket(ticket);

        setAmounts({
          totalLines: res.lines,
          winAmount: roundThousands(res.max_winning),
          minWinAmount: roundThousands(res.min_winning),
        });
      } else {
        setAmounts({
          totalLines: 0,
          winAmount: 0,
          minWinAmount: 0,
        });
      }
    }
  }, [lottoTicket, amount]);

  const handlePlaceBetClose = (type: any) => {
    clearBet();
    setOpenPlaceBet(false);
  };

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    setStakeAmount(amount);
  }, [amount]);

  const handleDeleteOpen = () => {
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  const handleDeleteYesClose = () => {
    setOpen(false);
    clearBet();
  };

  const onStakeInc = () => {
    betSlipLottoFreeBetClear();
    stakeInc(-1);
  };

  const onStakeDec = () => {
    betSlipLottoFreeBetClear();
    stakeDec(-1);
  };
  const handleStakeChange = (e: any) => {
    let ev = e.target.value;
    if (selectedFreeBet !== -1) {
      betSlipLottoFreeBetClear();
    }

    // let them input an empty string
    if (ev === '') {
      setStakeAmount('');
      stakeSet('');
      return;
    }

    // don't let them put an amount < 1
    if (ev === '0') {
      return;
    }

    // convert , to .
    ev = ev.split(',').join('.');

    // only one .
    const sev = ev.split('.');
    if (sev.length > 2) {
      return;
    }

    // only 2 decimals after .
    if (sev.length > 1 && sev[1].length > 2) {
      return;
    }

    // only valid numbers
    if (isNaN(+ev)) {
      return;
    }

    let v: any = '';

    if (ev !== '') {
      // amounts ending with . have the same value
      if (ev.endsWith('.')) {
        setStakeAmount(ev);
        return;
      }

      // convert amount
      v = parseFloat(ev);
      if (isNaN(v)) return;
    }

    // update amount
    setStakeAmount(ev);

    // only update betslip amount for new values
    if (v !== amount) {
      stakeSet(v);
    }
  };

  const createTicket = () => {
    const tickets: any = [];
    lottoTicket.allSelectedEvents.forEach((s: any, i: number) => {
      const ticketData: any = {
        numbers: lottoTicket.numbers,
        systems: lottoTicket.systems,
        event_id: s,
        amount: amount,
        date: lottoTicket.allSelectedEventsFull[i].event_date,
      };

      if (lottoTicket.extra) {
        const tmp = { ...lottoTicket.extra };

        if (selectedFreeBet > -1) {
          const freeBet = freeBets[selectedFreeBet];
          tmp.free_bet = freeBet.code;
        }

        const bonus_user_data = [];

        if (tmp.free_bet) {
          bonus_user_data.push({
            type: 'free_bet',
            id: tmp.free_bet,
            data: '{}',
          });
        } else {
          if (tmp.luckyNumber && bonuses && typeof bonuses.lotto_lucky_number !== 'undefined') {
            bonus_user_data.push({
              type: 'lotto_lucky_number',
              id: bonuses.lotto_lucky_number.code,
              data: JSON.stringify({
                number: parseInt(tmp.luckyNumber, 10),
              }),
            });
          }

          if (bonuses && typeof bonuses.lotto_chance !== 'undefined') {
            bonus_user_data.push({
              type: 'lotto_chance',
              id: bonuses.lotto_chance.code,
              data: '{}',
            });
          }
        }

        //console.log("bonus_user_data", bonus_user_data);

        ticketData.bonus_user_data = JSON.stringify(bonus_user_data);
      }
      tickets.push(ticketData);
    });

    const event = {
      event_name: lottoTicket.event.event_name,
    };

    betSlipLottoInitializeTickets({ event, tickets });
    setOpenPlaceBet(true);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  let loggedIn = false;
  if (auth && auth.auth_type === 'user') {
    loggedIn = true;
  }

  let bLimit = twoDecimalsFloat(wallet.main);
  let valid = bLimit >= amount;

  if (window.config.useBonusEvaluation === '1') {
    if (
      walletBonusEvaluate &&
      walletBonusEvaluate.success &&
      walletBonusEvaluate.data &&
      walletBonusEvaluate.data.bonus
    ) {
      bLimit = twoDecimalsFloat(walletBonusEvaluate.data.free_money_used);
      valid = walletBonusEvaluate.data.valid;
    } else {
      valid = bLimit >= amount;
    }
  }

  let hasUsedBonusMoney = false;
  if (
    walletBonusEvaluate &&
    walletBonusEvaluate.data &&
    walletBonusEvaluate.data.eligible &&
    walletBonusEvaluate.data.valid
  ) {
    let bonus_used = 0;
    walletBonusEvaluate.data.bonus.forEach((b: any) => {
      const bu = parseFloat(b.balance_used);
      if (!isNaN(bu) && bu > 0) {
        bonus_used += bu;
      }
    });

    if (bonus_used > 0) {
      hasUsedBonusMoney = true;
    }
  }

  const contextValue = {
    balls: [...Array(lottoTicket?.event?.k ?? 20).keys()].map((i) => {
      if (i < lottoTicket?.numbers?.length) {
        const isLN = lottoTicket?.extra?.luckyNumber?.toString() === lottoTicket?.numbers?.[i]?.toString();

        return {
          value: lottoTicket.numbers[i],
          lucky: isLN,
          hasUsedBonusMoney: hasUsedBonusMoney,
        };
      }
      return {
        value: '-',
        lucky: false,
        hasUsedBonusMoney: false,
      };
    }),
    deleteOpen: open,
    handleDeleteOpen,
    handleDeleteClose,
    handleDeleteYesClose,
    selectedFreeBet,
    freeBetName:
      selectedFreeBet !== -1
        ? freeBets[selectedFreeBet] && freeBets[selectedFreeBet].name
          ? freeBets[selectedFreeBet].name
          : 'N/A'
        : '',
    amount: amount,
    draws: lottoTicket?.allSelectedEvents?.length ?? 0,
    onStakeDec,
    onStakeInc,
    handleStakeChange,
    stakeAmount,
    loggedIn,
    valid,
    canPlaceTicket: !(
      !placeTicketEnabled ||
      (!valid && selectedFreeBet === -1) ||
      amount === 0 ||
      amount === '' ||
      lottoTicket?.allSelectedEvents?.length === 0
    ),
    createTicket,
    potentialWinning:
      amounts && amounts.winAmount && typeof amounts.winAmount.toFixed === 'function'
        ? amounts.winAmount.toFixed(2)
        : '0.00',
    openPlaceBet,
    handlePlaceBetClose,
    walletCurrency: formatCurrency(wallet.currency),
    lottoTicket,
  };

  //console.log('LottoQuickBet[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{props.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );

  /*
  return (
    <div className={classes.root}>
      <div className="d-flex flex-row flex-nowrap align-items-center justify-content-between">
        <div className={`${classes.infoWrapper} d-flex flex-row flex-wrap flex-grow-1`}>
          {[...Array(lottoTicket.event.k).keys()].map(i => {
            if (i < lottoTicket.numbers.length) {
              const isLN = lottoTicket && lottoTicket.extra && lottoTicket.extra.luckyNumber && lottoTicket.extra.luckyNumber === lottoTicket.numbers[i];

              return (
                <div key={i} className={`${classes.ball} ${isLN ? 'ln' : ''} ${isLN && hasUsedBonusMoney ? 'ds' : ''}`}>
                  {lottoTicket.numbers[i]}
                </div>
              );
            }
            return (
              <div key={i} className={`${classes.ball} ${classes.ballNoValue}`}>
                -
              </div>
            );
          })}
        </div>
        <div>
          <div className={classes.p0} onClick={handleClickOpen}>
            <img src={Delete} alt="" />
          </div>
        </div>
      </div>
      <div className={classes.hr}></div>

      <div
        className={'{classes.payHolder} d-flex flex-row flex-nowrap align-items-end justify-content-between'}
      >
        <div>
          <div className={classes.drawsText}>
            {selectedFreeBet !== -1 ? (freeBets[selectedFreeBet] && freeBets[selectedFreeBet].name ? freeBets[selectedFreeBet].name : 'N/A') : ''} {amount * lottoTicket.allSelectedEvents.length} Lei {t('total')}
          </div>
          <div className={`${classes.payAmount} d-flex flex-row flex-nowrap align-items-center`}>
            <BounceButton>
              <ButtonBase onClick={onStakeDec} variant="contained" className={classes.but} disabled={selectedFreeBet !== -1}>
                -
              </ButtonBase>
            </BounceButton>
            <InputBase
              type="text"
              onChange={handleStakeChange}
              className={`${classes.amount} ${amount < 2 ? classes.warning : ''}`}
              value={stakeAmount}
              inputProps={{
                className: classes.amountInput,
                'aria-label': 'stake',
                pattern: '[0-9\.]*'
              }}
              endAdornment={
                <InputAdornment
                  className={`${classes.amountEnd} ${amount < 2 ? classes.warning : ''}`}
                  position="end"
                >
                  lei
                </InputAdornment>
              }
            />
            <BounceButton>
              <ButtonBase onClick={onStakeInc} variant="contained" className={classes.but} disabled={selectedFreeBet !== -1}>
                +
              </ButtonBase>
            </BounceButton>
          </div>
        </div>
        <div className={classes.payButtonHolder}>
          {loggedIn && (
            <ButtonBase
              variant="contained"
              className={`${classes.payButton} ${(lottoTicket && lottoTicket.allSelectedEvents.length === 0) || !amount || (!valid && selectedFreeBet === -1) ? classes.payButtonDisabled : ''}`}
              onClick={createTicket}
              disabled={(lottoTicket && lottoTicket.allSelectedEvents.length === 0) || !amount || (!valid && selectedFreeBet === -1)}
            >
              <div>
                {lottoTicket.allSelectedEvents.length <= 1 && <div className={classes.buttonTitle}>{t('PLACE TICKET')}</div>}
                {lottoTicket.allSelectedEvents.length > 1 && <div className={classes.buttonTitle}>{t('PLACE TICKETS')}</div>}
                <div className={classes.buttonSubtitle}>
                  {t('Potential winning')} {amounts && amounts.winAmount && typeof amounts.winAmount.toFixed === 'function' ? amounts.winAmount.toFixed(2) : '0.00'} lei
                </div>
              </div>
            </ButtonBase>
          )}
          {!loggedIn && (
            <ButtonBase
              variant="contained"
              className={`${classes.loginButton}`}
              onClick={handleLogin}
            >
              <div>
                <div className={classes.buttonTitle}>
                  <img src={avatar} alt="" className={classes.avatar} /> {t('ENTER YOUR ACCOUNT')}
                </div>
              </div>
            </ButtonBase>
          )}
        </div>
      </div>
      <DeleteModal open={open} handleClose={handleClose} handleYesClose={handleYesClose} />
      <TicketModal openPlaceBet={openPlaceBet} handlePlaceBetClose={handlePlaceBetClose} />
    </div>
  );
  */
};

const mapStateToProps = () => {
  const getBonuses = makeGetBonuses();

  return (state: RootState, props: any) => {
    const bst = getLottoState(state);
    const ct = bst.betsSlip;

    let event_id = '';
    if (bst.betsSlip.lottoTicket && bst.betsSlip.lottoTicket.event && bst.betsSlip.lottoTicket.event.event_id) {
      event_id = bst.betsSlip.lottoTicket.event.event_id;
    }

    return {
      amount: bst.betsSlip.amount,
      wallet: state.wallet,
      profile: state.profile,
      walletBonusEvaluate: ct.lottoTicket && ct.lottoTicket.bonusEvaluate ? ct.lottoTicket.bonusEvaluate : null,
      auth: state.authentication,
      freeBets: state.freeBets.freeBets,
      selectedFreeBet: ct.selectedFreeBet,
      bonuses: event_id !== '' ? getBonuses(state, { event_id }) : [],
      lottoTicket: bst.betsSlip.lottoTicket,
      placeTicketEnabled: ct.placeTicketEnabled,
    };
  };
};

const actionCreators = {
  clearBet: betsSlipClearLottoTicket,

  stakeInc: betsSlipStakeInc,
  stakeDec: betsSlipStakeDec,
  stakeSet: betsSlipStakeSet,
  lottoTicketCreate: betSlipLottoTicketCreate,
  betSlipLottoInitializeTickets: betSlipLottoInitializeTickets,
  doLogin: doLogin,
  requestBonusEvaluation: betSlipLottoBonusRequestEvaluation,
  betSlipLottoFreeBetClear: betSlipLottoFreeBetClear,
};

export default withTranslation()(connect(mapStateToProps, actionCreators)(LottoQuickBet));
